import React, { useState } from 'react';
// components
import Icon from '@components/Icon';
import { Segment } from 'semantic-ui-react';
import { FlexItem, FlexRow } from '@components/tables/FlexTable';

const FlexRowWithToggle = ({ isOpen, setIsOpen, rowContent, content, loading, background, marginBottom, marginTop, borderless, ...rest }) => {
    const [open, setOpen] = useState(isOpen === undefined ? false : isOpen)

    const style = !borderless ? { 
        padding: 0,
        marginBottom: marginBottom || "0.5rem",
        marginTop: marginTop || "0.5rem",
        background: background || "var(--white)"
    } : { 
        padding: 0,
        marginBottom: marginBottom || "0.5rem",
        marginTop: marginTop || "0.5rem",
        boxShadow: "none",
        background: "transparent"
    }

    return (
        <Segment style={style}>
            <FlexRow {...rest} background={background || "transparent"} borders={borderless ? undefined : true} onClick={() => {
                setOpen(!open)
                if (setIsOpen) setIsOpen(rest.key)
             }}>
                <FlexItem textAlign="center" basis="10%">
                    <Icon
                        style={{ cursor: 'pointer', marginRight: "0.5rem" }}
                        name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
                    />
                </FlexItem>
                {rowContent}
            </FlexRow>
            {open &&
                <Segment loading={loading} style={{ padding: 0, marginTop: 0, border: "none" }}>
                    {content}
                </Segment>
            }
        </Segment>
    )
}

export default FlexRowWithToggle;
