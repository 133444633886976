import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Grid, Header, Segment, Icon, Divider, Popup } from 'semantic-ui-react';
// module specific components
import HROrderForm from './HROrderForm';
import EmploymentOrderForm from './EmploymentOrderForm';
import SaleOrderForm from './SaleOrderForm';

const CreateOrderForm = ({ setResult, onClose }) => {
    const { t } = useTranslation()
    const [currentView, setCurrentView] = useState(0)

    const BackToSelection = ({ content }) => {
        return (
            <>
                <FlexRow background="transparent" padding="0">
                    <FlexItem basis="25%">
                        <Icon.Group  className="ref-link" onClick={() => setCurrentView(0)}>
                            <Icon name="angle left"/>
                            <span style={{ textTransform: "normal" }}> { t('back_to_selection') }</span>
                        </Icon.Group>
                    </FlexItem>
                    <FlexItem textAlign="right">
                        <Header as="h4"
                            style={{ fontSize: "1.3rem" }}
                            content={content}
                        />
                    </FlexItem>
                </FlexRow>
                <Divider/>
            </>
        )
    }

    return (
        <>
        { currentView === 0 && 
            <>
                <Header as="h2" textAlign="center" content={t('choose_order_type')}/>
                <Grid>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <Segment
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                className="segment-hover"
                                textAlign="center"
                                content={t('recruitment')}
                                onClick={() => setCurrentView(1)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <Segment
                                style={{ cursor: "pointer", fontWeight: "bold" }}
                                className="segment-hover"
                                textAlign="center"
                                content={t('employment')}
                                onClick={() => setCurrentView(2)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <Popup
                                position="top center"
                                trigger={
                                    <Segment
                                        disabled
                                        style={{ cursor: "not-allowed", fontWeight: "bold" }}
                                        className="segment-hover"
                                        textAlign="center"
                                        content={t('sales')}
                                        // onClick={() => setCurrentView(3)}
                                    />
                                }
                                content="Currently unavailable/locked feature"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        }

        { currentView === 1 &&
            <>
                <BackToSelection content={t('recruitment')}/>
                <HROrderForm setResult={setResult} onClose={onClose}/>
            </>
        }
        { currentView === 2 && 
            <>
                <BackToSelection content={t('employment')}/>
                <EmploymentOrderForm setResult={setResult} onClose={onClose}/> 
            </>
        }
        { currentView === 3 && 
            <>
                <BackToSelection content={t('sales')}/>
                <SaleOrderForm setResult={setResult} onClose={onClose}/>
            </>
        }
        </>
    );
};

export default CreateOrderForm;