import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { icons } from '@store/icons';
// components
import { Container, Grid, Search } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import BreadcrumbNav from '@components/BreadcrumbNav';
import Loading from '@components/general/Loading';
import ReportSection from '../components/ReportSection';

const FilterAction = ({ text, isActive, ...rest }) => {
    return (
        <span className={"filter-action " + (isActive && "active")}  {...rest}>
            {text}
        </span>
    )
}

const ReportList = () => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)
    const [isHRActive, setIsHRActive] = useState(true)
    const [isPMActive, setIsPMActive] = useState(true)
    const [isSMActive, setIsSMActive] = useState(true)
    const [isOperativeActive, setIsOperativeActive] = useState(true)

    // eslint-disable-next-line
    const [reports, setReports] = useState([
        {
            slug: "candidates-by-stage",
            name: "Candidates by stage",
            category: t('hiring_room'),
            section: "HR",
            isActive: true
        },
        {
            slug: "candidate-stage-time",
            name: "Candidate stage time",
            category: t('hiring_room'),
            section: "HR",
            isActive: true
        },
        {
            slug: "recruiter-stats",
            name: "Recruiters",
            category: t('hiring_room'),
            section: "HR",
            isActive: true
        },
        {
            slug: "candidate-rejections",
            name: "Candidate rejections",
            category: t('hiring_room'),
            section: "HR",
            isActive: true
        },
        {
            slug: "candidates-interactions",
            name: "Candidate interactions",
            category: t('hiring_room'),
            section: "HR",
            isActive: true
        },
        {
            slug: "published-by-recruiter",
            name: "Published by Recruiter",
            category: t('jobs'),
            section: "HR",
            isActive: true
        },
        {
            slug: "publications-sources",
            name: "Publication sources",
            category: t('jobs'),
            section: "HR",
            isActive: true
        },
        {
            slug: "fluctuations",
            name: "Fluctuations",
            category: t('contracts'),
            section: "HR",
            isActive: false
        },
        {
            slug: "engagement-termination-tables",
            name: "Engagement/Termination tables",
            category: t('contracts'),
            section: "HR",
            isActive: true
        },
        {
            slug: "closed-projects",
            name: "Closed projects",
            category: t('projects'),
            section: "PM",
            isActive: false
        },
        {
            slug: "weekly-progress",
            name: "Weekly progress",
            category: t('projects'),
            section: "PM",
            isActive: false
        },
        {
            slug: "weekly-progress",
            name: "Weekly progress",
            category: t('orders'),
            section: "SM",
            isActive: false
        },
        {
            slug: "call-reports",
            name: "Call reports",
            category: t('Integrations'),
            section: "OPS",
            isActive: true
        }
    ])

    const breadcrumb_items = [
        { 'name': t('reports'), 'icon': icons.REPORTS, 'href': '' }
    ]


    return (
        <>
            <BreadcrumbNav items={breadcrumb_items} />
            {isLoading ? <Loading /> :
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <FlexRow>
                                <FlexItem>
                                    <FilterAction isActive={isHRActive} onClick={() => setIsHRActive(!isHRActive)} text={"HR"} />
                                    <FilterAction isActive={isPMActive} onClick={() => setIsPMActive(!isPMActive)} text={"PM"} />
                                    <FilterAction isActive={isSMActive} onClick={() => setIsSMActive(!isSMActive)} text={"SM"} />
                                    <FilterAction isActive={isOperativeActive} onClick={() => setIsOperativeActive(!isOperativeActive)} text={"OPS"} />
                                </FlexItem>
                                <FlexItem basis="150%">
                                    <Search placeholder={t('search_report_by_name')} className="report-search" />
                                </FlexItem>
                                <FlexItem>

                                </FlexItem>
                            </FlexRow>
                        </Grid.Row>
                        <ReportSection isActive={isHRActive} reports={reports} section="HR" sectionName={t('hr_long')} />
                        <ReportSection isActive={isPMActive} reports={reports} section="PM" sectionName={t('pm_long')} />
                        <ReportSection isActive={isSMActive} reports={reports} section="SM" sectionName={t('sm_long')} />
                        <ReportSection isActive={isOperativeActive} reports={reports} section="OPS" sectionName={t('ops_long')} />
                    </Grid>
                </Container>
            }
        </ >
    );
};

export default ReportList;