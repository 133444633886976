import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
//other
import SuperField from '@components/forms/SuperField';
import { Form, Button, Message } from 'semantic-ui-react';


function BusinessDetailForm({data, setView, onConfirm, patchEndpoint}) {
    const { t } = useTranslation();

    const business_detail_data = {
        name: data?.business_detail?.name || "",
        identification_number: data?.business_detail?.identification_number || "",
        dit_number: data?.business_detail?.dit_number || "",
        vat_number: data?.business_detail?.vat_number || "",
        business_register_folder: data?.business_detail?.business_register_folder || "",
        tax_mode: data?.business_detail?.tax_mode || null,
        reinvoice: data?.business_detail?.reinvoice || false
    }

    const [form, setForm] = useState({
        business_detail: business_detail_data
    })

    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {

        setIsProcessing(true);

        const request = await requests.patch(patchEndpoint + `${data?.id}/`, form)

        if (request.status === 200) {
            onConfirm(request.response)
            setView(0)
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false);
    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('name')}
                    placeholder={t('name')}
                    error={ errors?.business_detail?.name?.[0] || false }
                    value={form?.business_detail?.name}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                  name: value
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    required
                    label={t('id_number')}
                    placeholder={t('id_number')}
                    error={ errors?.business_detail?.identification_number?.[0] || false }
                    value={form?.business_detail?.identification_number}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                  identification_number: value
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('dit_number')}
                    placeholder={t('dit_number')}
                    error={ errors?.business_detail?.dit_number?.[0] || false }
                    value={form?.business_detail?.dit_number}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                  dit_number: value
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('vat_number')}
                    placeholder={t('vat_number')}
                    error={ errors?.business_detail?.vat_number?.[0] || false }
                    value={form?.business_detail?.vat_number}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                  vat_number: value
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    widht={10}
                    label={t('register_folder')}
                    placeholder={t('register_folder')}
                    error={ errors?.business_detail?.business_register_folder?.[0] || false }
                    value={form?.business_detail?.business_register_folder}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                    business_register_folder: value
                            }
                        }))
                    }
                />

                {/* <SuperField as="choice"
                    type="tax_mode"
                    label={t('tax_mode')}
                    value={form?.business_detail?.tax_mode?.toString() || ""}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            business_detail: {
                                ...prev.business_detail,
                                tax_mode: value
                            }
                        }))
                    }
                /> */}
            </Form.Group>

            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    disabled={isProcessing}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default BusinessDetailForm