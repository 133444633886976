import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// store
import { icons } from '@icons';
import { routes } from '@routes';
// components
import TeamForm from '../pm/teams/components/forms/TeamForm';
import QuickAccessLink from './QuickAccessLink';
import RecordForm from '../pm/timesheets/components/forms/RecordForm';
import BenefitsForm from '../hr/benefits/components/forms/BenefitsForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Segment, Sidebar, Icon, Grid, Header, Button, Divider } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';

const HorizontalSidebar = ({ visible, close }) => {
    const { t } = useTranslation()
    let history = useHistory();
    const user = useSelector((state) => state.user);

    const moveToLink = (to) => {
        close();

        if (to) {
            history.push(to);
        }
    }

    return (
        <Sidebar
            as={Segment}
            animation="overlay"
            direction="top"
            visible={visible}
        >
            <Grid stretched>
                <Grid.Column textAlign="left" computer={14} tables={14} mobile={16}>
                    <Header as='h3' style={{ paddingTop: "1rem" }} content={t('quick_access')} />
                </Grid.Column>
                <Grid.Column textAlign="right" computer={2} tables={4} mobile={16}>
                    <Button size="tiny" onClick={close}>
                        <Icon name="remove" /> {t('close')}
                    </Button>
                </Grid.Column>
            </Grid>

            <Divider />

            <Grid stackable stretched columns={6}>
                    <CanView permissions={['timesheets.c_manage_all_records', 'timesheets.c_manage_user_records']}>
                        {user.role_name !== "root" &&
                            <SuperDuperModal
                                trigger={
                                    <QuickAccessLink
                                        to={() => moveToLink()}
                                        // image="/images/icons/teams.svg"
                                        icon={icons.TIMESHEETS} //icon from sidebar
                                        text={t('add_timesheet_record')}
                                    />
                                }
                                size="small"
                                content={<RecordForm />}
                            />
                        }
                    </CanView>

                <CanView permissions={["benefits.c_manage_all_benefits"]}>
                    <SuperDuperModal
                        trigger={
                            <QuickAccessLink
                                to={() => moveToLink()}
                                // image="/images/icons/teams.svg"
                                icon={icons.BENEFITS} //icon from sidebar
                                text={t('add_benefits')}
                            />
                        }
                        size="small"
                        // content={<RecordForm />}
                        content={ <BenefitsForm /> }
                    />
                </CanView>
                <CanView permissions={["c_manage_all_employees"]}>
                    <QuickAccessLink
                        to={() => moveToLink(routes.CREATE_EMPLOYEE)}
                        icon={icons.EMPLOYEES} //icon from sidebar
                        text={t('create_employee')}
                    />
                </CanView>
                <CanView permissions={["teams.c_manage_all_teams"]}>
                    <SuperDuperModal
                        trigger={
                            <QuickAccessLink
                                to={() => moveToLink()}
                                // image="/images/icons/teams.svg"
                                icon={icons.TEAMS} // from sidebar
                                text={t('create_team')}
                            />
                        }
                        size="small"
                        header={t('create_new_team')}
                        content={<TeamForm
                            btnName={t('create')}
                            onConfirm={new_item=>history.push(routes.TEAMS_DETAIL+new_item.id)}

                        />}
                    />
                </CanView>

            </Grid>
        </Sidebar>
    );
};

export default HorizontalSidebar;