import React from "react";
// store
import { API } from "@store/config";
import { requests } from '@helpers/requests';
// components
import AvatarIcon from '@components/AvatarIcon';
import { Grid, Header, Card } from "semantic-ui-react";

const SupplierHeader = ({ data, isProfile }) => {

    const uploadLogo = async (image) => {
        const formData = new FormData();
        if (isProfile) {
            formData.append("profile_picture", image);
            return await requests.post(API.EMPLOYEES + data?.profile?.id + "/picture/", formData)
        } else {
            formData.append("logo", image);
            return await requests.post(API.ACCOUNTS + data?.account?.id + "/logo/", formData)
        }
    }

    const deleteLogo = async () => {
        if (isProfile) {
            return await requests.del(API.EMPLOYEES + data?.profile.id + "/picture/")
        } else {
            return await requests.del(API.ACCOUNTS + data?.account.id + "/logo/")
        }
    }


    return (
        <Card fluid style={{ borderRadius: 0 }}>
            <Grid centered>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column computer={4} tablet={16} mobile={16} style={{ paddingLeft: "1rem", paddingTop: "1rem", paddingBottom: "1rem" }}>
                        <AvatarIcon size={150} name={data?.account?.name || data?.profile?.fullname || "."} src={data?.account?.logo || data?.profile?.logo || ''} handleUpload={uploadLogo} handleDelete={deleteLogo} uploadable={true} />
                    </Grid.Column>
                    <Grid.Column computer={10} tablet={10} mobile={16}>
                        <Grid ><Header as="h1" style={{ fontSize: '3.5rem', paddingLeft: 0 }}>{data?.account?.name || data?.profile?.fullname || ""}</Header></Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Card>
    )

}

export default SupplierHeader;