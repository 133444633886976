import React from 'react';
// store 
import { routes } from '@store/routes'
// components
import { Grid, Header } from 'semantic-ui-react';
import ReportCard from './ReportCard';

const ReportSection = ({ isActive, reports, section, sectionName }) => {
    return (
        <>
            { isActive &&  
                <>
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header as="h2" content={sectionName}/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        { reports.map( (report, index) => (
                            <>
                                { report.section === section && 
                                    <ReportCard 
                                        key={index}
                                        linkTo={ report.isActive ? routes.REPORTS + report.slug + "/" : routes.REPORTS}
                                        category={ report.category }
                                        name={ report.name }
                                        isActive={report.isActive}
                                    />
                                }
                            </>
                        ))}
                    </Grid.Row>
                </>
            }  
        </>
    );
};

export default ReportSection;