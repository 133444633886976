import { APIRequest } from './helpers/requests';
import { API } from "../config";


export const publicService = {
    getLogo,
};


async function getLogo() {
    return await APIRequest({
        url: API.PUBLIC_LOGO,
        method: 'GET',
        data: {},
        private: false
    });
}