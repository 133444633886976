import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { patchRequest } from '@services/ServiceCommon';
// Components
import { Form } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperLabelField from '@components/forms/SuperLabelField';

const LabelsForm = ({ id, contactLabels, type, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const [labels, setLabels] = useState(contactLabels.map(item => item.id))
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)
        if (type === 'profile') {
            const request = await patchRequest(API.EMPLOYEES + id + '/?query={id, contact_labels}', {
                contact_labels: {
                    remove: contactLabels.map(item => item.id),
                    add: labels
                }
            })

            if (request.status === 200) {
                onConfirm(id, request.response.contact_labels, type)
                onClose()
            }
        }
        else {
            const request = await patchRequest(API.ACCOUNTS + id + '/', {
                tags: {
                    remove: contactLabels.map(item => item.id),
                    add: labels
                }
            })

            if (request.status === 200) {
                onConfirm(id, request.response.tags, type)
                onClose()
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperLabelField
                value={labels}
                onChange={(labels) => setLabels(labels)}
                endpoint={type === "profile" ? API.CONTACT_LABELS : API.ACCOUNTS_LABELS}
            />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default LabelsForm;