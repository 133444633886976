import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests, fileDownload } from '@helpers/requests';
// components 
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Button, Header, Icon, Label, Loader, Popup } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific
import StoredItems from '../../management/StoredItems';
import IssuedEmployeeItems from '../../cards/IssuedEmployeeItems';

const CardForm = ({ type, employee, items, setCards, onClose }) => {
    const { t } = useTranslation()
    const [issuedItems, setIssuedItems] = useState(items !== undefined ? items : [])
    const [verificationErrors, setVerificationErrors] = useState([])
    const [employers, setEmployers] = useState([])
    const [employees, setEmployees] = useState([])
    const [issueCard, setIssueCard] = useState({
        id: 0,
        status: type,
        employee_id: employee !== undefined ? employee : "",
        employer: null,
        employee: null,
        created_on: null
    })

    useEffect(() => {
        async function fetchEmployers(){
            const request = await requests.get(API.BUSINESS_DETAIL + "?is_employer=true")
            if (request.status === 200) {
                setEmployers(request.response.map(employer => ({ key: employer.id, text: employer.name, value: employer.id })))
                // if (request.response.length > 0) {
                //     setIssueCard(prev => ({...prev, employer: request.response[0].id}))
                // }
            }
        }
        fetchEmployers()
    }, [])

    useEffect(() => {
        async function fetchEmployees(){
            const request = await requests.get(API.EMPLOYEES + "?only_basic_info=true")
            if (request.status === 200) {
                setEmployees(request.response)
            }
        }

        fetchEmployees()
    }, [])

    const fetchUnit = async (id) => {
        let unit = undefined
        const request = await requests.get(API.UNITS + id + "/?query={id, name, business_detail{id}}")
        if (request.status === 200){
            unit = request.response
        }

        return unit
    }

    const [stage, setStage] = useState(0) // 0 - incialization, 1 - card create, 2 - items verification (stock check), 3 - final overview,

    const setProcessingItem = (storedItem, status) => {
        setIssuedItems(prevState => prevState.filter(item => {
            if( item.storedItem.id === storedItem.id ){
                item.isProcessing = status
            }

            return item
        }))
    }

    const setIssuedItemID = (storedItem, id) => {
        setIssuedItems(prevState => prevState.filter(item => {
            if( item.storedItem.id === storedItem.id ){
                item.id = id
            }

            return item
        }))
    }

    const showCard = async (id) => {
        await fileDownload("GET", API.API_URL + `/exports/issue_card_pdf_view/v2/${id}/?is_blob=true`, "application/pdf", `card_${id}.pdf`)
    }

    const handleSubmit = async () => {
        setVerificationErrors([])
        setStage(1) // card create
        let allowSkip = false;
        let cardID = 0
        if( issueCard.id !== 0 ) allowSkip = true;
        let requestCreateCard = undefined;

        if( !allowSkip ){
            requestCreateCard = await requests.post(API.ASSET_ISSUE_CARDS, {
                status: issueCard.status,
                employee: issueCard.employee_id,
                employer: issueCard?.employer || null
            })
        }

        if( (allowSkip || requestCreateCard !== undefined) && ( requestCreateCard?.status === 201 || allowSkip ) ){
            setStage(2) // item verification
            cardID = requestCreateCard === undefined ? issueCard.id : requestCreateCard?.response?.id
            setIssueCard(prevState => {
                return {
                    ...prevState,
                    id: cardID,
                    employee: requestCreateCard !== undefined ? requestCreateCard.response.employee : prevState.employee !== null ? prevState.employee : null,
                    created_on: requestCreateCard !== undefined ? requestCreateCard.response.created_on : prevState.created_on !== null ? prevState.created_on : null,

                }
            })

            if( !allowSkip && requestCreateCard?.status === 201 ){
                if( setCards !== undefined ){
                    setCards(prevState => {
                        return {
                            ...prevState,
                            data: {
                                ...prevState.data,
                                count: prevState.data.count + 1,
                                results: [requestCreateCard?.response, ...prevState.data.results]
                            }
                        }
                    })
                }
            }

            // Loop over list of issuedItems and perform create action.
            // If error occured for specific item store it to verification errors array and update view.
            for (let i = 0; i < issuedItems.length; i++) {
                if(issuedItems[i].id === 0){ // skip those that were created already
                    setProcessingItem(issuedItems[i].storedItem, true)
                    const requestIssuedItem = await requests.post(API.ASSET_ISSUED_ITEMS, {
                        employee: issueCard.employee_id,
                        issue_card: cardID,
                        quantity: issuedItems[i].quantity,
                        note: issuedItems[i].note !== "" ? issuedItems[i].note : null,
                        valid_until: issuedItems[i].valid_until !== "" ? issuedItems[i].valid_until : null,
                        stored_item: issuedItems[i].storedItem.id
                    })
    
                    if( requestIssuedItem.status === 201 ){
                        setIssuedItemID(issuedItems[i].storedItem, requestIssuedItem.response.id)
                    } else {
                        let message = "";
                        if( requestIssuedItem.response.quantity ){
                            message = requestIssuedItem.response.quantity[0]
                        } else if( requestIssuedItem.response.non_field_errors ){
                            message = requestIssuedItem.response.non_field_errors[0]
                        }
    
                        setVerificationErrors(prevState => {
                            return [...prevState, { 
                                storedItem: issuedItems[i].storedItem.id, 
                                message: message
                            }]
                        })
                    }
                    setProcessingItem(issuedItems[i].storedItem, false)
                }
            }
        } 

        if( cardID !== 0 || cardID !== undefined ){
            if( setCards !== undefined ){
                setCards(prevState => {
                    return {
                        ...prevState,
                        data: {
                            ...prevState.data,
                            results: prevState.data.results.filter(card => {
                                if( card.id === cardID ){
                                    card.item_count = issuedItems.filter(item => item.id !== 0).length
                                }
                                return card
                            })
                        }
                    }
                })
            }
        }

        setStage(3) // final overview
    }

    const RenderIssuedItemStatus = ({ item })=> {
        function containError(item){
            const error = verificationErrors.find(error => error.storedItem === item.storedItem.id) 
            return error === undefined ? false : error.message  
        }

        const errorMessage = containError(item)
        return (
            <>
            { errorMessage === false && item.id === 0 
                ? <Icon name="remove" style={{ color: "var(--danger)", cursor: "pointer" }} onClick={() => 
                    setIssuedItems(prevState => prevState.filter(issuedItem => issuedItem.storedItem.id !== item.storedItem.id))}
                />
                : errorMessage !== false
                    ?  <Popup simple trigger={<Icon name="warning circle" style={{ color: "var(--danger)" }}/>} content={errorMessage}/>
                    :  <Icon name="check circle" style={{ color: "var(--success)" }}/>
            }
            </>
        )
    }

    return (
        <>
            <Form>
                <Form.Group>
                    <SuperField as="choice"
                        search
                        required
                        width={12}
                        text="fullname"
                        label={t('employee')}
                        disabled={issueCard.id !== 0 || employee !== undefined}
                        customOptions={employees.map(employee => ({ key: employee.id, text: employee.fullname, value: employee.id }))}
                        value={issueCard.employee_id}
                        onChange={async (e, { value }) => {
                            let employer = ""
                            if( value === "" || type === 2 ){
                                setIssuedItems([])
                            } else {
                                // autocomplete employeer if possible
                                const employee = employees.find(employee => employee.id === value)
                                if (employee !== undefined) {
                                    if (employee.unit?.id !== undefined) { 
                                        const unit = await fetchUnit(employee.unit?.id)
                                        if (unit.business_detail?.id !== undefined) {
                                            employer = unit.business_detail?.id
                                        }
                                    }
                                }
                            }
                            setIssueCard({ ...issueCard, employee_id: value, employer: employer })
                        }}
                    />
                    <SuperField as="choice"
                        search
                        required
                        width={12}
                        label={t('employer')}
                        disabled={issueCard.id !== 0}
                        customOptions={employers}
                        value={issueCard.employer}
                        onChange={(e, { value }) => setIssueCard({ ...issueCard, employer: value })}
                    />
                    
                    <Form.Field style={{ textAlign: "right" }} width={4}>
                        <SuperDuperModal
                            dimmer={false}
                            size="large"
                            trigger={
                                <Button onClick={() => setStage(0)} type="button" disabled={issueCard.employee_id === ""} fluid primary style={{ marginTop: "1.5rem" }}>
                                    { t('select_items') }
                                </Button>
                            }
                            content={ type === 1 
                                ?
                                    <StoredItems 
                                        response={null}
                                        setResponse={null}
                                        issue={true}
                                        issuedItems={issuedItems}
                                        setIssuedItems={setIssuedItems}
                                    />
                                :   
                                    <IssuedEmployeeItems 
                                        employee={issueCard.employee_id}
                                        issuedItems={issuedItems}
                                        setIssuedItems={setIssuedItems}    
                                    />
                            }
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <Divider/>
            <Header as="h3" style={{ marginTop: "0.5rem" }} content={t('selected_items') + " ( " + issuedItems.length + " )" }/>
            <Divider/>
            <FlexTable>
                <FlexRow>
                    <FlexHeader basis="50%" content={t("code")}/>
                    <FlexHeader basis="150%" content={t("item_name")}/>
                    <FlexHeader basis="50%" content={t("quantity")}/>
                    { type === 1 && <FlexHeader basis="80%" content={t("valid_until")}/> }
                    <FlexHeader content={t("note")}/>
                    <FlexHeader basis="10%" textAlign="center" content={""}/>
                </FlexRow>
                <EmptyRow length={issuedItems.length}/>
                {
                    issuedItems.map(item => (
                        <FlexRow fontSize="0.9rem" borders key={item.storedItem.id}>
                            <FlexItem basis="50%">
                                { item.storedItem?.code || "" }
                            </FlexItem>
                            <FlexItem basis="150%" >
                                <strong>
                                { item.storedItem.name }
                                </strong> <br/> 
                                <small>
                                    { type === 1 && 
                                    <>
                                        {t('warehouse')}: <strong>{ item.storedItem.warehouse ? item.storedItem.warehouse : "--" }</strong> 
                                        {" | "} 
                                    </>
                                    }
                                    { type === 1 ? t('stock') : t('issued_on') }: <strong>{ item.storedItem.stock }</strong>
                                    { item.storedItem.sn !== null &&
                                        <>
                                            {" | "} 
                                            SN: <strong>{ item.storedItem.sn }</strong>
                                        </>
                                    }
                                </small>
                            </FlexItem>
                            <FlexItem basis="50%">
                                <SuperField size="small" as="input" type="number" min="0"
                                    fluid
                                    style={{ paddingRight: "1rem" }}
                                    disabled={ item.storedItem.sn !== null || item.isProcessing || item.id !== 0 }
                                    className={ parseInt(item.quantity) < 0 || (parseInt(item.storedItem.stock) < parseInt(item.quantity)) ? "error-input" : "" }
                                    error={ parseInt(item.quantity) < 0 }
                                    value={ item.quantity }
                                    onChange={(e, { value }) => setIssuedItems(prevState => prevState.filter(issuedItem => {
                                        if( issuedItem.storedItem.id === item.storedItem.id ){
                                            issuedItem.quantity = value
                                        }

                                        return issuedItem
                                    })) }
                                />
                            </FlexItem>
                            { type === 1 && 
                            <FlexItem basis="80%">
                                <SuperField size="small" as="datepicker"
                                    fluid
                                    style={{ paddingRight: "1rem" }}
                                    disabled={item.isProcessing || item.id !== 0}
                                    value={ item.valid_until }
                                    onChange={(e, { value }) => setIssuedItems(prevState => prevState.filter(issuedItem => {
                                        if( issuedItem.storedItem.id === item.storedItem.id ){
                                            issuedItem.valid_until = value
                                        }

                                        return issuedItem
                                    })) }
                                />
                            </FlexItem>
                            }
                            <FlexItem>
                                <SuperField size="small" as="input"
                                    fluid
                                    style={{ paddingRight: "1rem" }}
                                    disabled={item.isProcessing || item.id !== 0}
                                    value={ item.note }
                                    onChange={(e, { value }) => setIssuedItems(prevState => prevState.filter(issuedItem => {
                                        if( issuedItem.storedItem.id === item.storedItem.id ){
                                            issuedItem.note = value
                                        }

                                        return issuedItem
                                    })) }
                                />
                            </FlexItem>
                            <FlexItem basis="10%" textAlign="center">
                                { 
                                    item.isProcessing 
                                    ? <Loader size="small" className='dark-loader' active inline/>
                                    : <RenderIssuedItemStatus item={item}/> 
                                }
                                
                            </FlexItem>
                        </FlexRow>
                    ))
                }
            </FlexTable>

            <Divider/>
            <Form onSubmit={handleSubmit}>
                <Form.Group style={{ marginTop: "1rem" }}>
                    <Form.Field width={(stage !== 3 || verificationErrors.length > 0) ? 12 : 10} style={{ marginTop: "1rem" }}>
                        <Header as="h3">
                            { stage === 0 && 
                                <>
                                    {t('status')}: <Label size="large" basic>{t('status_initialization')}</Label>
                                </>
                            }
                            { stage === 1 && 
                                <>
                                    {t('status')}: <Label size="large" basic>{t('creating_card')} </Label> <Loader size="small" className='dark-loader' active inline/>
                                </>
                            }
                            { stage === 2 && 
                                <>
                                    {t('status')}: <Label size="large" basic>{t('stock_check')} </Label> <Loader size="small" className='dark-loader' active inline/>
                                </>
                            }
                            { stage === 3 && 
                                <>
                                    {t('status')}: <Label style={{ background: "var(--success)" }} size="large" basic>{t('final_overview')}</Label>
                                </>
                            }
                        </Header>
                    </Form.Field>
                    <Form.Field width={ (stage !== 3 || verificationErrors.length > 0) ? 4 : 6} style={{ textAlign: "right" }}>
                        { stage !== 3 || verificationErrors.length > 0
                            ? 
                                <Button fluid disabled={
                                    issueCard.employer === "" || issueCard.employer === null ||
                                    issuedItems.length === 0 || 
                                    (stage !== 0 && verificationErrors.length === 0) ||
                                    issuedItems.filter(item => parseInt(item.quantity) === 0).length > 0
                                } size="large" primary>
                                    <Icon name="clipboard outline"/> {t('save')}
                                </Button>
                            : 
                            <Button.Group widths={16} style={{ marginRight: "6.5rem" }}>
                                <Button type="button" onClick={onClose} style={{ background: "var(--dark)", color: "var(--white)" }}>
                                    {t('close')}
                                </Button>
                                <Button type="button" fluid style={{ background: "var(--info)", color: "var(--white)" }} onClick={() => showCard(issueCard.id)}>
                                    <Icon name="print"/> {t('print_preview')}
                                </Button>
                            </Button.Group>
                                
                        }
                    </Form.Field>
                </Form.Group>
            </Form>
        </>
    );
};

export default CardForm;