import React from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { getParams } from '@helpers/functions';
import { getRequest, deleteRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Grid, Divider, Button, Segment } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific components
import HRProcessForm from '../components/forms/HRProcessForm';

const HiringProcessesListView = () => {
    const { t } = useTranslation()

    const baseParams =  "?paginate=true&process_type=1"
    const [processes, setProcesses] = useFetchData(API.PROCESSES + baseParams)

    const previous = getParams(processes?.data?.previous)
    const next = getParams(processes?.data?.next)

    const fetchProcesses = async (params) => {
        params = params || ""

        setProcesses(prevState => ({ ...prevState, isLoading: true }))
        const request = await getRequest(API.PROCESSES + baseParams + params);
        if (request.status === 200) {
            setProcesses(prevState => ({
                ...prevState,
                isLoading: false,
                data: request.response
            }))
        }
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.PROCESSES + id + "/",);

        if( request.status === 204 ){
            setProcesses(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    count: prevState.data.count - 1,
                    results: prevState.data.results.filter( item => item.id !== id )
                }
            }))
        }
    }

    return (
        <>
            {/* Header section */}
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        {/* <Button.Group basic size='small' style={{ marginLeft: '1rem', borderRadius: 0 }}>
                            <Button style={{ color: 'var(--primary)' }} active={active === true} onClick={() => setActive(true)}>{t('active')}</Button>
                            <Button style={{ color: 'var(--primary)' }} active={active === false} onClick={() => setActive(false)}>{t('closed')}</Button>
                        </Button.Group> */}
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16} style={{ textAlign: "right" }}>
                        <Button.Group size="small" style={{ borderRadius: 0 }}>
                            <CanView permissions={["processes.c_manage_processes"]}>
                                <SuperDuperModal
                                    trigger={
                                        <Button primary content={t('add_process')} size="small" />
                                    }
                                    size='large'
                                    header={t('add_process')}
                                    content={
                                        <HRProcessForm
                                            setProcesses={setProcesses}
                                        />
                                    }
                                />
                            </CanView>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>

            {/* Table */}
            <Segment loading={processes.isLoading} style={{ padding: 0, margin: 0, minHeight: "2rem",backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
                { !processes.isLoading &&
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader content={t('name')} basis="250%"/>
                            <FlexHeader content={t('stages')}/>
                            <FlexHeader content={t('status')}/>
                            <FlexHeader content={t('actions')} basis="50%"/>
                        </FlexRow>
                        <EmptyRow length={processes.data?.results?.length}/>
                        { processes.data?.results.map( process => (
                            <FlexRow key={process.id} fontSize="1rem" borders >
                                <FlexItem bold basis="250%"> { process.name } </FlexItem>
                                <FlexItem> { process.stages.length } </FlexItem>
                                <FlexItem> 
                                    <Icon name="ellipse" style={{ color: process.is_active ? "var(--success)" : "var(--danger)", marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        { process.is_active ? t('active') : t('inactive') } 
                                    </span>
                                </FlexItem>
                                <FlexItem basis="50%"> 
                                    <FlexRow padding="0" background="transparent">
                                        <CanView permissions={['processes.c_manage_processes']}>
                                            <FlexItem>
                                                <SuperDuperModal
                                                    trigger={
                                                        <Icon name="pencil-outline" style={{ cursor: "pointer" }}/>
                                                    }
                                                    size='large'
                                                    header={t('update_process')}
                                                    content={
                                                        <HRProcessForm
                                                            process={process}
                                                            setProcesses={setProcesses}
                                                        />
                                                    }
                                                />
                                            </FlexItem>
                                        </CanView>
                                        <CanView permissions={['processes.c_delete_processes']}>
                                            <FlexItem>
                                                <ConfirmModal
                                                    description={t('are_you_sure')}
                                                    onConfirm={() => onDelete(process.id)}
                                                    button={
                                                        <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }}/>
                                                    }
                                                />
                                            </FlexItem>
                                        </CanView>
                                    </FlexRow>
                                </FlexItem>
                            </FlexRow>
                        ))}
                    </FlexTable>
                }
            </Segment>

            {/* Pagination */}
            { (!processes.isLoading && processes.data?.count > 10) &&
                <FlexRow>
                    <FlexItem basis="50%">
                        <Button.Group>
                            <Button disabled={ previous === null } primary onClick={ previous !== null ? () => fetchProcesses(previous) : null }> <Icon name="angle double left"/> </Button>
                            <Button disabled={ next === null } primary onClick={ next !== null ? () => fetchProcesses(next) : null }> <Icon name="angle double right"/> </Button>
                        </Button.Group>
                    </FlexItem>
                </FlexRow>
            }
        </>
    );
};

export default HiringProcessesListView;