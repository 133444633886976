import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { toOptionsLabels, toOptionsFromRedux } from '@store/services/helpers/utils';

import { Button, Form } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField';


const ActionArea = ({ handleFilters, onClose, data_filters }) => {
    const { t } = useTranslation();

    const priorities = useSelector((state) => state.choices.priority_choices)
    const status = useSelector((state) => state.choices.case_status_choices)
    const [labelOptions, setLabelOptions] = useState([])
    const [labelLoading, setLabelLoading] = useState(true)

    const [filters, setFilters] = useState(data_filters ? data_filters : {
        status: '',
        priority: '',
        name: '',
        type: '',
        label: []
    })
    useEffect(() => {
        async function fetchOptions() {
            setLabelLoading(true)
            let result = await getRequest(API.CASES_LABELS)
            if (result.status === 200) {
                setLabelOptions(toOptionsLabels(result.response))
            }
            setLabelLoading(false)
        }
        fetchOptions()
    }, [])

    return (
        <Form>
            <SuperField
                label={t("type")}
                as='choice'
                text="title"
                endpoint={API.CASES_TYPES}
                value={filters.type}
                onChange={(e, { value }) => setFilters({ ...filters, type: value })}
            />
            <SuperField
                label={t("find-by-title")}
                as='input'
                placeholder={t("write-title")}
                value={filters.name}
                onChange={(e, { value }) => setFilters({ ...filters, name: value })}
            />
            <SuperField
                label={t("priority")}
                as='choice'
                customOptions={toOptionsFromRedux(priorities)}
                value={filters.priority}
                onChange={(e, { value }) => setFilters({ ...filters, priority: value })}
            />
            <SuperField
                label={t("status")}
                as='choice'
                customOptions={toOptionsFromRedux(status)}
                value={filters.status}
                onChange={(e, { value }) => setFilters({ ...filters, status: value })}
            />
            <SuperField
                multiple
                loading={labelLoading}
                label={t("labels")}
                as='choice'
                customOptions={labelOptions}
                value={filters.label}
                onChange={(e, { value }) => setFilters({ ...filters, label: value })}
            />
            <Button content={t("reset")} onClick={
                () => {
                    handleFilters({
                        status: '',
                        priority: '',
                        name: '',
                        type: '',
                        label: []
                    })
                    onClose()
                }} />
            <Button primary content={t("confirm")} onClick={
                () => {
                    handleFilters(filters)
                    onClose()
                }} />
        </Form>
    );
};

export default ActionArea;