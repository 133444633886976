import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// components
import { Label, Icon, Dropdown, Popup } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import NewLabel from '@components/general/NewLabel';
import AvatarIcon from '@components/AvatarIcon';
import IssueDetail from './IssueDetail';
import IssueForm from '../forms/IssueForm';

import { patchRequest, getRequest } from '@services/ServiceCommon';
import { API } from '@store/config';
import { routes } from '@store/routes';

import { dates } from '@store/services/helpers/dates';

const ListView = ({ stats, setIssues, issues, setStats, filtered, state, setIsLoading }) => {
    const { t } = useTranslation();
    const params = useParams();

    const manageIssuesStatus = async (id, status, level, state) => {
        setIsLoading(true)
        const result = await patchRequest(API.PROJECT_ISSUES + id + '/', { state: status });
        if (result.status === 200) {
            const stats = await getRequest(API.PROJECTS_ISSUE_STATS + "stats/" + filtered.query);
            if (stats.status === 200) {
                setStats((prev) => ({ ...prev, data: stats.response }));
            }
            if (level === 0) {
                const list = await getRequest(API.PROJECT_ISSUES + '?project=' + params.id + '&state=' + state);
                if (list.status === 200) {
                    setIssues((prev) => ({ ...prev, data: list.response }));
                    setIsLoading(false)
                    return
                }
            }
            setIssues((prevState) => {
                return {
                    ...prevState,
                    data: handleIssuesAfterUpdate(prevState.data, result.response),
                };
            });
        }
        setIsLoading(false)
    };

    const [openedIssues, setOpenedIssues] = useState([]);
    function reformatIssueListUpdate(actual, response) {
        return actual.filter((item) => {
            if (item.id === response.id) return false;
            if (item.sub_issues.length > 0) item.sub_issues = reformatIssueListUpdate(item.sub_issues, response);
            return item;
        });
    }
    function addResponseToList(actual, response) {
        if (response.parent) {
            return actual.filter((item) => {
                if (item.id === response.parent) {
                    item.sub_issues.push(response);
                    return item;
                } else if (item.sub_issues.length > 0) {
                    addResponseToList(item.sub_issues, response);
                }
                return item;
            });
        }
        return actual.concat([response]);
    }
    function handleIssuesAfterUpdate(actual, response) {
        return addResponseToList(reformatIssueListUpdate(actual, response), response);
    }
    function renderSubIssues(list) {
        return list.map((item, key) => <IssueItem key={key} issue={item} />);
    }
    function formatIssueList() {
        let new_list = issues
        if (!filtered.process) new_list = new_list.filter((item) => !item.parent)
        if (state < 3) new_list = new_list.filter(item => item.state === state)
        return addLevelToList(new_list, 0);
    }
    function addLevelToList(actual, level) {
        return actual.filter((item) => {
            if (item.sub_issues?.length > 0) {
                item.sub_issues = addLevelToList(item.sub_issues, level + 1);
            }
            item.level = level;
            return item;
        });
    }
    function checkSubIssuesState(list, type) {
        let counter = 0;
        if (list.length > 0) {
            for (let item of list) {
                if (item.state === 2) ++counter;
            }
        }
        if (type === 'percent') return `${Math.round((counter / list.length) * 100)}%`;
        return `${counter}/${list.length}`;
    }

    const IssueItem = ({ issue }) => {
        return (
            <>
                <div style={{ paddingLeft: `${issue.level}rem`, display: 'flex', alignItems: 'center' }}>
                    {issue.level > 0 && (
                        <Icon
                            style={{ color: 'var(--primary)' }}
                            name={`${issue.state === 2 ? 'check ' : ''}${issue.level % 2 === 0 ? 'square' : 'circle'} outline`}
                        />
                    )}
                    <FlexRow fontSize="1rem" borders>
                        <FlexItem basis="200%">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {issue.level === 0 && state === 3 &&
                                    <Icon
                                        style={{ color: 'var(--primary)', marginRight: '0.2rem' }}
                                        name={`${issue.state === 2 ? 'check ' : ''}${issue.level % 2 === 0 ? 'square' : 'circle'} outline`}
                                    />
                                }
                                <IssueDetail issue={issue} />
                            </div>
                            <div>
                                #{issue.number} - {t('created')}
                                <Popup
                                    content={
                                        <>
                                            <Icon name="time" />
                                            {moment(dates.convertUTCDateToLocalDate(issue.created_on)).format(
                                                'MMMM Do YYYY, HH:mm:ss'
                                            )}
                                        </>
                                    }
                                    position="top center"
                                    inverted
                                    trigger={
                                        <span style={{ fontWeight: 'bold', color: 'var(--primary)' }}>
                                            {' '}
                                            {moment(dates.convertUTCDateToLocalDate(issue.created_on))
                                                .startOf('second')
                                                .fromNow()}{' '}
                                        </span>
                                    }
                                />
                                {t('by')}{' '}
                                <Link to={routes.EMPLYOEES_DETAIL + issue.created_by?.profile_id}> {issue.created_by?.name} </Link>
                                {/* Milestone */}
                                {issue.milestone !== null && (
                                    <span style={{ paddingLeft: '1rem' }} title="">
                                        <Icon name="time" /> {issue.milestone?.name}
                                    </span>
                                )}
                                {/* List of Labels */}
                                <span style={{ marginLeft: '1rem' }}>
                                    {issue.labels.map(({ name, color }, key) => (
                                        <NewLabel name={name} color={color} key={key} />
                                    ))}
                                </span>
                            </div>
                        </FlexItem>
                        {issue.sub_issues.length > 0 && (
                            <FlexItem basis="20%">{checkSubIssuesState(issue.sub_issues, 'percent')}</FlexItem>
                        )}
                        <FlexItem basis="60%" textAlign="right">
                            {issue.updated_by && (
                                <span style={{ float: 'right', marginLeft: '1rem' }}>
                                    <AvatarIcon
                                        size={25}
                                        name={issue.updated_by?.name}
                                        src={issue.updated_by?.avatar}
                                        uploadable={false}
                                    />
                                </span>
                            )}
                            {t('last_updated')}
                            <Popup
                                content={
                                    <>
                                        <Icon name="time" />
                                        {moment(issue.updated_on).format('MMMM Do YYYY, h:mm:ss')}
                                    </>
                                }
                                position="top center"
                                inverted
                                trigger={
                                    <span style={{ fontWeight: 'bold', color: 'var(--primary)' }}>
                                        {' '}
                                        {moment(issue.updated_on).startOf('day').fromNow()}{' '}
                                    </span>
                                }
                            />
                            {t('by')}
                        </FlexItem>
                        <FlexItem basis="20%" textAlign="center">
                            <Dropdown
                                simple
                                pointing="top right"
                                icon={<Icon className="toggle-button" name="ellipsis vertical" />}
                            >
                                <Dropdown.Menu>
                                    <SuperDuperModal
                                        size="small"
                                        closeIcon
                                        trigger={
                                            <Dropdown.Item>
                                                {' '}
                                                <strong> {t('update')} </strong>{' '}
                                            </Dropdown.Item>
                                        }
                                        content={
                                            <IssueForm issueAsProps={issue} employees={stats} setIssues={setIssues} />
                                        }
                                    />
                                    {issue.state === 1 ? (
                                        <Dropdown.Item
                                            onClick={() => manageIssuesStatus(issue.id, 2, issue.level, issue.state)}
                                        >
                                            {' '}
                                            {t('close')}{' '}
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item
                                            onClick={() => manageIssuesStatus(issue.id, 1, issue.level, issue.state)}
                                        >
                                            {' '}
                                            {t('reopen')}{' '}
                                        </Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </FlexItem>
                        {issue.sub_issues?.length > 0 && (
                            <>
                                {!openedIssues.find((x) => x === issue.id) ? (
                                    <Label
                                        style={{ position: 'absolute', right: '1rem', cursor: 'pointer' }}
                                        onClick={() => setOpenedIssues([...openedIssues, issue.id])}
                                    >
                                        <Icon name="angle down" />
                                        {issue.sub_issues?.length}
                                    </Label>
                                ) : (
                                    <Label
                                        style={{ position: 'absolute', right: '1rem', cursor: 'pointer' }}
                                        onClick={() => setOpenedIssues(openedIssues.filter((z) => z !== issue.id))}
                                    >
                                        <Icon name="angle up" />
                                        {issue.sub_issues?.length}
                                    </Label>
                                )}
                            </>
                        )}
                    </FlexRow>
                </div>

                {openedIssues.find((x) => x === issue.id) && renderSubIssues(issue.sub_issues)}
            </>
        );
    };

    // List View - JSX Template
    return (
        <FlexTable stripped={false}>
            <FlexRow padding="0" background="transparent" />
            {formatIssueList().map((item, key) => (
                <IssueItem issue={item} key={key} />
            ))}
        </FlexTable>
    );
};

export default ListView;
