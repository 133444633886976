import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// store
// eslint-disable-next-line
import { routes } from '@routes';
import { API } from '@store/config';
import { createRequest, getRequest, patchRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import { Form, Button, Divider, Header } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';

const ConnectionLoginForm = ({ connection, setRefetch }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line
    const history = useHistory()
    const [isProcessing, setIsProcessing] = useState(false)
    const [invalidLogin, setInvalidLogin] = useState(false)
    const [form, setForm] = useState({
        username: "",
        password: ""
    })

    const [selectedJournals, setSelectedJournals] = useState([])
    const [connectedUser, setConnectedUser] = useState(null)
    const [loginConnection, setLoginConnection] = useState(connection !== undefined ? connection : null)

    const handleSubmit = async () => {
        setIsProcessing(true)
        setInvalidLogin(false)
        const connectionParams = connection !== undefined ? "?connection=" + connection.connection_id : ""
        const request = await createRequest(API.INVOICES_LOGIN + connectionParams, form)
        if( request.status === 200 ){
            setConnectedUser(request.response.user)
            if( loginConnection === null ){
                const requestConnection = await getRequest(API.INVOICES_CONNECTIONS)
                if( requestConnection.status === 200 ) {
                    setLoginConnection(requestConnection.response[0])
                }
            }
            // history.push(routes.INVOICES)
            // setRefetch(true)
        } else {
            setInvalidLogin(true)
        }

        setIsProcessing(false)
    }

    
    const handleJournalAllow = async () => {
        // !! TODO: add support to remove existing allowed accounting journal if exists
        setIsProcessing(true)
        const request = await patchRequest(API.INVOICES + "invoice_connection/" + loginConnection.id + "/", {
            accounts: {
                create: selectedJournals.map(journal => {
                    return { 
                        name: journal.account_name,
                        id_number: journal.account_id_number,
                        account_id: journal.account_id
                    }
                } )
            }
        })
        if( request.status === 200 ){
            history.push(routes.INVOICES)
            setRefetch(true)
        }
        setIsProcessing(false)
    }

    return (
        <>
        { connectedUser === null 
        ?
            <Form onSubmit={handleSubmit}>
                <SuperField as="input"
                    required
                    autoFocus
                    icon="user"
                    iconPosition="left"
                    label={t('username')}
                    value={ form.username }
                    placeholder={t('username')}
                    error={ invalidLogin ? t('invalid_login') : null }
                    onChange={ (e, { value }) => setForm({...form, username: value})}
                />
                <SuperField as="input" type="password"
                    required
                    icon="user"
                    iconPosition="left"
                    label={t('password')}
                    value={ form.password }
                    placeholder={t('password')}
                    error={ invalidLogin ? t('invalid_login') : null }
                    onChange={ (e, { value }) => setForm({...form, password: value})}
                />
                <Form.Field>
                    <Button 
                        primary
                        fluid
                        size="large"
                        disabled={ form.username === "" || form.password === "" || isProcessing }
                        loading={isProcessing}
                    >
                        { t('login') }
                    </Button>
                </Form.Field>
            </Form>
        :
            <div>
                <p style={{ textAlign: "center" }}>
                    <strong style={{ fontSize: "1.2rem" }}>{ t('you_have_been_successfully_verified') }</strong> <br/>
                    { t('please_select_which_accounting_journal_would_you_like_to_allow') }
                </p>
                <Divider/>

                <Header as="h3" content={t('Accounting journals') + "(" + ( connectedUser.owned_accounts.length + connectedUser.allowed_accounts.length ) + ")"}/>
                <FlexTable>
                    <FlexRow background="transparent" borders>
                        <FlexHeader basis="30%"/>
                        <FlexHeader> { t('name') } </FlexHeader>
                        <FlexHeader> { t('id_number') }</FlexHeader>
                    </FlexRow>
                    { connectedUser.owned_accounts.map(journal => (
                        <FlexRow background="transparent" fontSize="0.9rem" borders key={journal.account_id}>
                            <FlexItem basis="30%" textAlign="center">
                                <SuperField as="checkbox"
                                    checked={ selectedJournals.filter(item => item.account_id === journal.account_id).length > 0 }
                                    onChange={ (e) => {
                                        if( selectedJournals.find(item => item.account_id === journal.account_id) ){ // remove
                                            setSelectedJournals(prevState => prevState.filter(item => item.account_id !== journal.account_id))
                                        } else { // add
                                            setSelectedJournals(prevState => [...prevState, journal])
                                        }
                                    }}
                                />
                            </FlexItem>
                            <FlexItem bold> { journal.account_name } </FlexItem>
                            <FlexItem> { journal.account_id_number } </FlexItem>
                        </FlexRow>
                    )) }
                    { connectedUser.allowed_accounts.map(journal => (
                        <FlexRow background="transparent" padding="0" fontSize="0.9rem" borders key={journal.account_id}>
                            <FlexItem basis="30%">
                                <SuperField as="checkbox"
                                    checked={ selectedJournals.filter(item => item.account_id === journal.account_id).length > 0 }
                                    onChange={ (e) => {
                                        if( selectedJournals.find(item => item.account_id === journal.account_id) ){ // remove
                                            setSelectedJournals(prevState => prevState.filter(item => item.account_id !== journal.account_id))
                                        } else { // add
                                            setSelectedJournals(prevState => [...prevState, journal])
                                        }
                                    }}
                                />                                           
                            </FlexItem>
                            <FlexItem bold> { journal.account_name } </FlexItem>
                            <FlexItem> { journal.account_id_number } </FlexItem>
                        </FlexRow>
                    )) }
                    
                </FlexTable>

                <Divider/>
                <Form.Field style={{ textAlign: "left" }}>
                    <Button 
                        loading={isProcessing}
                        disabled={isProcessing}
                        primary
                        type="button"
                        content={t('confirm')}
                        onClick={() => handleJournalAllow()}
                    />
                </Form.Field>
            </div>
        }
        </>
    );
};

export default ConnectionLoginForm;