import React from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
// components
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Grid } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const FilterAction = ({ text, isActive, ...rest }) => {
    return (
        <span className={"filter-action " + (isActive && "active")}  {...rest}>
            {text}
        </span>
    )
}

const PlannedCoursesFilters = ({ filter, setFilter }) => {
    const { t } = useTranslation();

    return (
        <Grid verticalAlign="middle">
            <Grid.Row style={{ padding: 0 }}>
                <Grid.Column computer={4} tablet={16} mobile={16}>
                    <FlexRow padding="0.5rem">
                        <FlexItem>
                            <FilterAction isActive={filter.isOpened} onClick={() => setFilter({ ...filter, isOpened: !filter.isOpened })} text={t('opened')} />
                            <FilterAction isActive={filter.isClosed} onClick={() => setFilter({ ...filter, isClosed: !filter.isClosed })} text={t('closed')} />
                        </FlexItem>
                    </FlexRow>
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right">
                    <SuperField fluid as="datepicker"
                        placeholder={t('date_start')}
                        value={filter.dateStart}
                        onChange={(e, { value }) => setFilter({ ...filter, dateStart: value })}
                    />
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right">
                    <SuperField fluid as="datepicker"
                        placeholder={t('date_end')}
                        value={filter.dateEnd}
                        onChange={(e, { value }) => setFilter({ ...filter, dateEnd: value })}
                    />
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right">
                    <SuperField fluid as="choice"
                        search
                        text="title"
                        value={filter.course}
                        endpoint={API.COURSES + "?query={id, title}"}
                        onChange={(e, { value }) => setFilter({ ...filter, course: value })}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default PlannedCoursesFilters;