import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import moment from 'moment';
import { Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';

const RecordForm = ({ record, setRecords, onClose }) => {
    const { t } = useTranslation();

    const [data, setData] = useState({
        course: record ? record.course.id : null,
        person: record ? record.person.id : null,
        made_on: record ? record.made_on : '',
        valid_from: record ? record.valid_from : '',
        valid_until: record ? record.valid_until : '',
        failed: record ? record.failed : false,
    });

    const [isFailed, setIsFailed] = useState(data.failed);
    const [isProcessing, setIsProcessing] = useState(false);
    const [invalidDateRange, setInvalidDateRange] = useState(false);
    const [courseIsMandatory, setCourseIsMandatory] = useState(false);

    // Validate Form
    useEffect(() => {
        if (data.valid_until !== '' && data.valid_from) {
            if (moment(data.valid_from).isAfter(data.valid_until)) {
                setInvalidDateRange(t('invalid_date_should_be_higher'));
            } else {
                setInvalidDateRange(false);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    const fetchCourse = async (id) => {
        if( id !== null && id > 0 ){ // avoid calling API when no course is being selected 
            const request = await requests.get(API.COURSES + id + '/?query={id, is_mandatory}');
        
            if (request.status === 200) {
                setCourseIsMandatory(request.response.is_mandatory);
            }
        }
    };

    useEffect(() => {
            fetchCourse(data.course);
    }, [data.course]);

    const onSubmit = async () => {
        setIsProcessing(true);

        const dataForSubmittion = {
            ...data,
            made_on: data.made_on !== '' ? data.made_on : null,
            valid_from: data.valid_from !== '' ? data.valid_from : null,
            valid_until: data.valid_until !== '' ? data.valid_until : null,
        };

        let request = null;
        if (record?.id) {
            request = await requests.put(API.COURSES_RECORDS + record.id + '/', dataForSubmittion);
        } else {
            request = await requests.post(API.COURSES_RECORDS, dataForSubmittion);
        }

        if (request.status === 201) {
            setRecords(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count + 1,
                    results: [request.response, ...prev.data.results]
                }
            }));
        } else if (request.status === 200) {
            setRecords(prev => ({
                ...prev, 
                data: {
                    ...prev.data, 
                    results: prev.data.results.map(item => {
                        if (item.id === record.id) {
                            item = request.response
                        }
        
                        return item;
                    })
                }
            }))
        }

        onClose();
        setIsProcessing(false);
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group widths="equal">
                <SuperField
                    search
                    as="choice"
                    required
                    label={t('courses')}
                    endpoint={API.COURSES + '?query={id, title}'}
                    value={data.course}
                    text="title"
                    onChange={(e, { value }) => setData({ ...data, course: value })}
                />

                <SuperField
                    search
                    as="choice"
                    required
                    label={t('employees')}
                    endpoint={API.EMPLOYEES + '?only_basic_info=true'}
                    value={data.person}
                    text="fullname"
                    onChange={(e, { value }) => setData({ ...data, person: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    required
                    label={t('made_on')}
                    value={data.made_on}
                    onChange={(e, { value }) => setData({ ...data, made_on: value })}
                />
                <SuperField
                    as="datepicker"
                    required={courseIsMandatory}
                    label={t('valid_from')}
                    value={data.valid_from}
                    onChange={(e, { value }) => setData({ ...data, valid_from: value })}
                />
                <SuperField
                    as="datepicker"
                    required={courseIsMandatory}
                    label={t('valid_until')}
                    error={invalidDateRange}
                    value={data.valid_until}
                    onChange={(e, { value }) => setData({ ...data, valid_until: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="checkbox"
                    label={t('failed')}
                    checked={data.failed}
                    onChange={(e) => {
                        setIsFailed(!isFailed);
                        setData({ ...data, failed: !isFailed });
                    }}
                />
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose}
                    text={t('cancel')}
                />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || data.course === '' || data.participants === 0}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    );
};

export default RecordForm;
