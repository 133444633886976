import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// store 
import { isEmpty } from "@services/helpers/validation";
// components
import { Form, Divider, Button } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import AddressFieldGroup from '@components/forms/common/AddressFieldGroup';

const AddressFillForm = ({ onClose, address, updateState, addressType }) => {
    const { t } = useTranslation()
    const countries = useSelector((state) => state.choices.countries)

    const [addr, setAddr] = useState({
        street: (address && address.street) || '',
        city: (address && address.city) || '',
        postcode: (address && address.postcode) || '',
        country:  (address && address.country) || '',
        state: (address && address.state) || '',
        addressLine: (address && address.address_line) || '',
    });

    const handleSubmit = async () => {
        updateState(prevState => ({
            ...prevState,
            [addressType]: {
                street: addr.street,
                city: addr.city,
                postcode: addr.postcode,
                country: addr.country,
                country_display: countries?.[addr.country],
                state: addr.state,
                address_line: addr.addressLine,
            }
        }))
        onClose()
    }

    return (
        <Form>
            <AddressFieldGroup  address={addr} setAddress={setAddr}/>
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <Button type="button" primary
                    onClick={handleSubmit}
                    content={t('confirm')}
                    disabled={ isEmpty(addr.postcode) ||  isEmpty(addr.street) || isEmpty(addr.city) || isEmpty(addr.country)}
                />
            </Form.Field> 
        </Form>
    );
};

export default AddressFillForm;