import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { useSelectOptions } from '@helpers/hooks';
import { setDotSeparator } from '@helpers/functions';
import { isEmpty } from "@store/services/helpers/validation";
// eslint-disable-next-line
import { createRequest, patchRequest, updateRequest, deleteRequest } from '@services/ServiceCommon';
// components 
import Icon from '@components/Icon';
import { Form, Divider, Button, Accordion, Header, Loader } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import AccordionItem from '@components/AccordionItem';

const EmploymentOrderForm = ({ order, setResult, onClose }) => {
    const { t } = useTranslation()
    
    const [employees] = useSelectOptions(API.EMPLOYEES + "?only_basic_info=true&is_active=true", "fullname")
    const [showAdditionalInformation, setShowAdditionalInformation] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        name: order !== undefined ? order.name : "",
        orderNumber: order !== undefined ? order.order_number : "",
        paymentMethod: order !== undefined ? order.payment_option : "",
        orderManager: order !== undefined ? order.order_manager?.id : "",
        teams: order !== undefined ? order.assigned_teams.map(item => item.id) : [],
        assignedEmployees: order !== undefined ? order.assigned_to.map(item => item.id) : [],
        account: order !== undefined ? order.account?.id : "",
    })

    const [items, setItems] = useState(order !== undefined 
        ? order.items.map(item => ({ 
            id: item.id,
            processing: false,
            error: null,
            profile: item.profile.id,
            sum: item.fee_per_hour.sum,
            currency: item.fee_per_hour.currency
        }))
        : []
    )

    const addItem = () => {
        setItems(prevState => [...prevState, { 
            id: 0,
            processing: false,
            error: null,
            profile: "",
            sum: 0,
            currency: "EUR"
        }])
    }

    const onItemChange = (index, updatedKey, value) => {
        setItems(prevState => prevState.filter((record, key) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const onItemRemove = async (item, index) => {
        onItemChange(index, "processing", true)
        if( item.id === 0 ){
            setItems(prevState => prevState.filter((stateItem, key) => key !== index))
        } else {
            const request = await deleteRequest(API.ORDERS + "employment_items/" + item.id)
            if( request.status === 204 ){
                setItems(prevState => prevState.filter(stateItem => stateItem.id !== item.id))
            }
        }
        onItemChange(index, "processing", false)
    }

    const handleSubmit = async () => {
        setIsProcessing(true)

        // handle item submit
        for (let i = 0; i < items.length; i++) {
            // validate empty rows (inline-fields)
            if( items[i].id === 0 && items[i].profile === "" && items[i].amount === "") {
                continue;
            } 

            // update or create items
            onItemChange(i, "processing", true)
            let request = undefined
            const data = {
                profile: items[i].profile,
                fee_per_hour: {
                    sum: items[i].sum,
                    currency: items[i].currency
                },
            }
            if( items[i].id === 0 ){ // create item
                request = await createRequest(API.ORDERS + "employment_items/", data)
            } else { // update item
                request = await updateRequest(API.ORDERS + "employment_items/" + items[i].id + "/", data)
                
            }
            
            if( request !== undefined ){
                if( request.status === 201 ){
                    onItemChange(i, "id", request.response.id)
                }
                
                if( request.status === 201 || request.status === 200 ){
                    // update item state
                    onItemChange(i, "error", null)
                } else {
                    // set error
                    onItemChange(i, "error", request.response)
                }
            }

            onItemChange(i, "processing", false)
        }

        // handle order submit
        let oldItemsToRemove = []
        if( order?.items?.length > 0 ){
            for (let i = 0; i < order.items.length; i++) {
                if( items.filter(item => item.id === order.items[i].id).length > 0 ){
                    oldItemsToRemove.push(order.items[i].id)
                }
            }
        }
        const orderDataset = {
            name: form.name,
            order_number: form.orderNumber,
            order_manager: form.orderManager,
            payment_option: form.paymentMethod !== "" ? form.paymentMethod : null,
            account: form.account,
            assigned_teams: {
                remove: order?.assigned_teams ? order.assigned_teams.map(item => item.id) : [],
                add: form.teams
            },
            assigned_to: {
                remove: order?.assigned_to ? order.assigned_to.map(item => item.id): [],
                add: form.assignedEmployees
            },
            items: {
                add: items.length > 0 ? items.map(item => item.id) : [],
            },
            resourcetype: "EmploymentOrder",
            order_type: 2
        }
        
        if( items.filter(item => item.error !== null).length === 0 ){
            let request = undefined
            if( order === undefined ){
                delete orderDataset.assigned_teams.remove
                delete orderDataset.assigned_to.remove
                request = await createRequest(API.ORDERS, orderDataset)
            } else {
                request = await patchRequest(API.ORDERS + order.id + "/", orderDataset)
            }
    
            if( request !== undefined){
                if(request.status === 201 || request.status === 200){
                    if( request.status === 201 ){ // created
                        setResult(prevState => ({
                            ...prevState,
                            data: {
                                ...prevState.data,
                                count: prevState.data.count + 1,
                                results: [request.response, ...prevState.data.results]
                            }
                        }))
                    }else if( request.status === 200 ){ // updated
                        setResult(prevState => ({
                            ...prevState,
                            data: {
                                ...prevState.data,
                                results: prevState.data.results.filter(item => {
                                    if( item.id === request.response.id ){
                                        item.name = request.response.name
                                        item.account = request.response.account
                                        item.order_manager = request.response.order_manager
                                        item.order_number = request.response.order_number
                                        item.payment_option = request.response.payment_option
                                        item.payment_option_display = request.response.payment_option_display
                                        item.order_status = request.response.order_status
                                        item.order_status_display = request.response.order_status_display
                                        item.items = request.response.items
                                    }
    
                                    return item
                                })
                            }
                        }))
                    }
    
                    onClose()
                }
            }
        }


        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('name')}
                    value={form.name}
                    onChange={(e, { value }) => setForm({ ...form, name: value })}
                />
                <SuperField as="input"
                    required
                    label={t('order_number')}
                    value={form.orderNumber}
                    onChange={(e, { value }) => setForm({ ...form, orderNumber: value })}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField
                    search
                    as="choice"
                    endpoint={API.ACCOUNTS + "?query={id, name}&is_active=true&is_supplier=false"}
                    text="name"
                    label={t('account')}
                    value={form.account}
                    onChange={(e, { value }) => setForm({ ...form, account: value })}
                />
                <SuperField
                    as="choice"
                    type="payment_option_choices"
                    label={t('payment_option')}
                    value={form.paymentMethod?.toString()}
                    onChange={(e, { value }) => setForm({ ...form, paymentMethod: value })}
                />
            </Form.Group>

            <Divider/>
            <Accordion as={Form.Field} fluid style={{ borderRadius: 0 }}>
                <AccordionItem 
                    index={0}
                    isActive={showAdditionalInformation}
                    onClick={ () => setShowAdditionalInformation(!showAdditionalInformation) }
                    title={t('additional_information')}
                >
                    <CanView permissions={["orders.c_assign_order_manager"]}>
                        <Form.Group widths='equal'>
                            <SuperField
                                as="choice"
                                search
                                disabled={employees.isLoading}
                                loading={employees.isLoading}
                                customOptions={employees.options}
                                label={t('order_manager')}
                                value={form.orderManager}
                                onChange={(e, { value }) => setForm({ ...form, orderManager: value })}
                            />
                        </Form.Group>
                    </CanView>

                    <Form.Group widths='equal'>
                        <SuperField
                            as="choice"
                            multiple
                            search
                            endpoint={API.TEAMS + "?only_basic_info=true"}
                            text="name"
                            label={t('teams')}
                            value={form.teams}
                            onChange={(e, { value }) => setForm({ ...form, teams: value })}
                        />
                        <SuperField
                            as="choice"
                            multiple
                            search
                            disabled={employees.isLoading}
                            loading={employees.isLoading}
                            customOptions={employees.options}
                            label={t('assigned_employees')}
                            value={form.assignedEmployees}
                            onChange={(e, { value }) => setForm({ ...form, assignedEmployees: value })}
                        />
                    </Form.Group>
                </AccordionItem>
            </Accordion>

            <Header as="h3" content={t('ordered_people') + " ("+ items.length +")"}/>
            <Divider/>
            
            {/* Headers */}
            { items.length > 0 && 
                <Form.Group widths='equal'>
                    <Form.Field width={6}>
                        <strong>{t('employee')}</strong>
                    </Form.Field>
                    <Form.Field width={4}>
                        <strong>{t('fee')}</strong>
                    </Form.Field>
                    <Form.Field width={6}>
                        <strong>{t('currency')}</strong>
                    </Form.Field>
                    <Form.Field width={4}/>
                </Form.Group>
            }

            {/* Inline-Fields */}
            { items.map((item, index) => (
                <Form.Group widths='equal' key={index}>
                    <SuperField as="choice"
                        search
                        width={6}
                        disabled={employees.isLoading}
                        loading={employees.isLoading}
                        customOptions={employees.options}
                        value={item.profile}
                        onChange={(e, { value }) => onItemChange(index, "profile", value)}
                    />
                    <SuperField as="input"
                        width={4}
                        value={item.sum}
                        error={item.error?.fee_per_hour !== undefined}
                        title={item.error?.fee_per_hour}
                        onChange={(e, { value }) => onItemChange(index, "sum", setDotSeparator(value))}
                    />
                    <SuperField as="choice"
                        search
                        width={6}
                        type="currency_codes"
                        value={item.currency}
                        onChange={(e, { value }) => onItemChange(index, "currency", value)}
                    />
                    <Form.Field width={4} style={{ textAlign: "center" }}>
                        { item.processing 
                            ? <Loader size="small" className='dark-loader' active inline/> 
                            : 
                            <>
                                { item.error === null && 
                                    <Icon 
                                        name="close-outline"
                                        onClick={ () => onItemRemove(item, index) }
                                        style={{ marginTop: "1rem", color: "var(--danger)", cursor: "pointer" }}
                                    />
                                }

                                { item.error !== null && 
                                    <Icon 
                                        name="warning-outline" 
                                        style={{ marginTop: "1rem", color: "var(--danger)", cursor: "pointer" }} 
                                    />
                                }
                            </>
                        }
                    </Form.Field>
                </Form.Group>
            )) }

            <Button type="button" onClick={ () => addItem() } primary size="small" content={t('add')}/>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    type="button"
                    style={{ backgroundColor: "var(--variant5)" }}
                    onClick={onClose}
                    disabled={isProcessing}
                >
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    style={{ backgroundColor: "var(--success)" }}
                    disabled={
                        isProcessing ||
                        isEmpty(form.name) ||
                        isEmpty(form.orderNumber)
                    }
                    loading={isProcessing}
                >
                    {t('save')}
                </Button>
            </Form.Field>
        </Form>
    );
};

export default EmploymentOrderForm;