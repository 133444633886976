import { APIRequest, URLManager } from './helpers/requests';
import { API } from "../config";


export const timesheetsService = {
    getTimesheets,
    getTimesheet,
    deleteTimesheet,
    createTimesheet,
    updateTimesheet,
    getRecords,
    createRecord,
    updateRecord,
    deleteRecord,
};


async function getTimesheets(params) {
    params = params || "";
    return await APIRequest({
        url: API.TIMESHEETS + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getRecords(params) {
    params = params || "";
    return await APIRequest({
        url: API.TIMESHEETS + 'records/' + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function createRecord(data) {
    return await APIRequest({
        url: API.TIMESHEETS + 'records/',
        method: 'POST',
        data: data,
        private: true
    });
}

async function updateRecord(data, id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TIMESHEETS + 'records/', id),
        method: 'PUT',
        data: data,
        private: true
    });
}

async function deleteRecord(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TIMESHEETS + 'records/', id),
        method: 'DELETE',
        data: {},
        private: true
    });
}

async function createTimesheet(data) {
    return await APIRequest({
        url: API.TIMESHEETS,
        method: 'POST',
        data: data,
        private: true
    });
}

async function getTimesheet(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TIMESHEETS, id),
        method: 'GET',
        data: {},
        private: true
    });
}

async function deleteTimesheet(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TIMESHEETS, id),
        method: 'DELETE',
        data: {},
        private: true
    });
}

async function updateTimesheet(data, id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TIMESHEETS, id),
        method: 'PUT',
        data: data,
        private: true
    });
}