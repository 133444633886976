import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { dates } from '@helpers/dates';
import { requests } from '@helpers/requests';
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import FlexRowWithToggle from '@components/FlexRowWithToggle';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexItem, FlexRow } from '@components/tables/FlexTable';
import { Container, Segment, Button, Grid, Form, Header, Divider, Checkbox } from 'semantic-ui-react';

const PermissionRow = ({ name, description, value, onChange, rule, disabled }) => {
    return (
        <FlexRow key={rule} borders>
            <FlexItem>
                <Header as='h4'>
                    <Header.Content>
                        { name }
                        <Header.Subheader>
                            { description || "" }
                        </Header.Subheader>
                    </Header.Content>
                </Header>
            </FlexItem>
            <FlexItem basis="20%" textAlign="right">
                <Checkbox toggle disabled={disabled} checked={value || false} onChange={() => onChange(!value)} />
            </FlexItem>
        </FlexRow>
    )
}

const FinancePermissionForm = ({ allowedUser, setAllowedUsers }) => {
    const { t } = useTranslation()

    const canManage = useHasPermissions(['integrations.c_manage_all_bank_account_allowed_users'])

    const handlePermissionChange = async (value, rule) => {
        const request = await requests.patch(API.INVOICES + "bank_accounts/allowed_users/" + allowedUser.id  + "/", {
            [rule]: value
        })

        if (request.status === 200) {
            setAllowedUsers(prev => prev.filter(user => {
                if (user.id === allowedUser.id) {
                    user[rule] = value
                }
    
                return user
            }))
        }
    }

    return (
        <>
            <PermissionRow
                name={t('can_view_account_balances')}
                description={""}
                value={allowedUser.can_view_account_balances}
                rule={"can_view_account_balances"}
                disabled={!canManage}
                onChange={ ( value ) => handlePermissionChange(value, "can_view_account_balances") }
                />
            <PermissionRow
                name={t('can_view_account_transactions')}
                description={""}
                value={allowedUser.can_view_account_transactions}
                rule={"can_view_account_transactions"}
                disabled={!canManage}
                onChange={ ( value ) => handlePermissionChange(value, "can_view_account_transactions") }
                />
            <PermissionRow
                name={t('can_view_account_payments')}
                description={""}
                value={allowedUser.can_view_account_payments}
                disabled={!canManage}
                rule={"can_view_account_payments"}
                onChange={ ( value ) => handlePermissionChange(value, "can_view_account_payments") }
                />
            <PermissionRow
                name={t('can_manage_account_payments')}
                description={""}
                value={allowedUser.can_manage_account_payments}
                disabled={!canManage}
                rule={"can_manage_account_payments"}
                onChange={ ( value ) => handlePermissionChange(value, "can_manage_account_payments") }
                />
            <PermissionRow
                name={t('can_cancel_account_payments')}
                description={""}
                value={allowedUser.can_cancel_account_payments}
                disabled={!canManage}
                rule={"can_cancel_account_payments"}
                onChange={ ( value ) => handlePermissionChange(value, "can_cancel_account_payments") }
            />
        </>
    )
}

const GrantAccessForm = ({ allowedUsers, selectedBank, setAllowedUsers, onClose }) => {
    const { t } = useTranslation()
    
    const [processing, setProcessing] = useState(false)
    const [errors, setErrors] = useState(null)
    const [form, setForm] = useState({
        account_id: selectedBank,
        can_view_account_balances: false,
        can_view_account_transactions: false,
        can_view_account_payments: false,
        can_manage_account_payments: false,
        can_cancel_account_payments: false,
        user: ""
    })

    const handlePermissionChange = (value, key) => {
        setForm(prev => ({
            ...prev,
            [key]: value
        }))
    }

    const handleSubmit = async () => {
        setProcessing(true)

        const request = await requests.post(API.INVOICES + "bank_accounts/allowed_users/", form)
        if (request.status === 201) {
            setAllowedUsers(prev => ([request.response ,...prev]))
            onClose()
        } else if (request.status === 400) {
            setErrors(request.response)
        }

        setProcessing(false)
    }

    return (
        <Form onSubmit={() => handleSubmit()}>
            <SuperField as="choice"
                endpoint={API.USERS + "?query{id, name}"}
                text="name"
                label={t('user')}
                value={form.user}
                error={errors?.user || false}
                exclude={allowedUsers.map(item => item.user.id)}
                onChange={(e, { value }) => setForm(prev => ({...prev, user: value}))}
            />
            <Divider/>

            <PermissionRow
                name={t('can_view_account_balances')}
                description={""}
                value={form.can_view_account_balances}
                rule={"can_view_account_balances"}
                disabled={false}
                onChange={ ( value ) => handlePermissionChange(value, "can_view_account_balances") }
                />
            <PermissionRow
                name={t('can_view_account_transactions')}
                description={""}
                value={form.can_view_account_transactions}
                rule={"can_view_account_transactions"}
                disabled={false}
                onChange={ ( value ) => handlePermissionChange(value, "can_view_account_transactions") }
                />
            <PermissionRow
                name={t('can_view_account_payments')}
                description={""}
                value={form.can_view_account_payments}
                disabled={false}
                rule={"can_view_account_payments"}
                onChange={ ( value ) => handlePermissionChange(value, "can_view_account_payments") }
                />
            <PermissionRow
                name={t('can_manage_account_payments')}
                description={""}
                value={form.can_manage_account_payments}
                disabled={false}
                rule={"can_manage_account_payments"}
                onChange={ ( value ) => handlePermissionChange(value, "can_manage_account_payments") }
                />
            <PermissionRow
                name={t('can_cancel_account_payments')}
                description={""}
                value={form.can_cancel_account_payments}
                disabled={false}
                rule={"can_cancel_account_payments"}
                onChange={ ( value ) => handlePermissionChange(value, "can_cancel_account_payments") }
            />
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={processing}/>
                <ModalSubmit content={t('grant_access')} disabled={processing || form.user === ""}/>
            </Form.Field>
        </Form>
    )
}

const FinanceACL = () => {
    const { t } = useTranslation()
    const [bankAccounts, setBankAccounts] = useState([])
    const [selectedBank, setSelectedBank] = useState("")
    const [allowedUsers, setAllowedUsers] = useState([])
    const [connection, setConnection] = useState(null)
    const [premiumConnection, setPremiumConnection] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingAllowedUsers, setLoadingAllowedUsers] = useState(false)

    const reduxUser = useSelector(state => state.user)

    useEffect(() => {
        async function fetchConnections(){
            const request = await requests.get(API.INVOICES_CONNECTIONS)
            if( request.status === 200 ){
                if( request.response.length > 0 ){
                    setConnection(request.response[0])

                    // attempt to fetch connection for Premium API:
                    const requestPremiumConnections = await requests.get(API.INVOICES + "banking/tb/premium/connected/?connection=" + request.response[0].connection_id)
                    if( requestPremiumConnections.status === 200 ){
                        if( expiresIn(requestPremiumConnections.response.expires_in, dates.convertUTCDateToLocalDate(requestPremiumConnections.response.authorized_when)) < 2 ){ // if expiration is less then 2 days refresh
                            const refreshPremiumConnection = await requests.post(API.INVOICES + "banking/tb/premium/refresh/?connection=" + request.response[0].connection_id)

                            if( refreshPremiumConnection.status === 200 ){
                                const refetchPremiumConnection = await requests.get(API.INVOICES + "banking/tb/premium/connected/?connection=" + request.response[0].connection_id)

                                if( refetchPremiumConnection.status === 200 ){
                                    setPremiumConnection(requestPremiumConnections.response)
                                    await fetchBankAccounts(request.response[0].connection_id)
                                }
                            }
                        } else {
                            setPremiumConnection(requestPremiumConnections.response)
                            await fetchBankAccounts(request.response[0].connection_id)
                        }
                        
                    }
                }
            }

            setTimeout(function() {
                setLoading(false)
            }, 500);
        }

        fetchConnections()
        // eslint-disable-next-line
    }, [reduxUser])

    useEffect(() => {

        async function fetchAllowedUsers(selectedBank){
            setLoadingAllowedUsers(true)
            const request = await requests.get(API.INVOICES + "bank_accounts/allowed_users/?account_id=" + selectedBank)
            if (request.status === 200) {
                setAllowedUsers(request.response)
            }
            setLoadingAllowedUsers(false)
        }

        if (selectedBank !== "") {
            // fetch allowed users for this bank
            fetchAllowedUsers(selectedBank)
        }
    }, [selectedBank])

    const onAccessRemove = async (allowedUser) => {
        const request = await requests.del(API.INVOICES + "bank_accounts/allowed_users/" + allowedUser.id + "/")

        if (request.status === 204) {
            setAllowedUsers(prev => prev.filter(user => user.id !== allowedUser.id))
        }
    }

    const fetchBankAccounts = async (connection_id) => {
        const request = await requests.get(API.INVOICES+ "banking/tb/premium/accounts/?connection=" + connection_id)
        if( request.status === 200 ){
            setBankAccounts(request.response?.accounts || [])
            if (request.response?.accounts?.length > 0) {
                setSelectedBank(request.response?.accounts?.[0]?.accountId || "")
            }
        }
    }

    const expiresIn = (expiresInSeconds, authorizedDate) => {
        const expiresInDate = moment(authorizedDate, "YYYY-MM-DD HH:mm:ss").add(expiresInSeconds, "seconds")
        const currentDate = moment(moment().format("YYYY-MM-DD HH:mm:ss"))

        return expiresInDate.diff(currentDate, "days")
    }

    const handlePremiumConnection = async () => {
        const domain = window.location.href
        const request = await requests.get(API.INVOICES + "banking/tb/premium/authorize/?connection=" + connection.connection_id + "&domain=" + domain)

        if( request.status === 200 ){
            window.location.href = request.response.authorize_url;
        }
    }

    const determinateAccess = (allowedUser) => {
        if ( 
            allowedUser.can_manage_account_payments === true && 
            allowedUser.can_cancel_account_payments === true &&
            allowedUser.can_view_account_balances === true &&
            allowedUser.can_view_account_transactions === true &&
            allowedUser.can_view_account_payments === true
        ){
            return t('full_access')
        }

        if ((allowedUser.can_view_account_balances === true || allowedUser.can_view_account_transactions === true || allowedUser.can_view_account_payments === true) && (allowedUser.can_manage_account_payments === false && allowedUser.can_cancel_account_payments === false) ){
            return t('viewer_access')
        }

        if ( allowedUser.can_manage_account_payments === true || allowedUser.can_cancel_account_payments === true ){
            return t('manager_access')
        }

        return t('no_access')
    }


    return (
        <CanView permissions={['integrations.c_view_all_bank_account_allowed_users']} redirect>
            <Container fluid>
                <Segment 
                    loading={loading}
                    style={{ 
                        padding: 0, 
                        background: "transparent", 
                        boxShadow: "none", 
                        border: "none",
                        marginBottom: "1rem",
                        marginTop: "1rem",
                    }}
                >
                    { (loading) && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_performing_verification') } </p> }

                    {/* If Connection is not found, then factoring service is not properly connected */}
                    { (connection === null && !loading) && 
                        <div style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold", paddingTop: "2rem" }}>
                            <Icon name="alert-circle-outline" style={{ fontSize: "5rem", color: "var(--danger)" }}/> <br/>
                            <p style={{ marginBottom: "0.5rem" }}> { t('error_hint_message_no_active_connection_part_1') } </p>
                            <p>{ t('error_hint_message_no_active_connection_part_2') } <Link to={routes.INVOICES} style={{ fontWeight: "bold" }}><u> { t('error_hint_message_no_active_connection_part_3') }</u></Link></p>
                        </div>
                    }

                    { (premiumConnection === null && connection !== null && !loading) && 
                        <div style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold", paddingTop: "2rem" }}>
                            <Icon name="alert-circle-outline" style={{ fontSize: "5rem", color: "var(--danger)" }}/> <br/>
                            <p style={{ marginBottom: "0.5rem" }}> { t('error_hint_message_no_active_bank_account_part_1') } </p>
                            <Button onClick={ () => handlePremiumConnection() } style={{ marginTop: "0.5rem" }} primary content={ t('connect_your_bank_accounts') }/>
                            <p style={{ fontSize: "1rem", fontWeight: "normal", marginTop: "1rem" }}> { t('error_hint_message_no_active_bank_account_part_2') } </p>
                        </div>
                    }

                    {/* If Connection is active, then render views */}
                    { (connection !== null && premiumConnection !== null && !loading) &&
                        <div>
                            <Grid>
                                <Grid.Row style={{ padding: 0 }} verticalAlign="middle">
                                    <Grid.Column computer={4} tablet={4} mobile={16}>
                                        <Header as="h3" content={t('finance_access_control')}/>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right" computer={12} tablet={12} mobile={16}>
                                        <Form>
                                            <Form.Group style={{ float: "right" }}>
                                                <SuperField inline label={t('select_bank_account')} as="choice"
                                                    value={selectedBank}
                                                    onChange={(e, { value}) => setSelectedBank(value)}
                                                    customOptions={bankAccounts.map((bank, index) => ({ key: index, value: bank.accountId, text: bank.displayName + " - " + bank.accountReference.iban }))}
                                                />
                                                <Button onClick={ () => handlePremiumConnection() } style={{ background: "var(--dark)", color: "var(--white)" }} content={ t('add_bank_account') }/>
                                                <CanView permissions={['integrations.c_manage_all_bank_account_allowed_users']}>
                                                    <SuperDuperModal
                                                        header={t('grant_finance_access')}
                                                        trigger={
                                                            <Button primary content={t('grant_access')}/>
                                                        }
                                                        content={
                                                            <GrantAccessForm
                                                                allowedUsers={allowedUsers}
                                                                setAllowedUsers={setAllowedUsers}
                                                                selectedBank={selectedBank}
                                                            />
                                                        }
                                                    />
                                                    
                                                </CanView>
                                            </Form.Group>
                                        </Form>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider/>
                            <Segment 
                                loading={loadingAllowedUsers}
                                style={{ 
                                    padding: 0, 
                                    background: "transparent", 
                                    boxShadow: "none", 
                                    border: "none",
                                }}
                            >
                                { loadingAllowedUsers && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('loading_allowed_users') } </p> }
                                { !loadingAllowedUsers && 
                                    <div>
                                        <EmptyRow length={allowedUsers.length}/>
                                        { allowedUsers.map(allowedUser => (
                                            <FlexRowWithToggle
                                                background={"var(--light)"}
                                                key={allowedUser.id}
                                                loading={false}
                                                rowContent={
                                                    <>
                                                        <FlexItem>
                                                            <div>{ t('user') }</div>
                                                            <strong> { allowedUser.user.name } </strong><br/>
                                                        </FlexItem>
                                                        <FlexItem>
                                                            <div>{ t('granted_access') }</div>
                                                            <strong> { determinateAccess(allowedUser) } </strong><br/>
                                                        </FlexItem>
                                                        <FlexItem>
                                                            <div>{ t('account_id') }</div>
                                                            <strong> { allowedUser.account_id } </strong><br/>
                                                        </FlexItem>
                                                    </>
                                                }
                                                content={
                                                    <Container fluid style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                                                        <FinancePermissionForm allowedUser={allowedUser} setAllowedUsers={setAllowedUsers} />
                                                        <CanView permissions={['integrations.c_delete_all_bank_account_allowed_users']}>
                                                            <Divider/>
                                                            <Form.Field style={{ textAlign: "right" }}>
                                                                <Button type="button" style={{ color: "var(--white)", background: "var(--danger)" }} size="small" content={t('remove_access')} onClick={() => onAccessRemove(allowedUser)}/>
                                                            </Form.Field>
                                                        </CanView>
                                                    </Container>
                                                }
                                            />
                                        ))}
                                    </div>
                                }
                            </Segment>

                        </div>
                    }
                </Segment>
            </Container>
        </CanView>
    );
};

export default FinanceACL;