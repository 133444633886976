import React from 'react';
import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';
import PDFFilePreview from '@components/PDFFilePreview';
import SuperDuperModal from '@components/modals/SuperDuperModal';


const CVCell = ({ row }) => {
    const { t } = useTranslation();

    return (
        <>
            { row.cv
                &&
                <>
                    <SuperDuperModal
                        size="small"
                        content={<PDFFilePreview title={t('preview')} file={row.cv}/>}
                        trigger={
                            <Icon style={{ color: "var(--danger)", cursor: "pointer",  fontSize: "2rem" }} name="document-outline"/>
                        }
                    />
                    <a href={row.cv} download={row.cv} target="_blank" rel="noopener noreferrer">
                        <Icon style={{ cursor: "pointer",  fontSize: "1.2rem", position: "relative", top: "-0.4rem", marginLeft: "1rem" }} name="download-outline"/>
                    </a>
                </>
            }
        </>
    );
};

export default CVCell;