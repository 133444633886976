import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
// store
import { routes } from '@routes'
// components
import Icon from '@components/Icon';
import { Button, Sidebar, Segment, Grid } from 'semantic-ui-react';
import PositionAdvancedFilter from '../../candidates/components/PositionAdvancedFilter';

const PositionFilterSection = ({ fetchJobPositions, filters, setFilters }) => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false)

    return (
        <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
            <PositionAdvancedFilter
                visible={visible}
                setVisible={setVisible}
                fetchJobPositions={fetchJobPositions}
                filters={filters}
                setFilters={setFilters}
            />
            <Sidebar.Pusher>
                <Grid columns="2">
                    <Grid.Column />
                    <Grid.Column textAlign='right' >
                        <Button.Group>
                            <Button
                                size="small"
                                style={{
                                    background: "transparent",
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem"
                                }}
                                onClick={() => setVisible(!visible)}
                            >
                                <Icon name="funnel-outline" style={{ margin: 0, fontSize: "1.2rem" }} />
                            </Button>
                            <Button style={{ width: "15rem" }} as={Link} to={routes.CREATE_JOB_POSITION} fluid primary> {t('create_position')} </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

export default PositionFilterSection;