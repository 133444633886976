import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { Form, Header, Button, Divider, List } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const AccountsFilterForm = ({ filters, setFilters, fetchAccounts }) => {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()

    const [fetchingLabels, setFetchingLabels] = useState(false)
    const [labelOptions, setLabelOptions] = useState([])

    const fetchLabels = async () => {

        setFetchingLabels(true)

        const request = await getRequest(API.ACCOUNTS_LABELS + "?query={id, name}&paginate=true")

        if (request.status === 200) {
            const accountLabels = request.response.results.map(item => ({ key: item.id + "__" + item.name, value: item.name, text: item.name }))

            setLabelOptions([...accountLabels])
        }
        setFetchingLabels(false)
    }

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchAccounts("", true)
    }

    useEffect(() => {
        fetchLabels()
    }, [])

    return (
        <Form onSubmit={() => handleSubmit()} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>

            <Divider />

            <SuperField as="input"
                icon="search"
                iconPosition="left"
                label={t('search')}
                placeholder={t('search')}
                value={filters?.search}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    search: value
                })}
            />

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('id_number')}
                    width={8}
                    value={filters?.identification_number}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        identification_number: value
                    })}
                />
                <SuperField as="input"
                    label={t('dit_number')}
                    width={8}
                    value={filters?.dit_number}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        dit_number: value
                    })}
                />
                <SuperField as="input"
                    label={t('vat_number')}
                    width={10}
                    value={filters?.vat_number}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        vat_number: value
                    })}
                />
            </Form.Group>

            <SuperField as="choice"
                search
                text="name"
                label={t('unit')}
                value={filters?.unit}
                onChange={ (e, {value}) => setFilters({ ...filters, unit: value }) }
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
            />

            <SuperField as="choice"
                search
                multiple
                disabled={fetchingLabels}
                loading={fetchingLabels}
                customOptions={labelOptions}
                label={t('labels')}
                value={filters?.labels}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    labels: value
                })}
            />

            <SuperField as="checkbox"
                label={t('invoice_approval') + " - " + t('automatic')}
                width={10}
                checked={filters?.approve_automatically}
                onChange={() => setFilters({
                    ...filters,
                    approve_automatically: !filters.approve_automatically
                })}
            />

            <SuperField as="checkbox"
                label={t('is_active')}
                width={10}
                checked={filters?.is_active}
                onChange={() => setFilters({
                    ...filters,
                    is_active: !filters.is_active
                })}
            />

            <Divider />
            <Form.Field>
                <Button.Group>
                    <Button size="small" primary> {t('apply')} </Button>
                    {/* <Button type="button" size="small"> {t('reset')} </Button> */}
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default AccountsFilterForm;