import React from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
// store
import { routes } from '@routes'
// components
import Icon from '@components/Icon';
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Button } from 'semantic-ui-react'
import JobFilterForm from './forms/JobFilterForm'
import CanView from '@components/perms/CanView';

const FilterAction = ({ text, isActive, ...rest }) => {
    return (
        <span className={"filter-action " + (isActive && "active") }  {...rest}>
            { text }
        </span>
    )
}

const JobFilterSection = ({ fetchJobOffers, selectedFilter, setSelectedFilter, result, filters, setFilters }) => {
    const { t } = useTranslation();

    return (
        <FlexRow padding="0.5rem">
            <FlexItem basis="300%">
                <FilterAction isActive={selectedFilter === 1} onClick={ () => setSelectedFilter(1) } text={t('all')  + " (" + (result.data.draft_count + result.data.published_count) + ")"}/>
                <FilterAction isActive={selectedFilter === 2} onClick={ () => setSelectedFilter(2) } text={t('drafts')  + " (" + result.data.draft_count + ")"}/>
                <FilterAction isActive={selectedFilter === 3} onClick={ () => setSelectedFilter(3) } text={t('published')  + " (" + result.data.published_count + ")"}/>
            </FlexItem>
            <FlexItem>
                <FlexRow>
                    <FlexItem basis="20%">
                            <SuperDuperModal
                                trigger={
                                    <Icon name="funnel-outline" style={{ cursor: "pointer", marginLeft: "1.5rem", marginRight: "1.5rem" }}/>
                                }
                                content={
                                    <JobFilterForm
                                        fetchJobOffers={fetchJobOffers}
                                        selectedFilter={selectedFilter}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />
                                }
                            />
                    </FlexItem>
                    <FlexItem>
                        <CanView permissions={["jobs.c_manage_job_offers"]}>
                            <Button style={{ width: "15rem" }} as={Link} to={routes.CREATE_JOB_OFFER} fluid primary> { t('create_job_offer') } </Button>
                        </CanView>
                    </FlexItem>
                </FlexRow>
            </FlexItem>
        </FlexRow>
    );
};

export default JobFilterSection;