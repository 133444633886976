import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { createRequest, updateRequest} from '@services/ServiceCommon';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

/* TODO: Save & Add another feature */
const ItemForm = ({ setResponse, onClose, item, setItems }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        title: item?.title ? item.title : "",
        description: item?.description ? item.description : "",
        is_vehicle: item?.is_vehicle === undefined ? false : item.is_vehicle,
        lifetime_in_months: item?.lifetime_in_months ? item.lifetime_in_months : 0,
        category: item?.category ? item.category.id : null
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        if( item?.id ){ // update
            const request = await updateRequest(API.ASSET_ITEMS + item.id + "/", {
                ...formData,
                lifetime_in_months: formData.lifetime_in_months === "" ? 0 : formData.lifetime_in_months,
                category: formData.category !== "" ? formData.category : null
            })

            if( request.status === 200 ){
                setItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.filter(filteredItem => {
                            if( filteredItem.id === item.id ){
                                filteredItem.title = request.response.title;
                                filteredItem.description = request.response.description;
                                filteredItem.is_vehicle = request.response.is_vehicle;
                                filteredItem.lifetime_in_months = request.response.lifetime_in_months;
                                filteredItem.category = request.response.category;
                            }
                            
                            return filteredItem;
                        })
                    }
                }))
                onClose()
            }
        } else { // create
            const request = await createRequest(API.ASSET_ITEMS, {
                ...formData,
                lifetime_in_months: formData.lifetime_in_months === "" ? 0 : formData.lifetime_in_months,
                category: formData.category !== "" ? formData.category : null
            })
            
            if(request.status === 201){
                setResponse({
                    type: "item_create",
                    result: request.response
                })
                
                onClose()
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input"
                autoFocus
                label={t('title')}
                required
                value={formData.title}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    title: value
                }) }
            />

            <Form.Group widths="equal">
                <SuperField as="input"
                    type="number"
                    min="0"
                    label={t('lifetime_in_months')}
                    value={formData.lifetime_in_months}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        lifetime_in_months: value
                    }) }
                />
                <SuperField as="choice"
                    label={t('category')}
                    endpoint={API.ASSET_CATEGORIES + "?query={id, title}"}
                    text="title"
                    value={formData.category}
                    onChange={ (e, { value }) => setFormData({
                        ...formData,
                        category: value
                    }) }
                />
            </Form.Group>

            <SuperField as="checkbox"
                label={t('is_vehicle')}
                checked={formData.is_vehicle}
                onChange={ () => setFormData({
                    ...formData,
                    is_vehicle: !formData.is_vehicle
                }) }
            />

            {/* TODO: Add Rich Text Editor */}
            <SuperField as="textarea"
                label={t('description')}
                value={formData.description}
                onChange={ (e, { value }) => setFormData({
                    ...formData,
                    description: value
                }) }
            />

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || formData.title === ""}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default ItemForm;