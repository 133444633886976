import { APIRequest } from './helpers/requests';
import { API } from "../config";

/*
 * Service functions
 */
export const accountsService = {
    getAccounts,
    deleteAccount,
    uploadLogo,
    deleteLogo,
    updateAccount,
    createAccounts
};

async function getAccounts(params){
    params = params || ""
    return await APIRequest({
        url: API.ACCOUNTS + params ,
        method: "GET",
        data: {},
        private: true
    })
}
async function createAccounts(data){
    return await APIRequest({
        url: API.ACCOUNTS ,
        method: "POST",
        data: data,
        private: true
    })
}
async function uploadLogo(id,data){
    return await APIRequest({
        url: API.ACCOUNTS + id + '/logo/',
        method: "POST",
        data: data,
        private: true
    })
}
async function deleteLogo(id){
    return await APIRequest({
        url: API.ACCOUNTS + id + '/logo/',
        method: "DELETE",
        private: true
    })
}

async function deleteAccount(id){
    return await APIRequest({
        url: API.ACCOUNTS + id + '/',
        method: "DELETE",
        private: true
    })
}
async function updateAccount(id, data){
    return await APIRequest({
        url: API.ACCOUNTS + id + '/',
        method: "PATCH",
        data: data,
        private: true
    })
}