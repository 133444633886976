import React from 'react';
import { useTranslation } from "react-i18next";
import { routes } from '@routes/index';
import { toast } from 'react-semantic-toasts';
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import { Label } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
import EmptyRow from '@components/tables/EmptyRow';
import SkillsTooltip from '@components/general/SkillsToolip';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// specific components
import CVCell from './CVCell';
import PersonCell from './PersonCell';
import ContactCell from './ContactCell';
import CandidateHistory from '../../views/CandidateHistory';
import CandidateHistoryAction from './CandidateHistoryAction';
import CandidateLabelsForm from '../forms/CandidateLabelForm';


const CandidatesTable = ({ onDelete, candidates, setCandidates, onLabelsAdd, onLabelRemove }) => {
    const { t } = useTranslation();

    const convertToEmployee = async (id) => {
        const request = await requests.patch(API.CANDIDATES + id + "/convert/", {});

        if (request.status === 200) {
            setCandidates(prev => prev.filter(item => item.id !== id))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('convert_to_employee_success_message'),
                animation: 'bounce',
                time: 5000,
            });
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('error'),
                description: t('convert_to_employee_error_message'),
                animation: 'bounce',
                time: 5000,
            });
        }
    }

    return (
        <FlexTable>
            <FlexRow>
                <FlexHeader basis="80%" content={t('name')} />
                <FlexHeader basis="30%" content={t('gender')} />
                <FlexHeader basis="70%" content={t('contact')} />
                <FlexHeader basis="50%" content={t('skills')} />
                <FlexHeader basis="150%" content={t('labels')} />
                <FlexHeader basis="30%" content={t('cv')} />
                <FlexHeader basis="50%" content={t('actions')} />
            </FlexRow>
            <EmptyRow length={candidates.length} />
            {candidates?.map((candidate) => (
                <FlexRow key={candidate.id} fontSize="0.9rem" borders>
                    <FlexItem basis="80%" content={<PersonCell row={candidate} />} />
                    <FlexItem basis="30%" content={candidate.gender} />
                    <FlexItem basis="70%" content={<ContactCell row={candidate} />} />
                    <FlexItem basis="50%" content={<SkillsTooltip skills={candidate.skills} />} />
                    <FlexItem basis="150%">
                        {candidate.labels.map(label => (
                            <Label basic size="tiny" key={label.id}>
                                <Icon name="bookmark-outline" style={{ color: label.color === "" ? "" : label.color }} /> {label.name}
                                <CanView permissions={["common.c_manage_all_candidates"]}>
                                    <Icon name="close-outline" style={{ marginLeft: "0.5rem", cursor: "pointer" }} onClick={() => onLabelRemove(candidate.id, label.id)} />
                                </CanView>
                            </Label>
                        ))}
                        <CanView permissions={["common.c_manage_all_candidates"]}>
                            <SuperDuperModal
                                trigger={
                                    <Label basic size="tiny" style={{ cursor: "pointer" }}>
                                        <Icon name="add-outline" />
                                    </Label>
                                }
                                content={
                                    <CandidateLabelsForm
                                        id={candidate.id}
                                        candidateLabels={candidate.labels}
                                        onConfirm={onLabelsAdd}
                                    />
                                }
                            />
                        </CanView>
                    </FlexItem>
                    <FlexItem basis="30%" content={<CVCell row={candidate} />} />
                    <FlexItem basis="50%">
                        <FlexRow background="transparent" padding="0">
                            <FlexItem textAlign="center">
                                <Action type='icon' as="link" to={routes.CANDIDATES_CARD + candidate.id} tooltip={t('view_detail')} />
                            </FlexItem>
                            <CanView permissions={["common.c_manage_all_candidates"]}>
                                <FlexItem textAlign="center">
                                    <Action
                                        as="confirm"
                                        type="icon"
                                        icon="person-add-outline"
                                        iconColor="black"
                                        text={t('convert_to_emp_text')}
                                        tooltip={t('convert_to_employee')}
                                        onClick={() => convertToEmployee(candidate.id)}
                                    />
                                </FlexItem>
                            </CanView>
                            <CanView permissions={["candidates.c_view_history_records"]}>
                                <FlexItem textAlign="center">
                                    <SuperDuperModal
                                        trigger={
                                            <CandidateHistoryAction />
                                        }
                                        content={
                                            <CandidateHistory candidate={candidate.id} />
                                        }
                                    />
                                </FlexItem>
                            </CanView>
                            <CanView permissions={["common.c_delete_all_candidates"]}>
                                <FlexItem textAlign="center">
                                    <Action as='delete' tooltip={t('delete')} text={t('delete_candidate_message')} onClick={() => onDelete(candidate.id)} />
                                </FlexItem>
                            </CanView>
                        </FlexRow>
                    </FlexItem>
                </FlexRow>
            ))}
        </FlexTable>
    );
};

export default CandidatesTable;