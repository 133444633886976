import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from "@helpers/validation";
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
import { Form, Message } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import CustomFormDivider from '@components/general/CustomFormDivider';


const MilestoneForm = ({ onConfirm, onClose, project, milestone }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errors, setErrors] = useState(null);
    const [form, setForm] = useState({
        name: milestone?.name || null,
        description: milestone?.description || null,
        is_active: milestone?.is_active || null,
        from_date: milestone?.from_date || null,
        due_date: milestone?.due_date || null,
        project: project.id
    })

    const handleSubmit = async () => {
        setIsProcessing(true);
        if (milestone?.id === undefined) {
             const result = await requests.post(API.PROJECT_MILESTONES, form)

            if (result.status === 201) {
                onConfirm(result.response);
                onClose()
            } else {
                setErrors(result.response)
            }
            setIsProcessing(false);
        }
        else {
            const result = await requests.patch(API.PROJECT_MILESTONES + `${milestone?.id}/`, form)

            if (result.status === 200) {
                onConfirm(result.response);
                onClose()
            } else {
                setErrors(result.response)
            }
            setIsProcessing(false);
        }

    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />
            <SuperField as="input"
                required
                autoFocus
                label={t('name')}
                placeholder={t('name')}
                value={form.name}
                onChange={(e, { value }) =>
                    setForm({ ...form, name: value})
                }
            />
            <SuperField as="textarea"
                label={t('description')}
                placeholder={t('description')}
                value={form?.description}
                onChange={(e, { value }) =>
                    setForm({ ...form, description: value})
                }
            />
            <CustomFormDivider
                header = {t('milestone_span')}
                divider = {true}
                content = {
                    <>
                    <Form.Group widths="2">
                        <SuperField as="datepicker"
                            label={t('from_date')}
                            placeholder={t('from_date')}
                            error={ errors === null ? false : errors?.from_date }
                            value={form?.from_date}
                            onChange={(e, { value }) =>
                                { value === "" || value === null ?
                                setForm({ ...form, from_date: null}) :
                                setForm({ ...form, from_date: value})
                            }}
                        />
                        <SuperField as="datepicker"
                            label={t('due_date')}
                            placeholder={t('due_date')}
                            error={ errors === null ? false : errors?.due_date }
                            value={form?.due_date}
                            onChange={(e, { value }) =>
                                { value === "" || value === null ?
                                setForm({ ...form, due_date: null}) :
                                setForm({ ...form, due_date: value})
                            }}
                        />
                    </Form.Group>
                </>
                }
            />
            { milestone?.id &&
                <SuperField as="checkbox"
                    label={ milestone.is_active ? t('close') : t('open_btn') }
                    value={form?.is_active}
                    onChange={(e, { value }) => setForm({
                    ...form, is_active: !value
                    })}
                />
            }
            <br />
            <Form.Field style={{ textAlign: "right", margin: "1rem" }}>
                <ModalCancel
                    text={t("cancel")}
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    text={milestone?.id ? t("save") : t("create")}
                    disabled={
                        isProcessing ||
                        isEmpty(form.name)
                    }
                    loading={isProcessing}
                />
            </Form.Field>
        </Form>
    )
}

export default MilestoneForm;

