import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useHistory } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config'
import { useFetchData } from '@helpers/hooks';
import { useQueryPage } from '@helpers/hooks';
import { useLocation } from 'react-router-dom';
import { deleteRequest, getRequest, createRequest } from '@services/ServiceCommon';
// components
import SuperDuperModal from '@components/modals/SuperDuperModal'
import ConfirmModal from '@components/modals/ConfrimModal';
import Loading from '@components/general/Loading';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import { Label, Segment } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import Icon from '@components/Icon';
// module specific Components
import PositionPreview from './PositionPreview';
import PositionFilterSection from './PositionFilterSection';
import LaunchHiringRoom from './forms/LaunchHiringRoom';
import Paginator from '@components/Paginator';

const PositionTable = () => {
    const { t } = useTranslation();
    const history = useHistory()
    let page = useQueryPage()

    const [limit, setLimit] = useState(10)
    const [result, setResult] = useFetchData(API.JOB_POSITIONS + "?paginate=true&page=" + page + "&limit=" + limit)

    const [loading, setLoading] = useState(false)
    const [reFetched, setReFetched] = useState(false)

    const initState = {
        search: "",
        title: "",
        is_external: ""
    }

    const [filters, setFilters] = useState(initState)
    const location = useLocation()

    const onFilter = () => {
        let filterBaseParams = ""

        if (filters.title !== "") {
            filterBaseParams += "&title=" + filters.title
        }

        if (filters.is_external !== "") {
            filterBaseParams += "&is_external=" + filters.is_external
        }

        if (filters.search !== ""){
            filterBaseParams += "&search=" + filters.search
        }

        return filterBaseParams
    }

    const onDelete = async (id) => {

        const request = await deleteRequest(API.JOB_POSITIONS + id + "/",);

        if (request.status === 204) {
            const filteredData = result.data.results.filter(offer => offer.id !== id)

            setResult({
                ...result,
                data: {
                    ...result.data,
                    count: result.data.count - 1,
                    results: filteredData
                }
            })
        }
        else (
            alert(t('delete_this'))
        )
    }

    const fetchJobPositions = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if( !result.isLoading ){
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if ( !params.includes('page') ) {
                params += "&page=" + page
            }

            if ( !params.includes('limit')){
                params += "&limit=" + limit
            } 

            setResult(prevState => ({
                ...prevState,
                isLoading: true
            }))

            const request = await getRequest(API.JOB_POSITIONS + "?paginate=true" + params)
    
            if (request.status === 200) {
                setResult(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }

            setReFetched(false)
            setLoading(false)
        }
    }

    return (
        <>
            <PositionFilterSection 
                fetchJobPositions={fetchJobPositions} 
                filters={filters}
                setFilters={setFilters}
            />
            {result.isLoading ? <Loading /> : 
            (!reFetched && !loading) &&
            <Segment loading={reFetched || loading} basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }}>
                <FlexTable>
                    <FlexRow>
                        <FlexHeader content="#" basis="35%" />
                        <FlexHeader content={t('title')} basis="150%" />
                        <FlexHeader content={t('isco')} />
                        <FlexHeader content={t('contracts')} />
                        <FlexHeader content={t('type')} />
                        <FlexHeader content={t('actions')} />
                    </FlexRow>

                    {result.data.results.map((offer, index) => (
                        <FlexRow key={offer.id} fontSize="1rem" borders>
                            <FlexItem bold basis="35%" content={index + 1 + "."} />
                            <FlexItem basis="150%">
                                <SuperDuperModal
                                    size="large"
                                    trigger={<span className="ref-link">{ offer.title }</span>}
                                    content={<PositionPreview data={offer}/>}
                                />
                            </FlexItem>
                            <FlexItem bold content={ offer.isco?.fullname } />
                            <FlexItem bold content={ offer.contract_count } />
                            <FlexItem>
                                {offer.is_external === false
                                    ? <Label content={t('internal_position')} style={{ backgroundColor: 'var(--primary)', color: 'var(--white)' }} />
                                    : <Label content={t('external_position')} style={{ backgroundColor: 'var(--info)', color: 'var(--white)' }} />
                                }
                            </FlexItem>
                            <FlexItem>
                                <FlexRow background="transparent" padding="0">
                                    <FlexItem>
                                        <SuperDuperModal
                                            size="large"
                                            trigger={
                                                <Icon name="eye-outline" style={{ color: "var(--primary)", cursor: "pointer" }} />
                                            }
                                            content={
                                                <PositionPreview data={offer} />
                                            }
                                        />
                                    </FlexItem>

                                    <CanView permissions={["candidates.c_manage_recruitment"]}>
                                        <FlexItem>
                                            <SuperDuperModal
                                                size="small"
                                                header={t('launch_hiring') + " - " + offer.title}
                                                trigger={
                                                    <Icon name='log-in-outline' title={t('launch_hiring') + " - " + offer.title} style={{ color: "var(--primary)", cursor: "pointer" }} />
                                                }
                                                content={
                                                    <LaunchHiringRoom offer={offer} btnName={t('launch')} />
                                                }
                                            />
                                        </FlexItem>
                                    </CanView>
                                    <CanView permissions={["jobs.c_manage_job_positions"]}>
                                        <FlexItem>
                                            <Link to={routes.UPDATE_JOB_POSITION + offer.id} >
                                                <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }} />
                                            </Link>
                                        </FlexItem>
                                    </CanView>

                                    <CanView permissions={["jobs.c_manage_job_positions"]}>
                                        <FlexItem>
                                            <ConfirmModal
                                                description={t('duplicate_position_confirm')}
                                                onConfirm={async () => {
                                                    const request = await createRequest(API.JOB_POSITIONS + offer.id + "/duplicate/")

                                                    if( request.status === 201 ){
                                                        history.push(routes.UPDATE_JOB_POSITION + request.response.id + "/")
                                                    }
                                                }}
                                                button={
                                                    <Icon name="copy-outline" style={{ color: "var(--info)", cursor: "pointer" }}/>
                                                }
                                            />
                                        </FlexItem>
                                    </CanView>

                                    <CanView permissions={["jobs.c_delete_job_positions"]}>
                                        <FlexItem>
                                            <ConfirmModal
                                                description={t('delete_desc')}
                                                onConfirm={() => onDelete(offer.id)}
                                                button={
                                                    <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                }
                                            />
                                        </FlexItem>
                                    </CanView>

                                </FlexRow>
                            </FlexItem>
                        </FlexRow>
                    ))}
                </FlexTable>
            </Segment>
            }
            <Paginator
                forcePage={page || 1}
                limit={limit}
                setLimit={setLimit}
                length={result?.data?.count || 0}
                onChange={(params) => fetchJobPositions(params)}
            />
        </>
    );
};

export default PositionTable;