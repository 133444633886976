import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Form, Divider, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';


const BankDetailsForm = ({ invoice, setInvoice, onClose }) => {
    const { t } = useTranslation()

    const [bankData, setBankData] = useState({
        bank_name: invoice.bank_name,
        bank_street: invoice.bank_street,
        bank_zip: invoice.bank_zip,
        bank_city: invoice.bank_city,
        bank_country: invoice.bank_country
    })

    const onConfirm = () => {
        setInvoice(prevState => {
            return {
                ...prevState,
                ...bankData
            }
        })
        onClose()
    }

    return (
        <Form>
            <SuperField as="input"
                autoFocus
                label={ t('name') }
                value={bankData.bank_name}
                onChange={(e, { value }) => setBankData({
                    ...bankData,
                    bank_name: value
                })}
            />
            <Form.Group widths={"equal"}>
                <SuperField as="input"
                    label={ t('swift') }
                    value={bankData.bank_swift_bic}
                    onChange={(e, { value }) => setBankData({
                        ...bankData,
                        bank_swift_bic: value
                    })}
                />
                <SuperField as="input"
                    search
                    label={ t('bank_code') }
                    value={bankData.bank_code}
                    onChange={(e, { value }) => setBankData({
                        ...bankData,
                        bank_code: value
                    })}
                />
            </Form.Group>
            <Divider/>

            <Form.Group widths={"equal"}>
                <SuperField as="input"
                    label={ t('street') }
                    value={bankData.bank_street}
                    onChange={(e, { value }) => setBankData({
                        ...bankData,
                        bank_street: value
                    })}
                />
                <SuperField as="input"
                    label={ t('postcode') }
                    value={bankData.bank_zip}
                    onChange={(e, { value }) => setBankData({
                        ...bankData,
                        bank_zip: value
                    })}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <SuperField as="input"
                    label={ t('city') }
                    value={bankData.bank_city}
                    onChange={(e, { value }) => setBankData({
                        ...bankData,
                        bank_city: value
                    })}
                />
                <SuperField as="choice"
                    search
                    label={ t('country') }
                    type="countries"
                    value={bankData.bank_country}
                    onChange={(e, { value }) => setBankData({
                        ...bankData,
                        bank_country: value
                    })}
                />
            </Form.Group>

            <Divider/>

            <Form.Field style={{ textAlign: "right" }}>
                <Button primary content={t('confirm')} onClick={onConfirm}/>
            </Form.Field>
        </Form>
    );
};

export default BankDetailsForm;