import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { patchRequest } from '@services/ServiceCommon';
// Components
import { Form } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperLabelField from '@components/forms/SuperLabelField';

const ContactLabelsForm = ({ id, rolesLabel, resourcetype, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const [labels, setLabels] = useState(rolesLabel.map(item => item.id)) 
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)
        const request = await patchRequest(API.CONTACTS + id + "/?query={id, roles_label}", {
            resourcetype: resourcetype,
            roles_label: {
                remove: rolesLabel.map(item => item.id),
                add: labels
            },
        })

        if( request.status === 200 ){
            onConfirm(id, request.response.roles_label)
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperLabelField
                value={labels}
                onChange={(labels) => setLabels(labels)}
                endpoint={API.CONTACT_LABELS}
            />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default ContactLabelsForm;