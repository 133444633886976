import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { getRequest } from '@services/ServiceCommon';
import { addMonths } from '@helpers/dates'
// components
import { Grid, Form, Dropdown } from 'semantic-ui-react';
import PieGraphInvoice from './PieGraphInvoice';
import BarGraph from "@components/graphs/BarGraph";
// import SuperScrollDatePicker from "@components/forms/SuperScrollDatePicker";
import moment from 'moment'

const InvoicesGraphs = ({ connection, premiumConnection }) => {
    const { t } = useTranslation();
    
    const MONTHS = [
        t("january"), t("february"), t("march"), t("april"),
        t("may"), t("june"), t("july"), t("august"),
        t("september"), t("october"), t("november"), t("december")
    ]
    
    const months = [
        {
            key: 'January',
            text: "January",
            value: '1',
        },
        {
            key: 'February',
            text: "February",
            value: '2',
        },
        {
            key: 'March',
            text: "March",
            value: '3',
        },
        {
            key: 'April',
            text: "April",
            value: '4',
        },
        {
            key: 'May',
            text: "May",
            value: '5',
        },
        {
            key: 'June',
            text: "June",
            value: '6',
        },
        {
            key: 'July',
            text: "July",
            value: '7',
        },
        {
            key: 'August',
            text: "August",
            value: '8',
        },
        {
            key: 'September',
            text: "September",
            value: '9',
        },
        {
            key: 'October',
            text: "October",
            value: '10',
        },
        {
            key: 'November',
            text: "November",
            value: '11',
        },
        {
            key: 'December',
            text: "December",
            value: '12',
        }
    ]
    
    const years = [
        {
            key: '2019',
            text: "2019",
            value: '2019',
        },
        {
            key: '2020',
            text: "2020",
            value: '2020',
        },
        {
            key: '2021',
            text: "2021",
            value: '2021',
        }
    ]
    
    const viewOptions = [
        {
            key: 'Month view',
            text: "Month",
            value: 'month',
        },
        {
            key: 'Year view',
            text: "Year",
            value: 'year',
        },
        {
            key: 'Day view',
            text: "Day",
            value: 'day',
        }
    ]

    const initValues = {
        option: "month",
        month: moment(new Date()).format("M"),
        year: moment(new Date()).format("YYYY"),
        from: moment(addMonths(new Date(), -5)).format("YYYY-MM-DD"),
        to: moment(new Date()).format("YYYY-MM-DD")
    }
    
    const [result, setResult] = useFetchData(API.GET_INVOICES + `graph_statistics/${connection}&option=${initValues.option}&date_from=${initValues.from}&date_to=${initValues.to}&convert_to_currency=EUR`)
    const [graphFilters, setGraphFilters] = useState(initValues)
    const [bankAccounts, setBankAccounts] = useState([])
    const [isLoadingBalances, setIsLoadingBalances] = useState(false)
    const [data, setData] = useState({
        labels: [],
        datasets : []
    })

    const getPriceByStatus = (status) => {
        if( result?.data === undefined || result.data === null || Object.keys(result.data).length === 0 ) return []

        return result?.data?.map((item) => {
            let values = { issued: 0, recieved: 0 }
            Object.entries(item.stats).forEach(([key,value]) => {
                if( key === status ){
                    values = value
                }
            })

            return values
        }) || []
    }

    useEffect( () => {
        async function fetchFilteredInvoices() {
            if( result.isLoading ) return;
            const request = await getRequest(API.GET_INVOICES + `graph_statistics/${connection}&option=${graphFilters.option}&date_from=${graphFilters.from}&date_to=${graphFilters.to}&convert_to_currency=EUR`)

            setResult({
                ...result,
                data: request.response
            })
        }

        fetchFilteredInvoices()
        // eslint-disable-next-line
    }, [graphFilters])

    useEffect( () => {
            setData({
                ...data,
                // eslint-disable-next-line
                labels: (result?.data !== undefined && result.data !== null && Object.keys(result.data).length !== 0) ? result.data?.map((item) => {
                    const date = new Date(item.date)
                    if (graphFilters.option === "month") return MONTHS[date.getMonth()]
                    else if (graphFilters.option === "year") return date.getFullYear()
                    else if (graphFilters.option === "day") return date.getDate() + " " + MONTHS[date.getMonth()]
                }) : [],
                datasets: [
                    {
                        label: t("drafts") + " - Issued",
                        stack: "issued",
                        data: getPriceByStatus("draft").map(item => item.issued),
                        backgroundColor: "#31C6E8",
                    },
                    {
                        label: t("outstanding") + " - Issued",
                        stack: "issued",
                        data: getPriceByStatus("outstanding").map(item => item.issued),
                        backgroundColor: "#FF4C78",
                    },
                    {
                        label: t("past_due") + " - Issued",
                        stack: "issued",
                        data: getPriceByStatus("past_due").map(item => item.issued),
                        backgroundColor: "#EEE722",
                    },
                    {
                        label: t("paid") + " - Issued",
                        stack: "issued",
                        data: getPriceByStatus("paid").map(item => item.issued),
                        backgroundColor: "#9bf754",
                    },
                    {
                        label: t("drafts") + " - Recieved",
                        stack: "recieved",
                        data: getPriceByStatus("draft").map(item => item.recieved),
                        backgroundColor: "#1dabcc",
                    },
                    {
                        label: t("outstanding") + " - Recieved",
                        stack: "recieved",
                        data: getPriceByStatus("outstanding").map(item => item.recieved),
                        backgroundColor: "#de2f5a",
                    },
                    {
                        label: t("past_due") + " - Recieved",
                        stack: "recieved",
                        data: getPriceByStatus("past_due").map(item => item.recieved),
                        backgroundColor: "#d1cb13",
                    },
                    {
                        label: t("paid") + " - Recieved",
                        stack: "recieved",
                        data: getPriceByStatus("paid").map(item => item.recieved),
                        backgroundColor: "#74d12c",
                    },
                ]
            })
        // eslint-disable-next-line
    }, [result])

    // fetch accounts balance if connection exist
    useEffect(() => {
        async function fetchBankBalances(){
            if( premiumConnection?.id ){
                setIsLoadingBalances(true)
                // fetch bank_account_balances
                const request = await getRequest(API.INVOICES+ "banking/tb/premium/accounts/" + connection)
                if( request.status === 200 ){
                    setBankAccounts(request.response.accounts)
                }
                setIsLoadingBalances(false)
            }
        }

        fetchBankBalances()
    }, [premiumConnection, connection])

    return (
        <Grid columns='2'>
            <Grid.Column mobile={16} computer={8} tablet={16}>
                <Form>
                    <Form.Group>
                        <span style={{ marginRight: "0.5rem" }}>{t('view_options')}</span>
                        <Dropdown
                            inline
                            options={viewOptions}
                            value={graphFilters.option}
                            style={{ color: 'var(--primary)' }}
                            onChange={(e, { value }) => setGraphFilters({...graphFilters, option: value})}
                        />
                        {/* Those 2 Dropdowns below - only for VIEW OPTION DAY */}
                        { 
                            graphFilters.option === "day"
                            &&
                            <>
                                <Dropdown
                                    inline
                                    options={months}
                                    value={graphFilters.month}
                                    style={{ color: 'var(--primary)' }}
                                    onChange={(e, { value }) => setGraphFilters({
                                        ...graphFilters,
                                        month: value,
                                        from: `${graphFilters.year}-${value}-1`,
                                        to: moment(`${graphFilters.year}-${value}-1`).endOf('month').format('YYYY-MM-DD')
                                    })}
                                />
                                <Dropdown
                                    inline
                                    options={years}
                                    value={graphFilters.year}
                                    style={{ color: 'var(--primary)' }}
                                    onChange={(e, { value }) => setGraphFilters({
                                        ...graphFilters,
                                        year: value,
                                        from: `${value}-${graphFilters.month}-1`,
                                        to: moment(`${value}-${graphFilters.month}-1`).endOf('month').format('YYYY-MM-DD')
                                    })}
                                />
                            </>
                        }
                            {/*:
                             <>
                                {
                                    graphFilters.option === "month"
                                    ?
                                    <SuperField as="input" type="number" min="1" max="12" label="Choose how many months to show" />
                                    :
                                    <SuperField as="input" type="number" min="1750" max="2300" label="Choose how many months to show" />
                                }
                            </> */}
                        
                        {/* <Dropdown
                            inline
                            options={viewOptions}
                            value={graphFilters.option}
                            style={{ color: 'var(--primary)' }}
                            onChange={(e, { value }) => setGraphFilters({...graphFilters, option: value})}
                         />

                        <SuperScrollDatePicker
                            value={ graphFilters.from }
                            onChange={ (selectedDate) => setGraphFilters({...graphFilters, from: selectedDate}) }
                        />*/}
                    </Form.Group>
                </Form>
                <BarGraph data={data}/>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8} tablet={16} style={{ display: "none" }}>
                <PieGraphInvoice
                    premiumConnection={premiumConnection}
                    bankAccounts={bankAccounts}
                    loading={isLoadingBalances}
                />
            </Grid.Column>
        </Grid>
    );
};

export default InvoicesGraphs;