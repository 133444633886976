import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { getParams } from '@helpers/functions';
import { requests } from '@helpers/requests';
// components 
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import EmptyRow from '@components/tables/EmptyRow';
import Action from '@components/general/Action';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Sidebar, Segment, Grid, Button, Menu } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific components
import ForecastForm from '../components/forms/ForecastForm';
import ForecastFilterForm from '../components/forms/ForecastFilterForm';
import ForecastRecordsList from '../views/ForecastRecordsList';

const ForecastList = ({ segmentRef }) => {
    const { t } = useTranslation()
    const [forecasts, setForecasts] = useFetchData(API.FORECASTS + "?paginate=true")
    const [visible, setVisible] = useState(false)

    const previous = getParams(forecasts.data?.previous)
    const next = getParams(forecasts.data?.next)

    const fetchForecasts = async (params) => {
        setForecasts(prevState => ({
            ...prevState,
            isLoading: true
        }))
        const request = await requests.get(API.FORECASTS + "?paginate=true" + params)

        if (request.status === 200) {
            setForecasts(prevState => ({
                ...prevState,
                isLoading: false,
                data: request.response
            }))
        }
    }

    const onDelete = async (id) => {
        const request = await requests.del(API.FORECASTS + id + '/');
        if (request.status === 204) {
            setForecasts(prevState => ({
                ...prevState,
                data: { 
                    ...prevState.data,
                    results: prevState.data.results.filter(item => item.id !== id)
                }
            }))
        }
    }

    return (
        <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
            <Sidebar
                style={{ width: "40rem",  }}
                as={Menu}
                animation='overlay'
                direction='right'
                icon='labeled'
                vertical
                visible={visible}
                onHide={() => setVisible(false)}
                width='wide'
                target={segmentRef}
            >
                <ForecastFilterForm
                    setVisible={setVisible}
                    fetchForecasts={fetchForecasts}
                />
            </Sidebar>
            <Sidebar.Pusher>
                <Segment basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none"}}>
                    <Grid>
                        <Grid.Row style={{ padding: 0, marginBottom: "0.5rem" }} verticalAlign="middle">
                            <Grid.Column computer={8} mobile={16} tablet={16}/>
                            <Grid.Column computer={8} mobile={16} tablet={16} style={{ textAlign: "right" }}>
                                <Button
                                    size="small"
                                    style={{ 
                                        background: "transparent", 
                                        paddingLeft: "1rem", 
                                        paddingRight: "1rem" 
                                    }}
                                    onClick={() => setVisible(!visible)}
                                >
                                    <Icon name='funnel-outline'/>
                                </Button>
                                <CanView permissions={['forecasts.c_manage_forecasts']}>
                                    <SuperDuperModal 
                                        header={ t('add_forecast') }
                                        size="small"
                                        trigger={
                                            <Button size="small" primary>{ t('add_forecast') }</Button>
                                        }
                                        content={
                                            <ForecastForm
                                                setForecasts={setForecasts}
                                            />
                                        }
                                    />
                                </CanView>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    {/* Table listing */}
                    { forecasts.isLoading ? <Loading /> :
                        <FlexTable>
                            <FlexRow>
                                <FlexHeader basis="150%" content={t('name')} />
                                <FlexHeader content={t('date_from')} />
                                <FlexHeader content={t('date_to')} />
                                <FlexHeader content={t('category')} />
                                <FlexHeader basis="50%" content={t('actions')} />
                            </FlexRow>
                            <EmptyRow length={forecasts.data.results?.length} />
                            {forecasts.data?.results?.map((forecast, index) => (
                                <FlexRow key={forecast.id} fontSize="0.9rem" borders>
                                    <FlexItem bold basis="150%" content={ forecast.title } />
                                    <FlexItem content={ forecast.date_from } />
                                    <FlexItem content={ forecast.date_to } />
                                    <FlexItem content={ forecast.category?.title } />
                                    <FlexItem basis="50%">
                                        <FlexRow padding="0" background="transparent">
                                            <FlexItem textAlign="center">
                                                <SuperDuperModal
                                                    closeIcon
                                                    size="large"
                                                    trigger={
                                                        <Button icon={<Icon name="eye-outline"/>} style={{
                                                            background: "none",
                                                            color: "var(--primary)",
                                                        }} />
                                                    }
                                                    content={
                                                        <ForecastRecordsList forecast={forecast}/>
                                                    }
                                                />
                                            </FlexItem>
                                            <CanView permissions={['forecasts.c_manage_forecasts']}>
                                                <FlexItem textAlign="center">
                                                    <SuperDuperModal
                                                        size="small"
                                                        trigger={
                                                            <Button icon={<Icon name="pencil-outline"/>} style={{
                                                                background: "none",
                                                                color: "var(--dark)",
                                                            }} />
                                                        }
                                                        content={
                                                            <ForecastForm 
                                                                forecast={forecast}
                                                                setForecasts={setForecasts}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                            </CanView>
                                            <CanView permissions={['forecasts.c_delete_forecasts']}>
                                                <FlexItem textAlign="center">
                                                    <Action as='delete' 
                                                        tooltip={t('delete')} 
                                                        text={t('delete')} 
                                                        onClick={() => onDelete(forecast.id)}
                                                    />
                                                </FlexItem>
                                            </CanView>
                                        </FlexRow>
                                    </FlexItem>
                                </FlexRow>
                            ))}
                        </FlexTable>
                    }
                    <FlexRow>
                        <FlexItem basis="50%">
                            <Button.Group>
                                <Button disabled={previous === null} primary onClick={previous !== null ? () => fetchForecasts(previous) : null}> <Icon name="chevron-back-outline" /> </Button>
                                <Button disabled={next === null} primary onClick={next !== null ? () => fetchForecasts(next) : null}> <Icon name="chevron-forward-outline" /> </Button>
                            </Button.Group>
                        </FlexItem>
                    </FlexRow>
                </Segment>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

export default ForecastList;