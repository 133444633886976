import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router';
import { routes } from '@routes/index';
// store
import { isEmpty } from "@helpers/validation";
import { createRequest } from '@services/ServiceCommon';
import { API } from '@store/config';
// components
import CanView from '@components/perms/CanView';
import { Form, Message } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import CustomFormDivider from '@components/general/CustomFormDivider';


const ProjectCreateForm = ({ onConfirm, onClose }) => {
    const { t } = useTranslation();
    const history = useHistory()
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errors, setErrors] = useState(null);
    const [form, setForm] = useState({
        name: null,
        identifier: null,
        description: null,
        budget_amount: null,
        project_manager: null,
        from_date: null,
        due_date: null
    })

    const [showAdditionalDatas, setShowAdditionalDatas] = useState(false)

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true);

        const result = await createRequest(API.PROJECTS, form)

        if (result.status === 201) {
            history.push(routes.PROJECT_DETAIL + result.response?.id)
        }
        if (result.status === 400) {
            setErrors(result.response)
        }

        setIsProcessing(false);
    }

    const replaceSpaces = (str) => {
        let strD = ""
        strD = str.replace(/\s+/g, "-").toLowerCase();
        return strD
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />
            <Form.Group widths={2}>
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('name')}
                    placeholder={t('name')}
                    error={ errors?.name || false }
                    value={form.name}
                    onChange={(e, { value }) =>
                        setForm({ ...form, name: value, identifier: replaceSpaces(value)})
                    }
                />
                <SuperField as="input"
                    required
                    label={t('identifier')}
                    placeholder={t('project_identifier')}
                    error={ errors?.identifier || false }
                    value={form.identifier}
                    onChange={(e, { value }) =>
                        setForm({ ...form, identifier: replaceSpaces(value)})
                    }
                />
            </Form.Group>

            <SuperField as="textarea"
                label={t('description')}
                placeholder={t('description')}
                error={ errors?.description || false }
                value={form?.description}
                onChange={(e, { value }) =>
                    setForm({ ...form, description: value})
                }
            />
            <CustomFormDivider
                header = {
                    <>
                        { t('additional_information') }
                        <small
                            onClick={ () => setShowAdditionalDatas(!showAdditionalDatas) }
                            className="ref-link"
                            style={{ color: !showAdditionalDatas ? "var(--primary)" : "var(--danger)", float: "right" }}
                        >
                            { showAdditionalDatas ? t('remove') : t('add')}
                        </small>
                    </>
                }
                divider = {true}
                content = {
                    <>
                    {
                    showAdditionalDatas ?
                    <>
                    <Form.Group widths="equal">
                        <CanView permissions={['projects.c_assign_project_manager']}>
                            <SuperField as="choice"
                                search
                                text="fullname"
                                label={t('project_manager')}
                                error={ errors?.project_manager || false }
                                value={form?.project_manager}
                                onChange={(e, { value }) =>
                                    setForm({ ...form, project_manager: value})
                                }
                                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                            />
                        </CanView>
                          <SuperField as="input"
                            label={t('budget')}
                            placeholder={t('budget')}
                            value={form?.budget_amount}
                            error={ errors?.budget_amount || false }
                            onChange={(e, { value }) =>
                                { value === "" || value === null ?
                                setForm({ ...form, budget_amount: null}) :
                                setForm({ ...form, budget_amount: value})
                            }}
                          />
                    </Form.Group>
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('start_date') }
                            error={ errors?.start_date || false }
                            value={form?.from_date}
                            onChange={(e, { value }) =>
                            setForm({ ...form, from_date: value})
                        }
                            />
                        <SuperField as="datepicker"
                            label={ t('due_date') }
                            value={form?.due_date}
                            error={ errors?.due_date || false }
                            onChange={(e, { value }) =>
                                { value === "" || value === null ?
                                setForm({ ...form, due_date: null}) :
                                setForm({ ...form, due_date: value})
                            }}
                        />
                    </Form.Group>

                    </>
                    : ""
                    }
                </>
                }
                />
            <Form.Field style={{ textAlign: "right", margin: "1rem" }}>
                <ModalCancel
                    text={t("cancel")}
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    text={"Create"}
                    disabled={
                        isProcessing ||
                        isEmpty(form.name)
                    }
                    loading={isProcessing}
                />
            </Form.Field>
        </Form>
    )
}

export default ProjectCreateForm;

