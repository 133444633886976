import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
// store
import { icons } from '@icons';
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { useFetchData, useQueryPage } from '@helpers/hooks';
// components
import { Container, Divider } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
import ApprovalsList from '../components/ApprovalsList';
import ApprovalsFilters from '../components/ApprovalFilters';
import CanView from '@components/perms/CanView';
import Paginator from '@components/Paginator';

const Approvals = () => {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()
    let page = useQueryPage()
    
    const [limit, setLimit] = useState(10)
    const [resourceType, setResourceType] = useState(null);
    const [responsiblePerson, setResponsiblePerson] = useState(null);
    const [status, setStatus] = useState(null);
    const [approvals, setApprovals] = useFetchData(API.APPROVALS + "?paginate=true&awaiting_approval=true&page=" + page + "&limit=" + limit);

    
    const breadcrumb_items = [
        { 'name': t("approvals"), 'icon': icons.APPROVALS, 'href': '' }
    ]

    const onFilter = async (params, isFiltered) => {
        if( !approvals.isLoading ){
            params = params || ""

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if ( !params.includes('page') ) {
                params += "&page=" + page
            }

            if ( !params.includes('limit')){
                params += "&limit=" + limit
            } 

            setApprovals(prevState => ({
                ...prevState,
                isLoading: true
            }))
    
            if(status === null){
                params += "&awaiting_approval=true"
            } else {
                params += "&is_approved="+status
            }
            if( resourceType !== null ) params += "&resource_type="+resourceType
            if( responsiblePerson !== null ) params += "&responsible_person="+responsiblePerson
            
            const request = await getRequest(API.APPROVALS + "?paginate=true" + params)
    
            if(request.status === 200){
                setApprovals(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }
            
        }
    };

    useEffect(() => {
        async function fetchData(){
            history.replace({ pathname: location.pathname, search: `?page=${1}` });
            await onFilter("", true)
        }

        fetchData()
        // eslint-disable-next-line
    }, [status])

    return (
        <CanView permissions={['approvals.c_view_all_approvals', 'approvals.c_view_user_approvals']} redirect>
            <BreadcrumbNav items={breadcrumb_items} style={{ marginBottom: 0 }} />
            <Container fluid>
                <ApprovalsFilters
                    responsiblePerson={responsiblePerson}
                    setResponsiblePerson={setResponsiblePerson}
                    resourceType={resourceType}
                    setResourceType={setResourceType}
                    onFilter={onFilter}
                    status={status}
                    setStatus={setStatus}
                    loading={approvals.isLoading}
                />
                <Divider/>
                <ApprovalsList 
                    status={status} 
                    approvals={approvals}
                    setApprovals={setApprovals}
                />
                <Paginator
                    forcePage={page || 1}
                    limit={limit}
                    setLimit={setLimit}
                    length={approvals?.data?.count || 0}
                    onChange={(params) => onFilter(params)}
                />
            </Container>
        </CanView>
    );
};

export default Approvals;
