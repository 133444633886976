import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperFieldSet from '@components/forms/SuperFieldSet';
import AddressFieldGroup from '@components/forms/common/AddressFieldGroup';

const PermanentAddress = ({ setData }) => {
    const { t } = useTranslation();

    const [address, setAddress] = useState({
        street: "",
        city: "",
        postcode: "",
        country: "",
        state: "",
        address_line: "",
    })

    useEffect( () => {
        let permanentAddress = null;
        if( address.street !== "" && address.city !== "" && address.postcode !== "" ){
            permanentAddress = address
        }

        setData(prevState => ({
            ...prevState,
            permanent_address: permanentAddress
        }))

    }, [address, setData])

    return (
        <SuperFieldSet legend={ t('pernament') }>
            <AddressFieldGroup address={address} setAddress={setAddress}/>
        </SuperFieldSet>
    );
};

export default PermanentAddress;