import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { isPhoneNumberValid, isEmailValid } from '@helpers/validation'
// components
import { Form, Divider, Tab, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import EmailField from '@components/forms/common/EmailField';
import PhoneField from '@components/forms/common/PhoneField';


const SupplierDetailsForm = ({ invoice, setInvoice, onClose }) => {
    const { t } = useTranslation()
    const [supplierData, setSupplierData] = useState({
        supplier_name: invoice.supplier_name,
        supplier_registration_id: invoice.supplier_registration_id,
        supplier_business_register_folder: invoice.supplier_business_register_folder,
        supplier_tax_id: invoice.supplier_tax_id,
        supplier_vat_id: invoice.supplier_vat_id,
        supplier_street: invoice.supplier_street,
        supplier_zip: invoice.supplier_zip,
        supplier_city: invoice.supplier_city,
        supplier_country: invoice.supplier_country,
        supplier_email: invoice.supplier_email,
        supplier_phone: invoice.supplier_phone
    })

    const onConfirm = () => {
        setInvoice(prevState => {
            return {
                ...prevState,
                ...supplierData
            }
        })
        onClose()
    }

    const panes = [
        { 
            menuItem: t('general_information'), 
            render: () => (
                <Tab.Pane>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            autoFocus
                            label={ t('name') }
                            value={supplierData.supplier_name}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_name: value
                            }))}
                        />
                        <SuperField as="input"
                            label={ t('supplier_registration_id') }
                            value={supplierData.supplier_registration_id}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_registration_id: value
                            }))}
                        />
                    </Form.Group>
                    <Divider/>
                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={ t('supplier_tax_id') }
                            value={supplierData.supplier_tax_id}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_tax_id: value
                            }))}
                        />
                        <SuperField as="input"
                            label={ t('supplier_vat_id') }
                            value={supplierData.supplier_vat_id}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_vat_id: value
                            }))}
                        />
                        <SuperField as="input"
                            label={ t('register_folder') }
                            value={supplierData.supplier_business_register_folder}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_business_register_folder: value
                            }))}
                        />
                    </Form.Group>
                </Tab.Pane>
            ) 
        },
        { 
            menuItem: t('address'), 
            render: () => (
                <Tab.Pane>
                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={ t('street') }
                            value={supplierData.supplier_street}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_street: value
                            }))}
                        />
                        <SuperField as="input"
                            label={ t('postcode') }
                            value={supplierData.supplier_zip}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_zip: value
                            }))}
                        />
                    </Form.Group>
                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={ t('city') }
                            value={supplierData.supplier_city}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_city: value
                            }))}
                        />
                        <SuperField as="choice"
                            search
                            type="countries"
                            label={ t('country') }
                            value={supplierData.supplier_country}
                            onChange={(e, { value }) => setSupplierData(prevState => ({
                                ...supplierData,
                                supplier_country: value
                            }))}
                        />
                    </Form.Group>
                </Tab.Pane>
            ) 
        },
        { 
            menuItem: t('contact_information'), 
            render: () => (
                <Tab.Pane>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label> { t('phone') } </label>
                            <PhoneField
                                hideType
                                value={supplierData.supplier_phone === null ? "" : supplierData.supplier_phone}
                                setValue={(e, { value }) => setSupplierData(prevState => ({
                                    ...prevState,
                                    supplier_phone: value
                                }))}
                            />
                        </Form.Field>
                        <EmailField
                            label={t('email')}
                            value={supplierData.supplier_email === null ? "" : supplierData.supplier_email}
                            setValue={(e, { value }) => setSupplierData(prevState => ({
                                ...prevState,
                                supplier_email: value
                            }))}
                        />
                    </Form.Group>
                </Tab.Pane>
            )
        },
      ]

    return (
        <Form>
            <Tab panes={panes}/>
            <Divider/>

            <Form.Field style={{ textAlign: "right" }}>
                <Button 
                    primary
                    content={t('confirm')}
                    onClick={onConfirm}
                    disabled={ 
                        (!isPhoneNumberValid(supplierData.supplier_phone) && supplierData.supplier_phone !== null && supplierData.supplier_phone !== "") ||
                        (!isEmailValid(supplierData.supplier_email) && supplierData.supplier_email !== null && supplierData.supplier_email !== "")
                    }
                />
            </Form.Field>
        </Form>
    );
};

export default SupplierDetailsForm;