import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { isEmpty, isEmailValid, isPhoneNumberValid } from '@services/helpers/validation';
import { API } from '@store/config';
import { createRequest, patchRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Message, Divider, Header } from 'semantic-ui-react';
import EmailField from "@components/forms/common/EmailField"
import PhoneField from "@components/forms/common/PhoneField"

const SupplierForm = ({ data, type, onConfirm, isSupplier, onClose }) => {
    const { t } = useTranslation();

    const contactInfo = type !== "account" ? data?.personal_information?.contact_info : data?.contact_info
    const primaryBank = data?.business_detail?.bank_accounts?.find( item => item.default === true )
    const businessDetail = data?.business_detail

    const [showContactFields, setShowContactFields] = useState((contactInfo?.phones?.length || contactInfo?.emails?.length) ? true : false)
    const [showBankAccountFields, setShowBankAccountFields] = useState(primaryBank?.id ? true : false)

    const [supplier, setSupplier] = useState({
        name: (type === "account" ? data?.name : data?.fullname) || "", // can be used to fill fullname or name based on given type,
        unit: data?.unit?.id || "",
        // business details
        id_number: businessDetail?.identification_number || "",               // IČO (identification_number)
        dit_number: businessDetail?.dit_number || "",                         // DIČ (DIT Number)
        vat_number: businessDetail?.vat_number || "",                         // IČ DPH (VAT Number) - optional
        nace_code: (businessDetail?.nace_code ? businessDetail?.nace_code?.id : "") || "",
        folder_details: businessDetail?.business_register_folder || "",       // business folder identifier
        reinvoice: businessDetail?.reinvoice || false,

        // Main contact info
        contact_phone: contactInfo?.phones?.length > 0
            ? { id: contactInfo?.phones[0].id, phone: contactInfo?.phones[0]?.phone, phone_type: contactInfo?.phones[0]?.phone_type ? contactInfo?.phones[0]?.phone_type?.id : "" }
            : { id: 0, phone: "", phone_type: "" },
        contact_email: contactInfo?.emails?.length > 0
            ? { id: contactInfo?.emails[0].id, email: contactInfo?.emails[0]?.email }
            : { id: 0, email: "" },

        // Primary bank account - later on in detail we will be able to add another bank account
        primary_bank_id: primaryBank ? primaryBank.id : 0,
        iban: primaryBank ? primaryBank.iban : "",
        bank_name: primaryBank ? primaryBank.name : "",
        swift_code: primaryBank ? primaryBank.swift_code : "",
        currency: primaryBank ? primaryBank.currency : "EUR",
        bank_detail: primaryBank ? primaryBank.bank_detail : "",
        alias: primaryBank ? primaryBank.alias : "",

        // invoice approvals - automatic (true) / manual (false)
        automatic_approval: data ? data?.approve_automatically : false,
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSuccess = (response, type) => {
        onConfirm(response, type);
        onClose()
    }

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }

        if (response.name) {
            errorsArray.push(response.name)
        }

        setErrors(errorsArray);
    }

    // TODO: ADD Support to set dynamically which type of supplier is being managed (account or profile)
    // !! TODO: for now only Account is used
    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true)

        let request = undefined
        const endpoint = type === "account" ? API.ACCOUNTS : API.EMPLOYEES

        const contactInfoDataset = {
            phones: supplier.contact_phone.id === 0 ?
                {   // create phone contact
                    create: supplier.contact_phone.phone !== "" ?
                    [
                        { phone: supplier.contact_phone.phone, phone_type: null }
                    ] : []
                } : supplier.contact_phone.id !== 0 && supplier.contact_phone.phone !== ""
                    ?
                        {   // update existing phone contact
                            update: {
                                [supplier.contact_phone.id]: {
                                    phone: supplier.contact_phone.phone,
                                    phone_type: null
                                }
                            }
                        }
                    :   { remove: [supplier.contact_phone.id]}
                ,
            emails: supplier.contact_email.id === 0 ?
                {   // create email contact
                    create: supplier.contact_email.email !== "" ?
                    [
                        { email: supplier.contact_email.email }
                    ] : []
                } : supplier.contact_email.id !== 0 && supplier.contact_email.email !== ""
                    ?
                    {
                        update: {
                            [supplier.contact_email.id]: {
                                email: supplier.contact_email.email
                            }
                        }
                    }
                    : { remove: [supplier.contact_email.id]}
        }

        const bank_account = supplier.primary_bank_id === 0
            ? // create
            {
                create: [
                    {
                        name: supplier.bank_name,
                        iban: supplier.iban,
                        swift_code: supplier.swift_code,
                        alias: supplier.alias,
                        bank_detail: supplier.bank_detail,
                        currency: supplier.currency,
                        default: true
                    }
                ]

            }
            : // update
            {
                update: {
                    [supplier.primary_bank_id]: {
                        name: supplier.bank_name,
                        iban: supplier.iban,
                        swift_code: supplier.swift_code,
                        alias: supplier.alias,
                        bank_detail: supplier.bank_detail,
                        currency: supplier.currency,
                        default: true
                    }
                }
            }

        let businessDetailDataset = {
            name: supplier.name,
            identification_number: supplier.id_number,
            dit_number: supplier.dit_number,
            vat_number: supplier.vat_number,
            business_register_folder: supplier.folder_details,
            nace_code: supplier.nace_code !== "" ? supplier.nace_code : null,
            bank_accounts: bank_account,
            reinvoice: supplier?.reinvoice
        }

        if( !showBankAccountFields ){
            if( supplier.primary_bank_id !== 0 ){
                businessDetailDataset.bank_accounts = {
                    remove: [supplier.primary_bank_id]
                }
            } else {
                delete businessDetailDataset.bank_accounts
            }
        }

        if ( data !== undefined && data.id > 0 ) {
            request = await patchRequest(endpoint + data.id + "/", {
                name: supplier.name,
                unit: supplier.unit,
                business_detail: businessDetailDataset,
                contact_info: showContactFields ? contactInfoDataset : null,
                approve_automatically: showBankAccountFields ? supplier.automatic_approval : false,
                reinvoice: showBankAccountFields ? supplier.reinvoice : false
            })
        } else {
            request = await createRequest(endpoint, {
                name: supplier.name,
                unit: supplier.unit,
                business_detail: businessDetailDataset,
                contact_info: showContactFields ? contactInfoDataset : null,
                approve_automatically: showBankAccountFields ? supplier.automatic_approval : false,
                reinvoice: showBankAccountFields ? supplier.reinvoice : false,
                is_supplier: isSupplier
            })
        }

        if( request !== undefined ){
            if (request.status === 200 || request.status === 201) {
                handleSuccess(request.response, type)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    const isContactInfoValid = (phone, email) => {
        let isValid = false
        if( phone !== "" && email === "" ) {
            if( isPhoneNumberValid(phone) ){
                isValid = true
            }
        } else if( email !== "" && phone === "" ) {
            if( isEmailValid(email) ){
                isValid = true
            }
        } else if( email !== "" && phone !== "" ){
            if( isPhoneNumberValid(phone) && isEmailValid(email) ){
                isValid = true
            }
        }

        return isValid
    }

    const isFilledRequiredFields = () => {
        let isValid = true

        // validate if business info are filled property
        if( isEmpty(supplier.name) || isEmpty(supplier.id_number) ){
            return false
        }

        // validate if contact information are filled properly
        if( showContactFields && (!isContactInfoValid(supplier.contact_phone.phone, supplier.contact_email.email))){
            return false
        }

        if( showBankAccountFields && (isEmpty(supplier.bank_name) || isEmpty(supplier.iban) || isEmpty(supplier.swift_code)) ){
            return false
        }

        return isValid
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />

            <SuperField as="input"
                fluid
                required
                autoFocus
                label={ t('name')}
                value={ supplier.name }
                onChange={(e, { value }) => setSupplier({...supplier, name: value})}
            />

            <Divider/>
            <Header as="h3" content={ t('business_detail') }/>
            <Divider/>

            <Form.Group widths="equal">
                <SuperField as="input"
                    required
                    label={t('id_number')}
                    placeholder={t('id_number')}
                    value={supplier.id_number}
                    onChange={(e, { value }) => setSupplier({ ...supplier, id_number: value })}
                />
                <SuperField as="input"
                    label={t('dit_number')}
                    placeholder={t('dit_number')}
                    value={supplier.dit_number}
                    onChange={(e, { value }) => setSupplier({ ...supplier, dit_number: value })}
                />
                <SuperField as="input"
                    label={t('vat_number')}
                    placeholder={t('vat_number')}
                    value={supplier.vat_number}
                    onChange={(e, { value }) => setSupplier({ ...supplier, vat_number: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('register_folder')}
                    placeholder={t('register_folder')}
                    value={supplier.folder_details}
                    onChange={(e, { value }) => setSupplier({ ...supplier, folder_details: value })}
                />
                <SuperField as="choice"
                    search
                    label={t('nace_code')}
                    endpoint={API.NACE_CODE}
                    text="name"
                    value={supplier.nace_code}
                    onChange={(e, { value }) => setSupplier({ ...supplier, nace_code: value })}
                />
            </Form.Group>
            { isSupplier &&
                <>
                <Divider/>
                    <Header as="h3" content={ t('reinvoice') }/>
                    <p> { t('reinvoice_hint_message') }. </p>
                    <Divider/>

                    <SuperField as="checkbox"
                        label={ t('reinvoice') }
                        checked={supplier.reinvoice}
                        onChange={() => setSupplier({...supplier, reinvoice: !supplier.reinvoice })}
                    />
                </>
            }

            <Divider/>
            <Header as="h3" content={ t('Unit assigment') }/>
            <Divider/>

            <SuperField as="choice"
                search
                fluid
                label={t('unit')}
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                text="name"
                value={supplier.unit}
                onChange={(e, { value }) => setSupplier({ ...supplier, unit: value })}
            />

            <Divider/>
            <Header as="h3" content={
                <>
                    { t('contact_info') }
                    <small
                        onClick={ () => setShowContactFields(!showContactFields) }
                        className="ref-link"
                        style={{ color: !showContactFields ? "var(--primary)" : "var(--danger)" , float: "right" }}
                    >
                        { showContactFields ? t('remove') : t('add')}
                    </small>
                </>
             }/>
            { showContactFields &&
                <>
                    <p> { t('contact_info_hint_message') }. </p>
                    <Divider/>
                </>
            }

            { showContactFields &&
                <Form.Group widths="equal">
                    <PhoneField
                        hideType
                        placeholder={t('enter_number')}
                        value={supplier.contact_phone.phone}
                        setValue={(e, { value }) => setSupplier({
                            ...supplier,
                            contact_phone: { ...supplier.contact_phone, phone: value }
                        })}
                        // phoneType={supplier.contact_phone.phone_type}
                        // setPhoneType={(e, { value }) => setSupplier({
                        //     ...supplier,
                        //     contact_phone: { ...supplier.contact_phone, phone_type: value }
                        // })}
                    />
                    <EmailField
                        label={t('email')}
                        value={supplier.contact_email.email}
                        setValue={(e, { value }) => setSupplier({
                            ...supplier,
                            contact_email: { ...supplier.contact_email, email: value }
                        })}
                    />
                </Form.Group>
            }

            <Divider/>
            <Header as="h3" content={
                <>
                    { t('primary_bank_details') }
                    <small
                        onClick={ () => setShowBankAccountFields(!showBankAccountFields) }
                        className="ref-link"
                        style={{ color: !showBankAccountFields ? "var(--primary)" : "var(--danger)", float: "right" }}
                    >
                        { showBankAccountFields ? t('remove') : t('add')}
                    </small>
                </>
            }/>

            { showBankAccountFields &&
            <>
                <p> { t('primary_bank_details_hint_message') }. </p>
                <Divider/>

                <Form.Group widths={2}>
                    <SuperField as="input"
                        required
                        label={t('name')}
                        placeholder={t('enter_bank_name')}
                        value={supplier.bank_name}
                        onChange={(e, { value }) => setSupplier({...supplier, bank_name: value})}
                    />
                    <SuperField as="input"
                        required
                        label='IBAN'
                        placeholder={t('enter_iban')}
                        value={supplier.iban}
                        onChange={(e, { value }) => setSupplier({...supplier, iban: value})}
                    />
                </Form.Group>
                <Form.Group widths={2}>
                    <SuperField as="input"
                        required
                        label='Swift'
                        placeholder={t('enter_swift')}
                        value={supplier.swift_code}
                        onChange={(e, { value }) => setSupplier({...supplier, swift_code: value})}
                    />
                    <SuperField as="choice"
                        fluid
                        search
                        type="currency_codes"
                        label={t('currency')}
                        value={supplier.currency}
                        onChange={(e, { value }) => setSupplier({...supplier, currency: value})}
                    />
                </Form.Group>

                <Form.Group widths={2}>
                    <SuperField as="input"
                        label='Alias'
                        placeholder={t('enter_bank_alias')}
                        value={supplier.alias}
                        onChange={(e, { value }) => setSupplier({...supplier, alias: value})}
                    />
                    <SuperField as="input"
                        label='Detail'
                        placeholder={t('enter_bank_details')}
                        value={supplier.bank_detail}
                        onChange={(e, { value }) => setSupplier({...supplier, bank_detail: value})}
                    />
                </Form.Group>

                <Divider/>
                <Header as="h3" content={ t('invoice_approval') }/>
                <p> { t('invoice_approval_hint_message') }. </p>
                <Divider/>

                <SuperField as="checkbox"
                    label={ t('automatic') }
                    checked={supplier.automatic_approval}
                    onChange={() => setSupplier({...supplier, automatic_approval: !supplier.automatic_approval })}
                />

            </>
            }

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || !isFilledRequiredFields()}
                    text={t('confirm')} />
            </Form.Field>
        </Form>
    );
};

export default SupplierForm;