import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import {  Form } from 'semantic-ui-react';
import SuperFieldSet from '@components/forms/SuperFieldSet';
import PhoneField from '@components/forms/common/PhoneField';
import EmailField from '@components/forms/common/EmailField';

const ContactInfo = ({ setData }) => {
    const { t } = useTranslation();

    const [phone, setPhone] = useState({
        phoneType: null,
        phone: ""
    })

    const [email, setEmail] = useState("")

    useEffect( () => {
        let contact_info = null;
        if( phone.phone !== "" || email !== "" ){

            contact_info = {
                phones: phone.phone !== "" ? {
                    create: [
                        { phoneType: phone.phoneType, phone: phone.phone },
                    ],
                } : { create: [] },
                emails: email !== "" ? {
                    create: [
                        { email: email },
                    ],
                } : { create: [] },
            }
        }

        setData(prevState => ({
            ...prevState,
            contact_info: contact_info
        }))

    }, [phone, email, setData])

    return (
        <SuperFieldSet legend={ t('contact_information') }>
            <Form.Group widths="equal">
                <PhoneField
                    hideType
                    placeholder={t('enter_number')}
                    value={phone.phone}
                    setValue={(e) => setPhone({
                        ...phone,
                        phone: e.target.value

                    })}
                />
                <EmailField
                    label={t('email')}
                    value={email}
                    setValue={(e) => setEmail(e.target.value)}
                />
            </Form.Group>
        </SuperFieldSet>
    );
};

export default ContactInfo;