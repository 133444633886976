import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { projectsService } from '../../../store/services/ServiceProjects';
// components
import ChoiceField from '../common/ChoiceField';

const MilestoneDropdownField = ({ milestone, setMilestone, project, disabled, ...rest }) => {
    /*
        ** milestone is current selected value that is represented by milestone ID
        ** setMilestone is state function that is handling milestone state change from outside of this component
        ** project is just project id used for fetching all milestones for current project
        ** disabled is used to set disabled state on Form.Select component
        ** ...rest are React "others" props that could be passed without explicit definition to Form.Select component
    */
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        async function fetchMilestones() {
            setLoading(true)
            // load milestones for this project
            const milestones = await projectsService.getMilestones("?project=" + project + "&is_active=true") // project is project ID
            let milestoneArray = []
            if (milestones.status === 200) {
                const response = milestones.response;
                for (let i = 0; i < response.length; i++) {
                    milestoneArray.push({
                        key: response[i].id, text: response[i].name, value: response[i].id
                    });
                }
                setOptions(milestoneArray);
            }
            setLoading(false)
        }

        if (project !== "") {
            fetchMilestones();
        } else {
            setOptions([]);
        }
    }, [project])

    return (
        <ChoiceField
            {...rest}
            clearable
            loading={loading}
            disabled={disabled || loading || options.length === 0}
            customOptions={options}
            label={t('milestone')}
            value={milestone}
            onChange={(e, data) => setMilestone(data.value)}
        />
    );
};

export default MilestoneDropdownField;