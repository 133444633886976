import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
// import { routes } from '@store/routes';
import { icons } from '@store/icons';
import { API } from '@store/config';
import { useQueryPage } from '@helpers/hooks';
import { requests } from '@helpers/requests';
import { useLocation, useHistory } from 'react-router-dom';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import Action from '@components/general/Action';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Button, Container, Grid, Sidebar, Segment, Menu } from 'semantic-ui-react';
// specific components
import EmployeesTable from '../components/tables/EmployeesTable';
import EmployeeFilterForm from '../components/EmployeeFilterForm';
import EmployeeForm from '../components/forms/EmployeeForm';


const EmployeesList = () => {
    const { t } = useTranslation();
    let page = useQueryPage()

    const location = useLocation()
    const history = useHistory()
    const [employeesStats, setEmployeesStats] = useState({});
    const [recordCount, setRecordCount] = useState(0);
    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [view, setView] = useState(0); // 1 - Internal, 2 - External, 3 - Freelancer
    const [limit, setLimit] = useState(10);
    const [filterReset, setFilterReset] = useState(false)

    const initState = {
        search: "",
        gender: "",
        country: "",
        education: "",
        position: "",
        unit: "",
        city: "",
        state: "",
        experience: "",
        isExact: false,
        skills: [],
        labels: [],
        language: "",
        languageLevel: ""
    }
    const [filters, setFilters] = useState(initState)

    const setParams = () => {
        let filterBaseParams = ""

        if( filters.search !== "" ){
            filterBaseParams += "&search="+filters.search
        }

        if( filters.experience !== "" ){
            filterBaseParams += "&experiences="+filters.experience
        }

        if( filters.skills.length > 0){
            let skillsParams = ""
            for (let i = 0; i < filters.skills.length; i++) {
                if( i === 0 ){
                    skillsParams += filters.skills[i]
                } else {
                    skillsParams += "--" + filters.skills[i]
                }
            }

            if( filters.isExact ){
                filterBaseParams += "&skill_and_seniority_conjoined=" + skillsParams
            } else {
                filterBaseParams += "&skill_and_seniority=" + skillsParams
            }
        }

        if( filters.language !== "" ){
            filterBaseParams += "&language="+filters.language
        }

        if( filters.gender !== "" ){
            filterBaseParams += "&gender="+filters.gender
        }

        if( filters.position !== "" ){
            filterBaseParams += "&position="+filters.position
        }

        if( filters.unit !== "" ){
            filterBaseParams += "&unit="+filters.unit
        }

        if( filters.languageLevel !== "" ){
            filterBaseParams += "&language_level="+filters.languageLevel
        }

        if( filters.labels.length > 0 ){
            for (let i = 0; i < filters.labels.length; i++) {
                filterBaseParams += "&contact_labels="+ filters.labels[i]
            }
        }
        return filterBaseParams
    }


    async function fetchEmployees(params, isFiltered) {
        setIsLoading(true)
        params = params || ""
        params += setParams()

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

       // add proper filters by view
       if (view === 0) params += ""
       if (view === 1) params += "&is_external=false"
       if (view === 2) params += "&is_external=true&is_freelancer=false"
       if (view === 3) params += "&is_freelancer=true"

        const request = await requests.get(API.EMPLOYEES + "?query={id, uuid, positions, skills, unit, is_active, is_external, fullname, profile_picture, contact_labels, personal_information{ person, contact_info }}&paginate=true" + params)
        if (request.status === 200) {
            setData(request.response.results);
            setRecordCount(request.response.count)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        const onfilterReset = async () => {
            if (filterReset === true) {
                history.replace({ pathname: location.pathname, search: `?page=${1}` })
                await fetchEmployees("", true);
                setFilterReset(false)
            }
        }
        onfilterReset()
        // eslint-disable-next-line
    }, [filterReset])

    useEffect(() => {
        const onViewChange = async () => {
            history.replace({ pathname: location.pathname, search: `?page=${1}` })
            await fetchEmployees("", true);
        }
        onViewChange()
        // eslint-disable-next-line
    }, [view])

    useEffect(() => {
        async function fetchEmployeesStats() {
            const request = await requests.get(API.EMPLOYEES_TOTAL_STATS)

            if (request.status === 200) {
                setEmployeesStats(request.response)
            }
        }

        fetchEmployeesStats()
    }, [])

    const onDelete = async (id) => {
        const request = await requests.del(API.EMPLOYEES + id + "/");

        if (request.status === 204) {
            setData(prevState => prevState.filter(item => item.id !== id))
        }
    }

    const onLabelsAdd = (id, labels) => {
        setData(prevState => prevState.filter(emp => {
            if (emp.id === id) {
                emp.contact_labels = labels
            }
            return emp;
        }))
    }

    const onLabelRemove = async (id, labelID) => {
        const request = await requests.patch(API.EMPLOYEES + id + "/?query={id, contact_labels}", {
            contact_labels: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setData(prevState => prevState.filter(emp => {
                if (emp.id === id) {
                    emp.contact_labels = request.response.contact_labels
                }
                return emp;
            }))
        }
    }

    return (
        <CanView permissions={["common.c_view_all_employees", "common.c_view_user_employees"]} redirect>
            <BreadcrumbNav
                items={[
                    { 'name': t("employees"), 'icon': icons.EMPLOYEES, 'href': '' }
                ]}
            />
            <Container fluid style={{ marginBottom: "5rem" }}>
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                    <Sidebar
                        style={{ width: "40rem", }}
                        as={Menu}
                        animation='overlay'
                        direction='right'
                        icon='labeled'
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        width='wide'
                    >
                        <EmployeeFilterForm
                            setVisible={setVisible}
                            fetchEmployees={fetchEmployees}

                            initState={initState}
                            filters={filters}
                            setFilters={setFilters}
                            setFilterReset={setFilterReset}
                        />
                    </Sidebar>
                    <Sidebar.Pusher>
                        <Segment basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }}>
                            <Grid style={{ marginBottom: "1rem" }}>
                                <Grid.Row style={{ padding: 0 }}>
                                    <Grid.Column computer={8} tablet={8} mobile={16} style={{ padding: 0 }}>
                                        <Button.Group basic size='small' style={{ marginBottom: '0rem', borderRadius: 0 }}>
                                            <Button style={{ color: 'var(--primary)' }} active={view === 0} onClick={() => setView(0)}>{t('all') + (employeesStats?.total_count ? " (" + employeesStats.total_count + ")" : "")}</Button>
                                            {employeesStats?.internal_count > 0 &&
                                                <Button style={{ color: 'var(--primary)' }} active={view === 1} onClick={() => setView(1)}>{t('internal') + (employeesStats?.internal_count ? " (" + employeesStats.internal_count + ")" : "")}</Button>
                                            }
                                            {employeesStats?.external_count > 0 &&
                                                <Button style={{ color: 'var(--primary)' }} active={view === 2} onClick={() => setView(2)}>{t('external') + (employeesStats?.external_count ? " (" + employeesStats.external_count + ")" : "")}</Button>
                                            }
                                            {employeesStats?.freelancer_count > 0 &&
                                                <Button style={{ color: 'var(--primary)' }} active={view === 3} onClick={() => setView(3)}>{t('self_employed') + (employeesStats?.freelancer_count ? " (" + employeesStats.freelancer_count + ")" : "")}</Button>
                                            }
                                        </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column computer={8} tablet={8} mobile={16} textAlign="right" style={{ padding: 0 }}>
                                        <Button icon={<Icon name="funnel-outline"/>} style={{ background: "transparent", paddingLeft: "1rem", paddingRight: "1rem" }} onClick={() => setVisible(!visible)}/>
                                        <CanView permissions={["common.c_manage_all_employees"]}>
                                            <Action
                                                as="modal"
                                                type="button"
                                                size="small"
                                                // modalSize="large"
                                                modal={<EmployeeForm />}
                                                primary
                                                buttonText={t('create_employee')}
                                            />
                                            {/* <Action as='link' type='buttonWithIcon' to={routes.CREATE_EMPLOYEE} buttonText={t('create_employee')} primary size="small" icon="plus" /> */}
                                        </CanView>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            {isLoading ? <Loading /> :
                                <>
                                    <EmployeesTable
                                        data={data}
                                        onDelete={onDelete}
                                        onLabelsAdd={onLabelsAdd}
                                        onLabelRemove={onLabelRemove}
                                    />
                                </>
                            }
                            <Paginator
                                forcePage={page || 1}
                                limit={limit}
                                setLimit={setLimit}
                                length={recordCount}
                                onChange={(params) => fetchEmployees(params)}
                            />
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Container>
        </CanView>
    );
};

export default EmployeesList;