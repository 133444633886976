import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// store
import { companyService } from '@store/services/ServiceCompany'
import { teamsService } from '@store/services/ServiceTeams'
// components
import { Grid } from 'semantic-ui-react'
import Team from './Team'
import HeaderWithButton from '../../../components/company/general/HeaderWithButton'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import AddTeamForm from '@components/forms/teams/AddTeamForm'
import OutlineAddButton from '@components/buttons/OutlineAddButton'
import CanView from '@components/perms/CanView';


const UnitDetailTeams = (props) => {
    const [teams, setTeams] = useState(props.teams ? props.teams : []);
    const [options, setOptions] = useState([]);
    const {t}=useTranslation()

    const onTeamAdd = (added_team) => {
        setTeams([...teams, added_team])
    }

    const onTeamRemove = async (id) => {
        const result = await companyService.updateUnitData(props.id, {
            teams: { remove: [id] }
        })

        if( result.status === 200 ){
            setTeams(prevState => prevState.filter(item => item.id !== id))
        }
    }

    useEffect(() => {
        async function getTeams(){
            const result = await teamsService.getTeams("?query={id,name,description}");

            if (result.status === 200) {

                let optionArray = [];
                for (let i = 0; i < result.response.length; i++) {
                    if( !teams.filter(item => item.id === result.response[i].id).length > 0 ){
                        optionArray.push(result.response[i]);
                    }
                }
                setOptions(optionArray);
            }
        }

        getTeams();
    },[teams])

    return (
        <Grid divided="vertically" style={styles.container}>
            <Grid.Row>
                <HeaderWithButton
                    textLabel={t('teams')}
                    buttonLabel={t('add_team')}
                    button={
                        <CanView permissions={["company.c_manage_units"]}>
                            <SuperDuperModal
                                trigger={
                                    <OutlineAddButton text={t('add_team')} size="small"/>
                                }
                                size="small"
                                header={t('add_team')}
                                content={
                                    <AddTeamForm
                                        btnName={t('add_team')}
                                        options={options}
                                        id={props.id}
                                        onConfirm={
                                            (added_team) => {
                                                onTeamAdd(added_team);
                                            }
                                        }
                                    />
                                }
                            />
                        </CanView>
                    }
                />
            </Grid.Row>
            <Grid.Row>
                { teams && teams.map((item, index) => (
                    <Grid.Column mobile={16} tablet={8} computer={4} key={index}>
                        <Team
                            data={item}
                            onDelete={(id) => onTeamRemove(item.id)}
                        />
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    )
}

const styles = {
    container: { padding: "3rem" },
}

export default UnitDetailTeams
