import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// store
import { getRequest } from '@services/ServiceCommon';
// components 
import SuperField from '@components/forms/SuperField';
import { Form } from 'semantic-ui-react';

const SkillSeniorityFilterField = ({ endpoint, value, onChange, isExact, setIsExact }) => {
    const { t } = useTranslation();
    const seniority = useSelector((state) => state.choices.seniority_level_types)
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])

    useEffect(() => {
        async function fetchSkills(){
            setLoading(true)
            const request = await getRequest(endpoint)

            if( request.status === 200 ){
                const skills = request.response
                const skillSeniorityOptions = []
                for (let i = 0; i < skills.length; i++) {
                    skillSeniorityOptions.push({ key: skills[i].id, value: skills[i].name, text: skills[i].name });
                    for (const property in seniority) {
                        skillSeniorityOptions.push({ key: skills[i].id, value: skills[i].name + "__" + property, text: skills[i].name + " - " + seniority[property] });
                    }
                }

                setOptions(skillSeniorityOptions)
            }

            setLoading(false)
        }

        fetchSkills()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <SuperField as="choice"
                search
                multiple
                disabled={loading}
                loading={loading}
                customOptions={options}
                label={ t('skills') }
                value={value}
                onChange={onChange}
            />
            <Form.Field>
                <SuperField as="checkbox"
                    label={t('all_selected_skills_must_match')}
                    checked={isExact}
                    onChange={setIsExact}
                />
            </Form.Field>
        </>

    );
};

export default SkillSeniorityFilterField;