import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
// components
import { Form, Header, Button, Divider, List } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
import SuggestionField from '@components/forms/common/SuggestionField'
import SkillSeniorityFilterField from '@components/forms/SkillSeniorityFilterField'

const CandidateFilterForm = ({ setVisible, fetchCandidates }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    // state
    const initState = {
        search: "",
        gender: "",
        country: "",
        education: "",
        city: "",
        state: "",
        experience: "",
        isExact: false,
        skills: [],
        labels: [],
        language: "",
        languageLevel: ""
    }

    const [filters, setFilters] = useState(initState)

    const onFilter = async (filters) => {
        setVisible(false)
        let filterBaseParams = ""

        if( filters.gender !== "" ){
            filterBaseParams += "&gender="+filters.gender
        }

        if( filters.country !== "" ){
            filterBaseParams += "&country="+filters.country
        }

        if( filters.education !== "" ){
            filterBaseParams += "&educations="+filters.education
        }

        if( filters.city !== "" ){
            filterBaseParams += "&city="+filters.city
        }

        if( filters.state !== "" ){
            filterBaseParams += "&state="+filters.state
        }

        if( filters.experience !== "" ){
            filterBaseParams += "&experiences="+filters.experience
        }

        if( filters.skills.length > 0){
            let skillsParams = ""
            for (let i = 0; i < filters.skills.length; i++) {
                if( i === 0 ){
                    skillsParams += filters.skills[i]
                } else {
                    skillsParams += "--" + filters.skills[i]
                }
            }

            if( filters.isExact ){
                filterBaseParams += "&skill_and_seniority_conjoined=" + skillsParams
            } else {
                filterBaseParams += "&skill_and_seniority=" + skillsParams
            }
        }

        if( filters.language !== "" ){
            filterBaseParams += "&language="+filters.language
        }

        if( filters.languageLevel !== "" ){
            filterBaseParams += "&language_level="+filters.languageLevel
        }

        if( filters.labels.length > 0 ){
            for (let i = 0; i < filters.labels.length; i++) {
                filterBaseParams += "&contact_labels="+ filters.labels[i]
            }
        }


        history.replace({ pathname: location.pathname, search: `?page=${1}` })
        await fetchCandidates("&search="+filters.search + filterBaseParams)
    }

    const resetFilters = async () => {
        setFilters(initState)
        setVisible(false)
        await onFilter(initState)
    }

    return (
        <Form onSubmit={() => onFilter(filters)} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }}/>
                    </List.Content>
                </List.Item>
            </List>

            <Divider/>
            <SuperField as="input"
                icon="search"
                iconPosition="left"
                label={t('search')}
                placeholder={t('search')}
                value={filters.search}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    search: value
                })}
            />

            <Form.Group widths="equal">
                <SuggestionField
                    label={t('city')}
                    endpoint={API.CANDIDATES + "filter_city"}
                    text="city"
                    search="city"
                    value={filters.city}
                    onSelect={(value) => setFilters({
                        ...filters,
                        city: value
                    })}
                />

                <SuggestionField
                    label={t('region')}
                    endpoint={API.CANDIDATES + "filter_state"}
                    text="state"
                    search="state"
                    value={filters.state}
                    onSelect={(value) => setFilters({
                        ...filters,
                        state: value
                    })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    type="countries"
                    label={t('country')}
                    value={filters.country}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        country: value
                    })}
                />

                <SuperField as="choice"
                    search
                    value={filters.education}
                    type="education_type_choices"
                    label={t('education')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        education: value
                    })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    value={filters.language}
                    type="language_choices"
                    label={t('language')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        language: value
                    })}
                />

                <SuperField as="choice"
                    search
                    value={filters.languageLevel}
                    type="language_level_choices"
                    label={t('language_level')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        languageLevel: value
                    })}
                />
            </Form.Group>

            <SkillSeniorityFilterField 
                endpoint={API.CANDIDATES + "filter_skills/"}
                value={filters.skills} 
                onChange={(e, { value }) => setFilters({...filters, skills: value})}
                isExact={filters.isExact}
                setIsExact={ () => setFilters({...filters, isExact: !filters.isExact})} 
            />

            <SuperField as="choice"
                search
                multiple
                value={filters.labels}
                label={t('labels')}
                endpoint={API.CONTACT_LABELS}
                text="name"
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    labels: value
                })}
            />
            <SuperField as="choice"
                value={filters.gender}
                type="genders"
                label={t('gender')}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    gender: value
                })}
            />

            <Form.Field>
                <Button.Group>
                    <Button size="small" primary> {t('apply')} </Button>
                    <Button type="button" onClick={resetFilters} size="small"> {t('reset')} </Button>
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default CandidateFilterForm;