import React, {useState}from 'react'
import { useTranslation } from 'react-i18next';
//store
import { isEmpty } from "@helpers/validation"
import { accountsService } from '@services/ServiceAccounts';
//components
import {  Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import AddTeamField from '@components/forms/common/AddTeamField';


export const AddTeamForm = ({ teams, data, onClose, onConfirm }) => {
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [id] = useState(data.id ? data.id : 0); // if 0 then its Create Form, else Update
    const [errorList, setErrorList] = useState([]); // empty array
    const [newTeam, setNewTeam] = useState(0)
    const [myTeams] = useState(teams ? teams : []); // empty array
    const {t}= useTranslation()

    const handleSubmit = async () => {
        setIsProcessing(true);

        const result = await accountsService.updateAccount(id,{
            teams: {add: [newTeam]}
        });

            if( result.status === 200 ) {
                onConfirm(result.response);
                onClose();
            } else {
                const error_message = result.response.teams.add;
                setErrorList([...errorList, error_message]);
            }


        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>

            { errorList.length > 0 && // TODO: apply some design for error handling, use Message Component from Semantic UI
                 (<Message negative>
                    { errorList.map( (error, index) =>
                       <p key={index}> {error} </p>
                    )}
                 </Message>)
            }

            <Form.Field >
                <AddTeamField
                    teams={myTeams}
                    required
                    value={newTeam}
                    setValue={(e, data) => setNewTeam(data.value)}
                    />
            </Form.Field>


            <Form.Field style={{ textAlign: "right"}}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing ||
                        isEmpty(newTeam) }
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}



