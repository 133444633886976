import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
// components
import Icon from '@components/Icon';
import { Button, Popup } from 'semantic-ui-react';
import ConfirmModal from '@components/modals/ConfrimModal';
import OutlineAddButton from '@components/buttons/OutlineAddButton'
import SuperDuperModal from '@components/modals/SuperDuperModal';

/*
AS - props `as` that is used to define render type, currently supported:
    - "modal" (SuperDuperModal)
    - "link" (Link)
    - "delete" (Delete confirm modal)
    - "confirm" (Confirm modal)
    - "custom" (To customize action)
TYPE - props `type` is used to define icon or button or buttonWithIcon
    - "button" need content - buttonText
    - "icon" need to define name of icon
    - "buttonWithIcon" need buttonText and icon
    - "addButton" = <OutlineAddButton /> need text
TO - props `to` is used to define URL
ICON - props `icon` is used to define icon name
MODAL - props `modal` is used to define component
BUTTONTEXT - props `buttonText` is used to define content of button
TOOLTIP - props `tooltip` is used to define popup content
TEXT - props `text` is used to define text which is used in component
SIZE - props `size` is used to define size of component
ONCLICK - props `onClick` is used to define action
BACKGROUNDCOLOR - props `backgroundColor` is used to define background color of component style
PADDINGLEFT & PADDINGRIGHT - props `paddingLeft` and `paddingRight` are used to define padding in style of component
*/

const Action = ({ as, type, to, icon, modal, buttonText, tooltip, iconColor, text, size, modalSize, onClick, backgroundColor, padding, paddingRight, paddingLeft, ...rest }) => {

    const { t } = useTranslation();

    const RenderModal = () => {
        return (
            <SuperDuperModal
                trigger={
                    type === 'button' ? <Button type="button" size={size ? size : "small"} primary content={buttonText}  style={{
                        paddingRight: paddingRight ? paddingRight : '0.785714em', paddingLeft: paddingLeft ? paddingLeft : '0.785714em' }} /> :
                    type === 'icon' ? <Button type="button" icon={<Icon name={icon} style={{ color: "var(--dark)" }}/>} style={{ background: "none", paddingRight:
                        paddingRight ? paddingRight : '0.785714em', paddingLeft: paddingLeft ? paddingLeft : '0.785714em',  color: iconColor ? iconColor : "var(--info)", }} /> :
                    type === 'addButton' ? <OutlineAddButton color="violet" text={buttonText ? buttonText : t('add')} size="small"  style={{
                        paddingRight: paddingRight ? paddingRight : '0.785714em', paddingLeft: paddingLeft ? paddingLeft : '0.785714em' }} /> :
                    type === 'customIcon' && <Button type="button" icon={icon} style={{ background: "none", paddingRight:
                        paddingRight ? paddingRight : '0', paddingLeft: paddingLeft ? paddingLeft : '0',  color: iconColor ? iconColor : "var(--info)", }} >
                            <Icon name={icon} /> </Button>
                }
                content={modal}
                size={modalSize || "small"}
                {...rest}
            />
        );
    }

    const RenderConfirm = () => {
        return (
            <ConfirmModal
                description={text}
                onConfirm={onClick}
                button={
                    type === 'icon' ?
                        <Button size={size ? size : "small"} icon={<Icon name={icon}/>} {...rest}
                            style={{
                                background: backgroundColor ? backgroundColor : "none",
                                color: iconColor ? iconColor : "var(--info)",
                                paddingRight: paddingRight ? paddingRight : 0,
                                paddingLeft: paddingLeft ? paddingLeft : 0
                            }}
                        />
                        : type === 'button' ?
                            <Button size={size ? size : "small"} content={buttonText} {...rest}
                                style={{
                                    paddingRight: paddingRight ? paddingRight : 0,
                                    paddingLeft: paddingLeft ? paddingLeft : 0
                                }}
                            />
                            :
                            <Button size={size ? size : "small"} icon={<Icon name={icon}/>} content={buttonText} {...rest}
                                style={{
                                    background: backgroundColor ? backgroundColor : "none",
                                    color: iconColor ? iconColor : "var(--info)",
                                    paddingRight: paddingRight ? paddingRight : 0,
                                    paddingLeft: paddingLeft ? paddingLeft : 0
                                }}
                            />
                }
            />
        );
    }

    const RenderCustom = () => {
        return (
            <>
                {type === 'icon' ?
                    <Button onClick={onClick} {...rest}
                        style={{ background: backgroundColor ? backgroundColor : "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0 }}
                    > {size ? <Icon name={icon} /> :
                        <Icon name={icon} />
                    }
                    </Button>
                    : type === 'button' ?
                        <Button {...rest} onClick={onClick} content={buttonText} size={size ? size : "small"}
                            style={{ background: backgroundColor ? backgroundColor : "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0 }}
                        />
                        :
                        <Button {...rest} onClick={onClick} content={buttonText} size={size ? size : "small"} icon={<Icon name={icon}/>}
                            style={{ background: backgroundColor ? backgroundColor : "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0 }}
                        />
                }
            </>
        );
    }

    const RenderLink = () => {
        return (
            <>
                {type === 'icon' ?
                    <Button {...rest} icon={icon ? <Icon name={icon}/> : <Icon name={"eye-outline"}/>} as={Link} to={to} size={size}
                        style={{ background: "none", color: iconColor ? iconColor : "var(--info)", paddingRight: paddingRight ? paddingRight : 0, paddingLeft: paddingLeft ? paddingLeft : 0 }}
                    />
                    : type === 'button' ?
                        <Button {...rest} as={Link} to={to} content={buttonText} size={size}
                            style={{ background: backgroundColor ? backgroundColor : "none", paddingRight: '1rem', paddingLeft: '1rem' }}
                        />
                        :
                        <Button {...rest} as={Link} to={to} content={buttonText} size={size} icon={<Icon name={icon}/>}
                            style={{ background: backgroundColor ? backgroundColor : "none", paddingRight: '1rem', paddingLeft: '1rem' }}
                        />
                }
            </>
        );
    }

    const RenderDelete = () => {
        return (
            <ConfirmModal
                description={text}
                onConfirm={onClick}
                button={
                    <Button
                        {...rest}
                        icon={icon ? <Icon name={icon}/> : <Icon name={"close-outline"}/>}
                        style={{
                            background: "none",
                            padding: padding ? padding : '0.785714em',
                            color: iconColor ? iconColor : "var(--danger)",
                            paddingRight: paddingRight ? paddingRight : '0.785714em',
                            paddingLeft: paddingLeft ? paddingLeft : '0.785714em'
                        }}
                    />
                }
            />
        );
    }

    const render = () => {
        switch (as) {
            case "modal":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable
                            content={tooltip}
                            trigger={<span> <RenderModal /> </span>} />
                        : <RenderModal />
                );
            case "link":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable hideOnScroll
                            content={tooltip}
                            trigger={<span><RenderLink /></span>} />
                        : <RenderLink />
                );
            case "delete":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable hideOnScroll
                            content={tooltip}
                            trigger={<span><RenderDelete /></span>} />
                        : <RenderDelete />
                );
            case "confirm":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 2 }} flowing size="small" inverted position="top center" hoverable hideOnScroll
                            content={tooltip}
                            trigger={<div> <RenderConfirm /> </div>} />
                        : <RenderConfirm />
                );
            case "custom":
                return (
                    tooltip
                        ? <Popup style={{ zIndex: 3 }} flowing size="small" inverted position="top center" hoverable hideOnScroll
                            content={tooltip}
                            trigger={<span><RenderCustom /></span>} />
                        : <RenderCustom />
                );
            default:
                return <></>;
        }
    };

    return <>{render()}</>;

};

export default Action;