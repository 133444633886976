import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { isEmailValid, isPhoneNumberValid } from '@services/helpers/validation';
import { createRequest, updateRequest } from '@services/ServiceCommon';
// components
import { Button, Divider, Form, Header, Segment, Grid } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import EmailField from "@components/forms/common/EmailField"
import PhoneField from "@components/forms/common/PhoneField"

const ContactForm = ({ contact, onClose, setContacts }) => {
    const { t } = useTranslation()

    const [data, setData] = useState({
        contact_info: {
            phones: contact?.contact_info.phones?.length > 0
                ? 
                {
                    id: contact?.contact_info.phones[0]?.id,
                    phone: contact?.contact_info.phones[0]?.phone,
                    phone_type: contact?.contact_info.phones[0]?.phone_type ? contact?.contact_info.phones[0]?.phone_type.id : ''
                }
                : { id: 0, phone: '', phone_type: '' },
            emails: contact?.contact_info.emails.length > 0 ? contact.contact_info.emails[0] : { id: 0, email: '', is_verified: false }
        },
        person: {
            firstname: contact?.person?.firstname ? contact.person?.firstname : '',
            lastname: contact?.person?.lastname ? contact.person?.lastname : '',
            // middlename: contact?.person?.middlename ? contact.person?.middlename : '',
            gender: contact?.person ? contact?.person?.gender : 3
        },
        name: contact?.name ? contact.name : '',
        description: contact?.description ? contact.description : '',
        // position: contact?.position ? contact.position : '',
        profile_picture: contact?.profile_picture ? contact.profile_picture : ''
    })

    const [stage, setStage] = useState(contact ? 1 : 0)// 0-chose type 1-form
    const [isProcessing, setIsProcessing] = useState(false)
    const [error, setError] = useState('')
    const [resourceType, setResourceType] = useState(contact?.resourcetype ? contact?.resourcetype : null)
    const [contactType, setContactType] = useState(contact?.contact_type ? contact?.contact_type : null)

    async function onSubmit() {
        setIsProcessing(true)
        let workingData = {
            description: data.description,
            resourcetype: resourceType,
            contact_type: contactType,
        }
        
        if (data.contact_info.phones.phone) {
            if( data.contact_info.phones.id === 0 ){
                workingData = { ...workingData, contact_info: { ...workingData.contact_info, phones: { create: [{ phone: data.contact_info.phones.phone, phone_type: data.contact_info.phones.phone_type }] }, }, }
            } else if( data.contact_info.phones.id > 0 ){
                workingData = { ...workingData, contact_info: { ...workingData.contact_info, phones: { update: {[data.contact_info.phones.id]: { phone: data.contact_info.phones.phone, phone_type: data.contact_info.phones.phone_type }} }, }, }
            }
        }
        if (data.contact_info.emails.email) {
            if( data.contact_info.emails.id === 0 ){
                workingData = { ...workingData, contact_info: { ...workingData.contact_info, emails: { create: [{ email: data.contact_info.emails.email, is_verified: false }] }, }, }
            } else if( data.contact_info.emails.id > 0 ) {
                workingData = { ...workingData, contact_info: { ...workingData.contact_info, emails: { update: {[data.contact_info.emails.id]: { email: data.contact_info.emails.email, is_verified: false} } }, }, }
            }
        }
        if (contactType === 4) {
            workingData = {
                ...workingData,
                name: data.name,
                contact_person: data.person
            }
        } else {
            workingData = {
                ...workingData,
                person: data.person,
                // position: data.position
                // profile_picture: data.profile_picture,
            }
        }

        if (contact) {
            const update = await updateRequest(API.CONTACTS + contact.id + "/", workingData)
            if (update.status === 200) {
                setContacts((prev) => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.filter(item => {
                            if (item.id === contact.id) {
                                item.contact_info = update.response.contact_info
                                item.description = update.response.description
                                if( contactType === 4 ){ // update account fields
                                    item.contact_person = update.response.contact_person
                                } else {
                                    item.person = update.response.person
                                }
                            }
    
                            return item
                        })
                    }
                }))
                onClose()
            } else {
                setError(update.response)
            }
            setIsProcessing(false)
            return
        }
        const request = await createRequest(API.CONTACTS, workingData)
        if (request.status === 201) {
            setContacts((prev) => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count + 1,
                    results: [request.response, ...prev.data.results]
                }
            }))
            onClose()
        } else {
            setError(request.response)
        }
        setIsProcessing(false)
    }

    const TypeSegment = ({ content, handleContactType, handleResourceType }) => (
        <Grid.Column mobile={16} tablet={16} computer={16}>
            <Segment
                onClick={() => {
                    setStage(1);
                    handleContactType("1");
                    handleResourceType("Contact")
                }}
                style={{ cursor: "pointer", fontWeight: "bold" }}
                className="segment-hover"
                textAlign="center"
                content={content}
            />
        </Grid.Column>
    )

    const isContactInfoValid = (phone, email) => {
        let isValid = false
        if( phone !== "" && email === "" ) {
            if( isPhoneNumberValid(phone) ){
                isValid = true
            }
        } else if( email !== "" && phone === "" ) {
            if( isEmailValid(email) ){
                isValid = true
            }
        } else if( email !== "" && phone !== "" ){
            if( isPhoneNumberValid(phone) && isEmailValid(email) ){
                isValid = true
            }
        }

        return isValid
    }

    function disabledButton() {
        // if account then check company name, firstname, lastname, gender and at least one contact info (phone/email)
        let isInvalid = true
        if ( 
            (data.person.firstname !== "" && data.person.lastname !== "") && 
            ((data.contact_info.emails.email !== "") || 
            (data.contact_info.phones.phone !== "" && data.contact_info.phones.phone_type !== "" ))
        ) {
            if( contactType === 4 && data.name !== "" ) {
                // check phone if is valid
                isInvalid = !isContactInfoValid(data.contact_info.phones.phone, data.contact_info.emails.email)

            } else if( contactType !== 4 ){
                isInvalid = !isContactInfoValid(data.contact_info.phones.phone, data.contact_info.emails.email)
            }
        }


        return isInvalid
    }

    return (
        <>
            {stage === 0 &&
                <Grid>
                    <Grid.Row>
                        <TypeSegment
                            content={t('candidate')}
                            handleContactType={() => setContactType(2)}
                            handleResourceType={() => setResourceType("PersonContact")}
                        />
                        {/* <TypeSegment
                            content={t('employee')}
                            handleContactType={() => setContactType(3)}
                            handleResourceType={() => setResourceType("PersonContact")}
                        /> */}
                    </Grid.Row>
                    <Grid.Row>
                        <TypeSegment
                            content={t('account')}
                            handleContactType={() => setContactType(4)}
                            handleResourceType={() => setResourceType("AccountContact")}
                        />
                    </Grid.Row>
                    <Grid.Row>
                        <TypeSegment
                            content={t('custom')}
                            handleContactType={() => setContactType(1)}
                            handleResourceType={() => setResourceType("PersonContact")}
                        />
                    </Grid.Row>
                </Grid>}
            {stage === 1 &&
                <Form onSubmit={onSubmit}>
                    <Header>
                        {contact ? t('update_contact') : t('create_new_contact')}
                        {contactType &&
                            <Header.Subheader>
                                {contactType === 1 && t('custom')}
                                {contactType === 2 && t('candidate')}
                                {contactType === 3 && t('employee')}
                                {contactType === 4 && t('account')}
                            </Header.Subheader>
                        }
                    </Header>
                    {/* NAME */}
                    {contactType === 4 &&
                        <SuperField
                            as="input"
                            required
                            label={t("company_name")}
                            placeholder={t("company_name")}
                            value={data.name}
                            onChange={(e, { value }) => setData({ ...data, name: value })}
                        />
                    }
                    {/* <Divider horizontal >{contactType !== 4 ? t("personal_information") : t("contact_person")}</Divider> */}
                    <Divider/>
                    <Form.Group widths="equal">
                        <SuperField
                            as="input"
                            required
                            label={t("firstname")}
                            placeholder={t("firstname")}
                            value={data.person.firstname}
                            onChange={(e, { value }) => setData({ ...data, person: { ...data.person, firstname: value } })}
                        />
                        {/* <SuperField
                            as="input"
                            label={t("middlename")}
                            placeholder={t("middlename")}
                            value={data.person.middlename}
                            onChange={(e, { value }) => setData({ ...data, person: { ...data.person, middlename: value } })}
                        /> */}
                        <SuperField
                            as="input"
                            required
                            label={t("lastname")}
                            placeholder={t("lastname")}
                            value={data.person.lastname}
                            onChange={(e, { value }) => setData({ ...data, person: { ...data.person, lastname: value } })}
                        />
                    </Form.Group>
                    <Form.Group style={{ display: 'block', paddingLeft: '0.5rem' }}>
                        <b>{t('gender')}</b>
                        <div style={{ display: 'flex' }}>
                            <SuperField as='radio' label={t('male')} checked={data.person.gender === 1} onChange={() => setData({ ...data, person: { ...data.person, gender: 1 } })} style={{ marginRight: '1rem' }} />
                            <SuperField as='radio' label={t('female')} checked={data.person.gender === 2} onChange={() => setData({ ...data, person: { ...data.person, gender: 2 } })} />
                        </div>
                    </Form.Group>

                    {/* ABOUT */}
                    <Form.Group widths='equal'>
                        <SuperField
                            as="textarea"
                            label={t("about")}
                            placeholder={t("about")}
                            value={data.description}
                            onChange={(e, { value }) => setData({ ...data, description: value })}
                        />
                    </Form.Group>
                    {/* CONTANCT */}
                    {/* <Divider horizontal style={{ paddingTop: '1rem' }}>
                        {t("contact_information")}
                    </Divider> */}
                    <Divider/>
                    <Form.Group widths="equal">
                        <PhoneField
                            placeholder={t('enter_number')}
                            value={data.contact_info.phones.phone}
                            setValue={(e, {value}) => setData({ ...data, contact_info: { ...data.contact_info, phones: { ...data.contact_info.phones, phone: value } } })}
                            phoneType={data.contact_info.phones.phone_type}
                            setPhoneType={(e, {value}) => setData({ ...data, contact_info: { ...data.contact_info, phones: { ...data.contact_info.phones, phone_type: value } } })}
                        />
                        <EmailField
                            label={t('email')}
                            value={data.contact_info.emails.email}
                            setValue={(e) => setData({ ...data, contact_info: { ...data.contact_info, emails: { ...data.contact_info.emails, email: e.target.value } } })}
                        />
                    </Form.Group>
                    {contactType !== 4 &&
                        <>
                            {/* <Divider horizontal style={{ paddingTop: '1rem' }}>
                                {t("additional_information")}
                            </Divider> */}
                            {/* <Form.Group widths="equal"> */}
                                {/* <SuperField
                                    as="input"
                                    label={t("position")}
                                    placeholder={t("position")}
                                    value={data.position}
                                    onChange={(e, { value }) => setData({ ...data, position: value })}
                                /> */}
                                {/* <SuperField
                                    as="input"
                                    label={t("profile_picture_url")}
                                    placeholder={t("profile_picture_url")}
                                    value={data.profile_picture}
                                    onChange={(e, { value }) => setData({ ...data, profile_picture: value })}
                                /> */}
                            {/* </Form.Group> */}
                        </>
                    }
                    <span style={{ color: 'var(--danger)' }}>
                        {error && t("check_if_all_required_fields_are_filled_or_in_correct_format")}
                    </span>
                    <Divider/>

                    <Button.Group floated="right" style={{ marginBottom: "1rem" }}>
                        <ModalCancel onClose={onClose} />
                        <ModalSubmit
                            text={t("create")}
                            disabled={
                                isProcessing || 
                                disabledButton() 
                            }
                            loading={isProcessing}
                        />
                    </Button.Group>
                </Form>}
        </>
    )
}

export default ContactForm
