import React from "react";
import { useTranslation } from "react-i18next";
// store
import { accountsService } from "@services/ServiceAccounts";
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import AvatarIcon from '@components/AvatarIcon';
import ConfrimModal from "@components/modals/ConfrimModal";
// eslint-disable-next-line
import { Grid, Header, Button, Item, Menu } from "semantic-ui-react";
import CanView from '@components/perms/CanView';

const AccountHeader = (props) => {
    const { t }=useTranslation()
    const canManage = useHasPermissions(["accounts.c_manage_all_accounts", "accounts.c_manage_user_accounts", "accounts.c_manage_assigned_accounts"]);

    const uploadLogo = async (image) => {
        const formData = new FormData();
        formData.append("logo", image);
        return await accountsService.uploadLogo(props.data.id, formData);
    }

    const deleteLogo = async () => {
        return await accountsService.deleteLogo(props.data.id);
    }


    return (
        <Grid centered className="header-block">
            <Grid.Column computer={4} tablet={16} mobile={16} style={{ paddingLeft: "1rem", paddingTop: "4.3rem", paddingBottom: "2rem" }}>
                <AvatarIcon size={200} active={props.data.is_active} name={props.data.name || "problem"} src={props.data.logo ? props.data.logo : ''} handleUpload={uploadLogo} handleDelete={deleteLogo} uploadable={canManage} />
            </Grid.Column>
            <Grid.Column computer={10} tablet={10} mobile={16} style={{ paddingTop: "3rem" }}>
                <Grid ><Header as="h1" style={{ fontSize: '3.5rem' }}>{props.data.name}</Header>{props.action} </Grid>
                <i style={{ margin: "1rem" }}>
                    <span style={{ paddingRight: "1rem", fontSize: '1.2rem' }} > {t('created_text')} </span>
                    <span style={{ fontSize: "1.3rem", paddingRight: "1rem", }}>
                        { props.data?.dts?.created_on }
                    </span>
                    <span style={{ paddingRight: "1rem", fontSize: '1.2rem' }} >
                        { t('by') }
                    </span>
                    <span style={{ fontSize: "1.3rem" }}>
                        <Button icon={<Icon name="person-outline" style={{ marginRight: "0.5rem" }}/>} size="tiny" content={props.data?.dts?.created_by?.name}/>
                    </span>
                </i>
                {/* <Grid>
                    <Grid.Row>
                        <Menu compact style={{ color: 'black', marginLeft: '0.5rem' }}>
                            <Menu.Item as='a' >
                                <Icon name={props.data.status_display === 1 ? 'lock open' : 'lock'} style={{ color: props.data.status === 1 ? 'var(--success)' : 'var(--danger)' }} />
                                <span style={{ paddingRight: "1rem", color: props.data.status === 1 ? 'var(--success)' : 'var(--danger)' }}> {props.data.status === 1 ? t('open') : t('closed')} </span>
                            </Menu.Item>

                            <Menu.Item as='a' >
                                <Icon name={props.data.is_active ? 'check' : 'delete'} style={{ color: props.data.is_active ? 'var(--success)' : 'var(--danger)' }} />
                                <span style={{ paddingRight: "1rem", color: props.data.is_active ? 'var(--success)' : 'var(--danger)' }}> {props.data.is_active ? t('active') : t('inactive')} </span>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Icon name='trophy' style={{ color: 'var(--variant1)' }} />
                                <span style={{ paddingRight: "1rem", color: 'var(--variant1)' }}> { t('rank') }</span>
                                <span style={{ paddingRight: "1rem", color: 'var(--variant1)' }}> - {props.data.rank} -</span>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Icon name='chart bar' style={{ color: 'var(--variant1)' }} />
                                <span style={{ paddingRight: "1rem", color: 'var(--variant1)' }}> {props.data.account_type_display}</span>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Icon name='factory' style={{ color: 'var(--variant1)' }} />
                                <span style={{ paddingRight: "1rem", color: 'var(--variant1)' }}> {props.data.industry_display} </span>
                            </Menu.Item>
                            <Menu.Item as='a'>
                                <Icon name='building outline' style={{ color: 'var(--variant1)' }} />
                                <span style={{ paddingRight: "1rem", color: 'var(--variant1)' }}> { t('size') } </span>
                                <span style={{ paddingRight: "1rem", color: 'var(--variant1)' }}> {props.data.company_size_display} </span>
                            </Menu.Item>
                        </Menu>
                    </Grid.Row>
                </Grid> */}
                {/* Responsible persons */}
                <Item style={{ padding: '0.5rem' }}>
                    <Item.Content>
                        {props.data.assigned_to && props.data.assigned_to.map((item, index) =>
                            <Button.Group key={index} size='small' style={{ marginRight: '0.5rem', marginBottom: '0.3rem' }}>
                                <Button key={index} icon={<Icon name="person-outline" style={{ marginRight: "0.5rem" }}/>} content={item.name} />
                                <CanView permissions={["accounts.c_manage_assigned_accounts", "accounts.c_manage_all_accounts"]}>
                                    <ConfrimModal
                                        onConfirm={() => props.functionDeletePerson(item.id)}
                                        button={
                                            <Button
                                                icon={<Icon name="close-outline" style={{ marginLeft: "0.3rem", marginRight: "0.2rem" }}/>}
                                                size='small'
                                                style={{
                                                    alignItems: 'center', color: 'var(--danger)',
                                                    paddingLeft: 0, marginLeft: 0,
                                                    paddingRight: 2, marginRight: 0
                                                }}
                                            />
                                        }
                                        description={t('delete_person')}
                                    />
                                </CanView>
                            </Button.Group>
                        )}
                        {props.actionAddPerson}
                    </Item.Content>
                </Item>
                {/* teams */}
                <Item style={{ padding: '0.5rem' }}>
                    <Item.Content>
                        {props.data.teams && props.data.teams.map((item, index) =>
                            <Button.Group key={index} size='small' style={{ marginRight: '0.5rem', marginBottom: '0.3rem' }}>
                                <CanView permissions={["accounts.c_manage_assigned_accounts", "accounts.c_manage_all_accounts"]}>
                                    <Button key={index} icon={<Icon name="person-outline" style={{ marginRight: "0.5rem" }}/>} size="tiny" content={item.name} />
                                    <ConfrimModal
                                        onConfirm={() => props.functionDeleteTeam(item.id)}
                                        button={
                                            <Button
                                                icon={<Icon name="close-outline" style={{ marginLeft: "0.3rem", marginRight: "0.2rem" }}/>}
                                                size='small'
                                                style={{
                                                    alignItems: 'center', color: 'var(--danger)',
                                                    paddingLeft: 0, marginLeft: 0,
                                                    paddingRight: 2, marginRight: 0
                                                }}
                                            />

                                        }
                                        description={t('delete_team')}
                                    />
                                </CanView>
                            </Button.Group>
                        )}
                        <CanView permissions={["teams.c_view_user_teams", "teams.c_view_all_teams"]}>
                            {props.actionAddTeam}
                        </CanView>
                    </Item.Content>
                </Item>
                {props.data.website ?
                    <Item style={{ padding: '0.5rem' }}>
                        <Item.Content>
                            <Button icon='globe' as='a' href={props.data.website} target="_blank" size="small" content={t('visit_website')}/>
                        </Item.Content>
                    </Item>
                    :
                    <Item style={{ padding: '0.5rem' }}>
                        <Item.Content>
                            {props.actionAddWebsite}
                        </Item.Content>
                    </Item>
                    }
            </Grid.Column>
        </Grid>
    )

}

export default AccountHeader;
