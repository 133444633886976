import React from 'react';
import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';
import { Button, Popup } from 'semantic-ui-react';

const CandidateHistoryAction = ({...rest}) => {
    const { t } = useTranslation();

    return (
        <Popup

            className="sidebar-item-popup"
            trigger={
                <Button {...rest} icon={<Icon name="albums-outline"/>} style={{ background: "none", color: "var(--info)", paddingRight: 1, paddingLeft: 0 }} />
            }
            flowing
            size="small"
            inverted
            position="top center"
            hoverable>
            {t('history')}
        </Popup>
    );
};

export default CandidateHistoryAction;