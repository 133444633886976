import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests, fileDownload } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';
import Loading from "@components/general/Loading";
import EmptyRow from '@components/tables/EmptyRow';
import { Container, Divider, Segment, Popup } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';


const AssetsSection = ({ data }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [assets, setAssets] = useState([])
    const [loading, setLoading] = useState(true)
    const date_format = useSelector(state => state.date_format)

    const fetchData = async (params, isFiltered) => {

        setLoading(true)
        params = params || ""
        if (isFiltered) {
            setPage(1)
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            setPage(param !== null ? param : 1)
        }
        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        const query = "&query={id, quantity, valid_until, note, stored_item{id, item, sn, code, note}}"
        const request = await requests.get(API.ASSET_ISSUED_ITEMS + "?paginate=true&status=1&employee=" + data?.id + params + query)

        if (request.status === 200) {
            setAssets({
                ...assets,
                data: request.response
            })
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchData('', true)
        // eslint-disable-next-line
    }, [])

    const showAssignedItems = async (id) => {
        await fileDownload("GET", API.API_URL + `/exports/not_returned_items/employee/${id}/?is_blob=true`, "application/pdf", `assigned_items_${id}.pdf`)
    }

    return (
        <CanView permissions={['assets.c_view_all_issued_items', 'assets.c_view_user_issued_items']}>
            <Container fluid>
                <Segment style={{ boxShadow: "none", border: "none", padding: 0, background: "transparent"}}>
                    { loading ? <Loading /> :
                        <>
                            <div style={{ textAlign: "left", marginBottom: "1rem", fontSize: "1rem"}}>
                                <Popup
                                    position="top center"
                                    trigger={
                                        <Icon name="download-outline" style={{ cursor: "pointer", fontSize: "1.5rem" }} onClick={() => showAssignedItems(data?.id)}/>
                                    }
                                    content={t('download')}
                                />
                            </div>
                            <FlexTable>
                                <FlexRow>
                                    <FlexHeader basis="40%" content={t('code')} />
                                    <FlexHeader content={t('item')} />
                                    <FlexHeader content={t('serial_number')} />
                                    <FlexHeader content={t('quantity')} />
                                    <FlexHeader content={t('category')} />
                                    <FlexHeader content={t('valid_until')} />
                                </FlexRow>
                                <EmptyRow length={assets?.data?.count || 0}/>
                                {assets?.data?.results?.map(asset => (
                                    <FlexRow borders background="transparent" fontSize="0.9rem">
                                        <FlexItem basis="40%" bold>
                                            { asset?.stored_item?.code ? asset?.stored_item?.code : "~"}
                                        </FlexItem>
                                        <FlexItem bold>
                                            { asset?.stored_item?.item?.title }
                                        </FlexItem>
                                        <FlexItem>
                                            { asset?.stored_item?.sn || "" }
                                        </FlexItem>
                                        <FlexItem>
                                             { asset?.quantity }
                                        </FlexItem>
                                        <FlexItem>
                                             { asset?.stored_item?.item?.category?.title || "~" }
                                        </FlexItem>
                                        <FlexItem>
                                             { asset.valid_until ? moment(asset.valid_until).format(date_format) : "--" }
                                        </FlexItem>
                                    </FlexRow>
                                ))}
                            </FlexTable>
                        </>
                    }
                    <Paginator
                        forcePage={page || 1}
                        forceReload={false}
                        limit={limit}
                        setLimit={setLimit}
                        length={assets?.data?.count || 0}
                        onChange={(params) => fetchData(params)}
                    />
                </Segment>
            </Container>
            <Divider />
        </CanView>
    );
};

export default AssetsSection;