import React, { Fragment }from 'react';
import { Card, Grid, Icon  } from 'semantic-ui-react'

function DashboardStatistics(props) {

    return (
        <Grid.Column style={{ minWidth: "25%", maxWidth: "25%" }} mobile={16} tablet={4} computer={3}>
            <Card as='button' 
            // onClick={() => props.handleStageFiltration(props.id)} 
            fluid style={{ borderRadius: "0rem", borderBottom: "4px solid #eeeeee", backgroundColor:props.bcolor }} >
                <Grid>
                    <Grid.Row style={{ padding: 0, paddingBottom:"1rem", marginLeft:"1rem" }}>
                        <Card.Description style={{ marginTop: "1rem" , fontWeight:"bold", color:"grey"}}>{props.description}</Card.Description>
                            <Grid.Column floated="right" style={{ paddinRight: "0.5rem", marginRight: "2rem", marginTop:"1rem" }}>
                                <Icon  size="large" name={props.icon} style={{ color: "grey" }} />
                            </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ padding: 0, marginBottom:"1rem" }}>
                        <Grid.Column style={{ paddingLeft: "1.5rem", paddingRight: "2.5rem", marginTop: "0.5rem"}}>
                            <Card.Header style={{ fontSize: "2.5rem" , fontWeight:"bold" }} > {props.number}</Card.Header>
                        </Grid.Column> 
                        
                        { (props.plus && props.number2) &&
                            <Grid.Column mobile={16} tablet={4} computer={10} floated="right" style={{ textAlign: "right", padding: 0, marginTop: "1.5rem", marginRight:"1.5rem" }}>
                                <Fragment style={{ marginTop: "1rem", fontSize:"1.5rem", color: "var( --success)", display:"inline"}}>
                                    <Icon  name="arrow up" style={{ color: "var( --success)" }} /> 
                                    <span style={{ fontSize:"1.5rem", color: "var( --success)"}}> { props.number2}</span>
                                </Fragment>
                            </Grid.Column>
                        }
                        
                        {/* { (!props.plus && props.number2 ) &&
                            <Grid.Column mobile={16} tablet={4} computer={10} floated="right" style={{ padding: 0, marginTop: "1.5rem"}}>
                                <Fragment style={{ marginTop: "1rem", fontSize:"1.5rem", color: "var( --danger)", display:"inline"}}>
                                    <Icon  name="arrow down" style={{ color: "var( --danger)" }} /> 
                                    <span style={{ fontSize:"1.5rem", color: "var( --danger)"}}> { props.number2 }</span>
                                </Fragment>
                            </Grid.Column>
                        } */}

                        { !props.number2 && 
                            <Grid.Column floated="right" style={{ padding: 0, marginTop: "1.2rem",  marginRight:"1.5rem"}}>
                                <span style={{ marginTop: "1rem", fontSize:"2rem", color: "grey"}}> - </span>
                            </Grid.Column>
                        }
                        
                        
                    </Grid.Row>
                </Grid>
            </Card>
        </Grid.Column>
    );
}

export default DashboardStatistics;