import React from 'react';
import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';
import { Label } from 'semantic-ui-react';
import Phone from '@components/general/Phone'

const ContactCell = ({ row }) => {
    const { t } = useTranslation();

    return (
        <>
            {row.phone || row.email ?
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {row.phone &&
                        <Phone phone={row.phone}/>
                    }

                    {row.email &&
                        <a href={"mailto:" + row.email}>
                            <Icon name="mail-outline"  style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.2rem" }}>{row.email}</span>
                        </a>
                    }
                </div>
                : <Label style={{ borderRadius: 0, color: "var(--light)", background: "var(--danger)" }}>{t('missing_contact')}</Label>}
        </>
    );
};

export default ContactCell;