import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// store
import { routes } from '@store/routes';
// components 
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific components
import OrderEmploymentDetail from '../OrderEmploymentDetail';
import EmploymentOrderForm from '../forms/EmploymentOrderForm';

const EmploymentOrdersTable = ({ result, setResult, onDelete, onOrderConfirm }) => {
    const { t } = useTranslation();

    return (
        <FlexTable>
            <FlexRow>
                <FlexHeader content={t('order_number')} basis="50%"/>
                <FlexHeader content={t('name')} basis="150%"/>
                <FlexHeader content={t('account')} basis="120%"/>
                <FlexHeader content={t('ordered_people')} basis="120%"/>
                <FlexHeader content={t('order_manager')}/>
                <FlexHeader content={t('state')}/>
                <FlexHeader content={t('status')}/>
                <FlexHeader content={t('actions')}/>
            </FlexRow>
            <EmptyRow length={result.data?.results?.length}/>
            { result.data?.results.map( order => (
                <FlexRow key={order.id} fontSize="1rem" borders >
                    <FlexItem content={ order.order_number } basis="50%"/>
                    <FlexItem bold basis="150%">
                        { order.name }
                    </FlexItem>
                    <FlexItem basis="120%">
                        { order?.account?.id ?
                            <>
                                <Link target="_blank" to={routes.ACCOUNTS_DETAIL + order.account.id}>
                                    <strong>{ order.account?.name }</strong>
                                </Link> <br/>
                                <small> { order.account?.is_supplier ? t('supplier') : t('account') } </small>
                            </>
                            : "--"
                        }
                    </FlexItem>
                    <FlexItem basis="120%">
                        <Icon name="people-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ order.items?.length }</span> <br/>
                        <SuperDuperModal
                            size="mini"
                            header={t('ordered_people')}
                            trigger={
                                <small style={{ color: "var(--primary)" }} className="ref-link"> {t('show_ordered_people')} </small>
                            }
                            content={
                                <div>
                                    <FlexRow background="transparent">
                                        <FlexHeader content={t('employee')}/>
                                        <FlexHeader textAlign="right" content={t('fee')}/>
                                    </FlexRow>
                                    { order.items?.map(item => (
                                        <FlexRow key={item.id} background="transparent" borders>
                                            <FlexItem>
                                                <Link target="_blank" to={routes.EMPLYOEES_DETAIL + item.profile.id}>
                                                    { item.profile.fullname }
                                                </Link>
                                            </FlexItem>
                                            <FlexItem textAlign="right">
                                                { item.fee_per_hour.sum } { item.fee_per_hour.currency }
                                            </FlexItem>
                                        </FlexRow>
                                    )) }
                                </div>
                            }
                        />
                    </FlexItem>
                    <FlexItem bold>
                        { order?.order_manager?.id ?
                            <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order?.order_manager?.id}>
                                <span>{ order?.order_manager?.fullname }</span>
                            </Link>
                            : "--"
                        }
                    </FlexItem>
                    <FlexItem>
                        { order.confirmed_on === null 
                            ? 
                            <>
                                {t('not_confirmed')} 
                                <CanView permissions={['orders.c_confirm_order']}>
                                    <br/>
                                    <ConfirmModal
                                        description={t('confirm_message')}
                                        onConfirm={() => onOrderConfirm(order.id)}
                                        button={
                                            <small style={{ color: "var(--primary)" }} className="ref-link"> {t('add_confirmation')} </small>
                                        }
                                    />
                                </CanView>
                            </>
                            : 
                            <>
                                <strong>{ order?.order_status_display }</strong> <br/>
                                <small> { t('confirmed_on') } { moment(order.confirmed_on).format("DD.MM.YYYY") } </small>

                            </>
                        }
                    </FlexItem>
                    <FlexItem>
                        <Icon name="ellipse"color={ order.is_active ? "green" : "red"} style={{ marginRight: "0.5rem" }}/>
                        <span style={{ position: "relative", top: "-0.2rem" }}>
                            { order.is_active ? t('active_order') : t('closed_order') }
                        </span>
                    </FlexItem>
                    <FlexItem>
                        <FlexRow background="transparent" padding="0">
                            <FlexItem>
                                <SuperDuperModal
                                    size="large"
                                    trigger={
                                        <Icon name="eye-outline" style={{ color: "var(--primary)", cursor: "pointer" }}/>
                                    }
                                    content={
                                        <OrderEmploymentDetail order={order} setResult={setResult}/>
                                    }
                                />
                            </FlexItem>
                            <CanView permissions={['orders.c_manage_order']}>
                                <FlexItem>
                                    <SuperDuperModal
                                        trigger={
                                            <Icon name="pencil-outline" style={{ cursor: "pointer" }}/>
                                        }
                                        size='small'
                                        header={t('update_order')}
                                        content={
                                            <EmploymentOrderForm
                                                order={order}
                                                setResult={setResult}
                                            />
                                        }
                                    />
                                </FlexItem>
                            </CanView>
                            <CanView permissions={['orders.c_delete_order']}>
                                <FlexItem>
                                    <ConfirmModal
                                        description={t('delete_message_order')}
                                        onConfirm={() => onDelete(order.id)}
                                        button={
                                            <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }}/>
                                        }
                                    />
                                </FlexItem>
                            </CanView>
                        </FlexRow>
                    </FlexItem>
                </FlexRow>
            ))}
        </FlexTable>
    );
};

export default EmploymentOrdersTable;