import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config'
import { useQueryPage, useFetchData, useHasPermissions } from '@helpers/hooks';
import { deleteRequest, createRequest, getRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import EmptyRow from '@components/tables/EmptyRow';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
// module specific Components
import JobOfferPreview from './JobOfferPreview'
import JobFilterSection from './JobFilterSection'
import PublicationList from '../views/PublicationList';

const JobsTable = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()
    let page = useQueryPage()
    const canViewHiringRoom = useHasPermissions(['candidates.c_view_recruitment']);
    const [limit, setLimit] = useState(10)
    const [result, setResult] = useFetchData(API.JOB_OFFERS + "?paginate=true&page=" + page + "&limit="+limit)
    const [loading, setLoading] = useState(false)
    const [reFetched, setReFetched] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState(1)

    const initState = {
        country: "",
        city: "",
        state: "",
        title: "",
        valid_for_disabled_person: false,
        valid_for_graduate: false,
        valid_for_student: false,
        recruitment: ""
    }

    const [filters, setFilters] = useState(initState)

    useEffect(() => {
        async function handleViewChange(){
            if (!result.isLoading) {
                history.replace({ pathname: location.pathname, search: `?page=${1}` });
                await fetchJobOffers("", true)
            }
        }

        handleViewChange()
        // eslint-disable-next-line
    }, [selectedFilter])


    const onFilter = () => {
        let filterBaseParams = "&title="+filters.title

        if(selectedFilter===2){
            filterBaseParams += "&is_draft=true"
        }
        if(selectedFilter===3){
            filterBaseParams += "&is_published=true"
        }

        if( filters.city !== ""){
            filterBaseParams += "&city="+filters.city
        }
        if( filters.state !== ""){
            filterBaseParams += "&state="+filters.state
        }
        if( filters.country !== "" ){
            filterBaseParams += "&country="+filters.country
        }
        if( filters.valid_for_disabled_person !== false ){
            filterBaseParams += "&valid_for_disabled_person="+filters.valid_for_disabled_person
        }
        if( filters.valid_for_graduate !== false ){
            filterBaseParams += "&valid_for_graduate="+filters.valid_for_graduate
        }
        if( filters.valid_for_student !== false ){
            filterBaseParams += "&valid_for_student="+filters.valid_for_student
        }
        if( filters.recruitment !== "" ){
            filterBaseParams += "&recruitment="+filters.recruitment
        }

        return filterBaseParams
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.GET_JOB_OFFERS + id + "/",);

        if( request.status === 204 ){
            const filteredData = result.data.results.filter( offer => offer.id !== id )

            setResult({
                ...result,
                data: {
                    ...result.data,
                    count: result.data.count - 1,
                    results: filteredData
                }
            })
        }
    }

    const fetchJobOffers = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()

        if (!loading){
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if ( !params.includes('page') ) {
                params += "&page=" + page
            }

            if ( !params.includes('limit')){
                params += "&limit=" + limit
            }

        setResult(prevState => ({
            ...prevState,
            isLoading: true
        }))

        const request = await getRequest(API.GET_JOB_OFFERS + "?paginate=true" + params)

        if (result.status === 403) {
            history.push(routes.DASHBOARD)
            return
        }

        if( request.status === 200 ){
            setResult(prevState => ({
                ...prevState,
                isLoading: false,
                data: request.response
            }))
        }
        setReFetched(false)
        setLoading(false)
        }
    }

    return (
        <>
        <CanView permissions={["jobs.c_view_job_offers"]} redirect>
        {result.isLoading ? <Loading /> :
            (!reFetched && !loading) &&
            <>
            <JobFilterSection
                result={result}
                fetchJobOffers={fetchJobOffers}
                filters={filters}
                setFilters={setFilters}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
            />
            <FlexTable>
                <FlexRow>
                    <FlexHeader content="" basis="30%"/>
                    <FlexHeader content={t('title')} basis="150%"/>
                    <FlexHeader content={t('workplace')}/>
                    <FlexHeader content={t('language')}/>
                    <FlexHeader content={t('open_positions')}/>
                    <FlexHeader content={t('hiring_room')}/>
                    <FlexHeader content={t('publications')}/>
                    <FlexHeader content={t('actions')}/>
                </FlexRow>
                <EmptyRow length={result?.data?.results || 0}/>
                { result.data.results.map( (offer, index) => (
                    <FlexRow key={offer.id} fontSize="1rem" borders>
                        <FlexItem bold basis="30%">
                            { offer.is_draft && <span style={{ color: "var(--danger)", float: "right", marginRight: "1rem" }}>({ t('draft') })</span> }
                        </FlexItem>
                        <FlexItem basis="150%">
                            <SuperDuperModal
                                size="large"
                                trigger={
                                    <span className="ref-link">{ offer.title }</span>
                                }
                                content={
                                    <JobOfferPreview offer={offer}/>
                                }
                            />
                        </FlexItem>
                        <FlexItem bold>
                            <span>
                            {
                                offer?.workplace?.address?.city && offer?.workplace?.address?.country_display ?
                                <>
                                    <Icon name="location-outline" style={{ marginRight: "0.5rem" }}/>
                                    <span style={{ position: "relative", top: "-0.2rem" }}>{offer?.workplace?.address?.city}, {offer?.workplace?.address?.country_display}</span>
                                </>
                                : "--"
                            }
                            </span>
                        </FlexItem>
                        <FlexItem bold content={ offer.in_language_display }/>
                        <FlexItem
                            bold
                            content={ <> <Icon name="people-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ offer.number_of_openings }</span> </> }
                        />
                        <FlexItem bold>
                            { offer.recruitment && canViewHiringRoom ?
                                <Link to={routes.HIRING_ROOMS + offer?.recruitment?.id}>
                                   {t('room')} #{ offer?.recruitment?.id }
                                </Link>
                                : <>--</>
                            }
                        </FlexItem>
                        <FlexItem bold>
                            <Icon name="pricetags-outline" style={{ marginRight: "0.5rem" }}/> 
                            <span style={{ position: "relative", top: "-0.1rem" }}>{ offer?.publication_count > 0 ? offer?.publication_count : 0 }</span>

                            <SuperDuperModal
                                size="large"
                                trigger={
                                    <Icon name="eye-outline" style={{ color: "var(--primary)", cursor: "pointer", marginLeft: "1.5rem" }}/>
                                }
                                content={
                                    <PublicationList offer={offer}/>
                                }
                            />
                        </FlexItem>
                        <FlexItem>
                            <FlexRow background="transparent" padding="0">
                                <FlexItem>
                                    <SuperDuperModal
                                        size="large"
                                        trigger={
                                            <Icon name="eye-outline" style={{ color: "var(--primary)", cursor: "pointer" }}/>
                                        }
                                        content={
                                            <JobOfferPreview offer={offer}/>
                                        }
                                    />
                                </FlexItem>
                                <CanView permissions={["jobs.c_manage_job_offers"]}>
                                    <FlexItem>
                                        <ConfirmModal
                                            description={t('duplicate_offer_confirm')}
                                            onConfirm={async () => {
                                                const request = await createRequest(API.GET_JOB_OFFERS + offer.id + "/duplicate/")

                                                if( request.status === 201 ){
                                                    history.push(routes.UPDATE_JOB_OFFER + request.response.id + "/")
                                                }
                                            }}
                                            button={
                                                <Icon name="copy-outline" style={{ color: "var(--info)", cursor: "pointer" }}/>
                                            }
                                        />
                                    </FlexItem>
                                </CanView>
                                <CanView permissions={["jobs.c_manage_job_offers"]}>
                                    <FlexItem>
                                        <Link to={routes.UPDATE_JOB_OFFER + offer.id} >
                                            <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }}/>
                                        </Link>
                                    </FlexItem>
                                </CanView>
                                <CanView permissions={["jobs.c_delete_offers"]}>
                                    <FlexItem>
                                        <ConfirmModal
                                            description={t('are_you_sure')}
                                            onConfirm={() => onDelete(offer.id)}
                                            button={
                                                <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }}/>
                                            }
                                        />
                                    </FlexItem>
                                </CanView>
                            </FlexRow>
                        </FlexItem>
                    </FlexRow>
                ))}

            </FlexTable>
            </>
        }
        <Paginator
            forcePage={page || 1}
            limit={limit}
            setLimit={setLimit}
            length={result?.data?.count || 0}
            onChange={(params) => fetchJobOffers(params)}
        />
        </CanView>
        </>
    );
};

export default JobsTable;