import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next"

import { Grid } from 'semantic-ui-react'

const Heading = () => {
    const colors = useSelector(state => state.global_pref.colors)
    const { t } = useTranslation();

    return (
        <Grid.Row columns={6} style={{ backgroundColor: colors.light, fontWeight: "bold" }}>
            <Grid.Column>{t("title")}</Grid.Column>
            <Grid.Column>{t("priority")}</Grid.Column>
            <Grid.Column>{t("status")}</Grid.Column>
            <Grid.Column>{t("labels")}</Grid.Column>
            <Grid.Column>{t("assignee")}</Grid.Column>
            <Grid.Column>{t("actions")}</Grid.Column>
        </Grid.Row>
    )
}

export default Heading;