import React from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
// components 
import { Segment, Icon, Button, Grid, Form, Header, Divider } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';

const IssuedEmployeeItems = ({ employee, issuedItems, setIssuedItems, onClose }) => {
    const { t } = useTranslation();
    const [employeeItems] = useFetchData(API.ASSET_ISSUED_ITEMS_OVERVIEW + "?id=" + employee + "&ordering=stored_item__code" )

    function checkIfWasIssued(storedItem){
        return issuedItems.find(item => item.storedItem.id === storedItem.id) !== undefined
    }

    const handleIssueItem = (storedItem) => {
        if( checkIfWasIssued(storedItem) ){ // remove
            setIssuedItems(prev => prev.filter(item => item.storedItem.id !== storedItem.id))
        } else { // issue
            setIssuedItems(prev => [
                {
                    id: 0,
                    quantity: storedItem.sn !== null ? 1 : storedItem.current_quantity,
                    valid_until: "",
                    note: "",
                    isProcessing: false,
                    storedItem: {
                        id: storedItem.id,
                        code: storedItem.code,
                        sn: storedItem.sn,
                        name: storedItem?.item?.title,
                        stock: storedItem.current_quantity,
                    }
                },
                ...prev
            ])
        }
    }

    return (
        <Segment loading={employeeItems.isLoading} style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
                marginBottom: "1rem"
            }}>
                { employeeItems.isLoading  && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_loading_assigned_items') } </p> }
                { !employeeItems.isLoading && 
                <>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={10} table={16} mobile={16}>
                                <Header as="h2" style={{ marginTop: "2rem" }} content={t('items_issued_to_an_employee')}/>
                            </Grid.Column>
                            <Grid.Column computer={6} table={16} mobile={16}>
                                <Form.Field  style={{ marginTop: "1.7rem", float: "right", fontSize: "1.1rem"}}>
                                    <Button fluid onClick={onClose} style={{ background: "var(--black)", color: "var(--white)" }}>
                                        <Icon name="angle left"/>  { t('return_to_return_card') }
                                    </Button>
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider/>
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader basis="40%" content={t('code')}/>
                            <FlexHeader basis="80%" content={t('item')}/>
                            <FlexHeader basis="40%" content={t('serial_number')}/>
                            <FlexHeader content={t('quantity')}/>
                            <FlexHeader content={t('actions')}/>
                        </FlexRow>
                        <EmptyRow length={employeeItems.data[0].stored_items.filter(item => item.current_quantity > 0).length}/>
                        {employeeItems.data[0].stored_items.sort((a, b) => a.code > b.code ? 1 : -1).map(storedItem => (
                            <>
                            { storedItem.current_quantity > 0 &&
                                <FlexRow fontSize="0.9rem" borders key={storedItem.id}>
                                    <FlexItem basis="40%">
                                        { storedItem?.code || "" }
                                    </FlexItem>
                                    <FlexItem basis="80%">
                                        <strong>{ storedItem?.item?.title }</strong> <br/>
                                    </FlexItem>
                                    <FlexItem basis="40%">
                                        { storedItem?.sn || "" }
                                    </FlexItem>
                                    <FlexItem content={storedItem.current_quantity}/>
                                    <FlexItem>
                                        <Button 
                                            size="small"
                                            fluid 
                                            disabled={storedItem.current_quantity === 0} 
                                            onClick={() => handleIssueItem(storedItem)}
                                            style={{
                                                color: "var(--white)",
                                                background: checkIfWasIssued(storedItem) ? "var(--dark)" : "var(--primary)"
                                            }}
                                        >
                                            { storedItem.current_quantity === 0 
                                                ? t('not_in_possession')
                                                : checkIfWasIssued(storedItem) 
                                                    ? <><Icon name="checkmark"/> { t('selected') } <br/> <small>{ t('click_to_remove') }</small> </>
                                                    : t('select_item')
                                            }
                                        </Button>
                                    </FlexItem>
                                </FlexRow>
                            }
                            </>
                        ))}
                    </FlexTable>
                </>
                }
        </Segment>
    );
};

export default IssuedEmployeeItems;