import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { routes } from '@routes';
// components
import Icon from '@components/Icon';
import { Container, Button } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
import CanView from '@components/perms/CanView';
// module specific Components
import JobsTable from '../components/JobsTable'

const JobListing = () => {
    const { t } = useTranslation();

    const breadcrumb_items = [
        // Put: name, icon and href data inside to create breadcrumb item
        { 'name': t('jobs'), 'icon': '', 'href': '' }
    ]

    const actions = [
        {
            component:
                <CanView permissions={["jobs.c_manage_application_forms"]}>
                    <Button
                        primary
                        as={Link}
                        size="small"
                        to={routes.JOBS_APPLY_FORMS}
                        content={ 
                            <span
                                style={{ 
                                    position: "relative",
                                    top: "-0.2rem" 
                                }}
                            > 
                                { t('application_forms') } 
                            </span>
                        }
                        icon={
                            <Icon
                                name="settings-outline"
                                style={{ marginRight: "0.5rem" }}
                            />
                        } 
                    />
                </CanView>
        }
    ]

    return (
        <>
            <CanView permissions={["jobs.c_view_job_offers"]} redirect>
                <BreadcrumbNav items={breadcrumb_items} actions={actions}/>
                <Container fluid>
                    <JobsTable />
                </Container>
            </CanView>
        </>
    );
};

export default JobListing;