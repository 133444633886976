import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { routes } from '@routes';
// store
import { API } from '@store/config';
import { useFetchData, useIsMount } from '@helpers/hooks';
import { requests } from '@helpers/requests';
import { addInvoiceCurrency } from '@store/actions';
import { useQueryPage, useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import Loading from '@components/general/Loading';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Button, Divider, Grid, Sidebar, Ref, Segment, Menu } from 'semantic-ui-react';
// module components
import Filters from '../components/detail/Filters';
import Statistics from '../components/detail/Statistics';
import RecordForm from '../components/detail/RecordForm';
import DetailTable from '../components/detail/DetailTable';
import Paginator from '@components/Paginator';

const CCDetailView = () => {
    const { t } = useTranslation()
    const { id } = useParams()
    const segmentRef = useRef()
    const history = useHistory()
    const isMount = useIsMount()

    const dispatch = useDispatch()

    const savedCurrency = useSelector((state) => state.invoice_currency)
    const currencies = useSelector(state => state.choices.currency_codes)
    const [currency, setCurrency] = useState(savedCurrency)

    const [visible, setVisible] = useState(false)
    const [state, setState] = useState('')
    const [statsLoading, setStatsLoading] = useState(false)
    const [filtering] = useState(false)
    // const [filterSection, setFilterSection] = useState(false)
    const [filterQuery, setFilterQuery] = useState('')

    const [center, setCenter] = useFetchData(API.COST_CENTERS + id + "/" + (currency !== "all" ? "?currency=" + currency : ""))
    const [records, setRecords] = useState([])

    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    let page = useQueryPage()

    const currentUser = useSelector(state => state.user)
    const [allowedUser, setAllowedUser] = useState([])
    const canManagePerm = useHasPermissions(['cost_centers.c_manage_cost_centers'])

    async function fetchCostCenter() {
        if( center.isLoading || filtering || isMount || loading ) return

        setStatsLoading(true)
        const params = onFilter()
        const request = await requests.get(API.COST_CENTER_RATIO_STATS + "?cost_center=" + id + params)
        if (request.status === 200){
            setCenter((prev) => ({ ...prev, data: request.response }))
        }

        setStatsLoading(false)
    }

    useEffect(() => {
        fetchCostCenter()
        // eslint-disable-next-line
    }, [currency, records])

    useEffect( () => {
        async function validateAccess(){
            const request = await requests.get(API.COST_CENTERS_ALLOWED_USERS + `?cost_center=${id}&user=${currentUser.id}`)

            if( request.status === 200 ){
                setAllowedUser(request.response)

                if (request.response?.[0]?.can_view || canManagePerm === true) {
                    await fetchCostCenter()
                } else {
                    history.push(routes.DASHBOARD)
                }
            }
        }
        validateAccess()
        // eslint-disable-next-line
    }, [])
    const initialFilters = {
        title: '',
        category: '',
        date_after: moment().startOf('month').format('YYYY-MM-DD'),
        date_before: moment().endOf('month').format('YYYY-MM-DD'),
        owner: '',
        account: '',
    }

    const [filters, setFilters] = useState(initialFilters)
    const location = useLocation()

    const onFilter = () => {
        let query = ''
        if (filters.title) query += `&search=${filters.title}`
        if (filters.category) query += `&category=${filters.category}`
        if (filters.owner) query += `&owner=${filters.owner}`
        if (filters.account) query += `&account=${filters.account}`
        if (filters.date_after) query += `&date_after=${filters.date_after}`
        if (filters.date_before) query += `&date_before=${filters.date_before}`

        return query
    }

    const fetchRecords = async (params, isFiltered) => {
        if (!loading){
            params = params || ""
            params += onFilter()
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if ( !params.includes('page') ) {
                params += "&page=" + page
            }

            if ( !params.includes('limit')){
                params += "&limit=" + limit
            }

            setRecords(prevState => ({
                ...prevState,
                isLoading: true
            }))

            const request = await requests.get(API.COST_CENTERS_RECORDS + `?paginate=true&cost_center=${id}${state}${params}`)
            if (request.status === 200) {
                setRecords(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }
            setLoading(false)
            }
    }

    useEffect(() => {
        fetchRecords()
        // eslint-disable-next-line
    }, [id, filterQuery])

    useEffect(() => {
        async function loadOnViewChange(){
            if (!loading && !isMount)
            history.replace({ pathname: location.pathname, search: `?page=${1}` });
            await fetchRecords("", true)
        }

        loadOnViewChange()
        // eslint-disable-next-line
    }, [state])

    return (
        <>
        {(allowedUser?.[0]?.can_view || canManagePerm) &&
            <Ref innerRef={segmentRef}>
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                    <Sidebar
                        style={{ width: "40rem" }}
                        as={Menu}
                        animation='overlay'
                        direction='right'
                        icon='labeled'
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        width='wide'
                        target={segmentRef}
                    >
                        <Filters
                            setFilterQuery={setFilterQuery}
                            loading={filtering}
                            setVisible={setVisible}
                            filters={filters}
                            setFilters={setFilters}
                            fetchRecords={fetchRecords}
                            fetchCostCenter={fetchCostCenter}
                        />
                    </Sidebar>
                    <Sidebar.Pusher>
                    <Segment loading={loading} basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }}>
                        <Grid style={{ marginBottom: "1rem" }}>
                            <Grid.Row style={{ padding: 0 }}>
                                <Grid.Column computer={8} tables={16} mobile={16}>
                                    <Button.Group basic style={{ borderRadius: 0 }}>
                                        <Button active={state === ''} onClick={() => setState('')}>{t('all')}</Button>
                                        <Button active={state === '&type=1'} onClick={() => setState('&type=1')}>{t('income')}</Button>
                                        <Button active={state === '&type=2'} onClick={() => setState('&type=2')}>{t('expense')}</Button>
                                    </Button.Group>
                                </Grid.Column>
                                <Grid.Column computer={8} tables={16} mobile={16} style={{ textAlign: "right" }}>
                                    <Button
                                        size="small"
                                        style={{
                                            background: "transparent",
                                            color: "var(--dark)",
                                            paddingLeft: "1rem",
                                            paddingRight: "1rem"
                                        }}
                                        onClick={() => setVisible(!visible)}
                                    >
                                        <Icon name='funnel-outline' style={{ margin: 0 }} />
                                    </Button>
                                    {(allowedUser?.[0]?.can_manage || canManagePerm === true) &&
                                        <SuperDuperModal
                                            size="small"
                                            header={t('add_record')}
                                            content={<RecordForm setData={setRecords} />}
                                            trigger={
                                                <Button primary>
                                                    <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/>
                                                    <span style={{ position: "relative", top: "-0.1rem" }}>{t('add_record')}</span>
                                                </Button>
                                            }
                                        />
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider />
                        <Grid>
                            <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                                <Grid.Column style={{ textAlign: "right" }}>
                                    <SuperField inline as="choice"
                                        size="small"
                                        label={<span style={{ marginRight: "1rem" }}>{t('display_in_currency')}</span>}
                                        search
                                        style={{ width: "200px" }}
                                        customOptions={[
                                            { key: 0, text: t('all_currencies'), value: "all" },
                                            ...Object.entries(currencies).map(([key,value]) => ({
                                                key: key, text: value, value: key
                                            }))
                                        ]}
                                        value={currency || ""}
                                        onChange={(e, { value }) => {
                                            if( value === "" ){
                                                setCurrency("all")
                                                dispatch(addInvoiceCurrency("all"))
                                            } else {
                                                setCurrency(value)
                                                dispatch(addInvoiceCurrency(value))
                                            }
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        {center.isLoading || filtering
                            ? <Loading />
                            : <>
                                {/* <CanView permissions={['reports.c_view_cost_center_reports']}> */}
                                    <Statistics stats={center.data} currency={currency} statsLoading={statsLoading}/>
                                {/* </CanView> */}
                                <Divider />
                                <DetailTable
                                    data={records?.data?.results}
                                    setData={setRecords}
                                    loading={filtering}
                                    canManage={allowedUser?.[0]?.can_manage}
                                    canDelete={allowedUser?.[0]?.can_delete}
                                />
                            </>
                        }
                        </Segment>
                        <Paginator
                            forcePage={page || 1}
                            limit={limit}
                            setLimit={setLimit}
                            length={records?.data?.count || 0}
                            onChange={(params) => fetchRecords(params)}
                        />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Ref>
        }
        </>
    );
};

export default CCDetailView;