import React from 'react';
import { useTranslation } from "react-i18next";
// components
import { Container } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
import CanView from '@components/perms/CanView';
// module specific Components
import PositionTable from '../components/PositionTable';


const PositionList = () => {
    const { t } = useTranslation();

    const breadcrumb_items = [
        { 'name': t('jobs_positions'), 'icon': '', 'href': '' }
    ]

    return (
        <CanView permissions={['jobs.c_view_job_positions']} redirect>
            <BreadcrumbNav items={breadcrumb_items} />
            <Container fluid>
                <PositionTable />
            </Container>
        </CanView>
    );
};

export default PositionList;