import React, { useState } from 'react';
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Header, Sidebar, Menu, Icon, Popup } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
import SuperDuperModal from '@components/modals/SuperDuperModal'
import NewLabel from '@components/general/NewLabel'

import { dates } from '@services/helpers/dates'
import { routes } from '@store/routes'

const IssueDetail = ({ issue }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(true)
    const labelStyle = { position: 'absolute', top: "1.2rem", right: "1.5rem" }
    function expired() {
        if (dates.diffDays(moment(), issue.due_date) > 1) return t("expired")
        return false
    }

    const Content = ({ header, content }) => {
        return (
            <div>
                <Header content={header} style={{ margin: 0 }} />
                {content}
            </div>
        )
    }
    const MenuItem = ({ content, icon, expand, ...rest }) => {
        //content component
        //icon string
        return (
            <Menu.Item {...rest}>
                {expanded ?
                    <div >
                        {content}
                    </div>
                    :
                    <div >
                        <Popup content={expand ? t('expand') : content.props.header} trigger={<Icon name={icon} />} />
                    </div>
                }
            </Menu.Item>
        )
    }
    const TimeTracking = () => {
        if (issue.estimated_time === null && issue.time_spent === null) return t("none")
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {issue.estimated_time && <span>{t("estimated")}: {issue.estimated_time}</span>}
                {issue.time_spent && <span>{t("spent")}: {issue.time_spent}</span>}
            </div>
        )
    }
    const Assigned = () => {
        if (issue.assigned.length === 0) return t("none")
        return issue.assigned.map((item, key) =>
            <AvatarIcon
                key={key}
                size={25}
                name={item?.fullname ? item.fullname : "JD"}
                src={item?.avatar}
                uploadable={false}
            />)
    }
    const Labels = () => {
        if (issue.labels.length === 0) return t("none")
        return issue.labels.map((item, key) => <NewLabel key={key} name={item.name} color={item.color} />)
    }

    const IssueDetailHeader = () => (
        <>
            #{issue.number} {issue.name} {issue.state === 1 ? <NewLabel outline name={t('opened')} style={labelStyle} /> : <NewLabel name={t('closed')} style={labelStyle} />}
            <Header.Subheader>
                {t('created')}
                <Popup
                    content={<><Icon name="time" />{moment(issue.created_on).format('MMMM Do YYYY, h:mm:ss')}</>}
                    position='top center'
                    inverted
                    trigger={
                        <span style={{ fontWeight: "bold", color: 'var(--primary)' }}> {moment(issue.created_on).startOf('day').fromNow()} </span>

                    }
                />
                {t('by')} <Link to={routes.PROFILE + issue.created_by?.id}> {issue.created_by?.name} </Link>
            </Header.Subheader>
        </>
    )
    const Milestone = ({ milestone }) => (
        <div>
            <span>{milestone.name}</span>
            {milestone.from_date && <span>From {milestone.from_date}</span>}
            {milestone.due_date && <span>Due {milestone.due_date}</span>}
        </div>
    )

    function checkSubIssuesState(list, type) {
        let counter = 0
        if (list.length > 0) {
            for (let item of list) {
                if (item.state === 2) ++counter
            }
        }
        if (type === "percent") return `${Math.round((counter / list.length) * 100)}%`
        return `${counter}/${list.length}`
    }

    return (
        <SuperDuperModal
            className="issue-modal"
            header={<IssueDetailHeader />}
            content={
                <>
                    <Sidebar.Pushable>
                        <Sidebar
                            as={Menu}
                            animation='overlay'
                            icon='labeled'
                            vertical
                            direction='right'
                            style={{ width: expanded ? '12rem' : '4rem' }}
                            visible={true}
                        >
                            <MenuItem
                                expand
                                style={{ display: 'flex', cursor: "pointer" }}
                                onClick={() => setExpanded(!expanded)}
                                icon={"angle double left"}
                                content={
                                    <Content
                                        content={<><Icon name="angle double right" />{t('hide')}</>} />}
                            />
                            <MenuItem
                                icon={"clock"}
                                content={
                                    <Content
                                        header={t("milestone")}
                                        content={issue.milestone ? <Milestone milestone={issue.milestone} /> : t("none")} />
                                }
                            />
                            <MenuItem
                                icon={"stopwatch"}
                                content={<Content header={t("time_tracking")} content={<TimeTracking />} />}
                            />
                            <MenuItem
                                icon={"calendar"}
                                content={
                                    <Content
                                        header={t("due_date")}
                                        content={
                                            <div style={{ display: 'flex', flexDirection: "column" }}>
                                                <span>{moment(issue.due_date).format('MMMM Do YYYY')}</span>
                                                <span style={{ color: "var(--danger)" }}>{expired()}</span>
                                            </div>
                                        }
                                    />}
                            />
                            <MenuItem
                                icon={"history"}
                                content={
                                    <Content
                                        header={t("last_updated")}
                                        content={moment(issue.updated_on).format('MMMM Do YYYY, hh:mm')}
                                    />}
                            />
                            <MenuItem
                                icon={"users"}
                                content={
                                    <Content
                                        header={t("assignees")}
                                        content={<Assigned />}
                                    />}
                            />
                            <MenuItem
                                icon={"tags"}
                                content={
                                    <Content
                                        header={t("labels")}
                                        content={<Labels />}
                                    />}
                            />
                            <MenuItem
                                icon={"tasks"}
                                content={
                                    <Content
                                        header={t("sub_issues")}
                                        content={checkSubIssuesState(issue.sub_issues, "percent")}
                                    />}
                            />
                        </Sidebar>

                        <Sidebar.Pusher style={{ padding: '1.5rem', marginRight: expanded ? '12rem' : '4rem' }}>
                            <div style={styles.row} >
                                <Header content={t('description')} style={{ width: "100%", paddingBottom: '1rem' }} />
                                {issue.description}
                            </div>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </>
            }
            trigger={
                <Header as="h3" style={{ margin: 0 }} content={
                    <span className="ref-link">
                        {issue.name}
                    </span>
                } />
            } />

    );
};
const styles = {
    row: { height: '30rem', overflow: 'auto', padding: 0 }
}
export default IssueDetail;