
import React from 'react';
import { useTranslation } from "react-i18next";

// components
import ConfirmModal from '@components/modals/ConfrimModal';
import { Icon } from 'semantic-ui-react';


const PublishModal = ({ publication, publish }) => {
    const { t } = useTranslation();

    return (
        <ConfirmModal
            description={t('publish_confirm')}
            onConfirm={() => {
                if(publication.publishers.length !== 0) {
                    publish(publication.id)
                } else {
                    alert("No publisher available")
                }
            }}
            button={
                <Icon
                    name="send"
                    title={ t('publish') }
                    style={{ color: "var(--info)", cursor: "pointer" }}
                />
            }
        />

    );
};

export default PublishModal;