import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { dates } from '@helpers/dates';
import { useHasPermissions } from '@helpers/hooks';
import { getRequest, deleteRequest } from '@services/ServiceCommon';
import { fileDownload } from '@helpers/requests';
// Components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import { Form, Grid, Button, Divider, Segment, Card, Header, Sidebar, Ref, Menu } from 'semantic-ui-react';
// module specific components
import RecordForm from '../components/forms/RecordForm';

const Timesheet = () => {
    const { t } = useTranslation();
    const segmentRef = useRef()
    const canViewAll = useHasPermissions(['timesheets.c_view_all_records']);
    const canViewUsers = useHasPermissions(['timesheets.c_view_user_records']);

    const only_current_user = (canViewAll === false && canViewUsers === true) ? "true" : "false"
    const user = useSelector(state => state.user)

    const [isInitialGeneration, setIsInitialGeneration] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [records, setRecords] = useState([])
    const [options, setOptions] = useState([])
    const [loadingBussiness, setLoadingBussiness] = useState(false)
    const [stats, setStats] = useState({
        totalHours: 0,
        totalRecords: 0,
        totalDays: 0
    })

    async function fetchBussiness() {
        setLoadingBussiness(true)
        const request = await getRequest(API.BUSINESS_DETAIL + "?is_freelancer=false&is_supplier=false&query={id, name, source}")

        if (request.status === 200) {
            const bussOpt = []
            for (let i = 0; i < request.response.length; i++) {
                bussOpt.push({ key: request.response[i].id, value: request.response[i].id, text: request.response[i].name, source: request.response[i].source });
            }

            setOptions(bussOpt)
        }

        setLoadingBussiness(false)
    }

    const [filters, setFilters] = useState({
        search: "",
        employee: user.profile_id !== null ? user.profile_id : "",
        category: "",
        dateFrom: moment().startOf('month').format("YYYY-MM-DD"),
        dateTo: moment().endOf('month').format("YYYY-MM-DD"),
        business_detail: null
    })

    async function fetchRecords() {
        if (!only_current_user || filters.employee !== "") {
            setIsInitialGeneration(false)
            setIsLoading(true)
            let params = "";
            if (filters.search !== "") params += "&search=" + filters.search;
            if (filters.employee !== "") params += "&profile=" + filters.employee;
            if (filters.category !== "") params += "&category=" + filters.category;
            if (filters.dateFrom !== "") params += "&start_date=" + filters.dateFrom;
            if (filters.dateTo !== "") params += "&end_date=" + filters.dateTo;
            if (filters.business_detail !== "" && filters.business_detail !== null) params += "&business_detail=" + filters.business_detail;

            const request = await getRequest(API.TIMESHEETS + "records/?only_current_user=" + only_current_user + params + "&ordering=-date")
            if (request.status === 200) {
                setRecords(request.response)
                setVisible(false)
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchRecords()
        fetchBussiness()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let totalRecords = records.length - 1 === -1 ? 0 : records.length - 1;
        let totalHours = parseFloat(0);
        for (let i = 0; i < records.length; i++) {
            if (records[i].total_hours === undefined) {
                totalHours += parseFloat(records[i].hours)
            }
        }

        setStats({
            totalHours: parseFloat(totalHours).toFixed(2),
            totalRecords: totalRecords,
            totalDays: dates.diffDays(filters.dateFrom, filters.dateTo)
        })

        // eslint-disable-next-line
    }, [records])


    const onFilter = async () => {
        await fetchRecords()
    }

    const onCreate = (response) => {
        if (!isInitialGeneration) {
            setRecords(prevState => [response, ...prevState]);
        }
    }

    const onUpdate = (response) => {
        setRecords(prevState => prevState.filter((item) => {
            if (item.id === response.id) {
                item.week = response.week;
                item.date = response.date;
                item.activity = response.activity;
                item.hours = response.hours;
                item.business_detail = response.business_detail ? response.business_detail : null;
                item.milestone = response.milestone ? response.milestone : null;
                item.project = response.project ? response.project : null;
            }

            return item;
        }));
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.TIMESHEETS + "records/" + id + "/")

        if (request.status === 204) {
            setRecords(prevState => prevState.filter(item => item.id !== id))
        }
    }

    const downloadReport = async () => {
        await fileDownload("GET", API.TIMESHEETS_EXPORTS + "?start_date=" + filters.dateFrom + "&end_date=" + filters.dateTo + "&profile=" + filters.employee, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx-export.xlsx")
    }

    return (
        <CanView permissions={["timesheets.c_view_all_records", "timesheets.c_view_user_records"]} redirect>
            <Ref innerRef={segmentRef}>
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", height: "90vh", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                    <Sidebar
                        style={{ width: "40rem" }}
                        as={Menu}
                        animation='overlay'
                        direction='right'
                        icon='labeled'
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        width='wide'
                        target={segmentRef}
                    >
                        <Form onSubmit={onFilter} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                            <Grid>
                                <Grid.Row columns={2} verticalAlign='middle' style={{ padding: 0 }}>
                                    <Grid.Column>
                                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", textAlign: "left" }} />
                                    </Grid.Column>
                                    <Grid.Column textAlign='right'>
                                        <Action
                                            className="filter-close-icon"
                                            as="custom"
                                            type="icon"
                                            icon="close-outline"
                                            onClick={() => setVisible(false)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />
                            <SuperField as="input"
                                icon="search"
                                iconPosition="left"
                                label={t('search')}
                                placeholder={t('search')}
                                value={filters.search}
                                onChange={(e, { value }) => setFilters({ ...filters, search: value })}
                            />
                            <SuperField as="choice"
                                size="small"
                                search
                                text="title"
                                label={t('activity')}
                                endpoint={API.TIMESHEETS + "categories/"}
                                value={filters.category}
                                onChange={(e, { value }) => setFilters({ ...filters, category: value })}
                            />
                            <Form.Group widths="equal">
                                <SuperField as="datepicker"
                                    size="small"
                                    label={t('date_from')}
                                    value={filters.dateFrom}
                                    style={{ height: "2.75rem" }}
                                    onChange={(e, { value }) => setFilters({ ...filters, dateFrom: value })}
                                />
                                <SuperField as="datepicker"
                                    size="small"
                                    label={t('date_to')}
                                    value={filters.dateTo}
                                    style={{ height: "2.75rem" }}
                                    onChange={(e, { value }) => setFilters({ ...filters, dateTo: value })}
                                />
                            </Form.Group>
                            <Form.Group widths="equal">
                                <CanView permissions={["timesheets.c_view_all_records"]}>
                                    <SuperField as="choice"
                                        size="small"
                                        search
                                        disabled={!canViewAll}
                                        label={t('employee')}
                                        endpoint={API.EMPLOYEES + "?query={id, fullname}"}
                                        text="fullname"
                                        value={filters.employee}
                                        onChange={(e, { value }) => {
                                            if (value === "") setIsInitialGeneration(true)

                                            setFilters({ ...filters, employee: value })
                                        }}
                                    />
                                </CanView>

                                <SuperField as="choice"
                                    search
                                    size="small"
                                    disabled={loadingBussiness}
                                    loading={loadingBussiness}
                                    customOptions={options}
                                    label={t('employer')}
                                    value={filters?.business_detail}
                                    onChange={(e, { value }) => setFilters({
                                        ...filters,
                                        business_detail: value
                                    })}
                                />
                            </Form.Group>
                            <Divider />
                            <Form.Field>
                                <Button.Group>
                                    <Button size="small" primary disabled={isLoading} loading={isLoading}> {t('apply')} </Button>
                                </Button.Group>
                            </Form.Field>
                        </Form>
                    </Sidebar>
                    <Sidebar.Pusher>
                        <Grid>
                            <Grid.Row style={{ padding: 0 }}>
                                <Grid.Column computer={10} table={16} mobile={16}>

                                </Grid.Column>
                                <Grid.Column computer={6} table={16} mobile={16} style={{ textAlign: "right" }}>
                                    <Icon
                                        title="Save record"
                                        style={{ cursor: "pointer", color: "var(--primary)" }}
                                        name="download-outline"
                                        onClick={() => downloadReport()}
                                    />
                                    <Action
                                        className="filter-icon"
                                        as="custom"
                                        type="icon"
                                        icon="funnel-outline"
                                        onClick={() => setVisible(!visible)}
                                    />
                                    <CanView permissions={["timesheets.c_manage_user_records"]} redirect>
                                        <SuperDuperModal
                                            size="small"
                                            trigger={
                                                <Button primary>
                                                    <Icon name="clipboard-outline" style={{ marginRight: "0.5rem" }}/> 
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>{t("add_record")}</span>
                                                </Button>
                                            }
                                            content={
                                                <RecordForm onSubmit={onCreate} options={options} />
                                            }
                                        />
                                    </CanView>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider />
                        <Segment loading={isLoading} style={{
                            padding: 0,
                            background: "transparent",
                            boxShadow: "none",
                            border: "none",
                            marginBottom: "1rem"
                        }}>

                            {!isLoading && (!only_current_user || filters.employee !== "") && !isInitialGeneration ?
                                <>
                                    <Grid style={{ marginBottom: "1rem" }}>
                                        <Grid.Row columns={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <Grid.Column style={{ textAlign: "center", }}>
                                                <Card className="active" fluid style={{ borderRadius: 0 }}>
                                                    <Card.Content as="h2">
                                                        {t("total_days")}: <br />
                                                        <span style={{ fontSize: "1.3rem" }}>
                                                            {stats.totalDays}
                                                        </span>
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                            <Grid.Column style={{ textAlign: "center", }}>
                                                <Card className="active" fluid style={{ borderRadius: 0 }}>
                                                    <Card.Content as="h2">
                                                        {t("total_records")}: <br />
                                                        <span style={{ fontSize: "1.3rem" }}>
                                                            {stats.totalRecords}
                                                        </span>
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                            <Grid.Column style={{ textAlign: "center", }}>
                                                <Card className="active" fluid style={{ borderRadius: 0 }}>
                                                    <Card.Content as="h2">
                                                        {t("total_hours")}: <br />
                                                        <span style={{ fontSize: "1.3rem" }}>
                                                            {stats.totalHours}
                                                        </span>
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Divider />
                                    <FlexTable>
                                        <FlexRow>
                                            <FlexHeader basis="50%" content={t('week')} />
                                            <FlexHeader basis="50%" content={t('date')} />
                                            <FlexHeader basis="120%" content={t('activity')} />
                                            <FlexHeader basis="50%" content={t('hours')} />
                                            <FlexHeader content={t('employer')} />
                                            <FlexHeader content={t('project')} />
                                            <FlexHeader content={t('milestone')} />
                                            <FlexHeader basis="120%" content={t('note')} />
                                            <CanView permissions={["timesheets.c_manage_user_records", "timesheets.c_manage_all_records"]}>
                                                <FlexHeader basis="40%" content={t('actions')} />
                                            </CanView>
                                        </FlexRow>
                                        <EmptyRow length={records.length} />
                                        {records.map(record => record.total_hours === undefined && (
                                            <FlexRow fontSize="0.9rem" key={record?.id} borders>
                                                <FlexItem basis="50%" content={record.week} />
                                                <FlexItem basis="50%" content={moment(record.date).format("DD.MM.YYYY")} />
                                                <FlexItem basis="120%" content={record?.category?.title} />
                                                <FlexItem basis="50%" content={record.hours} />
                                                <FlexItem>
                                                    <Link to={record?.business_detail?.source === "account" 
                                                    ? routes.ACCOUNTS_DETAIL + record?.business_detail?.identification_number 
                                                    : routes.SUPPLIERS + record?.business_detail?.identification_number}>
                                                        <span>{record?.business_detail?.name}</span>
                                                    </Link>
                                                </FlexItem>
                                                <FlexItem>
                                                    <Link to={routes.PROJECT_DETAIL + record?.project?.id}>
                                                        <span>{record?.project?.name}</span>
                                                    </Link>
                                                </FlexItem>
                                                <FlexItem content={record?.milestone?.name} />
                                                <FlexItem bold basis="120%" content={record.activity} />
                                                <CanView permissions={["timesheets.c_manage_user_records", "timesheets.c_manage_all_records"]}>
                                                    <FlexItem basis="40%">
                                                        <FlexRow background="transparent" padding="0">
                                                            <FlexItem>
                                                                <SuperDuperModal
                                                                    size="small"
                                                                    trigger={
                                                                        <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }} />
                                                                    }
                                                                    content={
                                                                        <RecordForm
                                                                            record={record}
                                                                            options={options}
                                                                            onSubmit={(response) => onUpdate(response)}
                                                                        />
                                                                    }
                                                                />
                                                            </FlexItem>
                                                            <FlexItem>
                                                                <ConfirmModal
                                                                    description={t('delete_record_message')}
                                                                    onConfirm={() => onDelete(record.id)}
                                                                    button={
                                                                        <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                                    }
                                                                />
                                                            </FlexItem>
                                                        </FlexRow>
                                                    </FlexItem>
                                                </CanView>
                                            </FlexRow>
                                        ))}
                                    </FlexTable>
                                </>
                                :
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column style={{ textAlign: "center", }}>
                                            {!isLoading && <Header as="h3" content={t('hint_generate_timesheet_by_selecting_employee_first')} />}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            }
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Ref>
        </CanView>
    );
};

export default Timesheet;