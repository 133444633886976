import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
import { patchRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import { Form, Button } from 'semantic-ui-react';

const TerminationUploadScannedFile = ({ terminationId, setTerminations, onClose  }) => {
    const { t } = useTranslation()
    const [scannedFile, setScannedFile] = useState(null)
    const [isUploading, setIsUploading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsUploading(true)

        const formData = new FormData()
        formData.append("external_file", scannedFile)

        const request = await patchRequest(API.CONTRACTS_TERMINATIONS + terminationId + "/file/", formData)

        if( request.status === 200 ){
            setTerminations(prevState => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: prevState.data.results.filter(termination => {
                            if(termination.id === terminationId){
                                termination.external_file = request.response.external_file
                            }
    
                            return termination
                        })
                    }
                }
            })
            onClose()
        }

        setIsUploading(false)
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField as="input" 
                    type="file" 
                    width={12}
                    label={ t('upload_scanned_file') }
                    onChange={(event) => {
                        if (event.target.files.length > 0) {
                            setScannedFile(event.target.files[0]);
                        }
                    }}
                />
                <Form.Field width={4}>
                    <Button 
                        fluid primary 
                        size="large"
                        loading={isUploading} 
                        content={ t('upload') }
                        style={{ marginTop: "1.6rem" }} 
                        disabled={isUploading || scannedFile === null} 
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    )
};

export default TerminationUploadScannedFile;