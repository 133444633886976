import React, { useState, Fragment } from 'react';
import { useTranslation } from "react-i18next"
// components
import BankAccountForm from './BankAccountForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import ConfrimModal from '@components/modals/ConfrimModal';
import { Icon, Grid, Button, Dropdown } from 'semantic-ui-react';

export default function BankAccount(props) {
    const { t } = useTranslation();

    //needed props {name, iban, swift, bank, def, currency}
    const [showMore, setShowMore] = useState(false)

    function ShowMore() {
        setShowMore(!showMore);
    }

    return (
        <Fragment>
            <Grid style={{ border: props.bank.default ? '2px solid var(--primary)' : '2px solid var(--variant5)', fontSize: "1.2rem", lineHeight: "2rem" }}  >
                <Grid.Row style={{ width: "100%", padding: "1rem", textAlign: "left", backgroundColor: "var(--default)", borderBottom: props.bank.default ? "6px solid var(--primary)" : "6px solid var(--variant5)" }} >
                    <Grid.Column style={styles.head} width={16}>
                        {showMore
                            ?
                            <div>
                                <Icon name="money bill alternate outline" />
                                <span style={{ fontWeight: "bold", }}>   {t('bank_info')}</span>
                            </div>
                            :
                            <div>
                                <Icon name="money bill alternate outline" />
                                <span style={{ fontWeight: "bold" }}>  {props.bank.name} </span>
                                <span style={{ paddingLeft: "0.5rem" }}> | </span>
                                <span> {props.bank.currency}</span>
                            </div>
                        }
                        <div >

                            <Button
                                size="small"
                                icon={showMore ? "chevron up" : "chevron down"}
                                style={{ fontWeight: "bold", backgroundColor: "transparent" }}
                                onClick={ShowMore}
                            />
                            { props.isManageable ?
                            <Dropdown
                                style={{ backgroundColor: "transparent" }}
                                icon='ellipsis vertical'
                                button
                                // options={options}
                                className='icon'
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        <SuperDuperModal
                                            trigger={
                                                <Button style={styles.actionIcon}> <Icon name="pencil" /> {t('update_bank')} </Button>
                                            }
                                            size="small"
                                            header={t('update_bank')}
                                            content={
                                                <BankAccountForm
                                                    btnName={t('update_bank')}
                                                    bank={props.bank}
                                                    onConfirm={
                                                        (updated_item) => {
                                                            props.handleBankUpdate(updated_item);
                                                        }
                                                    }
                                                />
                                            }
                                        />
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <ConfrimModal
                                            description={t('delete_bank')}
                                            onConfirm={() => props.onDelete()}
                                            button={
                                                <Button style={styles.actionIcon}>
                                                    <Icon name="close" color="red" /> {t('delete')}
                                                </Button>
                                            }
                                        />
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            : "" }
                        </div>
                    </Grid.Column>

                    {showMore
                        ?
                        <Grid.Column width={16} style={styles.content}>
                            <span>{t('name')}: <b>{props.bank.name}</b></span><br />
                            <span style={{ width: "100%" }}>IBAN: <b>{props.bank.iban}</b></span><br />
                            <span>SWIFT/BIC: <b>{props.bank.swift_code}</b></span><br />
                            <span>{t('bank_detail')}: <b>{props.bank.bank_detail}</b></span><br />
                        </Grid.Column>
                        :
                        <Grid.Column width={16} style={styles.content}>
                            <span>{t('current_balance')}: <b>13 258,88</b></span><br />
                            <span style={{ width: "100%" }}>{t('disposable_balance')}: <b>12 200,88</b></span><br />
                            <span>{t('blocked_amount')} <b>1 058</b></span><br />
                            <span> <b></b></span><br />
                        </Grid.Column>
                    }
                </Grid.Row>
            </Grid>
        </Fragment>
    )
}


const styles = {
    actionIcon: { padding: 0, backgroundColor: "transparent" },

    head: { display: 'flex', justifyContent: 'space-between' },

    yes: {
        padding: "1px 3px",
        backgroundColor: "var(--primary)",
        color: "var(--default)",
        fontSize: "1rem",
        fontWeight: "bold",
    },
    no: {
        padding: "1px 3px",
        backgroundColor: "var(--black)",
        color: "var(--default)",
        fontSize: "1rem",
        fontWeight: "bold",
    },
    content: { borderTop: "1px solid var(--light-grey)", paddingTop: "1rem" },
}
