import React from 'react';
//components
import Icon from '@components/Icon';
import { Card, Grid } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';

const InvoiceInfoCard = ({ onClick, color, number, description, icon }) => {

    return (
        <Grid.Column mobile={16} tablet={4} computer={3}>
            <Card fluid color={color} style={{ borderRadius: "0rem", marginBottom:"0.5rem" }} onClick={onClick} >
                <Grid >
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                            <FlexRow padding="0" background="transparent">
                                <FlexItem basis="200%">
                                    {number}
                                    <Card.Description style={{ marginTop: "1rem", width:"10rem", color:"black" }}>{description}</Card.Description>
                                </FlexItem>
                                <FlexItem textAlign="center">
                                    <Icon style={{ fontSize: "3rem" }} color={color} name={icon} />
                                </FlexItem>
                            </FlexRow>
                            
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card>
        </Grid.Column>
    );

};

export default InvoiceInfoCard;