import { APIRequest } from './helpers/requests';
import { API } from "../config";

export const onboardingService = {
  updateCheckItem,
  attachment,
  deleteAttachment,
  createTemplate,
  createTask,
  createInstanceTask,
  startOnOffboarding,
  deleteItem,
  terminate
};


async function updateCheckItem(id,data) {
    return await APIRequest({
        url: API.ONBOARDING_ITEMS + id + '/',
        method: "PATCH",
        data: data,
        private: true
    })
}
async function attachment(id, data) {
    return await APIRequest({
        url: API.ONBOARDING_ITEMS + id + "/attachment/",
        method: "PUT",
        data: data,
        private: true
    })
}
async function deleteAttachment(id) {
    return await APIRequest({
        url: API.ONBOARDING_ITEMS + id + "/attachment/",
        method: "DELETE",
        data: {},
        private: true
    })
}
async function createTemplate(data) {
    return await APIRequest({
        url: API.ONBOARDING_CHECKLIST_CONFIG,
        method: "POST",
        data: data,
        private: true
    })
}

async function createTask(data) {
    return await APIRequest({
        url: API.ONBOARDING_ITEMS_CONFIG,
        method: "POST",
        data: data,
        private: true
    })
}

async function createInstanceTask(data) {
    return await APIRequest({
        url: API.ONBOARDING_ITEMS,
        method: "POST",
        data: data,
        private: true
    })
}

async function startOnOffboarding(id, data) {
    return await APIRequest({
        url: API.ONBOARDING_CHECKLIST_CONFIG + id + '/instantiate/',
        method: "POST",
        data: data,
        private: true
    })
}

async function deleteItem(id) {
    return await APIRequest({
        url: API.ONBOARDING_ITEMS  + id + '/',
        method: "DELETE",
       
        private: true
    })
}

async function terminate(id, data) {
    return await APIRequest({
        url: API.ONBOARDING_CHECKLIST + id + '/',
        method: "PATCH",
        data: data,
        private: true
    })
}
