import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
// components
import { Form, List, Header, Button, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const ForecastFilterForm = ({ setVisible, fetchForecasts }) => {
    const { t } = useTranslation();

    // state
    const initState = {
        search: "",
        date_from: "",
        date_to: "",
        category: ""
    }

    const [filters, setFilters] = useState(initState)

    const onFilter = async (filters) => {
        setVisible(false)
        let filterBaseParams = ""

        if( filters.date_from !== "" ) filterBaseParams += "&date_from_after=" + filters.date_from
        if( filters.date_to !== "" ) filterBaseParams += "&date_to_before=" + filters.date_to
        if( filters.category !== "" ) filterBaseParams += "&category=" + filters.category
        if( filters.search !== "" ) filterBaseParams += "&search=" + filters.search

        await fetchForecasts(filterBaseParams)
    }

    const resetFilters = async () => {
        setFilters(initState)
        setVisible(false)
        await onFilter(initState)
    }

    return (
        <Form onSubmit={() => onFilter(filters)} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }}/>
                    </List.Content>
                </List.Item>
            </List>

            <Divider/>

            <SuperField as="input"
                icon="search"
                iconPosition="left"
                label={t('search')}
                placeholder={t('search')}
                value={filters.search}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    search: value
                })}
            />

            <SuperField as="choice"
                label={t('category')}
                endpoint={API.FORECASTS + "categories/"}
                text="title"
                value={filters.category}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    category: value
                })}
            />

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    clearable
                    label={ t('date_from') }
                    value={ filters.date_from } 
                    onChange={ (e, { value }) => setFilters({ ...filters, date_from: value }) }
                />
                <SuperField as="datepicker"
                    clearable
                    label={ t('date_to') }
                    value={ filters.date_to } 
                    onChange={ (e, { value }) => setFilters({ ...filters, date_to: value }) }
                />
            </Form.Group>

            <Divider/>
            <Form.Field>
                <Button.Group>
                    <Button size="small" primary> {t('apply')} </Button>
                    <Button type="button" onClick={resetFilters} size="small"> {t('reset')} </Button>
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default ForecastFilterForm;