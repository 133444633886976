import React from 'react';
import { useTranslation } from "react-i18next";
// components
import { Icon } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
import CanView from '@components/perms/CanView';
import AssignToHiringForm from '../forms/AssignToHiringForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';


const PersonCell = ({ row }) => {
    const { t } = useTranslation();

    return (
        <FlexRow background="transparent" padding="0" fontSize="0.9rem">
            <FlexItem basis="10%">
                <Icon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                    <AvatarIcon size="30" name={row.fullname} src={row.avatar}/>
                </Icon.Group>
            </FlexItem>
            <FlexItem>
                <strong>{row.fullname}</strong><br/>
                <span style={{ cursor: "pointer", color: "var(--primary)" }}>
                    <CanView permissions={["candidates.c_manage_recruitment"]}>
                        <SuperDuperModal
                            trigger={
                                <span> { t('assign_to_hiring_room') } </span>
                            }
                            content={
                                <AssignToHiringForm candidate={row.id}/>
                            }
                        />
                    </CanView>
                </span>
            </FlexItem>
        </FlexRow>
    );
};

export default PersonCell;