import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
// components
import { Button, Grid, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import CanView from '@components/perms/CanView';

const ApprovalsFilters = ({ status, setStatus, loading, responsiblePerson, resourceType, setResourceType, setResponsiblePerson, onFilter }) => {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()

    let stateOptions = [
        { key: 1, text: t('invoices'), value: 'invoice' },
        { key: 2, text: t('budget'), value: 'budget' },
        { key: 3, text: t('planned_courses'), value: 'plannedcourse' },
        { key: 4, text: t('projects'), value: 'project' },
        { key: 5, text: t('recruitments'), value: 'recruitment' },
        { key: 6, text: t('recruitments_stage_move'), value: 'recruitmentstage' },
    ];

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await onFilter("", true)
    }

    return (
        <Grid>
            <Grid.Row columns={2} style={{ paddingBottom: 0, paddingTop: 0 }}>
                <Grid.Column>
                    <Button.Group basic size="small" style={{ borderRadius: '0', marginTop: "1.8rem" }}>
                        <Button active={status === null} content={t('pending')} onClick={() => setStatus(null)} />
                        <Button active={status} content={t('approved')} onClick={() => setStatus(true)} />
                        <Button active={status === false} content={t('denied')} onClick={() => setStatus(false)} />
                    </Button.Group>
                </Grid.Column>
                <Grid.Column>
                    <Form onSubmit={() => handleSubmit()}>
                        <Form.Group widths="equal">
                            <CanView permissions={["approvals.c_view_all_approvals"]}>
                                <SuperField
                                    as="choice"
                                    search
                                    label={t('responsible_person')}
                                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                                    text="fullname"
                                    value={responsiblePerson}
                                    onChange={(e, { value }) => {
                                        setResponsiblePerson(value);
                                    }}
                                />
                            </CanView>
                            <SuperField
                                as="choice"
                                label={t('resource')}
                                // multiple
                                search
                                customOptions={stateOptions}
                                onChange={(e, { value }) => {
                                    setResourceType(value);
                                }}
                                value={resourceType}
                            />
                            
                            <Form.Field style={{ marginTop: "1.8rem" }}>
                                <Button disabled={loading} fluid primary content={t('confirm')}/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ApprovalsFilters;
