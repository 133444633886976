import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store 
import { routes } from '@routes';
import { API } from '@store/config';
import { fileDownload } from '@helpers/requests';
import { useSelectOptions, useHasPermissions } from '@helpers/hooks';
// eslint-disable-next-line
import { getRequest, createRequest, patchRequest, deleteRequest } from '@services/ServiceCommon';
// components 
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Button, Icon, Divider, Header } from 'semantic-ui-react';

const Documents = ({ contract, setContracts, onClose }) => {
    const { t } = useTranslation()
    let canEdit = useHasPermissions(['contracts.c_manage_all_contracts']);
    const [view, setView] = useState(1) // templates = 1, uploaded = 2
    const [isProcessing, setIsProcessing] = useState(false)
    const [templates] = useSelectOptions(API.TEMPLATING + "?source=contracts.ContractInstance", "title")
    const [documentGroups, setDocumentGroups] = useSelectOptions(API.ATTACHMENT_GROUPS, "name")

    const [template, setTemplate] = useState(contract.template?.id || "")
    const [templateAdditions, setTemplateAdditions] = useState(contract.template_additions.map(item => ({
        id: item.id,
        processing: false
    })))

    // eslint-disable-next-line
    const [documentGroup, setDocumentGroup] = useState("")
    const [uploadedDoc, setUploadedDoc] = useState(null)
    const [uploadedDocError, setUploadedDocError] = useState(null)
    // eslint-disable-next-line
    const [uploadedDocAdditions, setUploadedDocAdditions] = useState(contract.external_file_additions.map(item => ({
        id: item.id,
        name: item.name,
        file: item.file,
        source: null,
        processing: false,
        error: null,
    })))

    const generate = async () => {
        setIsProcessing(true)
        let additions = []
        for (let i = 0; i < templateAdditions.length; i++) {
            if( templateAdditions[i].id !== "" ){
                additions.push(templateAdditions[i].id)
            }
        }

        const request = await patchRequest(API.CONTRACTS_INSTANCES + contract.id + "/", {
            template: template,
            template_additions: {
                remove: contract.template_additions.map(item => item.id),
                add: additions
            }
        })

        if( request.status === 200 ){
            setContracts(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter(item => {
                        if (item.id === contract.id) {
                            item.template = request.response.template
                            item.template_additions = request.response.template_additions
                        }
        
                        return item
                    })
                }
            }))

            // generate main template 
            const docHtmlRequest = await getRequest(API.TEMPLATING + `${template}/fill/contracts.ContractInstance/${contract.id}`)
            if( docHtmlRequest.status === 200 ){
                await fileDownload("POST", API.EXPORTS_HTML_TO_PDF, "application/pdf", "contract.pdf", {
                    content: docHtmlRequest.response.html
                })
            }
            // generate each docs in new card
            for (let i = 0; i < templateAdditions.length; i++) {
                if(templateAdditions[i].id !== ""){
                    onTemplateChange(i, "processing", true)
                    const request = await getRequest(API.TEMPLATING + `${templateAdditions[i].id}/fill/contracts.ContractInstance/${contract.id}`)
                    if( request.status === 200 ){
                        await fileDownload("POST", API.EXPORTS_HTML_TO_PDF, "application/pdf", `addition-${(i + 1)}.pdf`, {
                            content: request.response.html
                        })
                    }
                    onTemplateChange(i, "processing", false)
                }
            }
        }

        setIsProcessing(false)
    }

    const handleStoreFile = async () => {
        setIsProcessing(true)

        // handle contract file first 
        let request = undefined
        if( uploadedDoc !== null ){ // create
            const formData = new FormData()
            formData.append("external_file", uploadedDoc)
            request = await patchRequest(API.CONTRACTS_INSTANCES + contract.id + "/file/", formData)

            if(request.status !== 200){
                setUploadedDocError(request.status.external_file)
            }
        }

        // handle additions (if new then create, other pass)
        let additions = []
        for (let i = 0; i < uploadedDocAdditions.length; i++) {
            onFileChange(i, "processing", true)
            if( uploadedDocAdditions[i].id === "" ){ // create
                const formData = new FormData()
                formData.append("name", uploadedDocAdditions[i].name)
                formData.append("file", uploadedDocAdditions[i].source)
                if( documentGroup !== "" ){
                    formData.append("group", documentGroup)
                }
                
                const additionRequest = await createRequest(API.ATTACHMENTS, formData)
                if( additionRequest.status === 201 ){
                    additions.push(additionRequest.response.id)
                    onFileChange(i, "id", additionRequest.response.id)
                } else {
                    onFileChange(i, "error", additionRequest.response.file)
                }
            } else {
                additions.push(uploadedDocAdditions[i].id)
            }
            onFileChange(i, "processing", false)
        }

        const contractRequest = await patchRequest(API.CONTRACTS_INSTANCES + contract.id + "/", {
            external_file: request?.status === 200 ? request.response.external_file : contract.external_file,
            external_file_additions: {
                add: additions
            }
        })

        if( contractRequest.status === 200 ){
            setContracts(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter(item => {
                        if (item.id === contract.id) {
                            item.external_file = contractRequest.response.external_file
                            item.external_file_additions = contractRequest.response.external_file_additions
                        }
        
                        return item
                    })
                }
            }))
        }

        if( uploadedDocError === null && uploadedDocAdditions.filter(item => item.error !== null).length === 0 ){
            onClose()
        }


        setIsProcessing(false)
    }

    const onAddTemplateAddition = () => {
        setTemplateAdditions(prevState => ([...prevState, {
            id: "",
            processing: false
        }]))
    }

    const onAddFileAddition = () => {
        setUploadedDocAdditions(prevState => ([...prevState, {
            id: "",
            name: "",
            file: "",
            source: null,
            processing: false,
            error: null
        }]))
    }

    // eslint-disable-next-line
    const handleAddItem = async (item) => {
        const request = await createRequest(API.ATTACHMENT_GROUPS, {
            name: item
        })

        if (request.status === 201) {
            setDocumentGroups({...documentGroups, options: [...documentGroups.options, { key: request.response.id, value: request.response.id, text: request.response.name }]})
            setDocumentGroup(request.response.id)
        }
    }

    const onTemplateChange = (index, updatedKey, value) => {
        setTemplateAdditions(prevState => prevState.filter((record, key) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const onFileChange = (index, updatedKey, value) => {
        setUploadedDocAdditions(prevState => prevState.filter((record, key) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const onRemove = async (index) => {
        setTemplateAdditions(prevState => prevState.filter((stateItem, key) => key !== index))
    }

    const onFileRemove = async (index, item) => {
        if( item.id === "" ){
            setUploadedDocAdditions(prevState => prevState.filter((stateItem, key) => key !== index))
        } else {
            const request = await deleteRequest(API.ATTACHMENTS + item.id + "/")
            if(request.status === 204){
                setUploadedDocAdditions(prevState => prevState.filter((stateItem, key) => stateItem.id !== item.id))
            }
        }
    }

    const onRemoveContractFile = async () => {
        const request = await deleteRequest(API.CONTRACTS_INSTANCES + contract.id + "/file/")
        if( request.status === 204 ){
            setUploadedDoc(null)
            setContracts(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter(item => {
                        if( item.id === contract.id ){
                            item.external_file = null
                        }
    
                        return item
                    })
                }
            }))

        }
    }

    const isFormValid = () => {
        if( view === 1 && template !== ""){
            return true
        } else if( view === 2 && (contract.external_file !== null || uploadedDoc !== null)){
            return true
        }

        return false
    }

    const Additions = () => {
        return (
            <>
                <Divider/>
                <Header as="h3" content={t('additions')}/>
                <Divider/>
            </>
        )
    }


    return (
        <>
            <Button.Group size="small" basic fluid style={{ borderRadius: "0rem"}}>
                <Button active={view === 1} onClick={() => setView(1)}>{ t("with_templates") }</Button>
                <Button active={view === 2} onClick={() => setView(2)}>{ t("uploaded_docs_versions") }</Button>
            </Button.Group>
            <Divider/>
            <p> 
                { view === 1 && <> <Icon name="info circle"/> {t('template_generated_docs_hint')} <Link to={routes.SETTINGS + "templates"} target="_blank">{t('in_template_settings')}</Link>. </> }
                { view === 2 && <> <Icon name="info circle"/> {t('uploaded_scan_versions_docs_hint')} <Link to={routes.EMPLYOEES_DETAIL + contract.employee.id} target="_blank">{t('in_employee_card_section_documents')}</Link>.</> }
            </p>
            <Divider/>
            <Form onSubmit={ view === 1 ? generate : handleStoreFile }>
                { view === 1 && 
                    <>
                        <SuperField as="choice"
                            required
                            search
                            label={t('contract')}
                            value={template}
                            loading={templates.isLoading}
                            disabled={templates.isLoading || !canEdit}
                            customOptions={templates.options}
                            onChange={(e, { value }) => setTemplate(value)}
                        />
                        <Additions/>

                        { templateAdditions.map((item, index) => (
                            <Form.Group key={index}>
                                <SuperField as="choice"
                                    search
                                    width="14"
                                    value={item.id}
                                    loading={templates.isLoading}
                                    disabled={templates.isLoading || !canEdit}
                                    customOptions={templates.options}
                                    onChange={ (e, { value }) => onTemplateChange(index, "id", value) }
                                />
                                <Form.Field width={2} style={{ textAlign: "center", marginTop: "0.7rem" }}>
                                    <Icon name="close" style={{ cursor: "pointer", color: "var(--danger)" }} onClick={() => onRemove(index)}/>
                                </Form.Field>
                            </Form.Group>
                        )) }
                    </>
                }
                { view === 2 && 
                    <>
                        { contract.external_file === null 
                            ? 
                            <Form.Group widths="equal">
                                <CanView permissions={['contracts.c_manage_all_contracts']}>
                                    <SuperField as="input" 
                                        required
                                        type="file" 
                                        label={ t('contract') }
                                        error={uploadedDocError !== null ? t('not_supported_file_type_only_pdf_docx') : false}
                                        onChange={(event) => {
                                            if (event.target.files.length > 0) {
                                                setUploadedDoc(event.target.files[0]);
                                            }
                                        }}
                                    />
                                </CanView>
                                {/* <SuperField
                                    as="choice"
                                    search
                                    allowAdditions
                                    help={ t('help_group_create') }
                                    onAddItem={(e, { value }) => handleAddItem(value)}
                                    customOptions={documentGroups.options}
                                    loading={documentGroups.isLoading}
                                    disabled={documentGroups.isLoading}
                                    label={t('folder')}
                                    value={documentGroup}
                                    onChange={(e, { value }) => setDocumentGroup(value)}
                                /> */}
                            </Form.Group>
                            :
                            <Form.Group widths="equal">
                                <Form.Field>
                                    <label><strong> {t("contract")} </strong></label>
                                    <div style={{ marginTop: "0.5rem" }}>
                                        <a href={contract.external_file} download={contract.external_file} target="_blank" rel="noopener noreferrer"> 
                                            <Icon name="file alternate"/> { t('contract') + " - " + contract.employee.fullname }
                                        </a>
                                        <CanView permissions={['contracts.c_manage_all_contracts']}>
                                            <Icon style={{ marginLeft: "1rem", cursor: "pointer" }} name="close" onClick={ () => onRemoveContractFile() }/>
                                        </CanView>
                                    </div>
                                </Form.Field>
                                {/* <SuperField
                                    as="choice"
                                    search
                                    allowAdditions
                                    help={ t('help_group_create') }
                                    onAddItem={(e, { value }) => handleAddItem(value)}
                                    customOptions={documentGroups.options}
                                    loading={documentGroups.isLoading}
                                    disabled={documentGroups.isLoading}
                                    label={t('folder')}
                                    value={documentGroup}
                                    onChange={(e, { value }) => setDocumentGroup(value)}
                                /> */}
                            </Form.Group>
                        }
                        <Additions/>

                        { uploadedDocAdditions.map((item, index) => (
                            <Form.Group key={index}>
                                { item.id === "" 
                                    ? 
                                    <Form.Group widths="equal">
                                        <SuperField as="input" 
                                            required
                                            type="file" 
                                            error={item.error !== null ? t('not_supported_file_type_only_pdf_docx') : false}
                                            onChange={(event) => {
                                                if (event.target.files.length > 0) {
                                                    onFileChange(index, "name", event.target.files[0].name);
                                                    onFileChange(index, "source", event.target.files[0]);
                                                }
                                            }}
                                        />
                                        <CanView permissions={['contracts.c_manage_all_contracts']}>
                                            <Form.Field style={{ marginTop: "0.7rem" }}>
                                                <Icon style={{ marginLeft: "1rem", cursor: "pointer" }} name="close" onClick={ () => onFileRemove(index, item) }/>
                                            </Form.Field>
                                        </CanView>
                                    </Form.Group>
                                    :
                                        <Form.Group>
                                            <Form.Field style={{ marginLeft: "0.5rem" }}>
                                                <a href={item.file} download={item.file} target="_blank" rel="noopener noreferrer"> 
                                                    <Icon name="file alternate"/> { item.name }
                                                </a>
                                                <CanView permissions={['contracts.c_manage_all_contracts']}>
                                                    <Icon style={{ marginLeft: "1rem", cursor: "pointer" }} name="close" onClick={ () => onFileRemove(index, item) }/>
                                                </CanView>
                                            </Form.Field>
                                        </Form.Group>
                                }
                            </Form.Group>
                        )) }
                    </>
                }

                <CanView permissions={['contracts.c_manage_all_contracts']}>
                    <Button type="button" size="small" primary content={t("add_addition")} onClick={ view === 1 ? onAddTemplateAddition : onAddFileAddition}/>
                </CanView>
                <Divider/>
                <Form.Field style={{ textAlign: "right"}}>
                    <ModalCancel onClose={onClose}/>
                    { view === 1 
                        ?   
                            <ModalSubmit
                                disabled={isProcessing || !isFormValid()}
                                loading={isProcessing}
                                text={t('save_and_generate_docs')}
                            />
                        :
                            <CanView permissions={['contracts.c_manage_all_contracts']}>
                                <ModalSubmit
                                    disabled={isProcessing || !isFormValid()}
                                    loading={isProcessing}
                                    text={t('save')}
                                />
                            </CanView>
                    }
                    
                </Form.Field>
            </Form>
        </>
    );
};

export default Documents;