import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { useQueryPage } from '@helpers/hooks';
import { fileDownload, requests } from '@helpers/requests';
import { useHasPermissions } from '@helpers/hooks';
// Components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import ConfirmModal from '@components/modals/ConfrimModal';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexTable, FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Form, Button, Segment, Message, Divider } from 'semantic-ui-react';
import TimesheetsFilters from '../components/TimesheetsFilters';
import Loading from '@components/general/Loading';
import Paginator from '@components/Paginator';

const Timesheets = () => {
    const { t } = useTranslation();
    let page = useQueryPage()
    const canManageRecords = useHasPermissions(['timesheets.c_manage_all_records'])

    const [limit, setLimit] = useState(10)

    const initState = {
        team: "",
        dateStart: "",
        dateEnd: ""
    }

    const [filter, setFilter] = useState(initState)
    const location = useLocation()
    const [members, setMembers] = useState()

    useEffect(() => {
        fetchMembers()
        // eslint-disable-next-line
    }, [filter.team])

    const onFilter = () => {
        let filterBaseParams = ""

        if (filter.team !== "") {
            filterBaseParams += "&team=" + filter.team
        }

        if (filter.dateStart !== "") {
            filterBaseParams += "&start_date=" + filter.dateStart
        }

        if (filter.dateEnd !== "") {
            filterBaseParams += "&end_date=" + filter.dateEnd
        }

        return filterBaseParams
    }

    const fetchMembers = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if ((!members || !members?.isLoading) && filter.team) {

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if (!params.includes('page')) {
                params += "&page=" + page
            }

            if (!params.includes('limit')) {
                params += "&limit=" + limit
            }

            setMembers(prevState => ({
                ...prevState,
                isLoading: true
            }))

            const request = await requests.get(API.TEAMMEMBERS + "?paginate=true" + params)

            if (request.status === 200) {
                setMembers(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }
        }
    }

    const FlexRowWithToggle = ({ rowContent, content, loading }) => {
        const [open, setOpen] = useState(false)

        return (
            <Segment style={{ padding: 0, marginBottom: "0.4rem", marginTop: 0 }}>
                <FlexRow background="var(--light)" borders>
                    <FlexItem textAlign="left" basis="10%">
                        <Icon
                            style={{ cursor: 'pointer' }}
                            name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
                            onClick={() => setOpen(!open)}
                        />
                    </FlexItem>
                    {rowContent}
                </FlexRow>
                {open &&
                    <Segment loading={loading} style={{ padding: 0, marginTop: 0, border: "none" }}>
                        {content}
                    </Segment>
                }
            </Segment>
        )
    }

    const MemberRow = ({ member }) => {
        return (
            <>
                <FlexItem bold basis="120%">
                    {member?.profile?.fullname}
                </FlexItem>
                <FlexItem bold basis="10%" textAlign="right">
                    <Icon
                        title="Save record"
                        style={{ cursor: "pointer", color: "var(--primary)" }}
                        name="download-outline"
                        onClick={() => downloadReport(member)}
                    />
                </FlexItem>
            </>
        )
    }

    const initStateRecords = {
        search: "",
        date_start: "",
        date_end: "",
        category: "",
        business_detail: ""
    }

    const [filtersRecords, setFiltersRecords] = useState(initStateRecords)
    const [loadingBussiness, setLoadingBussiness] = useState(false)
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [options, setOptions] = useState([])
    const [categories, setCategories] = useState([])

    async function fetchBussiness() {
        setLoadingBussiness(true)
        const request = await requests.get(API.BUSINESS_DETAIL + "?is_freelancer=false&is_supplier=false&query={id, name, source}")

        if (request.status === 200) {
            const employer_options = []
            for (let i = 0; i < request.response.length; i++) {
                employer_options.push({ key: request.response[i].id, value: request.response[i].id, text: request.response[i].name, source: request.response[i].source });
            }

            setOptions(employer_options)
        }

        setLoadingBussiness(false)
    }

    const monthStart = moment().startOf('month').format('YYYY-MM-DD');
    const monthEnd = moment().endOf('month').format('YYYY-MM-DD');

    const downloadReport = async (member) => {
        let startDate;
        let endDate;
        if (filtersRecords.date_start) { startDate = filtersRecords.date_start; }
        else { startDate = monthStart }
        if (filtersRecords.date_end) { endDate = filtersRecords.date_end; }
        else { endDate = monthEnd }

        await fileDownload("GET", API.TIMESHEETS_EXPORTS + "?start_date=" + startDate + "&end_date=" + endDate + "&profile=" + member.profile.id, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx-export.xlsx")
    }

    async function fetchCategories() {
        setLoadingCategories(true)
        const request = await requests.get(API.TIMESHEETS_CATEGORIES)

        if (request.status === 200) {
            const catOpt = []
            for (let i = 0; i < request.response.length; i++) {
                catOpt.push({ key: request.response[i].id, value: request.response[i].id, text: request.response[i].title });
            }

            setCategories(catOpt)
        }

        setLoadingCategories(false)
    }

    useEffect(() => {
        fetchBussiness()
        fetchCategories()
        // eslint-disable-next-line
    }, [])

    const RecordsList = ({ member }) => {
        const [visible, setVisible] = useState(false)
        const [records, setRecords] = useState([])
        const [loadingRecords, setLoadingRecords] = useState(true)
        const [loadingRecord, setLoadingRecord] = useState(false)

        const [newRecord, setNewRecord] = useState({
            date: '',
            activity: '',
            progress: 0,
            hours: 0,
            business_detail: null,
            milestone: null,
            project: null,
            category: 0,
            profile: member.profile.id
        })

        const [errorList, setErrorList] = useState([]);

        const handleErrors = (response) => {
            let errorsArray = [];
            if (response.hours) {
                errorsArray.push(response.hours)
            }
            if (response.business_detail) {
                errorsArray.push(response.business_detail)
            }
            if (response.date) {
                errorsArray.push(response.date)
            }
            if (response.non_field_errors) {
                errorsArray.push(response.non_field_errors)
            }

            setErrorList(errorsArray);
        }

        const onFilterRecords = () => {
            let filterBaseParams = ""

            if (filtersRecords.search !== "") {
                filterBaseParams += "&search=" + filtersRecords.search
            }

            if (filtersRecords.date_start !== "") {
                filterBaseParams += "&start_date=" + filtersRecords.start_date
            }

            if (filtersRecords.date_end !== "") {
                filterBaseParams += "&end_date=" + filtersRecords.date_end
            }

            if (filtersRecords.category !== "") {
                filterBaseParams += "&category=" + filtersRecords.category
            }

            if (filtersRecords.business_detail !== "" && filtersRecords.business_detail !== null) {
                filterBaseParams += "&business_detail=" + filtersRecords.business_detail
            }

            return filterBaseParams
        }

        async function fetchRecords() {
            setLoadingRecords(true)
            setErrorList([]);
            const request = await requests.get(API.TIMESHEETS_RECORDS + `?profile=${member.profile.id}` + onFilterRecords())

            if (request.status === 200) {
                setRecords(request.response)
            }
            else {
                handleErrors(request.response)
            }

            setLoadingRecords(false)
        }

        async function updateRecords(record) {
            setErrorList([]);
            setRecords(records.map(item => {
                if (item.id === record.id) {
                    return {
                        ...item,
                        is_processing: true
                    }
                }
                return item;
            }));
            const request = await requests.patch(API.TIMESHEETS_RECORDS + record.id + "/", {
                category: record?.category?.id ? record.category.id : record.category,
                activity: record.activity,
                date: record.date,
                hours: record.hours,
                business_detail: record.business_detail?.id || record.business_detail
            })

            if (request.status === 200) {
                setRecords(records.map(item => {
                    if (item.id === request.response.id) {
                        return {
                            ...item,
                            id: request.response.id,
                            is_processing: false
                        }
                    }
                    return item;
                }));
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('record_updated'),
                    animation: 'bounce',
                    time: 1000,
                }); 
            }
            else {
                handleErrors(request.response)
                setRecords(records.map(item => {
                    if (item.id === request.response.id) {
                        return {
                            ...item,
                            is_processing: false
                        }
                    }
                    return item;
                }));
            }
            setLoadingRecords(false)
        }

        async function addNewRecord(recordNew) {
            setErrorList([]);
            setLoadingRecord(true)
            const result = await requests.post(API.TIMESHEETS_RECORDS + "manual/", recordNew)

            if (result.status === 201) {
                setRecords([...records, result.response]);
                setNewRecord({
                    date: '',
                    activity: '',
                    progress: 0,
                    hours: 0,
                    business_detail: null,
                    milestone: null,
                    project: null,
                    category: 0,
                    profile: member.profile.id
                })
                setVisible(false)
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('record_created'),
                    animation: 'bounce',
                    time: 1000,
                }); 
            }
            else {
                handleErrors(result.response)
            }
            setLoadingRecord(false)
        }

        async function deleteRecords(id) {
            const result = await requests.del(API.TIMESHEETS_RECORDS + id + "/")
            if (result.status === 204) {
                setRecords(records.filter((item) => item.id !== id))
            }
        }

        useEffect(() => {
            fetchRecords()
            // eslint-disable-next-line
        }, [filtersRecords])

        const clearRow = () => {
            setVisible(false)
            setNewRecord({
                date: '',
                activity: '',
                progress: 0,
                hours: 0,
                business_detail: null,
                milestone: null,
                project: null,
                category: 0,
                profile: member.profile.id
            })
        }

        const onRecordChange = (index, value, attribute) => {
            setRecords(prev => prev.filter((record, key) => {
                if (index === key) { record[attribute] = value }
                return record
            }))
        }

        const isRecordValid = (record) => {
            if (record.category === "" || record.category === 0) return false
            if (record.date === "" || record.date === null) return false
            if (record.hours > 24 || record.hours <= 0) return false
            
            return true
        }

        return (
            <Segment loading={loadingRecords} style={{ minHeight: "10rem" }}>
                { loadingRecords && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('loading_records') } </p> }
                { !loadingRecords && 
                    <Form>
                        <Message
                            error
                            visible={errorList.length > 0}
                            header={t("error_submission_message")}
                            list={errorList}
                        />
                        <Form.Group size="small" widths='equal'>
                            <Form.Field required style={{ fontWeight: "bold" }}>{t('activity')} <sup style={{ color: "var(--danger)" }}>*</sup></Form.Field>
                            <Form.Field required style={{ fontWeight: "bold" }}>{t('date')} <sup style={{ color: "var(--danger)" }}>*</sup></Form.Field>
                            <Form.Field required style={{ fontWeight: "bold", marginLeft: "1rem" }} width="6">{t('hours')} <sup style={{ color: "var(--danger)" }}>*</sup></Form.Field>
                            <Form.Field required style={{ fontWeight: "bold" }}>{t('employer')}</Form.Field>
                            <Form.Field required style={{ fontWeight: "bold" }}>{t('note')}</Form.Field>
                            {canManageRecords && <Form.Field style={{ fontWeight: "bold" }}>{t('actions')}</Form.Field>}
                        </Form.Group>
                            <EmptyRow length={records.length || 0} />
                            { 
                                records.map((record, index) => (
                                    canManageRecords
                                        ?
                                        <Form.Group size="small" key={index} widths='equal'>
                                            <SuperField as="choice"
                                                search
                                                disabled={loadingCategories}
                                                loading={loadingCategories}
                                                customOptions={categories}
                                                value={record?.category}
                                                placeholder={record?.category?.title}
                                                style={{ height: "2.5rem", fontSize: "1rem" }}
                                                onChange={(e, { value }) => onRecordChange(index, value, "category")}
                                            />
                                            <SuperField
                                                style={{ height: "2.5rem", fontSize: "1rem" }}
                                                as="datepicker"
                                                value={record?.date}
                                                onChange={(e, { value }) => onRecordChange(index, value, "date")}
                                            />
                                            <SuperField as="input" type="number" min="0" max="24"
                                                value={record?.hours}
                                                width="6"
                                                style={{ height: "2.5rem", fontSize: "1rem" }}
                                                error={record.hours > 24 || record.hours < 0}
                                                pattern="^-?[0-9]\d*\.?\d*$"
                                                onChange={(e, { value }) => onRecordChange(index, value, "hours")}
                                            />
                                            <SuperField as="choice"
                                                search
                                                disabled={loadingBussiness}
                                                loading={loadingBussiness}
                                                customOptions={options}
                                                value={record?.business_detail?.id || record?.business_detail}
                                                placeholder={record?.business_detail?.name}
                                                style={{ height: "2.5rem", fontSize: "1rem" }}
                                                onChange={(e, { value }) => onRecordChange(index, value, "business_detail")}
                                            />
                                            <SuperField as="input"
                                                value={record?.activity}
                                                style={{ height: "2.5rem", fontSize: "1rem" }}
                                                onChange={(e, { value }) => onRecordChange(index, value, "activity")}
                                            />
                                            <Button
                                                size="tiny"
                                                type="button"
                                                style={{ background: "var(--black)", color: "var(--white)", height: "2.5rem", width: "50%", fontSize: "1rem" }}
                                                onClick={() => updateRecords(record)}
                                                loading={record?.is_processing}
                                                disabled={record?.is_processing || !isRecordValid(record)}
                                            >
                                                {t('update')}
                                            </Button>
                                            <ConfirmModal
                                                description={t('are_you_sure')}
                                                onConfirm={() => deleteRecords(record.id)}
                                                button={
                                                    <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer", width: "35%", marginTop: '0.5rem' }} />}
                                            />
                                        </Form.Group>
                                        :
                                        <FlexRow background="transparent" fontSize="1rem" key={index} padding="0.5rem" borders>
                                            <FlexItem basis="110%" content={record?.category?.title} />
                                            <FlexItem basis="110%" content={record?.date} />
                                            <FlexItem basis="110%" content={record?.hours} />
                                            <FlexItem content={record?.business_detail?.name} />
                                            <FlexItem basis="110%" content={record?.activity} />
                                        </FlexRow>
                                ))
                            }   
                            {canManageRecords && visible &&
                                <Form.Group size="small" widths='equal'>
                                    <SuperField as="choice"
                                        search
                                        disabled={loadingCategories}
                                        loading={loadingCategories}
                                        customOptions={categories}
                                        value={newRecord.category || ""}
                                        style={{ height: "2.5rem", fontSize: "1rem" }}
                                        onChange={(e, { value }) => setNewRecord(() => ({ ...newRecord, category: value }))}
                                    />
                                    <SuperField
                                        style={{ height: "2.5rem", fontSize: "1rem" }}
                                        as="datepicker"
                                        value={newRecord.date}
                                        onChange={(e, { value }) => setNewRecord(() => ({ ...newRecord, date: value }))}
                                    />
                                    <SuperField as="input" type="number" min="0" max="24"
                                        value={newRecord.hours}
                                        width="6"
                                        style={{ height: "2.5rem", fontSize: "1rem" }}
                                        onChange={(e, { value }) => setNewRecord(() => ({ ...newRecord, hours: value }))}
                                    />
                                    <SuperField as="choice"
                                        search
                                        disabled={loadingBussiness}
                                        loading={loadingBussiness}
                                        customOptions={options}
                                        value={newRecord.business_detail}
                                        style={{ height: "2.5rem", fontSize: "1rem" }}
                                        onChange={(e, { value }) => setNewRecord(() => ({ ...newRecord, business_detail: value }))}
                                    />
                                    <SuperField as="input"
                                        value={newRecord.activity}
                                        style={{ height: "2.5rem", fontSize: "1rem" }}
                                        onChange={(e, { value }) => setNewRecord(() => ({ ...newRecord, activity: value }))}
                                    />

                                    <Button
                                        size="tiny"
                                        type="button"
                                        loading={loadingRecord}
                                        disabled={loadingRecord || !isRecordValid(newRecord)}
                                        style={{ background: "var(--black)", color: "var(--white)", width: "50%", height: "2.5rem", fontSize: "1rem" }}
                                        onClick={() => addNewRecord(newRecord)}
                                    >
                                        {t('add')}
                                    </Button>
                                    <Button
                                        size="tiny"
                                        type="icon"
                                        style={{ cursor: "pointer", paddingTop: '0.5rem', width: "35%", background: "none", padding: 0, height: "2.5rem", fontSize: "1rem" }}
                                        onClick={() => clearRow()}
                                    >
                                        <Icon name="close-outline" style={{ color: "var(--danger)", marginLeft: '0.5rem' }} />
                                    </Button>
                                </Form.Group>
                            }

                        <Divider/>
                        {(canManageRecords && !visible) &&
                            <Button type="button" primary onClick={() => setVisible(!visible)}>
                                {t('add_new_record')}
                            </Button>
                        }
                    </Form>
                }
            </Segment>
        )
    }

    return (
        <>
            <TimesheetsFilters filter={filter} setFilter={setFilter} options={options} filtersRecords={filtersRecords} setFiltersRecords={setFiltersRecords} />
            {!members ?
                <div style={{ textAlign: "center", fontSize: "1.3rem" }}>
                    <strong>{t('please_select_team')}</strong>
                    <br />
                </div>
                : members?.isLoading
                    ?
                    <Loading />
                    :
                    <>
                        {members?.data?.results?.length === 0 ?
                            <EmptyRow length={members?.data?.results?.length || 0} />
                            :
                            <>
                                <FlexTable stripped={false} hoverable={false}>
                                    {members?.data?.results?.map(member => (
                                        <FlexRowWithToggle
                                            key={member.id}
                                            loading={false}
                                            rowContent={<MemberRow member={member} />}
                                            content={
                                                <RecordsList member={member} />
                                            }
                                        />
                                    ))}
                                </FlexTable>
                                <Paginator
                                    forcePage={page || 1}
                                    limit={limit}
                                    setLimit={setLimit}
                                    length={members?.data?.count || 0}
                                    onChange={(params) => fetchMembers(params)}
                                />
                            </>
                        }
                    </>
            }
        </>
    );
};

export default Timesheets;