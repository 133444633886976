import React, { useState, useRef } from 'react';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { icons } from '@icons';
import { routes } from '@routes';
import { API } from '@store/config';
import { dates } from '@helpers/dates';
import { useQueryPage } from '@helpers/hooks';
import { useLocation } from 'react-router-dom';
import { useFetchData, useSelectOptions } from '@helpers/hooks';
import { getRequest, createRequest, deleteRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import AvatarIcon from '@components/AvatarIcon';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// eslint-disable-next-line
import ConfirmModal from '@components/modals/ConfrimModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import { Container, Button, Icon as SemanticIcon, Segment, Grid, Sidebar, Menu, Ref, Form, Divider, Header, List, Popup } from 'semantic-ui-react';
// module specific components
import AgreementForm from './components/AgreementForm';
import Paginator from '@components/Paginator';

const GDPRAgreements = () => {
    const { t } = useTranslation()

    const history = useHistory()
    let page = useQueryPage()
    const location = useLocation()

    const [loading, setLoading] = useState(false)
    const [reFetched, setReFetched] = useState(false)
    const [limit, setLimit] = useState(10)

    const segmentRef = useRef()
    const [agreements, setAgreements] = useFetchData(API.GDPR_AGREEMENTS + "?paginate=true")
    const [employees] = useSelectOptions(API.EMPLOYEES + "?query={id, fullname}", "fullname")
    const [candidates] = useSelectOptions(API.CANDIDATES + "?query={id, fullname}", "fullname")
    const [isFiltrating, setIsFiltrating] = useState(false)
    const [visible, setVisible] = useState(false)

    const initFilterState = {
        search: "",
        date_from: "",
        date_to: "",
        profile: "",
        expired: ""
    }
    const [filters, setFilters] = useState(initFilterState)

    const onFilter = () => {
        setIsFiltrating(true)
        let filterBaseParams = ""
        if (filters.date_from !== "") filterBaseParams += "&valid_until_after=" + filters.date_from
        if (filters.date_to !== "") filterBaseParams += "&valid_until_after=" + filters.date_to
        if (filters.profile !== "") filterBaseParams += "&profile=" + filters.profile
        if (filters.expired !== "") filterBaseParams += "&is_gdpr_expired=" + filters.expired

        setVisible(false)
        setIsFiltrating(false)

        return filterBaseParams
    }

    const fetchAgreements = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if (!agreements.isLoading) {
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if (!params.includes('page')) {
                params += "&page=" + page
            }

            if (!params.includes('limit')) {
                params += "&limit=" + limit
            }

            setAgreements(prevState => ({
                ...prevState,
                isLoading: true
            }))

            const request = await getRequest(API.GDPR_AGREEMENTS + "?paginate=true" + params)
            if (request.status === 200) {
                setAgreements(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }

            setReFetched(false)
            setLoading(false)
        }

    }

    const IconMark = ({ icon, color, title }) => {
        return (
            <Popup content={title} position='top center' trigger={
                <Icon name={icon} style={{ color: color, cursor: "pointer", fontSize: "1.2rem" }} />
            } />
        )
    }

    // eslint-disable-next-line
    const onExtend = async (uuid) => {
        const request = await createRequest(API.API_URL + "/gdpr/" + uuid + "/send_extendation/")
        if (request.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('extend_request_successfull'),
                animation: 'bounce',
                time: 5000,
            });
        }
    }

    const onDelete = async (uuid) => {
        const request = await deleteRequest(API.GDPR_AGREEMENTS + uuid + "/")
        if (request.status === 204) {
            setAgreements(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count - 1,
                    results: prev.data.results.filter(item => item.uuid !== uuid)
                }
            }))
        }
    }

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchAgreements("", true)
    }

    const resetFilters = () => {
        setFilters(initFilterState)
        setVisible(false)
        onFilter(initFilterState)
    }

    return (
        <CanView permissions={['common.c_view_gdpr_agreements']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("gdpr_agreements"), 'icon': icons.GDPR_AGREEMENTS, 'href': '' }
                ]}
            />
            <Ref innerRef={segmentRef}>
                <Container fluid>
                    <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                        <Sidebar
                            style={{ width: "40rem" }}
                            as={Menu}
                            animation='overlay'
                            direction='right'
                            icon='labeled'
                            vertical
                            visible={visible}
                            onHide={() => setVisible(false)}
                            width='wide'
                            target={segmentRef}
                        >
                            <Form onSubmit={() => handleSubmit()} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                                <List divided verticalAlign='middle'>
                                    <List.Item>
                                        <List.Content>
                                            <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                                        </List.Content>
                                    </List.Item>
                                </List>

                                <Divider />

                                <SuperField as="choice"
                                    search
                                    label={t('person')}
                                    customOptions={[...candidates.options, ...employees.options]}
                                    loading={candidates.isLoading || employees.isLoading}
                                    disabled={candidates.isLoading || employees.isLoading}
                                    value={filters.profile}
                                    onChange={(e, { value }) => setFilters({ ...filters, profile: value })}
                                />

                                <Form.Group widths="equal">
                                    <SuperField as="datepicker"
                                        clearable
                                        label={t('valid_from')}
                                        value={filters.date_from}
                                        onChange={(e, { value }) => setFilters({ ...filters, date_from: value })}
                                    />
                                    <SuperField as="datepicker"
                                        clearable
                                        label={t('valid_to')}
                                        value={filters.date_to}
                                        onChange={(e, { value }) => setFilters({ ...filters, date_to: value })}
                                    />
                                </Form.Group>

                                <SuperField as="choice"
                                    label={t('Show expired')}
                                    customOptions={[
                                        { key: 1, value: "true", text: t('yes') },
                                        { key: 2, value: "false", text: t('no') },
                                    ]}
                                    value={filters.expired}
                                    onChange={(e, { value }) => setFilters({ ...filters, expired: value })}
                                />

                                <Divider />
                                <Form.Field>
                                    <Button.Group>
                                        <Button size="small" primary disabled={isFiltrating} loading={isFiltrating}> {t('apply')} </Button>
                                        <Button type="button" disabled={isFiltrating} onClick={resetFilters} size="small"> {t('reset')} </Button>
                                    </Button.Group>
                                </Form.Field>
                            </Form>
                        </Sidebar>
                        <Sidebar.Pusher>
                            <Segment loading={agreements.isLoading || isFiltrating || reFetched || loading} style={{ padding: 0, margin: 0, minHeight: "2rem", backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
                                {(!agreements.isLoading && !isFiltrating) && (!reFetched && !loading) &&
                                    <>
                                        <Grid style={{ marginBottom: "1rem" }}>
                                            <Grid.Row style={{ padding: 0 }}>
                                                <Grid.Column computer={8} tables={16} mobile={16}>
                                                    {/* Here comes some view filters */}
                                                </Grid.Column>
                                                <Grid.Column computer={8} tables={16} mobile={16} style={{ textAlign: "right" }}>
                                                    <Button
                                                        size="small"
                                                        style={{
                                                            background: "transparent",
                                                            color: "var(--black)",
                                                            paddingLeft: "1rem",
                                                            paddingRight: "1rem"
                                                        }}
                                                        onClick={() => setVisible(!visible)}
                                                    >
                                                        <Icon name='funnel-outline'/>
                                                    </Button>
                                                    <CanView permissions={["common.c_manage_all_candidates"]}>
                                                        <SuperDuperModal
                                                            trigger={
                                                                <Button size="small" primary> {t('add_agreement')} </Button>
                                                            }
                                                            header={t('add_agreement')}
                                                            size='tiny'
                                                            content={
                                                                <AgreementForm
                                                                    employees={employees}
                                                                    candidates={candidates}
                                                                    setAgreements={setAgreements}
                                                                />
                                                            }
                                                        />
                                                    </CanView>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <FlexTable>
                                            <FlexRow borders>
                                                <FlexHeader content={t('person')} />
                                                <FlexHeader content={t('gdpr')} />
                                                <FlexHeader content={t('checked_when')} />
                                                <FlexHeader content={t('valid_until')} />
                                                <FlexHeader content={t('status')} />
                                                <FlexHeader content={t('actions')} basis="30%" />
                                            </FlexRow>
                                            <EmptyRow length={agreements.data?.results?.length} />
                                            {agreements.data.results.map(agreement => (
                                                <FlexRow background="transparent" fontSize="0.9rem" borders>
                                                    <FlexItem>
                                                        <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                                            {agreement.imported_object &&
                                                                <IconMark icon="pin-outline" color="orange" title={t('Imported')} />
                                                            }
                                                            <FlexItem basis="10%">
                                                                <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                                                    { agreement?.profile && 
                                                                        <AvatarIcon size="30" name={agreement?.profile?.fullname } src={agreement?.profile?.profile_picture} />
                                                                    }
                                                                </SemanticIcon.Group>
                                                            </FlexItem>
                                                            <FlexItem>
                                                                <strong>{agreement?.profile?.fullname || agreement?.email}</strong><br />
                                                            </FlexItem>
                                                        </FlexRow>
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <Link to={routes.GDPRS + agreement.gdpr?.id} target="_blank">
                                                            <strong>{agreement.gdpr?.title}</strong>
                                                        </Link>
                                                        <br />
                                                        {agreement.gdpr?.type_display}
                                                    </FlexItem>
                                                    <FlexItem>{moment(dates.convertUTCDateToLocalDate(agreement.checked_when)).format('DD.MM.YYYY HH:mm')}</FlexItem>
                                                    <FlexItem>{moment(dates.convertUTCDateToLocalDate(agreement.valid_until)).format('DD.MM.YYYY HH:mm')}</FlexItem>
                                                    <FlexItem>
                                                        {moment().isAfter(agreement.valid_until)
                                                            ? <span style={{ color: "var(--danger)", fontWeight: "bold" }}> <Icon name="warning-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{t('expired')}</span></span>
                                                            : dates.diffDays(agreement.checked_when, agreement.valid_until) + " " + t('days_left_gdpr')
                                                        }

                                                        {/* <br/>
                                                        <ConfirmModal
                                                            description={t('confirm_message_extend')}
                                                            onConfirm={() => onExtend(agreement.uuid)}
                                                            button={
                                                                <small style={{ color: "var(--primary)" }} className="ref-link"> {t('request_an_extension')} </small>
                                                            }
                                                        /> */}
                                                    </FlexItem>
                                                    <FlexItem basis="30%">
                                                        <FlexRow padding="0" background="transparent">
                                                            <CanView permissions={['common.c_delete_gdpr_agreements']}>
                                                                <FlexItem>
                                                                    <Icon onClick={() => onDelete(agreement.uuid)} name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                                </FlexItem>
                                                            </CanView>
                                                        </FlexRow>
                                                    </FlexItem>
                                                </FlexRow>
                                            ))}
                                        </FlexTable>
                                    </>
                                }
                            </Segment>
                            <Paginator
                                forcePage={page || 1}
                                limit={limit}
                                setLimit={setLimit}
                                length={agreements?.data?.count || 0}
                                onChange={(params) => fetchAgreements(params)}
                            />
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Container>
            </Ref>
        </CanView>
    );
};

export default GDPRAgreements;