// validation input rules
export const isEmpty = (input) => {
    return input === "";
};

export const isNotEmpty = (input) => {
    return input !== "";
};

export const isEmptyErrorList = (input) => { // isEmptyErrorList([input1, input2, input3])
    return input.every(isNotEmpty);
};

export const isEmailError = (input) => {
    if (
        !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            input
        )
    ) {
        return {
            content: "The email address you entered is in the wrong format.",
        };
    }
    return false;
};

export const isValidEmail = (input) => {
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(input)
};
export const isEmailValid = (input) => {
    return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(input)
}

export const isPhoneNumberValid = (input) => {
    return /^[+0-9]{2,4}[0-9+]{8,15}$/.test(input)
}

// password rules: 1 big letter, 1 number, 8 characters minimum
export const isPasswordError = (input) => {
    if (!/^(?=.*\d)(?=.*[A-Z]).{8,}$/.test(input)) {
        return {
            content: "The password you entered is not strong enough.",
        };
    }
    return false;
};

export const isRepeatPasswordError = (password, repeatPassword) => {
    if (password !== repeatPassword) {
        return {
            content: "The passwords you entered do not match.",
        };
    }
    return false;
};

export const isValidRepeatPassword = (password, repeatPassword) => {
    return password === repeatPassword
};

export const isPhoneError = (input) => {
    if (!/^[+0-9]{2,4}[0-9+]{8,15}$/.test(input)) {
        return {
            content: "The number you entered is in the wrong format.",
        };
    }
    return false;
};

export const isNumberError = (input) => {
    if (!/^(0|[1-9][0-9]*)$/.test(input)) {
        return {
            content: "Integer value must be greater than or equal to 0.",
        };
    }
    return false;
};

export const isDateError = (input) => {
    if (
        !/(^(19|[2-9][0-9])\d\d[-](((0[1-9]|1[012])[-](0[1-9]|1[0-9]|2[0-8]))|((0[13578]|1[02])[-](29|30|31))|((0[469]|11)[-](29|30)))$)|(^(19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)[-]02[-]29$)/.test(
            input
        )
    ) {
        return {
            content: "The date you entered is in the wrong format.",
        };
    }
    return false;
};

export const isTimeError = (input) => {
    if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(input)) {
        return {
            content: "The time entered is in the wrong format.",
        };
    }
    return false;
};

export const isURLError = (input) => {
    if (
        !/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&=]*)/.test(
            input
        )
    ) {
        return {
            content: "The link you entered is in the wrong format.",
        };
    }
    return false;
};
