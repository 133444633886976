import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
// routes
import { routes } from '@routes';
// components
import Icon from '@components/Icon';
import {  Grid, Button, Header} from 'semantic-ui-react'

function RoomCards(props) {
    const { t } = useTranslation();

    const data = {recruitment:props.id}

    return (
        <Grid.Column  mobile={16} tablet={8} computer={6}>
            <Grid >
                <Grid.Row style={{ padding: 0, marginBottom:"1rem", }}>
                    <Grid.Column mobile={16} tablet={6} computer={8}>
                        <Header style={{ fontSize: "1.0rem", paddingTop: "0.6rem",color: props.color }}> {props.name}</Header>
                    </Grid.Column>
                    <Grid.Column floated="right" style={{marginRight: "5rem" }}>
                        {props.in===true
                            ?
                            <Button
                                size="small"
                                icon={<Icon name="eye-outline"/>}
                                as={Link}
                                to={routes.HIRING_ROOM + props.id}
                                style={{paddingLeft:"1.8rem", paddingRight:"1.8rem",  borderRadius: 0}}
                            />
                            :
                            <Button
                                primary
                                size="small"
                                type="button"
                                content={t("add")}
                                onClick={()=>props.addCandidate(props.candidateId, data)}
                            />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Grid.Column>
    );
}

export default RoomCards;