import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
import { hiringService } from '@services/ServiceHiring';
//components
import ChoiceField from '@components/forms/common/ChoiceField'
import SuperField from '@components/forms/SuperField'
import SuggestionField from '@components/forms/common/SuggestionField'
import { Header, Grid, Divider, Button, Form, Checkbox  } from 'semantic-ui-react'

const JobFilterForm = ({ fetchJobOffers, filters, setFilters }) => {

    const [rooms, setRooms] = useState([]);
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        async function fetchData() {
            const result = await hiringService.getRooms();

            if (result.status === 200) {
                let roomsArray = [];
                for (let i = 0; i < result.response.length; i++) {
                    roomsArray.push(
                        { key: result.response[i].id,
                        text: result.response[i].name,
                        value: result.response[i].id})
                }
                setRooms(roomsArray)
            }
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchJobOffers("", true)
    }
    
    // const resetFilters = () => {
    //     setFilters(initState)
    // }

    return (
        <div>
            <Grid>
                <Grid.Row style={{ padding: 0}}>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <Header size="large" content={t('advanced_filter')} style={{marginTop:"0.2rem"}}/>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                        <Button floated="right" size="medium" content={t('apply')} style={{backgroundColor: "var(--primary)", color:"white"}} onClick={() => handleSubmit()}/>
                        {/* <Button floated="right" size="medium" content={t('reset')} style={{}} onClick={()=>resetFilters()}/> */}
                    </Grid.Column>
                </Grid.Row>
                <Divider/>
            </Grid>

            <Form>
                <SuperField as="input"
                    label={t('title')}
                    placeholder={t('title')}
                    value={filters?.title}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        title: value
                    })}
                />
                <Form.Group widths="equal">
                    <SuggestionField
                        label={t('city')}
                        endpoint={API.JOBS + "filter_city/" }
                        text="city"
                        search="city"
                        value={filters.city}
                        onSelect={(value) => setFilters({
                            ...filters,
                            city: value
                        })}
                    />

                    <SuggestionField
                        label={t('region')}
                        endpoint={API.JOBS + "filter_state/"}
                        text="state"
                        search="state"
                        value={filters.state}
                        onSelect={(value) => setFilters({
                            ...filters,
                            state: value
                        })}
                    />
                </Form.Group>

                <ChoiceField
                    search
                    type="countries"
                    label={t('country')}
                    value={filters.country}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        country: value
                    })}
                />
                <ChoiceField
                    search
                    label={t('hiring_rooms')}
                    customOptions={rooms}
                    value={filters.recruitment}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        recruitment: value
                    })}
                />

                <Divider/>

                <Form.Group widths="equal">
                    <Form.Field>
                        <Checkbox
                            label={t('valid_for_disabled_person')}
                            checked={filters.valid_for_disabled_person}
                            onChange={(e, data) => setFilters({
                                ...filters,
                                valid_for_disabled_person: data.checked
                            })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label={t('valid_for_graduate')}
                            checked={filters.valid_for_graduate}
                            onChange={(e, data) => setFilters({
                                ...filters,
                                valid_for_graduate: data.checked
                            })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label={t('valid_for_student')}
                            checked={filters.valid_for_student}
                            onChange={(e, data) => setFilters({
                                ...filters,
                                valid_for_student: data.checked
                            })}
                        />
                    </Form.Field>
                </Form.Group>

            </Form>
        </div>
    );
};

export default JobFilterForm;