import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { thousandsSeparators } from '@helpers/functions';
// components
import { Grid, Card, Segment } from 'semantic-ui-react'

const DataStats = ({ data, currency }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(true)
    const [stats, setStats] = useState({})

    useEffect(() => {
        async function fetchCostCenterStats(){
            setIsLoading(true)
            const request = await getRequest(API.COST_CENTERS + "stats/" + (currency !== "all" ? "?currency=" + currency : ""))
            if( request.status === 200) setStats(request.response)
            setIsLoading(false)
        }

        fetchCostCenterStats()
    }, [currency, data])

    const renderIncome = () => {
        if (currency !== "all") return "+" + parseFloat(stats.total_converted_incomes).toFixed(2) + " " + currency
        if( stats.total_incomes.length === 0 ) return 0

        return stats.total_incomes.map(income => (
            <> + { thousandsSeparators(parseFloat(income.sum).toFixed(2)) + " " + income.currency } <br/> </> 
        ))
    }

    const renderExpense = () => {
        if (currency !== "all") return "-" + parseFloat(stats.total_converted_expenses).toFixed(2) + " " + currency

        if( stats.total_expenses.length === 0 ) return 0

        return stats.total_expenses.map(expense => (
            <> - { thousandsSeparators(parseFloat(expense.sum).toFixed(2)) + " " + expense.currency } <br/> </> 
        ))
    }
    
    const renderDifference = () => {
        if (currency !== "all") return parseFloat(stats.total_converted_incomes - stats.total_converted_expenses).toFixed(2) + " " + currency
        if( stats.total_expenses.length && stats.total_incomes.length === 0 ) return 0

        const income_currencies = stats.total_incomes.map(stats => stats.currency)
        const expense_currencies = stats.total_expenses.map(stats => stats.currency)
        const merged_arrays = (income_currencies.concat(expense_currencies))
        const currencies = merged_arrays.filter((item, pos) => merged_arrays.indexOf(item) === pos)

        return currencies.map(currency => {
            let difference = 0
            // let the magic happens here - return undefind if not found or founded object
            const income = stats.total_incomes.find(item => item.currency === currency)
            const expense = stats.total_expenses.find(item => item.currency === currency)
         
            difference = parseFloat((income !== undefined ? income.sum : 0) - (expense !== undefined ? expense.sum : 0)).toFixed(2)
         
            return <> { thousandsSeparators(difference) + " " + currency } <br/></> 
         })
    }
   
    return (
        <Segment loading={isLoading} style={{ border: 0,  boxShadow: 'none', padding: 0, minHeight: "5rem", background: "transparent" }}>
            { !isLoading && 
                <Grid >
                    <Grid.Row columns={3} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid.Column stretched style={{ textAlign: "center", }}>
                            <Card className="active" fluid style={{ borderRadius: 0 }}>
                                <Card.Content as="h2">
                                    {t('income')}: <br/>
                                    <span style={{ fontSize: "1.3rem", color: "var(--success)" }}>
                                        {renderIncome()}
                                    </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column stretched style={{ textAlign: "center", }}>
                            <Card className="active" fluid style={{ borderRadius: 0 }}>
                                <Card.Content as="h2">
                                    {t('expense')}: <br/>
                                    <span style={{ fontSize: "1.3rem", color: "var(--danger)" }}>
                                        { renderExpense() }
                                    </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column stretched style={{ textAlign: "center", }}>
                            <Card className="active" fluid style={{ borderRadius: 0 }}>
                                <Card.Content as="h2">
                                    {t('total')}: <br/>
                                    <span style={{ fontSize: "1.3rem" }}>
                                        { renderDifference() }
                                    </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </Segment>
    );
};

export default DataStats;