import React from "react";
import { useTranslation } from "react-i18next"
//components
import { Icon, Header } from "semantic-ui-react";
import SortedDocPreview from '@components/SortedDocPreview';
import SuperDuperModal from '@components/modals/SuperDuperModal'

const SortedFileRenderer = ({ name, file }) => {
    const { t } = useTranslation();
    const splittedURI = file?.split('.')
    const fileType = (splittedURI[splittedURI.length - 1] || "pdf").toLowerCase()


    return (
        <SuperDuperModal
            size="large"
            trigger={
                <Header as='h4'>
                    <Icon
                        size="large"
                        title={t('file')}
                        style={{ cursor: "pointer", color: "var(--dark)" }}

                        name={

                            fileType === "pdf" ? "file pdf" :
                            ['docx', 'doc'].includes(fileType) ? "file word" :
                            ['xlsx', 'xls'].includes(fileType) ? "file excel" :
                            ['txt'].includes(fileType) ? "file text" :
                            ['mp4', 'avi', 'mov', 'flv'].includes(fileType) ? "file video outline" :
                            ['mp3', 'm4a', 'wav'].includes(fileType) ? "file audio outline" :
                            ['png', 'gif', 'jpg', 'jpeg'].includes(fileType) ? "file image outline" :
                            "file alternate outline"
                        }
                        color={

                            fileType === "pdf" ? "red" :
                            ['docx', 'doc'].includes(fileType) ? "blue" :
                            ['xlsx', 'xls'].includes(fileType) ? "green" :
                            ['txt'].includes(fileType) ? "grey" :
                            ['mp4', 'avi', 'mov', 'flv'].includes(fileType) ? "violet" :
                            ['mp3', 'm4a', 'wav'].includes(fileType) ? "teal" :
                            ['png', 'gif', 'jpg', 'jpeg'].includes(fileType) ? "yellow" :
                            "black"
                        }
                    />
                    <Header.Content>{name}.{fileType}</Header.Content>
                </Header>


            }
            content={
                <SortedDocPreview uri={file} fileType={fileType} />
            }
        />
);
};


export default SortedFileRenderer;