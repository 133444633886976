import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { icons } from '@icons';
// components
import { Container, Button } from 'semantic-ui-react';
import Timesheet from './Timesheet';
import Timesheets from './Timesheets';
import BreadcrumbNav from '@components/BreadcrumbNav';
import CanView from '@components/perms/CanView';

const TimesheetsView = () => {
    const { t } = useTranslation();
    const [view, setView] = useState(0)

    return (
        <CanView permissions={["timesheets.c_view_user_records", "timesheets.c_videw_all_records"]} redirect>
            <BreadcrumbNav items={[
                { 'name': t('timesheets'), 'icon': icons.TIMESHEETS, 'href': '' }
            ]} />
            <Container fluid >
                <Button.Group basic size="small" style={{ borderRadius: "0" }}>
                    <CanView permissions={["timesheets.c_view_user_records"]}>
                        <Button active={view === 0} content={t('my_records')} onClick={() => setView(0)} />
                    </CanView>
                    <CanView permissions={["timesheets.c_view_all_records"]}>
                        <Button active={view === 1} content={t('group')} onClick={() => setView(1)} />
                    </CanView>
                </Button.Group>
                {view === 0 ? <Timesheet /> : <Timesheets />}
            </Container>
        </CanView>
    );
};

export default TimesheetsView;