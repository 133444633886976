import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { addInvoiceCurrency } from '@store/actions';
import { useQueryPage, useIsMount } from '@helpers/hooks';
import { useLocation } from 'react-router-dom';
import { getRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import SuperField from '@components/forms/SuperField';
import Paginator from '@components/Paginator';
import { Divider, Button, Grid, Segment, Sidebar, Menu, Ref } from 'semantic-ui-react'
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module components
import CCTable from '../components/main/CCTable';
import DataStats from '../components/main/DataStats';
import CostCenterForm from '../components/main/CostCenterForm';
import CostCentresFilterSection from '../components/main/CostCentersFilterSection';

const CCListView = () => {
    const { t } = useTranslation()

    const segmentRef = useRef()
    const dispatch = useDispatch()
    const location = useLocation()
    let page = useQueryPage()
    let isMount = useIsMount()

    const savedCurrency = useSelector((state) => state.invoice_currency)
    const currencies = useSelector(state => state.choices.currency_codes)

    const [limit, setLimit] = useState(10)
    const [currency, setCurrency] = useState(savedCurrency)
    const [visible, setVisible] = useState(false)
    const [centers, setCostCenters] = useState({
        isLoading: true,
        data: {}
    })

    const [filters, setFilters] = useState({
        search: '',
        unit: '',
        projects: []
    })

    const fetchCostCenters = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        setCostCenters(prev => ({ ...prev, isLoading: true}))
        const request = await getRequest(API.COST_CENTERS + "?paginate=true" + (currency !== "all" ? "&currency=" + currency : "" + params))

        if (request.status === 200){
            setCostCenters(prev => ({
                ...prev,
                isLoading: false,
                data: request.response
            }))
        }
    }

    useEffect(() => {
        if (isMount) {
            fetchCostCenters("", true)
        } else {
            fetchCostCenters()
        }
        // eslint-disable-next-line
    }, [currency])

    const onFilter = () => {
        let filterBaseParams = ""

        if (filters.search) filterBaseParams += "&search=" + filters.search
        if (filters.unit) filterBaseParams += "&unit=" + filters.unit
        if (filters.projects.length) {
            for (let item of filters.projects) {
                filterBaseParams += "&projects=" + item
            }
        }
        return filterBaseParams
    }

    return (
        <CanView permissions={['cost_centers.c_view_cost_centers']} redirect>
            <Ref innerRef={segmentRef}>
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                    <Sidebar
                        style={{ width: "40rem" }}
                        as={Menu}
                        animation='overlay'
                        direction='right'
                        icon='labeled'
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        width='wide'
                        target={segmentRef}
                    >
                        <CostCentresFilterSection
                            filters={filters}
                            setFilters={setFilters}
                            fetchCostCentres={fetchCostCenters}
                            setVisible={setVisible}
                        />
                    </Sidebar>
                    <Sidebar.Pusher>
                        <FlexRow padding="0">
                            <FlexItem textAlign="right">
                                <Button
                                    size="small"
                                    style={{
                                        background: "transparent",
                                        color: "var(--dark)",
                                        paddingLeft: "1rem",
                                        paddingRight: "1rem"
                                    }}
                                    onClick={() => setVisible(!visible)}
                                >
                                    <Icon name='funnel-outline'/>
                                </Button>
                                <CanView permissions={['cost_centers.c_view_budgets']}>
                                    <Button
                                        className="white-color"
                                        as={Link}
                                        to={routes.COST_CENTERS + "budgets/"}
                                        style={{
                                            background: "var(--dark)",
                                            borderRadius: 0
                                        }}
                                    >
                                        <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/>
                                        <span>
                                            {t('manage_budgets')}
                                        </span>
                                    </Button>
                                </CanView>
                                <CanView permissions={['cost_centers.c_manage_cost_centers']}>
                                    <SuperDuperModal
                                        header={t("create_cost_center")}
                                        trigger={
                                            <Button primary>
                                                <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/>
                                                <span>
                                                    {t('add_cost_center')}
                                                </span>
                                            </Button>
                                        }
                                        content={<CostCenterForm setCostCenters={setCostCenters}/>}
                                    />
                                </CanView>
                            </FlexItem>
                        </FlexRow>
                        <Divider />
                        <Segment loading={centers.isLoading} basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }}>
                            <Grid>
                                <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Grid.Column style={{ textAlign: "right" }}>
                                        <SuperField inline as="choice"
                                            size="small"
                                            label={<span style={{ marginRight: "1rem" }}>{t('display_in_currency')}</span>}
                                            search
                                            style={{ width: "200px" }}
                                            customOptions={[
                                                { key: 0, text: t('all_currencies'), value: "all" },
                                                ...Object.entries(currencies).map(([key,value]) => ({
                                                    key: key, text: value, value: key
                                                }))
                                            ]}
                                            value={currency || ""}
                                            onChange={(e, { value }) => {
                                                if( value === "" ){
                                                    setCurrency("all")
                                                    dispatch(addInvoiceCurrency("all"))
                                                } else {
                                                    setCurrency(value)
                                                    dispatch(addInvoiceCurrency(value))
                                                }
                                            }}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <DataStats currency={currency}/>
                            <Divider />
                            { centers.isLoading
                                ? <Loading />
                                :
                                <>
                                    <CCTable
                                        currency={currency}
                                        data={centers.data.results}
                                        setData={setCostCenters}
                                    />
                                    <Paginator
                                        forcePage={page || 1}
                                        limit={limit}
                                        setLimit={setLimit}
                                        length={centers?.data?.count || 0}
                                        onChange={(params) => fetchCostCenters(params)}
                                    />
                                </>
                            }
                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Ref>
        </CanView>
    );
};

export default CCListView;