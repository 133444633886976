import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { icons } from '@icons';
import { routes } from '@routes/index';
import { toggleSidebar } from '@services/helpers/functions';
import { allowedModules as modules } from '@store/permissions';
// components
import Icon from '@components/Icon';
import { List } from 'semantic-ui-react';
import SidebarItem from './SidebarItem';
import SidebarCategory from './SidebarCategory';
import CanView from '@components/perms/CanView';

// TODO: Improve routing ("to=..." property, should use routes defined as CONSTANT instead of strings)
const Sidebar = () => {
    const { t } = useTranslation()
    const [toggled, setToggled] = useState(false)

    function Toggle() {
        setToggled(!toggled)
        toggleSidebar()
    }

    return (
        <div className="sidebar scrollable">
            <List.Content>
                <List className="siderbar-list" style={{ marginBottom: 0 }}>
                    <div fluid basic size="large" onClick={Toggle} style={{ cursor: "pointer", padding: "0.5rem", marginTop: "1rem" }}>
                        <span className="hide-text" style={{ position: "relative", top: "-0.2rem" }}>{t('hide_sidebar')}</span>
                        <Icon name="chevron-back-outline" style={{ margin: 0, marginLeft: "1rem" }} className="toggle-icon"/>
                    </div>
                </List>
                <CanView permissions={[
                    'jobs.c_view_job_positions',
                    'contracts.c_view_all_contracts',
                    'contracts.c_view_user_contracts',
                    'common.c_view_all_employees',
                    'common.c_view_user_employees',
                    'benefits.c_view_all_benefits',
                    'benefits.c_view_user_benefits',
                    'courses.c_view_all_courses',
                    'courses.c_view_user_courses',
                    'jobs.c_view_job_offers',
                    'common.c_view_all_candidates',
                    'common.c_view_user_profile',
                    'candidates.c_view_recruitment',
                    'onboarding.c_view_all_checklist_instances',
                    'attendance.c_view_all_records', 
                    'attendance.c_view_user_records'
                ]}>
                    <SidebarCategory text={t('hr_long')} shorttext={t('hr_short')} />
                </CanView>
                <List className="siderbar-list">
                    <SidebarItem
                        permissions={['jobs.c_view_job_positions']}
                        modules={modules.POSITIONS}
                        to={routes.JOBS_POSITIONS}
                        text={t('jobs_positions')}
                        icon={icons.POSITIONS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts']}
                        modules={modules.CONTRACTS}
                        paddingLeft="0.2rem"
                        to={routes.CONTRACTS}
                        text={t('contracts')}
                        icon={icons.CONTRACTS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['common.c_view_all_employees', 'common.c_view_user_employees']}
                        modules={modules.EMPLOYEES}
                        to={routes.EMPLYOEES_LIST}
                        text={t('employees')}
                        icon={icons.EMPLOYEES}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['benefits.c_view_all_benefits', 'benefits.c_view_user_benefits']}
                        modules={modules.BENEFITS}
                        to={routes.BENEFITS_LIST}
                        text={t('benefits')}
                        icon={icons.BENEFITS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['courses.c_view_all_courses', 'courses.c_view_user_courses']}
                        modules={modules.COURSES}
                        paddingLeft="0.2rem"
                        to={routes.COURSES}
                        text={t('courses')}
                        icon={icons.COURSES}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['jobs.c_view_job_offers']}
                        modules={modules.JOBS}
                        to={routes.JOBS}
                        text={t('jobs')}
                        icon={icons.JOBS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['common.c_view_all_candidates', 'common.c_view_user_profile']}
                        modules={modules.CANDIDATES}
                        to={routes.CANDIDATES_LIST}
                        text={t('candidates')}
                        icon={icons.CANDIDATES}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['candidates.c_view_recruitment']}
                        modules={modules.HIRING_ROOMS}
                        to={routes.HIRING}
                        text={t('hiring_rooms')}
                        icon={icons.HIRING_ROOM}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['onboarding.c_view_all_checklist_instances']}
                        modules={modules.ONBOARDING}
                        to={routes.ONBOARDING}
                        text='On/Off-Boarding'
                        icon={icons.ONBOARDING}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['attendance.c_view_all_records', 'attendance.c_view_user_records']}
                        modules={modules.ATTENDANCE}
                        to={routes.ATTENDANCE}
                        text={t('attendance')}
                        icon={icons.ATTENDANCE}
                        hide={toggled}
                    />
                </List>
            </List.Content>
            <CanView permissions={[
                'projects.c_view_all_projects',
                'projects.c_view_user_projects',
                'teams.c_view_all_teams',
                'teams.c_view_user_teams',
                'timesheets.c_view_all_records',
                'timesheets.c_view_user_records'
            ]}>
                <List.Content>
                    <SidebarCategory text={t('pm_long')} shorttext={t('pm_short')} />
                    <List className="siderbar-list">
                        <SidebarItem
                            permissions={['projects.c_view_all_projects', 'projects.c_view_user_projects']}
                            modules={modules.PROJECTS}
                            to={routes.PROJECT_LIST}
                            text={t('projects')}
                            icon={icons.PROJECTS}
                            hide={toggled}
                        />

                        <SidebarItem
                            permissions={['timesheets.c_view_all_records', 'timesheets.c_view_user_records']}
                            modules={modules.TIMESHEETS}
                            paddingLeft="0.3rem"
                            to={routes.TIMESHEETS}
                            text={t('timesheets')}
                            icon={icons.TIMESHEETS}
                            hide={toggled}
                        />

                        <SidebarItem
                            permissions={['teams.c_view_all_teams', 'teams.c_view_user_teams']}
                            modules={modules.TEAMS}
                            to={routes.TEAMS_LIST}
                            text={t('teams')}
                            icon={icons.TEAMS}
                            hide={toggled}
                        />
                        
                    </List>
                </List.Content>
            </CanView>
            <List.Content>
                <CanView permissions={[
                    'accounts.c_view_all_accounts',
                    'accounts.c_view_user_accounts',
                    'accounts.c_view_assigned_accounts',
                    'orders.c_view_order',
                    'cost_centers.c_view_cost_centers',
                    'contacts.c_view_contacts'
                ]}>
                    <SidebarCategory text={t('sm_long')} shorttext={t('sm_short')} />
                </CanView>
                <List className="siderbar-list">
                    <SidebarItem
                        paddingLeft="0.5rem"
                        to={routes.SUPPLIERS}
                        text={t('suppliers')}
                        modules={modules.ACCOUNTS}
                        icon={icons.ACCOUNTS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={["accounts.c_view_all_accounts", "accounts.c_view_user_accounts", "accounts.c_view_assigned_accounts"]}
                        modules={modules.ACCOUNTS}
                        paddingLeft="0.5rem"
                        to={routes.ACCOUNTS}
                        text={t('accounts')}
                        icon={icons.ACCOUNTS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={["orders.c_view_order"]}
                        modules={modules.ORDERS}
                        paddingLeft="0.7rem" to={routes.ORDERS}
                        text={t('orders')}
                        icon={icons.ORDERS}
                        hide={toggled}
                    />

                    <SidebarItem
                        to={routes.INVOICES}
                        text={t('invoices')}
                        icon={icons.INVOICES}
                        hide={toggled}
                    />

                    <SidebarItem
                        to={routes.PAYMENTS}
                        text={t('payments')}
                        icon={icons.PAYMENTS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['cost_centers.c_view_cost_centers']}
                        modules={modules.COST_CENTERS}
                        paddingLeft="0.7rem"
                        to={routes.COST_CENTERS}
                        text={t('cost_centers')}
                        icon={icons.COST_CENTERS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['contacts.c_view_contacts']}
                        modules={modules.CONTACTS}
                        paddingLeft="0.7rem"
                        to={routes.CONTACTS}
                        text={t('contacts')}
                        icon={icons.CONTACTS}
                        hide={toggled}
                    />
                </List>
            </List.Content>
            <List.Content>
                <CanView permissions={[
                    'approvals.c_view_all_approvals',
                    'approvals.c_view_user_approvals',
                    'cases.c_view_all_cases',
                    'cases.c_view_assigned_user_cases',
                    'assets.c_view_items',
                    'assets.c_view_warehouses',
                    'assets.c_view_item_categories',
                    'company.c_view_active_company',
                    'forecasts.c_view_forecasts'
                ]}>
                    <SidebarCategory text={t('misc_long')} shorttext={t('misc_short')} />
                </CanView>
                <List className="siderbar-list">
                    <SidebarItem
                        permissions={["approvals.c_view_all_approvals", "approvals.c_view_user_approvals"]}
                        modules={modules.APPROVALS}
                        to={routes.APPROVALS}
                        text={t('approvals')}
                        icon={icons.APPROVALS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={["cases.c_view_all_cases", "cases.c_view_assigned_user_cases"]}
                        modules={modules.CASES}
                        to={routes.CASES}
                        text={t('cases')}
                        icon={icons.CASES}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={["forecasts.c_view_forecasts"]}
                        modules={modules.FORECASTS}
                        to={routes.FORECASTS}
                        text={t('forecasts')}
                        icon={icons.FORECASTS}
                        hide={toggled}
                    />

                    <SidebarItem
                        to={routes.REPORTS}
                        text={t('reports')}
                        icon={icons.REPORTS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['assets.c_view_items', 'assets.c_view_warehouses', 'assets.c_view_item_categories']}
                        modules={modules.ASSETS}
                        to={routes.ASSETS}
                        text={t('assets')}
                        icon={icons.ASSETS}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={["company.c_view_active_company"]}
                        modules={modules.COMPANY}
                        to={routes.COMPANY}
                        text={t('company')}
                        icon={icons.COMPANY}
                        hide={toggled}
                    />

                    <SidebarItem
                        permissions={['common.c_view_gdpr_agreements']}
                        modules={modules.GDPR_AGREEMENTS}
                        to={routes.GDPR_AGREEMENTS}
                        text={t('gdpr_agreements')}
                        icon={icons.GDPR_AGREEMENTS}
                        hide={toggled}
                    />

                    <SidebarItem
                        to={routes.SETTINGS}
                        text={t('settings')}
                        icon={icons.SETTINGS}
                        hide={toggled}
                    />
                </List>
            </List.Content>

        </div>
    );
};

export default Sidebar;