import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
// components
import EmptyRow from '@components/tables/EmptyRow';
import { Grid, Header, Segment, Container } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
import SuperDuperModal from '@components/modals/SuperDuperModal'
import IntegrationForm from './IntegrationForm';
import CanView from '@components/perms/CanView';

const IntegrationsList = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const [integrations, setIntegrations] = useState([
        {
            id: "profesia.sk",
            name: "Profesia SK",
            description: "Job portal",
            logo: "/images/integrations/profesia_logo.svg",
            is_active: false, // this should be dynamically changed based on existing configurations
            configuration: null
        },
        {
            id: "profesia.cz",
            name: "Profesia CZ",
            description: "Job portal",
            logo: "/images/integrations/profesia_logo.svg",
            is_active: false,
            configuration: null
        },
        {
            id: "kariera.sk",
            name: "Kariera SK",
            description: "Job portal",
            logo: "/images/integrations/kariera_logo.svg",
            is_active: false,
            configuration: null
        },
        {
            id: "rabota.ua",
            name: "Rabota UA",
            description: "Job portal",
            logo: "/images/integrations/robota_ua_logo.svg",
            is_active: false,
            configuration: null
        }
    ])


    const [result] = useFetchData(API.AUTH_CONFIGS);

    useEffect(() => {
        async function connectConfigurations() {

            if (result.data) {
                for (let x = 0; x < integrations.length; x++) {
                    const foundIntegration = result.data.find(item => item.integration === integrations[x].id);
                    if (foundIntegration) {
                        integrations[x].configuration = foundIntegration
                        integrations[x].is_active = true
                    }
                }

                setLoading(false);
            }
        }

        connectConfigurations();
        // eslint-disable-next-line
    }, [result.data])

    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            <CanView permissions={['integrations.c_view_third_party_auth']} redirect>
                <Segment loading={loading} style={{ padding: 0, margin: 0, minHeight: "2rem", backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
                    <EmptyRow length={integrations.length} />
                    <Grid>
                        {integrations.map((integration, i) => (
                            <Grid.Column computer={4} tablet={8} mobile={16} key={i}>
                                <CanView permissions={['integrations.c_manage_third_party_auth']}>
                                    <SuperDuperModal
                                        trigger={
                                            <Segment className="toggle-button">
                                                <Grid>
                                                    <Grid.Row style={{ padding: 0, textAlign: "left" }} columns={2}>
                                                        <Grid.Column>
                                                            <AvatarIcon
                                                                size={95}
                                                                name={integration.name}
                                                                src={integration.logo}
                                                            />
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            <Header as="h3" content={integration.name} style={{ marginTop: "0.8rem", marginBottom: 0 }} />
                                                            {integration?.description
                                                                ? <div>{integration.description}</div>
                                                                : <div>--</div>
                                                            }
                                                            {integration?.is_active
                                                                ? <strong style={{ color: "var(--success)" }}>{t('active_him')}</strong>
                                                                : <strong style={{ color: "var(--danger)" }}>{t('inactive_him')}</strong>
                                                            }
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>

                                            </Segment>
                                        }
                                        size="small"
                                        header={t('configuration') + ': ' + integration.name}
                                        content={
                                            <IntegrationForm
                                                integration={integration}
                                                result={integrations}
                                                setResult={setIntegrations}
                                            />
                                        }
                                    />
                                </CanView>
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>
            </CanView>
        </Container>
    );
};

export default IntegrationsList;