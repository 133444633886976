import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { requests } from '@helpers/requests';
// components
import { Button, Header, Divider, Grid, Sidebar, Menu, Segment, Container } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import EmptyRow from '@components/tables/EmptyRow';
import Loading from '@components/general/Loading';
import DocumentForm from './DocumentForm';
import DocumentFilterForm from './DocumentFilterForm';
import Action from '@components/general/Action';

const DocumentTable = ({ result, setResult, onDelete, onCreate, onUpdate }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [visible, setVisible] = useState(false);

    const getParams = (urlPath) => {
        if (urlPath === null || urlPath === undefined) return null;

        const url = new URL(urlPath);
        return url.search;
    };

    const previous = getParams(result.previous);
    const next = getParams(result.next);

    const fetchDocuments = async (params) => {
        setResult({
            ...result,
            isLoading: true,
        });
        const request = await requests.get(API.TEMPLATING + '?query={*}' + params);

        if (result.status === 403) {
            history.push(routes.DASHBOARD);
            return;
        }

        if (request.status === 200) {
            setResult({
                ...result,
                data: request.response,
                isLoading: false,
            });
        } else {
            setResult({
                ...result,
                isLoading: false,
            });
        }
    };

    const modelTerms = {
        ContractInstance: t('contract'),
        ContractTermination: t('termination'),
        JobOffer: t('job_offer'),
    };

    const getSource = (fullSource) => {
        let splitSource = fullSource.split('.');

        return modelTerms[splitSource[1]];
    };

    const listDocs = (document) => {
        return (
            <FlexRow key={document.id} fontSize="1rem" borders>
                <FlexItem content={document.title} />
                <FlexItem content={document?.category?.title} />
                <FlexItem content={document.source && getSource(document.source)} />
                <FlexItem basis="15%">
                    <FlexRow background="transparent" padding="0">
                        <FlexItem>
                            <Action
                                as="modal"
                                type="icon"
                                icon="pencil-outline"
                                modalSize="large"
                                tooltip={t('edit')}
                                modal={
                                    <DocumentForm document={document} onConfirm={(new_item) => onUpdate(new_item)} />
                                }
                            />
                        </FlexItem>
                        <FlexItem>
                            <Action
                                as="delete"
                                tooltip={t('delete')}
                                text={t('delete_this')}
                                onClick={() => onDelete(document.id)}
                            />
                        </FlexItem>
                    </FlexRow>
                </FlexItem>
            </FlexRow>
        );
    };

    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            <Sidebar.Pushable
                as={Segment}
                style={{ border: 'none', background: 'transparent', boxShadow: 'none', padding: 0, margin: 0 }}
                className="fixed-sidebar"
            >
                <Sidebar
                    style={{ width: '40rem' }}
                    as={Menu}
                    animation="overlay"
                    direction="right"
                    icon="labeled"
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    width="wide"
                >
                    <DocumentFilterForm setVisible={setVisible} fetchTemplates={fetchDocuments} />
                </Sidebar>
                <Sidebar.Pusher>
                    <Grid columns="2">
                        <Grid.Column style={{ paddingBottom: 0 }}>
                            <Header as="h3">{t('header_templates')}</Header>
                        </Grid.Column>
                        <Grid.Column textAlign="right" style={{ paddingBottom: 0, paddingTop: 0 }}>
                            <Action
                                as="custom"
                                type="icon"
                                icon="funnel-outline"
                                size="small"
                                onClick={() => setVisible(!visible)}
                                tooltip={t('filter')}
                                iconColor="var(--dark)"
                                paddingLeft="1rem"
                                paddingRight="1rem"
                            />
                            <Action
                                as="modal"
                                closeOnDimmerClick={false}
                                type="addButton"
                                modalSize="large"
                                buttonText={t('add_template')}
                                modal={<DocumentForm onConfirm={(new_item) => onCreate(new_item)} />}
                            />
                        </Grid.Column>
                    </Grid>
                    <Divider />
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader content={t('title')} />
                            <FlexHeader content={t('category')} />
                            <FlexHeader content={t('data_source')} />
                            <FlexHeader content={t('actions')} basis="15%" />
                        </FlexRow>
                        <EmptyRow length={result?.data?.length} />
                        {result.isLoading ? <Loading /> : result?.data?.map((document) => listDocs(document))}
                    </FlexTable>
                    <FlexRow>
                        <FlexItem basis="50%">
                            <Button.Group>
                                <Action
                                    as="custom"
                                    type="icon"
                                    icon="chevron-back-outline"
                                    disabled={previous === null}
                                    primary
                                    onClick={previous !== null ? () => fetchDocuments(previous) : null}
                                    iconColor="var(--white)"
                                    paddingLeft="1rem"
                                    paddingRight="1rem"
                                />
                                <Action
                                    as="custom"
                                    type="icon"
                                    icon="chevron-forward-outline"
                                    disabled={previous === null}
                                    primary
                                    onClick={next !== null ? () => fetchDocuments(next) : null}
                                    iconColor="var(--white)"
                                    paddingLeft="1rem"
                                    paddingRight="1rem"
                                />
                            </Button.Group>
                        </FlexItem>
                    </FlexRow>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Container>
    );
};

export default DocumentTable;
