import React from 'react';
// components
import { Menu, Sidebar } from 'semantic-ui-react';
import PositionFiltersForm from '../../job-positions/components/forms/PositionFiltersForm';

const PositionAdvancedFilter = ({ visible, setVisible, filters, setFilters, fetchJobPositions }) => {
    return (
        <Sidebar
            style={{ width: "40rem", }}
            as={Menu}
            animation='overlay'
            direction='right'
            icon='labeled'
            vertical
            visible={visible}
            onHide={() => setVisible(false)}
            width='wide'
        >
            <PositionFiltersForm
                setVisible={setVisible}
                fetchJobPositions={fetchJobPositions}
                filters={filters}
                setFilters={setFilters}
            />
        </Sidebar>
    );
};

export default PositionAdvancedFilter;