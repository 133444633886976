import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
import { patchRequest } from '@services/ServiceCommon';
// components 
import { Form, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const ContractSignForm = ({ contractId, dateOfSignature, onAddSign, onClose }) => {
    const { t } = useTranslation();
    const [date, setDate] = useState(dateOfSignature ? dateOfSignature : "");
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)

        const request = await patchRequest(API.CONTRACTS_INSTANCES + contractId + "/", {
            date_of_signature: date,
            is_draft: false
        })

        if(request.status === 200){
            onAddSign(contractId, request.response)
            onClose()
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <SuperField as="datepicker"
                    closable
                    required
                    width={12}
                    label={t('date_of_signature')}
                    value={ date }
                    onChange={ (e, { value }) => setDate(value) }
                />

                <Form.Field width={4}>
                    <Button 
                        fluid primary 
                        loading={isProcessing} 
                        content={ t('save') }
                        style={{ marginTop: "1.6rem" }} 
                        disabled={isProcessing || date === ""} 
                    />
                </Form.Field>
            </Form.Group>
        </Form>
    );
};

export default ContractSignForm;