import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { addDateFormat } from '@store/actions';
// components
import { Button, Divider, Form, Header, Container } from 'semantic-ui-react';

const Preferences = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const format = useSelector(state => state.date_format)
    const [defaultFormat, setDefaultFormat] = useState(format)
    const [loading, setLoading] = useState(false)

    const formats = ["YYYY-MM-DD", "YYYY/MM/DD", "DD.MM.YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "MM.DD.YYYY", "MM/DD/YYYY"]

    const setPreferences = async() => {
        setLoading(true)
        const request = await requests.patch(API.PREFERENCES + "date_format__global_date_format_preferences/", { value: defaultFormat })
        if (request.status === 200) {
            dispatch(addDateFormat(defaultFormat))
            toast({
                type: 'success',
                icon: 'check circle',
                title: "",
                description: t('format_successfully_changed'),
                animation: 'bounce',
                time: 5000,
            }); 
        }
        setLoading(false)
    }

    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            <Form>
                <Header style={{ paddingBottom: 0 }} as="h3">{t('date_format_preference')}</Header>
                <Divider />
                <p><strong>YYYY</strong> - {t('date_format_year')}</p>
                <p><strong>MM</strong> - {t('date_format_month')}</p>
                <p><strong>DD</strong> - {t('date_format_day')}</p>
                <Header style={{ padding: '1rem', paddingLeft: 0, paddingBottom: 0 }} as="h5">{t('choose_preset')}</Header>
                <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                    { formats.map(format => (
                        <Button
                            active={defaultFormat === format}
                            onClick={() => setDefaultFormat(format)}
                            content={format}
                        />
                    )) }
                </Button.Group>
                <p>
                    {t('selected_format')}: <strong>{defaultFormat}</strong>, {" "}
                    {t('for_example')} - <strong>{moment().format(defaultFormat)}</strong>
                </p>
                <Button
                    primary
                    disabled={loading}
                    loading={loading}
                    onClick={() => setPreferences()}
                    content={t('confirm')}
                />
            </Form>
        </Container>
    );
};

export default Preferences;