
import React, { useState, useEffect } from 'react';
import FilterCard from '@components/cards/FilterCard';
import { useTranslation } from "react-i18next"
import { Segment, Grid, Divider } from 'semantic-ui-react';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';


const ProjectDetailStatHeader = ({project_params}) => {
    const [isLoading, setIsLoading] = useState(true)
    const { t } = useTranslation()
    const [stats, setStats] = useState([])

    useEffect(() => {
        async function fetchStats() {
            setIsLoading(true)
                const request = await requests.get(API.REPORTS + `projects/issues/stats/?project=${project_params.id}`)
                if (request.status === 200) {
                    setStats(request.response)
                }
            setIsLoading(false)

        }
        fetchStats()
        // eslint-disable-next-line
    }, [])


    return (
    <Segment loading={isLoading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none" }}>
            <Grid centered>
                <Grid.Row>
                    <FilterCard
                        color='violet'
                        header={t("total_issues")}
                        // stat_number={stats?.all_issues}
                        icon="list-outline"
                        description={stats?.all_issues || "0"}
                    />
                    <FilterCard
                        color='orange'
                        header={t("opened_issues")}
                        // stat_number={stats?.opened_issues}
                        icon="folder-open-outline"
                        description={stats?.opened_issues || "0"}
                    />
                    <FilterCard
                        color='purple'
                        header={t("closed_issues")}
                        // stat_number={stats?.closed_issues}
                        icon="folder-outline"
                        description={stats?.closed_issues || "0"}
                    />
                    <FilterCard
                        color='orange'
                        header={t("total_estimated_time")}
                        icon="timer-outline"
                        description={(stats?.total_estimated_time || "0") + " " + t("_hours")}
                    />
                    <FilterCard
                        color='pink'
                        header={t("total_spent_time")}
                        icon="time-outline"
                        description={(stats?.total_spent_time || "0")  + " " + t("_hours")}
                    />
                </Grid.Row>
            </Grid>
        <Divider />
    </Segment>

    );
};

export default ProjectDetailStatHeader;