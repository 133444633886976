import React from "react";
// Components
import Icon from '@components/Icon';
import { Accordion, Form } from "semantic-ui-react";
import CourseForm from "./CourseForm";
import { FlexItem, FlexRow } from "@components/tables/FlexTable";
import { useTranslation } from "react-i18next";

const CourseTitle = ({ onDelete, name, isMandatory }) => {
    const { t } = useTranslation();

    return (
        <>
            <FlexItem basis="200%" bold>
                {" "}
                {name}
                {isMandatory && ` - ${t("mandatory")}`}
            </FlexItem>
            <FlexItem textAlign="right" position={"absolute"}>
                <Icon
                    name="close-outline"
                    style={{ cursor: "pointer", color: "var(--danger)", marginLeft: "0.5rem" }}
                    onClick={() => onDelete()}
                />
            </FlexItem>
        </>
    );
};

const CourseElement = ({
    name,
    category,
    course,
    setCourses,
    onDelete,
    isMandatory,
}) => {
    const panels = [
        {
            key: "details",
            title: {
                content: (
                    <CourseTitle
                        onDelete={onDelete}
                        name={name}
                        isMandatory={isMandatory}
                    />
                ),
            },
            content: {
                content: (
                    <CourseForm
                        course={course}
                        isMandatory={isMandatory}
                        setCourses={setCourses}
                        onClose={() => {}}
                        cancelHide={true}
                    />
                ),
            },
        },
    ];
    return (
        <FlexRow padding="0" borders>
            <Accordion as={Form.Field} panels={panels} fluid exclusive />
        </FlexRow>
    );
};

export default CourseElement;
