import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form } from 'semantic-ui-react';
import LabelInput from './input/LabelInput'

import { API } from '@store/config';
import { createRequest, getRequest } from '@services/ServiceCommon';


const AddCaseContent = ({ priorities, data, handleChange, handleRequest, onClose }) => {
    const { t } = useTranslation();
    const [employees, setEmployees] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState({
        open: false,
        message: t("error_message_cases")
    })
    const [form, setForm] = useState({
        title: data ? data.title : '',
        description: data ? data.description : '',
        priority: data ? data.priority : '',
        type: data ? data.type : '',
        label: data ? data.label : [],
        assigned_users: data ? data.assigned_users : [],
        assigned_teams: data ? data.assigned_teams : [],
        end_date: data ? data.end_date : '',

    })
    useEffect(() => {
        handleChange(form)
        // eslint-disable-next-line
    }, [form])

    useEffect(() => {
        async function fetchData() {
            let result = await getRequest(API.EMPLOYEES + '?query={fullname,user}')
            if (result.status === 200) {
                let output = []
                for (let item of result.response) {
                    if (item.user) {
                        output.push({ key: item.user.id, value: item.user.id, text: item.fullname })
                    }
                }
                setEmployees(output)
            }
        }
        fetchData()
    }, [])

    async function createCase() {
        setLoading(true)
        let new_data = {
            title: form.title,
            type: form.type,
        }
        if (form.assigned_users.length > 0) new_data = { ...new_data, assigned_users: { add: form.assigned_users } }
        if (form.assigned_teams.length > 0) new_data = { ...new_data, assigned_teams: { add: form.assigned_teams } }
        if (form.label.length > 0) new_data = { ...new_data, label: { add: form.label } }
        if (form.priority !== '') new_data = { ...new_data, priority: form.priority }
        if (form.description !== '') new_data = { ...new_data, description: form.description }

        let request = await createRequest(API.CASES, new_data)
        if (request.status === 201) {
            handleRequest(request.response)
            setForm({
                title: '',
                description: '',
                priority: '',
                type: '',
                label: [],
                assigned_users: [],
                assigned_teams: [],
                end_date: '',
            })
            onClose()
        } else {
            if (request.response.assigned_users) setError({ open: true, message: request.response.assigned_users.add[0] })
            if (request.response.assigned_teams) setError({ open: true, message: request.response.assigned_teams.add[0] })
        }
        setLoading(false)
    }
    return (
        <Form>
            <SuperField
                label={t("title")}
                required
                as='input'
                value={form.title}
                onChange={(e, { value }) => setForm({ ...form, title: value })}
            />
            <SuperField
                label={t("description")}
                as='textarea'
                value={form.description}
                onChange={(e, { value }) => setForm({ ...form, description: value })}
            />
            <SuperField
                label={t("priority")}
                as='choice'
                customOptions={priorities}
                value={form.priority}
                onChange={(e, { value }) => setForm({ ...form, priority: value })}
            />
            <SuperField
                label={t("type")}
                required
                as='choice'
                text="title"
                endpoint={API.CASES_TYPES}
                value={form.type}
                onChange={(e, { value }) => setForm({ ...form, type: value })}
            />
            <LabelInput
                value={form.label}
                onChange={(e) => setForm({ ...form, label: e })}
            />
            <SuperField
                multiple
                label={t("assignee_users")}
                as='choice'
                customOptions={employees}
                value={form.assigned_users}
                onChange={(e, { value }) => setForm({ ...form, assigned_users: value })}
            />
            <SuperField
                multiple
                label={t("assignee_teams")}
                as='choice'
                text="name"
                endpoint={API.TEAMS + '?query={id,name}'}
                value={form.assigned_teams}
                onChange={(e, { value }) => setForm({ ...form, assigned_teams: value })}
            />
            <SuperField
                multiple
                label={t("end_date")}
                as='datepicker'
                value={form.end_date}
                onChange={(e, { value }) => setForm({ ...form, end_date: value })}
            />
            {error.open &&
                <div>
                    {error.message}
                </div>
            }
            <ModalSubmit
                text={t("create_new_case")}
                onClick={() => createCase()} disabled={
                    form.title === "" || form.type === ""
                }
                loading={loading} />
        </Form>
    );
};

export default AddCaseContent;