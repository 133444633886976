import React from 'react';
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom';
//store
import { routes } from '@routes';
import { useFetchData } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Grid, Header, Divider } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import EmptyRow from '@components/tables/EmptyRow';
import Loading from "@components/general/Loading";
import CanView from '@components/perms/CanView';
import SuperDuperModal from '@components/modals/SuperDuperModal'
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import ConfirmModal from '@components/modals/ConfrimModal';
import TypeForm from './TypeForm';

const TypesManagement = ({ title, endpoint, permissions, definitions }) => {
    const { t } = useTranslation();
    const [result, setResult] = useFetchData(endpoint)

    const onDelete = async (id) => {
        const request = await requests.del(endpoint + id + "/",);

        if (request.status === 204) {
            const newData = result.data.filter(item => item.id !== id)

            setResult({
                ...result,
                data: newData
            })
        }
    }

    const onUpdate = (response) => {
        setResult(prevState => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.id === response.id) return response

                return item;
            })
        }))
    };

    const onCreate = async (item) => {
        setResult(prevState => ({
            ...prevState,
            data: [item, ...prevState.data]
        }))
    }

    return (
        <>
            {/* <CanView permissions={permissions.view}> */}
            {result.isLoading ? <Loading /> :
                <>
                    <Grid>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column computer={5}>
                                <Link to={routes.SETTINGS_TYPES}>
                                    <Icon name='arrow-back-outline' style={{ maxHeight: "43px", height: "100%" }} /> {t('back_to_the_list')}
                                </Link>
                            </Grid.Column>
                            <Grid.Column computer={6} textAlign='center'>
                                <Header>{ title || "undefined" }</Header>
                            </Grid.Column>
                            <Grid.Column computer={5} textAlign="right">
                                <CanView permissions={permissions.manage}>
                                    <SuperDuperModal
                                        header={t('add')}
                                        trigger={
                                            <OutlineAddButton
                                                size="small"
                                                color="violet"
                                                text={t('add')}
                                            />
                                        }
                                        content={
                                            <TypeForm
                                                endpoint={endpoint}
                                                definitions={definitions}
                                                action='create'
                                                onConfirm={(item) => onCreate(item)}
                                            />
                                        }
                                    />
                                </CanView>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />


                    <FlexTable padding='1rem'>
                        <FlexRow>
                            {definitions.map((header, index) => (
                                <FlexHeader key={index} content={header.name} />
                            ))}
                            <CanView permissions={permissions.manage}>
                                <FlexHeader basis="10%" content={t('actions')} />
                            </CanView>
                        </FlexRow>
                        <EmptyRow length={result?.data?.length} />
                        {result?.data?.map((item, index) => (
                            <FlexRow key={index}>
                                {definitions.map((cell, index) => (
                                    <FlexItem key={index}>
                                        {cell.as === "choice" ? cell.icon ? <Icon name={item?.[cell.key]} /> : item?.[cell.key + "_display"] : item?.[cell.key]}
                                    </FlexItem>
                                ))}
                                <CanView permissions={permissions.manage}>
                                    <FlexItem basis="10%">
                                        <FlexRow background="transparent" padding="0">
                                            <FlexItem>
                                                <SuperDuperModal
                                                    size="small"
                                                    trigger={
                                                        <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }} />
                                                    }
                                                    content={
                                                        <TypeForm
                                                            endpoint={endpoint}
                                                            definitions={definitions}
                                                            action='update'
                                                            result={item}
                                                            onConfirm={(updated_item) => onUpdate(updated_item)}
                                                        />
                                                    }
                                                />
                                            </FlexItem>
                                            <CanView permissions={permissions.delete}>
                                                <FlexItem>
                                                    <ConfirmModal
                                                        description={t('delete_this')}
                                                        onConfirm={() => onDelete(item.id)}
                                                        button={
                                                            <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                        }
                                                    />
                                                </FlexItem>
                                            </CanView>
                                        </FlexRow>
                                    </FlexItem>
                                </CanView>
                            </FlexRow>
                        ))}
                    </FlexTable>
                </>
            }
            {/* </CanView> */}
        </>
    );
};

export default TypesManagement;