import React, { useEffect, useState,  } from 'react';
// store
import { commonService } from '../../../store/services/ServiceCommon';
// components
import { Form } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";


const AccountTypeField = ({ disabled, placeholder, value, setValue, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const {t}=useTranslation()

    useEffect( () => {
        async function getChoices(){
            setLoading(true);

            const result = await commonService.getChoices();

            if(result.status === 200){
                const optionsArray = Object.entries(result.response.account_type).map(
                    (choice, index) => (
                        { key: index + 1, text: choice[1], value: parseInt(choice[0]) }
                    )
                );
                setOptions(optionsArray);
            }
            setLoading(false);
        }
        getChoices();
    }, [])


    return (
        <Form.Field>
            <Form.Select
                {...rest}
                fluid
                loading={loading}
                disabled={disabled || loading}
                label={t('account_type')}
                value={value}
                options={options}
                placeholder={placeholder}
                onChange={setValue}
            />
        </Form.Field>
    );
};

export default AccountTypeField;