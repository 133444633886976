import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField';

const MaritalStatus = ({ setData }) => {
    const { t } = useTranslation();
    const [maritalStatus, setMaritalStatus] = useState("")

    useEffect( () => {
        setData(prevState => ({
            ...prevState,
            marital_status: maritalStatus !== "" ? parseInt(maritalStatus) : null
        }))

    }, [maritalStatus, setData])

    return (
        <SuperField as="choice"
            required
            label={ t('marital_status') }
            type="marital_statuses"
            width="8"
            value={ maritalStatus }
            onChange={ (e, { value }) => setMaritalStatus(value) }
        />
    );
};

export default MaritalStatus;