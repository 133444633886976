import React, { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import ConfrimModal from '@components/modals/ConfrimModal';
import EducationForm from '@components/forms/EducationForm';
import {FlexItem, FlexRow} from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Header, Divider, Grid, Button } from 'semantic-ui-react';


const EducationSection = ({ isManageable, result, setResult, patchEndpoint }) => {
    const { t } = useTranslation();

    const [educations, setEducations] = useState(result?.educations || []);

    const onConfirm = async (newEducation) => {
        const data = {
            educations: {
                add: [newEducation.id]
            }
        }

        const request = await requests.patch(patchEndpoint + result?.id + "/", data)

        if( request.status === 200 ){
            setEducations([...educations, newEducation])
            setResult({
                ...result,
                data: {
                    ...result.data,
                    educations: [...educations, newEducation]
                }
            })
        }
    }

    const onDelete = async (id) => {
        const result = await requests.del(API.EDUCATIONS + id + "/");
        if (result.status === 204) {
            const newEducationList = educations.filter(item => item.id !== id)
            setEducations(newEducationList);
            setResult({
                ...result,
                data: {
                    ...result.data,
                    educations: newEducationList
                }
            })
        }
    }

    const onUpdate = (updateItem) => {
        const newEducationList = educations.filter((item) => {
            if (item.id === updateItem.id) {
                item.name = updateItem.name;
                item.education_type = updateItem.education_type;
                item.education_type_display = updateItem.education_type_display;
                item.department = updateItem.department;
                item.field = updateItem.field;
                item.date_from = updateItem.date_from;
                item.date_to = updateItem.date_to;
            }

            return item;
        })

        setEducations(newEducationList)
        setResult({
            ...result,
            data: {
                ...result.data,
                educations: newEducationList
            }
        })
    }

    return (
        <Fragment>
            <Grid stackable>
            <Grid.Row>
                <Grid.Column only="computer" computer="3" stretched style={{ borderRadius: "4px", paddingTop: "1rem", backgroundColor: "var(--light)"}}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem textAlign="center">
                            <Header icon >
                                <Icon name='library-outline' style={{ fontSize: "5rem" }} />
                                <h3>{t('education')}</h3>
                            </Header>
                        </FlexItem>
                    </FlexRow>
                </Grid.Column>
                <Grid.Column computer="12" tablet="16" mobile="16">

                    <Grid columns='2' style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <Grid.Column width='10' style={{ paddingTop: "0.8rem", paddingBottom: 0 }}>
                            <Header floated="left" as="h3">
                                <Icon name="library-outline"  style={{ marginRight: "0.5rem" }}/>
                                <span style={{ position: "relative", top: "-0.2rem" }}> {t('education')} </span> 
                            </Header>
                        </Grid.Column>
                        <Grid.Column width='6' textAlign="right" style={{ padding: 0 }}>
                            <CanView render={isManageable}>
                                <SuperDuperModal
                                    header={t('add_education')}
                                    trigger={
                                        <Header style={{ padding: 0, cursor: "pointer", paddingTop: "1rem" }} floated="right" as="h4">
                                            <Icon name="add-outline" style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('add_education')}</span>
                                        </Header>
                                    }
                                    content={
                                        <EducationForm
                                            education={{}}
                                            onConfirm={(newEducation) => onConfirm(newEducation)}
                                        />
                                    }
                                />
                            </CanView>
                        </Grid.Column>
                    </Grid>

                    <Divider />
                    { educations.length > 0 ?
                    <Grid stretched style={{ margin: "1rem" }}>
                        {educations.map((item, index) => (
                            <Grid.Column key={index} width={16} style={{ paddingTop: 0, paddingBottom: 0 }} >
                                <Fragment>
                                    <Grid style={{ backgroundColor: "var(--default)", marginTop: ".5rem", borderRadius: 0 }}>
                                        <Grid.Row>
                                            <Grid.Column width='14' style={{ paddingLeft: 0 }}>
                                                <h4>{item.name}</h4>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <SuperDuperModal
                                                    trigger={
                                                        <Button
                                                            icon={<Icon name="pencil-outline"/>}
                                                            style={{ backgroundColor: 'var(--default)', color: 'var(--primary)', padding: 0 }}
                                                        />
                                                    }
                                                    header={t('update_education')}
                                                    content={
                                                        <EducationForm
                                                            education={item}
                                                            onConfirm={(updatedEducation) => onUpdate(updatedEducation)}
                                                        />
                                                    }
                                                />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <ConfrimModal
                                                    onConfirm={() => onDelete(item.id)}
                                                    button={
                                                        <Button
                                                            icon={<Icon name="close-outline"/>}
                                                            style={{ backgroundColor: "var(--default)", color: 'var(--danger)', padding: 0 }}
                                                        />
                                                    }
                                                    description={t('delete_education')}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <p style={{ color: 'var(--variant2)', marginBottom: 5 }}>{item.education_type_display}</p>
                                    <p style={{ color: 'var(--variant2)', marginBottom: 5 }}>{item.department}, {item.field}</p>
                                    <p style={{ color: 'var(--variant2)', marginBottom: 5 }}>
                                        {item.date_from} - {item.date_to}
                                    </p>
                                </Fragment>
                                <Divider />
                            </Grid.Column>
                        ))

                    }
                    </Grid>
                    :
                    <Grid centered>
                        <Header as="h4">{t('nothing')}</Header>
                    </Grid> }
                </Grid.Column>
            </Grid.Row>
            </Grid>

        </Fragment>
    );
};

export default EducationSection;
