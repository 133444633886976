import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { patchRequest, deleteRequest, getRequest } from '@services/ServiceCommon';
import { routes } from '@routes';
import { icons } from '@icons';
import { useQueryPage } from '@helpers/hooks';
import { useLocation } from 'react-router-dom';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
import Loading from '@components/general/Loading';
import AvatarIcon from '@components/AvatarIcon';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Action from '@components/general/Action';
import { Button, Icon as SemanticIcon, Label, Divider, Sidebar, Segment, Menu, Grid, Container } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific components
import ContactCell from '../components/ContactCell';
import LabelsForm from '../components/forms/LabelsForm';
import SupplierForm from '../components/forms/SupplierForm';
import SuppliersFilterForm from '../components/forms/SuppliersFilterForm';
import BreadcrumbNav from '@components/BreadcrumbNav';

const SupplierList = () => {
    const { t } = useTranslation()
    let page = useQueryPage()
    const [suppliers, setSuppliers] = useFetchData(API.SUPPLIERS + "?paginate=true&page=" + page)
    const [visible, setVisible] = useState(false)

    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [reFetched, setReFetched] = useState(false)

    const initialFilters = {
        search: "",
        identification_number: "",
        dit_number: "",
        vat_number: "",
        is_active: true,
        is_freelancer: null,
        approve_automatically: null,
        reinvoice: null,
        unit: "",
        labels: []
    }

    const [filters, setFilters] = useState(initialFilters)
    const location = useLocation()

    const onFilter = () => {
        setVisible(false)
        let filterBaseParams = ""

        if( filters.identification_number !== "" ) filterBaseParams += "&identification_number=" + filters.identification_number
        if( filters.dit_number !== "" ) filterBaseParams += "&dit_number=" + filters.dit_number
        if( filters.vat_number !== "" ) filterBaseParams += "&vat_number=" + filters.vat_number
        if( filters.unit !== "" ) filterBaseParams += "&unit=" + filters.unit
        if( filters.search !== "" ) filterBaseParams += "&search=" + filters.search
        if( filters.approve_automatically !== "" ) filterBaseParams += "&approve_automatically=" + filters.approve_automatically
        if( filters.reinvoice !== "" ) filterBaseParams += "&reinvoice=" + filters.reinvoice

        if( filters.labels.length > 0 ){
            let mergedLabels = ""
            for (let i = 0; i < filters.labels.length; i++) {
                if( i === 0 ){
                    mergedLabels += filters.labels[i]
                } else {
                    mergedLabels += "--" + filters.labels[i]
                }
            }

            filterBaseParams += "&account_and_contact_labels=" + mergedLabels
        }

        return filterBaseParams
    }

    const onLabelsAdd = (id, labels, type) => {
        if (type === 'profile') {
            setSuppliers(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: suppliers.data.results.filter(emp => {
                        if (emp?.profile?.id === id) {
                            emp.profile.contact_labels = labels
                        }
                        return emp;
                    })
                }
            }))
        }
        else {
            setSuppliers(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: suppliers.data.results.filter(emp => {
                        if (emp?.account?.id === id) {
                            emp.account.tags = labels
                        }
                        return emp;
                    })
                }
            }))
        }
    }

    const onLabelRemove = async (id, labelID, type) => {
        if (type === 'profile') {
            const request = await patchRequest(API.EMPLOYEES + id + '/?query={id, contact_labels}', {
                contact_labels: {
                    remove: [labelID]
                }
            });

            if (request.status === 200) {
                setSuppliers(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: suppliers.data.results.filter(emp => {
                            if (emp?.profile?.id === id) {
                                emp.profile.contact_labels = request.response.contact_labels
                            }
                            return emp;
                        })
                    }
                }))
            }
        }
        else {
            const request = await patchRequest(API.ACCOUNTS + id + '/', {
                tags: {
                    remove: [labelID]
                }
            });

            if (request.status === 200) {
                setSuppliers(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: suppliers.data.results.filter(emp => {
                            if (emp?.account?.id === id) {
                                emp.account.tags = request.response.tags
                            }
                            return emp;
                        })
                    }
                }))
            }
        }

    }

    const ShowLabels = ({ row, type }) => {
        return (
            <>
                {type === 'profile' ?
                    row?.contact_labels?.map(label => (
                        <Label basic size="tiny" key={label.id}>
                            <Icon name="pricetag-outline" style={{ color: label.color === "" ? "" : label.color, marginRight: "0.5rem" }}/> {label.name}
                            <Icon name="close-outline" onClick={() => onLabelRemove(row.id, label.id, type)} style={{ marginLeft: "0.5rem" }}/>
                        </Label>
                    ))
                    :
                    row?.tags?.map(label => (
                        <Label basic size="tiny" key={label.id}>
                            <Icon name="pricetag-outline" style={{ color: label.color === "" ? "" : label.color, marginRight: "0.5rem" }}/> {label.name}
                            <Icon name="close-outline" onClick={() => onLabelRemove(row.id, label.id, type)} style={{ marginLeft: "0.5rem" }}/>
                        </Label>
                    ))}
                <SuperDuperModal
                    trigger={
                        <Label basic size="tiny" style={{ cursor: "pointer" }}>
                            <Icon name="add-outline"/>
                        </Label>
                    }
                    content={
                        <LabelsForm
                            id={row.id}
                            contactLabels={type === 'profile' ? row.contact_labels : row.tags}
                            type={type}
                            onConfirm={onLabelsAdd}
                        />
                    }
                />
            </>
        );
    }

    const onDelete = async (id, type) => {

        if (type === 'profile') {
            const request = await deleteRequest(API.EMPLOYEES + id + '/');
            if (request.status === 204) {
                setSuppliers(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: suppliers.data.results.filter(item => item.profile.id !== id)
                    }
                }))
            }
        }
        else {
            const request = await deleteRequest(API.ACCOUNTS + id + '/');
            if (request.status === 204) {
                setSuppliers(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: suppliers.data.results.filter(item => item.account.id !== id)
                    }
                }))
            }
        }

    }

    const onAdd = (response, type) => {
        if (type === 'profile') {
            setSuppliers(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: [{ profile: response, account: null }, ...prevState.data.results]
                }
            }))
        } else {
            setSuppliers(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: [{ profile: null, account: response }, ...prevState.data.results]
                }
            }))
        }
    }

    const onUpdate = (response, type) => {

        if (type === 'profile') {
            setSuppliers(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter(sup => {
                        if (sup.profile !== null) {
                            if (sup.profile.id === response.id) {
                                sup.profile.fullname = response.fullname
                                sup.profile.business_detail = response.business_detail
                                sup.profile.personal_information = response.personal_information
                                sup.profile.approve_automatically = response.approve_automatically
                            }
                        }
                        return sup;
                    })
                }
            }))
        }
        else {
            setSuppliers(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter(sup => {
                        if (sup.account !== null) {
                            if (sup.account.id === response.id) {
                                sup.account.name = response.name
                                sup.account.business_detail = response.business_detail
                                sup.account.contact_info = response.contact_info
                                sup.account.approve_automatically = response.approve_automatically
                            }
                        }
                        return sup;
                    })
                }
            }))
        }

    }

    const fetchSuppliers = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if (!loading){
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if ( !params.includes('page') ) {
                params += "&page=" + page
            }

            if ( !params.includes('limit')){
                params += "&limit=" + limit
            } 

            setSuppliers(prevState => ({
                ...prevState,
                isLoading: true
            }))
            const request = await getRequest(API.SUPPLIERS + "?paginate=true" + params)

            if (request.status === 200) {
                setSuppliers(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }
            setReFetched(false)
            setLoading(false)
        }
    }

    return (
        <>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("suppliers"), 'icon': icons.ACCOUNTS, 'href': '' }
                ]}
            />
            <Container fluid>
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                    <Sidebar
                        style={{ width: "40rem", }}
                        as={Menu}
                        animation='overlay'
                        direction='right'
                        icon='labeled'
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        width='wide'
                    >
                        <SuppliersFilterForm
                            setVisible={setVisible}
                            fetchSuppliers={fetchSuppliers}
                            onFilter={onFilter} 
                            filters={filters} 
                            setFilters={setFilters}
                        />
                    </Sidebar>
                    <Sidebar.Pusher>
                        <Segment loading={reFetched || loading} basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }}>
                            <Grid>
                                <Grid.Row style={{ padding: 0 }} verticalAlign="middle">
                                    <Grid.Column computer={8} mobile={16} tablet={16} />
                                    <Grid.Column computer={8} mobile={16} tablet={16} style={{ textAlign: "right" }}>
                                        <Button
                                            size="small"
                                            style={{
                                                background: "transparent",
                                                color: "var(--black)",
                                                paddingLeft: "1rem",
                                                paddingRight: "1rem"
                                            }}
                                            onClick={() => setVisible(!visible)}
                                        >
                                            <Icon name='funnel-outline'/>
                                        </Button>
                                        <SuperDuperModal
                                            header={t('add_supplier')}
                                            size="small"
                                            trigger={
                                                <Button size="small" primary>{t('add_supplier')}</Button>
                                            }
                                            content={
                                                <SupplierForm isSupplier={true} type="account" onConfirm={(response, type) => onAdd(response, type)} />
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />

                            {suppliers.isLoading ? <Loading /> :
                                <FlexTable>
                                    <FlexRow>
                                        <FlexHeader basis="50%" content={t('name')} />
                                        <FlexHeader basis="50%" content={t('id_number')} />
                                        <FlexHeader basis="60%" content={t('contact')} />
                                        <FlexHeader basis="125%" content={t('labels')} />
                                        <FlexHeader basis="50%" content={t('reinvoice')} />
                                        <FlexHeader basis="50%" content={t('invoice_approval')} />
                                        <FlexHeader basis="30%" content={t('actions')} />
                                    </FlexRow>
                                    <EmptyRow length={suppliers.data.results?.length} />
                                    {suppliers.data?.results?.map((supplier, index) => (
                                        <FlexRow key={index} fontSize="0.9rem" borders>
                                            <FlexItem basis="50%">
                                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                                    <FlexItem basis="10%">
                                                        <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                                            <AvatarIcon
                                                                size="30"
                                                                name={supplier?.profile?.fullname ? supplier.profile.fullname : supplier?.account?.name ? supplier.account.name : 'undefined'}
                                                                src={supplier?.profile?.profile_picture ? supplier?.profile?.profile_picture : supplier?.account?.logo ? supplier.account.logo : ''}
                                                            />
                                                        </SemanticIcon.Group>
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <strong> {supplier?.profile?.fullname ? supplier.profile.fullname : supplier?.account?.name ? supplier.account.name : '--'} </strong><br />
                                                    </FlexItem>
                                                </FlexRow>
                                            </FlexItem>
                                            <FlexItem basis="50%" content={supplier?.account?.business_detail?.identification_number || supplier?.profile?.business_detail?.identification_number} />
                                            <FlexItem basis="60%" content={<ContactCell row={supplier?.account?.contact_info ? supplier.account.contact_info : supplier?.profile?.personal_information?.contact_info ? supplier.profile.personal_information.contact_info : ''} />} />
                                            <FlexItem basis="125%" content={
                                                <ShowLabels
                                                    row={supplier?.account ? supplier.account : supplier?.profile ? supplier.profile : ''}
                                                    type={supplier?.profile ? 'profile' : 'account'}
                                                />
                                            } />
                                            <FlexItem basis="50%" bold content={supplier?.account?.business_detail?.reinvoice || supplier?.profile?.business_detail?.reinvoice} >
                                                {supplier?.account?.business_detail?.reinvoice !== null && <> {supplier?.profile?.business_detail?.reinvoice || supplier?.account?.business_detail?.reinvoice ? t('yes') : t('no')} </>}
                                            </FlexItem>
                                            <FlexItem basis="50%" bold >
                                                {supplier.profile !== null && <> {supplier.profile.approve_automatically ? t('automatic') : t('manual')} </>}
                                                {supplier.account !== null && <> {supplier.account.approve_automatically ? t('automatic') : t('manual')} </>}
                                            </FlexItem>
                                            <FlexItem basis="30%">
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem textAlign="center">
                                                        <Action type='icon' as="link" to={routes.SUPPLIERS + (supplier?.account?.business_detail?.identification_number || supplier?.profile?.business_detail?.identification_number)} tooltip={t('view_detail')} />
                                                    </FlexItem>
                                                    <FlexItem textAlign="center">
                                                        <Action
                                                            as="modal"
                                                            type="icon"
                                                            icon="pencil-outline"
                                                            size="small"
                                                            popupText={t('edit')}
                                                            modal={
                                                                <SupplierForm
                                                                    isSupplier={true}
                                                                    data={supplier?.account ? supplier.account : supplier.profile}
                                                                    type={supplier?.account ? 'account' : 'profile'}
                                                                    onConfirm={(response, type) => onUpdate(response, type)}
                                                                />
                                                            }
                                                        />
                                                    </FlexItem>
                                                    <FlexItem textAlign="center">
                                                        <Action
                                                            as='delete'
                                                            text={t('delete_supplier')}
                                                            onClick={() => onDelete(supplier?.account ? supplier.account.id : supplier?.profile ? supplier.profile.id : '', supplier?.account ? 'account' : supplier?.profile ? 'profile' : '')}
                                                        />
                                                    </FlexItem>
                                                </FlexRow>
                                            </FlexItem>
                                        </FlexRow>
                                    ))}
                                </FlexTable>
                            }
                        </Segment>
                        <Paginator
                            forcePage={page || 1}
                            limit={limit}
                            setLimit={setLimit}
                            length={suppliers?.data?.count || 0}
                            onChange={(params) => fetchSuppliers(params)}
                        />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Container>
        </>
    );
};

export default SupplierList;