import { APIRequest } from './helpers/requests';
import { API } from "../config";

export const unitService = {
    getUnitById,
    getUnitInTree,
    updateUnitHeader,
    addBusinessDetail
};

async function getUnitById(id) {
    return await APIRequest({
        url: API.COMPANIES + API.UNIT + id + '/',
        method: 'GET',
        data: {},
        private: true
    });
}

async function getUnitInTree(id) {
    return await APIRequest({
        url: API.COMPANIES + API.UNIT + id + '/tree/',
        method: 'GET',
        data: {},
        private: true
    });
}
async function updateUnitHeader(id, data) {
    return await APIRequest({
        url: API.COMPANIES + API.UNIT + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}
async function addBusinessDetail(id, data, object) {
    return await APIRequest({
        url: object + id + '/',
        method: "PATCH",
        data: data,
        private: true
    })
}
