export const languageOptions = [
    { key: 1, text: "English", value: "en", flag: "uk" },
    { key: 2, text: "Slovak", value: "sk", flag: "sk" },
    //{ key: 3, text: "Czech", value: "cz", flag: "cz" },
];


// Socialne siete
export const socialNetworkOptions = [
    {
        key: "globe",
        text: "Website",
        value: "globe",
        icon: "globe",
    },
    {
        key: "facebook",
        text: "Facebook",
        value: "facebook",
        icon: "facebook",
    },
    {
        key: "facebook messenger",
        text: "Facebook Messenger",
        value: "facebook messenger",
        icon: "facebook messenger",
    },
    {
        key: "google",
        text: "Google",
        value: "google",
        icon: "google",
    },
    {
        key: "instagram",
        text: "Instagram",
        value: "instagram",
        icon: "instagram",
    },
    {
        key: "linkedin",
        text: "LinkedIn",
        value: "linkedin",
        icon: "linkedin",
    },
    {
        key: "skype",
        text: "Skype",
        value: "skype",
        icon: "skype",
    },
    {
        key: "slack",
        text: "Slack",
        value: "slack",
        icon: "slack",
    },
    {
        key: "snapchat",
        text: "Snapchat",
        value: "snapchat",
        icon: "snapchat",
    },
    {
        key: "twitter",
        text: "Twitter",
        value: "twitter",
        icon: "twitter",
    },
    {
        key: "vimeo",
        text: "Vimeo",
        value: "vimeo",
        icon: "vimeo",
    },
    {
        key: "youtube",
        text: "YouTube",
        value: "youtube",
        icon: "youtube",
    },
];
