import userReducer from "./user";
import logoReducer from "./logo";
import journalReducer from "./journal";
import companyReducer from "./company";
import invoiceCurrencyReducer from "./invoice_currency";
import invoicePairingStatusReducer from "./invoice_pairing_status";
import currentBankAccountReducer from "./current_bank_account";
import themeReducer from "./theme";
import choicesReducer from "./choices";
import languageReducer from "./language";
import permissionReducer from "./permissions";
import urlReducer from "./settings_return_url";
import allowedModulesReducer from "./allowed_modules";
import globalPrefReducer from "./global_preferences";
import { combineReducers } from "redux";
import dateFormatReducer from "./date";


const allReducers = combineReducers({
    user: userReducer,
    choices: choicesReducer,
    global_pref: globalPrefReducer,
    logo: logoReducer,
    language: languageReducer,
    allowed_modules: allowedModulesReducer,
    permissions: permissionReducer,
    settings_return_url: urlReducer,
    company: companyReducer,
    journal: journalReducer,
    invoice_currency: invoiceCurrencyReducer,
    pairing_status: invoicePairingStatusReducer,
    date_format: dateFormatReducer,
    theme: themeReducer,
    bank_account: currentBankAccountReducer
});

export default allReducers;
