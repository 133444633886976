import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import { Form, Button, Grid, Divider, Header } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const CostCentresFilterSection = ({ filters, setFilters, setVisible, fetchCostCentres }) => {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchCostCentres("", true)
    }

    return (
        <Form style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <Grid>
                <Grid.Row columns={2} verticalAlign='middle' style={{ padding: 0 }}>
                    <Grid.Column>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }}/>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <Icon name="close-outline" style={{ cursor: "pointer", color: "var(--danger)", marginTop: "1rem" }} onClick={() => setVisible(false)}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>
            <SuperField
                as='input'
                label={t('search_by_title')}
                placeholder="..."
                value={filters.search}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    search: value
                })}
            />
            <SuperField
                text="name"
                as="choice"
                label={t('unit')}
                value={filters.unit}
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                onChange={(e, { value }) => setFilters((prev) => ({ ...prev, unit: value }))} 
            />
            <SuperField
                as="choice"
                multiple
                search
                text="name"
                label={t('projects')}
                value={filters.projects}
                endpoint={API.PROJECTS + `?query={id,name}`}
                onChange={(e, { value }) => setFilters((prev) => ({ ...prev, projects: value }))} 
            />

            <Divider/>
            <Form.Field>
                <Button
                    primary
                    onClick={() => handleSubmit()}
                    content={t('filter')}
                />
            </Form.Field>
        </Form>
    );
};

export default CostCentresFilterSection;