import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Divider, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const PaymentVerification = ({ invoice, setInvoices, connection, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [paidDate, setPaidDate] = useState(moment().format("YYYY-MM-DD"))

    const handleSubmit = async () => {
        setIsProcessing(true)
        const request = await requests.patch(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id, {
            status: 3,
            date_paid: paidDate,
            payment_is_approved: true, // !! TODO: adjust on backend change!
            payment_is_verified: true // !! TODO: adjust on backend change!
        })

        if (request.status === 200) {
            setInvoices(prev => ({
                ...prev,
                results: prev.results.filter(item => {
                    if (item.id === invoice.id){
                        item.status = request.response.status
                        item.date_paid = request.response.date_paid
                        item.payment_is_approved = request.response.payment_is_approved
                        item.payment_is_verified = request.response.payment_is_verified
                    }
                    return item
                })
            }))
            onClose()
        }
        
        setIsProcessing(true)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Header as="h3" content={ t('verify_payment') }/>
            <Divider/>

            <SuperField as="datepicker"
                label={ t('paid_date') }
                value={paidDate}
                onChange={ (e, { value }) => setPaidDate(value) }
            />

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={isProcessing || paidDate === "" || paidDate === null}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default PaymentVerification;