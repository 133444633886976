import React, { useState }from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
// components 
import SuperField from '@components/forms/SuperField';
import { Form, Segment, Button, Grid } from 'semantic-ui-react';
import TerminationForm from './TerminationForm';

const Termination = ({ onClose, contract, termination, setTermination, setContracts }) => {
    const { t } = useTranslation();

    const [view, setView] = useState(0)
    const [template, setTemplate] = useState("")

    const SelectOptionTemplateOrWithoutTemplate = () => {
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <Segment
                            onClick={() => setView(1)}
                            style={{ cursor: "pointer", fontWeight: "bold" }}
                            className="segment-hover"
                            textAlign="center"
                            content={ t('with_template') }
                        />
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <Segment
                            onClick={() => setView(2)}
                            style={{ cursor: "pointer", fontWeight: "bold" }}
                            className="segment-hover"
                            textAlign="center"
                            content={ t('without_template') }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    const SelectYourTemplate = ({ template, setTemplate }) => {
        const { t } = useTranslation()
        const [selection, setSelection] = useState(template)

        return (
            <Form>
                <Form.Group>
                    <SuperField as="choice"
                        search
                        required
                        width={12}
                        text="title"
                        value={selection}
                        label={ t('choose_your_template') }
                        endpoint={API.TEMPLATING + "?query={id, title}&source=contracts.ContractTermination"}
                        onChange={(e, { value }) =>  setSelection(value)}
                    />
                    <Form.Field width={4}>
                        <Button 
                            fluid primary 
                            disabled={ selection === "" }
                            type="button"
                            content={ t('next_step') }
                            style={{ marginTop: "1.6rem" }} 
                            onClick={ () => { 
                                setView(2)
                                setTemplate(selection)
                            }}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        )
    }

    return (
        <>
            { view === 0 && <SelectOptionTemplateOrWithoutTemplate/> }
            { view === 1 && <SelectYourTemplate template={template} setTemplate={setTemplate}/> }
            { view === 2 && 
                <TerminationForm 
                    template={template}
                    contract={contract} 
                    setContracts={setContracts}
                    onClose={onClose}
                /> 
            }
        </>
    );
};

export default Termination;