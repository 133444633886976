import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation} from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Paginator from '@components/Paginator';
import Loading from "@components/general/Loading";
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import SuperDuperModal from '@components/modals/SuperDuperModal'
import { Container, Divider, Label, Button, Grid, Segment, Popup, Icon } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import Documents from '../../../../pages/dashboard/hr/contracts/views/Documents'
import RenderTemplateHtmlToPDF from '@components/RenderTemplateHtmlToPDF';
import TerminationUploadScannedFile from '../../../../pages/dashboard/hr/contracts/components/TerminationUploadScannedFile';


const ContractsSection = ({ data }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [result, setResult] = useState([])
    const [view, setView] = useState(0)
    const [loading, setLoading] = useState(true)

    const fetchData = async (params, isFiltered) => {
        setLoading(true)
        params = params || ""
        if (isFiltered) {
            setPage(1)
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            setPage(param !== null ? param : 1)
        }
        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        const endpoint = (view === 0) ? API.CONTRACTS_INSTANCES : API.CONTRACTS_TERMINATIONS
        const request = await requests.get(endpoint + "?paginate=true&employee=" + data.id + params)

        if (request.status === 200) {
            setResult({
                ...result,
                data: request.response
            })
        }
        setLoading(false)
    }

        useEffect(() => {
            fetchData('', true)
        // eslint-disable-next-line
    }, [view])

    const NoticePeriodAlert = ({ noticePeriod }) => {
        return (
            <Popup
                basic
                inverted
                content={t('notice_period_until') + " " + noticePeriod}
                trigger={
                    <Icon style={{ color: "var(--danger)", marginLeft: "0.5rem" }} name="exclamation circle" />
                }
            />
        )
    }

    return (
        <CanView permissions={['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts']}>
            <Container fluid>
                <Grid>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column computer={16} tables={16} mobile={16}>
                            <Button.Group basic size="small" style={{ borderRadius: "0" }}>
                                <Button active={view === 0} content={t('contracts')} onClick={() => setView(0)} />
                                <Button active={view === 1} content={t('terminations')} onClick={() => setView(1)} />
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                {view === 0 ?
                    <Segment style={{
                        boxShadow: "none", border: "none", padding: 0, background: "transparent"
                    }}>
                        { loading ? <Loading /> :
                            <>
                            <FlexTable>
                                    <FlexRow>
                                        <FlexHeader content={t('contract_type')} />
                                        <FlexHeader content={t('starts')} />
                                        <FlexHeader content={t('ends')} />
                                        <FlexHeader content={t('status')} />
                                        <FlexHeader content={t('documents')} />
                                    </FlexRow>
                                    <EmptyRow length={result?.data?.count} />
                                    {result?.data?.results?.map(contract =>
                                        <FlexRow key={contract.id} fontSize="1rem" borders>
                                            <FlexItem bold> {contract?.contract_data?.type?.title} </FlexItem>
                                            <FlexItem>{contract?.contract_data?.valid_from}</FlexItem>
                                            <FlexItem>{contract?.contract_data?.valid_until ? contract?.contract_data?.valid_until : t('indefinite_period')}</FlexItem>
                                            <FlexItem>
                                                {contract?.is_draft // is draft, nothing can be done with this Contract
                                                    ? <Label basic>{t('draft')}</Label>
                                                    : contract?.is_active && !contract?.is_terminated // Is active but not terminated
                                                        ? <Label style={{ background: "var(--success)", color: "var(--white)" }}>{t('active')}</Label>
                                                        : contract?.is_active && contract?.is_terminated && contract?.notice_period !== null && moment(contract?.notice_period).isAfter(moment().format("YYYY-MM-DD"))
                                                            ? <><Label style={{ background: "var(--success)", color: "var(--white)", textAlign: "center" }}>{t('active')} </Label><NoticePeriodAlert noticePeriod={moment(contract?.notice_period).format("DD.MM.YYYY")} /></>
                                                            : <Label style={{ background: "var(--danger)", color: "var(--white)", textAlign: "center" }}>{t('terminated')} </Label>
                                                }
                                            </FlexItem>
                                            <FlexItem>
                                                <SuperDuperModal
                                                    size="small"
                                                    trigger={<Button size="tiny" basic content={t('manage_documents')} />}
                                                    content={<Documents contract={contract} setContracts={setResult}/>}
                                                />
                                            </FlexItem>
                                        </FlexRow>
                                    )}
                                </FlexTable>
                                <Paginator
                                    forcePage={page || 1}
                                    forceReload={false}
                                    limit={limit}
                                    setLimit={setLimit}
                                    length={result?.data?.count || 0}
                                    onChange={(params) => fetchData(params)}
                                />
                            </>
                        }
                    </Segment>
                :
                    <Segment style={{
                        boxShadow: "none", border: "none", padding: 0, background: "transparent"
                    }}>
                        { loading ? <Loading /> :
                            <>
                             <FlexTable>
                                <FlexRow>
                                    <FlexHeader content={ t('type') }/>
                                    <FlexHeader content={ t('terminated_by') }/>
                                    <FlexHeader content={ t('notice_period') }/>
                                    <FlexHeader content={ t('reason') }/>
                                    <FlexHeader content={ t('document') }/>
                                </FlexRow>
                                <EmptyRow length={result?.data?.count} />
                                { result?.data?.results?.map((termination) =>
                                    <FlexRow key={termination.id} fontSize="1rem" borders>
                                        <FlexItem bold> { termination?.type_display } </FlexItem>
                                        <FlexItem bold> { termination?.terminated_by_display } </FlexItem>
                                        <FlexItem> { moment(termination?.notice_period).format("DD.MM.YYYY") } </FlexItem>
                                        <FlexItem bold> { termination?.reason?.title } </FlexItem>
                                        <FlexItem>
                                            { termination?.template || termination?.external_file
                                                ?
                                                <>
                                                    { termination?.external_file &&
                                                        // eslint-disable-next-line
                                                        <a href={termination?.external_file} target="_blank" rel="noopener noreferrer">
                                                            <Icon style={{ cursor: "pointer", color: "var(--danger)" }} size="large" name="file pdf"/>
                                                        </a>
                                                    }

                                                    { termination?.template &&
                                                        <SuperDuperModal
                                                            size="small"
                                                            trigger={
                                                                <Icon style={{ cursor: "pointer", color: "var(--danger)" }} size="large" name="file pdf"/>
                                                            }
                                                            content={
                                                                <RenderTemplateHtmlToPDF
                                                                    documentId={termination?.template?.id}
                                                                    objId={termination.id}
                                                                    objInstance="contracts.ContractTermination"
                                                                />
                                                            }
                                                        />
                                                    }
                                                </>
                                                :
                                                <CanView permissions={['contracts.c_manage_all_contracts']}>
                                                    <SuperDuperModal
                                                        trigger={ <Button size="tiny" basic content={ t('add_document') }/> }
                                                        content={ <TerminationUploadScannedFile terminationId={termination.id} setTerminations={setResult}/> }
                                                    />
                                                </CanView>
                                            }
                                        </FlexItem>
                                        <FlexItem>
                                            <FlexRow background="transparent" padding="0" fontSize="1rem">
                                                <FlexItem textAlign="left">
                                                    <Popup
                                                        basic
                                                        inverted
                                                        content={ t('show_note') }
                                                        trigger={
                                                            <div>
                                                                <SuperDuperModal
                                                                    closeIcon
                                                                    header={ t('note') }
                                                                    content={ <p> { termination.note !== "" ? termination.note : t('note_is_empty') } </p> }
                                                                    trigger={
                                                                        <Icon name="sticky note" style={{ cursor: "pointer", color: "var(--dark)" }}/>
                                                                    }
                                                                />
                                                            </div>
                                                        }
                                                    />
                                                </FlexItem>
                                            </FlexRow>
                                        </FlexItem>
                                    </FlexRow>
                                )}
                            </FlexTable>
                                <Paginator
                                    forcePage={page || 1}
                                    forceReload={false}
                                    limit={limit}
                                    setLimit={setLimit}
                                    length={result?.data?.count || 0}
                                    onChange={(params) => fetchData(params)}
                                />
                            </>
                        }
                    </Segment>
                }

            </Container>
        </CanView>
    );
};

export default ContractsSection;