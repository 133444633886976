import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes } from '@routes';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Label, Icon as SemanticIcon, Grid, Modal, Button } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import CanView from '@components/perms/CanView';
import UserAvatar from 'react-user-avatar';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import LabelsForm from '@components/forms/LabelsForm';
import Action from '@components/general/Action';
import IssueDetail from './IssueDetail';


const IssueTableView = ({ issues, setIssues, readOnly, setReadOnly }) => {
    const { t } = useTranslation()
    const [modal, setModal] = useState({
        isOpen: false,
        selectedIssue: null,
      })

      const onDelete = async (id) => {
        const request = await requests.del(API.PROJECT_ISSUES + id + "/")
        if(request.status === 204){
            setIssues(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    count: prevState.data.count - 1,
                    results: issues.data.results.filter(item => item.id !== id)
                },
            }))
        }
    }

    const changeState = async (id, value) => {
        const request = await requests.patch(API.PROJECT_ISSUES + id + "/", {state: value})
        if(request.status === 200){
            setIssues(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: issues.data.results.filter(item => {
                        if (item?.id === id) {
                            item.state = value
                        }
                        return item;
                    })
                }
            }))
        }
    }

    const onLabelsAdd = (id, labels) => {
        setIssues(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                results: issues.data.results.filter(item => {
                    if (item?.id === id) {
                        item.labels = labels
                    }
                    return item;
                })
            }
        }))
    }

    const onLabelRemove = async (id, labelID) => {

        const request = await requests.patch(API.PROJECT_ISSUES + id + "/?query={id, labels}", {
            labels: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setIssues(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: issues?.data?.results?.filter(item => {
                        if (item?.id === id) {
                            item.labels = request.response.labels
                        }
                        return item;
                    })
                }
            }))
        }

    }

    const ShowLabels = ({ row }) => {
        return (
            <>
                {row?.labels?.map(label => (
                    <Label style={{ marginTop: '3px'}} basic size="tiny" key={label.id}>
                        <Icon name="pricetag-outline" style={{ color: label.color === "" ? "" : label.color, marginRight: "0.5rem" }}/> 
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            {label.name}
                        </span>
                        <CanView permissions={['projects.c_manage_labels']}>
                            <Icon name="close-outline" onClick={() => onLabelRemove(row.id, label.id)} style={{ marginLeft: "0.5rem", cursor: "pointer", color: "var(--danger)" }}/>
                        </CanView>
                    </Label>
                ))}
                <CanView permissions={['projects.c_manage_labels']}>
                    <SuperDuperModal
                        trigger={
                            <Label basic size="tiny" style={{ marginTop: '4px', cursor: "pointer" }}>
                                <Icon name="add-outline" style={{ marginRight: 0 }} />
                            </Label>
                        }
                        content={
                            <LabelsForm
                                id={row.id}
                                data={row.labels}
                                onConfirm={onLabelsAdd}
                                patchEndpoint={API.PROJECT_ISSUES}
                                labelEndpoint={API.PROJECT_LABELS + `?project=${row.project?.id}`}
                                fieldName={"labels"}
                            />
                        }
                    />
                </CanView>
            </>
        );
    }

    const PutAvatar = ({ row, count }) => {
        return (
            <Grid style={{ padding: 0 }} >
                <Grid.Row style={{ padding: 0 }}>
                    <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                        { count >= 2 &&
                        <UserAvatar className="avatar-project-row fourth" size={35} name={ (count -1).toString() } color='var(--info)'/>
                        }
                        <UserAvatar className="avatar-project-row first" style={{textAlign: "right"}} size={35} name={row?.fullname} src={row?.profile_picture} />
                    </SemanticIcon.Group>
                </Grid.Row>
            </Grid>
        );
    }

    return (
        <>
            <FlexTable>
                <FlexRow>
                    <FlexHeader basis="80%" content={t('name')} />
                    <FlexHeader basis="100%" content={t('labels')} />
                    <FlexHeader basis="50%" content={t('due_date')} />
                    <FlexHeader basis="50%" content={t('milestone')} />
                    <FlexHeader basis="50%" content={t('assigned_to')} />
                    <FlexHeader basis="30%" content={t('actions')} />
                </FlexRow>
                <EmptyRow length={issues?.data?.results?.length} />
                {issues?.data?.results?.map((issue, index) => (
                    <FlexRow key={index} fontSize="0.9rem" borders>
                        <FlexItem basis="80%">
                            <strong>{issue?.name}</strong>  <br />
                                <span><strong> # {issue?.number}</strong> {t('created_by')} </span>
                                <Link to={routes.EMPLYOEES_DETAIL + issue?.created_by?.profile_id} target="_blank">
                                    <strong>{issue?.created_by?.name}</strong>
                                </Link>
                                <span> {t('at')} <strong>{moment(issue?.created_on).format('YYYY-MM-DD')}</strong> </span>
                        </FlexItem>
                        <FlexItem basis="100%" content={
                            <ShowLabels
                                row={issue ? issue : ''}
                                type={'projects'}
                            />
                        } />
                        <FlexItem basis="50%" content={issue?.due_date} />
                        <FlexItem basis="50%">
                            <strong>{issue?.milestone?.name}</strong>
                        </FlexItem>
                        <FlexItem basis="50%">
                            { issue?.assigned?.[0] &&
                                <PutAvatar row={issue?.assigned?.[0]} count={issue?.assigned?.length} />
                            }
                        </FlexItem>
                        <FlexItem basis="30%">
                            <FlexRow background="transparent" padding="0">
                                <FlexItem textAlign="center">
                                    <Action
                                        as="custom"
                                        type="icon"
                                        icon="eye-outline"
                                        modalSize="large"
                                        popupText={t('view_detail')}
                                        tooltip={t('view_detail')}
                                        iconColor="var(--primary)"
                                        onClick={() => {
                                            setModal({isOpen: true, selectedIssue: issue})
                                            setReadOnly(true)
                                        }}
                                    />
                                </FlexItem>
                                <FlexItem textAlign="center">
                                    <CanView permissions={['projects.c_manage_issues']}>
                                        { issue.state === 2 ?
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="lock-open-outline"
                                            tooltip={t('open_issue')}
                                            iconColor="var(--info)"
                                            onClick={() => {
                                                changeState(issue.id, 1)
                                            }}
                                        /> :
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="lock-closed-outline"
                                            tooltip={t('close_issue')}
                                            iconColor="var(--info)"
                                            onClick={() => {
                                                changeState(issue.id, 2)
                                            }}
                                        />
                                    }

                                    </CanView>
                                </FlexItem>
                                {/* <FlexItem textAlign="center">
                                    <CanView permissions={['projects.c_manage_issues']}>
                                        <Action
                                            as="custom"
                                            type="icon"
                                            icon="pencil"
                                            modalSize="large"
                                            popupText={t('edit')}
                                            tooltip={t('edit')}
                                            iconColor="var(--primary)"
                                            onClick={() => {
                                                setModal({isOpen: true, selectedIssue: issue, readOnly: false})
                                                setReadOnly(false)
                                            }}
                                        />
                                    </CanView>
                                </FlexItem> */}
                                <FlexItem textAlign="center">
                                    <CanView permissions={['projects.c_delete_issues']}>
                                        <Action
                                            as='delete'
                                            text={t('delete_issue')}
                                            onClick={() => onDelete(issue.id)}
                                        />
                                    </CanView>
                                </FlexItem>
                            </FlexRow>
                        </FlexItem>
                    </FlexRow>
                ))}
            </FlexTable>

            <Modal
                closeIcon
                onClose={() => setModal( prev => ({ ...prev, isOpen: false}))}
                onOpen={() => setModal( prev => ({ ...prev, isOpen: true }))}
                open={modal.isOpen}
                size='large'
                trigger={<Button style={{ display: "none" }}/>}
                >
                <Modal.Content>
                    <IssueDetail
                        issue={modal.selectedIssue}
                        setIssues={setIssues}
                        issues={issues}
                        onLabelRemove={onLabelRemove}
                        onLabelsAdd={onLabelsAdd}
                        readOnly={readOnly}
                        setReadOnly={setReadOnly}

                        />
                </Modal.Content>
            </Modal>
        </>
    );
};

export default IssueTableView;