import React, { useState } from 'react'
// components
import Icon from '@components/Icon';
import { Button, Sidebar, Segment, Grid, Menu } from 'semantic-ui-react'
import RecordFilterForm from './forms/RecordFilterForm';

const RecordsFilter = ({ setFilters, options }) => {
    const [visible, setVisible] = useState(false)

    return (
        <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
            <Sidebar
                style={{ width: "40rem", }}
                as={Menu}
                animation='overlay'
                direction='right'
                icon='labeled'
                vertical
                visible={visible}
                onHide={() => setVisible(false)}
                width='wide'
            >
                <RecordFilterForm
                    setVisible={setVisible}
                    setFilters={setFilters}
                    options={options}
                />
            </Sidebar>
            <Sidebar.Pusher>
                <Grid columns="2">
                    <Grid.Column />
                    <Grid.Column textAlign='right' >
                        <Button.Group>
                            <Button
                                size="small"
                                style={{
                                    background: "none",
                                    color: "var(--dark)",
                                    paddingLeft: "1rem",
                                    paddingRight: "1rem"
                                }}
                                onClick={() => setVisible(!visible)}
                            >
                                <Icon name="funnel-outline" />
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid>
            </Sidebar.Pusher>
        </Sidebar.Pushable>
    );
};

export default RecordsFilter;