import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { companyService } from '@services/ServiceCompany';
import { employeesService } from '@services/ServiceEmpoyees';
// components
import { useHasPermissions } from '@helpers/hooks';
import { Button, Grid } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal'
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import BankAccount from '@components/common/bank_account/BankAccount';
import BankAccountForm from '@components/common/bank_account/BankAccountForm';
import CanView from '@components/perms/CanView';


const EmployeeBankAccounts = (props) => {
    const { t } = useTranslation();
    const canManageEmployees = useHasPermissions(["common.c_manage_user_employees", "common.c_manage_all_employees"]);

    // eslint-disable-next-line
    const [banks, setBanks] = useState(props.data?.personal_information?.bank_accounts)

    const onCreate = async (createdItem) => {
        // assign to employee
        const result = await employeesService.updatePersonalInformation({
            bank_accounts: { add: [createdItem.id] }
        }, props.data?.personal_information?.id)

        if (result.status === 200) {
            setBanks([...banks, createdItem])
            props.setData(prevState => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        personal_information: result.response
                    }
                }
            })

        }
    }

    const onUpdate = (updatedItem) => {
        setBanks(prevState => prevState.filter(item => {
            if (item.id === updatedItem.id) {
                item.name = updatedItem.name;
                item.swift_code = updatedItem.swift_code;
                item.iban = updatedItem.iban;
                item.alias = updatedItem.alias;
                item.bank_detail = updatedItem.bank_detail;
                item.currency = updatedItem.currency;
                item.default = updatedItem.default;
            }

            return item;

        }))
    }

    const onDelete = async (id) => {
        const result = await companyService.deleteBankAccount(id);
        if (result.status === 204) {
            const newBanks = banks.filter((item) => item.id !== id)
            setBanks(newBanks);
            props.setData(prevState => {
                return {
                    ...prevState,
                    data: {
                        ...prevState.data,
                        personal_information: {
                            ...prevState.data?.personal_information,
                            bank_accounts: newBanks
                        }
                    }
                }


            })
        }

    }

    return (
        <Grid>
            {banks.length > 0 &&
                <Grid.Row>
                    <Grid.Column textAlign="right">
                        <Button.Group>
                            <CanView permissions={["common.c_manage_user_employees", "common.c_manage_all_employees"]}>
                                <SuperDuperModal
                                    trigger={
                                        <OutlineAddButton color="violet" text={t('add_bank')} size="small" />
                                    }
                                    size="small"
                                    header={t('create_new_bank')}
                                    content={
                                        <BankAccountForm
                                            btnName={t('create')}
                                            onConfirm={(new_item) => onCreate(new_item)}
                                        />
                                    }
                                />
                            </CanView>
                            <Button style={{ marginLeft: "1rem", border: "1px solid var(--border)" }} type="button" onClick={props.onClose}>
                                {t('close')}
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            }

            <Grid.Row centered style={styles.list}>
                {banks.length > 0
                    ?
                    banks.map((bank, index) => (
                        <Grid.Column style={{ marginBottom: "3rem", width: "33%" }} mobile={16} tablet={8} computer={5} key={index}>
                            <BankAccount
                                bank={bank}
                                isManageable={canManageEmployees}
                                handleBankUpdate={(updated_item) => onUpdate(updated_item)}
                                onDelete={() => onDelete(bank.id)}
                            />
                        </Grid.Column>
                    ))

                    :
                    <div style={styles.warning}>
                        {t('no_banks')} <br />

                        <SuperDuperModal
                            trigger={
                                <OutlineAddButton color="violet" text={t('add_bank')} size="small" />
                            }
                            size="small"
                            header={t('create_new_bank')}
                            content={
                                <BankAccountForm
                                    btnName={t('create')}
                                    onConfirm={(new_item) => onCreate(new_item)}
                                />
                            }
                        />
                    </div>
                }
            </Grid.Row>
        </Grid>
    );
};

const styles = {
    page: { padding: '1.8rem', paddingBottom: 0, paddingTop: '0' },
    head: { justifyContent: "space-between", alignItems: "center", marginLeft: "1.5rem" },
    list: { marginTop: "2rem" },
    warning: { fontSize: 28, textAlign: "center" },
}

export default EmployeeBankAccounts