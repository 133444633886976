import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// store
import { deleteRequest, createRequest, patchRequest } from '@services/ServiceCommon';
import { API } from "@store/config";
import { routes } from '@store/routes';
// components
import Icon from '@components/Icon';
import { Icon as SemanticIcon, Label } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import AvatarIcon from '@components/AvatarIcon';
import ConfrimModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Phone from '@components/general/Phone';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
// module specific
import ContactForm from './ContactForm';
import ContactLabelsForm from './ContactLabelsForm';


const ContactTable = ({ data, setContacts, openDetail }) => {
    const { t } = useTranslation()

    async function deleteContact(id) {
        const request = await deleteRequest(API.CONTACTS + id + "/")
        if(request.status === 204){
            setContacts(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count - 1,
                    results: prev.data.results.filter((item) => item.id !== id)
                }
            }))
        }
    }

    const onLabelsAdd = (id, labels) => {
        setContacts(prev => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    results: prev.data.results.filter(contact => {
                        if (contact.id === id) {
                            contact.roles_label = labels
                        }
                        return contact;
                    })
                }
            }
        })
    }

    const onLabelRemove = async (id, labelID, resourcetype) => {
        const request = await patchRequest(API.CONTACTS + id + "/?query={id, roles_label}", {
            resourcetype: resourcetype,
            roles_label: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setContacts(prev => {
                return {
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.filter(contact => {
                            if (contact.id === id) {
                                contact.roles_label = request.response.roles_label
                            }
                            return contact;
                        })
                    }
                }
            })
        }
    }

    async function convertContact(id, contact_type) {
        const request = await createRequest(`${API.CONTACTS}${id}/convert/?query={id}`)
        if( request.status === 200 ){
            if( contact_type === 2 || contact_type === 3 ){
                setContacts(prev => {
                    return {
                        ...prev,
                        data: {
                            ...prev.data,
                            results: prev.data.results.filter(contact => {
                                if (contact.id === id) {
                                    contact.profile = request.response.id
                                }
                                return contact;
                            })
                        }
                    }
                })
            }
            if( contact_type === 4 ){
                setContacts(prev => {
                    return {
                        ...prev,
                        data: {
                            ...prev.data,
                            results: prev.data.results.filter(contact => {
                                if (contact.id === id) {
                                    contact.account = request.response.id
                                }
                                return contact;
                            })
                        }
                    }
                })
            }
        }
    }

    const Heading = () => (
        <FlexRow borders>
            <FlexHeader basis="80%" content={t('name')} />
            <FlexHeader basis="30%" content={t('gender')} />
            <FlexHeader basis="70%" content={t('contact')} />
            <FlexHeader basis="150%" content={t('labels')} />
            <FlexHeader basis="50%" content={t('actions')} />
        </FlexRow>
    )

    const TableContent = () => {

        function getColor(type) {
            switch (type) {
                case 1: return 'var(--variant3)'
                case 2: return 'var(--primary)'
                case 3: return 'var(--blue)'
                case 4: return 'var(--info)'
                default: return 'var(--black)'
            }
        }

        if (data.count === 0) return (
            <EmptyRow length={0}/>
        )


        return data.results.map(item => {
            let fullname = 'without name'
            let gender = "--"
            if (item.person) fullname = `${item.person?.firstname} ${item.person?.middlename && `${item.person?.middlename} `}${item.person?.lastname}`
            if (item.contact_type === 4) fullname = `${item.contact_person?.firstname} ${item.contact_person?.lastname} - ${item.name}`
            if (item.person) gender = item.person?.gender_display
            if( item.contact_type === 4) gender = item.contact_person?.gender_display
            const phone = item.contact_info.phones[0]?.phone
            const email = item.contact_info.emails[0]?.email

            return (
                <FlexRow key={item.id} padding="1rem" fontSize="0.9rem">
                    <FlexItem basis="80%">
                        <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                            <FlexItem basis="10%">
                                <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                    <AvatarIcon size={30} name={fullname} src={item.profile_picture} />
                                </SemanticIcon.Group>
                            </FlexItem>
                            <FlexItem>
                                <strong>{fullname}</strong><br/>
                                <strong style={{ color: getColor(item.contact_type) }}>
                                    {item.contact_type_display}
                                </strong>
                            </FlexItem>
                        </FlexRow>
                    </FlexItem>
                    <FlexItem basis="30%" content={gender} />
                    <FlexItem basis="70%">
                        { phone && <><Phone phone={phone} /><br/></>} 
                        { email && <a href={"mailto:" + email}> <Icon name="mail-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{email}</span> </a> }
                    </FlexItem>
                    <FlexItem basis="150%">
                        { item.roles_label.map(label => (
                            <Label basic size="tiny" key={label.id}>
                                <Icon name="pricetag-outline" style={{ color: (label.color === "" ? "" : label.color),  marginRight: "0.5rem" }}/> <span>{ label.name }</span> 
                                <CanView permissions={["contacts.c_manage_contacts"]}>
                                    <Icon name="close-outline" style={{ marginLeft: "0.5rem", cursor: "pointer", color: "var(--danger)" }} onClick={ () => onLabelRemove(item.id, label.id, item.resourcetype)}/>
                                </CanView>
                            </Label>
                        )) }
                        <CanView permissions={["contacts.c_manage_contacts"]}>
                            <SuperDuperModal 
                                trigger={
                                    <Label basic size="tiny" style={{ cursor: "pointer" }}>
                                        <Icon name="add-outline"/>
                                    </Label>
                                }
                                content={
                                    <ContactLabelsForm 
                                        id={item.id}
                                        rolesLabel={item.roles_label}
                                        resourcetype={item.resourcetype}
                                        onConfirm={onLabelsAdd}
                                    />
                                }
                            />
                        </CanView>
                    </FlexItem>
                    <FlexItem basis="50%">
                        <FlexRow background="transparent" padding="0">
                            <FlexItem textAlign="center">
                                <Icon
                                    name="eye-outline"
                                    style={{ cursor: 'pointer', color: 'var(--primary)' }}
                                    onClick={() => openDetail(item)} 
                                />
                            </FlexItem>
                            <CanView permissions={["contacts.c_manage_contacts"]}>
                                <FlexItem textAlign="center">
                                    { item.contact_type !== 1 && 
                                        <>
                                            { item.profile !== null && (item.contact_type === 2 || item.contact_type === 3)
                                                ? 
                                                    <Link to={item.contact_type === 2 ? routes.CONTACTS : routes.EMPLYOEES_DETAIL + item.profile} target="_blank">
                                                        <Icon name="id-card-outline" disabled={item.contact_type === 2} style={{ cursor: item.contact_type === 2 ? 'not-allowed' : 'pointer', opacity: item.contact_type === 2 ? "0.5" : "1", color: "var(--blue)" }}/>  
                                                    </Link>
                                                :   
                                                    <>
                                                        { (item.contact_type === 2) &&
                                                            <ConfrimModal
                                                                onConfirm={() => convertContact(item.id, item.contact_type)}
                                                                description={t('contact_convert_popup')}
                                                                button={
                                                                    <Icon
                                                                        name="log-out-outline"
                                                                        style={{ cursor: 'pointer', color: "var(--blue)" }} 
                                                                    />
                                                                }
                                                            />
                                                        }
                                                    </>
                                            }

                                            { item.account !== null && item.contact_type === 4
                                                ? 
                                                    <Link to={routes.ACCOUNTS_DETAIL + item.account} target="_blank">
                                                        <Icon name="id-card-outline" style={{ cursor: 'pointer', color: "var(--blue)" }}/>  
                                                    </Link>
                                                :   
                                                    <>
                                                        { item.contact_type === 4 && 
                                                            <ConfrimModal
                                                                onConfirm={() => convertContact(item.id, item.contact_type)}
                                                                description={`This action will convert contact to profile, would you like to proceed?`}
                                                                button={
                                                                    <Icon
                                                                        name="log-out-outline"
                                                                        style={{ cursor: 'pointer', color: "var(--blue)" }} 
                                                                    />
                                                                }
                                                            />
                                                        }
                                                    </>
                                            }
                                        </>
                                    }
                                </FlexItem>
                                <FlexItem textAlign="center">
                                    <SuperDuperModal
                                        size="small"
                                        trigger={
                                            <Icon
                                                name="pencil"
                                                style={{ cursor: 'pointer' }} 
                                            />
                                        }
                                        content={
                                            <ContactForm setContacts={setContacts} contact={item} />
                                        }
                                    />
                                </FlexItem>
                            </CanView>
                            <CanView permissions={["contacts.c_delete_contacts"]}>
                                <FlexItem textAlign="center">
                                    <ConfrimModal
                                        onConfirm={() => deleteContact(item.id)}
                                        description={`Do you really want to delete ${fullname} as your contact?`}
                                        button={
                                            <Icon
                                                name="close-outline"
                                                style={{ cursor: 'pointer', color: "var(--danger)" }}
                                            />
                                        }
                                    />
                                </FlexItem>
                            </CanView>
                        </FlexRow>
                    </FlexItem>
                </FlexRow>
            )
        })
    }


    return (
        <div style={{ minHeight: '72vh' }}>
            <FlexTable>
                <Heading />
                <TableContent />
            </FlexTable>
        </div>
    );
};

export default ContactTable;