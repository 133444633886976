import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { createRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import Invoice from './documents/Invoice';
import { Grid, Card, Header, Segment } from 'semantic-ui-react';

const CreateDocument = ({ connection, journal, onClose, setSize }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [document, setDocument] = useState(null)
    const [invoice, setInvoice] = useState(null)

    const handleDocumentCreate = async (isIssued) => {
        setIsProcessing(true)
        const request = await createRequest(API.INVOICES + "?connection=" + connection.connection_id + "&account_ids=" + journal.id, {
            invoice_type: 1, // invoice
            status: 4,
            is_issued: isIssued,
            payment_method: 1,
            tax_mode: 1,
            currency: "EUR",
            account: journal.account_id,
            supplier_registration_id: journal.id_number
        })
        
        if( request.status === 201 ){
            setInvoice(request.response)
            setDocument("sent_invoice")
        }
        setIsProcessing(false)
    }

    useEffect( () => {
        if( document !== null ){
            setSize("large")
        } else {
            setSize("tiny")
        }
        // eslint-disable-next-line
    }, [document])

    return (
        <>
        { document === null
            ? 
            <Segment loading={isProcessing} style={{ 
                padding: 0, 
                margin: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none", 
                paddingTop: isProcessing ? "2rem" : 0,
                paddingBottom: isProcessing ? "2rem" : 0,
            }}>
                { !isProcessing && 
                    <Grid>
                        <Grid.Row>
                            <Grid.Column textAlign="center">
                                <Header as="h2" content={ t('choose_invoice_type') }/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column
                                onClick={() => handleDocumentCreate(true)}
                                className="toggle-button"
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Card fluid style={{ borderRadius: 0 }}>
                                    <Card.Content as="h2">
                                        <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/>
                                            { t('send_invoice') }
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                            <Grid.Column
                                onClick={() => setDocument("recieved_invoice")}
                                className="toggle-button"
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Card fluid style={{ borderRadius: 0 }}>
                                    <Card.Content as="h2">
                                        <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/> 
                                        { t('recieved_invoice') }
                                    </Card.Content>
                                </Card>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                }
            </Segment>
            : 
            <>
                { document === "recieved_invoice" && <Invoice document={document} connection={connection} journal={journal} onClose={onClose}/> }
                { document === "sent_invoice" && <Invoice invoice={invoice} document={document} connection={connection} journal={journal} onClose={onClose}/> }
            </>
        }
        </>
    );
};

export default CreateDocument;