import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, Link} from 'react-router-dom';
import moment from 'moment';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { routes } from '@store/routes';
// components
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';
import Loading from "@components/general/Loading";
import EmptyRow from '@components/tables/EmptyRow';
import { Container, Divider, Form, Button, Grid, Segment } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import SuperField from '@components/forms/SuperField';
// module specific components


const TimesheetSection = ({ data }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [timesheets, setTimesheets] = useState([])
    const [loading, setLoading] = useState(true)

    const initState = {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
        category: "",
        business_detail: ""
    }
    const [filters, setFilters] = useState(initState)

    const setParams = () => {
        let params = ""
        if( filters.start_date !== "") params += `&start_date=${filters.start_date}`
        if( filters.end_date !== "") params += `&end_date=${filters.end_date}`
        if( filters.category !== "") params += `&category=${filters.category}`
        if( filters.business_detail !== "") params += `&business_detail=${filters.business_detail}`
        return params
    }

    const fetchData = async (params, isFiltered) => {
        setLoading(true)

        params = params || ""
        params += setParams()
        if (isFiltered) {
            setPage(1)
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            setPage(param !== null ? param : 1)
        }
        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        const request = await requests.get(API.TIMESHEETS_RECORDS + "?paginate=true&profile=" + data?.id + params)

        if (request.status === 200) {
            setTimesheets({
                ...timesheets,
                data: request.response
            })
        }
        setLoading(false)
    }

        useEffect(() => {
            fetchData('', true)
        // eslint-disable-next-line
    }, [])
    return (
        <CanView permissions={['timesheets.c_view_all_records', 'timesheets.c_view_user_records']}>
            <Container fluid>
                <Grid>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column computer={16} tables={16} mobile={16}>
                        <Form onSubmit={async () => {
                            history.replace({ pathname: location.pathname, search: `?page=${1}` });
                            await fetchData("", true)
                        }}>
                            <Form.Group widths="equal">
                                <SuperField as="datepicker"
                                    label={ t('start_date') }
                                    value={filters.start_date}
                                    onChange={ (e, { value }) => setFilters({...filters, start_date: value})}
                                    />
                                <SuperField as="datepicker"
                                    label={ t('due_date') }
                                    value={filters.end_date}
                                    onChange={ (e, { value }) => setFilters({...filters, end_date: value})}
                                />
                                <SuperField as="choice"
                                    search
                                    endpoint={API.BUSINESS_DETAIL + "?is_freelancer=false&is_supplier=false&query={id, name, source}"}
                                    text="name"
                                    label={t('employer')}
                                    value={filters?.business_detail}
                                    onChange={(e, { value }) => setFilters({
                                        ...filters,
                                        business_detail: value
                                    })}
                                />
                                <SuperField as="choice"
                                    search
                                    endpoint={API.TIMESHEETS_CATEGORIES}
                                    text="title"
                                    label={t('activity')}
                                    value={filters?.category}
                                    onChange={(e, { value }) => setFilters({
                                        ...filters,
                                        category: value
                                    })}
                                />
                                <Form.Field width={4} style={{ marginTop: "1.6rem" }}>
                                    <Button
                                        fluid
                                        loading={loading}
                                        disabled={loading}
                                        primary content={t('confirm')}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider />
                <Segment style={{
                    boxShadow: "none", border: "none", padding: 0, background: "transparent"
                }}>
                    { loading ? <Loading /> :
                        <>
                            <FlexTable>
                                <FlexRow>
                                    <FlexHeader basis="50%" content={t('week')} />
                                    <FlexHeader basis="50%" content={t('date')} />
                                    <FlexHeader basis="120%" content={t('activity')} />
                                    <FlexHeader basis="50%" content={t('hours')} />
                                    <FlexHeader content={t('employer')} />
                                    <FlexHeader content={t('project')} />
                                    <FlexHeader content={t('milestone')} />
                                    <FlexHeader basis="120%" content={t('note')} />
                                    {/* <CanView permissions={["timesheets.c_manage_user_records", "timesheets.c_manage_all_records"]}>
                                        <FlexHeader basis="40%" content={t('actions')} />
                                    </CanView> */}
                                </FlexRow>
                                <EmptyRow length={timesheets?.data?.count || 0} />
                                {timesheets?.data?.results?.map(record => record.total_hours === undefined && (
                                    <FlexRow fontSize="0.9rem" key={record?.id} borders>
                                        <FlexItem basis="50%" content={record.week} />
                                        <FlexItem basis="50%" content={moment(record.date).format("DD.MM.YYYY")} />
                                        <FlexItem basis="120%" content={record?.category?.title} />
                                        <FlexItem basis="50%" content={record.hours} />
                                        <FlexItem>
                                            <Link to={record?.business_detail?.source === "account"
                                            ? routes.ACCOUNTS_DETAIL + record?.business_detail?.identification_number
                                            : routes.SUPPLIERS + record?.business_detail?.identification_number}>
                                                <span>{record?.business_detail?.name}</span>
                                            </Link>
                                        </FlexItem>
                                        <FlexItem>
                                            <Link to={routes.PROJECT_DETAIL + record?.project?.id}>
                                                <span>{record?.project?.name}</span>
                                            </Link>
                                        </FlexItem>
                                        <FlexItem content={record?.milestone?.name} />
                                        <FlexItem bold basis="120%" content={record.activity} />
                                        {/* <CanView permissions={["timesheets.c_manage_user_records", "timesheets.c_manage_all_records"]}>
                                            <FlexItem basis="40%">
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem>
                                                        <SuperDuperModal
                                                            size="small"
                                                            trigger={
                                                                <Icon name="pencil" style={{ color: "var(--dark)", cursor: "pointer" }} />
                                                            }
                                                            content={
                                                                <RecordForm
                                                                    record={record}
                                                                    options={options}
                                                                    onSubmit={(response) => onUpdate(response)}
                                                                />
                                                            }
                                                        />
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <ConfirmModal
                                                            description={t('delete_record_message')}
                                                            onConfirm={() => onDelete(record.id)}
                                                            button={
                                                                <Icon name="remove" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                            }
                                                        />
                                                    </FlexItem>
                                                </FlexRow>
                                            </FlexItem>
                                        </CanView> */}
                                    </FlexRow>
                                ))}
                            </FlexTable>
                        </>
                    }
                    <Paginator
                        forcePage={page || 1}
                        forceReload={false}
                        limit={limit}
                        setLimit={setLimit}
                        length={timesheets?.data?.count || 0}
                        onChange={(params) => fetchData(params)}
                    />
                </Segment>
            </Container>
        </CanView>
    );
};

export default TimesheetSection;