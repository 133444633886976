import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { Button, Dropdown } from 'semantic-ui-react';

const DatePresets = ({ from, to, onSelection }) => {
    const { t } = useTranslation()
    const [year, setYear] = useState(moment().format("YYYY"))
    const [visible, setVisible] = useState(false)

    const handlePresetSelection = (month, year) => {
        const firstDay = moment(year + "-" + month + "-01").startOf('month').format('YYYY-MM-DD')
        const lastDay =  moment(year + "-" + month + "-01").endOf('month').format('YYYY-MM-DD')

        onSelection(firstDay, lastDay)
    }

    const isPresetSelected = (month, year) => {
        const firstDay = moment(year + "-" + month + "-01").startOf('month').format('YYYY-MM-DD')
        const lastDay =  moment(year + "-" + month + "-01").endOf('month').format('YYYY-MM-DD')

        if (from === firstDay && lastDay === to) {
            return true
        }

        return false
    }
    return (
        <div>
            <div style={{ marginBottom: "0.5rem", cursor: "pointer" }} onClick={ () => setVisible(!visible) }>
                <Icon name={ !visible ? "chevron-forward-outline" : "chevron-down-outline"} style={{ marginRight: "0.5rem" }}/>
                <span style={{ position: "relative", top: "-0.2rem" }}>
                    { t('show_date_presets') }
                </span>
            </div>


            { visible &&
                <>
                    <div style={{ marginBottom: "0.5rem" }}>
                        { t('selected_year') }:
                        <Dropdown
                            style={{ marginLeft: "0.5rem" }}
                            inline
                            options={[
                                { key: 1, value: "2012", text: "2012" },
                                { key: 2, value: "2013", text: "2013" },
                                { key: 3, value: "2014", text: "2014" },
                                { key: 4, value: "2015", text: "2015" },
                                { key: 5, value: "2016", text: "2016" },
                                { key: 6, value: "2017", text: "2017" },
                                { key: 7, value: "2018", text: "2018" },
                                { key: 8, value: "2019", text: "2019" },
                                { key: 9, value: "2020", text: "2020" },
                                { key: 10, value: "2021", text: "2021" },
                                { key: 11, value: "2022", text: "2022" },
                            ]}
                            value={year.toString()}
                            onChange={ (e, { value }) => setYear(value)}
                        />
                    </div>

                    <Button.Group basic size="small" fluid style={{ borderRadius: 0 }}>
                        <Button type="button" active={isPresetSelected(1, year)} onClick={() => handlePresetSelection(1, year)}>
                            { t('january') }
                        </Button>
                        <Button type="button" active={isPresetSelected(2, year)} onClick={() => handlePresetSelection(2, year)}>
                            { t('february') }
                        </Button>
                        <Button type="button" active={isPresetSelected(3, year)} onClick={() => handlePresetSelection(3, year)}>
                            { t('march') }
                        </Button>
                        <Button type="button" active={isPresetSelected(4, year)} onClick={() => handlePresetSelection(4, year)}>
                            { t('april') }
                        </Button>
                        <Button type="button" active={isPresetSelected(5, year)} onClick={() => handlePresetSelection(5, year)}>
                            { t('may') }
                        </Button>
                        <Button type="button" active={isPresetSelected(6, year)} onClick={() => handlePresetSelection(6, year)}>
                            { t('june') }
                        </Button>
                    </Button.Group>
                    <Button.Group basic size="small" fluid style={{ marginTop: "0.5rem", borderRadius: 0 }}>
                        <Button type="button" active={isPresetSelected(7, year)} onClick={() => handlePresetSelection(7, year)}>
                            { t('july') }
                        </Button>
                        <Button type="button" active={isPresetSelected(8, year)} onClick={() => handlePresetSelection(8, year)}>
                            { t('august') }
                        </Button>
                        <Button type="button" active={isPresetSelected(9, year)} onClick={() => handlePresetSelection(9, year)}>
                            { t('september') }
                        </Button>
                        <Button type="button" active={isPresetSelected(10, year)} onClick={() => handlePresetSelection(10, year)}>
                            { t('october') }
                        </Button>
                        <Button type="button" active={isPresetSelected(11, year)} onClick={() => handlePresetSelection(11, year)}>
                            { t('november') }
                        </Button>
                        <Button type="button" active={isPresetSelected(12, year)} onClick={() => handlePresetSelection(12, year)}>
                            { t('december') }
                        </Button>
                    </Button.Group>
                </>
            }
        </div>
    );
};

export default DatePresets;