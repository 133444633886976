import React from 'react';
import { useTranslation } from "react-i18next"
// components
import Icon from '@components/Icon';
import { Button, Label, Container } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module components
import BenefitsForm from '../forms/BenefitsForm';
import BenefitAssignee from '../BenefitAssignee';
import CanView from '@components/perms/CanView';


const BenefitsTable = (props) => {
    const { t } = useTranslation();

    const TableAction = ({ row }) => {

        return (
            <CanView permissions={["benefits.c_manage_all_benefits"]}>
                <Button.Group>
                    <SuperDuperModal
                        size='small'
                        header={t('update_benefit')}
                        trigger={
                            <Button
                                icon={<Icon name="pencil-outline"/>}
                                style={{
                                    background: "none",
                                    color: "var(--dark)"
                                }}
                            />
                        }
                        content={
                            <BenefitsForm
                                benefits={row}
                                categories={props.categories}
                                onConfirm={(newItem) => props.updateBenefit(newItem)}
                            />
                        }
                    />
                    <ConfirmModal
                        button={
                            <Button
                                icon={<Icon name="close-outline"/>}
                                style={{
                                    background: "none",
                                    color: "var(--danger)"
                                }}
                            />
                        }
                        description={t('confrim_message_delete_benefit')}
                        onConfirm={() => props.onDelete(row.id)}
                    />
                </Button.Group>
            </CanView>
        );
    }

    const EmployeeList = ({ row }) => {

        return (
            <SuperDuperModal
                trigger={
                    <Button basic>
                        <Icon name="people-outline" style={{ fontSize: "1.2rem", marginRight: "0.5rem" }}/>
                        <span style={{ position: "relative", top: "-0.2rem" }}>
                            { row.employees }
                        </span>
                    </Button>
                }
                size='tiny'
                header={row.name}
                content={ <BenefitAssignee row={row} />}
            />
        );
    }

    return (
        <Container fluid>
        <FlexTable>
            <FlexRow>
                <FlexHeader basis="100%" content={t('name')} />
                <FlexHeader basis="100%" content={t('category')} />
                <FlexHeader content={t('icon')} />
                <FlexHeader basis="100%" content={t('assigned_to')} />
                <FlexHeader basis="50%" content={t('actions')} />
            </FlexRow>
            <EmptyRow length={props.data.length} />
            {props.data?.map((benefit) => (
                <FlexRow key={benefit.id} fontSize="0.9rem" borders>
                    <FlexItem basis="100%" content={benefit.name} />
                    <FlexItem basis="100%" content={benefit.category_id && <Label style={{ background: benefit.category_color, color: "white", fontWeight:"normal" }}>{benefit.category}</Label>} />
                    <FlexItem><Icon name={benefit?.icon || ""} /></FlexItem>
                    <FlexItem basis="100%" content={<EmployeeList row={benefit} />} />
                    <FlexItem basis="50%" content={<TableAction row={benefit} />} />
                </FlexRow>
            ))}
        </FlexTable>
        </Container>
    );
};

export default BenefitsTable;