import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { routes } from '@store/routes';
import { useFetchData } from '@helpers/hooks';
// components
import { Container, Grid } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import BreadcrumbNav from '@components/BreadcrumbNav';
// module specific comps
import ComponentChoices from '../components/apply-forms/ComponentChoices';
import ApplyFormList from '../components/apply-forms/ApplyFormList';
import FormView from '../components/apply-forms/FormView';

const ApplyForms = () => {
    const { t } = useTranslation();

    // component states
    // eslint-disable-next-line
    const [result, setResult] = useFetchData(API.APPLICATION + "form_configs/")
    // eslint-disable-next-line
    const [selected, setSelected] = useState([]) // selected fields
    const [applyForm, setApplyForm] = useState({})

    const breadcrumb_items = [
        { 'name': t('jobs'), 'icon': icons.JOBS, 'href': routes.JOBS },
        { 'name': t('apply_forms_templates'), 'icon': '', 'href': '' }
    ]

    useEffect(() => {
        if( applyForm?.fields ){
            let fields = []
            for(let i = 0; i < applyForm.fields.length; i++){
                fields.push(applyForm.fields[i].name)
            }
            setSelected(fields)
        }
    }, [applyForm])

    return (
        <CanView permissions={["jobs.c_view_application_forms"]} redirect>
            <BreadcrumbNav items={breadcrumb_items}/>
            { result.isLoading ? <Loading/> :
                <Container fluid>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column computer={applyForm?.id || applyForm?.id === 0 ? 12 : 16}>
                                { applyForm?.id || applyForm?.id === 0
                                    ?
                                        <FormView
                                            selected={selected}
                                            setSelected={setSelected}
                                            applyForm={applyForm}
                                            setApplyForm={setApplyForm}
                                            result={result}
                                            setResult={setResult}
                                        />
                                    :
                                        <ApplyFormList
                                            result={result}
                                            setResult={setResult}
                                            setApplyForm={setApplyForm}
                                        />
                                }

                            </Grid.Column>

                            <Grid.Column
                                computer={4}
                                style={{ display: applyForm?.id || applyForm?.id === 0 ? "block" : "none", marginTop: "1rem" }}
                            >
                                <ComponentChoices
                                    selected={selected}
                                    setSelected={setSelected}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            }
        </CanView>
    );
};

export default ApplyForms;