import { APIRequest } from './helpers/requests';
import { API } from "../config";

export const candidatesService = {
    getInteractions,
    getCandidates,
    getCandidatesRecruitments,
    createCandidate,
    assignCandidate,
    getRejectedCandidates,
    deleteRejected,
    getStages,
    createStages,
    createProcess,
    updateCandidate,
    updateSkill,
    getHiringRooms,
    deleteCandidate,
    addCandidateToHiringRoom,
    addManager,
    uploadCV,
};

async function getInteractions(params) {
    params = params || ""
    return await APIRequest({
        url: API.CANDIDATES_INTERACTIONS + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function uploadCV(id, data) {
    return await APIRequest({
        url: API.CANDIDATES + id + "/cv/",
        method: 'POST',
        data: data,
        private: true
    });
}

async function addCandidateToHiringRoom(id, data) {
    return await APIRequest({
        url: API.CANDIDATES + id + "/assign_to_recruitment/",
        method: 'POST',
        data: data,
        private: true
    });
}

async function deleteCandidate(id) {
    return await APIRequest({
        url: API.CANDIDATES + id,
        method: "DELETE",
        data: {},
        private: true
    })
}

async function getHiringRooms(params) {
    params = params || ""
    return await APIRequest({
        url: API.HIRING_ROOM + params,
        method: 'GET',
        data: {},
        private: true
    });
}
async function createProcess(data) {
    return await APIRequest({
        url: API.PROCESSES,
        method: 'POST',
        data: data,
        private: true
    });
}

async function createStages(data) {
    return await APIRequest({
        url: API.CREATE_STAGES,
        method: 'POST',
        data: data,
        private: true
    });
}

async function getStages() {
    return await APIRequest({
        url: API.STAGES,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getCandidates(params) {
    params = params || ""
    return await APIRequest({
        url: API.CANDIDATES + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getRejectedCandidates(params) {
    params = params || ""
    return await APIRequest({
        url: API.REJECTED + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function deleteRejected(id, data) {
    return await APIRequest({
        url: API.REJECTED + id + "/delete/",
        method: "POST",
        data: data,
        private: true
    })
}

async function addManager(data) {
    return await APIRequest({
        url: API.MANAGERS,
        method: "POST",
        data: data,
        private: true
    })
}

async function getCandidatesRecruitments(id, params) {
    params = params || ""
    return await APIRequest({
        url: API.HIRING_ROOM + id + "/" + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function createCandidate(params) {

    const phone = await APIRequest({
        url: API.PHONES,
        method: 'POST',
        data: {
            "phone": params.phone,
            "phone_type": params.phoneType || null
        },
        private: true
    })


    const email = await APIRequest({
        url: API.EMAIL,
        method: 'POST',
        data: { "email": params.email },
        private: true
    })

    if (phone.status === 201) {

        let contactData = {}
        if (params.email !== "" && email.status === 201) {
            contactData = {
                "phones": {
                    "add": [phone.response.id]
                },
                "emails": {
                    "add": [email.response.id]
                }
            }
        } else if (params.email === "") {
            contactData = {
                "phones": {
                    "add": [phone.response.id]
                }
            }
        } else {
            return email.response.email;
        }


        const contact = await APIRequest({
            url: API.CONTACT_INTO,
            method: 'POST',
            data: contactData,
            private: true
        });


        if (contact.status === 201) {

            const person = await APIRequest({
                url: API.PERSONS,
                method: 'POST',
                data: {
                    "firstname": params.firstname,
                    lastname: params.lastname,
                    gender: params.gender,
                },
                private: true
            })

            const birthInfo = await APIRequest({
                url: API.BIRTH_INFO,
                method: 'POST',
                data: {
                    date_of_birth: params.dateOfBirth
                },
                private: true
            })


            if (person.status === 201 && birthInfo.status === 201) {

                const personalInfo = await APIRequest({
                    url: API.PERSONAL_INFORMATION_candidate,
                    method: 'POST',
                    data: {
                        birth_info: birthInfo.response.id,
                        person: person.response.id,
                        contact_info: contact.response.id,
                        marital_status: 1,

                    },
                    private: true
                })


                if (personalInfo.status === 201) {
                    return await APIRequest({
                        url: API.CANDIDATES,
                        method: 'POST',
                        data: {
                            personal_information: personalInfo.response.id,
                        },
                        private: true
                    })

                } else {
                    return personalInfo.response;
                }
            } else if (person.status === 400) {
                return person.response.firstname ? person.response.firstname : person.response.lastname;

            } else if (birthInfo.status !== 201) {
                return birthInfo.response.date_of_birth;

            }
        }
    } else {
        return phone.response.phone_type;
    }
}

async function updateCandidate(params, candidateId) {

    const phone = await APIRequest({
        url: API.PHONES + params.phoneId + "/",
        method: 'PATCH',
        data: {
            "phone": params.phone,
            "phone_type": params.phoneType
        },
        private: true
    })

    if (params.emailId !== 0) {
        await APIRequest({
            url: API.EMAIL + params.emailId + "/",
            method: 'PATCH',
            data: { "email": params.email },
            private: true
        })
    } else {
        if( params.email ){
            const email = await APIRequest({
                url: API.EMAIL,
                method: 'POST',
                data: { "email": params.email },
                private: true
            })
            if (email.status === 201) {
                await APIRequest({
                    url: API.CONTACT_INTO + params.contactId + "/",
                    method: 'PATCH',
                    data: {
                        "emails": {
                            "add": [email.response.id]
                        }
                    },
                    private: true
                });
            }
        }
    }

    if (phone.status === 200 ) {
            const person = await APIRequest({
                url: API.PERSONS + params.personId + "/",
                method: 'PATCH',
                data: {
                    firstname: params.firstname,
                    lastname: params.lastname,
                    gender: params.gender,
                },
                private: true
            })

            if (person.status === 200) {
                let birthInfo = null
                if( params.birthId === 0 ){
                    birthInfo = await APIRequest({
                        url: API.BIRTH_INFO,
                        method: 'POST',
                        data: {
                            date_of_birth: params.dateOfBirth
                        },
                        private: true
                    })
                } else {
                    birthInfo = await APIRequest({
                        url: API.BIRTH_INFO + params.birthId + "/",
                        method: 'PATCH',
                        data: {
                            date_of_birth: params.dateOfBirth
                        },
                        private: true
                    })
                }

                if( birthInfo.status === 201 || birthInfo.status === 200 ){
                    const candidate = await APIRequest({
                        url: API.CANDIDATES + candidateId + "/",
                        method: 'GET',
                        private: true
                    })

                    if( candidate.status === 200 ){
                        const personalInfo = await APIRequest({
                            url: API.PERSONAL_INFORMATION + candidate.response.personal_information.id + "/",
                            method: 'PATCH',
                            data: {
                                birth_info: birthInfo.response.id,
                            },
                            private: true
                        })

                        if( personalInfo.status === 200 ){
                            let updatedCandidate = candidate.response

                            updatedCandidate.personal_information = personalInfo.response

                            return {
                                status: 200,
                                response: updatedCandidate
                            }
                        }
                    }
                }

            } else if (person.status === 400) {
                return person.response.firstname ? person.response.firstname : person.response.lastname;
            } 
        
    } else {
        return phone.response.phone_type;
    }
}
async function updateSkill(data, id) {
    return await APIRequest({
        url: API.CANDIDATES + id + '/',
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function assignCandidate(id, room) {

    return await APIRequest({
        url: API.CANDIDATES + id + '/assign_to_recruitment/',
        method: 'POST',
        data: {
            "recruitment": room
        },
        private: true
    });
}

