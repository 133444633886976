import React, { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperFieldSet from '@components/forms/SuperFieldSet';
import AddressFieldGroup from '@components/forms/common/AddressFieldGroup';

const TemporaryAddress = ({ setData }) => {
    const { t } = useTranslation();

    const [address, setAddress] = useState({
        street: "",
        city: "",
        postcode: "",
        country: "",
        state: "",
        address_line: "",
    })

    useEffect( () => {
        let temporaryAddress = null;
        if( address.street !== "" && address.city !== "" && address.postcode !== "" ){
            temporaryAddress = address
        }

        setData(prevState => ({
            ...prevState,
            temporary_address: temporaryAddress
        }))

    }, [address, setData])

    return (
        <SuperFieldSet legend={ t('temp') }>
            <AddressFieldGroup address={address} setAddress={setAddress}/>
        </SuperFieldSet>
    );
};

export default TemporaryAddress;