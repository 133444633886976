import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import Heading from '../components/table/Heading';
import List from '../components/table/List'
const IncidentList = ({ loading, data, handleDelete, handleUpdate }) => {

    return (
        <Segment style={{ margin: '1rem', border: 'none', boxShadow: 'none' }} loading={loading} as={Grid}>
            <Heading />
            <List data={data} handleDelete={handleDelete} handleUpdate={handleUpdate} />
        </Segment>
    );
};

export default IncidentList;