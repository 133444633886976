import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
// components
import { Divider, Form, Icon, Header } from 'semantic-ui-react';
import DateField from '../../../../../../../components/forms/common/DateField';
import RabotaCitiesField from './fields/RabotaCitiesField';
import ChoiceField from '@components/forms/common/ChoiceField'
import SuperField from '@components/forms/SuperField'
import { FlexRow, FlexItem } from '@components/tables/FlexTable'

const RabotaPublicationForm = ({ offerId, setData, setIsDisabled }) => {
    const { t } = useTranslation();
    const [rabotaData, setRabotaData] = useState({
        is_active: true,
        is_featured: false,
        valid_until: "",
        application_form_config: null,
        // publish_when: "", // TODO: add dateField with state mng.
        job_offer: offerId,
        resourcetype: "Rabota_uaJobPublication",
        endingType: 1,
        publishType: "Business",
        isAskAttachExperience: false,
        isHideInProfile: false,       // hide
        isNeedSearchTemplate: false, // hide
        isShowMap: false,             // hide
        cityId: 34,
        rubricList: null,
        salary: 0,
        salaryDescr: ""
    })

    const [hideConfig, setHideConfig] = useState(false)

    useEffect( () => {
        setData(rabotaData)

        // eslint-disable-next-line
    }, [rabotaData])

    useEffect(() => {
        let isValid = true
        if(
            rabotaData.valid_until === "" ||
            rabotaData.valid_until === null ||
            rabotaData.rubricList === null ||
            rabotaData.rubricList === "" ||
            rabotaData.rubricList.length === 0 ||
            rabotaData.cityId === "" ||
            rabotaData.cityId === null
        ){
            isValid = false
        }

        setIsDisabled(!isValid)
        // eslint-disable-next-line
    }, [rabotaData])


    return (
        <>
        <Form.Group widths='equal'>
            <Form.Field>
                <DateField
                    required
                    startMode="year"
                    label={t('valid_until')}
                    value={rabotaData.valid_until}
                    onChange={(e, data) => setRabotaData({
                        ...rabotaData,
                        valid_until: data.value
                    })}
                />
            </Form.Field>
            <SuperField as="choice"
                endpoint={API.APPLICATION + "form_configs/"}
                text="name"
                label={ t('application_form') }
                value={ rabotaData.application_form_config }
                onChange={(e, { value }) => setRabotaData({
                    ...rabotaData,
                    application_form_config: value
                })}
            />
        </Form.Group>
        <Form.Field>
            <Form.Checkbox
                style={{ marginTop: "1rem" }}
                label={t('mark_as_featured')}
                checked={ rabotaData.is_featured }
                onChange={ () => setRabotaData({
                    ...rabotaData,
                    is_featured: !rabotaData.is_featured
                })}
            />
        </Form.Field>

        <FlexRow padding="1rem" borders>
            <FlexItem>
                <h2>
                    Rabota.ua {t('specific_configuration')}
                </h2>
            </FlexItem>
            <FlexItem textAlign="right">
                <Icon onClick={ () => setHideConfig(!hideConfig) } style={{ cursor: "pointer" }} name={hideConfig ? "angle down" : "angle up"}/>
            </FlexItem>
        </FlexRow>
        <div style={{ display: hideConfig ? "none" : "block" }}>
            <Form.Group style={{ marginTop: "1rem" }} widths="equal">
                <Form.Field>
                    <RabotaCitiesField
                        value={ rabotaData.cityId }
                        setValue={ (e, { value }) => setRabotaData({
                            ...rabotaData,
                            cityId: value
                        }) }
                    />
                </Form.Field>

                <Form.Field>
                    <ChoiceField
                        search
                        required
                        multiple
                        label={t('rubric_list')}
                        endpoint={API.INTEGRATIONS + "rabota_ua/rubrics/"}
                        text="rubricNameEng"
                        value={ rabotaData.rubricList }
                        onChange={ (e, {value}) => setRabotaData({
                            ...rabotaData,
                            rubricList: value
                        }) }
                    />
                </Form.Field>

            </Form.Group>

            <Divider/>

            <Header style={{ marginBottom: 0 }} as="h3" content={ t('additional_information') }/>

            <FlexRow background="transparent" padding="0">
                <FlexItem>
                    <Form.Checkbox
                        style={{ marginTop: "2.2rem" }}
                        label={t('ask_to_attach_experience')}
                        checked={ rabotaData.isAskAttachExperience }
                        onChange={ () => setRabotaData({
                            ...rabotaData,
                            isAskAttachExperience: !rabotaData.isAskAttachExperience
                        })}
                    />

                    <Form.Input
                        size="small"
                        label={t('salary') + " (in UAH)"}
                        value={ rabotaData.salary }
                        onChange={ (e, { value }) => setRabotaData({
                            ...rabotaData,
                            salary: value
                        })}
                    />


                    <Form.TextArea
                        size="small"
                        label={t('salary') + " " + t('description')}
                        value={ rabotaData.salaryDescr }
                        onChange={ (e, { value }) => setRabotaData({
                            ...rabotaData,
                            salaryDescr: value
                        })}
                    />
                </FlexItem>

                    {/* <Form.Checkbox s
                        label={t('hide_in_profile')}
                        checked={ rabotaData.isHideInProfile }
                        onChange={ () => setRabotaData({
                            ...rabotaData,
                            isHideInProfile: !rabotaData.isHideInProfile
                        })}
                    />
                    <Form.Checkbox
                        label={t('need_search_template')}
                        checked={ rabotaData.isNeedSearchTemplate }
                        onChange={ () => setRabotaData({
                            ...rabotaData,
                            isNeedSearchTemplate: !rabotaData.isNeedSearchTemplate
                        })}
                    />
                    <Form.Checkbox
                        label={t('show_on_map')}
                        checked={ rabotaData.isShowMap }
                        onChange={ () => setRabotaData({
                            ...rabotaData,
                            isShowMap: !rabotaData.isShowMap
                        })}
                    /> */}
            </FlexRow>

        </div>


        </>
    );
};

export default RabotaPublicationForm;