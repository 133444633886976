import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { jobsService } from '../../../../../../../../store/services/ServiceJobs';
// components
import { Form } from 'semantic-ui-react';

const RabotaCitiesField = ({ value, setValue, disabled, ...rest }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);

    useEffect( () => {
        async function getChoices(){
            setLoading(true);

            const result = await jobsService.getRabotaCities();

            if(result.status === 200){
                let optionsArray = []
                for (let i = 0; i < result.response.length; i++) {
                    optionsArray.push({ key: i + 1, text: result.response[i].nameEng, value: result.response[i].id });

                }
                setOptions(optionsArray);
            }
            setLoading(false);
        }
        getChoices();
    }, [])


    return (
        <Form.Field>
            <Form.Select
                {...rest}
                fluid
                scrolling
                search
                loading={loading}
                disabled={disabled || loading}
                label={t('select_city')}
                value={value}
                options={options}
                placeholder={t('select_city')}
                onChange={setValue}
            />
        </Form.Field>
    );
};

export default RabotaCitiesField;