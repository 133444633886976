import React from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
import { Grid, Divider, Label } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';
// others
import moment from 'moment';

function ProfileHeader({ data }) {
    const { t } = useTranslation();

    const uploadImage = async (image) => {
        const formData = new FormData();
        formData.append("avatar", image);

        return await requests.post(API.SET_AVATAR, formData);
    }

    const deleteImage = async () => {
        return await requests.del(API.DELETE_AVATAR);
    }

    return (
        <Grid centered className="header-block">
            <Grid.Column computer={6} tablet={6} mobile={16}>
                <AvatarIcon src={data.avatar} size={200} name={data.name || "test"} handleUpload={uploadImage} handleDelete={deleteImage} uploadable={true} />
            </Grid.Column>
            <Grid.Column computer={10} tablet={10} mobile={16} style={{ paddingTop: "5rem" }}>
                <h1>{data.name}</h1>
                <i style={{ margin: "1rem" }}> | {t('joined_text')} <strong>{moment(data.date_joined).format("DD.MM.YYYY")}</strong></i>
                <Divider />
                <Grid columns={2} doubling>
                    <Grid.Row>
                        <Grid.Column>
                            <h3>{t('role')}</h3>
                            <Label style={{ backgroundColor: "var(--black)", color: "var(--white)" }}>{data.role_name}</Label>
                        </Grid.Column>
                        <Grid.Column>
                            <h3>{t('permission_group')}</h3>
                            <h3>---</h3>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Grid.Column>
        </Grid>
    );
}

export default ProfileHeader;