import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import AvatarIcon from '@components/AvatarIcon';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import ConfirmModal from '@components/modals/ConfrimModal';
import { Divider, Icon as SemanticIcon, Button, Loader } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import UserForm from './UserForm';
import PasswordForm from './PasswordForm';
import Action from '@components/general/Action';

const getParams = (urlPath) => {
    if (urlPath === null || urlPath === undefined) return null;

    const url = new URL(urlPath)
    return url.search
}

const UsersTable = ({ result, setResult, fetchUsers }) => {
    const { t } = useTranslation();
    const user_id = useSelector(state => state.user.id)

    const previous = getParams(result.data.previous)
    const next = getParams(result.data.next)


    function getColor(type) {
        switch (type) {
            case 1: return 'var(--black)'
            case 2: return 'var(--primary)'
            case 3: return 'var(--blue)'
            case 4: return 'var(--info)'
            default: return 'var(--black)'
        }
    }

    const UserStatusAction = ({ user }) => {
        const [processing, setProcessing] = useState(false)

        const setUserActiveStatus = async (user, status) => {
            setProcessing(true)
            const request = await requests.patch(API.USERS + user.id + "/", {
                is_active: status
            });

            if (request.status === 200) {
                setResult({
                    ...result,
                    data: {
                        ...result.data,
                        results: result.data.results.filter((updatedUser) => {
                            if (user.id === updatedUser.id) {
                                updatedUser.is_active = status
                            }

                            return updatedUser;
                        })
                    }
                })
            }

            setProcessing(false)
        }

        return (
            <>
                {
                    processing
                        ? <Loader size="small" className='dark-loader' active inline />
                        :
                        <>
                            {user.id && user_id !== user.id &&
                                <>
                                    {user.is_active ?
                                        <FlexItem>
                                            <ConfirmModal
                                                description={t('deactive')}
                                                onConfirm={() => setUserActiveStatus(user, false)}
                                                button={
                                                    <Icon name="ban-outline" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                }
                                            />
                                        </FlexItem>
                                        :
                                        <FlexItem>
                                            <ConfirmModal
                                                description={t('activate')}
                                                onConfirm={() => setUserActiveStatus(user, true)}
                                                button={
                                                    <Icon name="checkmark-circle-outline" style={{ color: "var(--success)", cursor: "pointer" }} />
                                                }
                                            />
                                        </FlexItem>
                                    }
                                </>
                            }
                        </>
                }

            </>
        )
    }

    return (
        <>
            <Divider style={{ marginTop: 0 }} />
            <FlexTable>
                <FlexRow>
                    <FlexHeader basis="150%" content={t('user')} />
                    <FlexHeader content={t('email')} />
                    <FlexHeader content={t('profile')} />
                    <FlexHeader content={t('date_joined')} />
                    <FlexHeader content={t('is_active')} />
                    <FlexHeader content={t('actions')} />
                </FlexRow>

                <EmptyRow length={result.data.results.length} />

                {result.data.results.map(user => (
                    <FlexRow key={user.id} fontSize="0.9rem" borders>
                        <FlexItem basis="150%">
                            <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                <FlexItem basis="10%">
                                    <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                        <AvatarIcon size={30} name={user.username} src={user.profile_picture} />
                                    </SemanticIcon.Group>
                                </FlexItem>
                                <FlexItem>
                                    <strong style={{ fontSize: "1.2rem" }}>{user.username}</strong><br />
                                    <strong style={{ color: getColor(user.role) }}>
                                        {user.role_name}
                                    </strong>
                                </FlexItem>
                            </FlexRow>
                        </FlexItem>

                        <FlexItem content={user.email} />
                        <FlexItem>
                            {/* Employee */}
                            {(user.role === 3 && (user?.profile !== null && user?.profile !== undefined)) && (
                                <Link to={routes.EMPLYOEES_DETAIL + user.profile.id} target="_blank">
                                    {user.profile.name}
                                </Link>
                            )}

                            {/* Candidate */}
                            {(user.role === 2 && (user?.profile !== null && user?.profile !== undefined)) && (
                                // <Link  to={routes.EMPLYOEES_DETAIL + user.profile.id} target="_blank">
                                <span> {user.profile.name} </span>
                                // </Link>
                            )}

                            {/* Account */}
                            {(user.role === 4 && (user?.account !== null && user?.account !== undefined)) && (
                                <Link to={routes.ACCOUNTS_DETAIL + user.account.id} target="_blank">
                                    {user.account.name}
                                </Link>
                            )}
                        </FlexItem>
                        <FlexItem content={user.date_joined} />

                        <FlexItem>
                            <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                <FlexItem basis="10%">
                                    <Icon
                                        name='ellipse'
                                        style={{ color: user.is_active ? 'green' : "red", fontSize: "1rem", marginRight: "0.5rem" }}
                                    />
                                </FlexItem>
                                <FlexItem>
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        {user.is_active ? t('active_him') : t('inactive_him')}
                                    </span>
                                </FlexItem>
                            </FlexRow>
                        </FlexItem>

                        <FlexItem>
                            <FlexRow background="transparent" padding="0">
                                <CanView permissions={['users.c_change_users']}>
                                    <UserStatusAction user={user} />
                                    <FlexItem>
                                        <SuperDuperModal
                                            trigger={<Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }} />}
                                            content={<UserForm user={user} setResult={setResult} />}
                                        />
                                    </FlexItem>
                                    <CanView permissions={['users.c_manage_user_passwords']}>
                                        <FlexItem>
                                            <Action
                                                as="modal"
                                                type="icon"
                                                icon="lock-closed-outline"
                                                modalSize="small"
                                                tooltip={t('password_change')}
                                                modal={
                                                    <PasswordForm user={user} />
                                                }
                                            />
                                        </FlexItem>
                                    </CanView>
                                </CanView>
                            </FlexRow>
                        </FlexItem>
                    </FlexRow>
                ))}
            </FlexTable>
            <FlexRow>
                <FlexItem basis="50%">
                    <Button.Group>
                        <Button disabled={previous === null} primary onClick={previous !== null ? () => fetchUsers(previous) : null}> <Icon name="chevron-forward-outline"/> </Button>
                        <Button disabled={next === null} primary onClick={next !== null ? () => fetchUsers(next) : null}> <Icon name="chevron-back-outline"/> </Button>
                    </Button.Group>
                </FlexItem>
            </FlexRow>
        </>
    );
};

export default UsersTable;