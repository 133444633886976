import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
// store
import { API } from '@store/config';
import { createRequest } from '@services/ServiceCommon';
// components
import { Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';
import ApprovalForm from '../../../../core/approvals/components/ApprovalForm';

export const MoveStageForm = ({ stages, isDown, jumpStage, current, onClose, onConfirm, candidateID, roomID, selectedCandidates }) => {
    const { t } = useTranslation();
    const params = useParams()

    const roomId = roomID ? roomID : parseInt(params.room)
    const candidateId = candidateID ? candidateID : parseInt(params.candidate)

    const findStage = (current) => {
        const currentIndex = stages.findIndex(item => item.id === current.id)
        if (isDown) {
            return stages[currentIndex - 1]?.id
        } else {
            return stages[currentIndex + 1]?.id
        }

    }

    const [note, setNote] = useState('');

    const [stage, setStage] = useState(current ? jumpStage ? current.id : findStage(current) : '');

    const [requestPlaced, setRequestPlaced] = useState({
        visible: false,
        detail: ""
    })
    const [approval, setApproval] = useState(null)
    const [approvals, setApprovals] = useState([])
    const [isPerson, setIsPerson] = useState(false)

    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errors, setErrors] = useState([]); // empty array

    const submitCandidate = async (candidate) => {
        const id = candidate
        const request = await createRequest(API.CANDIDATES + id + '/move_stage/', {
            note: note,
            archived_recruitment_stage: stage,
            recruitment: roomId
        })

        if (request.status === 200) {
            onConfirm(request.response)
            onClose()
        } else if (request.status === 201) {
            setApproval(request.response.approval)
            setRequestPlaced({
                visible: true,
                detail: request.response.detail
            })

            if (request.response.approval?.responsible_person !== undefined) {
                if (request.response.approval.responsible_person !== null) {
                    onClose()
                }
            } else {
                onClose()
            }
        } else {
            const error_message = request.response.detail;
            setErrors([...errors, error_message]);
        }
    }

    const handleSubmit = async () => {
        setIsProcessing(true);

        if (!selectedCandidates) {
            submitCandidate(candidateId)
        }
        else {
            for (let i = 0; i < selectedCandidates.length; i++) {
                let id = null
                id = selectedCandidates[i].id
                let request = null;
                request = await createRequest(API.CANDIDATES + id + '/move_stage/', {
                    note: note,
                    archived_recruitment_stage: jumpStage ? stage : findStage(selectedCandidates[i].current_stage),
                    recruitment: roomId
                })

                if (request.status === 200) {
                    onConfirm(selectedCandidates[i].id, selectedCandidates[i].current_stage.id, jumpStage ? stage : findStage(selectedCandidates[i].current_stage), request.response)
                    onClose()
                }
                else if (request.status === 201) {
                    let approvals_response = null;
                    approvals_response = request.response.approval;
                    setApprovals(prevState => ([...prevState, approvals_response]))
                    setRequestPlaced({
                        visible: true,
                        detail: request.response.detail
                    })
                    if (request.response.approval.responsible_person !== null) {
                        setIsPerson(true)
                    }
                }
                else if (request.status === 400) {
                    const error_message = request.response.detail
                    setErrors([...errors, error_message]);
                }
            }
        }

        setIsProcessing(false);
    }

    return (
        <>
            {requestPlaced.visible &&
                <Message
                    success
                    visible={requestPlaced.visible}
                    icon="check circle outline"
                    content={requestPlaced.detail}
                />
            }

            {approval === null && (approvals.length === 0 || isProcessing)
                ?
                <Form onSubmit={handleSubmit}>
                    <Message
                        error
                        visible={errors.length > 0}
                        header={t('error_submission_message')}
                        list={errors}
                    />

                    {jumpStage &&
                        <SuperField as="choice"
                            required
                            placeholder={t('select_stage')}
                            customOptions={stages.map(item => (
                                { key: item.id, text: item.name, value: item.id }
                            ))}
                            label={t('select_stage')}
                            value={stage}
                            onChange={(e, { value }) => setStage(value)}
                        />
                    }

                    <SuperField as="textarea"
                        label={t('note')}
                        value={note}
                        onChange={(e, { value }) => setNote(value)}
                    />

                    <Form.Field style={{ textAlign: "right", margin: "1rem" }}>
                        <ModalCancel onClose={onClose} disabled={isProcessing} />
                        <ModalSubmit
                            disabled={isProcessing}
                            loading={isProcessing}
                            text={t('confirm')}
                        />
                    </Form.Field>
                </Form>
                : (approval?.responsible_person === null || approval?.responsible_person === undefined || isPerson === false) && (
                    <ApprovalForm
                        approval={approval}
                        approvals={approvals ? approvals : null}
                        onClose={onClose}
                        permission="candidates.c_manage_candidates_in_recruitment"
                    />
                )
            }
        </>
    )
}

