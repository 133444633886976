import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { icons } from '@icons';
// components
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Segment, Sidebar } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
// module specific
import ContactDetail from './ContactDetail';
import ContactsListing from './ContactsListing';

const Contacts = () => {
    const { t } = useTranslation()
    const breadcrumb_items = [
        { 'name': t("contacts"), 'icon': icons.ORDERS }
    ]
    const [detail, setDetail] = useState(null)

    return (
        <CanView permissions={['contacts.c_view_contacts']}>
            <BreadcrumbNav items={breadcrumb_items} />
            <Sidebar.Pushable as={Segment} style={{ background: "transparent", border: "none", boxShadow: "none", padding: 0, margin: 0}}>
                <Sidebar
                    style={{ width: "40%", minWidth: "500px", background: "var(--white)" }}
                    animation='overlay'
                    icon='labeled'
                    onHide={() => setDetail(null)}
                    visible={detail !== null}
                    direction="right"
                >
                    <ContactDetail data={detail} close={() => setDetail(null)} />
                </Sidebar>
                <Sidebar.Pusher>
                    <ContactsListing openDetail={(e) => setDetail(e)} />
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </CanView>
    );
};

export default Contacts;