import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//other
import SuperField from '@components/forms/SuperField';
import { Form, Button, Message, Grid } from 'semantic-ui-react';
import ContactInfoView from '@components/common/contact-info/ContactInfoView';


function ProfileCardHeaderForm({data, onClose, onConfirm, patchEndpoint}) {
    const { t } = useTranslation();

    const personal_information_data = {
        custom_id_prefix: data?.personal_information?.custom_id_prefix ||  "",
        custom_id: data?.personal_information?.custom_id ||  "",
        id_card_number: data?.personal_information?.id_card_number || "",
    }

    const [form, setForm] = useState({
        unit: data?.unit?.id || null,
        expected_start_date: data?.expected_start_date || null,
        personal_information: personal_information_data
    })

    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {

        setIsProcessing(true);
        if (data.personal_information.id_card_number === form.personal_information.id_card_number) {
            delete form.personal_information.id_card_number
        }
        const request = await requests.patch(patchEndpoint + `${data?.id}/`, form)

        if (request.status === 200) {
            onConfirm(request.response)
            onClose()
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false);
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column mobile={10} tablet={10} computer={10}>
                    <ContactInfoView
                        isProfile
                        updateState={onConfirm}
                        permissions={{
                            view: 'common.c_view_all_employees',
                            manage: 'common.c_manage_all_employees',
                            delete: 'common.c_manage_all_employees',
                        }}
                        data={data?.personal_information?.contact_info}
                        endpoint={patchEndpoint + data?.id + "/"}
                    />
                </Grid.Column>
                <Grid.Column mobile={6} tablet={6} computer={6}>
                <Form onSubmit={handleSubmit}>
                    <Message
                        error
                        visible={errors?.non_field_errors?.length > 0}
                        header={t("error_submission_message")}
                        list={errors?.non_field_errors}
                    />
                    <SuperField as="choice"
                        search
                        text="name"
                        label={t('unit')}
                        error={ errors?.unit?.[0] || false }
                        value={form?.unit}
                        onChange={(e, { value }) =>
                            setForm(prev => ({
                                ...prev,
                                unit: value
                            }))
                        }
                        endpoint={API.UNITS}
                    />
                    {(data?.start_date === "" || data?.start_date === null) &&
                        <SuperField as="datepicker"
                            label={ t('expected_start_date') }
                            error={ errors?.expected_start_date?.[0] || false }
                            value={form?.expected_start_date}
                            onChange={(e, { value }) =>
                                setForm(prev => ({
                                    ...prev,
                                    expected_start_date: value
                                }))
                            }
                        />
                    }

                    <SuperField as="input"
                        label={t('id_card_number')}
                        placeholder={t('id_card_number')}
                        error={ errors?.personal_information?.id_card_number?.[0] || false }
                        value={form?.personal_information?.id_card_number}
                        onChange={(e, { value }) =>
                            setForm(prev => ({
                                ...prev,
                                personal_information: {
                                    ...prev.personal_information,
                                    id_card_number: value
                                }
                            }))
                        }
                    />
                    <Form.Group widths={2}>
                        <SuperField as="input"
                            label={t('custom_id_prefix')}
                            placeholder={t('custom_id_prefix')}
                            error={ errors?.personal_information?.custom_id_prefix?.[0] || false }
                            value={form?.personal_information?.custom_id_prefix}
                            onChange={(e, { value }) =>
                                setForm(prev => ({
                                    ...prev,
                                    personal_information: {
                                        ...prev.personal_information,
                                        custom_id_prefix: value
                                    }
                                }))
                            }
                        />
                        <SuperField as="input"
                            label={t('custom_id')}
                            placeholder={t('custom_id')}
                            error={ errors?.personal_information?.custom_id?.[0] || false }
                            value={form?.personal_information?.custom_id}
                            onChange={(e, { value }) =>
                                setForm(prev => ({
                                    ...prev,
                                    personal_information: {
                                        ...prev.personal_information,
                                        custom_id: value
                                    }
                                }))
                            }
                        />
                    </Form.Group>
                    <Form.Field style={{ textAlign: "right" }}>
                        <Button
                            primary
                            loading={isProcessing}
                            type="submit"
                            disabled={isProcessing}
                        >
                            {t('confirm')}
                        </Button>
                    </Form.Field>
                </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default ProfileCardHeaderForm