import React from 'react';
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from 'react-router-dom';
// components
import { Form, Header, Button, Divider, List } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const PositionFiltersForm = ({ filters, setFilters, fetchJobPositions }) => {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchJobPositions("", true)
    }

    return (
        <Form onSubmit={() => handleSubmit()} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>

            <Divider />
            <SuperField as="input"
                icon="search"
                iconPosition="left"
                label={t('search')}
                placeholder={t('search')}
                value={filters.search}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    search: value
                })}
            />

            <Form.Group widths="equal">
                <SuperField as="input"
                    label={t('title')}
                    placeholder={t('title')}
                    value={filters.title}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        title: value
                    })}
                />

                <SuperField as="choice"
                    label={t('status')}
                    customOptions={[
                        { key: 1, value: true, text: t('external') },
                        { key: 2, value: false, text: t('internal') }
                    ]}
                    value={filters.is_external}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        is_external: value
                    })}
                />
            </Form.Group>

            <Form.Field>
                <Button.Group>
                    <Button size="small" primary> {t('apply')} </Button>
                    {/* <Button type="button" onClick={resetFilters} size="small"> {t('reset')} </Button> */}
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default PositionFiltersForm;