import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Icon from '@components/Icon';
import { Icon as SemanticIcon, Button, Divider, Grid } from 'semantic-ui-react'
import AvatarIcon from '@components/AvatarIcon';
import Phone from '@components/general/Phone'

const ContactDetail = ({ data, close }) => {
    const [tab, setTab] = useState(1)//HISTORY - 0, INFORMATIONS - 1 
    const { t } = useTranslation()

    const CloseButton = () => (
        <SemanticIcon.Group
            className='on-hover'
            size='big'
            onClick={close}
            style={{ cursor: 'pointer', position: 'absolute', top: '1rem', left: '1rem', zIndex: 10, padding: 0 }}>
            <Icon name='chevron-forward-circle-outline' />
        </SemanticIcon.Group>
    )
    const Header = () => {
        const fullname = data?.contact_type !== 4 ? `${data?.person.firstname} ${data?.person.lastname}` : data.name
        const phone = data?.contact_info.phones[0]?.phone
        const email = data?.contact_info.emails[0]?.email
        return (
            <Grid.Row style={{ display: 'flex', justifyContent: 'center', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <AvatarIcon size={90} name={fullname} src={data.profile_picture} />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem', justifyContent: 'space-between', height: '100%' }}>
                        <b style={{ fontSize: 24 }}>{fullname}</b>
                        <span style={{ fontSize: 18 }}>{data?.contact_type_display}</span>
                        <div>
                            <Button primary disabled={phone === undefined}><Phone phone={phone} noContent /></Button>
                            <a href={"mailto:" + email}>
                                <Button basic disabled={email === undefined}><Icon name='mail-outline' style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{t('send_message')}</span></Button>
                            </a>
                        </div>
                    </div>
                </div>
            </Grid.Row>
        )
    }
    const Tabs = () => {
        return (
            <Grid.Row style={{ display: 'flex', justifyContent: 'space-between', padding: '0 1rem' }}>
                <Button primary={tab === 1} onClick={() => setTab(1)} content={t('informations')} style={{ width: '47%' }} />
                <Button disabled primary={tab === 0} onClick={() => setTab(0)} content={t('calls_history')} style={{ width: '47%' }} />
            </Grid.Row>
        )
    }
    const CallsHistory = () => (
        <Grid.Row style={{ padding: '1rem', display: 'flex', justifyContent: 'center' }}>
            <b>WORKING ON THIS FEATURE</b>
        </Grid.Row>
    )
    const Informations = () => {
        const { address, contact_info, description, position, contact_type, contact_type_display } = data
        const person = contact_type === 4 ? data.contact_person : data.person
        const name = contact_type === 4 ? data.name : null
        const email = contact_info.emails[0]
        const phone = contact_info.phones[0]

        const InfoRow = ({ value, title }) => (
            <div style={{ display: 'flex', width: '100%', marginBorrom: '0.5rem' }}>
                <div style={{ width: '30%' }}>{title}:</div>
                <div style={{ fontWeight: 'bold' }}>{value}</div>
            </div>
        )
        return (
            <Grid.Row style={{ padding: '1rem', fontSize: 16 }}>
                {name && <InfoRow title={t('company_name')} value={name} />}
                {contact_type === 4
                    ? <InfoRow title={t('contact_person')} value={`${person.firstname} ${person.lastname}`} />
                    : <InfoRow title={t('name')} value={`${person.firstname} ${person.lastname}`} />
                }
                <InfoRow title={t('gender')} value={person.gender_display} />
                <InfoRow title={t('contact_type')} value={contact_type_display} />
                {phone && <InfoRow title={t('phone')} value={phone.phone} />}
                {email && <InfoRow title={t('email')} value={email.email} />}
                {address && <InfoRow title={t('address')} value={`${address.address_line}`} />}
                {position && <InfoRow title={t('position')} value={position} />}
                {description && <InfoRow title={t('description')} value={description} />}
            </Grid.Row>
        )
    }

    if (!data) return (<></>)
    return (
        <Grid>
            <CloseButton />
            <Header />
            <Divider style={{ marginBottom: '0.5rem' }} />
            <Tabs />
            <Divider style={{ marginTop: '0.5rem' }} />
            {tab === 0 && <CallsHistory />}
            {tab === 1 && <Informations />}
        </Grid>
    );
};

export default ContactDetail;