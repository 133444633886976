import moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
// components
import Icon from '@components/Icon';
import { List, Rating } from 'semantic-ui-react';


const HistoryRecord = ({ history }) => {


    const HistoryDescription = ({ history }) => {
        const { t } = useTranslation();

        return (
            <List.Description>
                { history.action === 1 &&
                    <span>{t('assigned_candidate_to_recruitment')} { history?.recruitment?.name }  <strong style={{ paddingLeft: "0.5rem" }}>{moment(history.created_on).format('MMMM Do YYYY, h:mm a')}</strong></span>
                }

                { history.action === 2 &&
                    <span>{t('commented_with_message')}: <i>"{ history.note }"</i>.  <strong style={{ paddingLeft: "0.5rem" }}> {moment(history.created_on).format('MMMM Do YYYY, h:mm a')} </strong></span>
                }

                { history.action === 3 &&
                    <span>{t('rated_candidate_with')} <Rating defaultRating={history.rating} maxRating={5} disabled />.  <strong style={{ paddingLeft: "0.5rem" }}> {moment(history.created_on).format('MMMM Do YYYY, h:mm a')} </strong></span>
                }

                { history.action === 4 &&
                    <span>{t('moved_candidate_to_stage')}: <u>{ history.archived_recruitment_stage.name }</u>.  <strong style={{ paddingLeft: "0.5rem" }}> {moment(history.created_on).format('MMMM Do YYYY, h:mm a')} </strong></span>
                }

                { history.action === 7 &&
                    <span>{t('rejected_candidate_from_this_hiring_room')}  <strong style={{ paddingLeft: "0.5rem" }}> {moment(history.created_on).format('MMMM Do YYYY, h:mm a')} </strong></span>
                }

                { history.action === 8 &&
                    <span>{t('blacklisted_candidate_from_this_hiring_room')}  <strong style={{ paddingLeft: "0.5rem" }}> {moment(history.created_on).format('MMMM Do YYYY, h:mm a')} </strong></span>
                }
            </List.Description>
        )
    }

    const ActivityIcon = ({ history }) => {
        return (
            <Fragment>
                { history.action === 1 && <List.Icon> <Icon name="pricetag-outline" /></List.Icon> }
                { history.action === 2 && <List.Icon> <Icon name="chatbox-ellipses-outline" /></List.Icon> }
                { history.action === 3 && <List.Icon> <Icon name="thumbs-up-outline" /></List.Icon> }
                { history.action === 4 && <List.Icon> <Icon name="shuffle-outline"/></List.Icon> }
                { history.action === 7 && <List.Icon> <Icon name="eye-off-outline" /></List.Icon> }
                { history.action === 8 && <List.Icon> <Icon name="eye-off-outline" /></List.Icon> }
            </Fragment>
        )
    }


    return (
        <List.Item>
            <ActivityIcon history={history} />
            <List.Content>
                <List.Header>{history?.created_by?.name}</List.Header>
                <HistoryDescription history={history}/>
            </List.Content>
        </List.Item>
    );
};

export default HistoryRecord;