import React from 'react'
//components
import Icon from '@components/Icon';
import { Card } from 'semantic-ui-react';

const PreviewCard = ({name, data}) => {
    return (
        <Card fluid style={{borderRadius:0, backgroundColor: "var(--info)"}}>
            <Card.Content>
                <Icon name="checkmark-outline"/>
                <span style={{ position: "relative", top: "-0.1rem" }}>
                    <span style={{marginLeft: "0.5rem" }}> {name}: </span>
                    <span style={{fontWeight: "bold", marginLeft: "0.5rem"  }}> {data} </span>
                </span>
            </Card.Content>
        </Card>
    )
}

export default PreviewCard