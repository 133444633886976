import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
//components
import Icon from '@components/Icon';
import { Button, Container, Grid, Header } from 'semantic-ui-react';
import PermissionsList from './PermissionsList';

const MODULES = [
    { name: 'common', appLabel: 'common', category: 'core' },
    { name: 'users', appLabel: 'users', category: 'core' },
    { name: 'company', appLabel: 'company', category: 'core' },
    { name: 'accounts', appLabel: 'accounts', category: 'finance' },
    { name: 'assets', appLabel: 'assets', category: 'operations' },
    { name: 'hiring_rooms', appLabel: 'candidates', category: 'human_resources' },
    { name: 'jobs', appLabel: 'jobs', category: 'human_resources' },
    { name: 'benefits', appLabel: 'benefits', category: 'human_resources' },
    { name: 'courses', appLabel: 'courses', category: 'human_resources' },
    { name: 'contracts', appLabel: 'contracts', category: 'human_resources' },
    { name: 'contacts', appLabel: 'contacts', category: 'finance' },
    { name: 'onboarding', appLabel: 'onboarding', category: 'human_resources' },
    { name: 'orders', appLabel: 'orders', category: 'finance' },
    { name: 'processes', appLabel: 'processes', category: 'core' },
    { name: 'projects', appLabel: 'projects', category: 'projects' },
    { name: 'teams', appLabel: 'teams', category: 'projects' },
    { name: 'timesheets', appLabel: 'timesheets', category: 'projects' },
    { name: 'cases', appLabel: 'cases', category: 'operations' },
    { name: 'cost_centers', appLabel: 'cost_centers', category: 'finance' },
    { name: 'approvals', appLabel: 'approvals', category: 'core' },
    { name: 'forecasts', appLabel: 'forecasts', category: 'operations' },
    { name: 'reports', appLabel: 'reports', category: 'core' },
    { name: 'integrations', appLabel: 'integrations', category: 'core' },
    { name: 'templating', appLabel: 'templating', category: 'core' },
]

const PermissionCategories = ({ permissions, label, category, groupPermissions, setGroupPermissions, group }) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false)

    const findPermissions = (appLabel) => {
        return permissions.filter(permission => permission.app_label === appLabel)
    }

    const findGroupPermissions = (appLabel) => {
        return groupPermissions.filter(permission => permission.app_label === appLabel)
    }

    return (
        <>
            <Grid>
                <Grid.Row columns='2' style={{ paddingBottom: 0, cursor: "pointer", borderBottom: "1px solid var(--light-grey)" }} onClick={() => setVisible(!visible)}>
                    <Grid.Column mobile={14} tablet={14} computer={14}>
                        <Header as="h4" >{t(label)}</Header>
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={2} computer={2}>
                        {!visible ?
                            <Button floated="right" size="medium" icon={<Icon name="chevron-down-outline"/>} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                            :
                            <Button floated="right" size="medium" icon={<Icon name="chevron-up-outline"/>} style={{ paddingTop: 0, background: "none", marginBottom: "0.5rem" }} />
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {visible &&
                <Container fluid style={{ borderRadius: 0, padding: '2rem', margin: 0 }}>
                    {MODULES.map(module => (
                        module.category === category &&
                        <PermissionsList
                            key={module.appLabel}
                            label={t(module.name)}
                            group={group}
                            permissions={findPermissions(module.appLabel)}
                            groupPerms={findGroupPermissions(module.appLabel)}
                            setGroupPermissions={setGroupPermissions}
                        />
                    ))}
                </Container>
            }
        </>
    )
}

export default PermissionCategories