import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// stored
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
import { useSelectOptions } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ForecastForm = ({ forecast, setForecasts, onClose }) => {
    const { t } = useTranslation();
    const [categoryTypes, setCategoryTypes] = useSelectOptions(API.FORECASTS + "categories/", "title")
    const [form, setForm] = useState({
        title: forecast !== undefined ? forecast.title : "",
        date_from: forecast !== undefined ? forecast.date_from : "",
        date_to: forecast !== undefined ? forecast.date_to : "",
        category: forecast !== undefined ? forecast.category?.id : "" // optional
    })
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async () => {
        setIsProcessing(true)

        let request = undefined;
        if( forecast === undefined ){ // create
            request = await requests.post(API.FORECASTS, form)
        } else { // update
            request = await requests.put(API.FORECASTS + forecast.id + "/", form)
        }

        if(request !== undefined){
            if( request.status === 201 ){
                setForecasts(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: [request.response, ...prevState.data.results]
                    }
                }))
                onClose()
            } else if(request.status === 200){
                setForecasts( prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: prevState.data.results.filter(item => {
                            if (item.id === request.response.id) {
                                item.title = request.response.title
                                item.date_from = request.response.date_from
                                item.date_to = request.response.date_to
                                item.category = request.response.category
                            }

                            return item;
                        })
                    }
                }))
                onClose()
            }
        }
        setIsProcessing(false)
    }

    const handleAddItem = async (newItem) => {
        setCategoryTypes({...categoryTypes, isLoading: true})
        const request = await requests.post(API.FORECASTS + "categories/", {
            title: newItem
        })

        if (request.status === 201) {
            setCategoryTypes(prevState => {
                return {
                    isLoading: false,
                    options: [{ 
                        key: request.response.id, 
                        value: request.response.id, 
                        text: request.response.title 
                    }, ...prevState.options]
                }
            })

            setForm(prevState => ({...prevState, category: request.response.id}))
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="input" required
                    autoFocus
                    label={t('title')}
                    value={form.title}
                    onChange={(e, { value }) => setForm({ ...form, title: value })}
                />

                <SuperField as="choice"
                    allowAdditions
                    search
                    text="title"
                    help={ t('add_category_type_hint') }
                    loading={categoryTypes.isLoading}
                    disabled={categoryTypes.isLoading}
                    customOptions={categoryTypes.options}
                    label={t('category')}
                    onAddItem={(e,{ value }) => handleAddItem(value)}
                    value={form.category}
                    onChange={(e, { value }) => setForm({ ...form, category: value })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={t('date_from')}
                    value={form.date_from}
                    onChange={(e, { value }) => setForm({ ...form, date_from: value })}
                />
                <SuperField as="datepicker"
                    required
                    label={t('date_to')}
                    value={form.date_to}
                    onChange={(e, { value }) => setForm({ ...form, date_to: value })}
                />
            </Form.Group>
            
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    loading={isProcessing}
                    disabled={
                        isProcessing ||
                        isEmpty(form.title) ||
                        isEmpty(form.date_from) ||
                        isEmpty(form.date_to)
                    }
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default ForecastForm;