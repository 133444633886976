import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { isPhoneNumberValid, isEmailValid } from '@helpers/validation'
// components
import { Form, Divider, Tab, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import EmailField from '@components/forms/common/EmailField';
import PhoneField from '@components/forms/common/PhoneField';


const AccountDetailsForm = ({ invoice, setInvoice, onClose }) => {
    const { t } = useTranslation()

    const [customerData, setCustomerData] = useState({
        customer_name: invoice.customer_name,
        customer_registration_id: invoice.customer_registration_id,
        customer_business_register_folder: invoice.customer_business_register_folder,
        customer_tax_id: invoice.customer_tax_id,
        customer_vat_id: invoice.customer_vat_id,
        customer_street: invoice.customer_street,
        customer_zip: invoice.customer_zip,
        customer_city: invoice.customer_city,
        customer_country: invoice.customer_country,
        customer_email: invoice.customer_email,
        customer_phone: invoice.customer_phone
    })

    const onConfirm = () => {
        setInvoice(prevState => {
            return {
                ...prevState,
                ...customerData
            }
        })
        onClose()
    }

    const panes = [
        { 
            menuItem: t('general_information'),
            render: () => (
                <Tab.Pane>
                    <Form.Group widths="equal">
                        <SuperField as="input"
                            autoFocus
                            label={ t('name') }
                            value={customerData.customer_name}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_name: value
                            })}
                        />
                        <SuperField as="input"
                            label={ t('account_registration_id') }
                            value={customerData.customer_registration_id}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_registration_id: value
                            })}
                        />
                    </Form.Group>
                    <Divider/>
                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={ t('account_tax_id') }
                            value={customerData.customer_tax_id}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_tax_id: value
                            })}
                        />
                        <SuperField as="input"
                            label={ t('account_vat_id') }
                            value={customerData.customer_vat_id}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_vat_id: value
                            })}
                        />
                        <SuperField as="input"
                            label={ t('register_folder') }
                            value={customerData.customer_business_register_folder}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_business_register_folder: value
                            })}
                        />
                    </Form.Group>
                </Tab.Pane>
            ) 
        },
        { 
            menuItem: t('address'),
            render: () => (
                <Tab.Pane>
                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={ t('street') }
                            value={customerData.customer_street}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_street: value
                            })}
                        />
                        <SuperField as="input"
                            label={ t('postcode') }
                            value={customerData.customer_zip}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_zip: value
                            })}
                        />
                    </Form.Group>
                    <Form.Group widths={"equal"}>
                        <SuperField as="input"
                            label={ t('city') }
                            value={customerData.customer_city}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_city: value
                            })}
                        />
                        <SuperField as="choice"
                            search
                            type="countries"
                            label={ t('country') }
                            value={customerData.customer_country}
                            onChange={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_country: value
                            })}
                        />
                    </Form.Group>
                </Tab.Pane>
            )
        },
        { 
            menuItem: t('contact_information'),
            render: () => (
                <Tab.Pane>
                    <Form.Group widths={2}>
                        <Form.Field>
                            <label> { t('phone') } </label>
                            <PhoneField
                                hideType
                                placeholder={t('phone')}
                                value={customerData.customer_phone === null ? "" : customerData.customer_phone}
                                setValue={(e, { value }) => setCustomerData({
                                    ...customerData,
                                    customer_phone: value
                                })}
                            />
                        </Form.Field>
                        <EmailField
                            label={t('email')}
                            value={customerData.customer_email === null ? "" : customerData.customer_email}
                            setValue={(e, { value }) => setCustomerData({
                                ...customerData,
                                customer_email: value
                            })}
                        />
                    </Form.Group>
                </Tab.Pane>
            ) 
        },
      ]

    return (
        <Form>
            <Tab panes={panes}/>
            <Divider/>

            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    primary
                    content={t('confirm')}
                    onClick={onConfirm}
                    disabled={ 
                        (!isPhoneNumberValid(customerData.customer_phone) && customerData.customer_phone !== null && customerData.customer_phone !== "") ||
                        (!isEmailValid(customerData.customer_email) && customerData.customer_email !== null && customerData.customer_email !== "")
                    }
                />
            </Form.Field>
        </Form>
    );
};

export default AccountDetailsForm;