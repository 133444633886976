import React from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { deleteRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Divider, Header, Button, Card } from 'semantic-ui-react';
import ContactPhoneForm from './ContactPhoneForm';
import ContactEmailForm from './ContactEmailForm';

const ContactInfoView = ({ updateState, isProfile, permissions, data, endpoint }) => {
    const { t } = useTranslation()

    const onPhoneDelete = async (id) => {
        const request = await deleteRequest(API.PHONES + id + "/")
        if (request.status === 204) {
            if (isProfile){
                updateState(prevState => ({
                    ...prevState,
                    personal_information: {
                        ...prevState.personal_information,
                        contact_info: {
                            ...prevState.personal_information.contact_info,
                            phones: prevState.personal_information.contact_info.phones.filter(item => item.id !== id)
                        }
                    }
                }))
            } else {
                updateState(prevState => ({
                    ...prevState,
                    contact_info: {
                        ...prevState.contact_info,
                        phones: prevState.contact_info.phones.filter(item => item.id !== id)
                    }
                }))
            }
        }
    }

    const onEmailDelete = async (id) => {
        const request = await deleteRequest(API.EMAIL + id + "/")
        if (request.status === 204) {
            if (isProfile){
                updateState(prevState => ({
                    ...prevState,
                    personal_information: {
                        ...prevState.personal_information,
                        contact_info: {
                            ...prevState.personal_information.contact_info,
                            emails: prevState.personal_information.contact_info.emails.filter(item => item.id !== id)
                        }
                    }
                }))
            } else {
                updateState(prevState => ({
                    ...prevState,
                    contact_info: {
                        ...prevState.contact_info,
                        emails: prevState.contact_info.emails.filter(item => item.id !== id)
                    }
                }))
            }
        }
    }

    return (
        <>
            <CanView permissions={[permissions.view]}>
                <FlexRow background="transparent" padding="0">
                    <FlexItem>
                        <Header as="h3" content={t('phone_contacts')} />
                    </FlexItem>
                    <FlexItem textAlign="right">
                        <CanView permissions={[permissions.manage]}>
                            <SuperDuperModal
                                size="tiny"
                                header={ t('add_phone_contact') }
                                trigger={ 
                                    <Button basic
                                        size="small"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/> 
                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                            { t('add_phone_contact') }
                                        </span>
                                    </Button> 
                                }
                                content={
                                    <ContactPhoneForm 
                                        isProfile={isProfile}
                                        endpoint={endpoint}
                                        updateState={updateState}
                                    />
                                }
                            />
                        </CanView>
                    </FlexItem>
                </FlexRow>
                <Divider/>
                { data?.phones?.length > 0 ?
                    <>
                        <Card.Group>
                        { data?.phones.map(phone => (
                            <Card fluid style={{ borderRadius: 0, marginBottom: "0.3rem", background: "transparent", boxShadow: "none" }} key={phone.id}>
                                <Card.Content style={{ padding: 0 }}>
                                    <Card.Header>
                                        <FlexRow background="transparent" padding="0.1rem" fontSize="1rem">
                                            <FlexItem bold>
                                                <a href={"tel:" + phone.phone}>
                                                    <Icon name="call-outline" style={{ marginRight: "0.5rem" }}/>
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                                        { phone.phone }
                                                    </span>
                                                </a>
                                            </FlexItem>
                                            <FlexItem basis="10%" textAlign="right">
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem textAlign="center"> 
                                                        <CanView permissions={[permissions.manage]}>
                                                            <Action as="modal" type="icon" modalSize="tiny" icon="pencil-outline" tooltip={t('edit')}
                                                                modal={
                                                                    <ContactPhoneForm 
                                                                        isProfile={isProfile}
                                                                        endpoint={endpoint}
                                                                        updateState={updateState}
                                                                        data={phone}
                                                                    />
                                                                }
                                                            /> 
                                                        </CanView>
                                                    </FlexItem>
                                                    <FlexItem textAlign="center"> 
                                                        <CanView permissions={[permissions.delete]}>
                                                            <Action as='delete' 
                                                                tooltip={t('delete')} 
                                                                text={t('phone_delete')} 
                                                                onClick={() => onPhoneDelete(phone.id)}
                                                            />
                                                        </CanView>
                                                    </FlexItem>
                                                </FlexRow>
                                            </FlexItem>
                                        </FlexRow>
                                    </Card.Header>
                                </Card.Content>
                            </Card>
                        )) }
                        </Card.Group>
                    </>
                    : <p style={{ textAlign: "center", fontWeight: "bold" }}> { t('phone_contacts_not_provided') } </p>
                }

                <div style={{ marginTop: "3rem" }}></div>
                <FlexRow background="transparent" padding="0">
                    <FlexItem>
                        <Header as="h3" content={t('email_contacts')} />
                    </FlexItem>
                    <FlexItem textAlign="right">
                        <CanView permissions={[permissions.manage]}>
                            <SuperDuperModal
                                size="tiny"
                                header={ t('add_email_contact') }
                                trigger={ 
                                    <Button basic
                                        size="small"
                                        style={{ fontWeight: "bold" }}
                                    >
                                        <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/> 
                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                            { t('add_email_contact') }
                                        </span>
                                    </Button> 
                                }
                                content={
                                    <ContactEmailForm 
                                        isProfile={isProfile}
                                        endpoint={endpoint}
                                        updateState={updateState}
                                    />
                                }
                            />
                        </CanView>
                    </FlexItem>
                </FlexRow>
                <Divider/>
                { data?.emails?.length > 0 ?
                    <>
                        <Card.Group>
                        { data?.emails.map(email => (
                            <Card fluid style={{ borderRadius: 0, marginBottom: "0.3rem", background: "transparent", boxShadow: "none"}} key={email.id}>
                                <Card.Content style={{ padding: 0}}>
                                    <Card.Header>
                                        <FlexRow background="transparent" padding="0.1rem" fontSize="1rem">
                                            <FlexItem bold>
                                                <a href={"mailto:" + email.email}>
                                                    <Icon name="mail-outline" style={{ marginRight: "0.5rem" }}/>
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                                        { email.email }
                                                    </span>
                                                </a>
                                            </FlexItem>
                                            <FlexItem basis="10%" textAlign="right">
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem textAlign="center"> 
                                                        <CanView permissions={[permissions.manage]}>
                                                            <Action as="modal" type="icon" modalSize="tiny" icon="pencil-outline" tooltip={t('edit')}
                                                                modal={
                                                                    <ContactEmailForm 
                                                                        isProfile={isProfile}
                                                                        endpoint={endpoint}
                                                                        updateState={updateState}
                                                                        data={email}
                                                                    />
                                                                }
                                                            /> 
                                                        </CanView>
                                                    </FlexItem>
                                                    <FlexItem textAlign="center"> 
                                                        <CanView permissions={[permissions.delete]}>
                                                            <Action as='delete' 
                                                                tooltip={t('delete')} 
                                                                text={t('email_delete')} 
                                                                onClick={() => onEmailDelete(email.id)}
                                                            />
                                                        </CanView>
                                                    </FlexItem>
                                                </FlexRow>
                                            </FlexItem>
                                        </FlexRow>
                                    </Card.Header>
                                </Card.Content>
                            </Card>
                        )) }
                        </Card.Group>
                    </>
                    : <p style={{ textAlign: "center", fontWeight: "bold" }}> { t('email_contacts_not_provided') } </p>
                }
            </CanView>
        </>
    );
};

export default ContactInfoView;