import React, { useEffect, useState, } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { teamsService } from '../../../store/services/ServiceTeams';
// components
import { Form } from 'semantic-ui-react';

const AddTeamField = ({ teams, disabled, value, setValue, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const { t } = useTranslation()

    useEffect(() => {

        async function getTeams() {
            setLoading(true)
            const result = await teamsService.getTeams("?only_basic_info=True");

            if (result.status === 200) {

                let optionArray = [];
                for (let i = 0; i < result.response.length; i++) {
                    if (!teams.filter(item => item.id === result.response[i].id).length > 0) {
                        optionArray.push(result.response[i]);
                    }
                }
                const opt = Object.entries(optionArray).map(
                    (item, index) => (
                        { key: index + 1, text: item[1].name, value: item[1].id }
                    )
                );
                setOptions(opt);

            }
            setLoading(false)
        }

        getTeams();
    }, [teams])

    return (
        <Form.Field>
            <Form.Select
                {...rest}
                fluid
                loading={loading}
                disabled={disabled || loading}
                label={t('add_team')}
                value={value}
                placeholder='-------------'
                options={options}
                onChange={setValue}
            />
        </Form.Field>
    );
};

export default AddTeamField;