import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store 
import { icons } from '@icons';
import { routes } from '@routes';
import { getRequest } from '@services/ServiceCommon';
import { API } from '@store/config'
// components
import { Container } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
// module components 
import CCListView from './views/CCListView';
import CCDetailView from './views/CCDetailView';
import Budgets from './views/Budgets';

const CostCenters = ({ location }) => {
    const history = useHistory()
    const { t } = useTranslation()
    const match_budgets = (location.pathname === routes.COST_CENTERS + "budgets") || (location.pathname === routes.COST_CENTERS + "budgets/")
    const match_detail = parseInt(location.pathname.replace(routes.COST_CENTERS + "detail/", '')) > 0 ? true : false
    const match_index = location.pathname === routes.COST_CENTERS
    const [breadcrumb_items, setBreadcrumbItems] = useState([
        { 'name': t("cost_centers"), 'icon': icons.COST_CENTERS, 'href': (match_budgets || match_detail) ? routes.COST_CENTERS : '' }
    ])

    useEffect(() => {
        async function fetchBreadcrumb() {
            let items = [
                { 'name': t("cost_centers"), 'icon': icons.COST_CENTERS, 'href': (match_budgets || match_detail) ? routes.COST_CENTERS : '' }
            ]

            if( !match_index ){
                if( match_budgets ){
                    items.push({ 'name': "Budgets", 'icon': 'money', 'href': '' })
                } 
                
                if (match_detail) {
                    const id = location.pathname.replace(routes.COST_CENTERS + "detail/", '')
                    const result = await getRequest(API.COST_CENTERS + `${+id}/?query={title}`)
                    items.push({ 'name': result.response.title, 'icon': icons.COST_CENTERS, 'href': '' })
                } 
            }
            
            setBreadcrumbItems(items)
            if( !match_detail && !match_budgets ){
                history.push(routes.COST_CENTERS)
                return;
            }
        }
        fetchBreadcrumb()
        // eslint-disable-next-line
    }, [t, location.pathname])

    return (
        <CanView permissions={['cost_centers.c_view_cost_centers', 'cost_centers.c_view_incomes_expenses', 'cost_centers.c_view_budgets']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={breadcrumb_items}
            />
            <Container fluid>
                <Switch>
                    <Route exact path={routes.COST_CENTERS} component={CCListView} />
                    <Route exact path={routes.COST_CENTERS + "budgets"} component={Budgets} />
                    <Route path={routes.COST_CENTERS + "detail/:id"} component={CCDetailView} />
                </Switch>
            </Container>
        </CanView>
    );
};

export default withRouter(CostCenters);