import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import { Container, Divider, Grid, Header, Button } from 'semantic-ui-react';
// module specific Components
import UsersTable from '../components/UsersTable';
import UserForm from '../components/UserForm';
import UserDemoForm from '../components/UserDemoForm';
import UsersFilterSection from '../components/UsersFilterSection';

const UsersList = () => {
    const { t } = useTranslation();
    const [result, setResult] = useFetchData(API.USERS + "?paginate=true")
    const [isFiltering, setIsFiltering] = useState(false)

    const fetchUsers = async (params) => {
        if (!result.isLoading) {
            setIsFiltering(true)
            const request = await requests.get(API.USERS + params + "&paginate=true")

            if (request.status === 200) {
                setResult(prevState => ({
                    ...prevState,
                    data: request.response
                }))
            }
            setIsFiltering(false)
        }
    }

    return (
        <CanView permissions={['users.c_view_users']} redirect>
            <Container fluid style={{ marginTop: "1rem" }}>
                <Grid columns='2'>
                    <Grid.Column style={{ paddingBottom: 0 }}>
                        <Header as="h3">{t('user_management')}</Header>
                    </Grid.Column>
                    <Grid.Column textAlign='right' style={{ paddingBottom: 0, paddingTop: 0 }}>
                        <CanView permissions={['users.c_create_users']}>
                            <SuperDuperModal
                                size='small'
                                header={t('create_user')}
                                trigger={
                                    <OutlineAddButton text={t('create_user')} color="violet" size="small" />
                                }
                                content={
                                    <UserForm setResult={setResult} />
                                }
                            />
                            {window.location.hostname === "demo.hriis.io" &&
                                <SuperDuperModal
                                    size='small'
                                    header={t('create_user')}
                                    trigger={
                                        <Button style={{ background: "var(--dark)", color: "var(--white)" }} content={t('create_demo_user')} />
                                    }
                                    content={
                                        <UserDemoForm setResult={setResult} />
                                    }
                                />
                            }
                        </CanView>
                    </Grid.Column>
                </Grid>
                <Divider style={{ marginBottom: 0 }} />
                <Container fluid >
                    <UsersFilterSection fetchUsers={fetchUsers} />
                    {result.isLoading || isFiltering ? <Loading /> :
                        <UsersTable
                            result={result}
                            setResult={setResult}
                            fetchUsers={fetchUsers}
                        />
                    }
                </Container>
            </Container>
        </CanView>
    );
};

export default UsersList;