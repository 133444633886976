import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { createRequest, getRequest } from '@services/ServiceCommon';
import { isEmailValid, isPhoneNumberValid } from '@services/helpers/validation';
// components
import { Segment, Form, Grid, Header, Button } from 'semantic-ui-react';
// module specific components
import SuperFieldSet from '@components/forms/SuperFieldSet';
import PersonData from './components/PersonData';
import ContactInfo from './components/ContactInfo';
import BirthInfo from './components/BirthInfo';
import PermanentAddress from './components/PermanentAddress';
import TemporaryAddress from './components/TemporaryAddress';
import PersonalNumber from './components/PersonalNumber';
import MaritalStatus from './components/MaritalStatus';
import LastJob from './components/LastJob';
import LastCountryOfResidence from './components/LastCountryOfResidence';
// import HealthInsurance from './components/HealthInsurance';
import Educations from './components/Educations';
import Experiences from './components/Experiences';
import Languages from './components/Languages';
import Skills from './components/Skills';
import Interests from './components/Interests';
import GDPRField from './components/GDPRField';

const FormPreview = ({ selected, isTemplate, setSubmitted, applyForm, publication }) => {
    const { t } = useTranslation();

    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [gdpr, setGDPR] = useState({})
    const [gdprs, setGDPRs] = useState([])

    useEffect(() => {
        async function fetchAndSetGDPRS(){
            if( !isTemplate ){
                const request = await getRequest(API.GDPRS_PUBLIC + "?id=" + applyForm?.gdpr + "&query={id, title, is_required}", false)
    
                if(request.status === 200){
                    setGDPR({
                        id: request.response[0].id,
                        title: request.response[0].title,
                        is_checked: false,
                        is_required: request.response[0].is_required,
                    })
                }

                // loop over additional gdprs
                let gdprsArray = []
                for (let i = 0; i < applyForm?.additional_gdprs?.length || 0; i++) {
                    const request = await getRequest(API.GDPRS_PUBLIC + "?id=" + applyForm?.additional_gdprs[i] + "&query={id, title, is_required}", false)
    
                    if(request.status === 200){
                        gdprsArray.push({
                            id: request.response[0].id,
                            title: request.response[0].title,
                            is_checked: false,
                            is_required: request.response[0].is_required,
                        })
                    }
                }

                setGDPRs(gdprsArray)
            }
        }

        fetchAndSetGDPRS()
        // eslint-disable-next-line
    }, [])


    const isSelected = (choice) => {
        return selected.includes(choice)
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)

        let finalDataset = {
            data: {
                personal_information: {
                    person: data.person,
                    birth_info: data.birth_info,
                    contact_info: data.contact_info,
                    permanent_address: data.permanent_address,
                    temporary_address: data.temporary_address,
                    personal_number: data.personal_number,
                    marital_status: data.marital_status,
                    // health_insurance: data.health_insurance // TODO: Backend doesn't support restQL
                },
                educations: data.educations,
                languages: data.languages,
                experiences: data.experiences,
                skills: data.skills,
                interests: data.interests,
                last_job: data.last_job === "" ? null : data.last_job ,
                last_country_residence: data.last_country_residence === "" ? null : data.last_country_residence ,
            },
            gdpr_agreement: {
                gdpr: gdpr?.id,
                is_checked: gdpr?.is_checked || false
            },
            additional_gdpr_agreements: gdprs.length === 0 ? null : {
                create: gdprs.map(item => {
                    return {
                        gdpr: item?.id,
                        is_checked: item?.is_checked || false
                    }
                })
            },
            publication: publication,
            config: applyForm?.id ? applyForm?.id : null,
            // attachments: null // TODO: Backend doesn't support null
        }

        if( isTemplate ){
            console.log(finalDataset)
        } else {
            const request = await createRequest(API.APPLICATION + "form_instances/", finalDataset, false)

            if( request.status === 201 ){
                setSubmitted(true)
            } else if( request.status === 400 ){
                setErrors(request.response)
            }
        }

        setIsProcessing(false)
    }

    const isFormValid = () => {
        // verify required GDPRS
        if( gdpr?.is_checked !== true ) return false
        if( gdprs.length !== 0 && gdprs.filter(item => (item.is_required === true && item.is_checked === false)).length > 0 ) return false
        
        // now verify incoming datasets based on field configuration:
        if( isSelected("personal_information.person") ){
            console.log(data.person)
            if( data.person?.firstname === "" || data.person?.lastname === "" || data.person?.gender === "" || data.person?.gender === null || isNaN(data.person?.gender) ) return false
        }

        if( isSelected("personal_information.contact_info") ){
            // validate phone or email
            const phone = data.contact_info?.phones?.create?.[0]?.phone
            const email = data.contact_info?.emails?.create?.[0]?.email
            if( email !== undefined && !isEmailValid(email) ) return false
            if( phone !== undefined && !isPhoneNumberValid(phone) ) return false
        }

        if( isSelected("personal_information.birth_info") ){
            if( data.birth_info.date_of_birth === "" ) return false
        }


        if( isSelected("personal_information.personal_number") ){
            if( data.personal_number === "" ) return false
        }

        if( isSelected("personal_information.marital_status") ){
            if( data.marital_status === "" ) return false
        }

        if( isSelected("personal_information.permanent_address") ){
            if(data.permanent_address === null ) return false
            if( data.permanent_address?.country === "" || data.permanent_address?.city === "" || data.permanent_address?.postcode === "" || data.permanent_address?.street === "" ) return false
        }
        
        if( isSelected("personal_information.temporary_address") ){
            if(data.temporary_address === null ) return false
            if( data.temporary_address?.country === "" || data.temporary_address?.city === "" || data.temporary_address?.postcode === "" || data.temporary_address?.street === "" ) return false
        }


        
        return true
    }

    return (
        <Form onSubmit={onSubmit}>
            <Segment style={{ paddingTop: "2rem", paddingBottom: "2rem" }}>
                { selected.length === 0 ?
                    <Grid centered>
                        <Header as="h3">
                            { t('select_data_source_to_render') }
                        </Header>
                    </Grid>
                :
                <>
                    { isSelected("personal_information.person") && <PersonData setData={setData} errors={errors}/> }
                    { isSelected("personal_information.contact_info") && <ContactInfo setData={setData} errors={errors}/> }
                    { isSelected("personal_information.birth_info") && <BirthInfo setData={setData} errors={errors}/> }

                    { (
                        isSelected("personal_information.personal_number") ||
                        isSelected("personal_information.marital_status") ||
                        isSelected("last_job") ||
                        isSelected("last_country_residence")
                    ) &&
                        <SuperFieldSet legend="Additional Information">
                            <Form.Group>
                                { isSelected("personal_information.personal_number") && <PersonalNumber setData={setData} errors={errors}/> }
                                { isSelected("personal_information.marital_status") && <MaritalStatus setData={setData} errors={errors}/> }
                            </Form.Group>
                            <Form.Group>
                                { isSelected("last_job") && <LastJob setData={setData} errors={errors}/> }
                                { isSelected("last_country_residence") && <LastCountryOfResidence setData={setData} errors={errors}/> }
                            </Form.Group>
                        </SuperFieldSet>
                    }

                    { (isSelected("personal_information.permanent_address") || isSelected("personal_information.temporary_address")) &&
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column style={{ padding: 0 }}>
                                    { isSelected("personal_information.permanent_address") && <PermanentAddress setData={setData} errors={errors}/> }
                                </Grid.Column>
                                <Grid.Column style={{ padding: 0 }}>
                                    { isSelected("personal_information.temporary_address") && <TemporaryAddress setData={setData} errors={errors}/> }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    }

                    {/* { isSelected("personal_information.health_insurance") && <HealthInsurance setData={setData} errors={errors}/> } */}

                    { isSelected("educations") && <Educations setData={setData} errors={errors}/> }
                    { isSelected("languages") && <Languages setData={setData} errors={errors}/> }
                    { isSelected("experiences") && <Experiences setData={setData} errors={errors}/> }

                    { isSelected("skills") && <Skills setData={setData} errors={errors}/> }
                    { isSelected("interests") && <Interests setData={setData} errors={errors}/> }

                    { gdpr?.id && 
                        <GDPRField gdpr={gdpr} checked={gdpr.is_checked} setChecked={(checked) => setGDPR(prevState => ({...prevState, is_checked: checked}))} />
                    }

                    { gdprs.map(additionalGDPR => (
                        <GDPRField key={additionalGDPR.id} gdpr={additionalGDPR} checked={additionalGDPR.is_checked} setChecked={(checked) => setGDPRs(prevState => prevState.filter(item => {
                            if(item.id === additionalGDPR.id){
                                item.is_checked = checked
                            }

                            return item
                        }))} />
                    ))}

                    { !isTemplate && 
                        <Form.Field style={{ textAlign: "right"}}>
                            <Button disabled={isProcessing || !isFormValid()} loading={isProcessing} primary>
                                { t('submit_reaction') }
                            </Button>
                        </Form.Field>
                    }
                </>
                }
            </Segment>
        </Form>
    );
};

export default FormPreview;