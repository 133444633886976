import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import { Form, Icon, Header, Divider } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import DateField from '@components/forms/common/DateField';
import ChoiceField from '@components/forms/common/ChoiceField';
import SuperField from '@components/forms/SuperField';

const ProfesiaSKPublicationForm = ({ offerId, offer, setData, resourceType, channel, setIsDisabled }) => {
    const { t } = useTranslation();

    // eslint-disable-next-line
    const [publicationData, setPublicationData] = useState({
        is_active: true,
        is_featured: false,
        publish_when: null,
        valid_until: null,
        job_offer: offerId,
        application_form_config: null,
        resourcetype: resourceType,
        regions: [],
        skills: [],
        categoryPositions: [],
        salaryInterval: {
            min: offer?.job_contract?.salary?.min_netto ? offer?.job_contract?.salary?.min_netto : 0,
            max: offer?.job_contract?.salary?.max_netto ? offer?.job_contract?.salary?.max_netto : 0,
            maxHidden: false,
            currency: {
                id: 2
            }
        },
        onlyNew: 0,
        // postingPeriod: 37,
        // autoRepeatPost: false,
        title: "",
        firstName: offer.contact_person !== "" ? offer.contact_person?.split(" ", 2)[0] : "",
        surname: offer.contact_person !== "" ? offer.contact_person?.split(" ", 2)[1] : "",
        email: offer.contact_email
    })

    

    const [hideConfig, setHideConfig] = useState(false)
    const [profesiaPositions, setProfesiaPositions] = useState([])
    // eslint-disable-next-line
    const [profesiaCategories, setProfesiaCategories] = useState([])
    const [profPositions, setProfPositions] = useState("")

    useEffect( () => {
        async function fetchProfesiaOptions(){
            const requestPositions = await getRequest(API.INTEGRATIONS + "profesia_sk/category_position/")
            if( requestPositions.status === 200 ){

                let positions = []
                for (let i = 0; i < requestPositions.response.length; i++) {
                    let categoryID = requestPositions.response[i].id
                    let positionOptions = requestPositions.response[i].positions
                    for(let i = 0; i < positionOptions.length; i++){
                        positions.push({
                            id: categoryID + "-" + positionOptions[i].id,
                            text: positionOptions[i].position,
                            value: categoryID + "-" + positionOptions[i].id
                        })
                    }
                }

                setProfesiaPositions(positions)
            }

            // const requestCategories = await getRequest(API.INTEGRATIONS + "profesia_sk/categories/")
            // if( requestCategories.status === 200 ){
            //     setProfesiaCategories(requestCategories.response)
            // }
        }

        fetchProfesiaOptions()
        // eslint-disable-next-line
    }, [])


    useEffect( () => {
        let dataset = publicationData

        if( channel ){
            dataset['channel'] = [ channel ]
        }

        if( profPositions !== "" ){
            let splittedValues = profPositions.split("-")
            dataset['categoryPositions'][0] = [parseInt(splittedValues[0]), parseInt(splittedValues[1])]
        }

        setData(dataset)

        // eslint-disable-next-line
    }, [publicationData, profPositions])

    useEffect(() => {
        let isValid = true
        if(
            publicationData.valid_until === "" ||
            publicationData.valid_until === null ||
            profPositions.length === 0 ||
            profesiaPositions.length === 0 || 
            publicationData.firstName === "" ||
            publicationData.surname === "" ||
            publicationData.email === ""
        ){
            isValid = false
        }

        setIsDisabled(!isValid)
        // eslint-disable-next-line
    }, [publicationData, profesiaPositions, profPositions])

    return (
        <>
            <Form.Group widths='equal'>
                <Form.Field>
                    <DateField
                        required
                        startMode="year"
                        label={t('valid_until')}
                        value={publicationData.valid_until}
                        onChange={(e, data) => setPublicationData({
                            ...publicationData,
                            valid_until: data.value
                        })}
                    />
                </Form.Field>
                <SuperField as="choice"
                    endpoint={API.APPLICATION + "form_configs/"}
                    text="name"
                    label={ t('application_form') }
                    value={ publicationData.application_form_config }
                    onChange={(e, { value }) => setPublicationData({
                        ...publicationData,
                        application_form_config: value
                    })}
                />
            </Form.Group>
            <Form.Field>
                <Form.Checkbox
                    style={{ marginTop: "1rem" }}
                    label={t('mark_as_featured')}
                    checked={ publicationData.is_featured }
                    onChange={ () => setPublicationData({
                        ...publicationData,
                        is_featured: !publicationData.is_featured
                    })}
                />
            </Form.Field>

            <FlexRow padding="1rem" borders>
                <FlexItem>
                    <h2>
                        {t('configuration')}
                    </h2>
                </FlexItem>
                <FlexItem textAlign="right">
                    <Icon onClick={ () => setHideConfig(!hideConfig) } style={{ cursor: "pointer" }} name={hideConfig ? "angle down" : "angle up"}/>
                </FlexItem>
            </FlexRow>
            <div style={{ display: hideConfig ? "none" : "block" }}>
                <Form.Group style={{ marginTop: "1rem" }} widths="equal">
                    <ChoiceField
                        multiple
                        required
                        search
                        label={ t('regions') }
                        endpoint={API.INTEGRATIONS + "profesia_sk/regions/"}
                        text="region"
                        value={ publicationData.regions }
                        onChange={ (e, { value }) => setPublicationData({
                            ...publicationData,
                            regions: value
                        })}
                    />
                    <ChoiceField
                        required
                        search
                        label={ t('position') }
                        customOptions={profesiaPositions}
                        value={ profPositions }
                        onChange={ (e, { value }) => setProfPositions(value)}
                    />
                </Form.Group>


                <Form.Group widths='equal'>
                        <Form.Field>
                            <Divider/>
                            <Header as="h3" content={ t('salary') }/>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    label={ t('min') }
                                    value={ publicationData.salaryInterval.min }
                                    onChange={ (e) => setPublicationData({
                                        ...publicationData,
                                        salaryInterval: {
                                            ...publicationData.salaryInterval,
                                            min: e.target.value
                                        }
                                    }) }
                                />
                                <Form.Input
                                    label={ t('max') }
                                    value={ publicationData.salaryInterval.max }
                                    onChange={ (e) => setPublicationData({
                                        ...publicationData,
                                        salaryInterval: {
                                            ...publicationData.salaryInterval,
                                            max: e.target.value
                                        }
                                    }) }
                                />
                            </Form.Group>

                            <ChoiceField
                                search
                                label={ t('currency') }
                                endpoint={API.INTEGRATIONS + "profesia_sk/currencies/"}
                                text="currency"
                                value={ publicationData.salaryInterval.currency.id }
                                onChange={ (e) => setPublicationData({
                                    ...publicationData,
                                    salaryInterval: {
                                        ...publicationData.salaryInterval,
                                        currency: {
                                            id: e.target.value
                                        }
                                    }
                                })}
                            />

                            <Form.Checkbox
                                style={{ marginTop: "2.2rem" }}
                                label={ t('hideMax') }
                                checked={ publicationData.salaryInterval.maxHidden }
                                onChange={ () => setPublicationData({
                                    ...publicationData,
                                    salaryInterval: {
                                        ...publicationData.salaryInterval,
                                        maxHidden: !publicationData.salaryInterval.maxHidden
                                    }
                                })}
                            />
                        </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>
                        <Form.Field>
                            <Divider/>
                            <Header as="h3" content={ t('contact_person') }/>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    required
                                    label={ t('firstname') }
                                    placeholder={ t('firstname') }
                                    value={ publicationData.firstName }
                                    onChange={ (e) => setPublicationData({
                                        ...publicationData,
                                        firstName: e.target.value
                                    }) }
                                />
                                <Form.Input
                                    required
                                    label={ t('surname') }
                                    placeholder={ t('surname') }
                                    value={ publicationData.surname }
                                    onChange={ (e) => setPublicationData({
                                        ...publicationData,
                                        surname: e.target.value
                                    }) }
                                />

                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Input
                                    label={ t('person_title') }
                                    placeholder={ t('person_title') }
                                    value={ publicationData.title }
                                    onChange={ (e) => setPublicationData({
                                        ...publicationData,
                                        title: e.target.value
                                    }) }
                                />
                                <Form.Input
                                    required
                                    label={ t('email') }
                                    placeholder={ t('email') }
                                    value={ publicationData.email }
                                    onChange={ (e) => setPublicationData({
                                        ...publicationData,
                                        email: e.target.value
                                    }) }
                                />
                            </Form.Group>
                        </Form.Field>
                </Form.Group>
            </div>
        </>
    );
};

export default ProfesiaSKPublicationForm;