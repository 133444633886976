import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
//other
import SuperField from '@components/forms/SuperField';
import { Message, Form, Button } from 'semantic-ui-react';


function OtherInfoForm({data, setView, onConfirm, patchEndpoint}) {
    const { t } = useTranslation();

    const birth_info_data = {
        citizenship: data?.personal_information?.birth_info?.citizenship  || null,
        nationality: data?.personal_information?.birth_info?.nationality  || null,
    }

    const health_insurance_data = {
        name: data?.personal_information?.health_insurance?.name  || null,
        code: data?.personal_information?.health_insurance?.code  || null,
    }

    const personal_information_data = {
        birth_info: birth_info_data,
        health_insurance: data?.personal_information?.health_insurance?.id ? health_insurance_data : null,
        driver_licence: data?.personal_information?.driver_licence ? data?.personal_information?.driver_licence?.map(item => item.id) : [],
    }

    const [form, setForm] = useState({
        personal_information: personal_information_data,
        last_job: data.last_job,
        is_external: data.is_external,
        is_foreigner: data.is_foreigner,
        last_country_residence: data.last_country_residence,
        workplace_country: data.workplace_country,
        acting_by: data.acting_by,
        source: data.source?.id,
        handicap_level: data.handicap_level
    })

    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {

        setIsProcessing(true);

        let data_form = form

        data_form.personal_information.driver_licence = {
            remove: data?.personal_information?.driver_licence ? data?.personal_information?.driver_licence?.map(item => item.id) : [],
            add: form?.personal_information?.driver_licence ? form?.personal_information?.driver_licence : []
        }

        if (data.personal_information?.health_insurance?.code === form.personal_information?.health_insurance?.code) {
            delete form?.personal_information?.health_insurance?.code
        }
        if (data.personal_information?.health_insurance?.name === form.personal_information?.health_insurance?.name) {
            delete form?.personal_information?.health_insurance?.name
        }
        if ( [null, ""].includes(form.personal_information?.health_insurance?.name) && [null, ""].includes(form.personal_information?.health_insurance?.code === "") ) {
            data_form.personal_information.health_insurance = null
        }

        const request = await requests.patch(patchEndpoint + `${data?.id}/`, form)

        if (request.status === 200) {
            onConfirm(request.response)
            setView(0)
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }
        setIsProcessing(false);
    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="checkbox"
                    label={ t('is_external') }
                    style={{ padding: '1rem' }}
                    error={ errors?.is_external || false }
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            is_external: !form.is_external
                        })
                    }
                    checked={form.is_external}
                />
                <SuperField as="choice"
                    type="citizenship"
                    search
                    label={t('citizenship')}
                    error={ errors?.personal_information?.birth_info?.citizenship || false }
                    value={form?.personal_information?.birth_info?.citizenship}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                birth_info: {
                                    ...prev.personal_information.birth_info,
                                    citizenship: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="choice"
                    type="nationalities"
                    search
                    label={t('nationality')}
                    error={ errors?.personal_information?.birth_info?.nationality || false }
                    value={form?.personal_information?.birth_info?.nationality}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                birth_info: {
                                    ...prev.personal_information.birth_info,
                                    nationality: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="checkbox"
                    label={ t('is_foreigner') }
                    style={{ padding: '1rem' }}
                    error={ errors?.is_foreigner || false }
                    onChange={(e, { value }) =>
                        setForm({
                            ...form,
                            is_foreigner: !form.is_foreigner
                        })
                    }
                    checked={form.is_foreigner}
                />
                <SuperField as="choice"
                    type="countries"
                    search
                    label={t('last_country_of_residence')}
                    value={form?.last_country_residence?.toString()}
                    error={ errors?.last_country_residence || false }
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            last_country_residence: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    label={t('last_job')}
                    placeholder={t('last_job')}
                    value={form?.last_job}
                    error={ errors?.last_job || false }
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            last_job: value
                        }))
                    }
                />
                <SuperField as="choice"
                    type="countries"
                    search
                    label={t('workplace_country')}
                    value={form?.workplace_country?.toString()}
                    error={ errors?.workplace_country || false }
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            workplace_country: value
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('acting_by')}
                    placeholder={t('acting_by')}
                    error={ errors?.acting_by || false }
                    value={form?.acting_by}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            acting_by: value
                        }))
                    }
                />
                <SuperField as="choice"
                    search
                    text="name"
                    label={t('hired_based_on')}
                    value={form?.source}
                    error={ errors?.source || false }
                    onChange={ (e, {value}) =>
                        setForm(prev => ({
                            ...prev,
                            source: value
                        }))
                    }
                    endpoint={API.SOURCES}
                />
                <SuperField as="choice"
                    type="handicap_levels"
                    label={t('handicap_level')}
                    error={ errors?.handicap_level || false }
                    value={form?.handicap_level?.toString()}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            handicap_level: value
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={2}>
                <SuperField as="choice"
                    search
                    text="driver_group"
                    multiple
                    label={t('driver_licence')}
                    error={ errors?.personal_information?.driver_licence || false }
                    value={form?.personal_information?.driver_licence}
                    onChange={ (e, {value}) =>
                    setForm(prev => ({
                        ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                    driver_licence: value
                            }
                        }))
                    }
                    endpoint={API.DRIVER_LICENCE}
                />
                <Form.Group widths={2}>
                    <SuperField as="input"
                        required={form?.personal_information?.health_insurance?.name || form?.personal_information?.health_insurance?.code}
                        label={t('health_insurance')}
                        placeholder={t('health_insurance')}
                        error={ errors?.personal_information?.health_insurance?.name || false }
                        value={form?.personal_information?.health_insurance?.name}
                        onChange={(e, { value }) =>
                            setForm(prev => ({
                            ...prev,
                                personal_information: {
                                    ...prev.personal_information,
                                    health_insurance: {
                                        ...prev.personal_information.health_insurance,
                                        name: value
                                    }
                                }
                            }))
                        }
                    />
                    <SuperField as="input"
                        required={form?.personal_information?.health_insurance?.name || form?.personal_information?.health_insurance?.code}
                        label={t('health_insurance_code')}
                        placeholder={t('health_insurance_code')}
                        error={ errors?.personal_information?.health_insurance?.code || false }
                        value={form?.personal_information?.health_insurance?.code}
                        onChange={(e, { value }) =>
                            setForm(prev => ({
                            ...prev,
                                personal_information: {
                                    ...prev.personal_information,
                                    health_insurance: {
                                        ...prev.personal_information.health_insurance,
                                        code: value
                                    }
                                }
                            }))
                        }
                    />
                </Form.Group>
            </Form.Group>

            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    disabled={isProcessing}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default OtherInfoForm