import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
// components
import Loading from '@components/general/Loading';

const PieGraphOrder = (props) => {
    const { t } = useTranslation();

    const all = props.ordered
    const awaiting = props.ordered - props.fulfilled

    const data = {
        labels: [
            t('awaiting'),
            t('fulfilled'),
        ],
        datasets: [{
            data: [awaiting > 0 ? awaiting : 0, props.fulfilled],
            backgroundColor: [
                '#FF4C78',
                '#6b48ff',
                '#EEE722'
            ],
            hoverBackgroundColor: [
                '#e73863',
                '#552fe4',
                '#c9c313'
            ]
        }]
    };
    return (
        <>
            {props.isProcessing? <Loading/> :
            <>
            <Doughnut data={data} options={{
                cutoutPercentage: 60,
            }} />
            <div style={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "bold" }}>
                {   all 
                    ? 
                        <>
                            <div>{props.fulfilled + " / " + props.ordered} ( { Math.round((props.fulfilled / props.ordered * 100))}{"%"} )</div>
                        </>
                    : 
                        <div>
                            <br/>
                            {t('nothing')}
                        </div>
                }
            </div>
            </>
            }
        </>


    )
}

export default PieGraphOrder
