import React from 'react';
// store 
import { API } from '@store/config';
// components
import { Grid } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import RecordsFilter from './RecordsFilter';

const TimesheetsFilters = ({ filter, setFilter, filtersRecords, setFiltersRecords, options }) => {

    return (
        <Grid verticalAlign="middle">
            <Grid.Row style={{ padding: 0 }}>
                <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right" style={{ paddingLeft: 0 }}>
                    <SuperField fluid as="choice"
                        search
                        text="name"
                        value={filter.team}
                        endpoint={API.TEAMS}
                        onChange={(e, { value }) => setFilter({ ...filter, team: value })}
                    />
                </Grid.Column>
                {/* <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right">
                    <SuperField fluid as="datepicker"
                        placeholder={t('date_start')}
                        value={filter.dateStart}
                        onChange={(e, { value }) => setFilter({ ...filter, dateStart: value })}
                    />
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right">
                    <SuperField fluid as="datepicker"
                        placeholder={t('date_end')}
                        value={filter.dateEnd}
                        onChange={(e, { value }) => setFilter({ ...filter, dateEnd: value })}
                    />
                </Grid.Column> */}
                <Grid.Column computer={12} tablet={16} mobile={16} textAlign="right" style={{ paddingRight: 0 }}>
                    <RecordsFilter filters={filtersRecords} setFilters={setFiltersRecords} options={options} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default TimesheetsFilters;