import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { jobsService } from '@services/ServiceJobs';
// components
import PublicationListView from '../components/publications/PublicationListView';

const PublicationList = ({ offer, onClose }) => {
    const { t } = useTranslation();
    const [publications, setPublications] = useState([]);
    const [externalPublications, setExternalPublications] = useState([]);
    const [loading, setLoading] = useState(true);

    const publish = async (id) => {
        const result = await jobsService.publishJobOffer(id);
        if (result?.status === 200) {
            setPublications((prevState) =>
                prevState.filter((item) => {
                    if (item.id === id) {
                        item.is_published = true;
                        item.external_offer_url = result.response.external_offer_url;
                    }

                    return item;
                })
            );

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('published_!'),
                description: t('hint_publication_has_to_be_processed'),
                animation: 'bounce',
                time: 10000,
            }); 

            if( result.response.external_offer_url !== undefined && result.response.external_offer_url === null && result.response.external_offer_url !== "" ){
                window.open(result.response.external_offer_url, '_blank');
            }

        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: " ",
                description: t('system_error_publish'),
                animation: 'bounce',
                time: 5000,
            }); 
        }
    };

    async function fetchResources(){
        setLoading(true)
        async function retrieveOfferUrls(){
            await getRequest(API.API_URL + "/integrations/kariera_sk/offer_urls/")
        }
        async function fetchPublications() {
            const result = await jobsService.getJobPublications('?job_offer=' + offer.id);

            if (result && result.status === 200) {
                setPublications(result.response);
            }
        }
        
        async function fetchExternalPublications() {
            const result = await getRequest(API.EXTERNAL_PUBLICATIONS + '?job_offer=' + offer.id);
            if (result && result.status === 200) {
                setExternalPublications(result.response);
            }
        }

        await retrieveOfferUrls()
        await fetchPublications();
        await fetchExternalPublications();
        setLoading(false)
    }

    useEffect( () => {
        fetchResources()
        // eslint-disable-next-line
    }, [offer.id]);

    return (
        <PublicationListView
            offer={offer}
            loading={loading}
            publications={publications}
            externalPublications={externalPublications}
            setPublications={setPublications}
            setExternalPublications={setExternalPublications}
            publish={publish}
            onClose={onClose}
        />
    );
};

export default PublicationList;
