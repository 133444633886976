import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { routes } from '@routes'
// components
import { Grid, Header } from 'semantic-ui-react';
// import InvoicesGraphs from '@pages/dashboard/sm/invoices/components/graphs/InvoicesGraphs'
import DashboardStatistics from './widgets/DashboardStatistics';
import Sidebar from './sidebar/Sidebar';
import Loading from '@components/general/Loading';
import MainNavigation from "./MainNavigation";

function SidebarWidgetsView({ changeViewType }) {
    const { t } = useTranslation();

    const [result] = useFetchData(API.DASHBOARD_STATS)

    return (
        <>
            <Sidebar />
            <div className="main">
                <MainNavigation changeViewType={changeViewType}/>
                {/* <GraphStatistics1 />
                <GraphStatistics2 /> */}
                <Grid centered stretched>
                    { result.isLoading ? <Loading/> :
                        <>
                            <DashboardStatistics
                                bcolor="white"
                                description={t('employees')}
                                icon="street view"
                                number={ result.data?.employees }
                                number2={ result.data?.new_employees > 0 ? "+" + result.data?.new_employees : 0 }
                                plus={result.data?.new_employees > 0 }
                            />
                            {/* <DashboardStatistics
                                    bcolor="white"
                                    description={t('hiring_rooms')}
                                    icon="winner"
                                    number="3"
                                    number2="+1"
                                    plus={true}
                            /> */}
                            <DashboardStatistics
                                bcolor="white"
                                description={t('active_projects')}
                                icon="folder open"
                                number={ result.data?.active_projects }
                                number2={ result.data?.new_active_projects > 0 ? "+" + result.data?.new_active_projects : 0 }
                                plus={result.data?.new_active_projects > 0}
                            />
                            <DashboardStatistics
                                bcolor="white"
                                description={t('open_issues')}
                                icon="file"
                                number={ result.data?.open_issues }
                                number2={ result.data?.new_open_issues > 0 ? "+" + result.data?.new_open_issues : 0 }
                                plus={result.data?.new_open_issues > 0}
                            />
                            {/* <DashboardStatistics
                                bcolor="#eeeeee"
                                description={t('unpaid_invoices')}
                                icon="file alternate"
                                number="7"
                                number2="36 589 €"
                            /> */}
                            <DashboardStatistics
                                bcolor="white"
                                description={t('new_orders')}
                                icon="clipboard list"
                                number={ result.data?.new_orders }
                                number2={ result.data?.new_orders_fee > 0 ? result.data?.new_orders_fee + "€" : 0 }
                                plus={result.data?.new_orders_fee > 0}
                            />
                        </>
                    }
                </Grid>
                {/* <InvoicesGraphs /> */}
                <Header as="h3" textAlign="center">
                    { t('go_to_reports') } <Link to={routes.REPORTS}>{ t('reports_Section') }</Link>
                </Header>
            </div>
        </>
    );
}

export default SidebarWidgetsView;