import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

// store
import { routes } from '@routes';
import { useHasPermissions } from '@helpers/hooks';
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { thousandsSeparators } from '@helpers/functions';
// components
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
// module components
import CostCenterForm from './CostCenterForm';
import CostCenterAllowedUsersManagement from './CostCenterAllowedUsersManagement';

const CCTable = ({ data, setData, currency }) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = useSelector(state => state.user)
    const [allowedUsers, setAllowedUsers] = useState([])
    const canManagePerm = useHasPermissions(['cost_centers.c_manage_cost_centers'])

    useEffect( () => {
        async function fetchAllowedUsers(){
            setIsLoading(true)
            const request = await requests.get(API.COST_CENTERS_ALLOWED_USERS + `?user=${currentUser.id}`)

            if( request.status === 200 ){
                setAllowedUsers(request.response)
            }
            setIsLoading(false)
        }

        fetchAllowedUsers()
        // eslint-disable-next-line
    }, [])

    async function onDelete(id) {
        setIsLoading(true)
        const request = await requests.del(API.COST_CENTERS + id + '/')
        if (request.status === 204) {
            setData(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count - 1,
                    results: data.filter(item => item.id !== id)
                }
            }))
        }
        setIsLoading(false)
    }

    const renderIncome = (item) => {
        if (currency !== "all") return "+" + parseFloat(item.converted_incomes).toFixed(2) + " " + currency
        if( item.total_incomes.length === 0 ) return 0

        return item.total_incomes.map(income => (
            <> + { thousandsSeparators(parseFloat(income.sum).toFixed(2)) + " " + income.currency } <br/> </>
        ))
    }

    const renderExpense = (item) => {
        if (currency !== "all") return "-" + parseFloat(item.converted_expenses).toFixed(2) + " " + currency

        if( item.total_expenses.length === 0 ) return 0

        return item.total_expenses.map(expense => (
            <> - { thousandsSeparators(parseFloat(expense.sum).toFixed(2)) + " " + expense.currency } <br/> </>
        ))
    }

    const renderDifference = (item) => {
        if (currency !== "all") return parseFloat(item.converted_incomes - item.converted_expenses).toFixed(2) + " " + currency
        if( item.total_expenses.length && item.total_incomes.length === 0 ) return 0

        const income_currencies = item.total_incomes.map(item => item.currency)
        const expense_currencies = item.total_expenses.map(item => item.currency)
        const merged_arrays = (income_currencies.concat(expense_currencies))
        const currencies = merged_arrays.filter((item, pos) => merged_arrays.indexOf(item) === pos)

        return currencies.map(currency => {
            let difference = 0
            // let the magic happens here - return undefind if not found or founded object
            const income = item.total_incomes.find(item => item.currency === currency)
            const expense = item.total_expenses.find(item => item.currency === currency)

            difference = parseFloat((income !== undefined ? income.sum : 0) - (expense !== undefined ? expense.sum : 0)).toFixed(2)

            return <> { thousandsSeparators(difference) + " " + currency } <br/></>
         })
    }

    const renderBudget = (item) => {
        if (currency !== "all") return parseFloat(item.converted_budgets).toFixed(2) + " " + currency

        if( item.total_budgets.length === 0 ) return 0

        return item.total_budgets.map(expense => (
            <> { thousandsSeparators(parseFloat(expense.sum).toFixed(2)) + " " + expense.currency } <br/> </>
        ))
    }

    return (
        <FlexTable>
            <FlexRow>
                <FlexHeader content={t("title")} />
                <FlexHeader content={t("unit")} />
                <FlexHeader content={t("projects")} />
                <FlexHeader content={t("income")} />
                <FlexHeader content={t("expense")} />
                <FlexHeader content={t("difference")} />
                <FlexHeader content={t("budget")} />
                <CanView permissions={['cost_centers.c_manage_cost_centers', 'cost_centers.c_view_incomes_expenses']}>
                    <FlexHeader content={t("actions")} />
                </CanView>
            </FlexRow>
            <EmptyRow length={data?.length || 0}/>
            { data.map(item => (
                <FlexRow key={item.id} fontSize="1rem" borders>
                    <FlexItem><b>{item.title}</b></FlexItem>
                    <FlexItem><b style={styles.unit}>{item.unit?.name}</b></FlexItem>
                    <FlexItem>{ item.projects.length }</FlexItem>
                    <FlexItem><b style={{ color: 'var(--success)' }}>{renderIncome(item)}</b></FlexItem>
                    <FlexItem><b style={{ color: 'var(--danger)' }}>{renderExpense(item)}</b></FlexItem>
                    <FlexItem><b style={{ color: 'var(--dark)' }}>{renderDifference(item)}</b></FlexItem>
                    <FlexItem><b style={{ color: 'var(--dark)' }}>{renderBudget(item)}</b></FlexItem>
                        <CanView permissions={['cost_centers.c_manage_cost_centers', 'cost_centers.c_view_incomes_expenses']}>
                            <FlexItem>
                                { (allowedUsers.find(user => user.cost_center.id === item.id)?.can_view === true || canManagePerm === true) &&
                                <Action
                                    type='icon'
                                    as="link"
                                    disabled={isLoading}
                                    to={routes.COST_CENTERS + "detail/" + item.id}
                                    tooltip={t('view_detail')}
                                />
                                }
                                <CanView permissions={['cost_centers.c_manage_cost_centers']}>
                                    <Action
                                        as="modal"
                                        type="icon"
                                        icon="settings-outline"
                                        modalSize="large"
                                        popupText={t('manage_allowed_users')}
                                        tooltip={t('allowed_users')}
                                        modal={
                                            <CostCenterAllowedUsersManagement currentUser={currentUser} costCenter={item} />
                                        }
                                    />
                                </CanView>
                            { (allowedUsers.find(user => user.cost_center.id === item.id)?.can_manage === true || canManagePerm === true) &&
                                <Action
                                    as="modal"
                                    type="icon"
                                    icon="pencil"
                                    popupText={t('update_cost_center')}
                                    tooltip={t('update_cost_center')}
                                    modal={
                                        <CostCenterForm setCostCenters={setData} data={item} />
                                    }
                                />
                            }
                            { (allowedUsers.find(user => user.cost_center.id === item.id)?.can_delete === true || canManagePerm === true) &&
                                <Action
                                    as="delete"
                                    tooltip={t('delete')}
                                    text={t('do_you_really_want_to_delete_cost_center')}
                                    onClick={() => onDelete(item.id)}
                                />
                            }
                            </FlexItem>
                        </CanView>
                </FlexRow>
            )) }
        </FlexTable>
    );
};

const styles = {
    icon: {
        cursor: 'pointer',
        margin: '0 1rem'
    },
    unit: {
        cursor: 'pointer',
        color: 'var(--primary)',
        fontWeight: 'bold'
    }
}
export default CCTable;