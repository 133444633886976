import React, { useState } from 'react'
// store
import { unitService } from '../../store/services/ServiceUnit';
import { isEmpty } from "../../store/services/helpers/validation"
import { companyService, serviceCompany } from '../../store/services/ServiceCompany';
// import { API } from '@store/config'
// components
import ModalCancel from '../buttons/ModalCancel';
import ModalSubmit from '../buttons/ModalSubmit';
import { DateInput } from 'semantic-ui-calendar-react';
import UnitTypeField from '../forms/common/UnitTypeField';
import { Form, TextArea, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
// import SuperField from '@components/forms/SuperField'

export const CompanyForm = ({ isUnit, data, onClose, onConfirm }) => {
    const [businessId] = useState(data ? data.id : 0); // if 0 then its Create Form, else Update
    const [desc, setDesc] = useState(data?.description ? data.description : "");
    const [name, setName] = useState(data?.name ? data.name : "");
    const [date, setDate] = useState(data?.opening_date ? data.opening_date : "");
    const [website, setWebsite] = useState(data?.website ? data.website : "");
    const [unitType, setUnitType] = useState(data?.unit_type ? data.unit_type.id : "");
    // const [responsiblePersons, setResponsiblePersons] = useState(data?.responsible_persons?.length > 0 ? data.responsible_persons[0] : {});
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errorList, setErrorList] = useState([]); // empty array

    const handleSubmit = async () => {
        setIsProcessing(true);

        if (isUnit) {
            const dataUnit = {
                name: name,
                opening_date: date === "" ? null : date,
                description: desc,
                unit_type: unitType,
                // responsible_persons: { add: [responsiblePersons] }
            }

            const result = await unitService.updateUnitHeader(businessId, dataUnit);

            if (result.status === 200) {
                onConfirm(result.response)
                onClose();
            } else {
                const error_message = result.response.website;
                setErrorList([...errorList, error_message]);
            }

        }
        else {
            const data = {
                name: name,
                opening_date: date === "" ? null : date,
                website: website,
                description: desc,
                is_active: true
            }

            if (businessId !== 0) {
                const result = await companyService.updateCompanyHeader(businessId, data);
                if (result.status === 200) {
                    onConfirm(result.response)
                    onClose();
                } else {
                    const error_message = result.response.website;
                    setErrorList([...errorList, error_message]);
                }
            } else {
                const result = await serviceCompany.createCompany(data);
                if (result.status === 201) {
                    onConfirm(result.response)
                    onClose();
                } else {
                    const error_message = result.response.website;
                    setErrorList([...errorList, error_message]);
                }


            }
        }

        setIsProcessing(false);
    }

    const addUrl = (value) => {
        setWebsite((value && !value.includes('http') ? 'https://' : '') + value)
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errorList.length > 0 &&
                <Message negative>

                    {errorList.map((error, index) =>
                        <p key={index}> {error} </p>
                    )}
                </Message>
            }

            <Form.Group widths={2}>
                {isUnit ?
                    <Form.Field>
                        <Form.Input
                            placeholder="Unit 1"
                            autoFocus
                            required
                            label={t('unit_name')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Field>

                    : <Form.Field>
                        <Form.Input
                            placeholder={t('name')}
                            autoFocus
                            required
                            label={t('name')}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Field>}


                <Form.Field>
                    <DateInput
                        placeholder="YYYY-MM-DD"
                        dateFormat="YYYY-MM-DD"
                        label={t('opening_date')}
                        name={t('opening_date')}
                        value={date}
                        iconPosition="left"
                        onChange={(e, data) => setDate(data.value)}
                    />
                </Form.Field>
            </Form.Group>

            {/* <SuperField as="choice"
                size="small"
                label={t('responsible_person')}
                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                text="fullname"
                search
                value={responsiblePersons}
                onChange={(e, data) => setResponsiblePersons(data.value)}
            /> */}

            {!isUnit &&
                <Form.Field>
                    <Form.Input
                        placeholder="www.company.com"
                        label={t('add_website')}
                        value={website}
                        onChange={(e) => addUrl(e.target.value)}
                    />
                </Form.Field>
            }

            {isUnit &&
                <UnitTypeField
                    value={unitType}
                    setValue={(e, data) => setUnitType(data.value)}
                />
            }

            <Form.Field>
                <Form.TextArea
                    placeholder="My company is ..."
                    label={t('description')}
                    control={TextArea}
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                />
            </Form.Field>

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(name)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

