import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// store
import { API } from '@store/config';
import { routes } from '@routes'
import { useHasPermissions } from '@helpers/hooks';
import { deleteRequest } from '@store/services/ServiceCommon';
// components
//import ConfirmModal from '@components/modals/ConfrimModal';
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Button, Divider, Grid, Label, Segment, Popup } from 'semantic-ui-react';
import { FlexHeader, FlexItem, FlexRow, FlexTable } from '@components/tables/FlexTable';
// specific components
import PublishModal from './forms/PublishModal';
import PublicationForm from './forms/PublicationForm';
import ExternalPublicatonForm from './forms/ExternalPublicatonForm';

const PublicationListView = ({
    offer,
    publications,
    setPublications,
    publish,
    onClose,
    loading,
    externalPublications,
    setExternalPublications,
}) => {
    const { t } = useTranslation();

    const [onPublicationCreate, setOnPublicationCreate] = useState(false);
    const canPublishToRabotaua = useHasPermissions(['integrations.c_rabota_ua_publications']);
    const canPublishToProfesia = useHasPermissions(['integrations.c_profesia_sk_publications']);
    const canPublishToKarierask = useHasPermissions(['integrations.c_kariera_sk_publications']);

    const [view, setView] = useState(0);
    const [viewMode, setViewMode] = useState(0);

    const PublicationHeader = ({ onClose }) => {
        return (
            <>
                <FlexRow>
                    <FlexItem basis="500%">
                        <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                            <Button active={view === 0} content={t('publications')} onClick={() => setView(0)} />
                            <Button
                                active={view === 1}
                                content={t('external_publications')}
                                onClick={() => setView(1)}
                            />
                        </Button.Group>
                    </FlexItem>
                    <FlexItem basis="50%">
                        <Button onClick={onClose}> {t('close')} </Button>
                    </FlexItem>
                    <CanView permissions={['jobs.c_general_publication']}></CanView>
                    <FlexItem basis="120%">
                        <Button fluid primary onClick={() => setOnPublicationCreate(true)}>
                            {t('create_publication')}
                        </Button>
                    </FlexItem>
                </FlexRow>
                <Divider />
            </>
        );
    };

    const onDelete = async (id) => {
        const request = await deleteRequest(API.EXTERNAL_PUBLICATIONS + id + '/');
        if (request.status === 204) {
            setExternalPublications((prevState) => prevState.filter((item) => item.id !== id));
        }
    };

    return (
        <>
            {loading ? (
                <Segment loading={loading} style={{ background: 'none', border: 'none' }} />
            ) : publications.length > 0 ? (
                <>
                    {onPublicationCreate ? (
                        <PublicationForm
                            offer={offer}
                            publications={publications}
                            setPublications={setPublications}
                            setExternalPublications={setExternalPublications}
                            setOnPublicationCreate={setOnPublicationCreate}
                            publish={publish}
                            onPublicationCreate={onPublicationCreate}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                        />
                    ) : (
                        <>
                            <PublicationHeader onClose={onClose} />
                            {view === 0 ? (
                                <FlexTable>
                                    <FlexRow>
                                        <FlexHeader content="#" basis="20%" />
                                        <FlexHeader content="" />
                                        <FlexHeader content={t('title')} basis="180%" />
                                        <FlexHeader content={t('published')} basis="120%"/>
                                        <FlexHeader content={t('valid_until')} />
                                        <FlexHeader content={t('published_by')} />
                                        <FlexHeader content={t('published_when')} />
                                        <FlexHeader content={t('actions')} />
                                    </FlexRow>

                                    {publications.map((publication, index) => (
                                        <FlexRow key={publication.id} fontSize="1rem" borders>
                                            <FlexItem bold basis="20%" content={index + 1 + '.'} />
                                            <FlexItem bold>
                                                <Label
                                                    size="small"
                                                    style={{
                                                        float: 'right',
                                                        color: 'var(--dark)',
                                                        backgroundColor: 'var(--warning)',
                                                        marginRight: '1rem',
                                                    }}
                                                >
                                                    {publication?.publishers?.length === 0
                                                        ? 'HRIIS'
                                                        : publication?.publishers_display_list?.length > 0 &&
                                                          publication?.publishers_display_list[0]}
                                                </Label>
                                            </FlexItem>
                                            <FlexItem bold basis="180%" content={publication?.job_offer?.title} />
                                            <FlexItem basis="120%" content={
                                                publication?.is_published 
                                                ? 
                                                <>
                                                    <strong>{ t('yes') }</strong>
                                                    { (publication.external_offer_url === undefined || publication.external_offer_url === "" || publication.external_offer_url === null)
                                                        && <small><br/> { t('waiting_for_processing') } </small> 
                                                    }
                                                </>
                                                : t('no')
                                            } />
                                            <FlexItem content={publication?.valid_until} />
                                            <FlexItem content={publication?.published_by?.name} />
                                            <FlexItem content={publication?.published_when} />
                                            <FlexItem>
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem>
                                                        {publication.is_published ? (
                                                            publication.external_offer_url === undefined || publication.external_offer_url === "" ? (
                                                                ''
                                                            ) : (
                                                                <Icon
                                                                    name="eye-outline"
                                                                    title={t('view_publications')}
                                                                    style={{
                                                                        color: 'var(--primary)',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        window.open(
                                                                            publication.external_offer_url,
                                                                            '_blank'
                                                                        )
                                                                    }
                                                                />
                                                            )
                                                        ) : (
                                                            <>
                                                                {publication?.publishers?.length > 0 &&
                                                                publication?.publishers[0] === 1 &&
                                                                canPublishToProfesia ? (
                                                                    <PublishModal
                                                                        publication={publication}
                                                                        publish={publish}
                                                                    />
                                                                ) : publication?.publishers?.length > 0 &&
                                                                  publication?.publishers[0] === 2 &&
                                                                  canPublishToProfesia ? (
                                                                    <PublishModal
                                                                        publication={publication}
                                                                        publish={publish}
                                                                    />
                                                                ) : publication?.publishers?.length > 0 &&
                                                                  publication?.publishers[0] === 3 &&
                                                                  canPublishToKarierask ? (
                                                                    <PublishModal
                                                                        publication={publication}
                                                                        publish={publish}
                                                                    />
                                                                ) : publication?.publishers?.length > 0 &&
                                                                  publication?.publishers[0] === 4 &&
                                                                  canPublishToRabotaua ? (
                                                                    <PublishModal
                                                                        publication={publication}
                                                                        publish={publish}
                                                                    />
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </>
                                                        )}
                                                    </FlexItem>
                                                    <FlexItem>
                                                        {/* Tu daj ikonku na republish alebo archiváciu publikácie */}
                                                    </FlexItem>
                                                    { publication.application_form_config !== null && 
                                                        <FlexItem>
                                                            <Link to={routes.PUBLIC_APPLICATION_FORM + publication.id} target="_blank">
                                                                <Popup 
                                                                    trigger={
                                                                        <Icon name="log-in-outline"/>
                                                                    }
                                                                    content={t('application_form_url')}
                                                                />
                                                                
                                                            </Link>
                                                        </FlexItem>
                                                    }
                                                </FlexRow>
                                            </FlexItem>
                                        </FlexRow>
                                    ))}
                                </FlexTable>
                            ) : (
                                <FlexTable>
                                    <FlexRow>
                                        <FlexHeader content="#" basis="20%" />
                                        <FlexHeader content={t('title')} basis="200%" />
                                        <FlexHeader content={t('url')} basis="200%" />
                                        <FlexHeader content={t('published')} />
                                        <FlexHeader content={t('valid_until')} />
                                        <FlexHeader content={t('portal')} />
                                        <FlexHeader content={''} />
                                    </FlexRow>

                                    {externalPublications.map((publication, index) => (
                                        <FlexRow key={publication.id} fontSize="1rem" borders>
                                            <FlexItem bold basis="20%" content={index + 1 + '.'} />
                                            <FlexItem bold content={publication.title} basis="200%" />
                                            <FlexItem
                                                basis="200%"
                                                content={
                                                    <a href={publication.url} target="_blank" rel="noopener noreferrer">
                                                        {publication.url}
                                                    </a>
                                                }
                                            />
                                            <FlexItem content={publication.is_published ? t('yes') : t('no')} />
                                            <FlexItem content={publication.published_when} />
                                            <FlexItem content={publication?.portal?.name} />
                                            <FlexItem>
                                                <FlexRow padding="0" background="transparent">
                                                    <CanView permissions={['courses.c_manage_all_courses']}>
                                                        <FlexItem>
                                                            <SuperDuperModal
                                                                trigger={
                                                                    <Icon
                                                                        name="pencil"
                                                                        style={{
                                                                            color: 'var(--dark)',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                }
                                                                size="small"
                                                                content={
                                                                    <ExternalPublicatonForm
                                                                        externalPublication={publication}
                                                                        setExternalPublications={
                                                                            setExternalPublications
                                                                        }
                                                                        offer={offer}
                                                                        setViewMode={setViewMode}
                                                                        viewMode={viewMode}
                                                                        externalPublications={externalPublications}
                                                                    />
                                                                }
                                                            />
                                                        </FlexItem>
                                                        <FlexItem>
                                                            <ConfirmModal
                                                                description={t('are_you_sure')}
                                                                onConfirm={() => onDelete(publication.id)}
                                                                button={
                                                                    <Icon
                                                                        name="close-outline"
                                                                        style={{
                                                                            color: 'var(--danger)',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </FlexItem>
                                                    </CanView>
                                                </FlexRow>
                                            </FlexItem>
                                        </FlexRow>
                                    ))}
                                </FlexTable>
                            )}
                        </>
                    )}
                </>
            ) : (
                <>
                    {onPublicationCreate ? (
                        <PublicationForm
                            offer={offer}
                            publications={publications}
                            externalPublications={externalPublications}
                            setPublications={setPublications}
                            setOnPublicationCreate={setOnPublicationCreate}
                            setExternalPublications={setExternalPublications}
                            publish={publish}
                            onPublicationCreate={onPublicationCreate}
                            viewMode={viewMode}
                            setViewMode={setViewMode}
                        />
                    ) : (
                        <>
                            <PublicationHeader onClose={onClose} />
                            <Grid centered>
                                <Grid.Row>
                                    <h3>{t('zero_publications')}</h3>
                                </Grid.Row>
                            </Grid>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default PublicationListView;
