import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components 
import { Form, Icon } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const ManageStoreItemForm = ({ parentItem, action, storedItem, setStoredItems, onClose }) => {
    // @docs - Used to perform Correction or Discard operation on Stored Item
    const { t } = useTranslation()

    const [isProcessing, setIsProcessing] = useState(false)
    const [errorQuantity, setErrorQuantity] = useState(null)
    const [form, setForm] = useState({
        reason: "",
        quantity: 0
    })

    const handleSubmit = async () => {
        setIsProcessing(true)
        setErrorQuantity(null)
        const data = {
            stored_item: storedItem.id,
            ...form
        }
        let request = undefined
        if( action === "correction" ){
            request = await requests.post(API.ASSET_CORRECTIONS, data)
        } else if( action === "discard" ){
            request = await requests.post(API.ASSET_DISCARDS, data)
        }

        if( request !== undefined){
            if( request.status === 201){
                if (parentItem?.id !== undefined) {
                    const getStoredItem = await requests.get(API.ASSET_STORED_ITEMS + parentItem?.id + "/")
                    if (getStoredItem.status === 200) {
                        setStoredItems(prev => ({
                            ...prev,
                            data: {
                                ...prev.data,
                                results: prev.data.results.map(item => {
                                    if( item.id === parentItem.id ){
                                        item = getStoredItem.response
                                    }
                
                                    return item
                                })
                            }
                        }))
                    } 
                } else {
                    setStoredItems(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            count: prev.data.count,
                            results: prev.data.results.filter(item => {
                                if( item.id === storedItem.id ){
                                    item.quantity = request.response.stored_item.quantity
                                }
        
                                return item
                            })
                        }
                    }))
                }
                onClose()
            } else {
                if( request.response.quantity ){
                    setErrorQuantity(request.response.quantity[0])
                }
                
                if( request.response.non_field_errors ){
                    setErrorQuantity(request.response.non_field_errors[0])
                }
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input" type="number"
                required
                autoFocus
                label={t('quantity')}
                value={form.quantity}
                error={errorQuantity}
                onChange={(e, { value }) => setForm({ ...form, quantity: value })}
            />
            <p>
                { t('current_stock') }: <strong>{ storedItem.quantity }</strong>
                { form.quantity < 0 && <span style={{ color: "var(--danger)" }}> { form.quantity } </span> }
                { form.quantity > 0 && <span style={{ color: action === "discard" ? "var(--danger)" : "var(--blue)" }}> { action === "discard" ? <>- { form.quantity }</> : <>+ { form.quantity }</> }</span> }
                { (parseInt(storedItem.quantity) + parseInt(form.quantity) < 0) 
                    && 
                    <span style={{ color: "var(--danger)", marginLeft: "1rem" }}>
                        <Icon name="warning circle" /> 
                        { t('not_allowed') }
                    </span>
                }
            </p>

            <SuperField as="textarea"
                label={t('reason')}
                value={form.reason}
                onChange={(e, { value }) => setForm({ ...form, reason: value })}
            />

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel 
                    disabled={isProcessing}
                    onClose={onClose} 
                />
                <ModalSubmit 
                    text={t('confirm')}
                    loading={isProcessing}
                    disabled={isProcessing || form.quantity === 0 || form.quantity === "" || (parseInt(storedItem.quantity) + parseInt(form.quantity) < 0)}
                />
            </Form.Field>
        </Form>
    );
};

export default ManageStoreItemForm;