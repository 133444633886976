import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
//components
import ChoiceField from '@components/forms/common/ChoiceField'
import { Header, Grid, Divider, Button, Form } from 'semantic-ui-react'

const UserFilterForm = ({ fetchUsers, selectedFilter, search, onClose }) => {
    const { t } = useTranslation();

    const initState = {
        role: ""
    }

    const [filters, setFilters] = useState(initState)

    const onFilter = () => {
        let params = "?title=" + search

        if (selectedFilter === 2) {
            params += "&is_active=true"
        }
        if (selectedFilter === 3) {
            params += "&is_active=false"
        }

        if (filters.role !== "") {
            params += "&role=" + filters.role
        }

        fetchUsers(params)
        onClose()

    }

    const resetFilters = async () => {
        setFilters(initState)
    }

    return (
        <div>
            <Grid>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        <Header size="large" content={t('advanced_filter')} style={{ marginTop: "0.2rem" }} />
                    </Grid.Column>
                </Grid.Row>
                <Divider />
            </Grid>

            <Form>
                <ChoiceField
                    search
                    type="roles"
                    label={t('role')}
                    value={filters.role}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        role: value
                    })}
                />
                <Form.Field style={{ textAlign: "right" }}>
                    <Button size="medium" content={t('reset')} style={{}} onClick={() => resetFilters()} />
                    <Button size="medium" content={t('apply')} style={{ backgroundColor: "var(--primary)", color: "white" }} onClick={() => onFilter()} />
                </Form.Field>
            </Form>
        </div>
    );
};

export default UserFilterForm;