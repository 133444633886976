import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
//store
import { requests } from '@helpers/requests';
//other
import SuperField from '@components/forms/SuperField';
import { Form, Button, Message } from 'semantic-ui-react';


function PersonalInfoForm({data, setView, onConfirm, patchEndpoint}) {
    const { t } = useTranslation();

    const person_data = {
        firstname: data?.personal_information?.person?.firstname || "",
        lastname: data?.personal_information?.person?.lastname || "",
        middlename: data?.personal_information?.person?.middlename || "",
        titles_before: data?.personal_information?.person?.titles_before || "",
        titles_after: data?.personal_information?.person?.titles_after || "",
        gender: data?.personal_information?.person?.gender || 3
    }

    const birth_info_data = {
        date_of_birth: data?.personal_information?.birth_info?.date_of_birth  || null,
        place_of_birth: data?.personal_information?.birth_info?.place_of_birth  || "",
        name_at_birth: data?.personal_information?.birth_info?.name_at_birth  || ""
    }

    const permanent_address_data = {
        street: data?.personal_information?.permanent_address?.street  || "",
        city: data?.personal_information?.permanent_address?.city  || "",
        postcode: data?.personal_information?.permanent_address?.postcode  || "",
        country: data?.personal_information?.permanent_address?.country  || null,
        state: data?.personal_information?.permanent_address?.state  || "",
        address_line: data?.personal_information?.permanent_address?.address_line  || ""
    }

    const temporary_address_data = {
        street: data?.personal_information?.temporary_address?.street  || "",
        city: data?.personal_information?.temporary_address?.city  || "",
        postcode: data?.personal_information?.temporary_address?.postcode  || "",
        country: data?.personal_information?.temporary_address?.country  || null,
        state: data?.personal_information?.temporary_address?.state  || "",
        address_line: data?.personal_information?.temporary_address?.address_line  || ""
    }

    const personal_information_data = {
        person: person_data,
        birth_info: birth_info_data,
        marital_status: data?.personal_information?.marital_status || null,
        personal_number: data?.personal_information?.personal_number || "",
        permanent_address: permanent_address_data,
        temporary_address: temporary_address_data
    }

    const [form, setForm] = useState({
        personal_information: personal_information_data
    })

    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async () => {

        setIsProcessing(true);
        if (data.personal_information.personal_number === form.personal_information.personal_number) {
            delete form.personal_information.personal_number
        }
        const request = await requests.patch(patchEndpoint + `${data?.id}/`, form)

        if (request.status === 200) {
            onConfirm(request.response)
            setView(0)
        } else if( request.status === 400 ) {
            setErrors(request.response)
        }

        setIsProcessing(false);
    }

    return (

        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />

            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('firstname')}
                    placeholder={t('firstname')}
                    error={ errors?.personal_information?.person?.firstname?.[0] || false }
                    value={form?.personal_information?.person?.firstname}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                person: {
                                  ...prev.personal_information.person,
                                  firstname: value
                               }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    required
                    label={t('lastname')}
                    placeholder={t('lastname')}
                    error={ errors?.personal_information?.person?.lastname?.[0] || false }
                    value={form?.personal_information?.person?.lastname}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                person: {
                                  ...prev.personal_information.person,
                                  lastname: value
                               }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('middlename')}
                    placeholder={t('middlename')}
                    error={ errors?.personal_information?.person?.middlename?.[0] || false }
                    value={form?.personal_information?.person?.middlename}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                person: {
                                  ...prev.personal_information.person,
                                  middlename: value
                               }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('titles_before')}
                    placeholder={t('titles_before')}
                    error={ errors?.personal_information?.person?.titles_before?.[0] || false }
                    value={form?.personal_information?.person?.titles_before}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                person: {
                                  ...prev.personal_information.person,
                                  titles_before: value
                               }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('titles_after')}
                    placeholder={t('titles_after')}
                    error={ errors?.personal_information?.person?.titles_after?.[0] || false }
                    value={form?.personal_information?.person?.titles_after}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                person: {
                                  ...prev.personal_information.person,
                                  titles_after: value
                               }
                            }
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="datepicker"
                    label={ t('date_of_birth') }
                    error={ errors?.personal_information?.birth_info?.date_of_birth?.[0] || false }
                    value={form?.personal_information?.birth_info?.date_of_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                birth_info: {
                                    ...prev.personal_information.birth_info,
                                    date_of_birth: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('place_of_birth')}
                    placeholder={t('place_of_birth')}
                    error={ errors?.personal_information?.birth_info?.place_of_birth?.[0] || false }
                    value={form?.personal_information?.birth_info?.place_of_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                birth_info: {
                                    ...prev.personal_information.birth_info,
                                    place_of_birth: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('name_at_birth')}
                    placeholder={t('name_at_birth')}
                    error={ errors?.personal_information?.birth_info?.name_at_birth?.[0] || false }
                    value={form?.personal_information?.birth_info?.name_at_birth}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                birth_info: {
                                    ...prev.personal_information.birth_info,
                                    name_at_birth: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="choice"
                    type="genders"
                    label={t('gender')}
                    error={ errors?.personal_information?.person?.gender?.[0] || false }
                    value={form?.personal_information?.person?.gender?.toString() || ""}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                person: {
                                    ...prev.personal_information.person,
                                    gender: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="choice"
                    search
                    type="marital_statuses"
                    label={t('marital_status')}
                    error={ errors?.personal_information?.marital_status?.[0] || false }
                    value={form?.personal_information?.marital_status?.toString() || ""}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                marital_status: value
                            }
                        }))
                    }
                />

            </Form.Group>
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    label={t('personal_number')}
                    placeholder={t('personal_number')}
                    error={ errors?.personal_information?.personal_number?.[0] || false }
                    value={form?.personal_information?.personal_number}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                personal_number: value
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('permanent_address_street')}
                    placeholder={t('street')}
                    error={ errors?.personal_information?.permanent_address?.street?.[0] || false }
                    value={form?.personal_information?.permanent_address?.street}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                permanent_address: {
                                    ...prev.personal_information.permanent_address,
                                    street: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('permanent_address_city')}
                    placeholder={t('city')}
                    error={ errors?.personal_information?.permanent_address?.city?.[0] || false }
                    value={form?.personal_information?.permanent_address?.city}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                permanent_address: {
                                    ...prev.personal_information.permanent_address,
                                    city: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('permanent_address_postcode')}
                    placeholder={t('postcode')}
                    error={ errors?.personal_information?.permanent_address?.postcode?.[0] || false }
                    value={form?.personal_information?.permanent_address?.postcode}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                permanent_address: {
                                    ...prev.personal_information.permanent_address,
                                    postcode: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="choice"
                    type="countries"
                    search
                    label={t('permanent_address_country')}
                    error={ errors?.personal_information?.permanent_address?.country?.[0] || false }
                    value={form?.personal_information?.permanent_address?.country?.toString()}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                permanent_address: {
                                    ...prev.personal_information.permanent_address,
                                    country: value
                                }
                            }
                        }))
                    }
                />
            </Form.Group>
            <Form.Group widths={5} style={{ paddingTop: "0.6rem", paddingBottom: "0.6rem"}}>
                <SuperField as="input"
                    label={t('temporary_address_street')}
                    placeholder={t('street')}
                    error={ errors?.personal_information?.temporary_address?.street?.[0] || false }
                    value={form?.personal_information?.temporary_address?.street}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                temporary_address: {
                                    ...prev.personal_information.temporary_address,
                                    street: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('temporary_address_city')}
                    placeholder={t('city')}
                    error={ errors?.personal_information?.temporary_address?.city?.[0] || false }
                    value={form?.personal_information?.temporary_address?.city}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                temporary_address: {
                                    ...prev.personal_information.temporary_address,
                                    city: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="input"
                    label={t('temporary_address_postcode')}
                    placeholder={t('postcode')}
                    error={ errors?.personal_information?.temporary_address?.postcode?.[0] || false }
                    value={form?.personal_information?.temporary_address?.postcode}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                temporary_address: {
                                    ...prev.personal_information.temporary_address,
                                    postcode: value
                                }
                            }
                        }))
                    }
                />
                <SuperField as="choice"
                    type="countries"
                    search
                    label={t('temporary_address_country')}
                    error={ errors?.personal_information?.temporary_address?.country?.[0] || false }
                    value={form?.personal_information?.temporary_address?.country?.toString()}
                    onChange={(e, { value }) =>
                        setForm(prev => ({
                            ...prev,
                            personal_information: {
                                ...prev.personal_information,
                                temporary_address: {
                                    ...prev.personal_information.temporary_address,
                                    country: value
                                }
                            }
                        }))
                    }
                />
            </Form.Group>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    primary
                    loading={isProcessing}
                    type="submit"
                    disabled={isProcessing}
                >
                    {t('confirm')}
                </Button>
            </Form.Field>
        </Form>
    )
}

export default PersonalInfoForm