import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import moment from 'moment';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { requests } from "@helpers/requests";
import { useQueryPage, useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import SuperField from '@components/forms/SuperField';
import ConfrimModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Divider, Segment, Button, Form, Popup } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import PlannedCourseForm from '../components/PlannedCourseForm';
import PlannedCoursesFilters from '../components/PlannedCoursesFilters';

const PlannedCoursesList = ({ plannedCourses, setPlannedCourses, categories }) => {
    const { t } = useTranslation()
    const canManageCourses = useHasPermissions(['courses.c_manage_all_courses']);

    let page = useQueryPage()
    const history = useHistory()

    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [reFetched, setReFetched] = useState(false)

    const initState = {
        course: "",
        dateStart: "",
        dateEnd: "",
        isOpened: false,
        isClosed: false
    }

    const [filters, setFilters] = useState(initState)
    const location = useLocation()

    const onFilter = () => {
        let filterBaseParams = ""

        if (filters.course !== "") filterBaseParams += "&course=" + filters.course
        if (filters.dateStart !== "") filterBaseParams += "&start_date=" + filters.dateStart;
        if (filters.dateEnd !== "") filterBaseParams += "&date_end=" + filters.dateEnd;
        if (filters.isOpened && !filters.isClosed) {
            filterBaseParams += "&is_closed=false"
        } else if (!filters.isOpened && filters.isClosed) {
            filterBaseParams += "&is_closed=true"
        }

        return filterBaseParams
    }

    const fetchPlannedCourses = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if (!plannedCourses.isLoading) {
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if (!params.includes('page')) {
                params += "&page=" + page
            }

            if (!params.includes('limit')) {
                params += "&limit=" + limit
            }

            setPlannedCourses(prevState => ({
                ...prevState,
                isLoading: true
            }))

            const request = await requests.get(API.PLANNED_COURSES + "?paginate=true" + params)
            if (request.status === 200) {
                setPlannedCourses(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }

            setReFetched(false)
            setLoading(false)
        }
    }

    async function onDelete(id) {
        const request = await requests.del(API.PLANNED_COURSES + id + "/")
        if (request.status === 204) {
            setPlannedCourses(prev => ({
                ...prev, 
                data: {
                    ...prev.data,
                    count: prev.data.count - 1,
                    results: prev.data.results.filter(item => item.id !== id)
                }
            }))
        }
    }

    const FlexRowWithToggle = ({ rowContent, content, loading }) => {
        const [open, setOpen] = useState(false)

        return (
            <Segment style={{ padding: 0, marginBottom: "0.5rem", margin: 0 }}>
                <FlexRow borders>
                    <FlexItem textAlign="center" basis="10%">
                        <Icon
                            style={{ cursor: 'pointer', marginRight: "0.5rem" }}
                            name={open ? 'chevron-up-outline' : 'chevron-down-outline'}
                            onClick={() => setOpen(!open)}
                        />
                    </FlexItem>
                    {rowContent}
                </FlexRow>
                {open &&
                    <Segment loading={loading} style={{ padding: 0, marginTop: 0, border: "none" }}>
                        {content}
                    </Segment>
                }
            </Segment>
        )
    }

    const PlannedCourseRow = ({ plannedCourse }) => {
        return (
            <>
                <FlexItem bold basis="120%">
                    {plannedCourse.course?.title}
                </FlexItem>
                <FlexItem bold basis="120%">
                    {plannedCourse.responsible_person?.fullname
                        ? <Link className="ref-link" to={routes.EMPLYOEES_DETAIL + plannedCourse.responsible_person?.id} target="_blank">{plannedCourse.responsible_person?.fullname}</Link>
                        : "--"
                    }
                </FlexItem>
                <FlexItem bold>
                    <Icon name="people-outline" style={{ marginRight: "1rem" }} />
                    <span style={{ position: "relative", top: "-0.2rem" }}>
                        {plannedCourse.participants.length}
                    </span>
                </FlexItem>
                <FlexItem>
                    {plannedCourse.start_date}
                </FlexItem>
                <FlexItem>
                    {plannedCourse.end_date}
                </FlexItem>
                <FlexItem bold>
                    {plannedCourse.is_closed
                        ? <span style={{ color: "var(--danger)" }}>{t('closed')}</span>
                        : <span style={{ color: "var(--success)" }}>{t('opened')}</span>
                    }
                </FlexItem>
                <CanView permissions={["courses.c_manage_all_courses"]}>
                    <FlexRow padding="0" background="transparent">
                        <FlexItem basis="20%">
                            <SuperDuperModal
                                trigger={
                                    <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }} />
                                }
                                size='small'
                                content={
                                    <PlannedCourseForm
                                        plannedCourse={plannedCourse}
                                        plannedCourses={plannedCourses}
                                        setPlannedCourses={setPlannedCourses} />
                                }
                            />
                        </FlexItem>
                        <FlexItem basis="20%">
                            <ConfrimModal
                                description={t('are_you_sure')}
                                onConfirm={() => onDelete(plannedCourse.id)}
                                button={
                                    <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer", zIndex: 100 }} />}
                            />
                        </FlexItem>
                    </FlexRow>
                </CanView>
            </>
        )
    }

    const ParticipantList = ({ participants, course, plannedCourse, closed }) => {
        const [closing, setClosing] = useState(false)
        const [loadingRecords, setLoadingRecords] = useState(false)
        const [participantsData, setParticipantsData] = useState([])

        async function fetchRecordsForParticipants() {
            setLoadingRecords(true)
            for (const participant of participants) {
                const request = await requests.get(API.COURSES_RECORDS + `?query={id, course{id}, failed, made_on, valid_from, valid_until, person{id, fullname, profile_picture}}&person=${participant.id}&course=${course}`)

                if (request.response.length > 0) {
                    setParticipantsData(prevState => {
                        return [...prevState, {
                            ...request.response[0],
                            person: participant,
                            isProcessing: false
                        }]
                    })
                } else {
                    setParticipantsData(prevState => {
                        return [...prevState, {
                            id: 0,
                            person: participant,
                            course: { id: course },
                            failed: false,
                            made_on: '',
                            valid_from: '',
                            valid_until: '',
                            isProcessing: false
                        }]
                    })
                }
            }
            setLoadingRecords(false)
        }


        useEffect(() => {
            fetchRecordsForParticipants()
            // eslint-disable-next-line
        }, [])

        function setProcessing(id, status) {
            setParticipantsData(participantsData.filter(item => {
                if (item.person.id === id) {
                    item.isProcessing = status
                }

                return item
            }))
        }

        function validateDates(validFrom, validUntil) {
            if (validFrom !== "" && validUntil !== "") {
                if (moment(validFrom).isAfter(validUntil)) {
                    return t('invalid_date_should_be_higher');
                }
            }

            return false
        }

        function validateFilledData(data) {
            if (data.failed === "" || data.made_on === "" || data.valid_from === "" || data.valid_until === "") {
                return true
            }

            return false
        }

        function handleData(id, value, type) {
            setParticipantsData(participantsData.filter(item => {
                if (item.person.id === id) item[type] = value
                return item
            }))
        }

        function handleApplyToAll() {
            setParticipantsData(participantsData.filter(item => {
                item.failed = participantsData[0].failed
                item.valid_from = participantsData[0].valid_from
                item.valid_until = participantsData[0].valid_until
                item.made_on = participantsData[0].made_on
                return item
            }))
        }

        function handleApplyToAllField(field) {
            setParticipantsData(participantsData.filter(item => {
                item[field] = participantsData[0][field]
                return item
            }))
        }

        async function evaluateParticipant(record) {
            setProcessing(record.person.id, true)
            const result = await requests.post(API.COURSES_RECORDS, {
                made_on: record.made_on,
                valid_from: record.valid_from,
                valid_until: record.valid_until,
                failed: record.failed,
                course: record.course?.id,
                person: record.person.id
            })

            if (result.status === 201) {
                setParticipantsData(participantsData.map(item => {
                    if (item.person.id === result.response.person.id) {
                        item.id = result.response.id
                    }

                    return item;
                }));
            }

            setProcessing(record.person.id, false)
        }

        async function updateRecord(record) {
            setProcessing(record.person.id, true)
            const result = await requests.put(API.COURSES_RECORDS + record.id + "/", {
                made_on: record.made_on,
                valid_from: record.valid_from,
                valid_until: record.valid_until,
                failed: record.failed,
                course: record.course?.id,
                person: record.person.id
            })

            if (result.status === 200) {
                setParticipantsData(participantsData.map(item => {
                    if (item.id === result.response.id) {
                        return {
                            ...item,
                            id: result.response.id
                        }
                    }
                    return item;
                }));
            }

            setProcessing(record.person.id, false)
        }

        function checkIfAllRecordsAreEvaluated() {
            let counter = 0
            for (const item of participantsData) {
                if (item.id !== 0) counter++
            }
            if (counter === participantsData.length) return true
            return false
        }

        async function closeCourse() {
            setClosing(true)
            const request = await requests.patch(API.PLANNED_COURSES + plannedCourse + "/", { is_closed: true })
            if (request.status === 200) {
                setPlannedCourses(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.filter(item => {
                            if (item.id === request.response.id) {
                                item.is_closed = true
                            }
                            return item;
                        })
                    }
                }));
            }
            setClosing(false)
        }

        if (participantsData.length === 0) return <FlexRow><FlexItem bold textAlign="center"> {t('no_records')} </FlexItem></FlexRow>

        return (
            <Segment loading={loadingRecords}>
                <FlexTable stripped={false}>
                    <FlexRow fontSize="1rem" borders>
                        <FlexHeader content={t('participants')} />
                        <FlexHeader content={
                            <>
                                {t('status')}
                                {participants.length > 1 &&
                                    <CanView permissions={["courses.c_manage_all_courses"]}>
                                        <Popup basic
                                            content={t('apply_to_all')}
                                            trigger={
                                                <Icon
                                                    onClick={() => handleApplyToAllField('failed')}
                                                    name="chevron-down-circle-outline"
                                                    style={{ fontSize: "1.2rem", marginLeft: "1rem", cursor: 'pointer' }}
                                                />
                                            }
                                        />
                                    </CanView>
                                }
                            </>
                        } />
                        <FlexHeader content={
                            <>
                                {t('made_on')}
                                {participants.length > 1 &&
                                    <CanView permissions={["courses.c_manage_all_courses"]}>
                                        <Popup basic
                                            content={t('apply_to_all')}
                                            trigger={
                                                <Icon
                                                    onClick={() => handleApplyToAllField('made_on')}
                                                    name="chevron-down-circle-outline"
                                                    style={{ fontSize: "1.2rem", marginLeft: "1rem", cursor: 'pointer' }}
                                                />
                                            }
                                        />
                                    </CanView>
                                }
                            </>
                        } />
                        <FlexHeader content={
                            <>
                                {t('valid_from')}
                                {participants.length > 1 &&
                                    <CanView permissions={["courses.c_manage_all_courses"]}>
                                        <Popup basic
                                            content={t('apply_to_all')}
                                            trigger={
                                                <Icon
                                                    onClick={() => handleApplyToAllField('valid_from')}
                                                    name="chevron-down-circle-outline"
                                                    style={{ fontSize: "1.2rem", marginLeft: "1rem", cursor: 'pointer' }}
                                                />
                                            }
                                        />
                                    </CanView>
                                }
                            </>
                        } />
                        <FlexHeader content={
                            <>
                                {t('valid_until')}
                                {participants.length > 1 &&
                                    <CanView permissions={["courses.c_manage_all_courses"]}>
                                        <Popup basic
                                            content={t('apply_to_all')}
                                            trigger={
                                                <Icon
                                                    onClick={() => handleApplyToAllField('valid_until')}
                                                    name="chevron-down-circle-outline"
                                                    style={{ fontSize: "1.2rem", marginLeft: "1rem", cursor: 'pointer' }}
                                                />
                                            }
                                        />
                                    </CanView>
                                }
                            </>
                        } />
                        <FlexHeader content={""} />
                    </FlexRow>
                    <Form>
                        {participantsData.map((participant, index) => (
                            <FlexRow key={participant.person.id} borders>
                                <FlexItem bold content={participant.person.fullname} />
                                <FlexItem>
                                    <Button.Group size="tiny" basic style={{ borderRadius: "0" }}>
                                        <Button disabled={!canManageCourses} active={!participant.failed} onClick={() => handleData(participant.person.id, false, "failed")}>{t('success')}</Button>
                                        <Button disabled={!canManageCourses} active={participant.failed} onClick={() => handleData(participant.person.id, true, "failed")}>{t('failure')}</Button>
                                    </Button.Group>
                                </FlexItem>
                                <FlexItem>
                                    <SuperField
                                        disabled={!canManageCourses}
                                        style={{ width: "70%", height: "2.5rem", fontSize: "1rem" }}
                                        as="datepicker"
                                        value={participant.made_on}
                                        onChange={(e, { value }) => handleData(participant.person.id, value, "made_on")} />
                                </FlexItem>
                                <FlexItem>
                                    <SuperField
                                        disabled={!canManageCourses}
                                        style={{ width: "70%", height: "2.5rem", fontSize: "1rem" }}
                                        as="datepicker"
                                        value={participant.valid_from}
                                        onChange={(e, { value }) => handleData(participant.person.id, value, "valid_from")} />
                                </FlexItem>
                                <FlexItem>
                                    <SuperField
                                        disabled={!canManageCourses}
                                        style={{ width: "70%", height: "2.5rem", fontSize: "1rem" }}
                                        as="datepicker"
                                        error={validateDates(participant.valid_from, participant.valid_until)}
                                        value={participant.valid_until}
                                        onChange={(e, { value }) => handleData(participant.person.id, value, "valid_until")} />
                                </FlexItem>
                                <FlexItem >
                                    <FlexRow padding="0" background="transparent">
                                        <CanView permissions={["courses.c_manage_all_courses"]}>
                                            <FlexItem>
                                                {participant.id === 0
                                                    ?
                                                    <Button
                                                        primary
                                                        size="tiny"
                                                        onClick={() => evaluateParticipant(participant)}
                                                        loading={participant.isProcessing}
                                                        disabled={validateFilledData(participant) || participant.isProcessing}
                                                    >
                                                        {t('evaluate')}
                                                    </Button>
                                                    :
                                                    <Button
                                                        size="tiny"
                                                        style={{ background: "var(--black)", color: "var(--white)" }}
                                                        onClick={() => updateRecord(participant)}
                                                        loading={participant.isProcessing}
                                                        disabled={validateFilledData(participant) || participant.isProcessing}
                                                    >
                                                        {t('update')}
                                                    </Button>
                                                }
                                            </FlexItem>
                                        </CanView>
                                        {index === 0 &&
                                            <>
                                                {participants.length > 1 &&
                                                    <CanView permissions={["courses.c_manage_all_courses"]}>
                                                        <FlexItem>
                                                            <Popup basic content={t('apply_to_all')} trigger={
                                                                <Icon
                                                                    onClick={() => handleApplyToAll()}
                                                                    name="chevron-down-circle-outline"
                                                                    style={{ cursor: 'pointer' }} />
                                                            } />
                                                        </FlexItem>
                                                    </CanView>
                                                }
                                            </>
                                        }
                                    </FlexRow>
                                </FlexItem>
                            </FlexRow>
                        ))}
                    </Form>
                </FlexTable>
                {!closed &&
                    <CanView permissions={["courses.c_manage_all_courses"]}>
                        <Form.Field style={{ textAlign: "right", marginRight: "9rem" }}>
                            <Button
                                disabled={!checkIfAllRecordsAreEvaluated()}
                                loading={closing}
                                style={{ margin: "1rem", background: "var(--danger)", color: "var(--white)" }}
                                content={t('close')}
                                onClick={() => closeCourse()}
                            />
                        </Form.Field>
                    </CanView>
                }
            </Segment>
        )
    }

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchPlannedCourses("", true)
    }

    useEffect(() => {
        handleSubmit()
        // eslint-disable-next-line
    }, [filters])

    return (
        <>
            <Divider />
            <PlannedCoursesFilters filter={filters} setFilter={setFilters} />
            <Divider />
            {plannedCourses.isLoading ? <Loading />
                : (!reFetched && !loading) &&
                <>
                    <FlexTable stripped={false} hoverable={false}>
                        <FlexRow>
                            <FlexHeader basis="10%" />
                            <FlexHeader basis="120%" content={t('course')} />
                            <FlexHeader content={t('responsible_person')} />
                            <FlexHeader content={t('participants')} />
                            <FlexHeader content={t('start_date')} />
                            <FlexHeader content={t('end_date')} />
                            <FlexHeader content={t('status')} />
                            <CanView permissions={["courses.c_manage_all_courses"]}>
                                <FlexHeader content={t('actions')} />
                            </CanView>
                        </FlexRow>
                        <EmptyRow length={plannedCourses?.data?.results?.length || 0}/>
                        {plannedCourses?.data?.results?.map(plannedCourse => (
                            <FlexRowWithToggle
                                key={plannedCourse.id}
                                loading={false}
                                rowContent={<PlannedCourseRow plannedCourse={plannedCourse} />}
                                content={
                                    <ParticipantList
                                        closed={plannedCourse.is_closed}
                                        participants={plannedCourse.participants}
                                        course={plannedCourse.course?.id}
                                        plannedCourse={plannedCourse.id}
                                    />
                                }
                            />
                        ))}
                    </FlexTable>
                    <Paginator
                        forcePage={page || 1}
                        limit={limit}
                        setLimit={setLimit}
                        length={plannedCourses?.data?.count || 0}
                        onChange={(params) => fetchPlannedCourses(params)}
                    />
                </>
            }

        </>
    );
};

export default PlannedCoursesList;