import React, {useState} from 'react';
import { useTranslation } from "react-i18next"
//components
import Icon from '@components/Icon';
import {FlexItem, FlexRow} from '@components/tables/FlexTable';
import { Label, Divider, Grid, Header } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// specific components
import OtherInfoForm from '../forms/OtherInfoForm';
import EmployeeBankAccounts from '../forms/EmployeeBankAccounts';
import PassportForm from '../forms/PassportForm';


const OtherInfoSection = ({ data, setData, patchEndpoint }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(0);

    return (
        <Grid stackable>
            <Grid.Row>
                <Grid.Column only="computer" computer="3" stretched style={{ borderRadius: "4px", paddingTop: "1rem", backgroundColor: "var(--light)"}}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem textAlign="center">
                            <Header icon >
                                <Icon name='information-circle-outline' style={{ fontSize: "5rem" }}/>
                                <h3>{ t('other_info') }</h3>
                            </Header>
                        </FlexItem>
                    </FlexRow>

                </Grid.Column>
                <Grid.Column computer="12" tablet="16" mobile="16">
                    <Grid style={{ padding: 0 }} >
                        <Grid.Row style={{ padding: 0 }} >
                            <Grid.Column only="computer" computer="16">
                                <Header floated="left" as="h3" style={{ marginBottom: 0 }}> 
                                    <Icon name="information-circle-outline" style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.2rem" }}>{t('other_info')}</span>
                                </Header>
                                <Header floated="right" as="h4" style={{ marginBottom: 0 }}>
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <Icon name="pencil-outline" style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('edit')}</span>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <Icon name="close-outline" style={{ fontSize: "1rem", color: "var(--danger)", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('cancel')}</span>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }} only="mobile tablet" tablet="16" mobile="16">
                            <Header style={{ padding: 0 }} floated="left" as="h4">
                                { view === 0 ?
                                    <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                        <span style={{ position: "relative", top: "-0.5rem" }}>{t('other_info')}</span>
                                        <Icon name="pencil-outline" style={{ fontSize: "1rem", marginLeft: "1rem" }} />
                                    </span>
                                :
                                    <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                        <span style={{ position: "relative", top: "-0.5rem" }}>{t('other_info')}</span>
                                        <Icon name="close-outline" style={{ fontSize: "1rem", color: "var(--danger)", marginLeft: "1rem"}} />
                                    </span>
                                }
                            </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />
                    { view === 1 ?
                        <OtherInfoForm
                            data={data}
                            setView={setView}
                            patchEndpoint={patchEndpoint}
                            onConfirm={setData}
                        />
                    :
                    <Grid stackable>
                        <Grid.Row columns={5}>
                            <Grid.Column>
                                {t('external_position')} <br />
                                <strong>{data?.is_external ? t("yes") : t("no") }</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('citizenship')} <br />
                                <strong>{data?.personal_information?.birth_info?.citizenship_display || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('nationality')} <br />
                                <strong>{data?.personal_information?.birth_info?.nationality_display || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('is_foreigner')} <br />
                                <strong>{data?.is_foreigner ? t("yes") : t("no") }</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('last_country_of_residence')} <br />
                                <strong>{data?.last_country_residence_display || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            <Grid.Column>
                                {t('last_job')} <br />
                                <strong>{data?.last_job || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('workplace_country')} <br />
                                <strong>{data?.workplace_country_display || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('acting_by')} <br />
                                <strong>{data?.acting_by || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('hired_based_on')} <br />
                                <strong>{data?.source?.name || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('handicap_level')} <br />
                                <strong>{data?.handicap_level_display || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{paddingTop: "1rem"}} columns={5}>
                            <Grid.Column>
                                {t('driver_licence')} <br />
                                {data?.personal_information?.driver_licence?.length === 0 && <strong>~</strong>}
                                <strong>{data?.personal_information?.driver_licence?.map(obj => (
                                    <Label style={{ marginTop: "0.2rem" }} key={obj.id} basic > {obj.driver_group} </Label>
                                ))}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('health_insurance')} <br />
                                <strong>{data?.personal_information?.health_insurance?.name || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('health_insurance_code')} <br />
                                <strong>{data?.personal_information?.health_insurance?.code || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                <SuperDuperModal
                                    trigger={
                                        <span> {t('passports')} <Icon size="small" name="plus" /> <br /></span>
                                    }
                                    content={
                                        <PassportForm
                                            data={data}
                                            setData={setData}
                                            patchEndpoint={patchEndpoint}
                                        />
                                    }
                                />
                                {data?.personal_information?.passports?.length === 0 && <strong>~</strong>}
                                {data?.personal_information?.passports?.map(passport => (
                                    <SuperDuperModal
                                        key={passport.id}
                                        trigger={
                                            <strong> {passport.travel_document_type_display } <Icon name="angle down" /> <br /></strong>
                                        }
                                        content={
                                            <PassportForm
                                                data={data}
                                                setData={setData}
                                                existingPassport={passport}
                                                patchEndpoint={patchEndpoint}
                                            />
                                        }
                                    />

                                ))}
                            </Grid.Column>
                            <Grid.Column>
                                <SuperDuperModal
                                    trigger={
                                        <span> {t('bank_accounts')}  <Icon name="angle down" /> <br /></span>
                                    }
                                    size="large"
                                    content={
                                        <EmployeeBankAccounts data={data} setData={setData} />
                                    }
                                />

                                <strong>{data?.personal_information?.bank_accounts?.[0]?.iban || '~'}</strong> <br />
                                <strong>{data?.personal_information?.bank_accounts?.[1]?.iban}</strong> <br />
                                <strong>{data?.personal_information?.bank_accounts?.[2]?.iban}</strong>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default OtherInfoSection;