import { APIRequest } from './helpers/requests';
import { API } from '../config';


/*
 * Service functions
 */
export const userService = {
    getUserProfile,
    setUserName,
    setPassword,
    setEmail,
    setAvatar,
    deleteAvatar,
    setPreferences,
    getPreferences,
    getPreference,
    setPreference,
    getUsers,
    getUsersMe
};

// getUserProfile:
async function getUserProfile() {
    return await APIRequest({
        url: API.GET_USER_ME, // .../api/users/me/
        method: 'GET',
        data: {},
        private: true
    });
}

async function getPreference(preference) {
    return await APIRequest({
        url: API.USER_PREFERENCES + preference + "/",
        method: 'GET',
        data: {},
        private: true
    });
}

// setPreferences:
async function setPreference(preference, value) {
    return await APIRequest({
        url: API.USER_PREFERENCES + preference + "/",
        method: 'PUT',
        data: {
            value: value
        },
        private: true
    });
}

// setUserName:
async function setUserName(data) {
    return await APIRequest({
        url: API.SET_USERNAME, // .../api/users/set_username/
        method: 'POST',
        data: data,
        private: true
    });
}

// setPassword:
async function setPassword(data) {
    return await APIRequest({
        url: API.SET_PASSWORD, // .../api/users/set_password/
        method: 'POST',
        data: data,
        private: true
    });
}

// setEmail:
async function setEmail(data) {
    return await APIRequest({
        url: API.SET_EMAIL, // .../api/users/set_password/
        method: 'PATCH',
        data: data,
        private: true
    });
}

// setAvatar:
async function setAvatar(data) {
    return await APIRequest({
        url: API.SET_AVATAR,
        method: 'POST',
        data: data,
        private: true
    });
}

// deleteAvatar:
async function deleteAvatar() {
    return await APIRequest({
        url: API.DELETE_AVATAR,
        method: 'DELETE',
        private: true
    });
}

// setPreferences:
async function setPreferences(data) {
    return await APIRequest({
        url: API.SET_PREFERENCES,
        method: 'PATCH',
        data: data,
        private: true
    });
}

// getPreferences:
// getPreference:
async function getPreferences() {
    return await APIRequest({
        url: API.GET_PREFERENCES,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getUsersMe() {
    return await APIRequest({
        url: API.USERS_ME,
        method: 'GET',
        data: {},
        private: true
    });
}


async function getUsers(query_params) {
    query_params = query_params || "";
    return await APIRequest({
        url: API.USERS + query_params,
        method: 'GET',
        data: {},
        private: true
    });
}
