import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import { Form, Button } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const CostCenterForm = ({ onClose, setCostCenters, data }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        projects: data?.projects ? data?.projects?.map(item => item.id) : [],
        unit: data?.unit ? data?.unit?.id : 0,
        title: data?.title ? data?.title : '',
        id: data?.id ? data?.id : null,
    })

    function requiredFilled() {
        if (!form.title) return true
        if (isProcessing) return true
        return false
    }

    const handleSubmit = async () => {
        setIsProcessing(true)

        const formData = {
            title: form.title,
            projects: { add: form.projects },
            unit: form.unit ? form.unit : null
        }

        if ( form.id === null ){
            const request = await requests.post(API.COST_CENTERS, formData)
    
            if (request.status === 201) {
                setCostCenters(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        count: prev.data.count + 1,
                        results: [...prev.data.results, request.response] 
                    }
                }))
                onClose()
            }
        } else {
            const request = await requests.patch(API.COST_CENTERS + form.id + "/", formData)
    
            if (request.status === 200) {
                setCostCenters(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: prev.data.results.filter(item => {
                            if (item.id === form.id){
                                item.title = request.response.title
                                item.projects = request.response.projects
                                item.unit = request.response.unit
                            }
    
                            return item
                        })
                    }
                }))
                onClose()
            }
        }

        setIsProcessing(false)
        
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField
                required
                label={t('title')}
                as="input"
                value={form.title}
                placeholder={t('title')}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, title: value }))} 
            />

            <SuperField
                label={t('unit')}
                as="choice"
                endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                text="name"
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, unit: value }))} 
            />

            <SuperField
                fluid
                search
                multiple
                as="choice"
                label={t('projects')}
                value={form.projects}
                text="name"
                endpoint={API.PROJECTS + '?query={id,name}'}
                onChange={(e, { value }) => setForm((prev) => ({ ...prev, projects: value }))} 
            />

            <Form.Group style={{ display: 'flex', justifyContent: 'flex-end', margin: 0 }}>
                <Button type='reset' onClick={onClose}>{t('cancel')}</Button>
                <Button primary
                    type='submit'
                    style={{ marginRight: 0 }}
                    disabled={requiredFilled()}
                    loading={isProcessing}
                >
                    {data ?
                        t('update_cost_center') :
                        t('add_cost_center')
                    }
                </Button>
            </Form.Group>
        </Form>
    );
};

export default CostCenterForm;