import React from "react";
import { useTranslation } from "react-i18next";
// store
// components
import { Grid, Header, Popup } from "semantic-ui-react";
import CourseElement from "../components/CourseElement";
import CanView from "@components/perms/CanView";
import SuperDuperModal from "@components/modals/SuperDuperModal";
import OutlineAddButton from "@components/buttons/OutlineAddButton";
import CourseForm from "../components/CourseForm";

const ManageCourses = ({ courses, onDelete, setCourses }) => {
    const { t } = useTranslation();

    return (
        <>
            <Grid>
                <Grid.Row>
                    {courses.length === 0 && (
                        <Grid.Column width="16" textAlign="center">
                            {" "}
                            <Header
                                as="h2"
                                content={t("no_course_record_found")}
                            />{" "}
                        </Grid.Column>
                    )}
                    {courses.map((course) => (
                        <Popup
                            hideOnScroll
                            hoverable
                            key={course.id}
                            position="bottom center"
                            trigger={
                                <CourseElement
                                    name={course?.title}
                                    course={course}
                                    category={course?.category?.title}
                                    isMandatory={course?.is_mandatory}
                                    setCourses={setCourses}
                                    onDelete={() => onDelete(course.id)}
                                />
                            }
                        ></Popup>
                    ))}
                </Grid.Row>
                <CanView permissions={["courses.c_manage_all_courses"]}>
                    <SuperDuperModal
                        trigger={
                            <OutlineAddButton
                                primary
                                text={t("create_course")}
                                size="small"
                            />
                        }
                        size="small"
                        content={
                            <CourseForm
                                onClose={() => {}}
                                setCourses={setCourses}
                            />
                        }
                    />
                </CanView>
            </Grid>
        </>
    );
};

export default ManageCourses;
