import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { fileDownload } from '@helpers/requests';
import { useQueryPage, useIsMount } from '@helpers/hooks';
import { thousandsSeparators } from '@helpers/functions';
import { getRequest, deleteRequest, patchRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import Invoice from './documents/Invoice';
import InvoicesFilter from './InvoicesFilter';
import InvoiceDivideToCC from './InvoiceDivideToCC';
import InvoiceApprovalForm from './InvoiceApprovalForm';
import PaymentVerification from './PaymentVerification';
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
import DocPreview from '@components/DocPreview';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import ConfirmModal from '@components/modals/ConfrimModal';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import { Icon as SemanticIcon, Button, Segment, Sidebar, Checkbox, Dropdown, Popup, Grid, Divider, Form } from 'semantic-ui-react';

const InvoicesTable = ( { limit, setLimit, premiumConnection, pairInvoices, setPairInvoices, choices, filters, setFilters, isLoading, segmentRef, connection, journal, type } ) => {
    const { t } = useTranslation()
    let page = useQueryPage()
    const isMount = useIsMount()
    const history = useHistory()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [invoices, setInvoices] = useState({})
    const [selectedInvoices, setSelectedInvoices] = useState([])
    const [visible, setVisible] = useState(false);
    const [checkedAll, setCheckedAll] = useState(false)
    const [reFetched, setReFetched] = useState(false)


    const user = useSelector(state => state.user)
    const canManage = journal.allowed_users.find(allowedUser => (allowedUser.user.id === user.id && allowedUser.can_manage)) !== undefined ? true : false
    const canDelete = journal.allowed_users.find(allowedUser => (allowedUser.user.id === user.id && allowedUser.can_delete)) !== undefined ? true : false


    const handleAllChecked = () => {
        let isCheckedAll = !checkedAll
        let tmpList = []
        for (let i = 0; i < invoices.results.length; i++) {
            tmpList.push({
                id: invoices.results[i].id,
                number: invoices.results[i].number,
                supplier_name: invoices.results[i].supplier_name,
                supplier_registration_id: invoices.results[i].supplier_registration_id,
                date_due: invoices.results[i].date_due !== null ? moment(invoices.results[i].date_due).format("DD.MM.YYYY") : "--",
                price_to_pay: thousandsSeparators(invoices.results[i].price_to_pay),
                currency: invoices.results[i].currency,
            })
        }
        if( isCheckedAll ){
            setSelectedInvoices(prevState => [...prevState, ...tmpList]);
        } else {
            setSelectedInvoices(prevState => prevState.filter(invoice => !tmpList.filter(item => item.id === invoice.id).length > 0))
        }
        setCheckedAll(isCheckedAll)
    }

    const handleFilterOptions = () => {
        let filterBaseParams = ""

        if( filters.date_from !== "" && filters.date_from !== null ){
            if(filters.date_type === 1) filterBaseParams += "&date_issue_after="+filters.date_from
            if(filters.date_type === 2) filterBaseParams += "&date_supply_after="+filters.date_from
            if(filters.date_type === 3) filterBaseParams += "&date_due_after="+filters.date_from
            if(filters.date_type === 4) filterBaseParams += "&date_approved_after="+filters.date_from
        }

        if( filters.date_to !== "" && filters.date_to !== null ){
            if(filters.date_type === 1) filterBaseParams += "&date_issue_before="+filters.date_to
            if(filters.date_type === 2) filterBaseParams += "&date_supply_before="+filters.date_to
            if(filters.date_type === 3) filterBaseParams += "&date_due_before="+filters.date_to
            if(filters.date_type === 4) filterBaseParams += "&date_approved_before="+filters.date_to
        }

        if( filters.approval !== "" ){
            filterBaseParams += "&approval="+filters.approval
        }

        if( filters.verified !== "" ){
            filterBaseParams += "&payment_is_approved="+filters.verified
        }

        if( filters.invoice_type !== "" ){
            filterBaseParams += "&invoice_type="+filters.invoice_type
        }

        if( filters.status !== "" ){
            if( filters.status !== "all" ){
                filterBaseParams += "&status="+filters.status
            }
        }

        if( filters.split_ratio !== "" ){
            filterBaseParams += filters.split_ratio
        }

        if( filters.number !== "" ){
            filterBaseParams += "&number="+filters.number
        }

        if( filters.city !== "" ){
            if( type === "incomes" ){
                filterBaseParams += "&customer_city="+filters.city
            } else {
                filterBaseParams += "&supplier_city="+filters.city
            }
        }

        if( filters.country !== "" ){
            if( type === "incomes" ){
                filterBaseParams += "&customer_country="+filters.country
            } else {
                filterBaseParams += "&supplier_country="+filters.country
            }
        }

        if( filters.name !== "" ){
            if( type === "incomes" ){
                filterBaseParams += "&customer_name="+filters.name
            } else {
                filterBaseParams += "&supplier_name="+filters.name
            }
        }

        if( filters.registration_id !== "" ){
            if( type === "incomes" ){
                filterBaseParams += "&customer_registration_id="+filters.registration_id
            } else {
                filterBaseParams += "&supplier_registration_id="+filters.registration_id
            }
        }

        if( filters.tax_id !== "" ){
            if( type === "incomes" ){
                filterBaseParams += "&customer_tax_id="+filters.tax_id
            } else {
                filterBaseParams += "&supplier_tax_id="+filters.tax_id
            }
        }

        if( filters.vat_id !== "" ){
            if( type === "incomes" ){
                filterBaseParams += "&customer_vat_id="+filters.vat_id
            } else {
                filterBaseParams += "&supplier_vat_id="+filters.vat_id
            }
        }

        if (filters.ordering){
            if (filters.ordering.direction === "asc" ){
                filterBaseParams += "&ordering=" + filters.ordering.field
            } else {
                filterBaseParams += "&ordering=-" + filters.ordering.field
            }
        }

        return filterBaseParams
    }

    useEffect( () => {
        if( !loading && invoices?.results?.length > 0 ){
            let isAllChecked = false
            let checked = 0
            for (let i = 0; i < invoices.results.length; i++) {
                if( selectedInvoices.filter(item => item.id === invoices.results[i].id).length > 0 ){
                    checked += 1
                }
            }
            if( invoices.results.length === checked ){
                isAllChecked = true
            }

            setCheckedAll(isAllChecked)
        }
        // eslint-disable-next-line
    }, [invoices, selectedInvoices])

    const fetchInvoices = async (params, isFiltered) => {
        if (!loading || !reFetched){
            params = params || ""
            params += handleFilterOptions()
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if ( !params.includes('page') ) {
                params += "&page=" + page
            }

            if ( !params.includes('limit')){
                params += "&limit=" + limit
            }

            if (pairInvoices){
                params += "&pair_invoices=true"
                if (journal?.ibans?.length > 0){
                    const ibans = journal?.ibans
                    let ibanList = "&ibans="
                    for (let i = 0; i < ibans.length; i++) {
                        const iban = ibans[i].iban.replace(/\s/g, '')
                        if (iban.length >= 15){
                            if (i !== ibans.length - 1){
                                ibanList += iban + ";"
                            } else {
                                ibanList += iban
                            }
                        }
                    }

                    params += ibanList
                }
            }

            let endpoint = API.GET_INVOICES + "?connection=" + connection.connection_id + "&paginate=true&is_issued=" + (type === "incomes") + "&account_ids=" + journal.id + params
            const request = await getRequest(endpoint)

            if (request === undefined) {
                setInvoices([])
            }

            if (request?.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if( request?.status === 200 ){
                setInvoices(request.response)
            }

            setReFetched(false)
            setLoading(false)
        }
    }

    useEffect( () => {
        fetchInvoices()
        // eslint-disable-next-line
    }, [type, location.key])

    useEffect( () => {
        async function onOrderChange(){
            if (!isMount && !loading && !reFetched) {
                history.replace({ pathname: location.pathname, search: `?page=${1}` });
                await fetchInvoices("", true)
            }
        }

        onOrderChange()
        // eslint-disable-next-line
    }, [filters.ordering])

    // Convert Array of selected invoices to string of ids separated by comma
    function convertSelectedToIds(invoices){
        let ids = ""
        let lastIndex = invoices.length - 1
        for (let i = 0; i < invoices.length; i++) {
            if( lastIndex === i ){
                ids += invoices[i].id
            } else {
                ids += invoices[i].id + ","
            }
        }

        return ids
    }

    const showInvoice = async (id) => {
        await fileDownload("GET", API.API_URL + `/integrations/invoices/${id}/pdf/?connection=${connection.connection_id}&account_ids=${journal.id}`, "application/pdf", "invoice.pdf")
    }

    const generatePohodaExportXML = async (generateAll) => {
        if (generateAll) {
            await fileDownload("GET", API.API_URL + "/integrations/invoices/xml_export_pohoda/?connection=" + connection.connection_id + "&account=" + journal.id + "&is_issued=" + (type === "incomes"), "application/xml", "xml-pohoda.xml")
        } else {
            if( selectedInvoices.length > 0 ){
                await fileDownload("GET", API.API_URL + "/integrations/invoices/xml_export_pohoda/?ids=" + convertSelectedToIds(selectedInvoices) + "&connection=" + connection.connection_id + "&account=" + journal.id + "&is_issued=" + (type === "incomes"), "application/xml", "xml-pohoda.xml")
            }
        }
    }

    const generateExportXLSX = async (generateAll) => {
        if (generateAll) {
            await fileDownload("GET", API.API_URL + "/integrations/invoices/export/?connection=" + connection.connection_id + "&account=" + journal.id + "&is_issued=" + (type === "incomes"), "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx-export.xlsx")
        } else {
            if( selectedInvoices.length > 0 ){
                await fileDownload("GET", API.API_URL + "/integrations/invoices/export/?ids=" + convertSelectedToIds(selectedInvoices) + "&connection=" + connection.connection_id + "&account=" + journal.id + "&is_issued=" + (type === "incomes"), "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "xlsx-export.xlsx")
            }
        }
    }

    const onDelete = async (invoice) => {
        // fetch splitted invoices to CC
        const request = await getRequest(API.COST_CENTERS_RECORDS + "?invoice_reference=" + (invoice.id + "__" + invoice.number))

        let errorReason = t('invoice_delete_request_error')
        const deleteInvoiceRequest = await deleteRequest(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id)

        if( deleteInvoiceRequest.status === 204 ){
            if(request.status === 200){
                for (let i = 0; i < request.response.length; i++) {
                    await deleteRequest(API.COST_CENTERS_RECORDS + request.response[i].id + "/")
                }
            }

            if( invoice.is_issued ){
                history.push(routes.INVOICES + "incomes/")
            } else{
                history.push(routes.INVOICES + "expenses/")
            }
        } else {
            if( deleteInvoiceRequest.status === 400 ) errorReason = deleteInvoiceRequest.response?.reason

            toast({
                type: 'error',
                icon: 'warning',
                title: t('error_occured'),
                description: errorReason,
                animation: 'bounce',
                time: 5000,
            });
        }
    }

    const InvoiceCCAction = ({ invoice }) => {
        const [isFetching, setIsFetching] = useState(true)
        const [records, setRecords] = useState([])
        const [difference, setDifference] = useState({
            diff: 0,
            percentage: 0
        })

        const [settings, setSettings] = useState({
            wasSplitted: false,
            tax: 1,
            split_definition: 1
        })

        const calculateTaxes = () => {
            let summary = {
                total: 0,
                total_excluding_tax: 0
            }

            let total = 0
            let total_excluding_tax = 0
            for (let i = 0; i < invoice.taxes.length; i++) {
                total += parseFloat(invoice.taxes[i].sum)
                total_excluding_tax += parseFloat(invoice.taxes[i].sum_excluding_tax)
            }

            summary.total = parseFloat(total).toFixed(2)
            summary.total_excluding_tax = parseFloat(total_excluding_tax).toFixed(2)

            return summary
        }

        const calculateRecordSum = () => {
            let sum = 0
            for (let i = 0; i < records.length; i++) {
                if( records[i].amount > 0 ){
                    sum += parseFloat(records[i].amount)
                } else {
                    sum += parseFloat(0)
                }

            }

            return sum
        }

        const calculateDiff = async () => {
            let diff = 0
            let percentage = 0
            let dividedSum = calculateRecordSum()
            let totalSum = settings.tax === 1 ? calculateTaxes().total_excluding_tax : calculateTaxes().total
            diff = parseFloat(parseFloat(totalSum).toFixed(2) - parseFloat(dividedSum)).toFixed(2)

            if( diff <= 0 ) {
                diff = 0
            } else {
                percentage = parseFloat((diff / totalSum) * 100).toFixed(2)
            }

            setDifference({
                diff: diff,
                percentage: percentage
            })
        }

        useEffect( () => {
            async function syncInvoice(){
                if( !isFetching ){
                    if( parseFloat(invoice.split_ratio).toFixed(2) !== parseFloat(difference.percentage).toFixed(2) ){
                        await patchRequest(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id, {
                            split_ratio: parseFloat(difference.percentage).toFixed(2)
                        })
                    }
                }
            }

            syncInvoice()
            // eslint-disable-next-line
        }, [difference])

        useEffect( () => {
            calculateDiff()
            // eslint-disable-next-line
        }, [records])

        useEffect( () => {
            async function fetchRecords() {
                if( invoice.price_display !== null && parseFloat(invoice.price_display) !== 0 && invoice.price_display !== "" ){
                    setIsFetching(true)
                    const request = await getRequest(API.COST_CENTERS_RECORDS + "?invoice_reference=" + (invoice.id + "__" + invoice.number) )
                    if( request.status === 200 ){
                        let items = []
                        const response = request.response
                        for (let i = 0; i < response.length; i++) {
                            items.push({
                                id: response[i].id,
                                title: response[i].title,
                                amount: response[i].amount.sum,
                                date: response[i].date,
                                category: response[i].category?.id,
                                cost_center: response[i].cost_center,
                                invoice_reference: response[i].invoice_reference,
                                processing: false
                            })
                        }

                        let taxMode = 1
                        if( request.response.length > 0 ){
                            let referenceValues = request.response[0].invoice_reference.split("__")
                            let taxSetting = referenceValues[2].split(":")
                            taxMode = taxSetting[1] !== undefined ? parseInt(taxSetting[1]) : 1
                        }

                        setSettings(prevState => ({
                            ...prevState,
                            wasSplitted: request.response.length > 0,
                            tax: taxMode
                        }))

                        setRecords(items)
                    }
                }
                setIsFetching(false)
            }

            fetchRecords()
            // eslint-disable-next-line
        }, [])

        const renderColor = () => {
            if( difference.percentage === 0) return "var(--success)"

            return "var(--danger)"
        }

        const renderTooltip = () => {
            if( difference.percentage === 0) return t('fully_splitted_invoice')

            return t('invoice_not_fully_splitted') + ". " + t("remaining") + ": " + difference.percentage + "%"
        }

        return (
            <>
                { canManage &&
                    <FlexItem textAlign="center">
                        <SuperDuperModal
                            size="large"
                            trigger={
                                <Icon
                                    className={ (invoice.price_display === null || parseFloat(invoice.price_display) === 0 || invoice.price_display === "" || isFetching) ? "disabled" : "" }
                                    title={records.length > 0 ? renderTooltip() : t('split_invoice')}
                                    style={{ cursor: "pointer", color: records.length > 0 ? renderColor() : "var(--dark)" }}
                                    name={isFetching ? "ban-outline" : "swap-horizontal-outline"}
                                />
                            }
                            content={
                                <InvoiceDivideToCC
                                    invoice={invoice}
                                    records={records}
                                    difference={difference}
                                    connection={connection}
                                    setRecords={setRecords}
                                    calculateTaxes={calculateTaxes}
                                    calculateRecordSum={calculateRecordSum}
                                    settings={settings}
                                    setSettings={setSettings}
                                />
                            }
                        />
                    </FlexItem>
                }
            </>
        )
    }

    const ApprovalStatusIndicator = ({ invoice }) => {
        const { t } = useTranslation()

        const [isFetching, setIsFetching] = useState(true)
        const [approval, setApproval] = useState(null)

        const syncStatus = async (status, date) => {
            await patchRequest(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id, {
                approval: status,
                date_approved: date || null
            })
        }

        useEffect( () => {
            async function fetchApprovals(){
                setIsFetching(true)
                const request = await getRequest(API.APPROVALS + "invoices/?invoice_reference=" + (invoice.id + ";" + invoice.number))
                if ( request.status === 200 ){
                    if ( request.response.length > 0) {
                        setApproval(request.response[0])

                        if ( request.response[0].datetime_approval !== null && invoice.date_approved === null && request.response[0].is_approved === true ){
                            await syncStatus(1, moment(request.response[0].approval_datetime).format("YYYY-MM-DD")) // approved
                        }

                        // Sync statuses with invoices
                        if ( request.response[0].is_approved === true && invoice.approval !== 1 ) {
                            await syncStatus(1, moment(request.response[0].approval_datetime).format("YYYY-MM-DD")) // approved
                        } else if (request.response[0].is_approved === false && invoice.approval !== 3){
                            await syncStatus(3) // denied
                        } else if (request.response[0].is_approved === null && invoice.approval !== 2){
                            await syncStatus(2) // awaiting approval
                        }
                    }
                }

                setIsFetching(false)
            }

            fetchApprovals()
            // eslint-disable-next-line
        }, [])

        const IconMark = ({ icon, color, title }) => {
            return (
                <Popup content={title} position='top center' trigger={
                    <Icon name={icon} style={{ color: color, cursor: "pointer", fontSize: "1.2rem" }}/>
                }/>
            )
        }

        return (
            <span style={{ marginLeft: "0.5rem" }}>
            { isFetching
                ? <SemanticIcon name="spinner" loading={isFetching}/>
                :
                <>
                    { approval === null && "" }
                    { approval?.is_approved === null && <IconMark icon="help-circle" color="orange" title={t('awaiting_approval')}/> }
                    { approval?.is_approved === true && <IconMark icon="checkmark-circle" color="var(--success)" title={t('approved') + (invoice.date_approved !== null && " - " + moment(invoice.date_approved).format("DD.MM.YYYY")) }/> }
                    { approval?.is_approved === false && <IconMark icon="warning-circle" color="var(--warning)" title={t('denied')}/> }
                </>
            }

            </span>
        )
    }

    return (
        <Segment style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "10rem", border: "none", boxShadow: "none" }}>
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0}} className="fixed-sidebar">
                    <InvoicesFilter
                        visible={visible}
                        setVisible={setVisible}
                        filters={filters}
                        setFilters={setFilters}
                        fetchInvoices={fetchInvoices}
                        handleFilterOptions={handleFilterOptions}
                        segmentRef={segmentRef}
                        setSelectedInvoices={setSelectedInvoices}
                        setCheckedAll={setCheckedAll}
                        connection={connection}
                        type={type}
                        choices={choices}
                        isLoading={isLoading}
                    />
                    <Sidebar.Pusher>
                        <Grid>
                            <Grid.Row style={{ padding: 0 }}>
                                <Grid.Column computer={8} tablet={8} mobile={16} textAlign="left">
                                    <Dropdown
                                        as={Button}
                                        text={<span> <Icon name="download-outline" style={{ marginRight: "0.5rem" }}/>{ t('export') }</span>}
                                        floating
                                        simple
                                        style={{ background: "var(--dark)", color: "var(--white)" }}
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown item text="XML Pohoda">
                                                <Dropdown.Menu direction="right">
                                                    <Dropdown.Item content="All" onClick={() => generatePohodaExportXML(true)}/>
                                                    <Popup
                                                        trigger={
                                                            <Dropdown.Item
                                                                disabled={selectedInvoices.length === 0}
                                                                onClick={() => generatePohodaExportXML()}
                                                                content={`Selected (${selectedInvoices.length})`}
                                                            />
                                                        }
                                                        content={`You have to select at least one invoice. Selected (${selectedInvoices.length})`}
                                                        position='right center'
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown item text="XLSX">
                                                <Dropdown.Menu direction="right">
                                                    <Dropdown.Item content="All" onClick={() => generateExportXLSX(true)}/>
                                                    <Popup
                                                        trigger={
                                                            <Dropdown.Item
                                                                disabled={selectedInvoices.length === 0}
                                                                onClick={() => generateExportXLSX()}
                                                                content={`Selected (${selectedInvoices.length})`}
                                                            />
                                                        }
                                                        content={`You have to select at least one invoice. Selected (${selectedInvoices.length})`}
                                                        position='right center'
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    { !(type === "incomes") &&
                                        <SuperDuperModal
                                            closeIcon
                                            header={ t('request_invoice_approval') }
                                            trigger={
                                                <Button icon={<Icon name="clipboard-outline"/>} primary content={t('request_invoice_approval')} disabled={selectedInvoices.length === 0}/>
                                            }
                                            content={
                                                <InvoiceApprovalForm
                                                    selectedInvoices={selectedInvoices}
                                                    setSelectedInvoices={setSelectedInvoices}
                                                    connection={connection}
                                                />
                                            }
                                        />
                                    }
                                    <Button style={{ background: "transparent", cursor: "default" }}>
                                        <strong>{ t('invoice_pairing') }</strong>
                                        <Button basic size="tiny" style={{ marginLeft: "0.5rem" }}>
                                            <strong style={{ color: pairInvoices ? "var(--primary)" : "var(--danger)" }}>{ pairInvoices ? t('activated') : t('deactivated') }</strong>
                                        </Button>
                                        { premiumConnection?.id !== undefined &&
                                            <Popup
                                                basic
                                                trigger={
                                                    <Icon
                                                        style={{ cursor: "pointer", marginLeft: "0.5rem", fontSize: "1.2rem" }}
                                                        name={pairInvoices ? "pause-circle-outline" : "play-circle-outline" }
                                                        onClick={() => setPairInvoices(!pairInvoices)}
                                                    />
                                                }
                                                content={ pairInvoices ? t('pause_pairing') : t('start_pairing') }
                                            />
                                        }
                                    </Button>
                                </Grid.Column>
                                <Grid.Column computer={8} tablet={8} mobile={16} textAlign="right">
                                    <Button.Group size="small">
                                        <Form>
                                            <SuperField as="choice" size="small"
                                                style={{ width: "150px" }}
                                                value={filters.ordering.field}
                                                clearable={false}
                                                onChange={(e, { value }) => setFilters(prev => ({...prev, ordering: {
                                                    ...prev.ordering,
                                                    field: value
                                                }}))}
                                                customOptions={[
                                                    { key: 1, value: "created_at", text: t('created_at')},
                                                    { key: 2, value: "date_approved", text: t('date_of_approval')},
                                                    { key: 3, value: "date_issue", text: t('date_of_issue')},
                                                    { key: 4, value: "date_supply", text: t('date_of_supply')},
                                                    { key: 5, value: "date_due", text: t('date_due')},
                                                    { key: 6, value: "date_paid", text: t('date_paid')},
                                                    { key: 7, value: "number", text: t('invoice_number')},
                                                ]}
                                            />
                                        </Form>
                                        <Button
                                            style={{ background: "var(--dark)", color: "var(--white)" }}
                                            title={filters.ordering.direction === "asc" ? t('ascending') : t('descending')}
                                            onClick={ () => setFilters(prev => ({...prev, ordering: {
                                                ...prev.ordering,
                                                direction: filters.ordering.direction === "asc" ? "desc" : "asc"
                                            }})) }

                                            icon={
                                                <SemanticIcon name={filters.ordering.direction === "asc" ? "sort amount up" : "sort amount down"}/>
                                            }
                                        />
                                    </Button.Group>
                                    <Button
                                        style={{ marginLeft: "0.5rem" }}
                                        primary
                                        onClick={() => setVisible(!visible)}
                                    >
                                        <Icon name='funnel-outline' style={{ margin: 0, marginRight: "0.5rem" }} /> { t('filter') }
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider/>
                        <Segment loading={reFetched || loading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "10rem", border: "none", boxShadow: "none" }}>
                            { (reFetched || loading) && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "8rem" }}> { t('loading_invoices') } </p> }
                            { (!reFetched && !loading) &&
                                <FlexTable>
                                    <FlexRow>
                                        <FlexHeader basis="30%" content={
                                            <Checkbox
                                                checked={checkedAll}
                                                onClick={() => handleAllChecked()}
                                            />
                                        }/>
                                        <FlexHeader content={t('number')}/>
                                        <FlexHeader basis="120%" content={ type === "incomes" ? t('account') : t('supplier') }/>
                                        <FlexHeader basis="80%" content={t('order_number')}/>
                                        <FlexHeader content={t('sum')}/>
                                        <FlexHeader content={t('dates')}/>
                                        <FlexHeader content={t('status')}/>
                                        <FlexHeader basis="50%" textAlign="center" content={t('scan')}/>
                                        <FlexHeader basis="50%" textAlign="center" content={t('factoring')}/>
                                        <FlexHeader basis="70%" textAlign="left" content={t('actions')}/>
                                    </FlexRow>
                                    <EmptyRow length={Object.keys(invoices).length === 0 ? 0 : invoices?.results?.length}/>
                                    { Object.keys(invoices).length === 0 ? "" : invoices?.results?.map( invoice => (
                                        <FlexRow key={invoice.id} fontSize="1rem" borders>
                                            <FlexItem
                                                bold
                                                basis="30%"
                                                content={
                                                    <Checkbox
                                                        checked={ selectedInvoices.filter(item => item.id === invoice.id).length > 0 }
                                                        onClick={() => {
                                                            if (selectedInvoices.filter(item => item.id === invoice.id).length > 0) {
                                                                setSelectedInvoices(prevState => prevState.filter( item => item.id !== invoice.id ))
                                                            } else {
                                                                setSelectedInvoices(prevState => [...prevState, {
                                                                    id: invoice.id,
                                                                    number: invoice.number,
                                                                    supplier_name: invoice.supplier_name,
                                                                    supplier_registration_id: invoice.supplier_registration_id,
                                                                    date_due: invoice.date_due !== null ? moment(invoice.date_due).format("DD.MM.YYYY") : "--",
                                                                    price_to_pay: thousandsSeparators(invoice.price_to_pay),
                                                                    currency: invoice.currency,
                                                                }])
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                            <FlexItem>
                                                <strong>{ invoice.number }
                                                    { !invoice.is_issued && <ApprovalStatusIndicator invoice={invoice}/> }
                                                </strong>  <br/>
                                                <small>{ invoice.invoice_type_display }</small>
                                            </FlexItem>
                                            <FlexItem basis="120%">
                                                <span style={{ fontSize: "1.2rem" }}>{ invoice.is_issued ? invoice.customer_name : invoice.supplier_name }</span> <br/>
                                                {/* IČO:  */}<strong> { invoice.is_issued ? invoice.customer_registration_id : invoice.supplier_registration_id } </strong>
                                                {/* IBAN: <strong> { invoice.customer_registration_id } </strong> */}
                                            </FlexItem>
                                            <FlexItem basis="80%" bold>
                                                { invoice.order_number }
                                            </FlexItem>
                                            <FlexItem bold>
                                                { invoice.price_to_pay !== null ? <> { thousandsSeparators(invoice.price_to_pay) } { invoice.currency } </> : "--" }
                                            </FlexItem>
                                            <FlexItem>
                                                <span>{t('issue_invoice_date')}:</span> <strong> { invoice.date_issue !== null ? moment(invoice.date_issue).format("DD.MM.YYYY") : "--" }   </strong> <br/>
                                                <span>{t('supply_invoice_date')}:</span> <strong> { invoice.date_supply !== null ? moment(invoice.date_supply).format("DD.MM.YYYY") : "--" } </strong> <br/>
                                                <span>{t('due_to_invoice_date')}:</span> <strong> { invoice.date_due !== null ? moment(invoice.date_due).format("DD.MM.YYYY") : "--" }  </strong>
                                            </FlexItem>
                                            <FlexItem>
                                                <>
                                                    <Icon
                                                        name="ellipse"
                                                        style={{
                                                            marginRight: "0.5rem",
                                                            color: 
                                                            invoice.status === 1
                                                                ? "blue"
                                                                : invoice.status === 3
                                                                    ? "green"
                                                                    : invoice.status === 4
                                                                    ? "blue"
                                                                    : ""
                                                        }}
                                                    />

                                                    { invoice.status === 4 && t('draft') }
                                                    { invoice.status === 1 &&
                                                        <>
                                                        { t('pending') }
                                                            { moment(invoice.date_due, "YYYY-MM-DD").isBefore(moment().format("YYYY-MM-DD"))
                                                                ?
                                                                    <Popup
                                                                        position='right center'
                                                                        trigger={
                                                                            <Icon name="alert-outline" style={{ color: "var(--danger)", cursor: "pointer", marginLeft: "0.5rem" }}/>
                                                                        }
                                                                        content={ <span style={{ position: "relative", top: "-0.2rem" }}>{ t('past_due') }</span> }
                                                                    />
                                                                : ""
                                                            }
                                                        </>
                                                    }
                                                    { invoice.status === 3 && t('paid') }

                                                    { invoice.status === 3 && invoice.date_paid !== null
                                                        ?
                                                        <div style={{ marginTop: "0.5rem", marginLeft: "0rem", fontSize: "0.9rem" }}>
                                                            { invoice.payment_is_approved &&
                                                                <Popup content={t("payment_verified")} position='top center' trigger={
                                                                    <Icon name="checkmark-circle" style={{ color: "var(--dark)", cursor: "pointer", marginRight: "0.5rem" }}/>
                                                                }/>
                                                            }
                                                            <strong style={{ position: "relative", top: "-0.1rem" }}>{ moment(invoice.date_paid).format("DD.MM.YYYY") }</strong>
                                                        </div>
                                                        :
                                                        <>
                                                            { canManage &&
                                                                <div style={{ marginTop: "0.2rem" }}>
                                                                    <SuperDuperModal
                                                                        size="tiny"
                                                                        trigger={
                                                                            <small style={{ color: "var(--primary)" }} className="ref-link"> {t('verify_payment')} </small>
                                                                        }
                                                                        content={
                                                                            <PaymentVerification invoice={invoice} setInvoices={setInvoices} connection={connection}/>
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            </FlexItem>
                                            <FlexItem basis="50%">
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem textAlign="center">
                                                        { invoice.scan === null
                                                            ? "--"
                                                            :
                                                            <SuperDuperModal
                                                                size="large"
                                                                trigger={
                                                                    <Icon
                                                                        title={t('scan')}
                                                                        name="document-text-outline"
                                                                        style={{ cursor: "pointer", color: "var(--dark)", fontSize: "1.5rem" }}
                                                                    />
                                                                }
                                                                content={
                                                                    <DocPreview uri={invoice.scan}/>
                                                                }
                                                            />
                                                        }
                                                    </FlexItem>
                                                </FlexRow>
                                            </FlexItem>
                                            <FlexItem basis="50%">
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem textAlign="center">
                                                        <SemanticIcon
                                                            disabled
                                                            title={t('factoring')}
                                                            style={{ cursor: "not-allowed", color: "var(--dark)" }}
                                                            name="retweet"
                                                        />
                                                    </FlexItem>
                                                </FlexRow>
                                            </FlexItem>
                                            <FlexItem basis="70%">
                                                <FlexRow background="transparent" padding="0">
                                                    <FlexItem textAlign="center">
                                                        <Icon
                                                            title="Generate PDF View"
                                                            style={{ cursor: "pointer", color: "var(--danger)" }}
                                                            name="document-text-outline"
                                                            onClick={ () => showInvoice(invoice.id) }
                                                        />
                                                    </FlexItem>

                                                    {/* Responsible for show option to divide invoice to Cost Centers */}
                                                    <InvoiceCCAction invoice={invoice}/>

                                                    { canManage &&
                                                        <FlexItem textAlign="center">
                                                            <SuperDuperModal
                                                                closeOnDimmerClick={false}
                                                                closeOnDocumentClick={false}
                                                                size="large"
                                                                trigger={
                                                                    <Icon
                                                                        title="Update invoice"
                                                                        style={{ cursor: "pointer", color: "var(--dark)" }}
                                                                        name="pencil-outline"
                                                                    />
                                                                }
                                                                content={
                                                                    <Invoice
                                                                        invoice={invoice}
                                                                        document={(type === "incomes") ? "sent_invoices" : "recieved_invoice"}
                                                                        connection={connection}
                                                                        journal={journal}
                                                                        isUpdate={true}
                                                                    />
                                                                }
                                                            />
                                                        </FlexItem>
                                                    }
                                                    { canDelete &&
                                                        <FlexItem textAlign="center">
                                                            <ConfirmModal
                                                                description={t('invoice_delete_message')}
                                                                onConfirm={() => onDelete(invoice)}
                                                                button={
                                                                    <Button
                                                                        icon={<Icon name="close-outline"/>}
                                                                        title="Remove Invoice"
                                                                        style={{
                                                                            background: "none",
                                                                            color: "var(--danger)",
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0,
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </FlexItem>
                                                    }
                                                </FlexRow>
                                            </FlexItem>
                                        </FlexRow>
                                    ))}
                                </FlexTable>
                            }
                        </Segment>
                        <Paginator
                            forcePage={page || 1}
                            limit={limit}
                            setLimit={setLimit}
                            length={invoices?.count || 0}
                            onChange={(params) => fetchInvoices(params)}
                        />
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
        </Segment>
    );
};

export default InvoicesTable;
