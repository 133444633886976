import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { Menu, Label, Popup } from 'semantic-ui-react';

const SkillsTooltip = ({ skills }) => {
    const { t } = useTranslation();

    return (
        <>
            { skills.length > 0 && 
                <Popup
                    flowing
                    hideOnScroll
                    hoverable
                    trigger={
                        <Menu size="tiny" compact style={{ cursor: "pointer", borderRadius: "0" }}>
                            <Menu.Item style={{ fontWeight: "bold", padding: "0.2rem", paddingLeft: "1rem"}}>
                                <span style={{ marginRight: "1rem" }}>
                                   { t('skills') + " (" + skills.length + ")" }
                                </span>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <Popup.Content>
                        <Label.Group>
                            { skills.map(skill => (
                                <Label basic size="tiny"> { skill.name }{ skill.seniority_level !== null && " - " + skill.seniority_level_display } </Label>
                            )) }
                        </Label.Group>
                    </Popup.Content>
                </Popup>
            }
        </>
    )
}

export default SkillsTooltip;