import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import '@store/languages';
// store
import { API } from '@store/config';
import { dates as dateHelper } from '@helpers/dates';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import { Form, Popup, Button, Segment } from 'semantic-ui-react';
import { FlexTable, FlexHeader, FlexRow, FlexItem } from '@components/tables/FlexTable';
// specific components
import PauseForm from './PauseForm';

const WeekManagement = ({ week, weekDates, employee, excludeWeekend, setWeeksStats }) => {
    const { t } = useTranslation()
    const language = useSelector(state => state.language)
    const date_format = useSelector(state => state.date_format)
    const [records, setRecords] = useState([])
    const [loading, setLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    useEffect( () => {
        const generateWeeklyAttendance = async () => {
            setLoading(true)
            let dateFrom = weekDates[0]?.day
            let dateTo = weekDates[weekDates.length - 1]?.day
    
            let dataset = []
            const request = await requests.get(API.ATTENDANCE + "?employee=" + employee + "&date_from=" + moment(dateFrom).format('YYYY-MM-DD') + "&date_to=" + moment(dateTo).format('YYYY-MM-DD'))
            if( request.status === 200 ){
                dataset = request.response
            }
            
            const dates = getDates(moment(dateFrom), moment(dateTo))
            const attendance = []
    
            for (let i = 0; i < dates.length; i++) {
                const attendance_record = dataset.find(item => item.date === moment(dates[i]).format("YYYY-MM-DD"))
                attendance.push({
                    week: moment(dates[i]).isoWeek(),
                    day: dates[i],
                    errors: [],
                    isProcessing: false,
                    data: attendance_record !== undefined 
                        ? {
                            ...attendance_record,
                            start_time: attendance_record?.start ? moment(dateHelper.convertUTCDateToLocalDate(attendance_record?.start)).format("HH:mm") : "",
                            end_time: attendance_record?.end ? moment(dateHelper.convertUTCDateToLocalDate(attendance_record?.end)).format("HH:mm") : "",
                            start_pause: attendance_record?.start_pause ? moment(dateHelper.convertUTCDateToLocalDate(attendance_record?.start_pause)).format("YYYY-MM-DD HH:mm") : "",
                            end_pause: attendance_record?.end_pause ? moment(dateHelper.convertUTCDateToLocalDate(attendance_record?.end_pause)).format("YYYY-MM-DD HH:mm") : ""
                        }
                        : {}
                })
            }
    
            setRecords(attendance)
            setLoading(false)
        }

        generateWeeklyAttendance()
        // eslint-disable-next-line
    }, [])

    const getDates = (from, to) => {
        let dates = []
        // add logic to calculate days between two date range
        for (let day = from; day.isSameOrBefore(to); day.add(1, 'days')) {
            dates.push(day.format('YYYY-MM-DD'));
        }

        return dates
    }

    const isWeekendDay = (day) => {
        if (excludeWeekend === false) return false

        return [0,6].includes(moment(day).day())
    }

    const handleDecimalValue = (value) => {
        if (value === "") return value

        return value.replace(",", ".")
    }

    const handleTimeValue = (value) => {
        value = value.replace(".", ":")
        value = value.replace(",", ":")
        value = value.replace(";", ":")
        // value = value.replace(" ", ":")

        return value
    }

    const isNumberValid = (value) => {
        if (value === "") return true
        if (value === null) return true
        if (value === undefined) return true

        return !isNaN(value)
    }

    const isTimeValid = (value) => {
        if (value === "") return true
        if (value === null) return true
        if (value === undefined) return true

        return moment(value, "HH:mm", true).isValid()
    }

    const handleRecordDelete = async (day) => {
        setIsProcessing(day, true)
        const record = records.find(item => item.day === day)
        if (record) {
            const request = await requests.del(API.ATTENDANCE + record.data.id + "/")
            if (request.status === 204) {
                setRecords(prev => prev.filter(item => {
                    if (item.day === day) {
                        item.data = {}
                    }

                    return item
                }))
            }
        }
        setIsProcessing(day, false)
    }

    const handleWeekSaveAction = async (week) => {
        setIsSaving(true)
        const week_records = records.filter(record => record.week === week)
        
        for (let i = 0; i < week_records.length; i++) {
            if (Object.keys(week_records[i].data).length !== 0) { // skip day when no data are filled
                setIsProcessing(week_records[i].day, true)
                const start = week_records[i].data?.start_time ? moment(week_records[i].day + " " + week_records[i].data?.start_time) : null

                const end_day = week_records[i].data?.is_night_shift === true ? moment(week_records[i].day).add(1, 'days').format("YYYY-MM-DD") : moment(week_records[i].day).format("YYYY-MM-DD")
                const end = week_records[i].data?.end_time ? moment(end_day + " " + week_records[i].data?.end_time) : null
    
                const data = {
                    start: start !== null ? moment.utc(start).format("YYYY-MM-DD HH:mm:ss") : start,
                    end: end !== null ? moment.utc(end).format("YYYY-MM-DD HH:mm:ss") : end,
                    duration: week_records[i].data?.duration || 0,
                    start_pause: week_records[i].data?.start_pause ? moment.utc(week_records[i].data?.start_pause).format("YYYY-MM-DD HH:mm:ss") : null,
                    end_pause: week_records[i].data?.end_pause ? moment.utc(week_records[i].data?.end_pause).format("YYYY-MM-DD HH:mm:ss") : null,
                    is_night_shift: week_records[i].data?.is_night_shift || false,
                    vacation: week_records[i].data?.vacation || null,
                    absention: week_records[i].data?.absention || null,
                    paragraph: week_records[i].data?.paragraph || null,
                    sick_day: week_records[i].data?.sick_day || null,
                    date: week_records[i].day,
                    employee: employee,
                }
    
                let request = null
                if (week_records[i].data?.id > 0) {
                    request = await requests.patch(API.ATTENDANCE + week_records[i].data?.id + "/", data)
                } else {
                    request = await requests.post(API.ATTENDANCE_MANUAL, data)
                }
    
                if (request.status === 200 || request.status === 201) {
                    const response = request.status === 201 ? request.response : request.response
                    setRecords(prev => prev.map(item => {
                        if (item.day === week_records[i].day) {
                            item.data = {
                                ...response,
                                start_time: response?.start ? moment(dateHelper.convertUTCDateToLocalDate(response?.start)).format("HH:mm") : "",
                                end_time: response?.end ? moment(dateHelper.convertUTCDateToLocalDate(response?.end)).format("HH:mm") : "",
                                start_pause: response?.start_pause ? moment(dateHelper.convertUTCDateToLocalDate(response?.start_pause)).format("HH:mm") : "",
                                end_pause: response?.end_pause ? moment(dateHelper.convertUTCDateToLocalDate(response?.end_pause)).format("HH:mm") : ""
                            }
                        }
    
                        return item
                    }))
                }
    
                if (request.status === 400) {
                    setRecords(prev => prev.filter(item => {
                        if (item.day === week_records[i].day) {
                            item.errors = request.response
                        }
    
                        return item
                    }))
                }
    
                setIsProcessing(week_records[i].day, false)
            }
        }

        // fetch latest statistics
        let date_from = weekDates[0]?.day
        let date_to = weekDates[weekDates.length - 1]?.day
        const request_week_stats = await requests.get(API.ATTENDANCE + `employee_stats/?date_from=${date_from}&date_to=${date_to}&employee=${employee}`)

        if (request_week_stats.status === 200) {
            setWeeksStats(prev => prev.map(item => {
                if (item.week === week) {
                    item = {
                        week: week,
                        ...request_week_stats.response
                    }
                }
                return item
            }))
        }

        setIsSaving(false)
    }

    const handleChange = async (day, value, attribute) => {
        setRecords(prev => prev.filter(record => {
            if (record.day === day) {
                record.data[attribute] = value
            }

            return record
        }))
    }

    const setIsProcessing = (day, value) => {
        setRecords(prev => prev.filter(item => {
            if (item.day === day) {
                item.isProcessing = value
            }

            return item
        }))
    }

    const calculateDuration = (record) => {
        let duration = ""
        if (Object.keys(record.data).length === 0) return
        if (record.data?.start_time === undefined || record.data?.start_time === null || record.data?.start_time === "") return
        if (record.data?.end_time === undefined || record.data?.end_time === null || record.data?.end_time === "") return

        // create start, end intervals
        if ( moment(record.data?.start_time, "HH:mm", true).isValid() && moment(record.data?.end_time, "HH:mm", true).isValid()){
            let start = moment(record.day + " " + record.data?.start_time).format("YYYY-MM-DD HH:mm")
            let end = record.data?.is_night_shift === true
                ? moment(moment(record.day).add(1, 'days').format("YYYY-MM-DD") + " " + record.data?.end_time).format("YYYY-MM-DD HH:mm") 
                : moment(record.day + " " + record.data?.end_time).format("YYYY-MM-DD HH:mm")

            duration = moment(end).diff(moment(start), "hours", true)
        }

        let breakTime = 0
        // create start, end intervals
        if ( moment(moment(record.data?.start_pause).format("YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm", true).isValid() && 
             moment(moment(record.data?.end_pause).format("YYYY-MM-DD HH:mm"), "YYYY-MM-DD HH:mm", true).isValid()
        ){
            let start_pause = moment(record.data?.start_pause).format("YYYY-MM-DD HH:mm")
            let end_pause = moment(record.data?.end_pause).format("YYYY-MM-DD HH:mm")

            breakTime = moment(end_pause).diff(moment(start_pause), "hours", true)
        }

        if (duration !== "") {
            if (duration > 0) {
                duration -= breakTime
            }
            handleChange(record.day, (duration?.toFixed(2) || ""), "duration")
        }
    }

    return (
        <Segment 
            loading={loading}
            style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
                marginBottom: "1rem",
            }}
        >
            { loading && 
                <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "10rem", paddingBottom: "4rem"}}> 
                    { t('loading_weekly_attendance') } 
                </p>
            }

            { !loading && 
                <FlexTable stripped={false} hoverable={false}>
                    <FlexRow fontSize="0.9rem" background="transparent">
                        <FlexHeader basis="30%" content={t('date')}/>
                        <FlexHeader basis="30%" content={t('day')}/>
                        <FlexHeader basis="50%" content={t('shift')}/>
                        <FlexHeader basis="80%" content={t('time_from_to')}/>
                        <FlexHeader basis="50%" textAlign="center" content={t('pause')}/>
                        <FlexHeader basis="50%" content={t('duration')}/>
                        <FlexHeader basis="50%" textAlign="center" content={t('vacation')}/>
                        <FlexHeader basis="50%" textAlign="center" content={t('absention')}/>
                        <FlexHeader basis="50%" textAlign="center" content={t('paragraph')}/>
                        <FlexHeader basis="50%" textAlign="center" content={t('sick_day')}/>
                        <FlexHeader basis="20%" textAlign="center" content={""}/>
                        <FlexHeader basis="20%" textAlign="center" content={""}/>
                    </FlexRow>

                    { records.map((record, index) => (
                        <>
                        { !isWeekendDay(record.day) && 
                                <FlexRow borders fontSize="0.9rem" key={index}>
                                    <FlexItem basis="30%" bold fontSize="1.3rem">
                                        { moment(record.day).format(date_format) }    
                                    </FlexItem>
                                    <FlexItem basis="30%" bold fontSize="1.3rem">
                                        <div style={{ textTransform: "capitalize" }}>
                                            { moment(record.day).locale(language).format('dddd') }
                                        </div>                                        
                                    </FlexItem>
                                    <FlexItem basis="50%">
                                        <Button.Group size="tiny" basic>
                                            <Popup
                                                position="top center"
                                                trigger={
                                                    <Button
                                                        active={record.data?.is_night_shift === false}
                                                        onClick={ () => {
                                                            handleChange(record.day, true, "is_night_shift") 
                                                            let changed_record = record 
                                                            changed_record.data['is_night_shift'] = false 
                                                            calculateDuration(changed_record)
                                                        }}
                                                    > 
                                                        <Icon name="sunny-outline"/> 
                                                    </Button>
                                                }
                                                content={t("day_shift")}
                                            />
                                            <Popup
                                                position="top center"
                                                trigger={
                                                    <Button
                                                        active={record.data?.is_night_shift === true}
                                                        onClick={ () => {
                                                            handleChange(record.day, true, "is_night_shift") 
                                                            let changed_record = record 
                                                            changed_record.data['is_night_shift'] = true 
                                                            calculateDuration(changed_record)
                                                        }}
                                                    >
                                                        <Icon name="moon-outline"/> 
                                                    </Button>
                                                }
                                                content={t("night_shift")}
                                            />
                                        </Button.Group>
                                    </FlexItem>
                                    <FlexItem basis="80%">
                                        <Form.Group size="tiny" style={{ marginBottom: 0 }}>
                                            <Popup
                                                position="top center"
                                                disabled={isTimeValid(record.data?.start_time || "")}
                                                trigger={
                                                    <SuperField 
                                                        as="timepicker"
                                                        style={{ width: "100px" }}
                                                        value={record.data?.start_time || ""}
                                                        onChange={ (e, { value }) => {
                                                            handleChange(record.day, handleTimeValue(value), "start_time")
                                                            let changed_record = record
                                                            changed_record.data['start_time'] = handleTimeValue(value)
                                                            calculateDuration(changed_record)
                                                        }}
                                                        error={!isTimeValid(record.data?.start_time || "") ? true : false}
                                                    />
                                                }
                                                content={!isTimeValid(record.data?.start_time || "") ? t('wront_time_format_use_hh_mm') : ""}
                                            />
                                            <Popup
                                                position="top center"
                                                disabled={isTimeValid(record.data?.end_time || "")}
                                                trigger={
                                                    <SuperField 
                                                        as="timepicker"
                                                        style={{ width: "100px" }}
                                                        value={record.data?.end_time || ""}
                                                        error={!isTimeValid(record.data?.end_time || "") ? true : false}
                                                        onChange={ (e, { value }) => {
                                                            handleChange(record.day, handleTimeValue(value), "end_time")
                                                            let changed_record = record
                                                            changed_record.data['end_time'] = handleTimeValue(value)
                                                            calculateDuration(changed_record)
                                                        }}
                                                    />
                                                }
                                                content={!isTimeValid(record.data?.end_time || "") ? t('wront_time_format_use_hh_mm') : ""}
                                            />
                                        </Form.Group>
                                    </FlexItem>
                                    <FlexItem basis="50%" textAlign="center">
                                        <Action
                                            as="modal"
                                            type="icon"
                                            modalSize="tiny"
                                            icon={record.data?.start_pause ? "pencil-outline" : "add-outline"}
                                            tooltip={record.data?.start_pause 
                                                ? 
                                                    <div style={{ textAlign: "center" }}>
                                                        <strong>{ t('update_pause') }</strong> <br/>
                                                        { moment(dateHelper.convertUTCDateToLocalDate(record.data?.start_pause)).format(date_format + " HH:mm") } <br/>
                                                        { moment(dateHelper.convertUTCDateToLocalDate(record.data?.end_pause)).format(date_format + " HH:mm") }
                                                    </div>
                                                : t('add_pause')
                                            }
                                            modal={
                                                <PauseForm
                                                    record={record}
                                                    handleChange={handleChange}
                                                    calculateDuration={calculateDuration}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem basis="50%">
                                        <Popup
                                            position="top center"
                                            disabled={isNumberValid(record.data?.duration || "")}
                                            content={!isNumberValid(record.data?.duration) ? t('not_a_number') : ""}
                                            trigger={
                                                <SuperField
                                                    fluid
                                                    as="input"
                                                    value={record.data?.duration?.replace(".00", "") || ""}
                                                    onChange={ (e, { value }) => handleChange(record.day, handleDecimalValue(value), "duration") }
                                                    error={!isNumberValid(record.data?.duration) ? true : false}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem basis="50%" textAlign="center">
                                        <Popup
                                            position="top center"
                                            disabled={isNumberValid(record.data?.vacation || "")}
                                            content={!isNumberValid(record.data?.vacation) ? t('not_a_number') : ""}
                                            trigger={
                                                <SuperField
                                                    as="input"
                                                    style={{ 
                                                        width: "50px"
                                                    }}
                                                    className="input-centered-value"
                                                    value={record.data?.vacation?.replace(".00", "") || ""}
                                                    onChange={ (e, { value }) => handleChange(record.day, handleDecimalValue(value), "vacation") }
                                                    error={!isNumberValid(record.data?.vacation || "") ? true : false}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem basis="50%" textAlign="center">
                                        <Popup
                                            position="top center"
                                            disabled={isNumberValid(record.data?.absention || "")}
                                            content={!isNumberValid(record.data?.absention) ? t('not_a_number') : ""}
                                            trigger={
                                                <SuperField
                                                    as="input"
                                                    style={{ 
                                                        width: "50px"
                                                    }}
                                                    className="input-centered-value"
                                                    value={record.data?.absention?.replace(".00", "") || ""}
                                                    onChange={ (e, { value }) => handleChange(record.day, handleDecimalValue(value), "absention") }
                                                    error={!isNumberValid(record.data?.absention || "") ? true : false}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem basis="50%" textAlign="center">
                                        <Popup
                                            position="top center"
                                            disabled={isNumberValid(record.data?.paragraph || "")}
                                            content={!isNumberValid(record.data?.paragraph) ? t('not_a_number') : ""}
                                            trigger={
                                                <SuperField
                                                    as="input"
                                                    style={{ 
                                                        width: "50px"
                                                    }}
                                                    className="input-centered-value"
                                                    value={record.data?.paragraph?.replace(".00", "") || ""}
                                                    onChange={ (e, { value }) => handleChange(record.day, handleDecimalValue(value), "paragraph") }
                                                    error={!isNumberValid(record.data?.paragraph || "") ? true : false}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem basis="50%" textAlign="center">
                                        <Popup
                                            position="top center"
                                            disabled={isNumberValid(record.data?.sick_day || "")}
                                            content={!isNumberValid(record.data?.sick_day) ? t('not_a_number') : ""}
                                            trigger={
                                                <SuperField
                                                    as="input"
                                                    style={{ 
                                                        width: "50px"
                                                    }}
                                                    className="input-centered-value"
                                                    value={record.data?.sick_day?.replace(".00", "") || ""}
                                                    onChange={ (e, { value }) => handleChange(record.day, handleDecimalValue(value), "sick_day") }
                                                    error={!isNumberValid(record.data?.sick_day || "") ? true : false}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem basis="20%" textAlign="center">
                                        <Popup
                                            disabled={true}
                                            position="left center"
                                            trigger={
                                                <Icon className="disabled" name="chatbubble-ellipses-outline" style={{ cursor: "not-allowed" }}/>
                                            }
                                            content={t('add_note')}
                                        />
                                    </FlexItem>
                                    <CanView permissions={['attendance.c_delete_all_records']}>
                                        <FlexItem basis="20%" textAlign="center">
                                            { record.data?.id > 0 && 
                                                <Popup
                                                    position="left center"
                                                    content={t('remove')}
                                                    trigger={
                                                        <Icon
                                                            className={record.isProcessing ? "disabled" : ""}
                                                            name="close-outline"
                                                            style={{ 
                                                                cursor: "pointer",
                                                                color: record.isProcessing ? "var(--black)" : "var(--danger)"
                                                            }}
                                                            onClick={ () => handleRecordDelete(record.day)}
                                                        />
                                                    }
                                                />
                                            }
                                        </FlexItem>
                                    </CanView>
                                </FlexRow>
                            }
                        </>
                    )) }
                    <Form.Field style={{ textAlign: "right", paddingTop: "1rem", paddingBottom: "1rem" }}>
                        <Button
                            primary
                            loading={isSaving}
                            disabled={isSaving}
                            type="button"
                            content={ t('save_changes') }
                            onClick={() => handleWeekSaveAction(week)}
                        />
                    </Form.Field>
                </FlexTable>
            }
        </Segment>
    );
};

export default WeekManagement;