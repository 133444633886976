import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
import { Link } from 'react-router-dom';
import moment from 'moment';
// store 
import { routes } from '@routes';
import { API } from '@store/config';
import { useSelectOptions } from '@helpers/hooks';
import { setDotSeparator, thousandsSeparators } from '@helpers/functions';
import { createRequest, patchRequest, getRequest, deleteRequest } from '@services/ServiceCommon';
// components 
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider, Grid, Header, Button, Icon, Loader, Popup, Segment } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';

const InvoiceDivideToCC = ({ invoice, connection, records, setRecords, calculateTaxes, calculateRecordSum, difference, settings, setSettings, onClose }) => {
    const { t } = useTranslation()
    const [isProcessing, setIsProcessing] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [costCenters, setCostCenters] = useSelectOptions(API.COST_CENTERS + "?query={id, title}", "title")
    const [categories, setCategories] = useState([])

    useEffect( () => {
        async function loadCategories(){
            setIsLoading(true)
            const request = await getRequest(API.COST_CENTERS_RECORD_CATEGORIES)
            
            if( request.status === 200 ) {
                setCategories(request.response)
            }
            setIsLoading(false)
        }

        loadCategories()
    }, [])

    const handleSubmit = async () => {
        setIsProcessing(true)

        let divided = 0
        for (let i = 0; i < records.length; i++) {
            onRecordChange(i, "processing", true)
            let data = {
                title: records[i].title,
                amount: {
                    sum: records[i].amount,
                    currency: invoice.currency || "EUR"
                },
                date: records[i].date,
                invoice_reference: setInvoiceReference(),
                category: records[i].category,
                cost_center: records[i].cost_center,
            }

            // find account based on given ID (if exists) and assign account to record
            data.account = null
            let id_number = null
            if ( invoice.is_issued ) id_number = invoice.customer_registration_id
            if ( !invoice.is_issued ) id_number = invoice.supplier_registration_id

            if (id_number !== null && id_number !== ""){
                const findAccountRequest = await getRequest(API.ACCOUNTS + "?query={id}&identification_number=" + id_number)
                if( findAccountRequest.status === 200 ){
                    if( findAccountRequest.response.length > 0 ){
                        data.account = findAccountRequest.response[0].id
                    }
                }
            }

            let request = undefined
            if( records[i].id === 0 ){
                request = await createRequest(API.COST_CENTERS_RECORDS, data)
            } else {
                request = await patchRequest(API.COST_CENTERS_RECORDS + records[i].id + "/", data)
            }
            
            if( request !== undefined ){
                if( request.status === 201 || request.status === 200 ){
                    onRecordChange(i, "processing", false)
                    onRecordChange(i, "id", request.response.id)
                    onRecordChange(i, "title", request.response.title)
                    onRecordChange(i, "amount", request.response.amount.sum)
                    onRecordChange(i, "date", request.response.date)
                    onRecordChange(i, "invoice_reference", request.response.invoice_reference)
                    onRecordChange(i, "category", request.response.category.id)
                    onRecordChange(i, "cost_center", request.response.cost_center)
                    divided += 1
                }
            }
        }

        if( records.length === divided ){
            let syncInvoice = true
            if( parseFloat(invoice.split_ratio).toFixed(2) !== parseFloat(difference.percentage).toFixed(2) ){
                const syncInvoiceRequest = await patchRequest(API.INVOICES + invoice.id + "/?connection=" + connection.connection_id, {
                    split_ratio: parseFloat(difference.percentage).toFixed(2) 
                })

                if( syncInvoiceRequest.status !== 200 ) syncInvoice = false
            }

            if( syncInvoice ){
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('successfully_splittet_to_cc'),
                    animation: 'pulse',
                    time: 5000,
                }); 
                onClose()
            } else {
                alert("External service failure.")
            }

        }

        setIsProcessing(false)
    }

    const handleAdditionCostCenter = async (value, key) => {
        setCostCenters({ ...costCenters, isLoading: true });
        const request = await createRequest(API.COST_CENTERS, {
            title: value,
        });

        if (request.status === 201) {
            setCostCenters((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    options: [
                        {
                            key: request.response.id,
                            value: request.response.id,
                            text: request.response.title,
                        },
                        ...prevState.options,
                    ],
                };
            });

            onRecordChange(key, "cost_center", request.response.id)
        }
    }

    const setInvoiceReference = () => {
        return invoice.id + "__" + invoice.number + "__tax:" + settings.tax
    }

    const onAddRecord = () => {
        setRecords(prevState => [...prevState, { 
            id: 0,
            title: renderName(),
            amount: "",
            date: invoice.date_supply !== null ? moment(invoice.date_supply).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            category: "",
            cost_center: "",
            invoice_reference: "",
            processing: false
        }])
    }

    const onRecordRemove = async (key, item) => {
        onRecordChange(key, "processing", true)
        if( item.id === 0 ){
            setRecords(prevState => prevState.filter((record, index) => index !== key))
        } else {
            const request = await deleteRequest(API.COST_CENTERS_RECORDS + item.id + "/")
            if( request.status === 204 ){
                setRecords(prevState => prevState.filter((record, index) => index !== key))
            }
        }
        onRecordChange(key, "processing", false)
    }

    const onRecordChange = (key, updatedKey, value) => {
        setRecords(prevState => prevState.filter((record, index) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const checkPriceIsOver = () => {
        let price = 0
        if( settings.tax === 1 ){
            price = calculateTaxes().total_excluding_tax
        } else {
            price = calculateTaxes().total
        }

        return parseFloat(calculateRecordSum()) > parseFloat(price)
    }

    const isAmountValid = (amount) => {
        // check if its number
        if( isNaN(parseFloat(amount)) && amount !== "" ) return false

        if( amount !== "" && parseFloat(amount) <= 0 ) return false

        return true
    }

    const evaluateRecords = () => {
        return records.filter(record => (!isAmountValid(record.amount) || record.cost_center === "" || record.title === "")).length > 0
    }

    const handleCategoryOptions = () => {
        let options = []

        for (let i = 0; i < categories.length; i++) {
            let type = invoice.is_issued ? 1 : 2
            if( parseInt(categories[i].type) === parseInt(type) ){
                let textValue = categories[i].title + " (" + categories[i].type_display + ")"
                
                if( categories[i].code !== "" && categories[i].code !== null){
                    textValue = categories[i].code + " - " + categories[i].title + " (" + categories[i].type_display + ")"
                }
    
                options.push({
                    id: categories[i].id,
                    value: categories[i].id,
                    text: textValue
                })
            }
        }

        return options
    }

    const renderName = () => {
        return invoice.is_issued 
            ? invoice.customer_name + ((invoice.customer_registration_id !== "" && invoice.customer_registration_id !== null) ? " - " + invoice.customer_registration_id : "")  
            : invoice.supplier_name  + ((invoice.supplier_registration_id !== "" && invoice.supplier_registration_id !== null) ? " - " + invoice.supplier_registration_id : "") 
    }

    return (
        <Segment style={{ 
            padding: 0, 
            background: "transparent", 
            boxShadow: "none", 
            border: "none",
            minHeight: "10rem",
            marginBottom: "1rem"
        }}>
            <Form onSubmit={handleSubmit}>
                <Grid>
                    <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                        <Grid.Column>
                            <span>{ t('document') }: <strong>{ invoice.is_issued ? t('send_invoice') : t('recieved_invoice') }</strong></span> 
                            <span style={{ paddingLeft: "1rem" }}>{ t('invoice_number') }: <strong>{ invoice.number }</strong></span>
                            <span style={{ paddingLeft: "1rem" }}>{ t('mode') }: <strong>{ invoice.tax_mode_display }</strong></span>
                            <span style={{ paddingLeft: "1rem" }}>{ invoice.is_issued ? t('account') : t('supplier') }: <strong>{ renderName() }</strong></span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider/>

                <Grid>
                    <Grid.Row style={{ padding: 0 }} columns={2}>
                        <Grid.Column>
                            <Header as="h4" content={t('tax_setting')}/>
                            <Form.Group widths={"equal"}>
                                <SuperField as="radio"
                                    disabled={settings.wasSplitted}
                                    label={t("sum_excluding_tax")}
                                    checked={settings.tax === 1}
                                    onChange={() => setSettings({...settings, tax: 1})}
                                />
                                <SuperField as="radio"
                                    disabled={invoice.tax_mode === 2 || settings.wasSplitted}
                                    label={t("sum_including_tax")}
                                    checked={settings.tax === 2}
                                    onChange={() => setSettings({...settings, tax: 2})}
                                />
                            </Form.Group>

                            <Header as="h4" content={t('split_mode')}/>
                            <Form.Group widths={"equal"}>
                                <SuperField as="radio"
                                    label={t("split")}
                                    checked={settings.split_definition === 1}
                                    onChange={() => setSettings({...settings, split_definition: 1})}
                                />
                                <SuperField as="radio"
                                    label={t("full_price")}
                                    checked={settings.split_definition === 2}
                                    onChange={() => {
                                        setSettings({...settings, split_definition: 2})

                                        if( records.length === 0){
                                            let price = 0
                                            if( settings.tax === 1 ){
                                                price = calculateTaxes().total_excluding_tax
                                            } else {
                                                price = calculateTaxes().total
                                            }

                                            setRecords(prevState => [...prevState, { 
                                                id: 0,
                                                type: invoice.is_issued ? 1 : 2,
                                                title: renderName(),
                                                amount: price,
                                                date: invoice.date_supply !== null ? moment(invoice.date_supply).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                                                category: "",
                                                cost_center: "",
                                                processing: false
                                            }])
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column>
                            <FlexTable responsive={false}>
                                <FlexRow background="transparent" borders fontSize="0.9rem" padding="0.5rem">
                                    <FlexHeader content={t("tax_rate")}/>
                                    <FlexHeader content={t("sum_excluding_tax")}/>
                                    { invoice.tax_mode !== 2 && <FlexHeader content={t("sum_including_tax")}/>}
                                </FlexRow>
                                { invoice.taxes.map(tax => (
                                    <FlexRow background="transparent" borders fontSize="0.9rem" padding="0.5rem">
                                        <FlexItem bold content={ tax.tax_rate + " %" }/>
                                        <FlexItem> { thousandsSeparators(tax.sum_excluding_tax)} </FlexItem> 
                                        { invoice.tax_mode !== 2 && 
                                            <FlexItem> { thousandsSeparators(tax.sum) } </FlexItem> 
                                        }
                                    </FlexRow>
                                )) }
                                <FlexRow background="transparent" borders fontSize="0.9rem" padding="0.5rem">
                                    <FlexItem bold content={t('summary')}/>
                                    <FlexItem bold>
                                        <span style={{ color: settings.tax === 1 ? "var(--info)" : "var(--black)" }}> { thousandsSeparators(calculateTaxes().total_excluding_tax) } </span>  { invoice.currency }
                                    </FlexItem>
                                    { invoice.tax_mode !== 2 && 
                                        <FlexItem bold>
                                            <span style={{ color: settings.tax === 2 ? "var(--info)" : "var(--black)" }}> { thousandsSeparators(calculateTaxes().total) } </span> { invoice.currency }
                                        </FlexItem>
                                    }
                                </FlexRow>
                            </FlexTable>
                            
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider/>
                <Grid>
                    <Grid.Row verticalAlign="middle" style={{ padding: 0 }} columns={2}>
                        <Grid.Column>
                            <p>
                                ** { t('manage_your_cost_centers') } <Link to={routes.COST_CENTERS}>{t('in_this_interface')}</Link>. <br/>
                                ** { t('manage_your_record_categories_in') } <Link to={routes.SETTINGS + "types/cost-centers-record-categories"}>{t("settings")}</Link>.
                            </p>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: "right" }}>
                            <span><strong>{t('divided')}:</strong><br/> 
                                { checkPriceIsOver() && 
                                    <Popup 
                                        position='left center'
                                        trigger={
                                            <Icon name="warning circle" style={{ color: "var(--danger)" }}/> 
                                        }
                                        content={ t('total_overcome_invoice_summary') }
                                    />
                                }
                                <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                                    { thousandsSeparators(calculateRecordSum()) } / { settings.tax === 1 ? thousandsSeparators(calculateTaxes().total_excluding_tax) : thousandsSeparators(calculateTaxes().total) } { invoice.currency } 
                                </span>
                            </span> <br/><br/>
                            <span>
                                <strong>{t('remaining')}:</strong><br/>
                                <span style={{ fontSize: "1.2rem", fontWeight: "bold", color: "var(--primary)" }}>{ thousandsSeparators(difference.diff) } { invoice.currency } - { difference.percentage }%</span>
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Divider/>

                <FlexTable>
                    <FlexRow padding="0.5rem">
                        <FlexHeader>
                            <Form.Group style={{ marginBottom: 0, fontSize: "0.9rem" }}>
                                <Form.Field width="6" content={t('category')}/>
                                <Form.Field width="6" content={t('sum')}/>
                                <Form.Field width="6" content={t('date')}/>
                                <Form.Field width="6" content={t('cost_center')}/>
                            </Form.Group>
                        </FlexHeader>
                        <FlexHeader basis="10%" textAlign="center" content={""}/>
                    </FlexRow>
                    <EmptyRow length={records.length}/>
                    { records.map((record, key) => (
                        <FlexRow key={key} background="transparent" fontSize="0.9rem" padding="0.5rem" borders>
                            <FlexItem>
                                <Form.Group style={{ marginBottom: "0.3rem" }}>
                                    <SuperField as="choice"
                                        search
                                        size="small"
                                        width="6"
                                        value={record.category}
                                        loading={isLoading}
                                        disabled={isLoading || record.processing}
                                        customOptions={handleCategoryOptions()}
                                        onChange={ (e, { value }) => onRecordChange(key, "category", value) }
                                    />
                                    <SuperField as="input"
                                        disabled={record.processing}
                                        width="6"
                                        size="small"
                                        value={record.amount}
                                        error={ !isAmountValid(record.amount) ? t('invalid_input') : false }
                                        onChange={ (e, { value }) => onRecordChange(key, "amount", setDotSeparator(value)) }
                                    />
                                    <SuperField as="datepicker"
                                        style={{ width: "220px" }}
                                        size="small"
                                        value={record.date}
                                        onChange={ (e, { value}) => onRecordChange(key, "date", value)}
                                    />
                                    <SuperField as="choice"
                                        search
                                        size="small"
                                        allowAdditions
                                        width="6"
                                        value={record.cost_center}
                                        loading={costCenters.isLoading}
                                        disabled={costCenters.isLoading || record.processing}
                                        customOptions={costCenters.options}
                                        onAddItem={(e, { value }) => handleAdditionCostCenter(value, key)}
                                        onChange={ (e, { value }) => onRecordChange(key, "cost_center", value) }
                                    />
                                    
                                </Form.Group>

                                <SuperField as="input"
                                    disabled={record.processing}
                                    placeholder={ t('note') + " / "+ t('title') }
                                    size="small"
                                    width="16"
                                    value={record.title}
                                    onChange={ (e, { value }) => onRecordChange(key, "title", value) }
                                />
                            </FlexItem>
                            <FlexItem basis="10%" textAlign="center">
                                <Form.Field style={{ marginTop: "0.5rem" }}>
                                    { record.processing 
                                        ? <Loader size="small" className='dark-loader' active inline/>
                                        : <Icon onClick={ () => onRecordRemove(key, record) } name="remove" style={{ cursor: "pointer", color: "var(--danger)" }}/>
                                    }
                                </Form.Field>
                            </FlexItem>
                        </FlexRow>
                    )) }
                </FlexTable>

                { settings.split_definition !== 2 && 
                    <Button 
                        primary 
                        icon="plus" 
                        size="small" 
                        type="button" 
                        iconPosition="left" 
                        content={t('add_record')}
                        style={{ marginTop: "0.5rem" }}
                        onClick={ () => onAddRecord() }
                    />
                }

                <Divider/>
                <Form.Field style={{ textAlign: "right" }}>
                    <ModalCancel onClose={onClose}/>
                    <ModalSubmit
                        text={t('confirm')}
                        loading={isProcessing}
                        disabled={
                            isProcessing || 
                            checkPriceIsOver() || 
                            evaluateRecords() || 
                            records.length === 0
                        }
                    />
                </Form.Field>
            </Form>
        </Segment>
    );
};

export default InvoiceDivideToCC;