import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { benefitsService } from '@services/ServiceBenefits'
import { isEmpty } from '@services/helpers/validation';
import { API } from '@store/config';
// components
import { Form, Message, Divider } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperField from '@components/forms/SuperField';

const BenefitsForm = (props) => {
    const { t } = useTranslation();

    const [benefit, setBenefit] = useState({
        id: props.benefits ? props.benefits.id : "",
        name: props.benefits ? props.benefits.name : "",
        category: props.benefits ? props.benefits.category_id : null,
        icon: props.benefits ? props.benefits.icon : "",
        amount: props.benefits ? props.benefits.amount : 1
    })

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleSuccess = (response) => {
        props.onConfirm(response);
        props.onClose()
    }

    const handleErrors = (response) => {
        let errorsArray = [];
        if (response.non_field_errors) {
            errorsArray.push(response.non_field_errors)
        }
        if (response.category) {
            errorsArray.push(response.category)
        }
        if (response.name) {
            errorsArray.push(response.name)
        }
        setErrors(errorsArray);
    }

    // async/await
    const handleSubmit = async () => {
        setErrors([]);
        setIsProcessing(true);

        //data preparation
        const data = {
            name: benefit.name,
            category: benefit.category ? benefit.category : null,
            icon: benefit.icon
        }

        if (!benefit.id || benefit.id === 0) {
            const request = await benefitsService.createBenefits(data)

            if (request.status === 201) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        } else {
            const request = await benefitsService.updateBenefits(data, benefit.id)

            if (request.status === 200) {
                handleSuccess(request.response)
            } else {
                handleErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors.length > 0}
                header={t("error_submission_message")}
                list={errors}
            />
            <Form.Group widths="equal">
                <Form.Input
                    autoFocus
                    required
                    label={t('name')}
                    placeholder={t('name')}
                    value={benefit.name}
                    onChange={(e) => setBenefit({
                        ...benefit,
                        name: e.target.value
                    })}
                />
                <SuperField as="choice"
                    search
                    label={t('category')}
                    settings="types/benefits-categories"
                    value={benefit.category}
                    endpoint={API.BENEFITS_CATEGORIES}
                    text="name"
                    onChange={(e, { value }) => setBenefit({
                        ...benefit,
                        category: value
                    })}
                />
            </Form.Group>
            <Form.Group widths="equal">
                <Form.Input
                    label={t('icon')}
                    placeholder={t('icon')}
                    value={benefit.icon}
                    onChange={(e) => setBenefit({
                        ...benefit,
                        icon: e.target.value
                    })}
                />
                <Form.Input
                    disabled
                    label={t('amount')}
                    placeholder={t('amount')}
                    value={benefit?.amount}
                    onChange={(e) => setBenefit({
                        ...benefit,
                        amount: e.target.value
                    })}
                />
            </Form.Group>
            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={props.onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(benefit.name)}
                    text={t('save')} />
            </Form.Field>
        </Form>
    );
};

export default BenefitsForm;