import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import SuperField from '@components/forms/SuperField';
import DatePresets from '@components/DatePresets';
import { Button, Form, Divider, Header, Grid } from 'semantic-ui-react';

const Filters = ({ filters, setFilters, fetchRecords, fetchCostCenter, setFilterQuery, setVisible, loading }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect( () => {
        async function loadCategories(){
            setIsLoading(true)
            const request = await getRequest(API.COST_CENTERS_RECORD_CATEGORIES)
            
            if( request.status === 200 ) {
                setCategories(request.response)
            }
            setIsLoading(false)
        }

        loadCategories()
    }, [])

    const handleCategoryOptions = () => {
        let options = []

        options = categories.map(category => {
            let textValue = category.title + " (" + category.type_display + ")"
            
            if( category.code !== "" && category.code !== null){
                textValue = category.code + " - " + category.title + " (" + category.type_display + ")"
            }

            return {
                id: category.id,
                value: category.id,
                text: textValue
            }
        })

        return options
    }

    function resetFilters() {
        setFilters({
            title: '',
            category: '',
            date_after: '',
            date_before: '',
            owner: '',
            account: '',
        })
        setFilterQuery('')
    }

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchRecords("", true)
        await fetchCostCenter()
    }

    

    return (
        <Form style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <Grid>
                <Grid.Row columns={2} verticalAlign='middle' style={{ padding: 0 }}>
                    <Grid.Column>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }}/>
                    </Grid.Column>
                    <Grid.Column textAlign='right'>
                        <Icon name="close-outline" style={{ cursor: "pointer", color: "var(--danger)", marginTop: "1rem" }} onClick={() => setVisible(false)}/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>

            <SuperField
                as='input'
                label={t('search_by_title')}
                value={filters.title}
                onChange={(e, { value }) => setFilters({ ...filters, title: value })}
            />
            <SuperField
                search
                as="choice"
                label={t('category')}
                loading={isLoading}
                disabled={isLoading}
                customOptions={handleCategoryOptions()}
                value={filters.category}
                onChange={(e, { value }) => setFilters({ ...filters, category: value })}
            />
            <SuperField
                search
                as="choice"
                label={t('employee')}
                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                text="fullname"
                value={filters.owner}
                onChange={(e, { value }) => setFilters({ ...filters, owner: value })}
            />
            <SuperField
                search
                as="choice"
                label={t('account') + "/" + t('supplier')}
                endpoint={API.ACCOUNTS + "?query={id, name}"}
                text="name"
                value={filters.account}
                onChange={(e, { value }) => setFilters({ ...filters, account: value })}
            />

            <Form.Group widths={'equal'}>
                <SuperField
                    as='datepicker'
                    label={t('date_from')}
                    value={filters.date_after}
                    onChange={(e, { value }) => setFilters({ ...filters, date_after: value })}
                />
                <SuperField
                    as='datepicker'
                    label={t('date_to')}
                    value={filters.date_before}
                    onChange={(e, { value }) => setFilters({ ...filters, date_before: value })}
                />
            </Form.Group>


            <DatePresets from={filters.date_after} to={filters.date_before} onSelection={ (firstDay, lastDay) => setFilters(prev => ({
                ...prev,
                date_after: firstDay,
                date_before: lastDay
            })) }/>

            <Divider/>
            <Form.Field>
                <Button.Group>
                    <Button size="small" primary onClick={() => handleSubmit()} disabled={loading} loading={loading}> {t('apply')} </Button>
                    <Button type="button" onClick={() => resetFilters()} disabled={loading} size="small"> {t('reset')} </Button>
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default Filters