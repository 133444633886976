import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { deleteRequest } from '@services/ServiceCommon';
import { thousandsSeparators } from '@helpers/functions';
import { useHasPermissions } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow';
import ConfrimModal from '@components/modals/ConfrimModal';
import { Dropdown, Loader } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import DocPreview from '@components/DocPreview';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import InvoicePreviewPDF from '../../../../sm/invoices/components/InvoicePreviewPDF';
// module components
import RecordForm from './RecordForm';

const DetailTable = ({ data, setData, loading, canManage, canDelete }) => {
    const { t } = useTranslation()
    const [processing, setProcessing] = useState(false)
    const canManagePerm = useHasPermissions(['cost_centers.c_manage_cost_centers'])

    async function deleteRecord(id) {
        setProcessing(true)
        const request = await deleteRequest(API.COST_CENTERS_RECORDS + id + "/")
        if (request.status === 204) {
            setData(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count - 1,
                    results: prev.data.results.filter(item => item.id !== id)
                }
            }))
        }
        setProcessing(false)
    }

    const InvoicePreview = ({ item }) => {
        return (
            <>
            { item.invoice_reference !== null ?
                <SuperDuperModal
                    size="large"
                    trigger={
                        <span style={{ color: "var(--primary)" }} className="ref-link">{ item.invoice_reference.split('__')[1] }</span>
                    }
                    content={
                        <InvoicePreviewPDF id={item.invoice_reference.split('__')[0]}/>
                    }
                />
            : "--" }
            </>
        )
    }

    return (
        <FlexTable>
            <FlexRow>
                <FlexHeader basis="150%" content={t("record")} />
                <FlexHeader basis="50%" content={t("type")} />
                <FlexHeader basis="50%" content={t("date")} />
                <FlexHeader content={t("sum")} />
                <FlexHeader content={t("document")} />
                <FlexHeader content={t("note")} />
                <FlexItem basis="20%">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {(processing || loading) && <Loader active={processing || loading} inline />}
                    </div>
                </FlexItem>
            </FlexRow>
            <EmptyRow length={data?.length}/>
            { data?.map(item =>
                <FlexRow key={item.id} fontSize="1rem" borders>
                    <FlexItem basis="150%">
                        { item?.category?.id
                            ?
                                <>
                                    { item?.category?.code !== "" && item?.category?.code !== null && item?.category?.code + " - "}
                                    { item?.category?.title }
                                </>
                            : ""
                        }
                        <strong>
                            { item.account?.id && <> <br/>{ item.account?.name } <br/></> }
                            { item.owner?.id && <> <br/>{ item.owner?.fullname } </>}
                        </strong>
                    </FlexItem>
                    <FlexItem basis="50%">
                        <b style={{ color: item.category?.type === 1 ? 'var(--success)' : 'var(--danger)' }}>{item.category?.type_display}</b>
                    </FlexItem>
                    <FlexItem basis="50%">{moment(item.date).format("DD.MM.YYYY")}</FlexItem>
                    <FlexItem>{thousandsSeparators(parseFloat(item.amount.sum).toFixed(2))} {item.amount.currency}</FlexItem>
                    <FlexItem bold>
                        { item.file !== null &&
                            <SuperDuperModal
                                size="large"
                                trigger={
                                    <Icon
                                        title={t('scan')}
                                        name="document-outline"
                                        style={{ cursor: "pointer", color: "var(--dark)" }}
                                    />
                                }
                                content={
                                    <DocPreview uri={item.file}/>
                                }
                            />
                        }

                        { (item.invoice_reference !== "" && item.invoice_reference !== null) &&
                            <InvoicePreview item={item}/>
                        }
                    </FlexItem>

                    <FlexItem> { item?.title } </FlexItem>
                    <FlexItem basis="20%">
                            { !processing && (canManage || canDelete || canManagePerm) &&
                                <Dropdown icon="ellipsis vertical" direction='left'>
                                    <Dropdown.Menu>
                                        {(canManage || canManagePerm) &&
                                        <SuperDuperModal
                                            size="small"
                                            trigger={<Dropdown.Item text={t('edit')} icon={<Icon name="pencil-outline" style={{ marginRight: "0.5rem" }}/>} />}
                                            content={<RecordForm setData={setData} data={item} />}
                                            header={t('edit_record')}
                                        />}
                                        {(canDelete || canManagePerm) &&
                                        <ConfrimModal
                                            description={t('do_you_really_want_to_delete_record') + " " + item.title + "?"}
                                            onConfirm={() => deleteRecord(item.id)}
                                            button={<Dropdown.Item text={t('delete')} icon={<Icon name="close-outline" style={{ marginRight: "0.5rem", color: "var(--danger)" }}/>} />}
                                        />}
                                    </Dropdown.Menu>
                                </Dropdown>
                            }
                    </FlexItem>
                </FlexRow>)}
        </FlexTable>
    );
};

export default DetailTable;