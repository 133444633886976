import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// components
import Icon from '@components/Icon';
import { Grid } from 'semantic-ui-react';
import UserAvatar from 'react-user-avatar';
// eslint-disable-next-line
import Action from '@components/general/Action';

const AvatarIcon = (props) => {
    // eslint-disable-next-line
    const { t } = useTranslation();
    const [avatar, setAvatar] = useState(props.src);

    const handleChange = async function loadFile(event) {

        if (event.target.files.length > 0) {
            const image = event.target.files[0];
            const result = await props.handleUpload(image);

            if (result.status === 200) {
                if (result.response.avatar) {
                    setAvatar(result.response.avatar);
                }
                else if (result.response.logo) {
                    setAvatar(result.response.logo);
                }
                else if (result.response.profile_picture) {
                    setAvatar(result.response.profile_picture);
                }
            }
            else {
                console.log("image upload failure");
            }

        }

    };

    const hiddenFileInput = React.useRef(null);
    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleDelete = async () => {

        const result = await props.handleDelete();

        if (result.status === 204) {
            setAvatar(result.response);
        }
        else {
            console.log("image delete failure");
        }

    };


    return (
        <Grid columns={1} centered>
            <Grid.Row >

                <UserAvatar
                    style={{ paddingLeft: '0rem', fontSize: props.size / 2.5 + "px" }}
                    size={props.size}
                    name={props.name}
                    color='var(--variant5)'
                    src={avatar}
                />

            </Grid.Row>
            { props.uploadable ?
                <Grid.Row>
                    <span style={{ padding: "0.785714em" }} >
                        <Icon name="pencil-outline" onClick={handleClick} style={{ cursor: "pointer" }}/>
                        <input
                            type="file"
                            id="avatar"
                            name="avatar"
                            accept="image/png, image/jpeg"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none" }}
                        />
                    </span>
                    {avatar &&
                    <span style={{ padding: "0.785714em" }} >
                        <Icon name="close-outline" onClick={handleDelete} style={{ color: "var(--danger)", cursor: "pointer" }} />
                    </span>
                    }
                </Grid.Row>
                : ''
            }
        </Grid>
    );
};

export default AvatarIcon;