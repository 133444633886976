import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from "react-router-dom";
// store
import { icons } from '@icons';
import { API } from '@store/config';
import { routes } from '@routes/index';
import { getRequest } from '@services/ServiceCommon';
import { accountsService } from '@services/ServiceAccounts'
import { useHasPermissions } from '@helpers/hooks';
// components
// import Tables from './Tables';
import Icon from '@components/Icon';
import AccountHeader from '../components/AccountHeader'
import { AddTeamForm } from '../components/forms/AddTeamForm';
import BreadcrumbNav from '@components/BreadcrumbNav';
import Loading from '@components/general/Loading';
import { EditHeaderAccount}  from '../components/forms/EditHeaderAccount';
import { Container, Tab, Button } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import {AddResponsiblePersonForm} from '../components/forms/AddResponsiblePersonForm';
import CanView from '@components/perms/CanView';
import DetailView from '../components/DetailView';
import FilesTab from '@components/documents/FilesTab';


const AccountsDetail = () => {
    const { t } = useTranslation()
    const params = useParams();
    const [id] = useState(params.id);
    const [data, setData] = useState({})
    const history = useHistory()

    const [creatorId, setCreatorId ] = useState(0)
    const [isLoading, setIsLoading] = useState(true);

    const canViewHomeTab = useHasPermissions(["accounts.c_view_assigned_accounts", "accounts.c_view_user_accounts", "accounts.c_view_all_accounts"]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            const request = await getRequest(API.ACCOUNTS + params.id + '/');

            if (request.status === 403) {
                history.push(routes.DASHBOARD)
                return
            }

            if (request.status === 200) {
                setData(request.response);
                setCreatorId(request.response.dts.created_by)
            }
            setIsLoading(false);
        };

        fetchData()

        // eslint-disable-next-line
    }, [params.id]);

    async function deleteResponsiblePerson(deleted) {
        const result = await accountsService.updateAccount(id, {
            assigned_to: { remove: [deleted] }
        });

        if (result.status === 200) {
            setData(result.response);
        }
    }

    async function deleteTeam(deleted) {
        const result = await accountsService.updateAccount(id, {
            teams: { remove: [deleted] }
        });

        if (result.status === 200) {
            setData(result.response);
        }
    }

    const actionAddWebsite = [
        <CanView permissions={["accounts.c_manage_assigned_accounts", "accounts.c_manage_user_accounts", "accounts.c_manage_all_accounts"]}>
            {/* <SuperDuperModal
                key={1}
                trigger={
                    <Button icon='globe' as='a' target="_blank" size="small" content={t('add_website')} />
                }
                header={t('update_header')}
                size="small"
                content={
                    <EditHeaderAccount
                        btnName={t('update')}
                        data={data}
                        onConfirm={
                            (updated_item) => {
                                setData(updated_item);
                            }
                        }
                    />
                }
            /> */}
        </CanView>
    ];

    const action = [
        <CanView permissions={["accounts.c_manage_assigned_accounts", "accounts.c_manage_user_accounts", "accounts.c_manage_all_accounts"]}>
            <SuperDuperModal
                key={1}
                trigger={<Button style={{ backgroundColor: "transparent", paddingLeft: "2rem" }} icon> <Icon name="pencil-outline" /></Button>}
                header={t('update_header')}
                size="small"
                content={
                    <EditHeaderAccount
                        btnName={t('update')}
                        data={data}
                        onConfirm={
                            (updated_item) => {
                                setData(updated_item);
                            }
                        }
                    />
                }
            />
        </CanView>
    ];

    const actionAddPerson = [
        <CanView permissions={["accounts.c_manage_assigned_accounts", "accounts.c_manage_all_accounts"]}>
            <SuperDuperModal
                key={2}
                trigger={
                    data.assigned_to?.length === 0 
                        ?
                        <Button basic icon={<Icon name="add-outline"/>} content={t('assign')} />
                        :
                        <Button basic icon={<Icon name="add-outline"/>} />
                }
                header={t('add_responsible_person')}
                size="tiny"
                content={
                    <AddResponsiblePersonForm
                        assigned={data.assigned_to}
                        data={data}
                        onConfirm={
                            (new_data) => {
                                setData(new_data);
                            }

                        }
                    />
                }
            />
        </CanView>
    ];

    const actionAddTeam = [
        <CanView permissions={["accounts.c_manage_assigned_accounts", "accounts.c_manage_all_accounts"]}>
            <SuperDuperModal
                key={2}
                trigger={
                    data.teams?.length === 0 ?
                        <Button basic icon={<Icon name="add-outline"/>} content={t('assign_team')} />
                        :
                        <Button basic icon={<Icon name="add-outline"/>} />
                }
                header={t('add_team')}
                size="tiny"
                content={
                    <AddTeamForm
                        teams={data.teams}
                        data={data}
                        onConfirm={
                            (new_data) => {
                                setData(new_data);
                            }

                        }
                    />
                }
            />
        </CanView>
    ];


    const breadcrumb_items = [
        { 'name': t('accounts_list'), 'icon': icons.ACCOUNTS, 'href':  routes.ACCOUNTS },
        { 'name': data.name, 'icon': '', 'href': routes.ACCOUNTS_DETAIL + id  },
    ]

    const panes = [
        {
            menuItem: canViewHomeTab && t('home'),
            render: () =>
                <Tab.Pane>
                    <DetailView account={data} setAccount={setData} />
                </Tab.Pane>
        },
        {
            menuItem: canViewHomeTab && t('documents'),
            render: () =>
                <Tab.Pane>
                    <FilesTab id={id} module={'account'} getEndpoint={API.ACCOUNTS} updateEndpoint={API.ACCOUNTS} />
                </Tab.Pane>
        },
    ]


    return (
        <Fragment >
            <BreadcrumbNav items={breadcrumb_items} />
            { isLoading ? <Loading /> :
                <Container fluid>
                    <AccountHeader
                        actionAddWebsite={actionAddWebsite}
                        actionAddPerson={actionAddPerson}
                        actionAddTeam={actionAddTeam}
                        action={action}
                        creatorId={creatorId}
                        data={data}
                        functionDeletePerson={(deleted) => deleteResponsiblePerson(deleted)}
                        functionDeleteTeam={(deleted) => deleteTeam(deleted)}
                    />
                    <Tab panes={panes} />
                </Container>
            }
        </Fragment>
    )
}

export default AccountsDetail;
