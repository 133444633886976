import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
import { teamsService } from '@services/ServiceTeams';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const NewRecruitersForm = ({ recruitersList, onClose, onConfirm }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false);
    const [assignee, setAssignee] = useState([]);
    const [team, setTeam] = useState([]);

    const handleSubmit = async () => {
        setIsProcessing(true);

        onConfirm({
            assignee: assignee
        })
        onClose()

        setIsProcessing(false);
    }

    const getPeopleFromTeam = async () => {
        if (team.length !== 0) {
            const request = await teamsService.getTeamMembers(team[team.length - 1]);

            if (request.status === 200) {
                const members = request.response.map(item => item.profile ? item.profile.id : null)
                const assigneePeople = assignee

                let mergedAssignee = [...assigneePeople, ...members.filter(c => !assigneePeople.includes(c) && c !== null)];

                setAssignee(mergedAssignee)
            }
        }
    }

    useEffect(() => {
        async function fetchData() {
            await getPeopleFromTeam()
        }

        fetchData();
        // eslint-disable-next-line
    }, [team])

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="choice"
                search
                placeholder={t('recruiters')}
                multiple
                endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                exclude={recruitersList}
                text="fullname"
                help={t('hint_to_assign_recruiters')}
                label={t('recruiters')}
                value={assignee}
                onChange={(e, { value }) => setAssignee(value)}
            />

            <Divider/>

            <SuperField as="choice"
                search
                placeholder={t('teams')}
                endpoint={API.TEAMS + "?only_basic_info=True"}
                multiple
                help={t('hint_to_assign_teams')}
                text="name"
                label={t('teams')}
                value={team}
                onChange={(e, { value }) => setTeam(value)
                }
            />

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing || isEmpty(assignee)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default NewRecruitersForm;