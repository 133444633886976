import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from 'react-router-dom';
// store 
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { addInvoiceCurrency } from '@store/actions';
import { thousandsSeparators } from '@helpers/functions';
//components
import SuperField from '@components/forms/SuperField';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Grid, Segment, Card } from 'semantic-ui-react';
import InvoiceInfoCard from "../components/cards/InvoiceInfoCard";

const InvoicesCardListing = ( { connection, journal, initialFilters, setFilters, type } ) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const dispatch = useDispatch()
    const invoiceCurrency = useSelector((state) => state.invoice_currency)
    const currencies = useSelector(state => state.choices.currency_codes)

    const [stats, setStats] = useState(null)
    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line
    const [currency, setCurrency] = useState(invoiceCurrency)

    useEffect(() => {
        async function fetchStats(){
            setLoading(true)
            let currencyFilter = "&currency=" + currency
            if( currency === "all" ) currencyFilter = ""
            const request = await getRequest(API.INVOICES + "invoice_statistics/?connection=" + connection.connection_id + "&account_ids=" + journal.id + "&is_issued=" + (type === "incomes" ? true : false) + currencyFilter)
            
            if( request.status === 200 ){
                setStats(request.response)
            }
            setLoading(false)
        }

        fetchStats()
        // eslint-disable-next-line
    }, [type, currency, location.key])


    const getTotals = (type) => {
        if( loading ) return <StatNumber number={0}/>
        if( stats?.length === 0 ) return <StatNumber number={0 + " EUR"}/>
        if( !Array.isArray(stats) && currency === "all" ) return <StatNumber number={0 + " EUR"}/>

        if( currency !== "all" ){
            if( type === "all" ) return <StatNumber number={(parseFloat(stats?.all || 0).toFixed(2) || 0) + " " + stats?.currency || ""}/>
            if( type === "draft" ) return <StatNumber number={(parseFloat(stats?.draft || 0).toFixed(2) || 0) + " " + stats?.currency || ""}/>
            if( type === "outstanding" ) return <StatNumber number={(parseFloat(stats?.outstanding || 0).toFixed(2) || 0) + " " + stats?.currency || ""}/>
            if( type === "past_due" ) return <StatNumber number={(parseFloat(stats?.past_due || 0).toFixed(2) || 0) + " " + stats?.currency || ""}/>
            if( type === "paid" ) return <StatNumber number={(parseFloat(stats?.paid || 0).toFixed(2) || 0) + " " + stats?.currency || ""}/>
        } else {
            if( type === "all" ) return (
                <>
                    { stats?.map((stat, index) => (
                        <StatNumber key={index} number={(parseFloat(stat.all).toFixed(2) || 0) + " " + stat.currency || ""}/>
                    ))}
                </>
            )

            if( type === "draft" ) return (
                <>
                    { stats?.map((stat, index) => (
                        <StatNumber key={index} number={(parseFloat(stat.draft).toFixed(2) || 0) + " " + stat.currency || ""}/>
                    ))}
                </>
            )

            if( type === "outstanding" ) return (
                <>
                    { stats?.map((stat, index) => (
                        <StatNumber key={index} number={(parseFloat(stat.outstanding).toFixed(2) || 0) + " " + stat.currency || ""}/>
                    ))}
                </>
            )

            if( type === "past_due" ) return (
                <>
                    { stats?.map((stat, index) => (
                        <StatNumber key={index} number={(parseFloat(stat.past_due).toFixed(2) || 0) + " " + stat.currency || ""}/>
                    ))}
                </>
            )

            if( type === "paid" ) return (
                <>
                    { stats?.map((stat, index) => (
                        <StatNumber key={index} number={(parseFloat(stat.paid).toFixed(2) || 0) + " " + stat.currency || ""}/>
                    ))}
                </>
            )
        }
    }

    const getCounts = (type) => {
        if( loading ) return 0
        if( stats?.length === 0 ) return 0
        if( !Array.isArray(stats) && currency === "all" ) return 0

        if( currency !== "all" ){
            if( type === "all" ) return stats?.all_count || 0
            if( type === "draft" ) return stats?.draft_count || 0
            if( type === "outstanding" ) return stats?.outstanding_count || 0
            if( type === "past_due" ) return stats?.past_due_count || 0
            if( type === "paid" ) return stats?.paid_count || 0
        } else {
            if( type === "all" ) return stats?.map(stat => stat.all_count).reduce((partialSum, a) => partialSum + a, 0)
            if( type === "draft" ) return stats?.map(stat => stat.draft_count).reduce((partialSum, a) => partialSum + a, 0)
            if( type === "outstanding" ) return stats?.map(stat => stat.outstanding_count).reduce((partialSum, a) => partialSum + a, 0)
            if( type === "past_due" ) return stats?.map(stat => stat.past_due_count).reduce((partialSum, a) => partialSum + a, 0)
            if( type === "paid" ) return stats?.map(stat => stat.paid_count).reduce((partialSum, a) => partialSum + a, 0)
        }

        return 0
    }

    const StatNumber = ({number}) => {
        return (
            <Card.Header style={{ fontSize: "1.5rem", width:"10rem", color:"black" }}>
                { thousandsSeparators(number) }
            </Card.Header>
        )
    }

    const handleViewChange = (view) => {
        setFilters({...initialFilters, status: view})
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
    }

    return (
        <>
            <Grid>
                <Grid.Row style={{ paddingTop: 0 }}>
                    <Grid.Column style={{ textAlign: "right", marginRight: "4rem" }}>
                        <FlexRow padding="0" background="transparent">
                            <FlexItem textAlign="right">
                                <SuperField inline as="choice"
                                    size="small"
                                    label={<span style={{ marginRight: "1rem" }}>{t('display_in_currency')}</span>}
                                    search
                                    style={{ width: "200px" }}
                                    customOptions={[
                                        { key: 0, text: t('all_currencies'), value: "all" },
                                        ...Object.entries(currencies).map(([key,value]) => ({ 
                                            key: key, text: value, value: key
                                        }))
                                    ]}
                                    value={currency || ""}
                                    onChange={(e, { value }) => {
                                        if( value === "" ){
                                            setCurrency("all")
                                            dispatch(addInvoiceCurrency("all"))
                                        } else {
                                            setCurrency(value)
                                            dispatch(addInvoiceCurrency(value))
                                        }

                                    }}
                                />
                            </FlexItem>
                        </FlexRow> 
                        
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Segment loading={loading} style={{ padding: 0, margin: 0, backgroundColor: "transparent", minHeight: "5rem", border: "none", boxShadow: "none" }}>
                <Grid centered>
                    <Grid.Row>
                        <InvoiceInfoCard
                            key={1}
                            onClick={() => handleViewChange("all")}
                            color="black"
                            number={ getTotals('all') }
                            description={t('all_invoices') + " - " + getCounts('all') }
                            icon="document-text-outline"
                        />
                        <InvoiceInfoCard
                            key={2}
                            onClick={() => handleViewChange("4")}
                            color="blue"
                            number={ getTotals('draft') }
                            description={t('drafts') + " - " + getCounts('draft')}
                            icon="document-outline"
                        />
                        <InvoiceInfoCard
                            key={3}
                            onClick={() => handleViewChange("1")}
                            color="orange"
                            number={ getTotals('outstanding')}
                            description={t('outstanding')+ " - " + getCounts('outstanding') }
                            icon="alert-circle-outline"
                        />
                        <InvoiceInfoCard
                            key={4}
                            onClick={() => handleViewChange("1&date_due_before=" + moment().add("-1", 'days').format("YYYY-MM-DD"))}
                            color="red"
                            number={ getTotals('past_due')}
                            description={t('past_due') + " - " + getCounts('past_due') }
                            icon="warning-outline"
                        />
                        <InvoiceInfoCard
                            key={5}
                            onClick={() => handleViewChange("3")}
                            color="green"
                            number={ getTotals('paid')}
                            description={t('paid') + " - " + getCounts('paid') }
                            icon="checkmark-outline"
                        />
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );
};

export default InvoicesCardListing;