import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { useQueryPage } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { Grid, Container, Sidebar, Segment, Menu, Button, Input, Ref } from 'semantic-ui-react';
import Loading from '@components/general/Loading';
import CanView from '@components/perms/CanView';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Paginator from '@components/Paginator';
// module specific
import ProjectTableView from '../components/ProjectTableView';
import ProjectCardView from '../components/ProjectCardView';
import ProjectListFiltersForm from '../components/forms/ProjectListFiltersForm';
import ProjectCreateForm from '../components/forms/ProjectCreateForm';


const ProjectList = () => {
    let page = useQueryPage()
    const { t } = useTranslation();
    const segmentRef = useRef()
    const location = useLocation()
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState([])
    const [view, setView] = useState(0)
    const [limit, setLimit] = useState(10)
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const initState = {
        isActive: true,
        projectMember: "",
        projectManager: "",
        fromDate: "",
        dueDate: "",
        endDate: "",
        labels: [],
        // isApproved: ""
    }
    const [filters, setFilters] = useState(initState)


    const setParams = () => {
        let params = "&search=" + search
        if( filters.fromDate !== "") params += `&from_date=${filters.fromDate}`
        if( filters.dueDate !== "") params += `&due_date=${filters.dueDate}`
        if( filters.endDate !== "") params += `&end_date=${filters.endDate}`
        if( filters.projectMember !== "") params += `&project_member=${filters.projectMember}`
        if( filters.projectManager !== "") params += `&project_manager=${filters.projectManager}`
        if( filters.isApproved !== "") params += `&is_approved=${filters.isApproved}`
        // if( filters.isActive !== "") params += `&is_active=${filters.isActive}`

        if( filters.labels.length > 0 ){
            for (let i = 0; i < filters.labels.length; i++) {
                params += "&labels="+ filters.labels[i]
            }
        }

        return params
    }

    const fetchData = async (params, isFiltered) => {
        params = params || ""
        params += setParams()
        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }
        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        setIsLoading(true)
        const request = await requests.get(API.PROJECTS + `?paginate=true&is_template=false` + params)

        if (request.status === 200) {
            setProjects({
                ...projects,
                data: request.response
            })
        }
        setIsLoading(false)

    }

        useEffect(() => {
            fetchData('', true)
        // eslint-disable-next-line
    }, [search])

    const onCreate = (response) => {
        setProjects(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                count: prevState.data.count + 1,
                results: [response, ...prevState.data.results]
            }
        }))
    }

    return (
        <CanView permissions={['projects.c_view_all_projects', 'projects.c_view_user_projects']} redirect>
        <BreadcrumbNav items={[{ 'name': t("projects"), 'icon': icons.PROJECTS, 'href': '' }]} />
          <Ref innerRef={segmentRef} >
            <Container fluid >
            <Sidebar.Pushable as={Segment} style={{ marginBottom: "1rem", border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                <Sidebar
                    style={{ width: "40rem", }}
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    icon='labeled'
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    width='wide'
                    target={segmentRef}
                >
                  <ProjectListFiltersForm
                      setVisible={setVisible}
                      setProjects={setProjects}
                      fetchData={fetchData}
                      initState = {initState}
                      filters={filters}
                      setFilters={setFilters}
                      search={search}
                  />
                </Sidebar>

            <Sidebar.Pusher>
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={0} tablet={2} computer={8}></Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={4} textAlign='right'>
                        <Input size="small" icon='search' value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search')} style={{ margin: "5px", width: "100%" }} />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={9} computer={4} textAlign='right'>
                        <Button
                            size="small"
                            style={{
                            background: "transparent",
                            color: "var(--black)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem"
                            }}
                            disabled={view===1}
                            onClick={() => {
                                setLimit(10)
                                setView(1)
                            }}
                        >
                            <Icon name='grid-outline' style={{ margin: 0 }} />
                        </Button>
                        <Button
                            size="small"
                            style={{
                            background: "transparent",
                            color: "var(--black)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem"
                            }}
                            disabled={view===0}
                            onClick={() => {
                                setLimit(10)
                                setView(0)
                            }}
                        >
                            <Icon name='list-outline' style={{ margin: 0 }} />
                        </Button>
                        <Button
                            size="small"
                            style={{
                            background: "transparent",
                            color: "var(--black)",
                            paddingLeft: "1rem",
                            paddingRight: "1rem"
                            }}
                            onClick={() => setVisible(!visible)}
                        >
                            <Icon name='funnel-outline' style={{ margin: 0 }} />
                        </Button>
                        <CanView permissions={['projects.c_manage_all_projects']}>
                            <SuperDuperModal
                                header={t('create_project')}
                                size="small"
                                trigger={
                                    <Button size="small" primary>{t('create_project')}</Button>
                                }
                                content={
                                    <ProjectCreateForm onConfirm={(response, type) => onCreate(response, type)} />
                                }
                            />
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                        {view === 0 && isLoading ? <Loading /> :
                            <>
                                {view === 0 && <ProjectTableView projects={projects} setProjects={setProjects} />}
                            </>
                        }
                        {view === 1 && isLoading ? <Loading /> :
                            <>
                                {view === 1 && <ProjectCardView projects={projects} setProjects={setProjects} />}
                            </>
                        }
                        { projects?.data?.count > 10 ?
                            <Paginator
                                forcePage={page || 1}
                                limit={limit}
                                setLimit={setLimit}
                                length={projects?.data?.count || 0}
                                onChange={(params) => fetchData(params)}
                            /> :
                            <>
                            <br />
                            <br />
                            </>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
                    {/* <Button.Group basic size="small" style={{ borderRadius: "0" }}>
                        <Button active={view === 0} content={t('table')} onClick={() => setView(0)} />
                        <Button active={view === 1} content={t('cards')} onClick={() => setView(1)} />
                    </Button.Group> */}


            </Sidebar.Pusher>
            </Sidebar.Pushable>
            </Container>
          </Ref>
    </CanView>
    )
}

export default ProjectList