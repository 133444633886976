import { APIRequest } from './helpers/requests';
import { API } from '../config';

export const jobsService = {
    getJobOffers,
    getJobOffer,
    deleteJobOffer,
    getBaseOffer,
    getJobPublications,
    publishJobOffer,
    createPublication,
    getRabotaCities,
    getJobPositions,
    deleteJobPosition,

    // *****
    createJobOffer,
    updateJobOffer,
    updateBaseOffer,
    createWorkPlace,
    updateWorkPlace,
    createSalary,
    updateSalary,
    createContract,
    updateContract,
    createRequirements,
    updateRequirements,
};

async function createJobOffer(data) {
    return await APIRequest({
        url: API.JOB_OFFERS,
        method: 'POST',
        data: data,
        private: true,
    });
}

async function updateJobOffer(id, data) {
    return await APIRequest({
        url: API.JOB_OFFERS + id + '/',
        method: 'PATCH',
        data: data,
        private: true,
    });
}

async function updateBaseOffer(id, data) {
    return await APIRequest({
        url: API.BASE_OFFERS + id + '/',
        method: 'PATCH',
        data: data,
        private: true,
    });
}

async function createWorkPlace(data) {
    return await APIRequest({
        url: API.JOB_WORKPLACES,
        method: 'POST',
        data: data,
        private: true,
    });
}

async function updateWorkPlace(id, data) {
    return await APIRequest({
        url: API.JOB_WORKPLACES + id + '/',
        method: 'PUT',
        data: data,
        private: true,
    });
}
async function createSalary(data) {
    return await APIRequest({
        url: API.JOB_SALARIES,
        method: 'POST',
        data: data,
        private: true,
    });
}

async function updateSalary(id, data) {
    return await APIRequest({
        url: API.JOB_SALARIES + id + '/',
        method: 'PUT',
        data: data,
        private: true,
    });
}

async function createContract(data) {
    return await APIRequest({
        url: API.JOB_CONTRACTS,
        method: 'POST',
        data: data,
        private: true,
    });
}

async function updateContract(id, data) {
    return await APIRequest({
        url: API.JOB_CONTRACTS + id + '/',
        method: 'PUT',
        data: data,
        private: true,
    });
}

async function createRequirements(data) {
    return await APIRequest({
        url: API.JOB_REQUIREMENTS,
        method: 'POST',
        data: data,
        private: true,
    });
}

async function updateRequirements(id, data) {
    return await APIRequest({
        url: API.JOB_REQUIREMENTS + id + '/',
        method: 'PATCH',
        data: data,
        private: true,
    });
}

async function getJobOffers(params) {
    params = params || '';
    return await APIRequest({
        url: API.GET_JOB_OFFERS + params,
        method: 'GET',
        data: {},
        private: true,
    });
}

async function getJobPositions(params) {
    params = params || '';
    return await APIRequest({
        url: API.JOB_POSITIONS + params,
        method: 'GET',
        data: {},
        private: true,
    });
}

async function getJobOffer(id) {
    return await APIRequest({
        url: API.GET_JOB_OFFERS + id + '/',
        method: 'GET',
        data: {},
        private: true,
    });
}

async function getBaseOffer(params) {
    params = params || '';
    return await APIRequest({
        url: API.BASE_OFFERS + params,
        method: 'GET',
        data: {},
        private: true,
    });
}
async function deleteJobOffer(id) {
    return await APIRequest({
        url: API.GET_JOB_OFFERS + id + '/',
        method: 'DELETE',
        private: true,
    });
}

async function deleteJobPosition(id) {
    return await APIRequest({
        url: API.JOB_POSITIONS + id + '/',
        method: 'DELETE',
        private: true,
    });
}

async function getJobPublications(params) {
    params = params || '';
    return await APIRequest({
        url: API.JOB_PUBLICATIONS + params,
        method: 'GET',
        data: {},
        private: true,
    });
}

async function publishJobOffer(id) {
    return await APIRequest({
        url: API.JOB_PUBLICATIONS + id + '/publish/',
        method: 'PUT',
        data: {},
        private: true,
    });
}

async function createPublication(data) {
    return await APIRequest({
        url: API.JOB_PUBLICATIONS,
        method: 'POST',
        data: data,
        private: true,
    });
}

async function getRabotaCities() {
    return await APIRequest({
        url: API.RABOTA_CITIES,
        method: 'GET',
        data: {},
        private: true,
    });
}
