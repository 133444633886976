import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
// components
import { Form, Header, Button, Divider, List } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'

const RecordFilterForm = ({ setFilters, options }) => {
    const { t } = useTranslation();
    const [filtersRecords, setFiltersRecords] = useState({
        search: "",
        date_start: "",
        date_end: "",
        category: "",
        business_detail: ""
    })

    const handleSubmit = async () => {
        setFilters(filtersRecords)
    }

    return (
        <Form onSubmit={() => handleSubmit()} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>

            <Divider />
            <SuperField as="input"
                icon="search"
                iconPosition="left"
                label={t('search')}
                placeholder={t('search')}
                value={filtersRecords.search}
                onChange={(e, { value }) => setFiltersRecords({
                    ...filtersRecords,
                    search: value
                })}
            />
            <SuperField as="choice"
                search
                endpoint={API.TIMESHEETS_CATEGORIES}
                text="title"
                label={t('activity')}
                value={filtersRecords.category}
                onChange={(e, { value }) => setFiltersRecords({
                    ...filtersRecords,
                    category: value
                })}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="datepicker"
                    value={filtersRecords.date_start}
                    placeholder={t('start_date')}
                    label={t('start_date')}
                    onChange={(e, { value }) => setFiltersRecords({
                        ...filtersRecords,
                        date_start: value
                    })}
                />
                <SuperField
                    as="datepicker"
                    label={t('end_date')}
                    value={filtersRecords.date_end}
                    placeholder={t('end_date')}
                    onChange={(e, { value }) => setFiltersRecords({
                        ...filtersRecords,
                        date_end: value
                    })}
                />
            </Form.Group>

            <SuperField as="choice"
                search
                customOptions={options}
                label={t('employer')}
                value={filtersRecords?.business_detail}
                onChange={(e, { value }) => setFiltersRecords({
                    ...filtersRecords,
                    business_detail: value
                })}
            />

            <Form.Field>
                <Button.Group>
                    <Button size="small" primary> {t('apply')} </Button>
                    {/* <Button type="button" onClick={resetFilters} size="small"> {t('reset')} </Button> */}
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default RecordFilterForm;