import React, { Fragment, useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Button, Form } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperField from '@components/forms/SuperField';


const CategoryForm = ({ category, setCategories, visible, hideForm }) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState(category ? category?.title : "")
    const [processing, setProcessing] = useState(false)

    const handleSubmit = async () => {
        setProcessing(true)
        let request = null;
        const data = { title: title }
        if (category?.id) { // update
            request = await requests.put(API.COURSES_CATEGORIES + category?.id + "/", data)
        } else { // create
            request = await requests.post(API.COURSES_CATEGORIES, data)
        }

        if (request.status === 200 || request.status === 201) {
            if (category?.id) { // update
                setCategories(prev => ({
                    ...prev, data: prev.data.filter(item => {
                        if (item.id === category.id) {
                            item.title = request.response.title
                        }
                        return item
                    })
                }))
                hideForm()
            } else {
                setCategories(prev => ({ ...prev, data: [...prev.data, request.response] }))
                setTitle("")
            }

        }
        setProcessing(false)
    }

    return (
        <>
            {visible &&
                <Form style={{ marginTop: "1rem" }} onSubmit={handleSubmit}>
                    <Form.Group>
                        <SuperField as="input"
                            fluid
                            autoFocus
                            width={10}
                            size="small"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <Form.Field width={6}>
                            <Button disabled={processing || title === ""} loading={processing} style={{ width: "100%" }} size="small" primary icon={<Icon name="add-outline" style={{ marginRight: "0.5rem" }}/>} content={<span style={{ position: "relative", top: "-0.2rem" }}> {category?.id ? t('save') : t('add')} </span>} />
                        </Form.Field>
                    </Form.Group>
                </Form>
            }
        </>
    )
}


const CategoriesList = ({ categories, setCategories }) => {
    // const { t } = useTranslation();
    const [openedForm, setOpenedForm] = useState("")

    const onDelete = async (id) => {
        const request = await requests.del(API.COURSES_CATEGORIES + id + "/")

        if (request.status === 204) {
            setCategories(prev => ({ ...prev, data: prev.data.filter(item => item.id !== id) }))
        }
    }

    return (
        <>
            {categories.map(category => (
                <Fragment key={category.id}>
                    <CategoryForm
                        category={category}
                        setCategories={setCategories}
                        visible={openedForm === "form_" + category.id}
                        hideForm={() => setOpenedForm("")}
                    />
                    {openedForm !== "form_" + category.id &&
                        <FlexRow borders padding="0.8rem">
                            <FlexItem basis="200%" bold> {category.title} </FlexItem>
                            <FlexItem textAlign="right">
                                <Icon name="pencil-outline" style={{ marginRight: "2rem", cursor: "pointer" }} onClick={() => setOpenedForm("form_" + category.id)} />
                                <Icon name="close-outline" style={{ cursor: "pointer", color: "var(--danger)" }} onClick={() => onDelete(category.id)} />
                            </FlexItem>
                        </FlexRow>
                    }
                </Fragment>
            ))}

            <CategoryForm setCategories={setCategories} visible={true} />
        </>
    );
};

export default CategoriesList;