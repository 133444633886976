import React, {useState} from 'react';
import { useTranslation } from "react-i18next"
//components
import Icon from '@components/Icon';
import {FlexItem, FlexRow} from '@components/tables/FlexTable';
import { Divider, Grid, Header, Form } from 'semantic-ui-react';
import BusinessDetailForm from '../forms/BusinessDetailForm';


const BusinessDetailSection = ({ data, setData, patchEndpoint }) => {
    const { t } = useTranslation()
    const [view, setView] = useState(0);

    return (
        <>
        <Form>
        <Grid stackable>
            <Grid.Row>
                <Grid.Column only="computer" computer="3" stretched style={{ borderRadius: "4px", paddingTop: "1rem", backgroundColor: "var(--light)"}}>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem textAlign="center">
                            <Header icon >
                                <Icon  name='briefcase-outline' style={{ fontSize: "5rem" }} />
                                <h3>{t('business_info')}</h3>
                            </Header>
                        </FlexItem>
                    </FlexRow>

                </Grid.Column>
                <Grid.Column computer="12" tablet="16" mobile="16">
                    <Grid style={{ padding: 0 }} >
                        <Grid.Row style={{ padding: 0 }} >
                            <Grid.Column only="computer" computer="16">
                                <Header floated="left" as="h3" style={{ marginBottom: 0 }}>
                                    <Icon name="briefcase-outline" style={{ marginRight: "0.5rem" }}/>
                                    <span style={{ position: "relative", top: "-0.2rem" }}>{t('business_info')}</span>
                                </Header>
                                <Header floated="right" as="h4" style={{ marginBottom: 0 }}>
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <Icon name="pencil-outline" style={{ fontSize: "1rem", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('edit')}</span>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <Icon name="close-outline" style={{ fontSize: "1rem", color: "var(--danger)", marginRight: "0.5rem" }} />
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('cancel')}</span>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ padding: 0 }} only="mobile tablet" tablet="16" mobile="16">
                                <Header style={{ padding: 0 }} floated="left" as="h4">
                                    { view === 0 ?
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(1)}>
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('business_info')}</span>
                                            <Icon name="pencil-outline" style={{ fontSize: "1rem", marginLeft: "0.5rem" }}/>
                                        </span>
                                    :
                                        <span style={{ cursor: "pointer" }} onClick={() => setView(0)}>
                                            <span style={{ position: "relative", top: "-0.2rem" }}>{t('business_info')}</span>
                                            <Icon name="close-outline" style={{ fontSize: "1rem", marginLeft: "0.5rem", color: "var(--danger)" }}/>
                                        </span>
                                    }
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                    <Divider />
                    { view === 1 ?
                        <BusinessDetailForm
                            data={data}
                            setView={setView}
                            patchEndpoint={patchEndpoint}
                            onConfirm={setData}
                        /> :
                    <Grid stackable>
                        <Grid.Row columns={5}>
                            <Grid.Column>
                                {t('name')} <br />
                                <strong>{data?.business_detail?.name || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('id_number')} <br />
                                <strong>{data?.business_detail?.identification_number || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('dit_number')} <br />
                                <strong>{data?.business_detail?.dit_number || '~'}</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('vat_number')} <br />
                                <strong>{data?.business_detail?.vat_number || '~' }</strong>
                            </Grid.Column>
                            <Grid.Column>
                                {t('register_folder')} <br />
                                <strong>{data?.business_detail?.business_register_folder || '~'}</strong>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </Form>
        </>
    );
};

export default BusinessDetailSection;