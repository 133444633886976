import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from "react-i18next"

const ProjectGraph = ({ all, done, width }) => {
    const { t } = useTranslation();

    const todo = all - done
    const data = {
        labels: [t("done"), t("to_do")],
        datasets: [
            {
                label: 'My First dataset',
                data: [done, todo],
                backgroundColor: ['#6b48ff', '#EEE722'],
                hoverBackgroundColor: ['#552fe4', '#c9c313']
            }
        ]
    };
    return (

        <>
            {all ? <div className="graph-center">{Math.round((done / all * 100))}{"%"}<br />{t("done")}</div>
                : <div className="graph-center"><br />{t("no_issues")}</div>}

            <Doughnut
                data={data}
                width={width}
                options={{
                    cutoutPercentage: 65,
                    maintainAspectRatio: false,
                    legend: { display: false },
                    responsive: false,
                    tooltips: {
                        bodyFontColor: '#fff',
                        backgroundColor: '#000',
                    }
                }}
            />
        </>

    )
}


export default ProjectGraph
