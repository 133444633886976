import React, { useState }from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
import { useSelectOptions } from '@helpers/hooks';
import { patchRequest, createRequest, updateRequest } from '@services/ServiceCommon';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const TerminationForm = ({ template, onClose, contract, termination, setTerminations, setContracts }) => {
    const { t } = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false)
    const [reasons, setReasons] = useSelectOptions(API.CONTRACTS_TERMINATION_REASONS, 'title');
    const [formData, setFormData] = useState({
        contractInstance: contract.id,
        terminatedBy: termination?.id ? termination.terminated_by : "",
        type: termination?.id ? termination.type : "",
        noticePeriod: termination?.id ? termination.notice_period : "",
        note: termination?.id ? termination.note : "",
        reason: termination?.id ? termination.reason : ""
    })

    const handleAddition = async (item) => {
        setReasons({ ...reasons, isLoading: true });
        const request = await createRequest(API.CONTRACTS_TERMINATION_REASONS, {
            title: item,
        });

        if (request.status === 201) {
            setReasons((prevState) => {
                return {
                    options: [
                        {
                            key: request.response.id,
                            value: request.response.id,
                            text: request.response.title,
                        },
                        ...prevState.options,
                    ],
                };
            });

            setFormData((prevState) => ({
                ...prevState,
                reason: request.response.id
            }));
        }
    };

    const handleSubmit = async () => {
        setIsProcessing(true)
        const data = {
            contract_instance: formData.contractInstance,
            terminated_by: formData.terminatedBy,
            type: formData.type,
            notice_period: formData.noticePeriod,
            note: formData.note,
            reason: formData.reason,
            template: template !== null ? template : null
        }

        if( termination?.id ){ // update
            const request = await updateRequest(API.CONTRACTS_TERMINATIONS + termination.id + "/", data)

            if( request.status === 200 ){
                // setTerminations(prevState => {
                //     return {
                //         ...prevState,
                //         data: prevState.data.filter(record => {
                //             if(record.id === termination.id){
                //                 record.terminated_by = request.response.terminated_by
                //                 record.type = request.response.type
                //                 record.note = request.response.note
                //                 record.notice_period = request.response.notice_period
                //             }
    
                //             return record
                //         })
                //     }
                // })
                onClose()
            }
        } else { // create
            const request = await createRequest(API.CONTRACTS_TERMINATIONS, data)

            if( request.status === 201 ){
                const requestContract = await patchRequest(API.CONTRACTS_INSTANCES + contract.id + "/", { is_draft: false })

                if( requestContract.status === 200 ){
                    setContracts(prevState => {
                        return {
                            ...prevState,
                            data: {
                                ...prevState.data,
                                results: prevState.data.results.filter(record => {
                                    if(record.id === contract.id){
                                        record.is_draft = requestContract.response.is_draft
                                        record.notice_period = requestContract.response.notice_period
                                        record.is_terminated = requestContract.response.is_terminated
                                        record.is_active = requestContract.response.is_active
                                        // record.is_archived = requestContract.response.is_archived
                                    }
            
                                    return record
                                })
                            }
                        }
                    })
                    onClose()
                }
            }
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
                <SuperField as="choice"
                    required
                    label={ t('terminated_by') }
                    type="contract_termination_actors"
                    value={ formData.terminatedBy }
                    onChange={(e, { value }) => setFormData({...formData, terminatedBy: value}) }
                />
                <SuperField as="choice"
                    required
                    label={ t('type') }
                    type="contract_termination_types"
                    value={ formData.type }
                    onChange={(e, { value }) => setFormData({...formData, type: value}) }
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    closable
                    label={ t('notice_period') }
                    value={formData.noticePeriod}
                    onChange={(e, { value }) => setFormData({...formData, noticePeriod: value})}
                />
                <SuperField
                    as="choice"
                    search
                    label={t('reason')}
                    text="title"
                    help={t('reasons_type_and_hit_enter_to_create_hint')}
                    allowAdditions
                    disabled={reasons.isLoading}
                    loading={reasons.isLoading}
                    customOptions={reasons.options}
                    onAddItem={(e, { value }) => handleAddition(value)}
                    value={formData.reason}
                    onChange={(e, { value }) => setFormData({ ...formData, reason: value })}
                />
            </Form.Group>
            <SuperField as="textarea"
                label={ t('note') }
                value={ formData.note }
                onChange={(e, { value }) => setFormData({...formData, note: value}) }
            />

            <Divider/>
            <Form.Group style={{ marginBottom: 0 }}>
                <Form.Field width="8"/>
                <Form.Field width="8" style={{ textAlign: "right" }}>
                    <ModalCancel onClose={onClose} />
                    <ModalSubmit
                        loading={isProcessing}
                        disabled={isProcessing}
                        text={t('confirm')} />
                </Form.Field>
            </Form.Group>
        </Form>
    );
};

export default TerminationForm;