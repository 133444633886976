import React from 'react';
// components
import { Menu, Sidebar } from 'semantic-ui-react';
import CandidateFilterForm from '../components/forms/CandidateFilterForm';

const CandidateFilters = ({ visible, setVisible, setCandidates, setIsLoading, fetchCandidates }) => {
    return (
        <Sidebar
            style={{ width: "40rem",  }}
            as={Menu}
            animation='overlay'
            direction='right'
            icon='labeled'
            vertical
            visible={visible}
            onHide={() => setVisible(false)}
            width='wide'
        >
            <CandidateFilterForm 
                setCandidates={setCandidates}
                setIsLoading={setIsLoading}
                setVisible={setVisible}
                fetchCandidates={fetchCandidates}
            />
        </Sidebar>
    );
};

export default CandidateFilters;