import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next"

import { API } from '@store/config';
import { deleteRequest } from '@services/ServiceCommon';

import Icon from '@components/Icon';
import { Grid, Label, Segment } from 'semantic-ui-react'
import CanView from '@components/perms/CanView';
import AvatarList from '@components/general/AvatarList'
import ConfrimModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CaseDetail from '../CaseDetail';
import EditCaseContent from '../EditCaseContent';


const List = ({ data, handleDelete, handleUpdate }) => {
    const colors = useSelector(state => state.global_pref.colors)
    const { t } = useTranslation();
    const [openedCases, setOpenedCases] = useState([])

    const ListLabels = ({ data }) => {
        if (data.length === 0) return <></>
        return (
            <>
                {data.slice(0, 3).map((item, key) => (
                    <Label key={key} style={{ backgroundColor: item.color }}>{item.name}</Label>
                ))}
                {data.length > 3 && <Label>{data.length}</Label>}
            </>
        )
    }
    const ListUsers = ({ users, teams }) => {
        let usersList = users.map(item => (item.name))
        if (usersList.lenght === 0 && teams.lenght !== 0) {
            return (
                <>
                    {teams.map((item, key) => (
                        <Label key={key} style={{ marginBottom: '0.2rem' }}>
                            <Icon name='person-outline' style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                {item.name}
                            </span>
                        </Label>
                    ))}
                </>
            )
        }
        return (<AvatarList members={usersList} />)
    }

    function dateReformatOnlyDate(date) {
        let new_date = date.split(' ')
        new_date = new_date[0].split('-')
        return `${new_date[2]}.${new_date[1]}.${new_date[0]}`
    }

    function priorityColor(priority) {
        if (priority === 1) return colors.success
        if (priority === 2) return colors.primary
        if (priority === 3) return colors.warning
        if (priority === 4) return colors.danger
    }

    async function deleteCase(id) {
        let result = await deleteRequest(`${API.CASES}${id}/`)
        if (result.status === 204) {
            handleDelete(id)
        }
    }

    return data.map((item, key) => (
        <Fragment key={key + item.title + 'xxx'}>
            <Grid.Row columns={6} as={Segment} style={{ margin: ".5rem 0", fontWeight: "bold" }}>
                <Grid.Column style={{ flexDirection: 'column' }}>
                    <div>{item.title}</div>
                    <div>{dateReformatOnlyDate(item.created_on)}</div>
                </Grid.Column>
                <Grid.Column style={{ color: priorityColor(item.priority) }}>{item.priority_display}</Grid.Column>
                <Grid.Column>{item.status_display}</Grid.Column>
                <Grid.Column><ListLabels data={item.label} /></Grid.Column>
                <Grid.Column><ListUsers users={item.assigned_users} teams={item.assigned_teams} /></Grid.Column>
                <Grid.Column style={{ display: 'flex', justifyContent: "space-around" }}>
                    {openedCases.find(value => value === item.id)
                        ?
                        <Icon name='eye-off-outline' style={{ cursor: 'pointer', color: colors.primary }} onClick={() => setOpenedCases(openedCases.filter(x => x !== item.id))} />
                        :
                        <Icon name='eye-outline' style={{ cursor: 'pointer', color: colors.primary }} onClick={() => setOpenedCases(openedCases.concat([item.id]))} />
                    }
                    <CanView with permissions={["cases.c_manage_all_cases"]}>
                        <SuperDuperModal
                            header={`${t("update")} ${item.title}`}
                            content={
                                <EditCaseContent
                                    data={item}
                                    handleRequest={(e) => handleUpdate(e)} />
                            }
                            trigger={<Icon name='pencil-outline' style={{ cursor: 'pointer' }} />}
                        />
                    </CanView>
                    <CanView with permissions={["cases.c_delete_all_cases"]}>
                        <ConfrimModal
                            button={<Icon name='close-outline' style={{ cursor: 'pointer', color: colors.danger }} />}
                            onConfirm={() => deleteCase(item.id)}
                            description={`${t("delete_sentence")} ${item.title}?`}
                        />
                    </CanView>

                </Grid.Column>
            </Grid.Row>
            {openedCases.find(value => value === item.id) &&
                <CaseDetail data={item} />
            }
        </Fragment>
    ))
}

export default List;