import { APIRequest, URLManager } from './helpers/requests';
import { API } from "../config";


export const benefitsService = {
    getBenefits,
    updateBenefits,
    deleteBenefits,
    createBenefits,
    getCategories,
    createCategories,
    deleteCategories,
    updateCategory,
};


async function getBenefits(params) {
    params = params || ""
    return await APIRequest({
        url: API.BENEFITS + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getCategories(params) {
    params = params || ""
    return await APIRequest({
        url: API.BENEFITS_CATEGORIES + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function updateBenefits(data, id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.BENEFITS, id),
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function createBenefits(data) {
    return await APIRequest({
        url: API.BENEFITS,
        method: 'POST',
        data: data,
        private: true
    });
}

async function createCategories(data) {
    return await APIRequest({
        url: API.BENEFITS_CATEGORIES,
        method: 'POST',
        data: data,
        private: true
    });
}

async function updateCategory(data, id) {
    return await APIRequest({
        url: API.BENEFITS_CATEGORIES + id + "/",
        method: 'PATCH',
        data: data,
        private: true
    });
}

async function deleteBenefits(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.BENEFITS, id),
        method: 'DELETE',
        private: true
    });
}

async function deleteCategories(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.BENEFITS_CATEGORIES, id),
        method: 'DELETE',
        private: true
    });
}