import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
// store
import { companyService } from '@store/services/ServiceCompany';
import { routes } from '@store/routes/index';
// components
import { Grid } from 'semantic-ui-react';
import Loading from '@components/general/Loading';
import TreeGraph from '@components/general/TreeGraph'

const CompanyDetailUnits = (props) => {
    const [isLoading, setIsLoading] = useState(true); 
    const [unitData, setUnitData] = useState({});
    const history = useHistory()

    async function fetchData() {
        setIsLoading(true); 
        const result = await companyService.getTree(props.data.id);

        if (result.status === 403) {
            history.push(routes.DASHBOARD)
            return
        }
        
        if (result.status === 200) {
            setUnitData(result.response);     
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [props.data.id]);

    return ( 
        <Grid centered divided="vertically" style={styles.container}>
            { isLoading ? <Loading /> :
                <Grid.Row style={styles.tree}>
                    {unitData
                        ? <TreeGraph data={unitData} rerender={fetchData} />
                        : <div style={styles.warning}>Company has no child unit yet.</div>
                    }
                </Grid.Row>
            }
        </Grid>
    )
}
const styles = {
    container: { padding: "2rem", paddingTop:"0rem" },
    warning: { fontSize: 28 },
    tree: { justifyContent: "center" }
}

export default CompanyDetailUnits;