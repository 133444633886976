import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
// components
import AvatarIcon from '@components/AvatarIcon';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexTable, FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperField from '@components/forms/SuperField';
import { Divider, Button, Form } from 'semantic-ui-react';
import Action from '@components/general/Action';

const ListSelectedCandidates = ({ type, room, convertToEmployee, launchOnboarding, selectedCandidates, removeSelectedCandidate, bulkActionUp }) => {
    const { t } = useTranslation();

    const [template, setTemplate] = useState({})
    const [deadline, setaDeadline] = useState('')

    return (
        <Form onSubmit={() => type === 'convert' ? convertToEmployee() : type === 'onboarding' ? launchOnboarding(template, deadline) : type === 'stage_up' && bulkActionUp()} >
            <FlexTable>
                <EmptyRow length={selectedCandidates.length} />
                {selectedCandidates.map((candidate, i) => (
                    <FlexRow fontSize="0.9rem" borders background="transparent" key={i}>
                        <FlexItem basis="15%">
                            <AvatarIcon
                                size={35}
                                name={candidate?.fullname}
                                src={candidate?.profile_picture}
                            />
                        </FlexItem>
                        <FlexItem bold>
                            {candidate?.fullname}
                        </FlexItem>
                        <FlexItem basis="20%">
                            {type === 'convert' ?
                                candidate?.profile_type === 2 ?
                                    <strong style={{ color: 'red' }}>{t('already_converted')}</strong>
                                    :
                                    candidate?.current_stage?.id !== room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id ?
                                        <strong style={{ color: 'red' }}>{t('candidate_is_not_in_last_stage')}</strong>
                                        : ''
                                :
                                type === 'onboarding' &&
                                    candidate?.profile_type === 1 ?
                                    <strong style={{ color: 'red' }}>{t('not_converted')}</strong>
                                    :
                                    candidate?.current_stage?.id !== room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id ?
                                        <strong style={{ color: 'red' }}>{t('candidate_is_not_in_last_stage')}</strong>
                                        :
                                        candidate.onboardings.length !== 0 ?
                                            <strong style={{ color: 'red' }}>{t('candidate_already_hired')}</strong>
                                            : ''
                            }
                        </FlexItem>
                        <FlexItem basis="5%" textAlign="center">
                            <Action as='custom' type='icon' icon="delete" tooltip={t('delete')} size='small' onClick={() => removeSelectedCandidate(candidate)}
                                iconColor='var(--danger)' paddingLeft="1rem" paddingRight="1rem"
                            />
                        </FlexItem>
                    </FlexRow>
                ))}
            </FlexTable>
            {
                type === 'onboarding' &&
                <>
                    <Divider />
                    <Form.Group widths={2}>
                        <SuperField as="choice"
                            search
                            required
                            label={t('template')}
                            value={template}
                            endpoint={API.ONBOARDING_CHECKLIST_CONFIG + '?type=1'}
                            onChange={(e, { value }) => setTemplate(value)}
                            text="title"
                        />

                        <SuperField as="datepicker"
                            dateFormat="YYYY-MM-DD"
                            required
                            label={t('deadline')}
                            placeholder={t('deadline')}
                            value={deadline}
                            iconPosition="left"
                            onChange={(e, data) => setaDeadline(data.value)}
                        />
                    </Form.Group>
                </>
            }

            <Form.Field style={{ marginTop: "1.8rem" }}>
                <Button
                    disabled={type === 'onboarding' ? isEmpty(template) || isEmpty(deadline) : selectedCandidates.length === 0}
                    fluid primary
                    content={type === 'convert' ? t('accept_as_employee') : type === 'onboarding' ? t('start_onboarding') : type === 'stage_up' && t('change_stage')}
                />
            </Form.Field>
        </Form>
    );
};

export default ListSelectedCandidates;