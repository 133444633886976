
import React, { useEffect, useState,  } from 'react';
// store
import { userService } from '../../../store/services/ServiceUser';

// components
import { Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const ResponsiblePersonField = ({ assignedList, disabled, value, setValue, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const {t}=useTranslation()

    useEffect( () => {
        async function getChoices(){
            setLoading(true);

                   const resultUser = await userService.getUsers()

                    if (resultUser.status === 200) {

                        const opt= []
                        for (let i = 0; i < resultUser.response.length; i++) {
                            if( !assignedList.filter(item => item.id === resultUser.response[i].id).length > 0 ){
                                opt.push(resultUser.response[i]);
                            }
                        }
                        const optionsArray = Object.entries(opt).map(
                            (item, index) => (
                                { key: index + 1 , text: item[1].name, value: item[1].id }
                            )
                        );

                        setOptions(optionsArray);
                    }

                setLoading(false);
        }

        getChoices();
    }, [assignedList])


    return (
        <Form.Field>
            <Form.Select
                {...rest}
                fluid
                loading={loading}
                disabled={disabled || loading}
                label={t('add_responsible_person')}
                value={value}
                placeholder='-------------'
                options={options}
                onChange={setValue}
            />
        </Form.Field>
    );
};

export default ResponsiblePersonField;
