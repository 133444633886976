import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { routes } from '@store/routes';
import { deleteRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import { Label, Popup, Button } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
// import ContractUploadScannedFile from '../components/ContractUploadScannedFile';
// import RenderTemplateHtmlToPDF from '@components/RenderTemplateHtmlToPDF';
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
// specific components
import ContractSignForm from '../components/ContractSignForm';
import Termination from '../components/Termination';
import ContractForm from '../components/ContractForm'
import Documents from './Documents';

const ContractList = ({ contracts, setContracts }) => {
    const { t } = useTranslation()


    const onAddSign = (id, response) => {
        setContracts(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                results: prevState.data.results.filter(contract => {
                    if (contract.id === id) {
                        contract.is_draft = false
                        contract.date_of_signature = response.date_of_signature
                        contract.is_active = response.is_active
                        contract.is_terminated = response.is_terminated
                        contract.notice_period = response.notice_period
                    }
    
                    return contract
                })
            }
        }))
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.CONTRACTS_INSTANCES + id + "/")
        if(request.status === 204){
            setContracts(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter(item => item.id !== id)
                }
            }))
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('can_not_be_removed'),
                description: t('containing_history_records'),
                animation: 'bounce',
                time: 5000,
            });
        }
    }

    const NoticePeriodAlert = ({ noticePeriod }) => {
        return (
            <Popup
                basic
                inverted
                content={t('notice_period_until') + " " + noticePeriod}
                trigger={
                    <Icon style={{ color: "var(--danger)", marginLeft: "0.5rem" }} name="exclamation circle" />
                }
            />
        )
    }

    return (
        <>
            <FlexTable>
                <FlexRow>
                    <FlexHeader content={t('employee')} />
                    <FlexHeader content={t('contract_type')} />
                    <FlexHeader content={t('starts')} />
                    <FlexHeader content={t('ends')} />
                    <FlexHeader content={t('status')} />
                    <FlexHeader content={t('documents')} />
                    <CanView permissions={['contracts.c_manage_all_contracts']}>
                        <FlexHeader content={t('actions')} />
                    </CanView>
                </FlexRow>
                {contracts?.length === 0 &&
                    <FlexRow>
                        <FlexItem bold textAlign="center">
                            {t('no_records')}
                        </FlexItem>
                    </FlexRow>
                }
                {contracts?.map(contract =>
                    <FlexRow key={contract.id} fontSize="1rem" borders>
                        <FlexItem>
                            <Link to={routes.EMPLYOEES_DETAIL + contract?.employee?.id} target="_blank">
                                <strong>{contract?.employee?.fullname}</strong>
                            </Link> <br />
                            <small>{contract?.contract_data?.work_position?.title}</small>
                        </FlexItem>
                        <FlexItem bold> {contract?.contract_data?.type?.title} </FlexItem>
                        <FlexItem>{contract?.contract_data?.valid_from}</FlexItem>
                        <FlexItem>{contract?.contract_data?.valid_until ? contract?.contract_data?.valid_until : t('indefinite_period')}</FlexItem>
                        <FlexItem>
                            {contract?.is_draft // is draft, nothing can be done with this Contract
                                ? <Label basic>{t('draft')}</Label>
                                : contract?.is_active && !contract?.is_terminated // Is active but not terminated
                                    ? <Label style={{ background: "var(--success)", color: "var(--white)" }}>{t('active')}</Label>
                                    : contract?.is_active && contract?.is_terminated && contract?.notice_period !== null && moment(contract?.notice_period).isAfter(moment().format("YYYY-MM-DD"))
                                        ? <><Label style={{ background: "var(--success)", color: "var(--white)", textAlign: "center" }}>{t('active')} </Label><NoticePeriodAlert noticePeriod={moment(contract?.notice_period).format("DD.MM.YYYY")} /></>
                                        : <Label style={{ background: "var(--danger)", color: "var(--white)", textAlign: "center" }}>{t('terminated')} </Label>
                            }
                        </FlexItem>
                        <FlexItem>
                            <SuperDuperModal
                                size="small"
                                trigger={<Button size="tiny" basic content={t('manage_documents')} />}
                                content={<Documents contract={contract} setContracts={setContracts}/>}
                            />
                            {/* {contract?.template || contract?.external_file
                                ?
                                <>
                                    {contract?.external_file &&
                                        // eslint-disable-next-line
                                        <a href={contract?.external_file} target="_blank" rel="noopener noreferrer">
                                            <Icon style={{ cursor: "pointer", color: "var(--danger)" }} size="large" name="file pdf" />
                                        </a>
                                    }

                                    {contract?.template &&
                                        // <PDFDownloadLink document={<PDFTemplate htmlContent={<p>Test</p>}/>} fileName="somename.pdf">
                                        <SuperDuperModal
                                            size="small"
                                            trigger={
                                                <Icon style={{ cursor: "pointer", color: "var(--danger)" }} size="large" name="file pdf" />
                                            }
                                            content={
                                                <RenderTemplateHtmlToPDF
                                                    documentId={contract?.template?.id}
                                                    objId={contract.id}
                                                    objInstance="contracts.ContractInstance"
                                                />
                                            }
                                        />
                                        // </PDFDownloadLink>
                                    }
                                </>
                                :
                                <CanView permissions={['contracts.c_manage_all_contracts']}>
                                    <SuperDuperModal
                                        trigger={<Button size="tiny" basic content={t('add_document')} />}
                                        content={<ContractUploadScannedFile setContracts={setContracts} contractId={contract.id} />}
                                    />
                                </CanView>
                            } */}
                        </FlexItem>
                        <CanView permissions={['contracts.c_manage_all_contracts']}>
                            <FlexItem>
                                <FlexRow background="transparent" padding="0" fontSize="1rem">
                                    {/* <FlexItem>
                                    <Popup
                                        inverted
                                        content='Upload addition files'
                                        trigger={
                                            <Icon size="tiny" name="cloud upload" style={{ cursor: "pointer", color: "var(--primary)" }}/>
                                        }
                                    />
                                </FlexItem> */}
                                    <FlexItem textAlign="center">
                                        <Popup
                                            basic
                                            inverted
                                            content={contract?.date_of_signature ? t('update_date_of_signature') : t('add_date_of_signature')}
                                            trigger={
                                                <div>
                                                    <SuperDuperModal
                                                        closeIcon
                                                        header={contract?.date_of_signature ? t('update_date_of_signature') : t('add_date_of_signature')}
                                                        content={<ContractSignForm contractId={contract.id} dateOfSignature={contract?.date_of_signature} onAddSign={onAddSign} />}
                                                        trigger={
                                                            <Icon name="create-outline" style={{ cursor: "pointer", color: "var(--blue)" }} />
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </FlexItem>
                                    <FlexItem textAlign="center">
                                        <Action as="modal" type="icon" icon="pencil-outline" size="small" tooltip={t('edit')}
                                            modal={
                                                <ContractForm
                                                    contract={contract}
                                                    setContracts={setContracts}
                                                />
                                            }
                                        />
                                    </FlexItem>
                                    {/* <FlexItem textAlign="center">
                                    <Popup
                                        basic
                                        inverted
                                        content={ contract.is_archived ? t('set_to_active') : t('set_to_archived') }
                                        trigger={
                                            <Icon
                                                name={ contract.is_archived ? "check circle outline" : "ban" }
                                                onClick={ () => onArchived(contract.id, !contract.is_archived) }
                                                style={{ cursor: "pointer", color: contract.is_archived ? "var(--success)" : "var(--danger)" }}
                                            />
                                        }
                                    />
                                </FlexItem> */}
                                    <FlexItem textAlign="center">
                                        {(contract?.is_active && !contract?.is_terminated) &&
                                            <Popup
                                                basic
                                                inverted
                                                content={t('termination') + ": " + contract?.employee?.fullname}
                                                trigger={
                                                    <div>
                                                        <SuperDuperModal
                                                            header={t('termination') + ": " + contract?.employee?.fullname}
                                                            content={
                                                                <Termination
                                                                    contract={contract}
                                                                    setContracts={setContracts}
                                                                />
                                                            }
                                                            trigger={
                                                                <Icon
                                                                    name="close-circle-outline"
                                                                    style={{ cursor: "pointer", color: "var(--danger)" }}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                }
                                            />
                                        }
                                    </FlexItem>
                                    <CanView permissions={["contracts.c_delete_all_contracts"]}>
                                        <FlexItem textAlign="center">
                                            <Action as='delete' tooltip={t('delete')} text={t('delete_contract_message')} onClick={() => onDelete(contract.id)} />
                                        </FlexItem>
                                    </CanView>
                                </FlexRow>
                            </FlexItem>
                        </CanView>
                    </FlexRow>
                )}
            </FlexTable>

        </>
    );
};

export default ContractList;