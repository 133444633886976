import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { useLocation, useHistory } from 'react-router-dom';
//components
import SuperField from '@components/forms/SuperField';
import CustomFormDivider from '@components/general/CustomFormDivider';
import { Divider, Button, Form, List, Header  } from 'semantic-ui-react';


const ProjectListFiltersForm = ({ fetchData, setVisible, initState, filters, setFilters }) => {

    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()
    const [processing, setProcessing] = useState(false)

    const onFilter = async () => {
        setProcessing(true)
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchData('', true)
        setProcessing(false)
    }

    const resetFilters = () => {
        setFilters(initState)
    }


    return (
        <div>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('advanced_filter')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>
            <Form style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                <SuperField as="choice"
                    search
                    text="fullname"
                    label={t('project_member')}
                    value={filters.projectMember}
                    onChange={ (e, {value}) => setFilters({ ...filters, projectMember: value }) }
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                />
                <SuperField as="choice"
                    search
                    text="fullname"
                    label={t('project_manager')}
                    value={filters.projectManager}
                    onChange={ (e, {value}) => setFilters({ ...filters, projectManager: value }) }
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                />
                <Divider/>

                <Form.Group widths="equal">
                    {/* <SuperField as="checkbox"
                        label={t('is_approved')}
                        width={10}
                        checked={filters.isApproved}
                        onChange={() => setFilters({...filters, isApproved: !filters.isApproved})}
                    /> */}
                    <SuperField as="checkbox"
                        label={t('is_active')}
                        width={10}
                        checked={filters.isActive}
                        onChange={() => setFilters({...filters, isActive: !filters.isActive})}
                    />
                </Form.Group>

                <SuperField as="choice"
                    search
                    multiple
                    value={filters.labels}
                    label={t('labels')}
                    endpoint={API.PROJECT_LABELS}
                    text="name"
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        labels: value
                    })}
                />

                <CustomFormDivider
                    header = {t('date_filters')}
                    divider = {true}
                    content = {
                        <Form.Group widths="3">
                            <SuperField as="datepicker"
                                label={ t('start_date') }
                                value={filters.fromDate}
                                onChange={ (e, { value }) => setFilters({...filters, fromDate: value})}
                                />
                            <SuperField as="datepicker"
                                label={ t('due_date') }
                                value={filters.dueDate}
                                onChange={ (e, { value }) => setFilters({...filters, dueDate: value})}
                            />
                            <SuperField as="datepicker"
                                label={ t('end_date') }
                                value={filters.endDate}
                                onChange={ (e, { value }) => setFilters({...filters, endDate: value})}
                            />
                        </Form.Group>
                    }
                />

                <Form.Field style={{ textAlign: "right"}}>
                    <Button size="medium" content={t('reset')} style={{}} onClick={() => resetFilters()}/>
                    <Button loading={processing} disabled={processing} size="medium" content={t('apply')}
                        style={{backgroundColor: "var(--primary)", color:"white"}}
                        onClick={() => {
                            onFilter()
                            setVisible(false)}
                    }/>
                </Form.Field>
            </Form>
        </div>
    );
};

export default ProjectListFiltersForm;