import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { setDotSeparator } from '@helpers/functions';
import { isEmpty } from "@store/services/helpers/validation";
// eslint-disable-next-line
import { createRequest, patchRequest } from '@services/ServiceCommon';
// components 
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';
import { Form, Divider, Button, Accordion } from 'semantic-ui-react';
import AccordionItem from '@components/AccordionItem';

const HROrderForm = ({ order, setResult, onClose }) => {
    const { t } = useTranslation()
    const [showAdditionalInformation, setShowAdditionalInformation] = useState(order !== undefined ? true : false)
    const [showPaymentTerms, setShowPaymentTerms] = useState(order !== undefined ? true : false)
    const [isProcessing, setIsProcessing] = useState(false)
    const [form, setForm] = useState({
        name: order !== undefined ? order.name : "",
        orderNumber: order !== undefined ? order.order_number : "",
        amount: order !== undefined ? order.amount : 0,
        fee: order !== undefined ? order?.fee?.sum ? order?.fee?.sum : 0 : 0,
        currency: order !== undefined ? order?.fee?.currency ? order?.fee?.currency : "EUR" : "EUR",
        service: order !== undefined ? order.service : "",
        services: order !== undefined ? order.ordered_services : "",
        paymentMethod: order !== undefined ? order.payment_option : "",
        orderManager: order !== undefined ? order.order_manager?.id : "",
        teams: order !== undefined ? order.assigned_teams.map(item => item.id) : [],
        assignedEmployees: order !== undefined ? order.assigned_to.map(item => item.id) : [],
        tenant: order !== undefined ? order.tenant?.id : "",
        account: order !== undefined ? order.account?.id : "",
        start_date: order !== undefined ? order.start_date : "",
        end_date: order !== undefined ? order.end_date : "",
    })

    console.log(form)

    const handleSubmit = async () => {
        setIsProcessing(true)

        const orderDataset = {
            name: form.name,
            order_number: form.orderNumber,
            amount: form.amount,
            fee: {
                sum: form.fee,
                currency: form.currency
            },
            tenant: form.tenant,
            order_manager: form.orderManager,
            payment_option: form.paymentMethod !== "" ? form.paymentMethod : null,
            start_date: form.start_date !== "" ? form.start_date : null,
            end_date: form.end_date !== "" ? form.end_date : null,
            account: form.account,
            ordered_services: form.services,
            service: form.service,
            assigned_teams: {
                remove: order?.assigned_teams ? order.assigned_teams.map(item => item.id): [],
                add: form.teams
            },
            assigned_to: {
                remove: order?.assigned_to ? order.assigned_to.map(item => item.id): [],
                add: form.assignedEmployees
            },
            resourcetype: "HROrder",
            order_type: 2
        }

        let request = undefined

        if( order === undefined ){
            delete orderDataset.assigned_teams.remove
            delete orderDataset.assigned_to.remove
            request = await createRequest(API.ORDERS, orderDataset);
        } else {
            request = await patchRequest(API.ORDERS + order.id + "/", orderDataset);
        }
        
        if( request !== undefined ){
            if (request.status === 201 || request.status === 200) {

                if( request.status === 201 ){ // created
                    setResult(prevState => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            count: prevState.data.count + 1,
                            results: [request.response, ...prevState.data.results]
                        }
                    }))
                } else if( request.status === 200 ){ // updated
                    setResult(prevState => ({
                        ...prevState,
                        data: {
                            ...prevState.data,
                            results: prevState.data.results.filter(item => {
                                if( item.id === request.response.id ){
                                    item.name = request.response.name
                                    item.account = request.response.account
                                    item.amount = request.response.amount
                                    item.fee = request.response.fee
                                    item.currency = request.response.currency
                                    item.order_manager = request.response.order_manager
                                    item.order_number = request.response.order_number
                                    item.start_date = request.response.start_date
                                    item.end_date = request.response.end_date
                                    item.ordered_services = request.response.ordered_services
                                    item.ordered_services_display = request.response.ordered_services_display
                                    item.service = request.response.service
                                    item.service_display = request.response.service_display
                                    item.payment_option = request.response.payment_option
                                    item.payment_option_display = request.response.payment_option_display
                                    item.order_status = request.response.order_status
                                    item.order_status_display = request.response.order_status_display
                                }

                                return item
                            })
                        }
                    }))
                }
                
                onClose()
            } 
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
                <SuperField as="input"
                    required
                    autoFocus
                    label={t('name')}
                    value={form.name}
                    onChange={(e, { value }) => setForm({ ...form, name: value })}
                />
                <SuperField as="input"
                    required
                    label={t('order_number')}
                    value={form.orderNumber}
                    onChange={(e, { value }) => setForm({ ...form, orderNumber: value })}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField
                    as='choice'
                    required
                    search
                    type="order_services_choices"
                    label={t('service')}
                    value={form.services?.toString()}
                    onChange={(e, { value }) => setForm({ ...form, services: value })}
                />
                <SuperField
                    as="choice"
                    required
                    type="service_choices"
                    label={t('type')}
                    value={form.service?.toString()}
                    onChange={(e, { value }) => setForm({ ...form, service: value })}
                />
            </Form.Group>

            <Form.Group widths='equal'>
                <SuperField
                    // search
                    as="choice"
                    endpoint={API.ACCOUNTS + "?query={id, name}&is_active=true&is_supplier=false"}
                    text="name"
                    label={t('account')}
                    value={form.account}
                    onChange={(e, { value }) => setForm({ ...form, account: value })}
                />
                <SuperField as="input"
                    type="number"
                    min="0"
                    label={t('amount')}
                    value={form.amount}
                    onChange={(e, { value }) => setForm({ ...form, amount: value })}
                />
            </Form.Group>

            <Divider/>
            <Accordion as={Form.Field} fluid style={{ borderRadius: 0 }}>
                <AccordionItem 
                    index={0}
                    isActive={showPaymentTerms}
                    onClick={ () => setShowPaymentTerms(!showPaymentTerms) }
                    title={t('payment_conditions')}
                >
                    <Form.Group widths='equal'>
                        <SuperField
                            as="choice"
                            type="payment_option_choices"
                            label={t('payment_option')}
                            value={form.paymentMethod?.toString()}
                            onChange={(e, { value }) => setForm({ ...form, paymentMethod: value })}
                        />
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <SuperField as="input"
                            label={t('fee')}
                            value={form.fee}
                            onChange={(e, { value }) => setForm({ ...form, fee: setDotSeparator(value) })}
                        />
                        <SuperField as="choice"
                            search
                            type="currency_codes"
                            label={t('currency')}
                            value={form.currency}
                            onChange={(e, { value }) => setForm({ ...form, currency: value })}
                        />
                    </Form.Group>
                </AccordionItem>
            </Accordion>
            

            <Divider/>
            <Accordion as={Form.Field} fluid style={{ borderRadius: 0 }}>
                <AccordionItem 
                    index={0}
                    isActive={showAdditionalInformation}
                    onClick={ () => setShowAdditionalInformation(!showAdditionalInformation) }
                    title={t('additional_information')}
                >
                    <Form.Group widths='equal'>
                        <SuperField as="datepicker"
                            label={t('start_date')}
                            value={form.start_date}
                            onChange={(e, { value }) => setForm({ ...form, start_date: value })}
                        />
                        <SuperField as="datepicker"
                            label={t('end_date')}
                            value={form.end_date}
                            onChange={(e, { value }) => setForm({ ...form, end_date: value })}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <CanView permissions={["orders.c_assign_order_manager"]}>
                            <SuperField
                                as="choice"
                                search
                                endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                                text="fullname"
                                label={t('order_manager')}
                                value={form.orderManager}
                                onChange={(e, { value }) => setForm({ ...form, orderManager: value })}
                            />
                        </CanView>
                        {/* <SuperField
                            as="choice"
                            endpoint={API.ACCOUNTS + "?query={id, name}&is_active=true"}
                            text="name"
                            label="Tenant"
                            value={form.tenant}
                            onChange={(e, { value }) => setForm({ ...form, tenant: value })}
                        /> */}
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <SuperField
                            as="choice"
                            multiple
                            search
                            endpoint={API.TEAMS + "?only_basic_info=true"}
                            text="name"
                            label={t('teams')}
                            value={form.teams}
                            onChange={(e, { value }) => setForm({ ...form, teams: value })}
                        />
                        <SuperField
                            as="choice"
                            multiple
                            search
                            endpoint={API.EMPLOYEES + "?only_basic_info=true&is_active=true"}
                            text="fullname"
                            label={t('assigned_employees')}
                            value={form.assignedEmployees}
                            onChange={(e, { value }) => setForm({ ...form, assignedEmployees: value })}
                        />
                    </Form.Group>
                </AccordionItem>
            </Accordion>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    type="button"
                    style={{ backgroundColor: "var(--variant5)" }}
                    onClick={onClose}
                    disabled={isProcessing}
                >
                    {t('cancel')}
                </Button>
                <Button
                    primary
                    style={{ backgroundColor: "var(--success)" }}
                    disabled={
                        isProcessing ||
                        isEmpty(form.name) ||
                        isEmpty(form.orderNumber) ||
                        isEmpty(form.service) ||
                        isEmpty(form.services)
                    }
                    loading={isProcessing}
                >
                    {t('save')}
                </Button>
            </Form.Field>
        </Form>
    );
};

export default HROrderForm;