import React, { Fragment } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
// routes
import { routes } from '@routes/index';
// components
import Icon from '@components/Icon';
import { Breadcrumb } from 'semantic-ui-react';


const BreadcrumbNav = ({ items, actions }) => {
    const { t } = useTranslation()

    return (
        <div className="breadcrumb" style={{ padding: "0.8rem", marginBottom: "1rem", background: "transparent", boxShadow: "none" }}>
            <Breadcrumb size="large" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>
                    <Breadcrumb.Section as={Link} to={routes.DASHBOARD}>
                        <Icon name="home-outline" style={{ fontSize: "1.5rem", paddingLeft: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.3rem" }}>{ t('dashboard') }</span>
                    </Breadcrumb.Section>
                    <Breadcrumb.Divider icon={<Icon name='chevron-forward-outline' style={{ position: "relative", top: "-0.1rem" }} />} />

                    {items &&
                        items.map((item, i) =>
                            <Fragment key={i}>
                                <Breadcrumb.Section key={i}>
                                    {item.href
                                        ? <Link to={item.href}><span style={{ position: "relative", top: "-0.3rem" }}> { item.name }</span></Link>
                                        : <span style={{ position: "relative", top: "-0.3rem" }}>{ item.name }</span>}
                                </Breadcrumb.Section>

                                {items.length !== i + 1 && <Breadcrumb.Divider icon={<Icon name='chevron-forward-outline' style={{ position: "relative", top: "-0.1rem" }}/>} />}

                            </Fragment>
                        )
                    }
                </div>

                <div>
                    {actions && actions.map((action, index) => <Fragment key={index}>{action.component}</Fragment>)}
                </div>
            </Breadcrumb>
        </div>
    );
};

export default BreadcrumbNav;