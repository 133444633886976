import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
// store
import { routes } from '@store/routes';
import { icons } from '@store/icons';
// components
import { Container } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
import CandidatesByStage from '../components/stats/hr/CandidatesByStage';
import CandidateRejections from '../components/stats/hr/CandidateRejections';
import PublicationSources from '../components/stats/hr/PublicationSources';
import CandidateStageTime from '../components/stats/hr/CandidateStageTime'
import RecruiterStats from '../components/stats/hr/RecruiterStats';
import CandidatesInteractions from '../components/stats/hr/CandidatesInteractions';
import PublishedByRecruiter from '../components/stats/hr/PublishedByRecruiter';
import Fluctuations from '../components/stats/hr/Fluctuations';
import CallReports from '../components/stats/ops/call-reports/CallReports';
import EngagementTerminationTables from '../components/stats/hr/engagement-terminations/EngagementTerminationTables';

function queryParams(location) {
    return new URLSearchParams(location.search)
}

const ReportView = () => {
    const params = useParams();
    const location = useLocation();
    const query = queryParams(location)

    const breadcrumb_items = [
        { 'name': "Reports", 'icon': icons.REPORTS, 'href': routes.REPORTS },
        { 'name': params.slug, 'icon': icons.REPORTS, 'href': '' }
    ]

    return (
        <>
            <BreadcrumbNav items={breadcrumb_items} />
            <Container fluid>
                { params.slug === "candidate-stage-time" && <CandidateStageTime /> }
                { params.slug === "candidates-by-stage" && <CandidatesByStage room={query.get("room")} /> }
                { params.slug === "candidate-rejections" && <CandidateRejections room={query.get("room")} /> }
                { params.slug === "recruiter-stats" && <RecruiterStats recruiter={query.get("recruiter")} /> }
                { params.slug === "publications-sources" && <PublicationSources room={query.get("room")} /> }
                { params.slug === "candidates-interactions" && <CandidatesInteractions  /> }
                { params.slug === "published-by-recruiter" && <PublishedByRecruiter /> }
                { params.slug === "fluctuations" && <Fluctuations /> }
                { params.slug === "engagement-termination-tables" && <EngagementTerminationTables /> }
                { params.slug === "call-reports" && <CallReports /> }
            </Container>
        </>
    );
};

export default ReportView;