import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
// components
import Icon from '@components/Icon';
import ActionArea from './ActionArea';
import IncidentList from './IncidentList';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import AddCaseContent from '../components/AddCaseContent';
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Button } from 'semantic-ui-react';

import { getRequest } from '@services/ServiceCommon';
import { API } from '@store/config';
import { icons } from '@store/icons';
import { toOptionsFromRedux } from '@store/services/helpers/utils';

const Cases = () => {
    const { t } = useTranslation();
    const priorities = useSelector((state) => state.choices.priority_choices)
    const [filters, setFilters] = useState({
        status: '',
        priority: '',
        name: '',
        type: '',
        label: []
    })
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useState({
        title: '',
        description: '',
        priority: '',
        type: '',
        label: [],
        assigned_users: [],
        assigned_teams: [],
        end_date: '',
    })
    const breadcrumb_items = [
        { 'name': t("cases"), 'icon': icons.CASES, 'href': '' }
    ]
    useEffect(() => {
        async function fetch() {
            setLoading(true)
            let endpoint = `${API.CASES}`
            let request = await getRequest(endpoint)
            if (request.status === 200) {
                setData(request.response)
            }
            setLoading(false)
        }
        fetch()
    }, [])
    async function handleFilters(e) {
        let endpoint = (e.status !== '' || e.priority !== '' || e.type !== '' || e.label.length > 0 || e.name !== '') ? `${API.CASES}?` : `${API.CASES}`
        if (e.name !== '') endpoint += `search=${e.name}&`
        if (e.type !== '') endpoint += `type=${e.type}&`
        if (e.status !== '') endpoint += `status=${e.status}&`
        if (e.priority !== '') endpoint += `priority=${e.priority}&`
        if (e.label.length > 0) {
            for (let item of e.label) {
                endpoint += `label=${item}&`
            }
        }
        setLoading(true)
        let request = await getRequest(endpoint)
        if (request.status === 200) {
            setData(request.response)
        }
        setFilters(e)
        setLoading(false)
    }
    function handleUpdate(e) {
        setData(data.map(item => {
            if (item.id === e.id) return e
            return item
        }))
    }

    const actions = [{
        component:
            <SuperDuperModal
                header={t("filter_cases")}
                content={
                    <ActionArea
                        data_filters={filters}
                        loading={loading}
                        handleRequest={(e) => setData(data.concat([e]))}
                        handleFilters={(e) => handleFilters(e)} />
                }
                trigger={
                    <Button
                        primary
                        style={{ 
                            background: "transparent",

                        }}
                    >
                        <Icon name='funnel-outline' style={{ margin: 0 }} />
                    </Button>
                }
            />
    },
    {
        component:
            <CanView permissions={["cases.c_manage_all_cases"]}>
                <SuperDuperModal
                    header={t("create_case")}
                    content={
                        <AddCaseContent
                            priorities={toOptionsFromRedux(priorities)}
                            data={form}
                            handleChange={(e) => setForm(e)}
                            handleRequest={(e) => setData(data.concat([e]))} />
                    }
                    trigger={<OutlineAddButton text={t("add_case")} />}
                />
            </CanView>
    }]

    return (
        <CanView permissions={["cases.c_view_all_cases", "cases.c_view_assigned_user_cases"]} redirect>
            <BreadcrumbNav items={breadcrumb_items} actions={actions} style={{ marginBottom: 0 }} />
            <IncidentList
                data={data}
                loading={loading}
                handleDelete={(id) => setData(data.filter(item => item.id !== id))}
                handleUpdate={(e) => handleUpdate(e)}
            />
        </CanView>
    );
};

export default Cases;