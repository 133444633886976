import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
// store
import { isEmpty } from "@helpers/validation"
import { companyService } from '@services/ServiceCompany';
// components
import { Checkbox, Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ChoiceField from '@components/forms/common/ChoiceField'

const BankAccountForm = ({ bank, onConfirm, onClose, btnName }) => {
    const { t } = useTranslation();

    const [bankId] = useState(bank ? bank.id : 0);

    // form formdata
    const [name, setName] = useState(bank ? bank.name : "");
    const [iban, setIban] = useState(bank ? bank.iban : "");
    const [swift, setSwift] = useState(bank ? bank.swift_code : "");
    const [alias, setAlias] = useState(bank ? bank.alias : "");
    const [detail, setDetail] = useState(bank ? bank.bank_detail : "");
    const [currency, setCurrency] = useState(bank ? bank.currency.toString() : "EUR");
    const [isDefault, setIsDefault] = useState(bank ? bank.default : false);

    // form states
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errorList, setErrorList] = useState([]); // empty array

    const handleSubmit = async () => {
        setIsProcessing(true);
        setErrorList([]);

        const data = {
            name: name,
            iban: iban,
            swift_code: swift,
            alias: alias,
            currency: currency,
            bank_detail: detail,
            default: isDefault
        }

        if (bankId === 0) {
            const result = await companyService.createBank(data);
            if (result.status === 201) {
                onConfirm(result.response);
                onClose()
            } else {
                const error_message = result.response.name;
                setErrorList([...errorList, error_message[0]]);
            }
        } else {
            const result = await companyService.updateBank(bankId, data);
            if (result.status === 200) {
                onConfirm(result.response)
                onClose()
            } else {
                const error_message = result.response.name;
                setErrorList([...errorList, error_message[0]]);
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            {errorList.length > 0 &&
                (<Message negative>
                    {errorList.map((error, index) =>
                        <p key={index}> {error} </p>
                    )}
                </Message>)
            }

            <Form.Group widths={2}>
                <Form.Field>
                    <Form.Input
                        autoFocus
                        label={t('name')}
                        placeholder={t('enter_bank_name')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        required
                        label='IBAN'
                        placeholder={t('enter_iban')}
                        value={iban}
                        onChange={(e) => setIban(e.target.value)}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Field>
                    <Form.Input
                        label='Swift'
                        placeholder={t('enter_swift')}
                        value={swift}
                        onChange={(e) => setSwift(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label='Detail'
                        placeholder={t('enter_bank_details')}
                        value={detail}
                        onChange={(e) => setDetail(e.target.value)}
                    />
                </Form.Field>
            </Form.Group>


            <Form.Group widths={2}>
                <Form.Field>
                    <Form.Input
                        label='Alias'
                        placeholder={t('enter_bank_alias')}
                        value={alias}
                        onChange={(e) => setAlias(e.target.value)}
                    />
                </Form.Field>
                <ChoiceField
                    fluid
                    search
                    type="currency_codes"
                    label={t('currency')}
                    value={currency}
                    onChange={(e, data) => setCurrency(data.value)}
                />
            </Form.Group>

            <Checkbox
                label={t('set_as_default')}
                checked={isDefault}
                onChange={() => setIsDefault(!isDefault)}
            />

            <Form.Field style={{ textAlign: "right", margin: " 1rem" }}>
                <ModalCancel
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(iban)}
                    text={btnName}
                />
            </Form.Field>
        </Form>
    )
}
export default BankAccountForm;
