import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// store
import { API } from '@store/config';
import { useFetchData, useQueryPage } from '@helpers/hooks';
import { requests } from '@helpers/requests';
// components
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';
import Filters from '../components/history/Filters';
import HistoryTable from '../components/history/HistoryTable';

const AssetsHistoryView = () => {
    let page = useQueryPage()
    const location = useLocation()
    const query = "?query={created_on,created_by,note,id,quantity,operation_display,updated_on,stored_item{item{title},warehouse{title}}}"

    const [limit, setLimit] = useState(10)
    const [assetsHistory, setAssetsHistory] = useFetchData(API.ASSET_HISTORY + query + "&paginate=true&page=" + page + "&limit=" + limit)

    const initFilterValues = {
        operation: "",
        storedItem: ""
    }

    const [filterValues, setFilterValues] = useState(initFilterValues)

    const onFilter = async (params, isFiltered) => {
        setAssetsHistory((prev) => ({ ...prev, isLoading: true }))

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        } 

        if( filterValues.operation ) params += "&operation=" + filterValues.operation
        if( filterValues.storedItem ) params += "&stored_item=" + filterValues.storedItem

        const request = await requests.get(API.ASSET_HISTORY + query + "&paginate=true" + params)
        if (request.status === 200) {
            setAssetsHistory({
                isLoading: false,
                data: request.response,
            })
        } else {
            setAssetsHistory((prev) => ({ ...prev, isLoading: false }))
        }
    }

    return (
        <CanView permissions={['assets.c_view_item_history']} redirect>
            <Filters 
                filterValues={filterValues}
                assetsHistory={assetsHistory}
                onFilter={onFilter}
                setFilterValues={setFilterValues}
            />
            <HistoryTable assetsHistory={assetsHistory}/>
            <Paginator
                forcePage={page || 1}
                limit={limit}
                setLimit={setLimit}
                length={assetsHistory?.data?.count || 0}
                onChange={(params) => onFilter(params)}
            />
        </CanView>
    );
};

export default AssetsHistoryView;