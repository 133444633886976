import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
// store
// components
import { Form, Button, Menu, Sidebar, Divider, List, Header } from 'semantic-ui-react';
import DatePresets from '@components/DatePresets';
import SuperField from '@components/forms/SuperField';

const InvoicesFilter = ({ fetchInvoices, filters, choices, isLoading, setSelectedInvoices, setCheckedAll, setFilters, visible, setVisible, segmentRef }) => {
    const { t } = useTranslation();
    const location = useLocation()
    const history = useHistory()

    const populateOptions = (type) => {
        const options = choices?.[type] !== undefined ? Object.entries(choices?.[type]).map((item, index) => (
            { key: index + 1, text: item[1], value: item[0] }
        )) : [];

        return options
    }

    const handleSubmit = async () => {
        setSelectedInvoices([])
        setCheckedAll(false)
        // window.history.pushState('data', "HRIIS", location.pathname + "?page=1")
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchInvoices("", true)
    }

    return (
        <Sidebar  
            direction="right"
            style={{ width: "40rem" }}
            as={Menu}
            animation='push'
            icon='labeled'
            vertical
            visible={visible}
            onHide={() => setVisible(false)}
            target={segmentRef}
        >
            <Form onSubmit={() => handleSubmit()} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                <List divided verticalAlign='middle'>
                    <List.Item>
                        <List.Content>
                            <Header as="h2" content={ t('filters') } style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }}/>
                        </List.Content>
                    </List.Item>
                </List>
                
                
                <Divider/>
                { !isLoading && 
                    <>
                        <Form.Group widths="equal">
                            <SuperField as="datepicker"
                                clearable
                                label={ t('date_from') }
                                value={ filters.date_from } 
                                onChange={ (e, { value }) => { 
                                    if( value === "" ) {
                                        setFilters({ ...filters, date_from: null })
                                    } else {
                                        setFilters({ ...filters, date_from: value })
                                    }

                                }}
                            />
                            <SuperField as="datepicker"
                                clearable
                                label={ t('date_to') }
                                value={ filters.date_to } 
                                onChange={ (e, { value }) => setFilters({ ...filters, date_to: value })}
                            />
                        </Form.Group>
                        <DatePresets from={filters.date_from} to={filters.date_to} onSelection={ (firstDay, lastDay) => setFilters(prev => ({
                            ...prev, 
                            date_from: firstDay,
                            date_to: lastDay
                        }))}/>

                        <p style={{ fontWeight: 'bold', fontSize: "1.2rem" }}>{ t('select_date_to_be_used_in_filter') }:</p>
                        <Form.Group widths="equal">
                            <SuperField as="radio"
                                label={t('date_issue')}
                                checked={ filters.date_type === 1 }
                                onChange={ () => setFilters({ ...filters, date_type: 1 }) }
                            />
                            <SuperField as="radio"
                                label={t('date_supply')}
                                checked={ filters.date_type === 2 }
                                onChange={ () => setFilters({ ...filters, date_type: 2 }) }
                            />
                            <SuperField as="radio"
                                label={t('date_due')}
                                checked={ filters.date_type === 3 }
                                onChange={ () => setFilters({ ...filters, date_type: 3 }) }
                            />
                            <SuperField as="radio"
                                label={t('approval_date')}
                                checked={ filters.date_type === 4 }
                                onChange={ () => setFilters({ ...filters, date_type: 4 }) }
                            />
                        </Form.Group>

                        <Divider/>
                        <Form.Group widths="equal">
                            <SuperField as="input"
                                label={ t('invoice_number') }
                                value={ filters.number }
                                onChange={ (e, { value }) => setFilters({...filters, number: value}) }
                            />
                            <SuperField as="choice"
                                disabled
                                label={ t('type') }
                                customOptions={ populateOptions('invoice_type_choices') }
                                value={ filters.invoice_type }
                                onChange={ (e, { value }) => setFilters({...filters, invoice_type: value}) }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField as="input"
                                label={ t('name') }
                                value={ filters.name }
                                onChange={ (e, { value }) => setFilters({...filters, name: value}) }
                            />
                            <SuperField as="input"
                                label={ t('city') }
                                value={ filters.city }
                                onChange={ (e, { value }) => setFilters({...filters, city: value}) }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                label={ t('country') }
                                type="countries"
                                search
                                value={ filters.country }
                                onChange={ (e, { value }) => setFilters({...filters, country: value}) }
                            />
                            <SuperField as="input"
                                label={ t('registration_id') }
                                value={ filters.registration_id }
                                onChange={ (e, { value }) => setFilters({...filters, registration_id: value}) }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField as="input"
                                label={ t('tax_id') }
                                value={ filters.tax_id }
                                onChange={ (e, { value }) => setFilters({...filters, tax_id: value}) }
                            />
                            <SuperField as="input"
                                label={ t('vat_id') }
                                value={ filters.vat_id }
                                onChange={ (e, { value }) => setFilters({...filters, vat_id: value}) }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                label={ t('split_ratio_status') }
                                value={ filters.split_ratio }
                                customOptions={[
                                    { key: 1, value: "&split_ratio=100", text: t('not_splitted') },
                                    { key: 2, value: "&split_ratio_from=1&split_ratio_to=99", text: t('partially_splitted') },
                                    { key: 3, value: "&split_ratio=0", text: t('fully_splitted') },
                                ]}
                                onChange={ (e, { value }) => setFilters({...filters, split_ratio: value}) }
                            />
                            <SuperField as="choice"
                                label={ t('status') }
                                customOptions={ [
                                    { key: 1, value: "all", text: t('all_invoices') },
                                    { key: 2, value: "4", text: t('drafts') },
                                    { key: 3, value: "1", text: t('outstanding') },
                                    { key: 4, value: "1&date_due_before=" + moment().add("-1", 'days').format("YYYY-MM-DD") , text: t('past_due') },
                                    { key: 5, value: "3", text: t('paid') },
                                ] }
                                value={ filters.status }
                                onChange={ (e, { value }) => setFilters({...filters, status: value}) }
                            />
                        </Form.Group>
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                label={ t('approval_status') }
                                customOptions={ [
                                    { key: 1, value: 1, text: t('approved') },
                                    { key: 2, value: 2, text: t('awaiting_approval') },
                                    { key: 3, value: 3, text: t('denied') },
                                ] }
                                value={ filters.approval }
                                onChange={ (e, { value }) => setFilters({...filters, approval: value}) }
                            />
                            <SuperField as="choice"
                                label={ t('payment_verify_status') }
                                customOptions={ [
                                    { key: 1, value: true, text: t('verified') },
                                    { key: 2, value: false, text: t('not_verified') },
                                ] }
                                value={ filters.verified }
                                onChange={ (e, { value }) => setFilters({...filters, verified: value}) }
                            />
                        </Form.Group>
                        {/* <Button type="button" onClick={resetFilters} size="small"> {t('common.reset')} </Button> */}
                        <Button size="small" primary> {t('apply')} </Button>
                    </>
                }
            </Form>
        </Sidebar>
    )

}

export default InvoicesFilter;