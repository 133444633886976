import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { API } from "@store/config";
import { requests } from "@helpers/requests";
// components
import { Form, Divider } from "semantic-ui-react";
import SuperField from "@components/forms/SuperField";
import ModalCancel from "@components/buttons/ModalCancel";
import ModalSubmit from "@components/buttons/ModalSubmit";
// others

const CourseForm = ({
    courseType,
    setCourses,
    onClose,
    course,
    cancelHide,
}) => {
    const { t } = useTranslation();

    const [data, setData] = useState({
        title: course ? course.title : "",
        is_mandatory: course ? course.is_mandatory : false,
        category: course ? course.category?.id : 0,
    });

    const [isMandatory, setIsMandatory] = useState(data.is_mandatory);

    const [isProcessing, setIsProcessing] = useState(false);

    // Submit Form
    const onSubmit = async () => {
        setIsProcessing(true);

        let request = null;
        if (course?.id) {
            request = await requests.put(API.COURSES + course.id + "/", data);
        } else {
            request = await requests.post(API.COURSES, data);
        }

        if (request.status === 201) {
            setCourses((prevState) => [...prevState, request.response]);
        } else if (request.status === 200) {
            setCourses((prevState) =>
                prevState.filter((item) => {
                    if (item.id === course.id) {
                        item.title = request.response.title;
                        item.is_mandatory = request.response.is_mandatory;
                        item.category = request.response.category;
                    }

                    return item;
                })
            );
        }

        onClose();
        setIsProcessing(false);
    };

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                as="input"
                autoFocus
                required
                label={t("title")}
                value={data.title}
                onChange={(e) => setData({ ...data, title: e.target.value })}
            />

            <Divider />

            <Form.Group widths="equal">
                <SuperField
                    as="choice"
                    required
                    label={t("category")}
                    endpoint={API.COURSES_CATEGORIES}
                    text="title"
                    value={data.category}
                    onChange={(e, { value }) =>
                        setData({ ...data, category: value })
                    }
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField
                    as="checkbox"
                    required
                    label={t("mandatory")}
                    text="title"
                    checked={data.is_mandatory}
                    onChange={(e) => {
                        setIsMandatory(!isMandatory);
                        setData({ ...data, is_mandatory: !isMandatory });
                    }}
                />
            </Form.Group>

            <Divider />
            <Form.Field style={{ textAlign: "right" }}>
                {cancelHide ? "" : <ModalCancel onClose={onClose} />}
                <ModalSubmit
                    loading={isProcessing}
                    disabled={
                        isProcessing || data.title === "" || data.category === 0
                    }
                    text={t("save")}
                />
            </Form.Field>
        </Form>
    );
};

export default CourseForm;
