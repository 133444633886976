import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { useLocation, useHistory } from 'react-router-dom';
//components
import SuperField from '@components/forms/SuperField';
import CustomFormDivider from '@components/general/CustomFormDivider';
import { Divider, Button, Form, List, Header  } from 'semantic-ui-react';
import moment from 'moment';

const ContractTerminationFilterForm = ({ fetchDatas, setVisible, terminationsInitState, terminationsFilters, setTerminationsFilters }) => {
    const location = useLocation()
    const history = useHistory()
    const { t } = useTranslation();
    const [processing, setProcessing] = useState(false)
    const [invalidDateRange, setInvalidDateRange] = useState(false)

    // Validate Form
    useEffect( () => {
        if( terminationsFilters.noticePeriodAfter !== "" && terminationsFilters.noticePeroidBefore){
            if( moment(terminationsFilters.noticePeroidBefore).isAfter(terminationsFilters.noticePeriodAfter) ){
                setInvalidDateRange(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRange(false)
            }
        }
        // eslint-disable-next-line
    }, [terminationsFilters])

    const onFilter = async () => {
        setProcessing(true)
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchDatas(true)
        setProcessing(false)
    }

    const resetTerminationsFilters = () => {
        setTerminationsFilters(terminationsInitState)
    }

    return (
        <div>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('advanced_filter')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>
            <Form style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                <SuperField as="choice"
                    search
                    text="fullname"
                    label={t('employee')}
                    value={terminationsFilters.employee}
                    onChange={ (e, {value}) => setTerminationsFilters({ ...terminationsFilters, employee: value }) }
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                />

                <Divider/>

                <Form.Group widths="equal">
                    <SuperField as="choice"
                        type="contract_termination_types"
                        label={t('type')}
                        value={terminationsFilters.type}
                        onChange={(e, { value }) => setTerminationsFilters({ ...terminationsFilters, type: value })}
                    />
                    <SuperField as="choice"
                        type="contract_termination_actors"
                        label={t('terminated_by')}
                        value={terminationsFilters.terminatedBy}
                        onChange={(e, { value }) => setTerminationsFilters({ ...terminationsFilters, terminatedBy: value })}
                    />

                </Form.Group>

                <CustomFormDivider
                    header = {t('notice_period_range')}
                    divider = {true}
                    content = {
                        <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={terminationsFilters.noticePeriodAfter}
                            onChange={ (e, { value }) => setTerminationsFilters({...terminationsFilters, noticePeriodAfter: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRange}
                            value={terminationsFilters.noticePeroidBefore}
                            onChange={ (e, { value }) => setTerminationsFilters({...terminationsFilters, noticePeroidBefore: value})}
                        />
                        </Form.Group>
                    }
                />

                <Form.Field style={{ textAlign: "right"}}>
                    <Button size="medium" content={t('reset')} style={{}} onClick={() => resetTerminationsFilters()}/>
                    <Button loading={processing} disabled={processing} size="medium" content={t('apply')}
                        style={{backgroundColor: "var(--primary)", color:"white"}}
                        onClick={() => {
                            onFilter()
                            setVisible(false)
                    }}/>
                </Form.Field>
            </Form>
        </div>
    );
};

export default ContractTerminationFilterForm;