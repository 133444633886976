import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { benefitsService } from '@services/ServiceBenefits';
// components
import Loading from '@components/general/Loading';
import BreadcrumbNav from '@components/BreadcrumbNav';
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module components
import BenefitsTable from '../components/tables/BenefitsTable';
import BenefitsForm from '../components/forms/BenefitsForm';
//import CategoriesList from './CategoriesList';
import CanView from '@components/perms/CanView';


const BenefitsList = () => {
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const breadcrumb_items = [
        { 'name': t('benefits_list'), 'icon': '', 'href': '' }
    ]

    const actions = [{
        component:
            <CanView permissions={["benefits.c_manage_all_benefits"]}>
                <SuperDuperModal
                    size='small'
                    header={t('create_benefit')}
                    trigger={
                        <OutlineAddButton
                            size="small"
                            color="violet"
                            text={t('create_benefit')}
                        />
                    }
                    content={
                        <BenefitsForm
                            categories={categories}
                            onConfirm={(response) => onCreate(response)}
                        />
                    }
                />
            </CanView>
    }]

    useEffect(() => {
        async function fetchData() {
            const request = await benefitsService.getBenefits()
            if (request.status === 200) {
                let tableData = [];
                for (let i = 0; i < request.response.length; i++) {
                    tableData.push({
                        id: request.response[i].id,
                        name: request.response[i].name,
                        category: request.response[i].category && request.response[i].category.name,
                        category_id: request.response[i].category && request.response[i].category.id,
                        category_color: request.response[i].category && request.response[i].category.color,
                        icon: request.response[i].icon && request.response[i].icon,
                        employees: request.response[i].employees.length,
                    });
                }

                setData(tableData);
            }

            const request2 = await benefitsService.getCategories()
            if (request2.status === 200) {
                setCategories(request2.response)
            }

            setIsLoading(false);
        }

        fetchData();
    }, [])

    const onDelete = async (id) => {
        const result = await benefitsService.deleteBenefits(id);

        if (result.status === 204) {
            setData(prevState => prevState.filter(item => item.id !== id))
        }
    }

    async function onCreate(new_item) {
        setData([...data, {
            id: new_item.id,
            name: new_item.name,
            category: new_item.category && new_item.category.name,
            category_id: new_item.category ? new_item.category.id : "",
            category_color: new_item.category ? new_item.category.color : "",
            icon: new_item.icon ? new_item.icon : "",
            employees: new_item.employees.length
        }])
    }

    const updateBenefit = async (updated_item) => {
        setData(prevState => prevState.filter((item) => {
            if (item.id === updated_item.id) {
                item.name = updated_item.name;
                item.category = updated_item.category && updated_item.category.name;
                item.category_id = updated_item.category ? updated_item.category.id : "";
                item.category_color = updated_item.category ? updated_item.category.color : "";
                item.icon = updated_item.icon;
                item.employees = updated_item.employees.length;
            }

            return item;
        }));
    }

    return (
        <>
            <CanView permissions={["benefits.c_view_all_benefits", "benefits.c_view_user_benefits"]} redirect>
                <BreadcrumbNav items={breadcrumb_items} actions={actions} />
                { isLoading ? <Loading /> :
                    <BenefitsTable
                        data={data}
                        categories={categories}
                        updateBenefit={(updated) => updateBenefit(updated)}
                        onDelete={onDelete}
                    />
                }
            </CanView>
        </>
    );
};

export default BenefitsList;