import React, { Fragment, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
// store
import { routes } from "@routes/index";
import { authService } from "@services/ServiceAuth";
// eslint-disable-next-line
import { setTheme } from '@store/actions'

import {
    Menu,
    Image,
    Dropdown,
    // Icon,
    // Search,
    Label,
    Input,
    Popup
} from "semantic-ui-react";
import Icon from '@components/Icon';
import LanguageSelector from "../../../components/general/LanguageSelector";

import HorizontalSidebar from './HorizontalSidebar';

function MainNavigation({ changeViewType, isFullWidth }) {
    const { t } = useTranslation()
    const history = useHistory();
    const dispatch = useDispatch()
    const user = useSelector((state) => state.user);
    const logo = useSelector((state) => state.logo);
    const theme = useSelector(state => state?.theme || "light")
    const [hSidebar, setHSidebar] = useState(false);

    const logout = () => {
        authService.logout(dispatch);
        history.push(routes.LOGIN);
    };

    return (
        <Fragment>
            <div className="bigMenu">
                <HorizontalSidebar visible={hSidebar} close={() => setHSidebar(false)} />

                <Menu stackable fixed='top' borderless style={ isFullWidth ? { left: 0, width: "100%" } : {}}>
                    <Menu.Menu position="left">
                        <Menu.Item>
                            <Link to={routes.DASHBOARD}>
                                <Image size="tiny" src={logo} style={{ maxHeight: "43px", height: "100%" }} />
                            </Link>
                        </Menu.Item>
                    </Menu.Menu>

                    <Menu.Menu style={{ width: "55%", marginLeft: "auto", marginRight: "auto" }}>
                        {/* <Search disabled className="search-bar" fluid style={{ width: "100%", marginTop: ".8rem" }} size="large" placeholder={t('search')} /> */}
                    </Menu.Menu>

                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <LanguageSelector />
                        </Menu.Item>
                        <Menu.Item>
                            <Icon style={{ cursor: "pointer" }} title={t('quick_access')} onClick={() => setHSidebar(true)} name="grid-outline" />
                        </Menu.Item>
                        {window.location.pathname === routes.DASHBOARD &&
                            <Menu.Item>
                                <Icon style={{ cursor: "pointer" }} onClick={changeViewType !== undefined ? changeViewType : null} name="swap-horizontal-outline" />
                            </Menu.Item>
                        }
                        <Menu.Item>
                            <Dropdown
                                className='icon'
                                floating
                                icon={<Icon name="notifications-outline"/>}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Header content={t('notifications')} />
                                    <Dropdown.Menu scrolling>
                                        {/* <Dropdown.Item>Notification 1</Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <Label
                                circular
                                floating
                                style={{ background: "var(--danger)", padding: "2px 3px", top: "10px", left: "30px" }}>
                                3
                            </Label> */}
                        </Menu.Item>
                        <Menu.Item>
                            <Popup 
                                position="bottom center"
                                trigger={
                                    <Icon 
                                        className="disabled"
                                        style={{ cursor: "not-allowed" }}
                                        name={theme === "light" ? "moon-outline" : "sunny-outline" } 
                                        // onClick={() => dispatch(setTheme(theme === "light" ? "dark" : "light"))}
                                    />
                                }
                                content={theme === "light" ? "Dark mode (new feature soon)" : "Light mode"}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            {!user.avatar ?
                                <Label circular className="circular-label" style={{ marginRight: ".5rem", backgroundColor: "var(--light)", color: "var(--dark)" }}>
                                    {user.name ? user?.name.charAt(0) : ""}
                                </Label>
                                : <Image src={user.avatar} style={{ marginRight: ".5rem" }} avatar />
                            }
                            <Dropdown text={user?.name}>
                                <Dropdown.Menu direction="left" style={{ marginTop: "1rem", borderRadius: "0" }}>
                                    <Dropdown.Item 
                                        as={Link}
                                        className="nav-link"
                                        to={routes.PROFILE}
                                        icon={<Icon name="person-circle-outline" style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}/>}
                                        text={<span style={{ position: "relative", top: "-0.2rem" }}> { t('profile') } </span>}
                                    />
                                    <Dropdown.Item
                                        as={Link}
                                        className="nav-link"
                                        to={routes.SETTINGS}
                                        icon={<Icon name="settings-outline" style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}/>}
                                        text={<span style={{ position: "relative", top: "-0.2rem" }}> { t('settings') } </span>}
                                    />
                                    <Dropdown.Item
                                        as="button"
                                        icon={<Icon name="log-out-outline" style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}/>}
                                        text={<span style={{ position: "relative", top: "-0.2rem" }}> { t('sign_out') } </span>}
                                        onClick={logout}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>

            <div className="smallMenu">
                <HorizontalSidebar visible={hSidebar} close={() => setHSidebar(false)} />

                <Menu fixed='top' borderless style={ isFullWidth ? { left: 0, width: "100%" } : {}}>
                    <Menu.Menu position="left" style={{ width: "70%" }}>
                        <Menu.Item>
                            <Link to={routes.DASHBOARD}>
                                <Image size="tiny" src={logo} style={{ maxHeight: "43px", height: "100%" }} />
                            </Link>
                        </Menu.Item>
                    </Menu.Menu>

                    <Menu.Menu position='right' style={{ right: 0 }}>

                        <Menu.Item>
                            <Icon style={{ cursor: "pointer" }} title={t('quick_access')} onClick={() => setHSidebar(true)} name="grid-outline" />
                        </Menu.Item>
                        <Menu.Item>
                            <Dropdown
                                className='icon'
                                floating
                                icon={<Icon name="notifications-outline"/>}
                            >
                                <Dropdown.Menu>
                                    <Dropdown.Header content={t('notifications')} />
                                    <Dropdown.Menu scrolling>
                                        {/* <Dropdown.Item>Notification 1</Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <Label
                                circular
                                floating
                                style={{ background: "var(--danger)", padding: "2px 3px", top: "10px", left: "30px" }}>
                                3
                            </Label> */}
                        </Menu.Item>
                        <Menu.Item>
                            <Popup 
                                position="bottom center"
                                trigger={
                                    <Icon 
                                        className="disabled"
                                        style={{ cursor: "pointer" }}
                                        name={theme === "light" ? "moon-outline" : "sunny-outline" } 
                                        // onClick={() => dispatch(setTheme(theme === "light" ? "dark" : "light"))}
                                    />
                                }
                                content={theme === "light" ? "Dark mode" : "Light mode"}
                            />
                        </Menu.Item>

                        <Menu.Item>
                            <Dropdown
                                icon='bars'
                                floating
                                className='icon'
                            >
                                <Dropdown.Menu>
                                    <Input disabled icon="search" iconPosition='left' name='search' className="search-bar" placeholder={t('search')} />
                                    <Dropdown.Divider />
                                    <Dropdown.Item 
                                        as={Link}
                                        className="nav-link"
                                        to={routes.PROFILE}
                                        icon={<Icon name="person-circle-outline" style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}/>}
                                        text={<span style={{ position: "relative", top: "-0.2rem" }}> { t('profile') } </span>}
                                    />
                                    <Dropdown.Item
                                        as={Link}
                                        className="nav-link"
                                        to={routes.SETTINGS}
                                        icon={<Icon name="settings-outline" style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}/>}
                                        text={<span style={{ position: "relative", top: "-0.2rem" }}> { t('settings') } </span>}
                                    />
                                    <Dropdown.Item
                                        as="button"
                                        icon={<Icon name="log-out-outline" style={{ fontSize: "1.1rem", marginRight: "0.5rem" }}/>}
                                        text={<span style={{ position: "relative", top: "-0.2rem" }}> { t('sign_out') } </span>}
                                        onClick={logout}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </div>
        </Fragment >
    );
}

export default MainNavigation;