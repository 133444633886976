import React from 'react';
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
// components
import { Form, Header, Button, Divider, List } from 'semantic-ui-react'
import SuperField from '@components/forms/SuperField'
// import SuggestionField from '@components/forms/common/SuggestionField'
import SkillSeniorityFilterField from '@components/forms/SkillSeniorityFilterField'

const EmployeeFilterForm = ({ setVisible, fetchEmployees, initState, filters, setFilters, setFilterReset }) => {
    const { t } = useTranslation()
    const history = useHistory()
    const location = useLocation()

    const onFilter = async () => {
        setVisible(false)

        history.replace({ pathname: location.pathname, search: `?page=${1}` })
        await fetchEmployees('', true)
    }

    const resetFilters = async () => {
        setFilters(initState)
        setVisible(false)
        setFilterReset(true)
    }

    return (
        <Form onSubmit={() => onFilter(filters)} style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('filters')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }}/>
                    </List.Content>
                </List.Item>
            </List>

            <Divider/>
            <SuperField as="input"
                icon="search"
                iconPosition="left"
                label={t('search')}
                placeholder={t('search')}
                value={filters.search}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    search: value
                })}
            />

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    value={filters.language}
                    type="language_choices"
                    label={t('language')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        language: value
                    })}
                />

                <SuperField as="choice"
                    search
                    value={filters.languageLevel}
                    type="language_level_choices"
                    label={t('language_level')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        languageLevel: value
                    })}
                />
            </Form.Group>

            <SkillSeniorityFilterField
                endpoint={API.EMPLOYEES + "filter_skills/"}
                value={filters.skills}
                onChange={(e, { value }) => setFilters({...filters, skills: value})}
                isExact={filters.isExact}
                setIsExact={ () => setFilters({...filters, isExact: !filters.isExact})}
            />

            <SuperField as="choice"
                search
                multiple
                value={filters.labels}
                label={t('labels')}
                endpoint={API.CONTACT_LABELS}
                text="name"
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    labels: value
                })}
            />
            <SuperField as="choice"
                value={filters.gender}
                type="genders"
                label={t('gender')}
                onChange={(e, { value }) => setFilters({
                    ...filters,
                    gender: value
                })}
            />

            <Form.Group widths="equal">
                <SuperField as="choice"
                    search
                    value={filters.position}
                    endpoint={API.JOB_POSITIONS + "?query={id, title}"}
                    text="title"
                    label={t('position')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        position: value
                    })}
                />
                <SuperField as="choice"
                    search
                    value={filters.unit}
                    endpoint={API.UNITS + "?query={id, name}&only_from_active_company=true"}
                    text="name"
                    label={t('unit')}
                    onChange={(e, { value }) => setFilters({
                        ...filters,
                        unit: value
                    })}
                />
            </Form.Group>

            <Form.Field>
                <Button.Group>
                    <Button size="small" primary> {t('apply')} </Button>
                    <Button type="button" onClick={resetFilters} size="small"> {t('reset')} </Button>
                </Button.Group>
            </Form.Field>
        </Form>
    );
};

export default EmployeeFilterForm;