import React from 'react';
import { withRouter } from 'react-router';
import { Switch, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store 
import { icons } from '@icons';
import { routes } from '@routes';
// components
import { Container, Grid, Card, Divider } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
// module specific
import AssignedItemsView from './views/AssignedItemsView';
import AssetsManagementView from './views/AssetsManagementView';
import IssuedReturnedCardsView from './views/IssuedReturnedCardsView';
import AssetsHistoryView from './views/AssetsHistoryView';

const Assets = ({ location }) => {
    const { t } = useTranslation();

    const NavigateTo = ({ route, text, isActive, ...rest }) => {
        return (
            <Grid.Column as={Link} to={route} {...rest}
                className="toggle-button"
                style={{
                    textAlign: "center",
                }}
            >
                <Card className="active" fluid style={{ borderRadius: 0, background: isActive ? "var(--light)" : "none" }}>
                    <Card.Content as="h2">
                        {text}
                    </Card.Content>
                </Card>
            </Grid.Column>
        )
    }
    
    return (
        <CanView permissions={['assets.c_view_items', 'assets.c_view_warehouses', 'assets.c_view_item_categories']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("assets"), 'icon': icons.ASSETS, 'href': '' }
                ]}
            />
            <Container fluid>
                <Grid>
                    <Grid.Row columns={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <NavigateTo 
                            isActive={routes.ASSETS === location.pathname}
                            route={routes.ASSETS}
                            text={t('assigned_assets')}
                        />
                        <NavigateTo 
                            isActive={routes.ASSETS + "cards/" === location.pathname}
                            route={routes.ASSETS + "cards/"}
                            text={t('return_issue_cards')}
                        />
                        <NavigateTo 
                            isActive={routes.ASSETS + "management/" === location.pathname}
                            route={routes.ASSETS + "management/"}
                            text={t('manage_assets')}
                        />
                        <NavigateTo 
                            isActive={routes.ASSETS + "history/" === location.pathname}
                            route={routes.ASSETS + "history/"}
                            text={t('history')}
                        />
                    </Grid.Row>
                </Grid>
                <Divider/>

                <Switch>
                    <Route exact path={routes.ASSETS} component={AssignedItemsView} />
                    <Route path={routes.ASSETS + "cards/"} component={IssuedReturnedCardsView} />
                    <Route path={routes.ASSETS + "management/"} component={AssetsManagementView} />
                    <Route path={routes.ASSETS + "history/"} component={AssetsHistoryView} />
                </Switch>
            </Container>
        </CanView>
    );
};

export default withRouter(Assets);