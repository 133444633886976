import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { useSelectOptions } from '@helpers/hooks';
//components
import SuperField from '@components/forms/SuperField';
import CustomFormDivider from '@components/general/CustomFormDivider';
import { Divider, Button, Form, List, Header  } from 'semantic-ui-react';
import moment from 'moment';

const ContractFilterForm = ({ fetchDatas, setVisible, contractsInitState, contractsFilters, setContractsFilters }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const statusOptions = [
        { key: 1, value: "&is_draft=true", text: t('is_draft') },
        { key: 2, value: "&is_active=true&is_terminated=false", text: t('active') },
        { key: 3, value: "&is_active=false&is_terminated=true", text: t('terminated') },
        { key: 4, value: "&is_active=true&is_terminated=true", text: t('terminated_with_notice_period') }
    ]

    const [processing, setProcessing] = useState(false)
    const [workPositionOptions] = useSelectOptions(API.JOB_POSITIONS + "?query={id, title}&limit=0", "title")
    const [invalidDateRange, setInvalidDateRange] = useState(false)

    // Validate Form
    useEffect( () => {
        if( contractsFilters.validUntilAfter !== "" && contractsFilters.validUntilBefore){
            if( moment(contractsFilters.validUntilBefore).isAfter(contractsFilters.validUntilAfter) ){
                setInvalidDateRange(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRange(false)
            }
        }
        if( contractsFilters.validFromBefore !== "" && contractsFilters.validFromAfter){
            if( moment(contractsFilters.validFromAfter).isAfter(contractsFilters.validFromBefore) ){
                setInvalidDateRange(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRange(false)
            }
        }
        if( contractsFilters.trialPeriodBefore !== "" && contractsFilters.trialPeriodAfter){
            if( moment(contractsFilters.trialPeriodAfter).isAfter(contractsFilters.trialPeriodBefore) ){
                setInvalidDateRange(t('invalid_date_should_be_higher'))
            } else {
                setInvalidDateRange(false)
            }
        }

        // eslint-disable-next-line
    }, [contractsFilters])

    const onFilter = async () => {
        setProcessing(true)
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchDatas(true)
        setProcessing(false)
    }

    const resetContractsFilters = () => {
        setContractsFilters(contractsInitState)
    }

    return (
        <div>
            <List divided verticalAlign='middle'>
                <List.Item>
                    <List.Content>
                        <Header as="h2" content={t('advanced_filter')} style={{ marginTop: "1rem", paddingLeft: "1rem", textAlign: "left" }} />
                    </List.Content>
                </List.Item>
            </List>
            <Form style={{ paddingLeft: "1rem", paddingRight: "1rem", textAlign: "left" }}>
                <SuperField as="choice"
                    search
                    text="fullname"
                    label={t('employee')}
                    value={contractsFilters.employee}
                    onChange={ (e, {value}) => setContractsFilters({ ...contractsFilters, employee: value }) }
                    endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                />
                <Divider/>

                <Form.Group widths="equal">
                    <SuperField as="choice"
                        label={ t('contract_type') }
                        endpoint={API.CONTRACTS_TYPES}
                        text="title"
                        value={ contractsFilters.type }
                        onChange={(e, { value }) => setContractsFilters({...contractsFilters, type: value})}
                    />
                    <SuperField as="choice"
                        search
                        label={ t('work_position') }
                        disabled={ workPositionOptions.isLoading }
                        loading={ workPositionOptions.isLoading }
                        customOptions={workPositionOptions.options}
                        value={ contractsFilters.workPosition }
                        onChange={(e, { value }) => setContractsFilters({...contractsFilters, workPosition: value})}
                    />
                </Form.Group>

                {/* <Form.Group widths="equal">
                    <SuperField as="choice"
                        label={ t('project') }
                        endpoint={API.PROJECTS + "?query={id, name}"}
                        text="name"
                        value={ contractsFilters.project }
                        onChange={ (e, { value }) => setContractsFilters({...contractsFilters, project: value}) }
                    />
                    <SuperField as="choice"
                        label={ t('team') }
                        endpoint={API.TEAMS + "?only_basic_info=true"}
                        text="name"
                        value={ contractsFilters.team }
                        onChange={ (e, { value }) => setContractsFilters({...contractsFilters, team: value}) }
                    />
                </Form.Group> */}

                <SuperField as="choice"
                    label={ t('status') }
                    value={contractsFilters.status}
                    onChange={(e, {value}) => setContractsFilters({
                        ...contractsFilters,
                        status: value
                    })}
                    customOptions={statusOptions}
                />
                <SuperField as="checkbox"
                    label={t('indefinite_period_only')}
                    width={10}
                    checked={contractsFilters.isValidUntilNone}
                    onChange={() => setContractsFilters({...contractsFilters, isValidUntilNone: !contractsFilters.isValidUntilNone})}
                />
                <CustomFormDivider
                    header = {t('valid_from_range')}
                    divider = {true}
                    content = {
                        <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={contractsFilters.validFromAfter}
                            onChange={ (e, { value }) => setContractsFilters({...contractsFilters, validFromAfter: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRange}
                            value={contractsFilters.validFromBefore}
                            onChange={ (e, { value }) => setContractsFilters({...contractsFilters, validFromBefore: value})}
                        />
                        </Form.Group>
                    }
                />
                <CustomFormDivider
                    header = {t('valid_until_range')}
                    divider = {true}
                    content = {
                    <Form.Group widths="equal">
                        <SuperField as="datepicker"
                            label={ t('date_from') }
                            value={contractsFilters.validUntilAfter}
                            onChange={ (e, { value }) => setContractsFilters({...contractsFilters, validUntilAfter: value})}
                            />
                        <SuperField as="datepicker"
                            label={ t('date_to') }
                            error={invalidDateRange}
                            value={contractsFilters.validUntilBefore}
                            onChange={ (e, { value }) => setContractsFilters({...contractsFilters, validUntilBefore: value})}
                        />
                    </Form.Group>
                    }
                />
                <CustomFormDivider
                    header = {t('trial_period_range')}
                    divider = {true}
                    content = {<Form.Group widths="equal">
                    <SuperField as="datepicker"
                        label={ t('date_from') }
                        value={contractsFilters.trialPeriodAfter}
                        onChange={ (e, { value }) => setContractsFilters({...contractsFilters, trialPeriodAfter: value})}
                        />
                    <SuperField as="datepicker"
                        label={ t('date_to') }
                        error={invalidDateRange}
                        value={contractsFilters.trialPeriodBefore}
                        onChange={ (e, { value }) => setContractsFilters({...contractsFilters, trialPeriodBefore: value})}
                    />
                    </Form.Group>}
                />


                <Form.Field style={{ textAlign: "right"}}>
                    <Button size="medium" content={t('reset')} style={{}} onClick={() => resetContractsFilters()}/>
                    <Button loading={processing} disabled={processing} size="medium" content={t('apply')}
                        style={{backgroundColor: "var(--primary)", color:"white"}}
                        onClick={() => {
                            onFilter()
                            setVisible(false)}
                    }/>
                </Form.Field>
            </Form>
        </div>
    );
};

export default ContractFilterForm;