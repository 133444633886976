import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
// components
import Icon from '@components/Icon';
import { FlexRow, FlexItem } from '@components/tables/FlexTable'
import { Input } from 'semantic-ui-react'
import SuperDuperModal from '@components/modals/SuperDuperModal'
import UserFilterForm from './UserFilterForm'

const FilterAction = ({ text, isActive, ...rest }) => {
    return (
        <span className={"filter-action " + (isActive && "active")}  {...rest}>
            {text}
        </span>
    )
}

const UsersFilterSection = ({ fetchUsers }) => {
    const { t } = useTranslation();

    const [selectedFilter, setSelectedFilter] = useState(1)
    const [search, setSearch] = useState("")

    useEffect(() => {
        async function onFilter(){
            let params = "?search=" + search
    
            if (selectedFilter === 2) {
                params += "&is_active=true"
            } else if (selectedFilter === 3) {
                params += "&is_active=false"
            }

            await fetchUsers(params)
        }

        onFilter()
        // eslint-disable-next-line
    }, [selectedFilter, search])

    return (
        <FlexRow padding="0.5rem">
            <FlexItem basis="300%">
                <FilterAction isActive={selectedFilter === 1} onClick={() => setSelectedFilter(1)} text={t('all')} />
                <FilterAction isActive={selectedFilter === 2} onClick={() => setSelectedFilter(2)} text={t('active')} />
                <FilterAction isActive={selectedFilter === 3} onClick={() => setSelectedFilter(3)} text={t('inactive')} />
            </FlexItem>
            <FlexItem>
                <FlexRow>
                    <FlexItem>
                        <Input size="small" icon='search' value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search')} style={{ width: "100%" }} />
                    </FlexItem>
                    <FlexItem basis="20%">
                        <SuperDuperModal
                            trigger={
                                <Icon name="funnel-outline" style={{ cursor: "pointer", marginLeft: "1.5rem", marginRight: "1.5rem" }} />
                            }
                            content={
                                <UserFilterForm
                                    fetchUsers={fetchUsers}
                                    selectedFilter={selectedFilter}
                                    search={search}
                                />
                            }
                        />
                    </FlexItem>
                </FlexRow>
            </FlexItem>
        </FlexRow>
    );
};

export default UsersFilterSection;