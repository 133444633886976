import React, { Fragment, useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Switch, Route, Link, useLocation, useHistory } from "react-router-dom";
// store
import { routes } from '@routes';
import { icons } from '@icons';
import { API } from '@store/config';
import { getRequest, createRequest } from '@services/ServiceCommon';
import { dates } from '@helpers/dates';
import { addJournal } from '@store/actions'
//components
import Icon from '@components/Icon';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Container, Divider, Grid, Ref, Segment, Header, Card, Button, Image, Dropdown, Popup } from 'semantic-ui-react';
// module specific
import InvoicesGraphs from './components/graphs/InvoicesGraphs';
import InvoiceListView from './views/InvoiceListView';
import CreateAccountForm from './components/CreateAccountForm';
import ConnectionLoginForm from './components/ConnectionLoginForm';
import ManageAllowedUsers from './components/ManageAllowedUsers';
import SwitchAccountingJournal from './components/SwitchAccountingJournal';
import AccountingJournalForm from './components/AccountingJournalForm';
import InvoiceForm from './views/InvoiceForm';
import CreateDocument from './components/CreateDocument';
import ImportInvoices from './components/ImportInvoices';
import moment from 'moment';

function queryParams(location) {
    return new URLSearchParams(location?.search)
}

const Invoices = ({ location }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const query = queryParams(useLocation())
    const segmentRef = useRef()
    const reduxUser = useSelector(state => state.user)
    const reduxJournal = useSelector(state => state.journal)
    const [loading, setLoading] = useState(true)
    const [connections, setConnections] = useState([])
    const [connection, setConnection] = useState({})
    // eslint-disable-next-line
    const [premiumConnection, setPremiumConnection] = useState({})
    const [view, setView] = useState(null)
    const [refetch, setRefetch] = useState(true)
    const [user, setUser] = useState({
        ...reduxUser,
        canView: false,
        canManage: false,
        canDelete: false,
        journal: reduxJournal
    })

    const connected = query.get("connected") === "True" || query.get("connected") === "true"
    const breadcrumb_items = [
        { 'name': t('invoices'), 'icon': icons.INVOICES, 'href': '' }
    ]

    useEffect(() => {
        /* 
            ** 1. fetch connections,
            ** 2. set first as active...
            ** 3. fetch premium api connection
        */
        async function fetchConnections(){
            if( refetch === false ) return;

            const request = await getRequest(API.INVOICES_CONNECTIONS)
            if( request.status === 200 ){
                setConnections(request.response)
                if( request.response.length > 0 ){
                    setConnection(request.response[0])
                    let currentJournal = request.response[0].accounts.find(item => item.allowed_users.find(allowedUser => allowedUser.user.username === user.username) !== undefined)
                    if( user.journal !== null ){
                        currentJournal = request.response[0].accounts.find(item => item.account_id === reduxJournal.account_id)
                    }

                    if( currentJournal !== undefined ) dispatch(addJournal(currentJournal))
                    let allowedUser =  currentJournal?.allowed_users?.find(item => item.user.username === user.username )

                    if (allowedUser){
                        setUser(prevState => {
                            return {
                                ...prevState,
                                canView: allowedUser.can_view,
                                canManage: allowedUser.can_manage,
                                canDelete: allowedUser.can_manage,
                                journal: currentJournal
                            }
                        })
                    }

                    
                    if (currentJournal !== undefined) {
                        // get ibans for current journal
                        const requestIbans = await getRequest(API.COMMON + "ibans/?id_number=" + currentJournal.id_number)
                        if (requestIbans.status === 200){
                            currentJournal['ibans'] = requestIbans.response
                            dispatch(addJournal(currentJournal))
                        }

                        // get premium API connection
                        const requestPremiumConnections = await getRequest(API.INVOICES + "banking/tb/premium/connected/?connection=" + request.response[0].connection_id)
                        if( requestPremiumConnections.status === 200 ){
                            if( expiresIn(requestPremiumConnections.response.expires_in, dates.convertUTCDateToLocalDate(requestPremiumConnections.response.authorized_when)) < 2 ){ // if expiration is less then 2 days refresh
                                const refreshPremiumConnection = await createRequest(API.INVOICES + "banking/tb/premium/refresh/?connection=" + request.response[0].connection_id)
    
                                if( refreshPremiumConnection.status === 200 ){
                                    const refetchPremiumConnection = await getRequest(API.INVOICES + "banking/tb/premium/connected/?connection=" + request.response[0].connection_id)
    
                                    if( refetchPremiumConnection.status === 200 ){
                                        setPremiumConnection(requestPremiumConnections.response)
                                    }
                                }
                            } else {
                                setPremiumConnection(requestPremiumConnections.response)
                            }
                            
                        }
                    }
                }
            }

            setTimeout(function() {
                setLoading(false)
                setRefetch(false)
            }, 500);
        }

        fetchConnections()
        // eslint-disable-next-line
    }, [reduxUser, refetch])

    const expiresIn = (expiresInSeconds, authorizedDate) => {
        const expiresInDate = moment(authorizedDate, "YYYY-MM-DD HH:mm:ss").add(expiresInSeconds, "seconds")
        const currentDate = moment(moment().format("YYYY-MM-DD HH:mm:ss"))

        return expiresInDate.diff(currentDate, "days")
    }

    const CardChoice = ({ content, onChoiceSelect, ...rest }) => {
        return (
            <Grid.Column {...rest}
                className="toggle-button"
                style={{
                    textAlign: "center",
                    cursor: "default"
                }}
            >
                <Card onClick={onChoiceSelect} centered className="active" fluid style={{ cursor: "pointer", borderRadius: 0, color: "var(--dark)", width: "50%", marginBottom: "2rem" }}>
                    <Card.Content as="h2">
                        { content }
                    </Card.Content>
                </Card>
            </Grid.Column>
        )
    }

    const BlockedViewAccess = () => {
        const { t } = useTranslation()
        return (
            <div style={{ width: "100%", textAlign: "center", marginTop: "2rem"}}>
                <Header as="h3" content={
                    <>
                        <span style={{ fontSize: "1.5rem" }}> 
                            { t('cant_see_invoices_message') } 
                        </span> <br/>
                        { t('contact_your_administrator') }.
                    </>
                }/>
                <Link to={routes.DASHBOARD}>
                    <Button primary size="large">
                        <Icon
                            name="arrow-back-outline"
                            style={{ marginRight: "0.5rem" }}
                        /> 
                        <span 
                            style={{ 
                                position: "relative",
                                top: "-0.2rem"
                            }}
                        >
                            { t('back_to_dashboard') }
                        </span>
                    </Button>
                </Link>
            </div>
        )
    }

    const FirstConnectSetup = () => {
        const { t } = useTranslation()
        return (
            <Grid style={{ paddingTop: 0 }}>
                <Grid.Row verticalAlign="middle" style={{ paddingTop: 0 }}>
                    <Grid.Column computer={8}>
                        { view === 1 && <ConnectByCreatingUser/> }
                        { (view === 2 || connected === true) && <ConnectByExistingUser connected={connected}/> }
                        { ((view === null || view === undefined)) && (connected === false || connected === undefined) && 
                            <>
                                <Header as="h2" style={{ marginTop: "2rem", marginBottom: "3rem", fontWeight: "bold", textTransform: "uppercase"}}>
                                    { t('connect_invoice_service') }
                                </Header>
                                <Grid centered>
                                    <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <CardChoice onChoiceSelect={() => setView(1)} content={
                                            <> 
                                                <Icon name="log-in-outline" style={{ marginRight: "0.5rem" }}/>
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    { t('connect_by_creating_new') } 
                                                </span>
                                            </>
                                        }/>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <CardChoice onChoiceSelect={() => setView(2)} content={
                                            <> 
                                                <Icon name="log-in-outline" style={{ marginRight: "0.5rem" }}/> 
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    { t('connect_with_existing') }
                                                </span>
                                            </>
                                        }/>
                                    </Grid.Row>
                                </Grid>
                            </>
                        }
                    </Grid.Column>
                    <Grid.Column computer={8}>
                        <Image centered src="/images/invoices/finvix_banner.svg" width="80%"/>
                    </Grid.Column>
                </Grid.Row>
                
            </Grid>
        )
    }

    const ConnectByCreatingUser = () => {
        const { t } = useTranslation()
        const [verifyEmail, setVerifyEmail] = useState(false)
        const [email, setEmail] = useState("")

        return (
            <Grid centered>
                <Grid.Row>
                    <Grid.Column computer={16} table={16} mobile={16}>
                        <Segment style={{ padding: "2rem" }}>
                        { !verifyEmail 
                            ? 
                            <>
                                <FlexRow background="transparent" padding="0">
                                    <FlexItem basis="25%">
                                        <Icon className="ref-link" onClick={() => setView(null)} name="arrow-back-outline"/>
                                    </FlexItem>
                                    <FlexItem textAlign="right">
                                        <Header as="h4"
                                            style={{ fontSize: "1.3rem" }}
                                            content={ t('connect_by_creating_new') }
                                        />
                                    </FlexItem>
                                </FlexRow>
                                <Divider/>
                                <CreateAccountForm 
                                    setVerifyEmail={setVerifyEmail}
                                    setEmail={setEmail}
                                />
                            </>
                            : 
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <Header as="h3" content={t('email_verification_is_required')}/>
                                <p> { t('we_sent_verification_to_your_email') } <strong>{ email }</strong>. { t('please_confirm_your_email_address') }.</p>
                            </div>
                        }
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    const ConnectByExistingUser = () => {
        const { t } = useTranslation()

        return (
            <Grid centered>
                <Grid.Row>
                    <Grid.Column computer={12} table={16} mobile={16}>
                        { connected && 
                            <p style={{ fontWeight: "bold", fontSize: "1.2rem", textAlign: "center"}}>
                                <div style={{ color: "var(--blue)", textTransform: "uppercase", fontSize: "1.5rem", width: "100%", paddingBottom: "0.8rem" }}> { t('verification_was_successfull') }!</div>
                                <div style={{ fontSize: "1.3rem", width: "100%" }}>
                                    { t('please_sign_in_to_finish_your_connection_process') }.
                                </div>
                            </p>
                        }

                        <Segment style={{ padding: "2rem" }}>
                            { connected === true ? "" : 
                                <>
                                    <FlexRow background="transparent" padding="0">
                                        <FlexItem basis="25%">
                                            <Icon className="ref-link" onClick={() => setView(null)} name="arrow-back-outline"/>
                                        </FlexItem>   
                                        <FlexItem textAlign={ connected ? "center" : "right" }>
                                            <Header as="h4"
                                                style={{ fontSize: "1.3rem" }}
                                                content={ t('connect_with_existing') }
                                            />
                                        </FlexItem>
                                    </FlexRow>
                                    <Divider/>
                                </>
                            }
                            <ConnectionLoginForm setRefetch={setRefetch}/>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

    const InvoicesHeaderPanel = () => {
        const { t } = useTranslation()
        const history = useHistory()

        const handlePremiumConnection = async () => {
            history.push(routes.INVOICES)
            const domain = window.location.href
            const request = await getRequest(API.INVOICES + "banking/tb/premium/authorize/?connection=" + connection.connection_id + "&domain=" + domain)

            if( request.status === 200 ){
                window.location.href = request.response.authorize_url;
            }
        }

        return (
            <>
                <Grid>
                    <Grid.Row verticalAlign="middle" style={{ padding: 0 }} columns={2}>
                        <Grid.Column computer={8} style={{ textAlign: "left" }}>
                            <Button.Group basic size="small" style={{ borderRadius: '0' }}>
                                <Button active={routes.INVOICES === location.pathname} as={Link} to={routes.INVOICES}>
                                    <Icon name="bar-chart-outline" style={{ marginRight: "0.5rem" }}/>
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        { t('overview') /* Prehľad */} 
                                    </span>
                                </Button>
                                <Button active={routes.INVOICES + "incomes/" === location.pathname} as={Link} to={routes.INVOICES + "incomes/"}>
                                    <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        { t('send_invoices') } { /*Vystavené */ }
                                    </span>
                                </Button>
                                <Button active={routes.INVOICES + "expenses/" === location.pathname} as={Link} to={routes.INVOICES + "expenses/"}>
                                    <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/> 
                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                        { t('recieved_invoices') } {/* Prijaté */}
                                    </span>
                                </Button>
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column computer={2}/>
                        <Grid.Column style={{ textAlign: "right" }} computer={6}>
                            <Button.Group size="small" style={{ borderRadius: '0' }}>
                                <Button.Group>
                                    <Button style={{ background: "var(--dark)", color: "var(--white)", cursor: "default"}}>
                                        <Popup 
                                            position='left center'
                                            trigger={
                                                <div>
                                                    <Icon name={ connection.is_active && user.journal !== null ? "ellipse" : "alert-circle" } style={{ marginRight: "0.5rem", color: connection.is_active && user.journal !== null ? "var(--success)" : "var(--danger)" }}/> 
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                                        { user.journal !== null ? user.journal.name : "Journal unavailable!" }
                                                    </span>
                                                </div>
                                            }
                                            content={ connection.is_active ? t('connected') : t('connection_error') }
                                        />
                                    </Button>
                                    <Dropdown
                                        simple
                                        style={{ background: "var(--dark-hover)", color: "var(--white)", marginRight: "0.5rem" }}
                                        className='button icon'
                                        floating
                                        trigger={<Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/>}
                                    >
                                        <Dropdown.Menu style={{ marginTop: "0", zIndex: 999 }}>
                                            <SuperDuperModal 
                                                size="large"
                                                trigger={
                                                    <Dropdown.Item disabled={ user.role !== 1 }>
                                                        <Icon name="people-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('permissions_management_users') + " - " + (user.journal !== null && user.journal.name) }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <ManageAllowedUsers connection={connection} user={user} setUser={setUser}/>
                                                }
                                            />
                                            <SuperDuperModal 
                                                size="small"
                                                trigger={
                                                    <Dropdown.Item disabled={!user.canManage}>
                                                        <Icon name="cloud-upload-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('import_invoices') }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <ImportInvoices connection={connection} user={user} setRefetch={setRefetch}/>
                                                }
                                            />
                                            <SuperDuperModal 
                                                size="small"
                                                trigger={
                                                    <Dropdown.Item disabled={connection.accounts.length < 2}>
                                                        <Icon name="swap-vertical-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('switch_journal') }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <SwitchAccountingJournal connection={connection} user={user} setRefetch={setRefetch}/>
                                                }
                                            />
                                            <SuperDuperModal 
                                                size="tiny"
                                                header={t('add_another_journal')}
                                                trigger={
                                                    <Dropdown.Item disabled={user.role !== 1}>
                                                        <Icon name="add-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('add_another_journal') }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <AccountingJournalForm connection={connection} user={user} setRefetch={setRefetch}/>
                                                }
                                            />
                                            {/* <SuperDuperModal
                                                trigger={
                                                    <Dropdown.Item disabled={ !user.canManage }>
                                                        <Icon name="sync alternate"/> { t('sign_in_again') } - <strong>{ connection.account_name }</strong>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <ConnectionLoginForm connection={connection} setRefetch={setRefetch}/>
                                                }
                                            /> */}
                                            { premiumConnection?.id === undefined
                                                ? 
                                                <Dropdown.Item onClick={ () => handlePremiumConnection() }> 
                                                    <Icon name="log-in-outline" style={{ marginRight: "0.5rem" }}/> 
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>{ t('connect_to_premium_aisp') }</span> 
                                                </Dropdown.Item>
                                                : 
                                                <Dropdown.Item> 
                                                    <Icon name="information-circle-outline" style={{ marginRight: "0.5rem" }}/>
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                                        { t('premium_aisp_connection_expires_in')}  
                                                        <strong> { expiresIn(premiumConnection.expires_in, dates.convertUTCDateToLocalDate(premiumConnection.authorized_when)) } { t('days') } </strong>
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                            { premiumConnection?.id !== undefined
                                                && 
                                                <Dropdown.Item onClick={ () => handlePremiumConnection() }>
                                                    <Icon name="sync-outline" style={{ marginRight: "0.5rem" }}/>
                                                    <span style={{ position: "relative", top: "-0.2rem"}}>
                                                        { t('connect_to_premium_aisp_again') } 
                                                    </span>
                                                </Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Button.Group>
                                
                                { user.canManage && 
                                    <Button.Group>
                                        <SuperDuperModal 
                                            closeOnDimmerClick={false}
                                            closeOnDocumentClick={false}
                                            trigger={
                                                <Button primary>
                                                    <Icon name="document-text-outline" style={{ marginRight: "0.5rem" }}/>
                                                    <span style={{ position: "relative", top: "-0.2rem" }}>
                                                        { t('create_invoice_document') }
                                                    </span>
                                                </Button>
                                            }
                                            content={ <CreateDocument connection={connection} journal={user.journal}/>}
                                        />
                                        {/* <Dropdown
                                            simple
                                            style={{ background: "var(--primary-hover)", color: "var(--white)"}}
                                            className='button icon'
                                            floating
                                            trigger={<></>}
                                        >
                                            <Dropdown.Menu style={{ marginTop: "0" }}>
                                                <Dropdown.Item disabled>
                                                    <Icon name="upload"/> { t('import_invoice_xlsx') }
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                    </Button.Group>
                                }
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>
            </>
        )
    }

    return (
        <Fragment>
            <BreadcrumbNav items={breadcrumb_items}/>
            <Ref innerRef={segmentRef}>
                <Container fluid>
                    <Segment 
                        loading={loading || refetch}
                        style={{ 
                            padding: 0, 
                            background: "transparent", 
                            boxShadow: "none", 
                            border: "none",
                            marginBottom: "1rem",
                            marginTop: "2rem",
                            textAlign: "center"
                        }}
                    >
                        { (loading || refetch) && <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> { t('message_performing_verification') } </p> }
                        { (!loading && !refetch) && 
                            <>
                                { connections.length === 0 && // when no connection is created allow user to create one if have proper permissions for it
                                    <Grid style={{ paddingTop: 0 }}>
                                        <Grid.Row style={{ paddingTop: 0 }}>
                                            <Grid.Column>
                                                { user.role === 1 
                                                    ? <FirstConnectSetup/>
                                                    : <p style={{ textAlign: "center", fontWeight: "bold" }}> {t('no_permissions_to_manage_invoices')} </p>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                }

                                { connections.length > 0 && // If there is any connection (at least one)
                                    <>
                                        { user.canView && <InvoicesHeaderPanel/> }
                                        <Switch>
                                            <Route exact path={routes.INVOICES}>
                                                { user.canView
                                                    ? <InvoicesGraphs connection={"?connection=" + connection.connection_id + "&account_ids=" + user.journal.id} premiumConnection={premiumConnection}/>
                                                    : <BlockedViewAccess/>
                                                }
                                            </Route>
                                            <Route path={routes.INVOICES + "incomes/"}>
                                                { user.canView
                                                    ? <InvoiceListView connection={connection} journal={user.journal} premiumConnection={premiumConnection} segmentRef={segmentRef} type="incomes"/>
                                                    : <BlockedViewAccess/>
                                                }
                                            </Route>
                                            <Route path={routes.INVOICES + "expenses/"}>
                                                { user.canView
                                                    ? <InvoiceListView connection={connection} journal={user.journal} premiumConnection={premiumConnection} segmentRef={segmentRef} type="expenses"/>
                                                    : <BlockedViewAccess/>
                                                }
                                            </Route>
                                            <Route path={routes.INVOICES + "manage/:id"}>
                                                { user.canView
                                                    ? <InvoiceForm connection={connection} journal={user.journal}/>
                                                    : <BlockedViewAccess/>
                                                }
                                            </Route>
                                        </Switch>
                                    </>
                                }
                            </>
                        }
                    </Segment>
                </Container>
            </Ref>
        </Fragment>
    );
};

export default withRouter(Invoices);
