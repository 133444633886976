import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { toast } from 'react-semantic-toasts';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { getParams } from '@helpers/functions';
import { parseJsonStringToObject } from "@helpers/functions";
import { hiringService } from '@services/ServiceHiring';
import { getRequest, patchRequest, createRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import AvatarIcon from '@components/AvatarIcon';
import EmptyRow from '@components/tables/EmptyRow';
import CanView from '@components/perms/CanView';
import ConfirmModal from '@components/modals/ConfrimModal';
import ContactCell from '../../candidates/components/tables/ContactCell';
import FullHistoryLog from '../components/activity-log/FullHistoryLog';
import ReactionsTable from '../components/tables/ReactionsTable';
import NewCandidateForm from '../components/forms/NewCandidateForm';
import LaunchNewRoomForm from '../components/forms/LaunchNewRoomForm';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import SuperField from '@components/forms/SuperField';
import NewManagerForm from '../components/forms/NewManagerForm';
import NewRecruitersForm from '../components/forms/NewRecruitersForm';
import { Icon as SemanticIcon, Grid, Divider, Button, Dropdown, Segment, Card, Label, Rating, Header, Popup, Checkbox, Sidebar, Menu } from 'semantic-ui-react';
import Action from '@components/general/Action';
import ListSelectedCandidates from '../components/lists/ListSelectedCandidates';
import StartOnboarding from '../components/forms/StartOnboarding';
import { MoveStageForm } from "../components/forms/MoveStageForm";

const HiringRoomDetailView = () => {
    const firstUpdate = useRef(true);
    const { t } = useTranslation()
    const history = useHistory()
    const params = useParams();
    const [view, setView] = useState(0)
    const [room, setRoom] = useState({})
    const [rejected, setRejected] = useState([])
    const [blacklisted, setBlacklisted] = useState([])
    const [candidates, setCandidates] = useState({})
    const [reactions, setReactions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingTable, setIsLoadingTable] = useState(true)
    const [selectedStage, setSelectedStage] = useState("all")
    const [isFetchingCandidates, setIsFetchingCandidates] = useState(true)
    const [isRejected, setIsRejected] = useState('')
    const [publishers, setPublishers] = useState('')
    const [checkedAll, setCheckedAll] = useState(false)
    const [search, setSearch] = useState("")
    const [visible, setVisible] = useState(false)
    const [stages, setStages] = useState([])

    // eslint-disable-next-line
    const [selectedCandidates, setSelectedCandidates] = useState([])

    const previous = getParams(candidates?.previous)
    const next = getParams(candidates?.next)

    useEffect(() => {
        async function fetchRecruitment() {
            setIsLoading(true)
            const request = await getRequest(API.HIRING_ROOM + params.id + "/")
            if (request.status === 200) {
                setRoom(request.response)
                setStages(request.response.archived_recruitment_stages)
            }
            setIsLoading(false)
        }

        async function fetchResources() {
            await fetchRejectedOrBlacklisted("rejected", "&query={id, profile{id}}", false)
            await fetchRejectedOrBlacklisted("blacklisted", "&query={id, profile{id}}", false)
        }

        fetchResources()
        fetchRecruitment()
        // eslint-disable-next-line
    }, [params])

    const handleAllChecked = () => {
        let isCheckedAll = !checkedAll
        let tmpList = []
        for (let i = 0; i < candidates.results.length; i++) {
            tmpList.push({
                id: candidates.results[i].id,
                profileID: candidates.results[i]?.profile?.id,
                current_stage: candidates.results[i]?.current_stage,
                profile_type: candidates.results[i]?.profile_type,
                fullname: candidates.results[i]?.fullname,
                profile_picture: candidates.results[i]?.profile_picture,
                onboardings: candidates.results[i]?.onboardings,
            })
        }
        if (isCheckedAll) {
            setSelectedCandidates(prevState => [...prevState, ...tmpList]);
        } else {
            setSelectedCandidates(prevState => prevState.filter(candidate => !tmpList.filter(item => item.id === candidate.id).length > 0))
        }
        setCheckedAll(isCheckedAll)
    }

    useEffect(() => {
        if (!isFetchingCandidates && candidates?.results?.length > 0) {
            let isAllChecked = false
            let checked = 0
            for (let i = 0; i < candidates?.results?.length; i++) {
                if (selectedCandidates.filter(item => item.id === candidates?.results[i].id).length > 0) {
                    checked += 1
                }
            }
            if (candidates?.results?.length === checked) {
                isAllChecked = true
            }

            setCheckedAll(isAllChecked)
        }
        // eslint-disable-next-line
    }, [candidates, setSelectedCandidates])

    async function fetchCandidates(queryParams) {
        queryParams = queryParams || ""
        let excludeLimiter = ""
        setIsFetchingCandidates(true)
        if (selectedStage !== "all") excludeLimiter += "&exclude_rejected=true&exclude_blacklisted=true"
        const infoLimiter = "&query={id, fullname, personal_information{id, contact_info}, onboardings{id, title, status_display}, profile_picture, current_stage, average_rating, date_of_assignment, current_stage_date, is_active, is_foreigner, profile_type}&paginate=true" + excludeLimiter
        const request = await getRequest(API.CANDIDATES + "?recruitment=" + params.id + infoLimiter + queryParams)
        if (request.status === 200) {
            setCandidates(request.response)
        }
        setIsFetchingCandidates(false)
    }

    async function reactionfilters() {
        let params = '';

        if (isRejected !== "") {
            params += "&is_rejected=" + isRejected
        }
        if (publishers !== "") {
            params += "&publishers=" + publishers
        }

        await fetchReactions(params)
    }

    async function fetchReactions(queryParams) {

        setIsLoadingTable(true)
        const request = await getRequest(API.PUBLICATION_REACTIONS + "?recruitment=" + params.id + queryParams)

        if (request.status === 200) {
            let reactionsTable = [];
            let data;
            for (let i = 0; i < request.response.length; i++) {
                if (typeof request.response[i].data === 'object') {
                    data = request.response[i].data
                } else {
                    data = parseJsonStringToObject(request.response[i].data);
                }

                reactionsTable.push({
                    id: request.response[i].id,
                    uuid: request.response[i].uuid,
                    name: data?.personal_information?.person?.firstname + ' ' + data?.personal_information?.person?.lastname,
                    phone: data?.personal_information?.contact_info?.phones?.create[0]?.phone,
                    email: data?.personal_information?.contact_info?.emails?.create[0]?.email,
                    created_on: request.response[i].created_on ? moment(request.response[i].created_on).format('YYYY-MM-DD') : '',
                    publication: request.response[i].publication.publishers_display_list,
                    gdpr: request.response[i].gdpr?.is_checked,
                    status: request.response[i].is_rejected,
                    profile: request.response[i].profile?.id
                });
            }
            setReactions(reactionsTable);
        }

        setIsLoadingTable(false)
    }

    async function undoAction(id, profileID) {
        let endpoint = ""
        if (selectedStage === "rejected") endpoint = API.CANDIDATES + "rejections/" + id + "/delete/";
        if (selectedStage === "blacklisted") endpoint = API.CANDIDATES + "blacklist_records/" + id + "/delete/";
        const request = await createRequest(endpoint, { note: "" })
        if (request.status === 204) {
            // !! TODO: update statistic counters

            // move candidate away from list
            setCandidates(prevState => ({
                ...prevState,
                results: prevState.results.filter(item => item.id !== id)
            }))

            if (selectedStage === "rejected") {
                setRejected(prevState => prevState.filter(item => item !== profileID))
            }

            if (selectedStage === "blacklisted") {
                setBlacklisted(prevState => prevState.filter(item => item !== profileID))
            }
        }
    }

    async function performSelectionAction(type) {
        let endpoint = ""
        if (type === "rejected") endpoint = API.CANDIDATES + "rejections/";
        if (type === "blacklisted") endpoint = API.CANDIDATES + "blacklist_records/";


        for (let i = 0; i < selectedCandidates.length; i++) {
            if ((rejected.filter(item => item === selectedCandidates[i].id).length === 0) && (blacklisted.filter(item => item === selectedCandidates[i].id).length === 0)) {

                let data = {
                    profile: selectedCandidates[i].id,
                    note: ""
                }

                if (type === "rejected") {
                    data['recruitment'] = params.id
                    data['rejected_by_candidate'] = false
                }

                const request = await createRequest(endpoint, data)
                if (request.status === 201) {
                    // !! TODO: update statistic counters

                    // move candidate away from list
                    if (selectedStage !== "all") {
                        setCandidates(prevState => ({
                            ...prevState,
                            results: prevState.results.filter(item => item.id !== selectedCandidates[i].id)
                        }))
                    }

                    if (type === "rejected") {
                        setRejected(prevState => [...prevState, selectedCandidates[i].id])
                    }

                    if (type === "blacklisted") {
                        setBlacklisted(prevState => [...prevState, selectedCandidates[i].id])
                    }
                }
            }
        }

        setSelectedCandidates([])
        setCheckedAll(false)
    }

    async function convertToEmployee() {

        for (let i = 0; i < selectedCandidates.length; i++) {
            if (selectedCandidates[i].current_stage.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id && selectedCandidates[i].profile_type === 1 && selectedCandidates[i].onboardings.length === 0) {
                acceptAsEmployee(selectedCandidates[i].id)
            }
        }

        setSelectedCandidates([])
        setCheckedAll(false)
    }

    async function launchOnboarding(template, deadline) {

        for (let i = 0; i < selectedCandidates.length; i++) {
            if (selectedCandidates[i].profile_type === 1) {
                acceptAsEmployee(selectedCandidates[i].id)
            }


            const requestPatch = await createRequest(API.ONBOARDING_CHECKLIST_CONFIG + template + "/instantiate/", { employee: selectedCandidates[i].id, deadline: deadline })

            if (requestPatch.status === 201) {
                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('started_onboarding'),
                    animation: 'bounce',
                    time: 5000,
                });

                setCandidates(prevState => ({
                    ...prevState,
                    results: prevState.results.filter(candidate => {
                        if (candidate.id === selectedCandidates[i].id) {
                            candidate.onboardings = requestPatch.response
                        }
                        return candidate
                    })
                })
                )
            }
            else {
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('error'),
                    description: t('employee_can_not_be_onboarded'),
                    animation: 'bounce',
                    time: 5000,
                });
            }
        }
        setSelectedCandidates([])
        setCheckedAll(false)
    }

    function removeSelectedCandidate(candidate) {
        setSelectedCandidates(prevState => prevState.filter(item => item.id !== candidate.id))
    }

    async function performSelectionUndoAction() {
        for (let i = 0; i < selectedCandidates.length; i++) {
            await undoAction(selectedCandidates[i].id, selectedCandidates[i].profileID)
        }

        setSelectedCandidates([])
        setCheckedAll(false)
    }

    async function fetchRejectedOrBlacklisted(type, queryParams, storeCandidates) {
        if (storeCandidates) setIsFetchingCandidates(true)
        let endpoint = ""
        if (type === "rejected") endpoint = API.CANDIDATES + "rejections/?recruitment=" + params.id + queryParams
        if (type === "blacklisted") endpoint = API.CANDIDATES + "blacklist_records/?assigned_in_recruitment=" + params.id + queryParams
        // const infoLimiter = "{id, fullname, personal_information{id, contact_info},profile_picture, current_stage, average_rating, date_of_assignment, current_stage_date, is_active, is_foreigner}&paginate=true"
        const request = await getRequest(endpoint)
        if (request.status === 200) {
            if (type === "rejected") setRejected(request.response.map(item => item.profile.id))
            if (type === "blacklisted") setBlacklisted(request.response.map(item => item.profile.id))
            if (storeCandidates) {
                setCandidates({
                    count: 0,
                    results: request.response
                })
            }
        }
        if (storeCandidates) setIsFetchingCandidates(false)
    }

    const closeRoom = async (id) => {
        const request = await patchRequest(API.HIRING_ROOM + params.id + "/", { is_active: false });

        if (request.status === 200) {
            history.push(routes.HIRING)
        }

    }

    const acceptAsEmployee = async (id) => {
        const request = await patchRequest(API.CANDIDATES + id + "/convert/", { profile_type: 2 });

        if (request.status === 200) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('candidate_was_accepted_as_employee'),
                animation: 'bounce',
                time: 5000,
            });
        }

        setCandidates(prevState => ({
            ...prevState,
            results: prevState.results.filter(candidate => {
                if (candidate.id === id) {
                    candidate.profile_type = request.response.profile_type
                }
                return candidate
            })
        })
        )
    }

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }

        async function fetchOnSearch() {
            await fetchCandidates("&search=" + search)
        }

        fetchOnSearch()
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        setSelectedCandidates([])
        setCheckedAll(false)
        if (selectedStage === "all") {
            fetchCandidates()
        } else if (selectedStage === "rejected") {
            fetchRejectedOrBlacklisted(selectedStage, "", true)
        } else if (selectedStage === "blacklisted") {
            fetchRejectedOrBlacklisted(selectedStage, "", true)
        } else {
            fetchCandidates("&current_stage=" + selectedStage)
        }
        // eslint-disable-next-line
    }, [selectedStage])

    const onReject = async (reactionData) => {
        const request = await patchRequest(API.PUBLICATION_REACTIONS + reactionData.uuid + "/", { is_rejected: true })

        if (request.status === 200) {
            setReactions(prevState => prevState.filter(reaction => {
                if (reaction.id === request.response.id) {
                    reaction.status = true
                }

                return reaction
            }))
        }
    }

    const onApply = async (reactionData, roomID) => {
        const request = await createRequest(API.PUBLICATION_REACTIONS + reactionData.id + "/convert_to_profile/", {})

        if (request.status === 201) {
            const requestAssign = await createRequest(API.CANDIDATES + request.response.id + "/assign_to_recruitment/", { recruitment: roomID })
            if (requestAssign.status === 201) {
                setReactions(prevState => prevState.filter(reaction => {
                    if (reaction.id === reactionData.id) {
                        reaction.profile = request.response
                    }

                    return reaction
                }))

                setRoom(prevState => ({
                    ...prevState,
                    archived_recruitment_stages: prevState.filter(item => {
                        if (item.id === room.archived_recruitment_stages[0].id) {
                            item.candidate_count += 1
                        }

                        return item
                    })
                }))
            }
        }
    }

    const handlePersonRemove = async (type, id) => {
        if (type === "manager") {
            const request = await hiringService.updateHiring(room.id, {
                hiring_managers: { remove: [id] }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    hiring_managers: room.hiring_managers.filter(item => item.id !== id)
                })
            }

        } else if (type === "recruiter") {
            const request = await hiringService.updateHiring(room.id, {
                recruiters: { remove: [id] }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    recruiters: room.recruiters.filter(item => item.id !== id)
                })
            }
        }
    }

    const handlePersonAdd = async (type, id) => {
        if (type === "manager") {
            const request = await hiringService.updateHiring(room.id, {
                hiring_managers: { add: [id] }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    hiring_managers: request.response.hiring_managers
                })
            }

        } else if (type === "recruiter") {
            const request = await hiringService.updateHiring(room.id, {
                recruiters: { add: id }
            })

            if (request.status === 200) {
                setRoom({
                    ...room,
                    recruiters: request.response.recruiters
                })
            }
        }
    }

    const StageCard = ({ stage, index, isRejected, isBlacklisted, isAll, isActive }) => {

        const handleStageOnClick = () => {
            if (isAll) {
                setSelectedStage("all")
                return
            }
            if (isRejected) {
                setSelectedStage("rejected")
                return
            }
            if (isBlacklisted) {
                setSelectedStage("blacklisted")
                return
            }

            setSelectedStage(stage.id)
            return
        }

        const IconMark = ({ icon, color, title }) => {
            return (
                <Popup content={title} position='top center' trigger={
                    <Icon name={icon} style={{ color: color, cursor: "pointer" }} />
                } />
            )
        }

        return (
            <Grid.Column mobile={16} tablet={4} computer={2}>
                <Card as='button'
                    fluid
                    onClick={() => handleStageOnClick()}
                    style={{
                        borderRadius: "0rem",
                        border: isActive ? "1px solid var(--variant3)" : "inherit",
                        borderBottom: "2px solid " + (stage.color !== "" ? stage.color : "var(--dark)"),
                        marginTop: "0.5rem",
                        marginBottom: "0.5rem",
                        cursor: "pointer",
                    }}
                >
                    <Grid>
                        <Grid.Row style={{ padding: 0 }} columns={2}>
                            <Grid.Column style={{ paddingLeft: "1rem", textAlign: "left" }}>
                                <Card.Header style={{ fontSize: "3rem" }} > {stage.candidate_count}</Card.Header>
                            </Grid.Column>
                            <Grid.Column style={{ textAlign: "right", marginTop: "1rem" }}>
                                <Icon size="large" name={stage.icon} style={{ color: (stage.color !== "" ? stage.color : "var(--dark)"), marginRight: "0.5rem" }} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ padding: 0, paddingBottom: "1rem", marginLeft: "1rem", textAlign: "left" }}>
                            <Card.Description style={{ marginTop: "1rem", }}>
                                {stage?.id === undefined && <div>&nbsp;</div>}
                                <div>{stage.name} {stage?.is_approval_required === true && <IconMark icon="information-circle-outline" title={t('approval_stage')} />}</div>
                                <div>
                                    {stage?.id &&
                                        <small style={{ color: "var(--variant3)" }}>{t('stage')} {index + 1}
                                            {(room?.archived_recruitment_stages.length === index + 1) && " (" + t('final_stage') + ")"}
                                        </small>
                                    }
                                </div>
                            </Card.Description>
                        </Grid.Row>
                    </Grid>
                </Card>
            </Grid.Column>
        )
    }

    const ManagersList = () => {
        return (
            <>
                {!isLoading &&
                    <CanView permissions={["candidates.c_view_hiring_managers"]}>
                        <Header as="h3" content={t('managers')} />
                        {room.hiring_managers?.map((item, index) => (
                            <Button
                                style={{ textAlign: "left" }}
                                fluid basic key={index}
                            >
                                <SemanticIcon.Group style={{ marginRight: "1rem" }}>
                                    <AvatarIcon size="30" src={item.profile.profile_picture} name={item.profile.fullname} />
                                </SemanticIcon.Group>
                                {item.profile.fullname}
                                <CanView permissions={["candidates.c_manage_hiring_managers"]}>
                                    <Icon
                                        name="close-outline"
                                        onClick={() => handlePersonRemove("manager", item.id)}
                                        style={{ float: "right", color: "var(--danger)", marginTop: "0.5rem" }}
                                    />
                                </CanView>
                            </Button>
                        ))}
                        <CanView permissions={["candidates.c_manage_hiring_managers"]}>
                            <SuperDuperModal
                                key={1}
                                trigger={
                                    <Button icon style={{ textAlign: "center" }} fluid basic>
                                        <Icon name='add-outline'/>
                                    </Button>
                                }
                                header={t('add_managers')}
                                size="small"
                                content={
                                    <NewManagerForm
                                        roomId={room.id}
                                        managersList={room.hiring_managers.map(manager => {
                                            return manager.profile.id
                                        })}
                                        onConfirm={
                                            (new_manager) => {
                                                handlePersonAdd("manager", new_manager.id)
                                            }
                                        }
                                    />
                                }
                            />
                        </CanView>
                    </CanView>
                }
            </>
        )
    }

    const RecruitersList = () => {
        return (
            <>
                {!isLoading &&
                    <>
                        <Header as="h3" content={t('recruiters')} />
                        {room.recruiters?.map((item, index) => (
                            <Button
                                style={{ textAlign: "left" }}
                                fluid basic key={index}
                            >
                                <SemanticIcon.Group style={{ marginRight: "1rem" }}>
                                    <AvatarIcon size="30" src={item.profile_picture} name={item.fullname} />
                                </SemanticIcon.Group>
                                {item.fullname}
                                <CanView permissions={["candidates.c_manage_recruitment"]}>
                                    <Icon
                                        name="close-outline"
                                        onClick={() => handlePersonRemove("recruiter", item.id)}
                                        style={{ float: "right", color: "var(--danger)", marginTop: "0.5rem" }}
                                    />
                                </CanView>
                            </Button>
                        ))}
                        <CanView permissions={["candidates.c_manage_recruitment"]}>
                            <SuperDuperModal
                                key={1}
                                trigger={
                                    <Button icon style={{ textAlign: "center" }} fluid basic>
                                        <Icon name='add-outline' />
                                    </Button>
                                }
                                header={t('add_recruiters')}
                                size="small"
                                content={
                                    <NewRecruitersForm
                                        roomId={room.id}
                                        recruitersList={room.recruiters?.map(item => item.id)}
                                        onConfirm={
                                            (response) => {
                                                handlePersonAdd("recruiter", response.assignee)
                                            }
                                        }
                                    />
                                }
                            />
                        </CanView>
                    </>
                }
            </>
        )
    }

    const getTargetPercent = (recruited, target) => {
        let x;
        x = recruited * 100 / target
        return Number((x).toFixed(1))
    }

    const actionUp = (candidate) => {
        const currentID = candidate?.current_stage?.id;
        return (
            <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                <Action
                    as="modal"
                    key={1}
                    type="icon"
                    className="stage-actions"
                    disabled={candidate?.current_stage?.id === stages[stages?.length - 1]?.id}
                    iconColor='var(--dark)'
                    paddingLeft="0.5rem"
                    icon="chevron-up-outline"
                    tooltip={t('stage_up')}
                    header={t('update_candidate_stage')}
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={room.id}
                            stages={stages}
                            candidateID={candidate.id}
                            isDown={false}
                            onConfirm={
                                (response) => {
                                    setCandidates(prevState => ({
                                        ...prevState,
                                        results: prevState.results.filter(cand => {
                                            if (cand.id === candidate.id) {
                                                cand.current_stage = response
                                            }
                                            return cand
                                        })
                                    }))
                                    setRoom(prevState => ({
                                        ...prevState,
                                        archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                            if (item.id === currentID) {
                                                item.candidate_count -= 1
                                            }
                                            if (item.id === response.id) {
                                                item.candidate_count += 1
                                            }

                                            return item
                                        })
                                    }))
                                    if (selectedStage !== "all") {
                                        setCandidates(prevState => ({
                                            ...prevState,
                                            results: prevState.results.filter(item => item.id !== candidate.id)
                                        }))
                                    }
                                }
                            }
                        />
                    }
                />
            </CanView>
        )
    }

    const actionDown = (candidate) => {
        const currentID = candidate?.current_stage?.id;
        return (
            <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                <Action
                    as="modal"
                    key={2}
                    type="icon"
                    className="stage-actions"
                    disabled={candidate?.current_stage?.id === stages[0]?.id}
                    iconColor='var(--dark)'
                    paddingLeft="0.5rem"
                    icon="chevron-down-outline"
                    tooltip={t('stage_down')}
                    header={t('downgrade_candidate_stage')}
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={room.id}
                            stages={stages}
                            isDown={true}
                            candidateID={candidate?.id}
                            onConfirm={
                                (response) => {
                                    setCandidates(prevState => ({
                                        ...prevState,
                                        results: prevState.results.filter(cand => {
                                            if (cand.id === candidate.id) {
                                                cand.current_stage = response
                                            }
                                            return cand
                                        })
                                    }))
                                    setRoom(prevState => ({
                                        ...prevState,
                                        archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                            if (item.id === currentID) {
                                                item.candidate_count -= 1
                                            }
                                            if (item.id === response.id) {
                                                item.candidate_count += 1
                                            }

                                            return item
                                        })
                                    }))
                                    if (selectedStage !== "all") {
                                        setCandidates(prevState => ({
                                            ...prevState,
                                            results: prevState.results.filter(item => item.id !== candidate.id)
                                        }))
                                    }
                                }
                            }
                        />
                    }
                />
            </CanView>
        )
    }

    const actionSelect = (candidate) => {
        const currentID = candidate?.current_stage?.id;
        return (
            <CanView permissions={["candidates.c_manage_candidates_in_recruitment"]}>
                <Action
                    as="modal"
                    key={2}
                    type="icon"
                    className="stage-actions"
                    iconColor='var(--dark)'
                    paddingLeft="0.5rem"
                    paddingBottom="0"
                    paddingTop="0"
                    icon="swap-vertical-outline"
                    tooltip={t('select_stage')}
                    header={t('move_candidate_to_stage')}
                    modal={
                        <MoveStageForm
                            current={candidate?.current_stage}
                            roomID={room.id}
                            jumpStage={true}
                            candidateID={candidate.id}
                            stages={stages}
                            position={room.job_position}
                            onConfirm={
                                (response) => {
                                    setCandidates(prevState => ({
                                        ...prevState,
                                        results: prevState.results.filter(cand => {
                                            if (cand.id === candidate.id) {
                                                cand.current_stage = response
                                            }
                                            return cand
                                        })
                                    }))
                                    setRoom(prevState => ({
                                        ...prevState,
                                        archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                            if (item.id === currentID) {
                                                item.candidate_count -= 1
                                            }
                                            if (item.id === response.id) {
                                                item.candidate_count += 1
                                            }

                                            return item
                                        })
                                    }))
                                    if (selectedStage !== "all") {
                                        setCandidates(prevState => ({
                                            ...prevState,
                                            results: prevState.results.filter(item => item.id !== candidate.id)
                                        }))
                                    }
                                }
                            }
                        />
                    }
                />
            </CanView>
        )
    }

    const ApprovalStatusIndicator = ({ approval }) => {
        const { t } = useTranslation()

        const IconMark = ({ icon, color, title }) => {
            return (
                <Popup content={title} position='top center' trigger={
                    <Icon name={icon} style={{ color: color, cursor: "pointer" }} />
                } />
            )
        }

        return (
            <span style={{ marginLeft: "0.5rem" }}>
                <>
                    {approval === null && ""}
                    {approval?.is_approved === null && <IconMark icon="help-circle-outline" color="var(--warning)" title={t('awaiting_approval')} />}
                    {approval?.is_approved === true && <IconMark icon="checkmark-circle-outline" color="var(--success)" title={t('approved')} />}
                    {approval?.is_approved === false && <IconMark icon="warning circle" color="var(--danger)" title={t('denied')} />}
                </>
            </span>
        )
    }

    return (
        <>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column computer={8} tablet={8} mobile={16}>
                        <Button.Group size="small" style={{ borderRadius: "0" }} basic>
                            <Button active={view === 0} onClick={() => { setView(0); setSelectedStage("all") }}> {t('recruitment')} </Button>
                            <Button active={view === 1} onClick={() => setView(1)}> {t('reactions')} </Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={8} mobile={16} style={{ textAlign: "right" }}>
                        <CanView permissions={['candidates.c_manage_recruitment']}>
                            <Button.Group size="small" style={{ borderRadius: '0' }}>
                                <Button.Group>
                                    <Button style={{ background: "var(--dark)", color: "var(--white)", cursor: "default" }}>
                                        {t('manage_hiring_room')}
                                    </Button>
                                    <Dropdown
                                        simple
                                        style={{ background: "var(--dark-hover)", color: "var(--white)", marginRight: "0.5rem" }}
                                        className='button icon'
                                        floating
                                        trigger={<Icon name="settings-outline" style={{ marginRight: "0.5rem" }} />}
                                    >
                                        <Dropdown.Menu style={{ marginTop: "0", zIndex: 1000 }}>
                                            <SuperDuperModal
                                                header={t('edit_room')}
                                                size="small"
                                                trigger={
                                                    <Dropdown.Item>
                                                        <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/> 
                                                        <span style={{ position: "relative", top: "-0.2rem" }}>
                                                            { t('edit_hiring_room') }
                                                        </span>
                                                    </Dropdown.Item>
                                                }
                                                content={
                                                    <LaunchNewRoomForm
                                                        room={room}
                                                        btnName={t('confirm')}
                                                        onConfirm={(response) => setRoom(response)}
                                                    />
                                                }
                                            />

                                            <Dropdown.Item onClick={() => setVisible(true)}>
                                                <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/>
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    { t('manage_recruiters') }
                                                </span>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => setVisible(true)}>
                                                <Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/>
                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                    { t('manage_managers') }
                                                </span>
                                            </Dropdown.Item>
                                            {room.is_active &&
                                                <ConfirmModal
                                                    button={
                                                        <Dropdown.Item style={{ background: "var(--danger)", color: "var(--white)" }}>
                                                            <Icon name="close-outline" style={{ marginRight: "0.5rem" }}/>
                                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                { t('end_hiring') }
                                                            </span>
                                                        </Dropdown.Item>
                                                    }
                                                    description={t('end_hiring_message')}
                                                    onConfirm={() => closeRoom(room.id)}
                                                />
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Button.Group>
                                <SuperDuperModal
                                    trigger={
                                        <Button size="small" primary> {t('add_candidate')} </Button>
                                    }
                                    header={t('add_candidate')}
                                    size="small"
                                    content={
                                        <NewCandidateForm
                                            roomId={params.id}
                                            onConfirm={(response) => {
                                                if (selectedStage === "all" || selectedStage === room.archived_recruitment_stages[0].id) {
                                                    setCandidates({
                                                        ...candidates,
                                                        results: [...candidates.results, response]
                                                    })
                                                    setRoom({
                                                        ...room,
                                                        candidate_count: room.candidate_count + 1,
                                                        archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                                            if (item.id === selectedStage) {
                                                                item.candidate_count = item.candidate_count + 1
                                                            } else if (item.id !== selectedStage && item.id === room.archived_recruitment_stages[0].id) {
                                                                item.candidate_count = item.candidate_count + 1
                                                            }
                                                            return item
                                                        })
                                                    })
                                                } else {
                                                    setRoom({ ...room, candidate_count: room.candidate_count + 1 })
                                                }
                                            }}
                                        />
                                    }
                                />
                            </Button.Group>
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <Sidebar.Pushable as={Segment} style={{ boxShadow: "none", border: "none", marginTop: 0 }} className="fixed-sidebar">
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    icon='labeled'
                    vertical
                    visible={visible}
                    width='wide'
                >
                    <Grid.Column computer={4} tablet={8} mobile={16} textAlign="left">
                        <div style={{ padding: "1rem", width: "100%" }}>
                            <Button fluid type="button" size="small" content={t("close")} onClick={() => setVisible(false)} />
                            <ManagersList />
                            <RecruitersList />
                        </div>
                    </Grid.Column>
                </Sidebar>

                <Sidebar.Pusher>
                    {view === 0 &&
                        <>
                            {!isLoading &&
                                <Grid>
                                    <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                                        <Grid.Column computer={10} tablet={10} mobile={16}>
                                            <Header as="h2" content={room.name} style={{ marginBottom: 0 }} />
                                            <small style={{ color: "var(--variant1)" }}> {t('hiring_room')} </small>
                                        </Grid.Column>
                                        <Grid.Column computer={6} tablet={6} mobile={16} style={{ textAlign: "right" }}>
                                            <FlexRow padding="0" background="transparent">
                                                <FlexItem>
                                                    <Popup
                                                        inverted
                                                        position="left center"
                                                        content={t('start_date')}
                                                        trigger={
                                                            <div style={{ marginBottom: "1rem" }}>
                                                                <Icon name="calendar-clear-outline" style={{ marginRight: "0.5rem" }}/> 
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {room.date_from !== null ? moment(room.date_from).format('DD.MM.YYYY') : "--"}
                                                                </span>
                                                            </div>
                                                        }
                                                    />
                                                    <Popup
                                                        inverted
                                                        position="left center"
                                                        content={t('end_date')}
                                                        trigger={
                                                            <div style={{ marginBottom: "1rem" }}>
                                                                <Icon name="calendar-outline" style={{ marginRight: "0.5rem" }}/>
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {room.date_to !== null ? moment(room.date_to).format('DD.MM.YYYY') : "--"}
                                                                </span>
                                                            </div>
                                                        }
                                                    />
                                                    <Popup
                                                        inverted
                                                        position="left center"
                                                        trigger={
                                                            <div>
                                                                <Icon name="bar-chart-outline" style={{ marginRight: "0.5rem" }}/> 
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {room.target ? <> {t('recruited')}: {room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1].candidate_count + '/' + room.target + ' - ' + getTargetPercent(room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1].candidate_count, room.target) + '%'} </> : "--"}
                                                                </span>
                                                            </div>
                                                        }
                                                        content={t('target')}
                                                    />
                                                </FlexItem>
                                                <FlexItem>
                                                    <Popup
                                                        inverted
                                                        position="left center"
                                                        trigger={
                                                            <div style={{ marginBottom: "1rem" }}>
                                                                <Icon name="briefcase-outline" style={{ marginRight: "0.5rem" }}/>
                                                                <span style={{ position: "relative", top: "-0.2rem"}}>
                                                                    {room.job_position.title}
                                                                </span>
                                                            </div>
                                                        }
                                                        content={t('position')}
                                                    />
                                                    <Popup
                                                        inverted
                                                        position="left center"
                                                        trigger={
                                                            <div>
                                                                <Icon name="list-outline" style={{ marginRight: "0.5rem" }}/> 
                                                                <span tyle={{ position: "relative", top: "-0.2rem"}}>
                                                                    {room.hrorder?.id ? <> {t('order_number')}: {room.hrorder?.order_number} </> : "--"}
                                                                </span>
                                                            </div>
                                                        }
                                                        content={t('order')}
                                                    />
                                                </FlexItem>
                                            </FlexRow>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            }

                            <Divider />

                            <Segment loading={isLoading} style={{ padding: 0, margin: 0, minHeight: "2rem", backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
                                {!isLoading &&
                                    <Grid stretched>
                                        <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                                            <StageCard
                                                isActive={selectedStage === "all"}
                                                isAll
                                                stage={{
                                                    icon: "people-outline",
                                                    color: "var(--dark)",
                                                    name: t('all'),
                                                    candidate_count: room.candidate_count
                                                }}
                                            />

                                            {room.archived_recruitment_stages.map((stage, index) => (
                                                <StageCard
                                                    isActive={selectedStage === stage.id}
                                                    stage={stage}
                                                    index={index}
                                                    key={stage.id}
                                                />
                                            ))}

                                            <StageCard
                                                isActive={selectedStage === "rejected"}
                                                isRejected
                                                stage={{
                                                    icon: 'close-circle-outline',
                                                    color: "var(--danger)",
                                                    name: t('rejected'),
                                                    candidate_count: rejected.length
                                                }}
                                            />
                                            <StageCard
                                                isActive={selectedStage === "blacklisted"}
                                                isBlacklisted
                                                stage={{
                                                    icon: 'warning-outline',
                                                    color: "var(--dark)",
                                                    name: t('blacklisted'),
                                                    candidate_count: blacklisted.length
                                                }}
                                            />
                                        </Grid.Row>
                                    </Grid>
                                }
                            </Segment>
                            <Divider />

                            <Grid>
                                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                                    <Grid.Column computer={12} tablet={16} mobile={16}>
                                        <Button.Group>
                                            <Dropdown
                                                simple
                                                item
                                                style={{ background: "var(--dark)", color: "var(--white)" }}
                                                className='button icon'
                                                direction="right"
                                                trigger={<>{t('manage_candidates')} </>}
                                            >
                                                <Dropdown.Menu style={{ marginTop: "0", zIndex: 1000 }}>
                                                    {/* <SuperDuperModal
                                                        size="small"
                                                        trigger={
                                                            <Dropdown.Item disabled>
                                                                <Icon name="comment alternate"/> { t('send_sms') }
                                                            </Dropdown.Item>
                                                        }
                                                        content={
                                                            <div>Send sms...</div>
                                                        }
                                                    />
                                                    <SuperDuperModal
                                                        size="small"
                                                        trigger={
                                                            <Dropdown.Item disabled>
                                                                <Icon name="envelope"/> { t('send_email') }
                                                            </Dropdown.Item>
                                                        }
                                                        content={
                                                            <div>Send email...</div>
                                                        }
                                                    /> */}

                                                    {selectedStage === "rejected"
                                                        ?
                                                        <Dropdown.Item
                                                            disabled={selectedCandidates.length === 0}
                                                            onClick={() => performSelectionUndoAction()}
                                                        >
                                                            <Icon name="arrow-undo-outline" style={{ color: "var(--danger)", marginRight: "0.5rem" }} />
                                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                {t('undo_rejection')}
                                                            </span>
                                                        </Dropdown.Item>
                                                        : selectedStage === "blacklisted" ? "" :
                                                            <Dropdown.Item
                                                                disabled={selectedCandidates.length === 0}
                                                                onClick={() => performSelectionAction("rejected")}
                                                            >
                                                                <Icon name="close-circle-outline" style={{ color: "var(--danger)", marginRight: "0.5rem" }} />
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {t('reject_candidate')}
                                                                </span>
                                                            </Dropdown.Item>
                                                    }

                                                    {selectedStage === "blacklisted"
                                                        ?
                                                        <Dropdown.Item
                                                            disabled={selectedCandidates.length === 0}
                                                            onClick={() => performSelectionUndoAction()}
                                                        >
                                                            <Icon name="arrow-undo-outline" style={{ marginRight: "0.5rem" }}/> 
                                                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                {t('undo_blacklist')}
                                                            </span>
                                                        </Dropdown.Item>
                                                        : selectedStage === "rejected" ? "" :
                                                            <Dropdown.Item
                                                                disabled={selectedCandidates.length === 0}
                                                                onClick={() => performSelectionAction("blacklisted")}
                                                            >
                                                                <Icon name="warning-outline" style={{ marginRight: "0.5rem" }}/> 
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {t('blacklist_candidate')}
                                                                </span>
                                                            </Dropdown.Item>
                                                    }
                                                    <SuperDuperModal
                                                        size="small"
                                                        header={t('update_candidate_stage')}
                                                        trigger={
                                                            <Dropdown.Item
                                                                disabled={selectedCandidates.length === 0}
                                                            >
                                                                <Icon name="chevron-up-outline" style={{ marginRight: "0.5rem" }}/> 
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {t('update_candidate_stage')}
                                                                </span>
                                                            </Dropdown.Item>
                                                        }
                                                        content={
                                                            <MoveStageForm
                                                                roomID={room.id}
                                                                stages={stages}
                                                                isDown={false}
                                                                selectedCandidates={selectedCandidates}
                                                                onConfirm={
                                                                    (candidate, current, stageTo, response) => {
                                                                        setSelectedCandidates([])
                                                                        setCheckedAll(false)
                                                                        if (selectedStage !== "all") {
                                                                            setCandidates(prevState => ({
                                                                                ...prevState,
                                                                                results: prevState.results.filter(item => item.id !== candidate)
                                                                            }))
                                                                        }
                                                                        setCandidates(prevState => ({
                                                                            ...prevState,
                                                                            results: prevState.results.filter(cand => {
                                                                                if (cand.id === candidate) {
                                                                                    cand.current_stage = response
                                                                                }
                                                                                return cand
                                                                            })
                                                                        }))
                                                                        setRoom(prevState => ({
                                                                            ...prevState,
                                                                            archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                                                                if (item.id === current) {
                                                                                    item.candidate_count -= 1
                                                                                }
                                                                                if (item.id === stageTo) {
                                                                                    item.candidate_count += 1
                                                                                }

                                                                                return item
                                                                            })
                                                                        }))
                                                                    }
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <SuperDuperModal
                                                        size="small"
                                                        header={t('downgrade_candidate_stage')}
                                                        trigger={
                                                            <Dropdown.Item
                                                                disabled={selectedCandidates.length === 0}
                                                            >
                                                                <Icon name="chevron-down-outline" style={{ marginRight: "0.5rem" }}/>
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {t('downgrade_candidate_stage')}
                                                                </span>
                                                            </Dropdown.Item>
                                                        }
                                                        content={
                                                            <MoveStageForm
                                                                roomID={room.id}
                                                                stages={stages}
                                                                isDown={true}
                                                                selectedCandidates={selectedCandidates}
                                                                onConfirm={
                                                                    (candidate, current, stageTo, response) => {
                                                                        setSelectedCandidates([])
                                                                        setCheckedAll(false)
                                                                        if (selectedStage !== "all") {
                                                                            setCandidates(prevState => ({
                                                                                ...prevState,
                                                                                results: prevState.results.filter(item => item.id !== candidate)
                                                                            }))
                                                                        }
                                                                        setCandidates(prevState => ({
                                                                            ...prevState,
                                                                            results: prevState.results.filter(cand => {
                                                                                if (cand.id === candidate) {
                                                                                    cand.current_stage = response
                                                                                }
                                                                                return cand
                                                                            })
                                                                        }))
                                                                        setRoom(prevState => ({
                                                                            ...prevState,
                                                                            archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                                                                if (item.id === current) {
                                                                                    item.candidate_count -= 1
                                                                                }
                                                                                if (item.id === stageTo) {
                                                                                    item.candidate_count += 1
                                                                                }

                                                                                return item
                                                                            })
                                                                        }))
                                                                    }
                                                                }
                                                            />
                                                        }
                                                    />
                                                    <SuperDuperModal
                                                        size="small"
                                                        header={t('move_candidate_to_stage')}
                                                        trigger={
                                                            <Dropdown.Item
                                                                disabled={selectedCandidates.length === 0}
                                                            >
                                                                <Icon name="swap-vertical-outline" style={{ marginRight: "0.5rem" }}/> 
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    {t('move_candidate_to_stage')}
                                                                </span>
                                                            </Dropdown.Item>
                                                        }
                                                        content={
                                                            <MoveStageForm
                                                                roomID={room.id}
                                                                stages={stages}
                                                                jumpStage={true}
                                                                selectedCandidates={selectedCandidates}
                                                                onConfirm={
                                                                    (candidate, current, stageTo, response) => {
                                                                        setSelectedCandidates([])
                                                                        setCheckedAll(false)
                                                                        if (selectedStage !== "all") {
                                                                            setCandidates(prevState => ({
                                                                                ...prevState,
                                                                                results: prevState.results.filter(item => item.id !== candidate)
                                                                            }))
                                                                        }
                                                                        setCandidates(prevState => ({
                                                                            ...prevState,
                                                                            results: prevState.results.filter(cand => {
                                                                                if (cand.id === candidate) {
                                                                                    cand.current_stage = response
                                                                                }
                                                                                return cand
                                                                            })
                                                                        }))
                                                                        setRoom(prevState => ({
                                                                            ...prevState,
                                                                            archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                                                                if (item.id === current) {
                                                                                    item.candidate_count -= 1
                                                                                }
                                                                                if (item.id === stageTo) {
                                                                                    item.candidate_count += 1
                                                                                }

                                                                                return item
                                                                            })
                                                                        }))
                                                                    }
                                                                }
                                                            />
                                                        }
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id === selectedStage ?
                                                <Dropdown
                                                    simple
                                                    style={{ background: "var(--primary)", color: "var(--white)" }}
                                                    className='button icon'
                                                    floating
                                                    direction='right'
                                                    trigger={<>{t('launch_process')} </>}
                                                >
                                                    <Dropdown.Menu direction='right' style={{ marginTop: "0", zIndex: 1000 }}>
                                                        <SuperDuperModal
                                                            size="small"
                                                            header={t('list_of_selected_candidates')}
                                                            trigger={
                                                                <Dropdown.Item
                                                                    disabled={selectedCandidates.length === 0}
                                                                >
                                                                    <Icon name="id-card-outline" /> {t('accept_as_employee')}
                                                                </Dropdown.Item>
                                                            }
                                                            content={
                                                                <ListSelectedCandidates
                                                                    type={'convert'}
                                                                    room={room}
                                                                    convertToEmployee={convertToEmployee}
                                                                    launchOnboarding={launchOnboarding}
                                                                    selectedCandidates={selectedCandidates}
                                                                    removeSelectedCandidate={removeSelectedCandidate}
                                                                />
                                                            }
                                                        />
                                                        <SuperDuperModal
                                                            size="small"
                                                            header={t('list_of_selected_candidates')}
                                                            trigger={
                                                                <Dropdown.Item
                                                                    disabled={selectedCandidates.length === 0}
                                                                >
                                                                    <Icon name="list-outline" /> {t('start_onboarding')}
                                                                </Dropdown.Item>
                                                            }
                                                            content={
                                                                <ListSelectedCandidates
                                                                    type={'onboarding'}
                                                                    room={room}
                                                                    convertToEmployee={convertToEmployee}
                                                                    launchOnboarding={launchOnboarding}
                                                                    selectedCandidates={selectedCandidates}
                                                                    removeSelectedCandidate={removeSelectedCandidate}
                                                                />
                                                            }
                                                        />
                                                        {/* <SuperDuperModal
                                                            size="small"
                                                            header={t('list_of_selected_candidates')}
                                                            trigger={
                                                                <Dropdown.Item
                                                                    disabled={selectedCandidates.length === 0}
                                                                >
                                                                    <Icon name="chevron up" /> {t('update_candidate_stage')}
                                                                </Dropdown.Item>
                                                            }
                                                            content={
                                                                <MoveStageForm
                                                                    roomID={room.id}
                                                                    stages={stages}
                                                                    isDown={false}
                                                                    selectedCandidates={selectedCandidates}
                                                                // onConfirm={
                                                                //     (response) => {
                                                                //         setCandidates(prevState => ({
                                                                //             ...prevState,
                                                                //             results: prevState.results.filter(cand => {
                                                                //                 if (cand.id === candidate.id) {
                                                                //                     cand.current_stage = response
                                                                //                 }
                                                                //                 return cand
                                                                //             })
                                                                //         }))
                                                                //         setRoom(prevState => ({
                                                                //             ...prevState,
                                                                //             archived_recruitment_stages: room.archived_recruitment_stages.filter(item => {
                                                                //                 if (item.id === currentID) {
                                                                //                     item.candidate_count -= 1
                                                                //                 }
                                                                //                 if (item.id === response.id) {
                                                                //                     item.candidate_count += 1
                                                                //                 }

                                                                //                 return item
                                                                //             })
                                                                //         }))
                                                                //         setCandidates(prevState => ({
                                                                //             ...prevState,
                                                                //             results: prevState.results.filter(item => item.id !== candidate.id)
                                                                //         }))
                                                                //     }
                                                                // }
                                                                />
                                                            }
                                                        /> */}
                                                        {/* <Dropdown.Item>
                                                            <Icon name="file alternate" /> {t('contract_preparation')}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item disabled>
                                                            <Icon name="file alternate" /> {t('documents_preparation_foreigners_only')}
                                                        </Dropdown.Item> */}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                :
                                                <Dropdown />
                                            }

                                        </Button.Group>
                                    </Grid.Column>
                                    <Grid.Column style={{ textAlign: "right" }} computer={4} tablet={16} mobile={16}>
                                        {(selectedStage !== "rejected" && selectedStage !== "blacklisted") &&
                                            <SuperField as="input"
                                                iconPosition="right"
                                                icon="search"
                                                placeholder={t('search')}
                                                value={search}
                                                onChange={(e, { value }) => setSearch(value)}
                                            />
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />
                            <Segment loading={isFetchingCandidates} style={{ padding: 0, margin: 0, minHeight: "2rem", backgroundColor: "transparent", border: "none", boxShadow: "none", marginBottom: "2rem" }}>
                                {!isFetchingCandidates &&
                                    <>
                                        <FlexTable>
                                            <FlexRow>
                                                <FlexHeader basis="10%" content={
                                                    <Checkbox
                                                        checked={checkedAll}
                                                        onClick={() => handleAllChecked()}
                                                    />
                                                } />
                                                <FlexHeader content={t('name')} />

                                                {/* Remove for rejected */}
                                                {selectedStage === "rejected" || selectedStage === "blacklisted"
                                                    ?
                                                    <>
                                                        <FlexHeader content={t('last_stage')} />
                                                        <FlexHeader content={t('note')} />
                                                        <FlexHeader content={t('created_on')} />
                                                    </>
                                                    :
                                                    <>
                                                        <FlexHeader content={t('contact')} basis="80%" />
                                                        <FlexHeader content='' basis="10%" />
                                                        <FlexHeader content={t('stage')} basis="120%" />
                                                        <FlexHeader content={t('applied')} basis="50%" />
                                                        <FlexHeader content={t('status')} basis="50%" />
                                                    </>

                                                }
                                                <FlexHeader content={t('actions')} basis="30%" />
                                            </FlexRow>
                                            <EmptyRow length={candidates.results.length} />
                                            {candidates.results.map(candidate => (
                                                <FlexRow key={candidate.id} fontSize="0.9rem" borders>
                                                    <FlexItem
                                                        bold
                                                        basis="10%"
                                                        content={
                                                            <Checkbox
                                                                checked={selectedCandidates.filter(item => item.id === candidate.id).length > 0}
                                                                onClick={() => {
                                                                    if (selectedCandidates.filter(item => item.id === candidate.id).length > 0) {
                                                                        setSelectedCandidates(prevState => prevState.filter(item => item.id !== candidate.id))
                                                                    } else {
                                                                        setSelectedCandidates(prevState => [...prevState, {
                                                                            id: candidate.id,
                                                                            profileID: candidate?.profile?.id,
                                                                            current_stage: candidate?.current_stage,
                                                                            profile_type: candidate?.profile_type,
                                                                            fullname: candidate?.fullname,
                                                                            profile_picture: candidate?.profile_picture,
                                                                            onboardings: candidate?.onboardings,
                                                                        }])
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    />

                                                    <FlexItem content={
                                                        <FlexRow padding="0" background="transparent">
                                                            <FlexItem basis="20%">
                                                                <AvatarIcon
                                                                    size={35}
                                                                    name={(selectedStage === "rejected" || selectedStage === "blacklisted") ? candidate?.profile?.fullname || "-" : candidate?.fullname || "-"}
                                                                    src={(selectedStage === "rejected" || selectedStage === "blacklisted") ? candidate?.profile?.profile_picture : candidate?.profile_picture}
                                                                />
                                                            </FlexItem>
                                                            <FlexItem bold>
                                                                {(selectedStage === "rejected" || selectedStage === "blacklisted") ? candidate?.profile?.fullname : candidate?.fullname}
                                                                {candidate.current_stage && <ApprovalStatusIndicator approval={candidate.current_stage.approval} />}
                                                                <div>
                                                                    <small>
                                                                        <Rating rating={candidate.average_rating} maxRating={5} disabled />
                                                                        {candidate.average_rating ? candidate.average_rating : ' 0.0'}
                                                                    </small>
                                                                </div>
                                                                <small>
                                                                    <>
                                                                        {(selectedStage === "rejected" || selectedStage === "blacklisted"
                                                                            ? (rejected.filter(item => item === candidate?.profile?.id).length > 0) && <div style={{ color: "var(--danger)", fontWeight: "bold", marginTop: "0.5rem" }}> <Icon name="close-circle-outline" /> {t('rejected')}</div>
                                                                            : (rejected.filter(item => item === candidate?.id).length > 0) && <div style={{ color: "var(--danger)", fontWeight: "bold", marginTop: "0.5rem" }}> <Icon name="close-circle-outline" /> {t('rejected')}</div>
                                                                        )}
                                                                    </>
                                                                    <>
                                                                        {(selectedStage === "blacklisted" || selectedStage === "rejected"
                                                                            ? (blacklisted.filter(item => item === candidate?.profile?.id).length > 0) && <div style={{ color: "var(--danger)", fontWeight: "bold", marginTop: "0.5rem" }}> <Icon name="warning-outline" /> {t('blacklisted')}</div>
                                                                            : (blacklisted.filter(item => item === candidate?.id).length > 0) && <div style={{ color: "var(--danger)", fontWeight: "bold", marginTop: "0.5rem" }}> <Icon name="warning-outline" /> {t('blacklisted')}</div>
                                                                        )}
                                                                    </>
                                                                </small>
                                                            </FlexItem>
                                                        </FlexRow>
                                                    } />

                                                    {selectedStage === "rejected" || selectedStage === "blacklisted"
                                                        ?
                                                        <>
                                                            <FlexItem>
                                                                {candidate.last_stage !== null ?
                                                                    <Label
                                                                        size="medium"
                                                                        style={{ background: candidate?.last_stage?.color, color: "var(--white)" }}
                                                                    >
                                                                        {candidate?.last_stage?.name}
                                                                    </Label>
                                                                    : "--"
                                                                }
                                                            </FlexItem>
                                                            <FlexItem content={candidate.note} />
                                                            <FlexItem content={candidate.created_on} />
                                                        </>
                                                        :
                                                        <>
                                                            <FlexItem basis="80%" content={
                                                                <ContactCell row={{
                                                                    phone: candidate?.personal_information?.contact_info?.phones[0]?.phone,
                                                                    email: candidate?.personal_information?.contact_info?.emails[0]?.email
                                                                }} />
                                                            } />

                                                            {/* stage with action */}
                                                            <FlexItem basis="10%">
                                                                {actionUp(candidate)}
                                                                <br />
                                                                {actionSelect(candidate)}
                                                                <br />
                                                                {actionDown(candidate)}
                                                            </FlexItem>
                                                            <FlexItem basis="120%">
                                                                <Label
                                                                    size="medium"
                                                                    style={{ background: candidate?.current_stage?.color, color: "var(--white)" }}
                                                                >
                                                                    {candidate?.current_stage?.name}
                                                                </Label>
                                                                <br />
                                                                <small> {moment(candidate?.current_stage_date).format("DD.MM.YYYY HH:mm")} </small>
                                                            </FlexItem>

                                                            <FlexItem basis="50%" content={moment(candidate.date_of_assignment).format("DD.MM.YYYY")} />
                                                            <FlexItem basis="50%" content={
                                                                <div>
                                                                    {candidate.profile_type === 1 && candidate.current_stage.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id ?
                                                                        t('non_converted_candidate')
                                                                        : candidate.profile_type === 2 && candidate.current_stage.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id && candidate.onboardings.length !== 0 ?
                                                                            t('onboarding') + ' - ' + candidate?.onboardings[0]?.status_display
                                                                            : candidate.profile_type === 2 && candidate.current_stage.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id && candidate.onboardings.length === 0 ?
                                                                                t('converted')
                                                                                :
                                                                                candidate?.profile_type === 1
                                                                                && <div style={{ fontWeight: 'lighter', fontSize: 12 }}>{t('candidate')}</div>
                                                                    }

                                                                </div>
                                                            } />

                                                        </>
                                                    }
                                                    <FlexItem basis="30%" content={
                                                        <>
                                                            {(selectedStage === "rejected" || selectedStage === "blacklisted") &&
                                                                <ConfirmModal
                                                                    button={
                                                                        <Icon name="redo" style={{ color: "var(--dark)", paddingRight: '1.5rem' }} />
                                                                    }
                                                                    description={selectedStage === "rejected" ? t('rejected_message_delete') : t('blacklisted_message_delete')}
                                                                    onConfirm={() => undoAction(candidate.id, candidate.profile.id)}
                                                                />
                                                            }
                                                            <Button.Group>
                                                                <Action type='icon' as="link" to={routes.HIRING_ROOM + params.id + routes.CANDIDATES_DETAIL + candidate.id} tooltip={t('view_detail')} />

                                                                <Action
                                                                    as="modal"
                                                                    type="icon"
                                                                    icon="albums-outline"
                                                                    iconColor="grey"
                                                                    tooltip={t('history')}
                                                                    modal={
                                                                        <FullHistoryLog room={params.id} candidate={candidate.id} />
                                                                    }
                                                                />
                                                                {room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id === selectedStage && candidate.current_stage.id === room?.archived_recruitment_stages?.[room?.archived_recruitment_stages.length - 1]?.id &&
                                                                    <Dropdown
                                                                        icon='ellipsis vertical'
                                                                        floating
                                                                        button
                                                                        style={{ background: "none", margin: 0, paddingLeft: 0, paddingRight: 0 }}
                                                                    >
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Header content='Choose action' />
                                                                            <Dropdown.Divider />

                                                                            <ConfirmModal
                                                                                button={
                                                                                    <Dropdown.Item
                                                                                        disabled={candidate.profile_type !== 1}
                                                                                    >
                                                                                        <Icon name="id-card-outline" /> {t('accept_as_employee')}
                                                                                    </Dropdown.Item>
                                                                                }
                                                                                description={t('confirm_as_employee')}
                                                                                onConfirm={() => acceptAsEmployee(candidate.id)}
                                                                            />

                                                                            <SuperDuperModal
                                                                                size="small"
                                                                                trigger={
                                                                                    <Dropdown.Item>
                                                                                        <Icon name="list-outline" /> {t('start_onboarding')}
                                                                                    </Dropdown.Item>
                                                                                }
                                                                                content={
                                                                                    <StartOnboarding id={candidate.id} setCandidates={setCandidates} acceptAsEmployee={acceptAsEmployee} converted={candidate.profile_type === 1 ? 'no' : 'yes'} />
                                                                                }
                                                                            />

                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                }

                                                            </Button.Group>
                                                        </>
                                                    } />
                                                </FlexRow>
                                            ))}
                                        </FlexTable>

                                        {/* Pagination */}
                                        {candidates.count > 10 &&
                                            <FlexRow>
                                                <FlexItem basis="50%">
                                                    <Button.Group>
                                                        <Button disabled={previous === null} primary onClick={previous !== null ? () => fetchCandidates(previous) : null}> <Icon name="chevron-back-outline" /> </Button>
                                                        <Button disabled={next === null} primary onClick={next !== null ? () => fetchCandidates(next) : null}> <Icon name="chevron-forward-outline" /> </Button>
                                                    </Button.Group>
                                                </FlexItem>
                                            </FlexRow>
                                        }
                                    </>
                                }
                            </Segment>
                        </>
                    }

                    {view === 1 &&
                        <ReactionsTable
                            data={reactions}
                            roomID={params.id}
                            filters={() => reactionfilters()}
                            isRejected={isRejected}
                            setIsRejected={setIsRejected}
                            publishers={publishers}
                            setPublishers={setPublishers}
                            setCandidates={setCandidates}
                            loading={isLoadingTable}
                            onReject={onReject}
                            onApply={onApply}
                        />
                    }
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    );
};

export default HiringRoomDetailView;