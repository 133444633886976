import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
// store
import { API } from "@store/config";
import { getRequest } from '@services/ServiceCommon';
import { useFetchData, useQueryPage } from '@store/services/helpers/hooks'
//components
import Paginator from '@components/Paginator';
import { Button, Grid, Container, Form } from 'semantic-ui-react';
import OutlineAddButton from '@components/buttons/OutlineAddButton';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Loading from '@components/general/Loading';
import ContactForm from '../components/ContactForm'
import ContactTable from '../components/ContactTable';
import SuperField from '@components/forms/SuperField';

const ContactsListing = ({ openDetail }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()
    let page = useQueryPage()

    const [limit, setLimit] = useState(10)
    const [{ data, isLoading }, setContacts] = useFetchData(API.CONTACTS + "?paginate=true&page=" + page + "&limit=" + limit)
    const [filters, setFilters] = useState({
        contactType: 0,
        labels: [],
        search: "",
    })

    const fetchContacts = async (params, isFiltered) => {
        setContacts((prev) => ({ ...prev, isLoading: true }))

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        } 

        const request = await getRequest(API.CONTACTS + "?paginate=true" + params)
        if (request !== undefined) {
            if (request.status === 200) {
                setContacts((prev) => ({ ...prev, data: request.response }))
            }
        }
        setContacts((prev) => ({ ...prev, isLoading: false }))
    }

    const onFilter = async (params, isFiltered) => {
        params += "&PersonContact_person_name=" + filters.search

        if (filters.contactType > 0) params += "&contact_type=" + filters.contactType
        if( filters.labels.length > 0 ){
            for (let i = 0; i < filters.labels.length; i++) {
                params += "&roles_label="+ filters.labels[i]
            }
        }

        await fetchContacts(params, isFiltered)
    }

    return (
        <>
            <Grid>
                <Grid.Column mobile={16} tablet={16} computer={10}>
                    <Form onSubmit={async () => {
                        history.replace({ pathname: location.pathname, search: `?page=${1}` });
                        await onFilter("", true)
                    }}>
                        <Form.Group widths="equal">
                            <SuperField as="choice"
                                label={ t('type')}
                                value={ filters.contactType }
                                onChange={(e, { value }) => {
                                    if( value === "" ) value = 0
                                    setFilters({ ...filters, contactType: value })
                                }}
                                customOptions={[
                                    { key: 0, value: 0, text: t('all') },
                                    { key: 2, value: 2, text: t('candidates') },
                                    { key: 3, value: 3, text: t('employees') },
                                    { key: 4, value: 4, text: t('accounts') },
                                    { key: 1, value: 1, text: t('custom') },
                                ]}
                            />
                            <SuperField as="choice"
                                multiple 
                                search
                                label={ t('labels')}
                                value={ filters.labels }
                                onChange={(e, { value }) => setFilters({ ...filters, labels: value })}
                                endpoint={API.CONTACT_LABELS}
                                text="name"
                            />
                            <SuperField as="input" 
                                icon="search" 
                                label={ t('search')}
                                placeholder={ t('search')}
                                value={ filters.search }
                                onChange={(e, { value }) => setFilters({ ...filters, search: value })}
                            />
                            <Form.Field style={{ marginTop: "1.8rem" }}>
                                <Button primary content={t('confirm')}/>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={6}>
                    <SuperDuperModal
                        size="small"
                        trigger={
                            <OutlineAddButton color="violet" text={t('add_contact')} size="small" style={{ float: "right", marginTop: "1.8rem" }} />
                        }
                        content={
                            <ContactForm setContacts={setContacts} />
                        }
                    />
                </Grid.Column>
            </Grid>
            <Container fluid>
                {isLoading ?
                    <Loading /> :
                    <ContactTable data={data} setContacts={setContacts} openDetail={openDetail} />
                }
                <Paginator
                    forcePage={page || 1}
                    limit={limit}
                    setLimit={setLimit}
                    length={data?.count || 0}
                    onChange={(params) => onFilter(params)}
                />
            </Container>
        </>
    )
}

export default ContactsListing
