import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
// components
import Loading from '@components/general/Loading';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Segment, Divider } from 'semantic-ui-react';

const ComponentChoices = ({ selected, setSelected }) => {
    const { t } = useTranslation();

    // eslint-disable-next-line
    const [result, setResult] = useFetchData(API.APPLICATION + "choices/")
    const [properties, setProperties] = useState([])

    useEffect(() => {
        const propertyList = []
        for( let property in result.data?.application_form_choices){
            propertyList.push({ key: property, name: result.data.application_form_choices[property] })
        }

        setProperties(propertyList)
    }, [result])

    const handleSelection = ( choice ) => {
        if ( selected.includes(choice) ){
            setSelected( prevState => prevState.filter( item => item !== choice) )
        } else {
            setSelected([...selected, choice])
        }

    }

    return (
        <>
            { result.isLoading ? <Loading/> :
                <div style={{ display: "flex", flexDirection: "column"}}>
                    <FlexRow padding="0">
                        <FlexItem>
                            <h3> {t('data_sources')} </h3>
                        </FlexItem>
                    </FlexRow>
                    <Divider/>
                    { properties.map( (property, index) => (

                        <Segment
                            onClick={ () => handleSelection(property.key)}
                            key={index} clearing
                            className={ selected.includes(property.key) ? "segment-hover active" : "segment-hover"}
                            style={{
                                marginTop: "0",
                                marginBottom: "0.2rem",
                                cursor: "pointer",
                                fontWeight: "bold",
                                display: (property.key === "personal_information.driver_licence" || property.key === "personal_information.health_insurance") ? "none" : "block"
                        }}>
                            { property.name }
                        </Segment>
                    ))  }
                </div>
            }
        </>
    );
};

export default ComponentChoices;