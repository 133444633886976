import { APIRequest } from './helpers/requests';
import { API } from "../config";

/*
 * Service functions
 */
export const hiringService = {
    getRooms,
    getHiringRoom,
    moveStage,
    createManager,
    launchNewHiring,
    updateHiring,
    getHistory,
    getComments,
    getRejected,
    getBlacklisted,
    createComment,
    setRating,
    setBlacklisted,
    setRejected,
    undoBlacklisted,
    undoRejected,
    getApprovals,
    getRejections,
}

async function getApprovals(params){
    params = params || ""
    return await APIRequest({
        url:  API.CANDIDATES + "approvals/" + params,
        method: "GET",
        data: {},
        private: true
    })
}

async function getRejections(params){
    params = params || ""
    return await APIRequest({
        url:  API.CANDIDATES + "rejections/" + params,
        method: "GET",
        data: {},
        private: true
    })
}

async function getRooms(params){
    params = params || ""
    return await APIRequest({
        url:  API.HIRING_ROOM + params,
        method: "GET",
        data: {},
        private: true
    })
}
async function getHiringRoom(id){
    return await APIRequest({
        url:  API.HIRING_ROOM + id +'/',
        method: "GET",
        data: {},
        private: true
    })
}
async function createManager(data){
    return await APIRequest({
        url:  API.MANAGERS,
        method: "POST",
        data: data,
        private: true
    })
}
async function launchNewHiring(data){
    return await APIRequest({
        url:  API.HIRING_ROOM,
        method: "POST",
        data: data,
        private: true
    })
}
async function updateHiring(id, data){
    return await APIRequest({
        url:  API.HIRING_ROOM + id + '/',
        method: "PATCH",
        data: data,
        private: true
    })
}

async function setRating(id, data){
    return await APIRequest({
        url:  API.CANDIDATES + id + '/rating/',
        method: "POST",
        data: data,
        private: true
    })
}

async function setRejected(data){
    return await APIRequest({
        url:  API.REJECTED,
        method: "POST",
        data: data,
        private: true
    })
}

async function setBlacklisted(data){
    return await APIRequest({
        url:  API.BLACKLISTED,
        method: "POST",
        data: data,
        private: true
    })
}

async function undoRejected(id, data){
    return await APIRequest({
        url:  API.REJECTED + id + "/delete/",
        method: "POST",
        data: data,
        private: true
    })
}

async function undoBlacklisted(id, data){
    return await APIRequest({
        url:  API.BLACKLISTED + id + "/delete/",
        method: "POST",
        data: data,
        private: true
    })
}


async function getBlacklisted(params) {
    params = params || ""
    return await APIRequest({
        url: API.BLACKLISTED + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getRejected(params) {
    params = params || ""
    return await APIRequest({
        url: API.REJECTED + params,
        method: 'GET',
        data: {},
        private: true
    });
}
async function getComments(id, params) {
    params = params || ""
    return await APIRequest({
        url: API.CANDIDATES+id+"/history/" + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function getHistory(id, params) {
    params = params || ""
    return await APIRequest({
        url: API.CANDIDATES+id+"/history/" + params,
        method: 'GET',
        data: {},
        private: true
    });
}

async function createComment(id, data){
    return await APIRequest({
        url:  API.CANDIDATES + id + '/comment/',
        method: "POST",
        data: data,
        private: true
    })
}

async function moveStage(id, data){
    
    return await APIRequest({
        url:  API.CANDIDATES + id +'/move_stage/', 
        method: "POST",
        data: data,
        private: true
    })
}