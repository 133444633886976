import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { getRequest } from '@services/ServiceCommon';
import { useQueryPage } from '@helpers/hooks';
import { useLocation, useHistory } from 'react-router-dom';
// components
import CanView from '@components/perms/CanView';
import { Button, Grid, Divider, Segment, Header } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import SuperDuperModal from '@components/modals/SuperDuperModal';
// module specific components
import LaunchNewRoomForm from '../components/forms/LaunchNewRoomForm';
import Paginator from '@components/Paginator';

const HiringRoomListView = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(true)
    let page = useQueryPage()
    const [limit, setLimit] = useState(10)
    const location = useLocation()
    const history = useHistory()

    const baseParams = `?paginate=true&is_active=${active}&query={id, job_position{id, title}, hrorder{id, name, order_number}, recruiters, hiring_managers, candidate_count, date_from, date_to, name}`
    const [rooms, setRooms] = useFetchData(API.HIRING_ROOMS + baseParams + "&page=" + page + "&limit=" + limit)

    async function fetchRooms(params, isFiltered) {
        params = params || ""

        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if (!params.includes('page')) {
            params += "&page=" + page
        }

        if (!params.includes('limit')) {
            params += "&limit=" + limit
        }

        setRooms(prevState => ({ ...prevState, isLoading: true }))
        const request = await getRequest(API.HIRING_ROOMS + baseParams + params);
        if (request.status === 200) {
            setRooms(prevState => ({
                ...prevState,
                isLoading: false,
                data: request.response
            }))
        }
    };

    useEffect(() => {
        if (!rooms.isLoading) {
            history.replace({ pathname: location.pathname, search: `?page=${1}` })
            fetchRooms("", true)
        }
        // eslint-disable-next-line
    }, [active])

    return (
        <>
            <Grid>
                <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <Button.Group basic size='small' style={{ marginLeft: '1rem', borderRadius: 0 }}>
                            <Button style={{ color: 'var(--primary)' }} active={active === true} onClick={() => setActive(true)}>{t('active')}</Button>
                            <Button style={{ color: 'var(--primary)' }} active={active === false} onClick={() => setActive(false)}>{t('closed')}</Button>
                        </Button.Group>
                    </Grid.Column>
                    <Grid.Column computer={8} tablet={16} mobile={16} style={{ textAlign: "right" }}>
                        <Button.Group size="small" style={{ borderRadius: 0 }}>
                            <CanView permissions={["processes.c_view_processes"]}>
                                <Button as={Link}
                                    className="white-color"
                                    style={{
                                        background: "var(--dark)",
                                        borderRadius: 0,
                                        marginRight: "0.5rem"
                                    }}
                                    to={routes.HIRING_PROCESSES}
                                    content={t('manage_hiring_processes')}
                                />
                            </CanView>

                            <CanView permissions={["candidates.c_manage_recruitment"]}>
                                <SuperDuperModal
                                    trigger={
                                        <Button primary content={t('launch_new_room')} size="small" />
                                    }
                                    header={t('launch_new_room')}
                                    size="small"
                                    content={
                                        <LaunchNewRoomForm
                                            btnName={t('launch')}
                                            onConfirm={() => console.log("launched...")}
                                        />
                                    }
                                />
                            </CanView>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider />

            <Segment loading={rooms.isLoading} style={{ padding: 0, margin: 0, minHeight: "2rem", backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
                {!rooms.isLoading &&
                    <>
                        <EmptyRow length={rooms.data?.results?.length} />
                        <Grid>
                            {rooms.data.results.map(room => (
                                <Grid.Column computer={4} tablet={8} mobile={16} key={room.id}>
                                    <Link to={routes.HIRING_ROOMS + room.id}>
                                        <Segment className="toggle-button">
                                            <Header as="h3" content={room.name} style={{ marginLeft: "1rem", marginTop: "0.8rem" }} />
                                            <Grid>
                                                <Grid.Row style={{ padding: 0, textAlign: "left" }} columns={2}>
                                                    <Grid.Column>
                                                        <strong style={{ color: "var(--variant2)" }}> {t('position')} </strong>
                                                        {room.job_position?.id
                                                            ? <Header as="h4" content={room.job_position.title} style={{ marginTop: 0 }} />
                                                            : <div>--</div>
                                                        }
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <strong style={{ color: "var(--variant2)" }}> {t('order_number')} </strong>
                                                        {room.hrorder?.id && room.hrorder?.order_number
                                                            ? <Header as="h4" content={room.hrorder.order_number} style={{ marginTop: 0 }} />
                                                            : <div>--</div>
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Divider />

                                            <Grid>
                                                <Grid.Row style={{ padding: 0, textAlign: "center" }} columns={3}>
                                                    <Grid.Column>
                                                        <strong style={{ color: "var(--variant2)" }}> {t('recruiters')} </strong>
                                                        <Header as="h3" content={room.recruiters.length} style={{ marginTop: 0 }} />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <strong style={{ color: "var(--variant2)" }}> {t('managers')} </strong>
                                                        <Header as="h3" content={room.hiring_managers.length} style={{ marginTop: 0 }} />
                                                    </Grid.Column>
                                                    <Grid.Column>
                                                        <strong style={{ color: "var(--variant2)" }}> {t('candidates')} </strong>
                                                        <Header as="h3" content={room.candidate_count} style={{ marginTop: 0 }} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Segment>
                                    </Link>
                                </Grid.Column>
                            ))}
                        </Grid>
                    </>
                }
            </Segment>

            <Paginator
                forcePage={page || 1}
                limit={limit}
                setLimit={setLimit}
                length={rooms?.data?.count || 0}
                onChange={(params) => fetchRooms(params)}
            />

        </>
    );
};

export default HiringRoomListView;