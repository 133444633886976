
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link, useHistory } from 'react-router-dom';
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { createRequest } from '@services/ServiceCommon';
//components
import ConfirmModal from '@components/modals/ConfrimModal';
import Icon from '@components/Icon';
import { Header, Grid, /*Icon,*/ Button, Divider, List } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import PreviewCard from '@components/cards/PreviewCard';


const JobOfferPreview = ({ offer }) => {

    const { t } = useTranslation();
    const[requirementsHide, setRequirementsHide] = useState(false)
    const[contractHide, setContractHide] = useState(false)
    const[salaryHide, setSalaryHide] = useState(false)

    const history = useHistory()

    return (
        <div>
            <Grid>
                <Grid.Row>
                    <Grid.Column mobile={10} tablet={10} computer={10}>
                        <Grid.Row>
                            <Header size="medium" content= { offer.title }/>
                        </Grid.Row>
                        <Grid.Row>
                            {offer?.workplace?.address?.city && offer?.workplace?.address?.country_display ?
                            <>
                            <Icon name="location-outline" style={{ marginRight: "0.5rem" }}/>
                            <span style={{ position: "relative", top: "-0.2rem" }}>{offer?.workplace?.address?.city}, {offer?.workplace?.address?.country_display}</span>
                            </>
                            : ""
                        }
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={6} computer={6}>
                        <CanView permissions={["jobs.c_manage_job_offers"]}>
                            <ConfirmModal
                                description={t('duplicate_offer_confirm')}
                                onConfirm={async () => {
                                    const request = await createRequest(API.GET_JOB_OFFERS + offer.id + "/duplicate/")

                                    if( request.status === 201 ){
                                        history.push(routes.UPDATE_JOB_OFFER + request.response.id + "/")
                                    }
                                }}
                                button={
                                    <Button floated="right" size="huge" icon={<Icon name="copy-outline"/>} style={{background: "none", color:"var(--info)", paddingTop:0}}/>
                                }
                            />
                        </CanView>
                        {/* <Button floated="right" size="huge" icon="pencil" style={{background: "none", paddingRight:"0", paddingTop:0}}/> */}
                        <CanView permissions={["jobs.c_manage_job_offers"]}>
                            <Link to={routes.UPDATE_JOB_OFFER + offer.id} >
                                <Button floated="right" size="huge" icon={<Icon name="pencil-outline"/>} style={{background: "none", paddingRight:"0", paddingTop:0}}/>
                            </Link>
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row style={{paddingBottom:0}}>
                    <Grid.Column>
                        <Header size="medium" content={t('description')}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                    <Grid.Column>
                        {offer.description ?
                            <div style={{ whiteSpace: "pre-line" }}>{ offer.description }</div>
                            : "--"
                        }
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingBottom:0}}>
                    <Grid.Column mobile={14} tablet={14} computer={14}>
                        <Header size="medium" content={t('requirements')}/>
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={2} computer={2}>
                        {requirementsHide ?
                            <Button floated="right" size="medium" onClick={()=>setRequirementsHide(false)} icon={<Icon name="chevron-down-outline"/>} style={{paddingTop:0, background: "none", marginBottom:"0.5rem"}}/>
                        :
                            <Button floated="right" size="medium" onClick={()=>setRequirementsHide(true)} icon={<Icon name="chevron-up-outline"/>} style={{paddingTop:0, background: "none", marginBottom:"0.5rem"}}/>
                        }

                    </Grid.Column>
                </Grid.Row>
                {!requirementsHide && offer.job_requirement_list && <>
                <Grid.Row style={{paddingTop:0}}>
                    <Grid.Column mobile={8} tablet={8} computer={8} style={{paddingRight:"0.4rem"}}>
                        {offer?.job_requirement_list?.age_lower &&
                        <PreviewCard
                            name={t('age_lower_then')}
                            data={ offer?.job_requirement_list?.age_lower }
                        />}
                        {offer?.job_requirement_list?.practice_years &&
                        <PreviewCard
                            name={t('year_of_practice')}
                            data={offer?.job_requirement_list?.practice_years}
                        />}
                        {offer?.job_requirement_list?.citizenships_display_list &&
                        <PreviewCard
                            name={t('citizenship')}
                            data={ offer?.job_requirement_list?.citizenships_display_list ?
                                offer?.job_requirement_list?.citizenships_display_list.map((item) => item + " ")
                                : ""
                            }
                        />}
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8} style={{paddingLeft:"0.4rem"}}>
                        {offer?.job_requirement_list?.age_higher &&
                        <PreviewCard
                            name={t('age_higher_then')}
                            data={offer?.job_requirement_list?.age_higher}
                        />}
                        {offer?.job_requirement_list?.gender_display &&
                        <PreviewCard
                            name={t('gender')}
                            data={offer?.job_requirement_list?.gender_display}
                        />}
                        {offer?.job_requirement_list?.drivers_licences_display_list &&
                        <PreviewCard
                            name={t('drive_licences')}
                            data={offer?.job_requirement_list?.drivers_licences_display_list ?
                                offer?.job_requirement_list?.drivers_licences_display_list.map((item, index) => item + ", ")
                                : ""
                            }
                        />}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                        {offer?.job_requirement_list?.eligibilities_display_list &&
                        <>
                        <Header size="small" content={t('eligibility') + ":"}/>
                        <List style={{marginLeft: "1rem"}}>
                            {offer?.job_requirement_list?.eligibilities_display_list ? offer?.job_requirement_list?.eligibilities_display_list.map((item, index) =>
                                    <List.Item key={ index }>
                                        <List.Icon icon={<Icon name="checkmark-done-outline"/>}/>
                                        <List.Content>
                                            {item}
                                        </List.Content>
                                    </List.Item>
                                    )
                                    :
                                    ""
                            }
                        </List></>}

                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8}>
                    {offer?.job_requirement_list?.specialization && <>
                    <Header size="small" content={t('specialization') + ":"}/>
                        <List style={{marginLeft: "1rem"}}>
                            <List.Item>
                                <List.Icon name="check"/>
                                <List.Content>
                                    {offer?.job_requirement_list?.specialization}
                                </List.Content>
                            </List.Item>

                        </List> </>}
                    {offer?.job_requirement_list?.educations_display_list && <>
                    <Header size="small" content={t('educations') + ":"}/>
                        <List style={{marginLeft: "1rem"}}>
                            {offer?.job_requirement_list?.educations_display_list ? offer?.job_requirement_list?.educations_display_list.map((item, index) =>
                                    <List.Item key={index}>
                                        <List.Icon name="check"/>
                                        <List.Content>
                                            {item}
                                        </List.Content>
                                    </List.Item>
                                    )
                                    :
                                    ""
                            }
                        </List> </>}
                    </Grid.Column>
                </Grid.Row>
                </> }
                {!requirementsHide && !offer.job_requirement_list &&
                 <Grid.Row >
                    <Grid.Column>
                        {t('no_data_available')}
                    </Grid.Column>
                 </Grid.Row>
                 }
                <Divider/>
                <Grid.Row style={{paddingBottom:0}}>
                    <Grid.Column mobile={14} tablet={14} computer={14}>
                        <Header size="medium" content={t('contract_information')} />
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={2} computer={2}>
                    {contractHide ?
                            <Button floated="right" size="medium" onClick={()=>setContractHide(false)} icon={<Icon name="chevron-down-outline"/>} style={{paddingTop:0, background: "none", marginBottom:"0.5rem"}}/>
                        :
                            <Button floated="right" size="medium" onClick={()=>setContractHide(true)} icon={<Icon name="chevron-up-outline"/>} style={{paddingTop:0, background: "none", marginBottom:"0.5rem"}}/>
                        }
                    </Grid.Column>
                </Grid.Row>
                {!contractHide && offer?.job_contract && <>
                <Grid.Row style={{paddingTop:0}}>
                    <Grid.Column mobile={8} tablet={8} computer={8} style={{paddingRight:"0.4rem"}}>
                        {offer?.job_contract?.contract_type_display &&
                        <PreviewCard
                            name={t('contract_type')}
                            data={offer?.job_contract?.contract_type_display}
                        />}
                        {offer?.job_contract?.work_schedule_display &&
                        <PreviewCard
                            name= {t('work_schedule_')}
                            data={offer?.job_contract?.work_schedule_display}
                        />}
                        {offer?.job_contract?.working_hours_per_day &&
                        <PreviewCard
                            name={t('hours_per_day_')}
                            data={offer?.job_contract?.working_hours_per_day}
                        />}
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8} style={{paddingLeft:"0.4rem"}}>
                        {offer?.job_contract?.allowed_overtime &&
                        <PreviewCard
                            name={t('allowed_overtime_')}
                            data={offer?.job_contract?.allowed_overtime}
                        /> }
                        {offer?.job_contract?.work_shift_display &&
                        <PreviewCard
                            name={t('work_shifts_')}
                            data={offer?.job_contract?.work_shift_display}
                        />}
                        {offer?.job_contract?.hours_per_month &&
                        <PreviewCard
                            name={t('hours_per_month_')}
                            data={offer?.job_contract?.hours_per_month}
                        />}
                    </Grid.Column>
                </Grid.Row>
                </>}
                {!contractHide && !offer?.job_contract &&
                 <Grid.Row >
                    <Grid.Column>
                        {t('no_data_available')}
                    </Grid.Column>
                 </Grid.Row>
                 }
                <Divider/>
                <Grid.Row style={{paddingBottom:0}}>
                    <Grid.Column mobile={14} tablet={14} computer={14}>
                        <Header size="medium" content={t('salary_information')}/>
                    </Grid.Column>
                    <Grid.Column mobile={2} tablet={2} computer={2}>
                    {salaryHide ?
                            <Button floated="right" size="medium" onClick={()=>setSalaryHide(false)} icon={<Icon name="chevron-down-outline"/>} style={{paddingTop:0, background: "none", marginBottom:"0.5rem"}}/>
                        :
                            <Button floated="right" size="medium" onClick={()=>setSalaryHide(true)} icon={<Icon name="chevron-up-outline"/>} style={{paddingTop:0, background: "none", marginBottom:"0.5rem"}}/>
                        }
                    </Grid.Column>
                </Grid.Row>
                {!salaryHide && offer?.job_contract?.salary && <>
                <Grid.Row style={{paddingTop:0}}>
                    <Grid.Column mobile={8} tablet={8} computer={8} style={{paddingRight:"0.4rem"}}>
                        {offer?.job_contract?.salary?.salary_type_display &&
                        <PreviewCard
                            name={t('salary_type')}
                            data={offer?.job_contract?.salary?.salary_type_display}
                        />}
                        {offer?.job_contract?.salary?.min_brutto &&
                        <PreviewCard
                            name={t('min_brutto')}
                            data={offer?.job_contract?.salary?.min_brutto}
                        />}
                        {offer?.job_contract?.salary?.min_netto &&
                        <PreviewCard
                        name={t('min_netto')}
                        data={offer?.job_contract?.salary?.min_netto}
                        />}
                    </Grid.Column>
                    <Grid.Column mobile={8} tablet={8} computer={8} style={{paddingLeft:"0.4rem"}}>
                        {offer?.job_contract?.salary?.currency &&
                        <PreviewCard
                            name={t('currency')}
                            data={offer?.job_contract?.salary?.currency}
                        />}
                        {offer?.job_contract?.salary?.max_brutto &&
                        <PreviewCard
                            name={t('max_brutto')}
                            data={offer?.job_contract?.salary?.max_brutto}
                        />}
                        {offer?.job_contract?.salary?.max_netto &&
                        <PreviewCard
                            name={t('max_netto')}
                            data={offer?.job_contract?.salary?.max_netto}
                        />}
                    </Grid.Column>
                </Grid.Row>
                </>}
                {!salaryHide && !offer?.job_contract?.salary &&
                 <Grid.Row >
                    <Grid.Column>
                        {t('no_data_available')}
                    </Grid.Column>
                 </Grid.Row>
                 }
            </Grid>
        </div>
    );
};

export default JobOfferPreview;