import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useSelectOptions } from '@helpers/hooks';
// components
import { Divider, Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ModalCancel from '@components/buttons/ModalCancel';
import SuperCheckboxField from '@components/forms/SuperCheckboxField';
import moment from 'moment';

const PlannedCourseForm = ({ plannedCourse, setPlannedCourses, onClose, plannedCourses }) => {
    const { t } = useTranslation();
    const [employee] = useSelectOptions(API.EMPLOYEES + '?is_active=true&only_basic_info=true', "fullname")  

    const [data, setData] = useState({
        course: plannedCourse ? plannedCourse.course.id : null,
        responsible_person: plannedCourse ? plannedCourse?.responsible_person?.id : null,
        participants: plannedCourse ? plannedCourse.participants.map((el) => el.id) : [],
        is_approved: plannedCourse ? plannedCourse.is_approved : false,
        is_closed: plannedCourse ? plannedCourse.is_closed : false,
        start_date: plannedCourse?.start_date ? plannedCourse.start_date.slice(0, -3) : '',
        end_date: plannedCourse?.end_date ? plannedCourse.end_date.slice(0, -3) : '',
    });

    // const [isApproved, setIsApproved] = useState(data.is_approved);
    // const [isClosed, setIsClosed] = useState(data.is_closed);

    // Validate Form
    useEffect(() => {
        if (data.end_date && data.start_date) {
            if (moment(data.start_date).isAfter(data.end_date)) {
                setInvalidDateRange(t('invalid_date_should_be_higher'));
            } else {
                setInvalidDateRange(false);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    const [isProcessing, setIsProcessing] = useState(false);
    const [invalidDateRange, setInvalidDateRange] = useState(false);

    const onSubmit = async () => {
        setIsProcessing(true);
        let new_participants = {};
        if (plannedCourse?.id) {
            new_participants = {
                remove: plannedCourse?.participants ? plannedCourse.participants.map((el) => el.id) : [],
                add: data.participants,
            };
        } else {
            new_participants = {
                add: data.participants,
            };
        }
        const dataForSubmittion = {
            ...data,
            participants: new_participants,
            start_date: data.start_date !== '' ? data.start_date + ':00' : null,
            end_date: data.end_date !== '' ? data.end_date + ':00' : null,
        };

        if (plannedCourse?.id) {
            const request = await requests.put(API.PLANNED_COURSES + plannedCourse.id + '/', dataForSubmittion);
            if (request.status === 200) {
                setPlannedCourses({
                    ...plannedCourses,
                    data: {
                        ...plannedCourses.data,
                        results: plannedCourses.data.results.filter((item) => {
                        if (item.id === plannedCourse?.id) {
                            item.course = request.response.course;
                            item.responsible_person = request.response.responsible_person;
                            item.participants = request.response.participants;
                            item.is_approved = request.response.is_approved;
                            item.is_closed = request.response.is_closed;
                            item.end_date = request.response.end_date;
                            item.start_date = request.response.start_date;
                        }
                        return item;
                    }),
                }})
                onClose()
            }
        } else {
            const request = await requests.post(API.PLANNED_COURSES, dataForSubmittion);
            if (request.status === 201) {
                setPlannedCourses((prev) => ({ ...prev, data: { ...prev.data, count: prev.data.count + 1, results: [request.response, ...prev.data.results]} }))
                onClose()
            }
        }
        setIsProcessing(false);
    };

    return (
        <Form onSubmit={onSubmit}>
            <SuperField
                search
                as="choice"
                required
                label={t('courses')}
                endpoint={API.COURSES}
                value={data.course}
                text="title"
                onChange={(e, { value }) => setData({ ...data, course: value })}
            />

            <Form.Group widths="equal">
                <SuperField
                    as="datetimepicker"
                    required
                    hideMobileKeyboard
                    closeOnMouseLeave
                    clearable
                    label={t('start_date')}
                    value={data.start_date}
                    onChange={(e, { value }) => setData({ ...data, start_date: value })}
                />
                <SuperField
                    as="datetimepicker"
                    clearable
                    hideMobileKeyboard
                    closeOnMouseLeave
                    label={t('end_date')}
                    error={invalidDateRange}
                    value={data.end_date}
                    onChange={(e, { value }) => setData({ ...data, end_date: value })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="choice"
                    required
                    search
                    label={t('responsible_person')}
                    disabled={employee.isLoading}
                    loading={employee.isLoading}
                    customOptions={employee.options}
                    value={data.responsible_person}
                    onChange={(e, { value }) => {
                        setData({ ...data, responsible_person: value });
                    }}
                />

                <SuperCheckboxField
                    label={t('employees')}
                    text="fullname"
                    // disabled={employee.isLoading}
                    // loading={employee.isLoading}
                    endpoint={API.EMPLOYEES + '?is_active=true&only_basic_info=true'}
                    // customOptions={employee.isLoading ? [] : employee.options}
                    value={data.participants}
                    onChange={(selected) => {
                        setData({ ...data, participants: selected });
                    }}
                />
            </Form.Group>            

            {/*<Form.Group widths="equal">*/}
            {/*    <SuperField*/}
            {/*        as="checkbox"*/}
            {/*        label={t("is_approved")}*/}
            {/*        checked={data.is_approved}*/}
            {/*        onChange={(e) => {*/}
            {/*            setIsApproved(!isApproved);*/}
            {/*            setData({ ...data, is_approved: !isApproved });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*    <SuperField*/}
            {/*        as="checkbox"*/}
            {/*        label={t("is_closed")}*/}
            {/*        checked={data.is_closed}*/}
            {/*        onChange={(e) => {*/}
            {/*            setIsClosed(!isClosed);*/}
            {/*            setData({ ...data, is_closed: !isClosed });*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Form.Group>*/}

            <Divider />
            <Form.Field style={{ textAlign: 'right' }}>
                <ModalCancel onClose={onClose} />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing || data.course === '' || data.responsible_person === '' || data.participants === 0}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    );
};

export default PlannedCourseForm;
