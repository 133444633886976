import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// store
import { routes } from '@store/routes';
// components
import SuperDuperModal from '@components/modals/SuperDuperModal';
import RenderTemplateHtmlToPDF from '@components/RenderTemplateHtmlToPDF';
import TerminationUploadScannedFile from '../components/TerminationUploadScannedFile';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import { Icon, Button, Popup } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
// import TerminationForm from '../components/TerminationForm';

const TerminationList = ( { terminations, setTerminations }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line


    return (
        <>
            <FlexTable>
                <FlexRow>
                    <FlexHeader content={ t('employee') }/>
                    <FlexHeader content={ t('type') }/>
                    <FlexHeader content={ t('terminated_by') }/>
                    <FlexHeader content={ t('notice_period') }/>
                    <FlexHeader content={ t('reason') }/>
                    <FlexHeader content={ t('document') }/>
                    <FlexHeader content={ t('actions') }/>
                </FlexRow>
                { terminations?.length === 0 &&
                    <FlexRow>
                        <FlexItem bold textAlign="center">
                            { t('no_records') }
                        </FlexItem>
                    </FlexRow>
                }
                { terminations?.map((termination) =>
                    <FlexRow key={termination.id} fontSize="1rem" borders>
                        <FlexItem>
                            <Link to={routes.EMPLYOEES_DETAIL + termination?.contract_instance?.employee?.id} target="_blank">
                                <strong>{ termination?.contract_instance?.employee?.fullname }</strong>
                            </Link> <br />
                            <small>{ termination?.contract_instance?.contract_data?.work_position?.title }</small>
                        </FlexItem>
                        <FlexItem bold> { termination?.type_display } </FlexItem>
                        <FlexItem bold> { termination?.terminated_by_display } </FlexItem>
                        <FlexItem> { moment(termination?.notice_period).format("DD.MM.YYYY") } </FlexItem>
                        <FlexItem bold> { termination?.reason?.title } </FlexItem>
                        <FlexItem>
                            { termination?.template || termination?.external_file
                                ?
                                <>
                                    { termination?.external_file &&
                                        // eslint-disable-next-line
                                        <a href={termination?.external_file} target="_blank" rel="noopener noreferrer">
                                            <Icon style={{ cursor: "pointer", color: "var(--danger)" }} size="large" name="file pdf"/>
                                        </a>
                                    }

                                    { termination?.template &&
                                        <SuperDuperModal
                                            size="small"
                                            trigger={
                                                <Icon style={{ cursor: "pointer", color: "var(--danger)" }} size="large" name="file pdf"/>
                                            }
                                            content={
                                                <RenderTemplateHtmlToPDF
                                                    documentId={termination?.template?.id}
                                                    objId={termination.id}
                                                    objInstance="contracts.ContractTermination"
                                                />
                                            }
                                        />
                                    }
                                </>
                                :
                                <CanView permissions={['contracts.c_manage_all_contracts']}>
                                    <SuperDuperModal
                                        trigger={ <Button size="tiny" basic content={ t('add_document') }/> }
                                        content={ <TerminationUploadScannedFile terminationId={termination.id} setTerminations={setTerminations}/> }
                                    />
                                </CanView>
                            }
                        </FlexItem>
                        <FlexItem>
                            <FlexRow background="transparent" padding="0" fontSize="1rem">
                                <FlexItem textAlign="left">
                                    <Popup
                                        basic
                                        inverted
                                        content={ t('show_note') }
                                        trigger={
                                            <div>
                                                <SuperDuperModal
                                                    closeIcon
                                                    header={ t('note') }
                                                    content={ <p> { termination.note !== "" ? termination.note : t('note_is_empty') } </p> }
                                                    trigger={
                                                        <Icon name="sticky note" style={{ cursor: "pointer", color: "var(--dark)" }}/>
                                                    }
                                                />
                                            </div>
                                        }
                                    />
                                </FlexItem>
                                {/* <FlexItem textAlign="center">
                                    <Popup
                                        basic
                                        inverted
                                        content={ "Update termination" }
                                        trigger={
                                            <div>
                                                <SuperDuperModal
                                                    closeIcon
                                                    header={ "Update termination" }
                                                    content={
                                                        <TerminationForm
                                                            contract={termination.contract_instance}
                                                            termination={termination}
                                                            setTerminations={setTerminations}/>
                                                        }
                                                    trigger={
                                                        <Icon name="sticky note" style={{ cursor: "pointer", color: "var(--dark)" }}/>
                                                    }
                                                />
                                            </div>
                                        }
                                    />
                                </FlexItem> */}
                            </FlexRow>
                        </FlexItem>
                    </FlexRow>
                )}
            </FlexTable>
        </>
    );
};

export default TerminationList;