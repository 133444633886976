import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { isPhoneNumberValid, isEmailValid } from '@helpers/validation'
// components
import { Form, Divider, Header, Button, Search } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import EmailField from '@components/forms/common/EmailField';
import PhoneField from '@components/forms/common/PhoneField';


const DetailsForm = ({ form, setForm, document, errors, type, onClose }) => {
    const { t } = useTranslation()

    const [searching, setSearching] = useState(false);
    const [query, setQuery] = useState("")
    const [results, setResults] = useState([]);

    const [data, setData] = useState({
        name: type === "supplier" ? form.supplier_name : form.customer_name,
        registration_id: type === "supplier" ? form.supplier_registration_id : form.customer_registration_id,
        business_register_folder: type === "supplier" ? form.supplier_business_register_folder : form.customer_business_register_folder,
        tax_id: type === "supplier" ? form.supplier_tax_id : form.customer_tax_id,
        vat_id: type === "supplier" ? form.supplier_vat_id : form.customer_vat_id,
        street: type === "supplier" ? form.supplier_street : form.customer_street,
        zip: type === "supplier" ? form.supplier_zip : form.customer_zip,
        city: type === "supplier" ? form.supplier_city : form.customer_city,
        country: type === "supplier" ? form.supplier_country : form.customer_country,
        email: type === "supplier" ? form.supplier_email : form.customer_email,
        phone: type === "supplier" ? form.supplier_phone : form.customer_phone,
        bank_iban: form.bank_iban,
        bank_swift_bic: form.bank_swift_bic,
    })

    const handleSearchChange = async (e, { value }) => {
        setQuery(value);

        if( value.length > 3 ){
            setSearching(true);
    
            if( !searching ){
                // verify document type and management type to search in proper models
                if( document === "recieved_invoice" ){
                    if( type === "supplier" ){
                        // prijatá faktúra a supplier je supplier/account
                        await findSupplierOrAccount()
                    } else {
                        // prijatá faktúra a customer je company/units
                        await findCompanyOrAccount()
                    }
                } else {
                    if( type === "supplier" ){
                        // vystavená faktúra a supplier je company/units
                        await findCompanyOrAccount()
                    } else {
                        await findSupplierOrAccount()
                        // vystavená faktúra a customer je supplier/account
                    }
                }
    
                setSearching(false);
        }
        }
    }

    const findSupplierOrAccount = async () => {
        let resultList = []
        const request = await getRequest(API.ACCOUNTS + "?&query={business_detail, contact_info}&search=" + query)
            
        if( request.status === 200 ){
            if( request.response.length > 0){
                resultList = [...resultList, ...request.response]
            } 
        }

        if( resultList.length > 0 ){
            setResults(resultList.map(item => {
                return {
                    ...item,
                    title: item?.business_detail?.name + " - " + item?.business_detail?.identification_number 
                }
            }))
        }
    }

    const findCompanyOrAccount = async () => {
        let resultList = []
        const request = await getRequest(API.COMPANIES + "?is_active=true&query={business_detail, contact_info}&search=" + query)
            
        if( request.status === 200 ){
            if( request.response.length > 0){
                resultList = [...resultList, ...request.response]
            } 
            
            // try units
            const requestUnits = await getRequest(API.COMPANIES + "units/?only_from_active_company=true&query={business_detail, contact_info}&search=" + query)
            if( requestUnits.status === 200 ){
                if( request.response.length > 0){
                    resultList = [...resultList, ...requestUnits.response]
                } 
            }
        }

        if( resultList.length > 0 ){
            setResults(resultList.map(item => {
                return {
                    ...item,
                    title: item?.business_detail?.name + " - " + item?.business_detail?.identification_number 
                }
            }))
        }
    }

    const resultRenderer = ({ title }) => {
        return (
            <div key='content' style={{ width: "100%" }} className='content'>
                {title && <div className='title'>{title}</div>}
            </div>
        );
    };

    const setSupplier = () => {
        return {
            supplier_name: data.name,
            supplier_registration_id: data.registration_id,
            supplier_business_register_folder: data.business_register_folder,
            supplier_tax_id: data.tax_id,
            supplier_vat_id: data.vat_id,
            supplier_street: data.street,
            supplier_zip: data.zip,
            supplier_city: data.city,
            supplier_country: data.country,
            supplier_email: data.email,
            supplier_phone: data.phone,
        }
    }

    const setCustomer = () => {
        return {
            customer_name: data.name,
            customer_registration_id: data.registration_id,
            customer_business_register_folder: data.business_register_folder,
            customer_tax_id: data.tax_id,
            customer_vat_id: data.vat_id,
            customer_street: data.street,
            customer_zip: data.zip,
            customer_city: data.city,
            customer_country: data.country,
            customer_email: data.email,
            customer_phone: data.phone,
        }
    }

    const onConfirm = () => {
        if(type === "supplier"){
            const supplierData = setSupplier()
            setForm(prevState => {
                return {
                    ...prevState,
                    ...supplierData,
                    bank_iban: data.bank_iban,
                    bank_swift_bic: data.bank_swift_bic,
                }
            })
        } else {
            const customerData = setCustomer()
            setForm(prevState => {
                return {
                    ...prevState,
                    ...customerData,
                }
            })
        }
        onClose()
    }

    const onSelect = (e, { result }) => {
        let gatheredData = {
            name: "",
            registration_id: "",
            business_register_folder: "",
            tax_id: "",
            vat_id: "",
            phone: "",
            email: "",
            bank_iban: "",
            bank_swift_bic: "",
        }

        if( result?.business_detail !== null || result?.business_detail !== undefined ){
            gatheredData.name = result.business_detail?.name || ""
            gatheredData.registration_id = result.business_detail?.identification_number || ""
            gatheredData.business_register_folder = result.business_detail?.business_register_folder || ""
            gatheredData.tax_id = result.business_detail?.dit_number || ""
            gatheredData.vat_id = result.business_detail?.vat_number || ""

            if( result?.business_detail?.billing_address !== null || result?.business_detail?.billing_address !== undefined ){
                gatheredData.street = result?.business_detail?.billing_address?.street || ""
                gatheredData.zip = result?.business_detail?.billing_address?.postcode || ""
                gatheredData.city = result?.business_detail?.billing_address?.city || ""
                gatheredData.country = result?.business_detail?.billing_address?.country || ""
            }

            if( type === "supplier" ){
                const defaultBankAccount = result?.business_detail?.bank_accounts?.find(bank => bank.default)

                if( defaultBankAccount !== undefined ){
                    gatheredData.bank_iban = defaultBankAccount.iban
                    gatheredData.bank_swift_bic = defaultBankAccount.swift_code
                }
            }
        }

        if( result?.contact_info !== null || result?.contact_info !== undefined ){
            if(result?.contact_info?.emails?.length > 0){
                gatheredData.email = result?.contact_info?.emails?.[0]?.email || ""
            }

            if(result?.contact_info?.phones?.length > 0){
                gatheredData.phone = result?.contact_info?.phones?.[0]?.phone || "" 
            }
        }

        setData(prevState => ({
            ...prevState,
            ...gatheredData
        }))
    }

    const onPhoneInput = (input) => {
        return input.replace(" ", "")
    }

    return (
        <Form>
            <Form.Field className="invoice-autocomplete-search">
                <label>{t('search_by_id_number_or_name')}</label>
                <Search
                    autoFocus
                    input={{
                        placeholder: t('search'),
                        icon: 'search',
                        iconPosition: 'left'
                    }}
                    showNoResults={false}
                    loading={searching}
                    onResultSelect={(e, result) => onSelect(e, result)}
                    onSearchChange={_.debounce(handleSearchChange, 2000, {leading: true,})}
                    value={query}
                    results={results}
                    resultRenderer={resultRenderer}
                >
                </Search>
            </Form.Field>

            <Header as="h3" content={t('business_detail')}/>
            <Divider/>
            <Form.Group widths="equal">
                <SuperField as="input"
                    label={ t('name') }
                    value={data.name}
                    error={ type === "supplier" ? errors?.supplier_name : errors?.customer_name }
                    onChange={(e, { value }) => setData({
                        ...data,
                        name: value
                    })}
                />
                <SuperField as="input"
                    label={ t('registration_id') }
                    value={data.registration_id}
                    error={ type === "supplier" ? errors?.supplier_registration_id : errors?.customer_registration_id }
                    onChange={(e, { value }) => setData({
                        ...data,
                        registration_id: value
                    })}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <SuperField as="input"
                    label={ t('tax_id') }
                    value={data.tax_id}
                    error={ type === "supplier" ? errors?.supplier_tax_id : errors?.customer_tax_id }
                    onChange={(e, { value }) => setData({
                        ...data,
                        tax_id: value
                    })}
                />
                <SuperField as="input"
                    label={ t('vat_id') }
                    value={data.vat_id}
                    error={ type === "supplier" ? errors?.supplier_vat_id : errors?.customer_vat_id }
                    onChange={(e, { value }) => setData({
                        ...data,
                        vat_id: value
                    })}
                />
                <SuperField as="input"
                    label={ t('register_folder') }
                    error={ type === "supplier" ? errors?.supplier_business_register_folder : errors?.customer_business_register_folder }
                    value={data.business_register_folder}
                    onChange={(e, { value }) => setData({
                        ...data,
                        business_register_folder: value
                    })}
                />
            </Form.Group>

            <Divider/>
            <Header as="h3" content={t('address')}/>
            <Divider/>
            <Form.Group widths={"equal"}>
                <SuperField as="input"
                    label={ t('street') }
                    value={data.street}
                    error={ type === "supplier" ? errors?.supplier_street : errors?.customer_street }
                    onChange={(e, { value }) => setData({
                        ...data,
                        street: value
                    })}
                />
                <SuperField as="input"
                    label={ t('postcode') }
                    value={data.zip}
                    error={ type === "supplier" ? errors?.supplier_zip : errors?.customer_zip }
                    onChange={(e, { value }) => setData({
                        ...data,
                        zip: value
                    })}
                />
            </Form.Group>
            <Form.Group widths={"equal"}>
                <SuperField as="input"
                    label={ t('city') }
                    value={data.city}
                    error={ type === "supplier" ? errors?.supplier_city : errors?.customer_city }
                    onChange={(e, { value }) => setData({
                        ...data,
                        city: value
                    })}
                />
                <SuperField as="choice"
                    search
                    type="countries"
                    label={ t('country') }
                    value={data.country}
                    error={ type === "supplier" ? errors?.supplier_country : errors?.customer_country }
                    onChange={(e, { value }) => setData({
                        ...data,
                        country: value
                    })}
                />
            </Form.Group>


            <Divider/>
            <Header as="h3" content={t('contact_information')}/>
            <Divider/>
            <Form.Group widths={2}>
                <Form.Field>
                    <PhoneField
                        hideType
                        placeholder={t('phone')}
                        error={ type === "supplier" ? errors?.supplier_phone : errors?.customer_phone }
                        value={data.phone || ""}
                        setValue={(e, { value }) => setData({
                            ...data,
                            phone: onPhoneInput(value)
                        })}
                    />
                </Form.Field>
                <EmailField
                    label={t('email')}
                    placeholder={t('email')}
                    error={ type === "supplier" ? errors?.supplier_email : errors?.customer_email }
                    value={data.email || ""}
                    setValue={(e, { value }) => setData({
                        ...data,
                        email: value
                    })}
                />
            </Form.Group>

            <Divider/>   

            <Form.Field style={{ textAlign: "right" }}>
                <Button
                    primary
                    content={t('confirm')}
                    type="button"
                    onClick={onConfirm}
                    disabled={ 
                        (!isPhoneNumberValid(data.phone) && data.phone !== null && data.phone !== "" && data.phone !== undefined) ||
                        (!isEmailValid(data.email) && data.email !== null && data.email !== "" && data.email !== undefined)
                    }
                />
            </Form.Field>
        </Form>
    );
};

export default DetailsForm;