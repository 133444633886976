import React from 'react';
import { Switch } from 'react-router-dom';
// routes
import { routes } from '@routes/index';
import { allowedModules as modules } from '@store/permissions/index';
// routes components
import PrivateRoute from '@components/routes/PrivateRoute';
import PublicRoute from '@components/routes/PublicRoute';
// Common
import Profile from './core/profile/views/Profile';
import ComponentExamples from './_views/ComponentExamples';
// Human Resources
import EmployeesList from './hr/employees/views/EmployeesList';
// import EmployeeDetail from './hr/employees/views/EmployeeDetail';
import ProfileCard from '@components/common/profile-card/ProfileCard';
import CreateAnEmployeeForm from './hr/employees/views/CreateAnEmployee';
import CandidateDetail from './hr/hiring-rooms/views/CandidateDetail';
// import HiringList from './hr/hiring-rooms/views/HiringList';
// import HiringRoom from './hr/hiring-rooms/views/HiringRoom';
import CandidatesList from './hr/candidates/views/CandidatesList';
import BenefitsList from './hr/benefits/views/BenefitsList';
import JobListing from './hr/jobs/views/JobListing';
// import JobDetail from './hr/jobs/detail/JobDetail';
import PositionList from './hr/job-positions/views/PositionList';
import PositionForm from './hr/job-positions/views/PositionForm';
import JobOfferForm from './hr/jobs/views/JobOfferForm';
import Onboarding from './hr/onboarding/views/Onboarding';
import ApplyForms from './hr/jobs/views/ApplyForms';
import CoursesList from './hr/courses/views/CoursesList';
import ContractListView from './hr/contracts/views/ContractListView';
// Project Management
import TeamsDetail from './pm/teams/views/TeamsDetail';
import TeamsList from './pm/teams/views/TeamsList';
import ProjectList from './pm/projects/views/ProjectList';
import ProjectDetailView from './pm/projects/v2_views/ProjectDetailView';
import DetailView from './pm/projects/views/DetailView';
// import TimesheetsList from './pm/timesheets/views/TimesheetsList';
// import TimesheetsDetail from './pm/timesheets/views/TimesheetsDetail';
// Sales Management
import Invoices from './sm/invoices/index';
// import InvoiceForm from './sm/invoices/components/InvoiceForm';
import AccountsList from './sm/accounts/views/AccountsList';
import AccountsDetail from './sm/accounts/views/AccountsDetail';
import Orders from './sm/orders/views/Orders';
// Misc
import Company from './core/company/views/Company';
// import CompanyList from './core/company/views/CompanyList';
// import CompanyDetail from './core/company/views/CompanyDetail';
import UnitDetail from './core/company/views/UnitDetail';
import ReportList from './core/reports/views/ReportList';
import ReportView from './core/reports/views/ReportView';
import SettingsView from './core/settings/views/SettingsView';
import Cases from './core/cases/views/Cases';
// import CalendarView from './core/calendar/views/CalendarView';
// Attendance
// import AttendanceMainPage from './hr/attendance/views/AttendanceMainPage';
// import AttendanceDetail from './hr/attendance/views/AttendanceDetail';
// Contacts
import Contacts from './sm/contacts/views/Contacts';
import Approvals from './core/approvals/views/Approvals';
import Assets from './core/assets/';
import CostCenters from './core/cost-centers';
import TypesDetail from './core/settings/components/types-management/TypesDetail';
import Suppliers from './sm/suppliers';
import Forecasts from './core/forecasts';
import HiringRooms from './hr/hiring-rooms';
import SupplierDetail from './sm/suppliers/views/SupplierDetail';
import GDPRAgreements from './core/gdpr-agreements';
import GDPRView from '../public/gdprs/GDPRView';
import Attendance from './hr/attendance-v2';
import TimesheetsView from './pm/timesheets/views/TimesheetsView';
import Payments from './sm/payments';

/*
# Add any Route you want here to extend dashboard pages
# This component contain all accessible routes for dashboard
*/
const DashboardPages = () => {

    return (
        <Switch>
            {/* Component Examples */}
            <PrivateRoute exact path={routes.COMPONENTS} component={ComponentExamples} />

            {/* Contracts Pages */}
            <PrivateRoute exact path={routes.CONTRACTS} modules={modules.CONTRACTS} component={ContractListView} />

            {/* User Profile Pages */}
            <PrivateRoute exact path={routes.PROFILE} modules={modules.PROFILE} component={Profile} />

            {/* Company Pages */}
            <PrivateRoute exact path={routes.COMPANY} modules={modules.COMPANY} component={Company} />
            {/* <PrivateRoute exact path={routes.COMPANY_LIST} modules={modules.COMPANY} component={CompanyList} /> */}
            {/* <PrivateRoute exact path={routes.COMPANY_DETAIL + ':id'} modules={modules.COMPANY} component={CompanyDetail} /> */}
            <PrivateRoute exact path={routes.COMPANY + 'unit/:unit_id'} modules={modules.COMPANY} component={UnitDetail} />

            {/* Employees Pages */}
            <PrivateRoute exact path={routes.EMPLYOEES_LIST} modules={modules.EMPLOYEES} component={EmployeesList} />
            <PrivateRoute exact path={routes.CREATE_EMPLOYEE} modules={modules.EMPLOYEES} component={CreateAnEmployeeForm} />
            <PrivateRoute exact path={routes.EMPLYOEES_DETAIL + ':id'} modules={modules.EMPLOYEES} component={ProfileCard} />
            {/* <PrivateRoute exact path={routes.EMPLYOEES_DETAIL + ':id'} modules={modules.EMPLOYEES} component={EmployeeDetail} /> */}


            {/* Candidates Pages */}
            <PrivateRoute exact path={routes.CANDIDATES_LIST} modules={modules.CANDIDATES} component={CandidatesList} />
            <PrivateRoute exact path={routes.CANDIDATES_CARD + ':id'} modules={modules.CANDIDATES}>
                <ProfileCard isCandidate={true} />
            </PrivateRoute>
            <PrivateRoute
                exact
                path={routes.HIRING_ROOM + ':room' + routes.CANDIDATES_DETAIL + ':candidate'}
                modules={modules.HIRING_ROOMS}
                component={CandidateDetail}
            />

            {/* HIRING ROOMS Pages */}
            <PrivateRoute path={routes.HIRING} modules={modules.HIRING_ROOMS} component={HiringRooms} />
            {/* <PrivateRoute exact path={routes.HIRING_ROOM + ':id'} modules={modules.HIRING_ROOMS} component={HiringRoom} /> */}

            {/* Benefits Pages */}
            <PrivateRoute exact path={routes.BENEFITS_LIST} module={module.BENEFITS} component={BenefitsList} />
            {/* <PrivateRoute exact path={routes.CREATE_BENEFITS} component={CreateAnEmployeeForm} /> */}

            {/* Teams Pages */}
            <PrivateRoute exact path={routes.TEAMS_LIST} modules={modules.TEAMS} component={TeamsList} />
            <PrivateRoute exact path={routes.TEAMS_DETAIL + ':id'} modules={modules.TEAMS} component={TeamsDetail} />

            {/* Project Pages */}
            <PrivateRoute exact path={routes.PROJECT_LIST} modules={modules.PROJECTS} component={ProjectList} />
            <PrivateRoute exact path={routes.PROJECT_DETAIL + ':id'} modules={modules.PROJECTS} component={DetailView} />
            <PrivateRoute exact path={routes.PROJECT_OLD_DETAIL + ':id'} modules={modules.PROJECTS} component={ProjectDetailView} />

            {/* Invoices Pages */}
            <PrivateRoute path={routes.INVOICES} modules={modules.INVOICES} component={Invoices} />

            {/* Payments Pages */}
            <PrivateRoute path={routes.PAYMENTS} modules={modules.PAYMENTS} component={Payments} />

            {/* Timesheets Pages */}
            <PrivateRoute exact path={routes.TIMESHEETS} modules={modules.TIMESHEETS} component={TimesheetsView} />
            {/* <PrivateRoute exact path={routes.TIMESHEETS + ':id'} component={TimesheetsDetail} /> */}

            {/* Jobs Pages */}
            <PrivateRoute exact path={routes.JOBS} modules={modules.JOBS} component={JobListing} />
            <PrivateRoute exact path={routes.JOBS_APPLY_FORMS} modules={modules.JOBS} component={ApplyForms} />
            {/* <PrivateRoute exact path={routes.JOBS_DETAIL + ":id"} component={JobDetail} /> */}
            <PrivateRoute exact path={routes.CREATE_JOB_OFFER} modules={modules.JOBS} component={JobOfferForm} />
            <PrivateRoute exact path={routes.UPDATE_JOB_OFFER + ':id'} modules={modules.JOBS} component={JobOfferForm} />

            {/* Jobs Position Pages */}
            <PrivateRoute exact path={routes.JOBS_POSITIONS} modules={modules.POSITIONS} component={PositionList} />
            <PrivateRoute exact path={routes.CREATE_JOB_POSITION} modules={modules.POSITIONS} component={PositionForm} />
            <PrivateRoute exact path={routes.UPDATE_JOB_POSITION + ':id'} modules={modules.POSITIONS} component={PositionForm} />

            {/* Orders Pages */}
            <PrivateRoute exact path={routes.ORDERS} modules={modules.ORDERS} component={Orders} />

            {/* Settings Pages */}
            <PrivateRoute exact path={routes.SETTINGS} modules={modules.SETTINGS} component={SettingsView} />
            <PrivateRoute exact path={routes.SETTINGS + ':slug'} modules={modules.SETTINGS} component={SettingsView} />
            <PrivateRoute exact path={routes.SETTINGS + 'types/:slug'} modules={modules.SETTINGS} component={TypesDetail} />

            {/* Accounts Pages */}
            <PrivateRoute exact path={routes.ACCOUNTS} modules={modules.ACCOUNTS} component={AccountsList} />
            <PrivateRoute exact path={routes.ACCOUNTS_DETAIL + ':id'} modules={modules.ACCOUNTS} component={AccountsDetail} />
            <PrivateRoute exact path={routes.ONBOARDING} modules={modules.ACCOUNTS} component={Onboarding} />

            {/* Users Pages */}
            {/* <PrivateRoute exact path={routes.CALENDAR} component={CalendarView} /> */}

            {/* Reports Pages */}
            <PrivateRoute exact path={routes.REPORTS} modules={modules.REPORTS} component={ReportList} />
            <PrivateRoute exact path={routes.REPORTS + ':slug/'} modules={modules.REPORTS} component={ReportView} />

            {/* Couress Pages */}
            <PrivateRoute exact path={routes.COURSES} modules={modules.COURSES} component={CoursesList} />

            {/* Cases Pages */}
            <PrivateRoute exact path={routes.CASES} modules={modules.CASES} component={Cases} />

            {/* Attendance Pages */}
            <PrivateRoute path={routes.ATTENDANCE} modules={modules.ATTENDANCE} component={Attendance} />
            {/* <PrivateRoute exact path={routes.ATTENDANCE_DETAIL + ":id"} modules={modules.ATTENDANCE} component={AttendanceDetail} /> */}

            {/* Contacts page */}
            <PrivateRoute exact path={routes.CONTACTS} modules={modules.CONTACTS} component={Contacts} />

            {/* Approvals Pages */}
            <PrivateRoute exact path={routes.APPROVALS} modules={modules.APPROVALS} component={Approvals} />

            {/* Assets Pages */}
            <PrivateRoute path={routes.ASSETS} modules={modules.ASSETS} component={Assets} />

            {/* Cost centers Pages */}
            <PrivateRoute path={routes.COST_CENTERS} modules={modules.COST_CENTERS} component={CostCenters} />

            {/* Suppliers Pages */}
            <PrivateRoute path={routes.SUPPLIERS} modules={modules.SUPPLIERS} component={Suppliers} />
            <PrivateRoute exact path={routes.SUPPLIERS + ':id'} modules={modules.SUPPLIERS} component={SupplierDetail} />

            {/* Forecasts Pages */}
            <PrivateRoute exact path={routes.GDPR_AGREEMENTS} modules={modules.GDPR_AGREEMENTS} component={GDPRAgreements} />

            {/* Forecasts Pages */}
            <PrivateRoute path={routes.FORECAST} modules={modules.FORECASTS} component={Forecasts} />

            {/* Gdpr Pages */}
            <PublicRoute path={routes.GDPRS + ':id'} modules={modules.GDPRS} component={GDPRView} />

        </Switch>
    );
};

export default DashboardPages;
