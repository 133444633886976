import React from "react";
import { useTranslation } from 'react-i18next';
//components
import { Button, Grid, Icon } from "semantic-ui-react";
import ConfrimModal from '@components/modals/ConfrimModal';
import SortedFileRenderer from '@components/SortedFileRenderer';

const Attachment = ({ docs, onDelete }) => {
    const { t } = useTranslation()

    return (
        <Grid>
            <Grid.Row style={{ padding: 0 }}>
                <SortedFileRenderer name={docs.name} file={docs.file} />
                <ConfrimModal
                    description={t('delete_attachments')}
                    onConfirm={() => onDelete(docs.id)}
                    button={
                        <Button style={{ marginLeft: '5px', paddingTop: '0px', backgroundColor: "transparent" }}>
                            <Icon name="close" color="red" />
                        </Button>
                    }
                />

            </Grid.Row>
        </Grid>
    );
};


export default Attachment;