import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// store
import { API } from "@store/config";
import { requests } from "@helpers/requests";
// components
import { Button } from "semantic-ui-react";
import CategoriesList from "./CategoriesList";
import ManageCourses from "./ManageCourses";
import Loading from "@components/general/Loading";

const ManageModule = ({
    categories,
    setCategories,
    resolveExpiration,
}) => {
    const [manageOption, setManageOptionOption] = useState("courses");
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const { t } = useTranslation();

    const fetchCourses = async () => {
        setLoading(true)
        const request = await requests.get(API.COURSES)

        if (request.status === 200) {
            setCourses(request.response);
        }
        setLoading(false);
    };

    const onDelete = async (id) => {
        const request = await requests.del(API.COURSES + id + "/")
        if (request.status === 204) {
            setCourses((prevState) =>
                prevState.filter((item) => item.id !== id)
            );
        }
    };

    useEffect(() => {
        fetchCourses()
    }, [])



    return (
        <>
            <Button.Group size="large" fluid>
                <Button
                    primary={manageOption === "courses"}
                    onClick={() => {
                        setManageOptionOption("courses");
                    }}
                    content={t("courses")}
                />
                <Button
                    primary={manageOption === "categories"}
                    onClick={() => {
                        setManageOptionOption("categories");
                    }}
                    content={t("categories")}
                />
            </Button.Group>
            {manageOption === "categories" && (
                <CategoriesList
                    categories={categories}
                    setCategories={setCategories}
                />
            )}
            {manageOption === "courses" && (
                <>
                { !loading 
                    ? 
                        <ManageCourses
                            courses={courses}
                            setCourses={setCourses}
                            resolveExpiration={resolveExpiration}
                            onDelete={onDelete}
                        />
                    : <Loading/>
                }
                </>
            )}
        </>
    );
};

export default ManageModule;
