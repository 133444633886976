import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// store
import { useHasPermissions } from '@helpers/hooks';
import { addLogo } from "@store/actions";
import { companyService } from "@store/services/ServiceCompany";
import { publicService } from "@store/services/ServicePublic";
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import AvatarIcon from '@components/AvatarIcon';
import { Grid, Header, Button, Card, Label } from "semantic-ui-react";

const CompanyHeader = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const canManage = useHasPermissions(['company.c_change_active_company', 'company.c_change_inactive_companies']);

    const uploadLogo = async (image) => {
        const formData = new FormData();
        formData.append("logo", image);

        const result = await companyService.updateLogo(props.data.id, formData);

        if(result.status === 200){
            if(props.data.is_active){
                dispatch(addLogo(result.response.logo));
            }
        }

        return result;
    }

    const deleteLogo = async () => {
        const result = await companyService.deleteLogo(props.data.id);

        if(result.status === 204){
            if(props.data.is_active){
                const result = await publicService.getLogo();

                if( result.status === 200 ){
                    dispatch(addLogo(result.response.logo))
                }
            }
        }
        return result;
    }

    return (
        <CanView permissions={["company.c_view_active_company", "company.c_view_inactive_companies"]}>
            <Card fluid style={{ borderRadius: 0 }}>
                <Grid centered>
                    <Grid.Row verticalAlign='middle'>
                        <Grid.Column computer={5} tablet={5} mobile={16} style={{ paddingTop: "1rem", paddingBottom:"1rem" }}>
                            <AvatarIcon
                                size={150}
                                name={ props.data.name || "."}
                                src={ props.data.logo ? props.data.logo : props.logo.logo}
                                handleUpload={uploadLogo}
                                handleDelete={deleteLogo}
                                uploadable={canManage}
                            />
                        </Grid.Column>
                        <Grid.Column computer={11} tablet={11} mobile={16}>
                            <Grid><Header as="h2" style={{ fontSize: '3rem' }}>{props.data.name}</Header>{props.action} </Grid>
                            <Grid columns={3} doubling>
                                <Grid.Row style={{ padding: 0 }}>
                                    <CanView permissions={["company.c_change_active_company", "company.c_change_inactive_companies"]}>
                                        <Grid.Column style={{fontSize:"1.3rem"}} computer={5} tablet={5} mobile={16} >
                                            <Label basic  size="large">
                                                <span style={{paddingRight:"1rem"}}> {props.stats.unit_count} </span>
                                                <span>{t('unit_count')}</span>
                                            </Label>

                                            <Label basic size="large">
                                                <span style={{paddingRight:"1rem"}}> {props.stats.employee_count} </span>
                                                <span> {t('employee_count')}</span>
                                            </Label>
                                        </Grid.Column>
                                    </CanView>

                                    { props.data.website ?
                                        <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: "1rem" }}>
                                        <Button icon={<Icon name="earth-outline" style={{ marginRight: "0.5rem" }}/>} as='a' href={props.data.website} target="_blank" size="small" content={<span style={{ position: "relative", top: "-0.2rem" }}>{ t('visit_website') }</span>}/>
                                        </Grid.Column> :
                                        <CanView permissions={["company.c_change_active_company", "company.c_change_inactive_companies"]}>
                                            <Grid.Column computer={16} tablet={16} mobile={16} style={{ marginTop: "1rem" }}>
                                                    {props.actionAddWebsite}
                                            </Grid.Column>
                                        </CanView>
                                    }

                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card>
        </CanView>
    )

}

export default CompanyHeader;
