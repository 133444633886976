import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { getRequest, createRequest, updateRequest, deleteRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
// import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Form, Divider, Segment, Grid, Header, Button, Icon, Loader, Popup, Message } from 'semantic-ui-react';
import Sticky from 'react-sticky-el';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import BankDetailsForm from '../components/BankDetailsForm';
import SupplierDetailsForm from '../components/SupplierDetailsForm';
import AccountDetailsForm from '../components/AccountDetailsForm';
// import PhoneField from '@components/forms/common/PhoneField';

const InvoiceForm = ({ connection, journal }) => {
    const { t } = useTranslation();
    const params = useParams()
    const history = useHistory()

    const [invoice, setInvoice] = useState({ currency: "EUR" })
    const [loadingItems, setLoadingItems] = useState(true)
    const [items, setItems] = useState([])
    const [dueDays, setDueDays] = useState(14)
    const [withItems, setWithItems] = useState(true)
    const [isProcessing, setIsProcessing] = useState(false);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        async function fetchInvoice(){
            setLoading(true)

            const request = await getRequest(API.INVOICES + params.id + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id)

            if( request.status === 200 ){
                setInvoice({
                    ...request.response,
                    date_issue: request.response.date_issue !== null ? request.response.date_issue : moment().format("YYYY-MM-DD"),
                    date_supply: request.response.date_supply !== null ? request.response.date_supply : moment().format("YYYY-MM-DD"),
                    account: request.response.account.id,
                })
            }

            setLoading(false)
        }

        fetchInvoice()
        // eslint-disable-next-line
    }, [params.id])


    // fetch items if exists
    useEffect(() => {
        async function fetchInvoiceItems(){
            setLoadingItems(true)
            const request = await getRequest(API.INVOICES + "items/?connection="+connection.connection_id + "&account_ids=" + journal.id + "&invoice__id=" + params.id)
            
            if( request.status === 200 ){
                if( request.response.length > 0 ){
                    let itemList = []
                    for (let i = 0; i < request.response.length; i++) {
                        itemList.push({
                            id: request.response[i].id,
                            invoice: params.id,
                            service_type: request.response[i].service_type,
                            amount: request.response[i].amount,
                            code: request.response[i].code,
                            name: request.response[i].name,
                            unit: request.response[i].unit,
                            unit_price: request.response[i].unit_price,
                            tax_rate: request.response[i].tax_rate,
                            discount: request.response[i].discount,
                            note: request.response[i].note,
                            processing: false,
                            error: null
                        });
                        
                    }
                    setItems(itemList)
                    setWithItems(true)
                }
            }
            setLoadingItems(false)
        }

        fetchInvoiceItems()
        // eslint-disable-next-line
    }, [params.id])

    const onItemFormAdd = () => {
        setItems(prevState => [...prevState, {
            id: 0,
            invoice: params.id,
            service_type: 1,
            amount: null,
            code: null,
            name: null,
            unit: null,
            unit_price: null,
            tax_rate: "20.00",
            discount: 0,
            note: "",
            processing: false,
            error: null
        }])
    }

    const onRecordChange = (index, updatedKey, value) => {
        setItems(prevState => prevState.filter((record, key) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const onRemove = async (item, index) => {
        onRecordChange(index, "processing", true)
        if( item.id === 0 ){
            setItems(prevState => prevState.filter((stateItem, key) => key !== index))
        } else {
            const request = await deleteRequest(API.INVOICES + "items/" + item.id + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id)
            if( request.status === 204 ){
                setItems(prevState => prevState.filter(stateItem => stateItem.id !== item.id))
            }
        }
        onRecordChange(index, "processing", false)
    }

    useEffect(() => {
        if( dueDays > 0 && (invoice.date_issue !== "" || invoice.date_issue !== null) ){
            setInvoice(prevState => {
                return {
                    ...prevState,
                    date_due: moment(invoice.date_issue, "YYYY-MM-DD").add(dueDays, 'days').format('YYYY-MM-DD')
                }
            })
        }
    }, [invoice.date_issue, dueDays])

    const handleSubmit = async () => {
        setIsProcessing(true);
        let invoiceData = invoice
        // delete invoiceData.account
        delete invoiceData.group
        delete invoiceData.price
        delete invoiceData.price_to_pay

        const request = await updateRequest(API.INVOICES + params.id + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id, invoiceData)
        if( request.status === 200 ){
            // loop over items and save them
            // const itemsToBeRemoved = []
            for (let i = 0; i < items.length; i++) {
                if( items[i].id === 0 && (items[i].name === "" || items[i].name === null) && 
                    (items[i].amount === "" || items[i].amount === null) && 
                    (items[i].unit_price === "" || items[i].unit_price === null)) {
                    // itemsToBeRemoved.push(parseInt(i))
                    continue;
                } 

                let data = {
                    invoice: parseInt(items[i].invoice),
                    service_type: parseInt(items[i].service_type),
                    amount: parseFloat(items[i].amount),
                    code: items[i].code,
                    name: items[i].name,
                    unit: items[i].unit,
                    unit_price: parseFloat(items[i].unit_price),
                    tax_rate: parseFloat(items[i].tax_rate),
                    discount: parseFloat(items[i].discount),
                    note: items[i].note
                }


                onRecordChange(i, "processing", true)
                let request = undefined
                if( items[i].id === 0 ){ // create item
                    request = await createRequest(API.INVOICES + "items/?connection=" + connection.connection_id + "&account_ids=" + journal.id, data)
                } else { // update item
                    request = await updateRequest(API.INVOICES + "items/" + items[i].id + "/?connection=" + connection.connection_id + "&account_ids=" + journal.id, data)
                    
                }
                
                if( request !== undefined ){
                    if( request.status === 201 ){
                        onRecordChange(i, "id", request.response.id)
                    }
                    
                    if( request.status === 201 || request.status === 200 ){
                        // update item state
                        onRecordChange(i, "error", null)
                    } else {
                        // set error
                        onRecordChange(i, "error", request.response)
                    }
                }

                onRecordChange(i, "processing", false)
            }


            // when everything is OK
            if( items.filter(item => item.error !== null).length === 0 ){
                if( invoice.is_issued ){
                    history.push(routes.INVOICES + "incomes/")
                } else{
                    history.push(routes.INVOICES + "expenses/")
                }
            }
        }
        setIsProcessing(false);
    }

    const calculateTotalForItem = (amount, unitPrice, taxMode, taxRate) => {
        let total = 0
        let parsedAmount = parseFloat(amount)
        let parsedUnitPrice = parseFloat(unitPrice)
        if( !isNaN(parsedAmount) && !isNaN(parsedUnitPrice) ) {
            total = parseFloat(parsedAmount * parsedUnitPrice)

            // check tax mode (VAT PAYER)
            if( parseInt(taxMode) === 1 && taxRate > 0 ){
                const tax = (total / 100) * parseFloat(taxRate)
                total = parseFloat(tax) + parseFloat(total)
            }
        }

        return parseFloat(total).toFixed(2)
    }

    const calculateTotalForInvoice = (items, taxMode) => {
        let total = 0
        for (let i = 0; i < items.length; i++) {
            let parsedAmount = parseFloat(items[i].amount)
            let parsedUnitPrice = parseFloat(items[i].unit_price)

            if( !isNaN(parsedAmount) && !isNaN(parsedUnitPrice) ) {
                let totalForItem = parseFloat(parsedAmount * parsedUnitPrice)

                // check tax mode (VAT PAYER)
                if( parseInt(taxMode) === 1 && parseFloat(items[i].tax_rate) > 0 ){
                    const tax = (totalForItem / 100) * parseFloat(items[i].tax_rate)
                    totalForItem = parseFloat(tax) + parseFloat(totalForItem)
                }

                total += totalForItem
            }

        }

        return parseFloat(total).toFixed(2)
    }

    const isValidTaxRate = (taxRate) => {
        if( isNaN(parseFloat(taxRate)) || taxRate === "" || parseFloat(taxRate) < 0 ) return false 

        return true
    }

    const setDotSeparator = (input) => {
        return input.replace(',', '.')
    }

    const SupplierColumn = () => {
        const { t } = useTranslation()

        return (
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Header as="h3">
                    { t('supplier') }:
                </Header>
                <p style={{ fontWeight: "bold" }}>{ invoice.supplier_name === null ? "not specified" : invoice.supplier_name }</p>
                <SuperDuperModal
                    header={t('supplier')}
                    size="small"
                    trigger={
                        <span className="ref-link" style={{ color: "var(--primary)" }}>
                            { t('manage_supplier_details') }
                        </span>
                    }
                    content={
                        <SupplierDetailsForm 
                            invoice={invoice} 
                            setInvoice={setInvoice}
                        />
                    }
                />
            </Grid.Column>
        )
    }

    const AccountColumn = () => {
        const { t } = useTranslation()
        // const [accountOptions, setAccountOptions] = useState([])

        return (
            <Grid.Column computer={8} tablet={8} mobile={16}>
                <Header as="h3">
                    { t('account') }:
                </Header>
                {/* !! TODO: Find the way to fill choice field with text value and complete data for account */}
                {/* <SuperField as="choice"
                    size="small"
                    search
                    icon="search"
                    iconPosition="right"
                    placeholder="Enter ID or account name"
                    customOptions={accountOptions}
                    value={invoice.customer_name !== null ? invoice.customer_name.toString() : ""}
                    defaultSearchQuery={invoice.customer_name !== null ? invoice.customer_name : ""}
                    onChange={(e, { options }) => {
                        if( options.length > 0 ){
                            if( options[0].item !== undefined ){
                                setInvoice(prevState => ({ ...prevState, customer_name: options[0].item.name }))
                            }
                        } 
                    }} 
                    onSearchChange={ async (e, { searchQuery }) => {
                        const request = await getRequest(API.ACCOUNTS + "?query={id, name, business_detail, contact_info}&search=" + searchQuery)
                        if( request.status === 200 ){
                            setAccountOptions(request.response.map(item => {
                                return { key: item.id, value: item.name, text: item.name, item: item }
                            }))
                        }
                    }}
                /> */}

                <p style={{ fontWeight: "bold" }}> { invoice.customer_name === null ? "not specified" : invoice.customer_name } </p>
                <SuperDuperModal
                    header={t('account')}
                    size="small"
                    trigger={
                        <span className="ref-link" style={{ color: "var(--primary)" }}>
                            { t('manage_account_details') }
                        </span>
                    }
                    content={
                        <AccountDetailsForm 
                            invoice={invoice} 
                            setInvoice={setInvoice}
                        />
                    }
                />
            </Grid.Column>
        )
    }

    const BaseCalculator = ({ item, index }) => {

        const CalculatorForm = ({ onClose }) => {
            const { t } = useTranslation();
            const [taxRate, setTaxRate] = useState(item.tax_rate)
            const [unitPrice, setUnitPrice] = useState("")
            const [taxPrice, setTaxPrice] = useState("")
            const [totalPrice, setTotalPrice] = useState("")

            const onConfirm = () => {
                onRecordChange(index, "tax_rate", taxRate)
                onRecordChange(index, "unit_price", unitPrice)
                onClose()
            }

            useEffect(() => {
                setUnitPrice("")
                setTaxPrice("")
                setTotalPrice("")
            }, [taxRate])

            const onCalculation = (field, value) => {
                if( field === "unitPrice" ){ // perform calculation with unitPrice and tax
                    setUnitPrice(value)
                    const tax = (parseFloat(value) / 100) * parseFloat(taxRate)
                    setTaxPrice(parseFloat(tax).toFixed(2))
                    setTotalPrice(parseFloat(parseFloat(tax) + parseFloat(value)).toFixed())
                } else if( field === "taxPrice" ){ // perform calculation with taxPrice and tax
                    setTaxPrice(value)
                    const base = (parseFloat(value) / parseFloat(taxRate)) * 100
                    setUnitPrice(parseFloat(base).toFixed(2))
                    setTotalPrice(parseFloat(parseFloat(base) + parseFloat(value)).toFixed(2))
                } else if( field === "totalPrice" ){ // perform calculation with totalPrice and tax
                    setTotalPrice(value)
                    const base = (parseFloat(value) / (100 + parseFloat(taxRate))) * 100
                    setUnitPrice(parseFloat(base).toFixed(2))
                    setTaxPrice(parseFloat(parseFloat(value) - parseFloat(base)).toFixed(2))
                }
            }

            return (
                <Form>
                    <Message
                        style={{ borderRadius: 0 }}
                        info
                        header={ t('hint_calculate_base_unit_price') }
                        content={ t('hint_enter_values_to_calculate') }
                    />
                    <Divider/>
                    <Form.Field>
                        <label> { t('tax_rate') } </label>
                        <Button.Group size="small" basic style={{ borderRadius: 0 }}>
                            <Button type="button" 
                                content={"0 %"}
                                active={parseFloat(taxRate) === 0}
                                style={{ fontWeight: parseFloat(taxRate) === 0 ? "bold" : "normal" }}
                                onClick={ () => setTaxRate(0)} 
                            />
                            <Button type="button" 
                                content={"10 %"}
                                active={parseFloat(taxRate) === 10}
                                style={{ fontWeight: parseFloat(taxRate) === 10 ? "bold" : "normal" }}
                                onClick={ () => setTaxRate(10) }
                            />
                            <Button type="button"
                                content={"20 %"}
                                active={parseFloat(taxRate) === 20}
                                style={{ fontWeight: parseFloat(taxRate) === 20 ? "bold" : "normal" }}
                                onClick={ () => setTaxRate(20) }
                            />
                            <Button type="button" style={{ padding: 0, cursor: "normal"}}>
                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                    <FlexItem>
                                        <SuperField as="input" 
                                            size="small" 
                                            placeholder={ t("custom") } 
                                            style={{ width: "5rem"}}
                                            value={ [0, 10, 20].includes(parseFloat(taxRate)) ? "" : taxRate }
                                            onChange={(e, { value }) => {
                                                if( value === "" ){
                                                    setTaxRate(20)
                                                } else if( value === 0 ){
                                                    setTaxRate(0)
                                                } else if( value > 0 ){
                                                    setTaxRate(setDotSeparator(value))
                                                }
                                            }}
                                        />
                                    </FlexItem>
                                    <FlexItem textAlign="center">
                                        <div style={{ margin: "0 0.5rem" }}> % </div>
                                    </FlexItem>
                                </FlexRow>
                            </Button>
                        </Button.Group>
                    </Form.Field>
                    <Divider/>
                    <Form.Group widths="equal">
                        <SuperField as="input" 
                            value={ unitPrice }
                            size="small"
                            disabled={!isValidTaxRate(taxRate)}
                            label={t('unit_price_without_tax')}
                            onChange={(e, { value }) => onCalculation("unitPrice", setDotSeparator(value))}
                        />
                        <SuperField 
                            value={ taxPrice }
                            as="input"
                            size="small" 
                            label={t('tax')}
                            disabled={!isValidTaxRate(taxRate) || parseFloat(taxRate) === 0} 
                            onChange={(e, { value }) => onCalculation("taxPrice", setDotSeparator(value))}
                        />
                        <SuperField 
                            value={ totalPrice }
                            as="input"
                            size="small" 
                            label={t('unit_price_with_tax')}
                            disabled={!isValidTaxRate(taxRate) || parseFloat(taxRate) === 0} 
                            onChange={(e, { value }) => onCalculation("totalPrice", setDotSeparator(value))}
                        />
                    </Form.Group>
                    <p style={{ fontWeight: "bold" }}>{ t('hint_enter_at_least_one_value') }.</p>
                    <Divider/>
                    <Form.Field style={{ textAlign: "right" }}>
                        <Button 
                            size="small"
                            primary
                            type="button" 
                            content={t('confirm')} 
                            onClick={() => onConfirm()}
                        />
                    </Form.Field>
                </Form>
            )
        }

        return (
            <SuperDuperModal 
                size="small"
                trigger={
                    <Icon name="calculator" link>
                        <Popup
                            trigger={
                                <Icon style={{ paddingTop: "0.8rem", paddingLeft: "0.3rem", opacity: 0 }} name='calculator'/>
                            }
                            content={t('hint_calculate_base_unit_price')}
                        />
                    </Icon>
                }
                content={<CalculatorForm/>}
            />
        )
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Grid as={Segment} loading={loading} style={{padding: 0, border: "none", boxShadow: "none", background: "transparent", minHeight: "10rem", textAlign: "left", marginBottom: "5rem" }}>
                { !loading && 
                <>
                    <Grid.Row verticalAlign="middle" style={{ padding: 0 }}>
                        <Grid.Column computer={8} tablet={8} mobile={16}>
                            <Header as="h2">{ invoice.is_issued ? t('send_invoice') : t('recieved_invoice') } - #{ invoice.number }</Header>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16} textAlign="right">
                            <Button.Group basic style={{ borderRadius: 0 }}>
                                <Button 
                                    type="button"
                                    active={withItems}
                                    onClick={ () => setWithItems(true) }
                                    style={{ fontWeight: withItems ? "bold" : "normal" }}
                                >
                                        <Icon name="list"/> { t('invoice_with_items') }
                                </Button>
                                <Button 
                                    type="button"
                                    disabled={items.length > 0}
                                    active={!withItems}
                                    onClick={ () => setWithItems(false) }
                                    style={{ fontWeight: !withItems ? "bold" : "normal" }}
                                >
                                    { t('invoice_without_items') }
                                </Button>
                            </Button.Group>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider style={{ marginBottom: 0 }}/>
                    <Grid.Row>
                        <Grid.Column computer={4} tablet={4} mobile={16}>
                            <Sticky stickyStyle={{ marginTop: "5rem" }}>
                                <Segment style={{minHeight: "10rem", textAlign: "left", marginBottom: "5rem"}}>
                                    <SuperField as="input"
                                        size="small"
                                        icon='file text'
                                        iconPosition='left'
                                        label={t('invoice_number')}
                                        value={invoice.number}
                                        onChange={(e, { value }) => setInvoice({
                                            ...invoice,
                                            number: value
                                        })}
                                    />

                                    <Form.Group widths="equal">
                                        <SuperField as="choice"
                                            fluid
                                            size="small"
                                            clearable={false}
                                            label={t('status')}
                                            customOptions={[
                                                { key: 4, value: 4, text: t('draft') },
                                                { key: 1, value: 1, text: t('pending') },
                                                { key: 2, value: 2, text: t('canceled') },
                                                { key: 3, value: 3, text: t('paid') },
                                            ]}
                                            value={invoice.status}
                                            onChange={(e, { value }) => setInvoice({
                                                ...invoice,
                                                status: value
                                            })}
                                        />

                                        <SuperField as="choice"
                                            size="small"
                                            fluid
                                            label={t('payment_method')}
                                            customOptions={[
                                                { key: 1, value: 1, text: t('payment_order') },
                                                { key: 2, value: 2, text: t('cash') },
                                                { key: 3, value: 3, text: t('cash_on_delivery') },
                                                { key: 4, value: 4, text: t('credit') },
                                                { key: 5, value: 5, text: t('card_payment') },
                                            ]}
                                            value={invoice.payment_method}
                                            onChange={(e, { value }) => setInvoice({
                                                ...invoice,
                                                payment_method: value
                                            })}
                                        />
                                    </Form.Group>

                                    <SuperField as="input"
                                        size="small"
                                        label={ t('delivery_method') }
                                        value={invoice.delivery_method}
                                        onChange={(e, { value }) => setInvoice({
                                            ...invoice,
                                            delivery_method: value
                                        })}
                                    />

                                    <Divider/>

                                    <SuperField as="choice"
                                        search
                                        size="small"
                                        label={t('currency')}
                                        type="currency_codes"
                                        value={invoice.currency}
                                        onChange={(e, { value }) => setInvoice({
                                            ...invoice,
                                            currency: value
                                        })}
                                    />

                                    <Divider/>

                                    {/* <Form.Group widths="equal">
                                        <SuperField as="input"
                                            size="small"
                                            label={ t('sum') }
                                            help="Fill total sum if you are not going to add any items"
                                            value={invoice.price}
                                            onChange={(e, { value }) => setInvoice({
                                                ...invoice,
                                                price: value
                                            })}
                                        />
                                    </Form.Group> */}

                                    <Divider/>

                                    <SuperField as="input"
                                        fluid
                                        size="small"
                                        label={t('order_number')}
                                        value={invoice.order_number}
                                        onChange={(e, { value }) => setInvoice({
                                            ...invoice,
                                            order_number: value
                                        })}
                                    />

                                    <SuperField as="textarea"
                                        size="small"
                                        label={t('note')}
                                        value={invoice.note}
                                        onChange={(e, { value }) => setInvoice({
                                            ...invoice,
                                            note: value
                                        })}
                                    />

                                    
                                    <Divider/>
                                    <Form.Field style={{ textAlign: "right" }}>
                                        <Button
                                            fluid
                                            primary
                                            loading={isProcessing}
                                            disabled={isProcessing}
                                        >
                                            { t('save') }
                                        </Button>
                                    </Form.Field>
                                </Segment>
                            </Sticky>
                        </Grid.Column>
                        <Grid.Column computer={12} tablet={12} mobile={16}>
                            <Segment>
                                <Grid>
                                    <Grid.Row divided>
                                        <SupplierColumn/> 
                                        <AccountColumn/>
                                    </Grid.Row>                  
                                </Grid>
                                <Divider/>
                                <Grid>
                                    <Grid.Row style={{ padding: 0 }}>
                                        { invoice.is_issued 
                                            ? 
                                            <>
                                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                                    <Form.Field>
                                                        <SuperField as="input"
                                                            style={{ marginBottom: "0.5rem" }}
                                                            className="mb-0"
                                                            size="small"
                                                            label={ t('iban') }
                                                            value={invoice.bank_iban}
                                                            onChange={(e, { value }) => setInvoice({
                                                                ...invoice,
                                                                bank_iban: value
                                                            })}
                                                        />
                                                        <SuperDuperModal
                                                            header="Bank details"
                                                            trigger={
                                                                <span className="ref-link" style={{ color: "var(--primary)" }}>
                                                                    { t('manage_bank_details') }
                                                                </span>
                                                            }
                                                            content={
                                                                <BankDetailsForm 
                                                                    invoice={invoice} 
                                                                    setInvoice={setInvoice}
                                                                />
                                                            }
                                                        />
                                                    </Form.Field>
                                                    <SuperField as="input"
                                                        size="small"
                                                        label={ t('variable_symbol') }
                                                        value={invoice.variable_symbol}
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            variable_symbol: value
                                                        })}
                                                    />
                                                    <SuperField as="input"
                                                        size="small"
                                                        label={ t('constant_symbol') }
                                                        value={invoice.constant_symbol}
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            constant_symbol: value
                                                        })}
                                                    />
                                                    <SuperField as="input"
                                                        size="small"
                                                        label={ t('specific_symbol') }
                                                        value={invoice.specific_symbol}
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            specific_symbol: value
                                                        })}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                                    <Form.Field style={{ marginBottom: "2.9rem" }}>
                                                        <label> { t('due_days') } </label>
                                                        <Button.Group size="small" basic style={{ borderRadius: 0 }}>
                                                            <Button type="button" active={dueDays === 14} style={{ fontWeight: dueDays === 14 ? "bold" : "normal" }} onClick={ () => setDueDays(14) } content={"14 " + t('days')}/>
                                                            <Button type="button" active={dueDays === 21} style={{ fontWeight: dueDays === 21 ? "bold" : "normal" }} onClick={ () => setDueDays(21) } content={"21 " + t('days')}/>
                                                            <Button type="button" active={dueDays === 28} style={{ fontWeight: dueDays === 28 ? "bold" : "normal" }} onClick={ () => setDueDays(28) } content={"28 " + t('days')}/>
                                                            <Button type="button" style={{ padding: 0, cursor: "normal"}}>
                                                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                                                    <FlexItem>
                                                                        <SuperField as="input" 
                                                                            size="small" 
                                                                            placeholder={ t("custom") } 
                                                                            style={{ width: "5rem"}}
                                                                            value={ [14, 21, 28].includes(parseInt(dueDays)) ? "" : dueDays }
                                                                            onChange={(e, { value }) => {
                                                                                if( value === "" || value === 0 ){
                                                                                    setDueDays(14)
                                                                                }

                                                                                if( value > 0 ){
                                                                                    setDueDays(parseInt(value))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FlexItem>
                                                                    <FlexItem textAlign="center">
                                                                        <div style={{ margin: "0 0.5rem" }}> { t('days') } </div>
                                                                    </FlexItem>
                                                                </FlexRow>
                                                            </Button>
                                                        </Button.Group>
                                                    </Form.Field>
                                                    <SuperField as="datepicker"
                                                        size="small"
                                                        width={8}
                                                        dateFormat="YYYY-MM-DD"
                                                        name='date'
                                                        label={ t('date_of_issue') }
                                                        placeholder={ t('date_of_issue') }
                                                        value={invoice.date_issue !== null ? invoice.date_issue : moment().format("YYYY-MM-DD")}
                                                        iconPosition="left"
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            date_issue: value
                                                        })}
                                                    />

                                                    <SuperField as="datepicker"
                                                        dateFormat="YYYY-MM-DD"
                                                        width={8}
                                                        name='date'
                                                        size="small"
                                                        label={ t('date_of_supply') }
                                                        placeholder={ t('date_of_supply') }
                                                        value={invoice.date_supply !== null ? invoice.date_supply : moment().format("YYYY-MM-DD")}
                                                        iconPosition="left"
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            date_supply: value
                                                        })}
                                                    />

                                                    <Form.Field>
                                                        <label> { t('date_of_due') } </label>
                                                        <SuperField as="datepicker"
                                                            dateFormat="YYYY-MM-DD"
                                                            // className="invisible-input-border"
                                                            width={8}
                                                            style={{ opacity: 1 }}
                                                            name='date'
                                                            size="small"
                                                            value={invoice.date_due}
                                                            onChange={(e, { value }) => setInvoice({
                                                                ...invoice,
                                                                date_due: value
                                                            })}
                                                            iconPosition="left"
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </>
                                            :
                                            <>
                                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                                    <Form.Field style={{ marginBottom: "2.9rem" }}>
                                                        <label> { t('due_days') } </label>
                                                        <Button.Group size="small" basic style={{ borderRadius: 0 }}>
                                                            <Button type="button" active={dueDays === 14} style={{ fontWeight: dueDays === 14 ? "bold" : "normal" }} onClick={ () => setDueDays(14) } content={"14 " + t('days')}/>
                                                            <Button type="button" active={dueDays === 21} style={{ fontWeight: dueDays === 21 ? "bold" : "normal" }} onClick={ () => setDueDays(21) } content={"21 " + t('days')}/>
                                                            <Button type="button" active={dueDays === 28} style={{ fontWeight: dueDays === 28 ? "bold" : "normal" }} onClick={ () => setDueDays(28) } content={"28 " + t('days')}/>
                                                            <Button type="button" style={{ padding: 0, cursor: "normal"}}>
                                                                <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                                                    <FlexItem>
                                                                        <SuperField as="input" 
                                                                            size="small" 
                                                                            placeholder={ t("custom") } 
                                                                            style={{ width: "5rem"}}
                                                                            value={ [14, 21, 28].includes(parseInt(dueDays)) ? "" : dueDays }
                                                                            onChange={(e, { value }) => {
                                                                                if( value === "" || value === 0 ){
                                                                                    setDueDays(14)
                                                                                }

                                                                                if( value > 0 ){
                                                                                    setDueDays(parseInt(value))
                                                                                }
                                                                            }}
                                                                        />
                                                                    </FlexItem>
                                                                    <FlexItem textAlign="center">
                                                                        <div style={{ margin: "0 0.5rem" }}> { t('days') } </div>
                                                                    </FlexItem>
                                                                </FlexRow>
                                                            </Button>
                                                        </Button.Group>
                                                    </Form.Field>
                                                    <SuperField as="datepicker"
                                                        size="small"
                                                        width={8}
                                                        dateFormat="YYYY-MM-DD"
                                                        name='date'
                                                        label={ t('date_of_issue') }
                                                        placeholder={ t('date_of_issue') }
                                                        value={invoice.date_issue !== null ? invoice.date_issue : moment().format("YYYY-MM-DD")}
                                                        iconPosition="left"
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            date_issue: value
                                                        })}
                                                    />

                                                    <SuperField as="datepicker"
                                                        dateFormat="YYYY-MM-DD"
                                                        width={8}
                                                        name='date'
                                                        size="small"
                                                        label={ t('date_of_supply') }
                                                        placeholder={ t('date_of_supply') }
                                                        value={invoice.date_supply !== null ? invoice.date_supply : moment().format("YYYY-MM-DD")}
                                                        iconPosition="left"
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            date_supply: value
                                                        })}
                                                    />

                                                    <Form.Field>
                                                        <label> { t('date_of_due') } </label>
                                                        <SuperField as="datepicker"
                                                            dateFormat="YYYY-MM-DD"
                                                            // className="invisible-input-border"
                                                            width={8}
                                                            style={{ opacity: 1 }}
                                                            name='date'
                                                            size="small"
                                                            value={invoice.date_due}
                                                            onChange={(e, { value }) => setInvoice({
                                                                ...invoice,
                                                                date_due: value
                                                            })}
                                                            iconPosition="left"
                                                        />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                                    <Form.Field>
                                                        <SuperField as="input"
                                                            style={{ marginBottom: "0.5rem" }}
                                                            className="mb-0"
                                                            size="small"
                                                            label={ t('iban') }
                                                            value={invoice.bank_iban}
                                                            onChange={(e, { value }) => setInvoice({
                                                                ...invoice,
                                                                bank_iban: value
                                                            })}
                                                        />
                                                        <SuperDuperModal
                                                            header="Bank details"
                                                            trigger={
                                                                <span className="ref-link" style={{ color: "var(--primary)" }}>
                                                                    { t('manage_bank_details') }
                                                                </span>
                                                            }
                                                            content={
                                                                <BankDetailsForm 
                                                                    invoice={invoice} 
                                                                    setInvoice={setInvoice}
                                                                />
                                                            }
                                                        />
                                                    </Form.Field>
                                                    <SuperField as="input"
                                                        size="small"
                                                        label={ t('variable_symbol') }
                                                        value={invoice.variable_symbol}
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            variable_symbol: value
                                                        })}
                                                    />
                                                    <SuperField as="input"
                                                        size="small"
                                                        label={ t('constant_symbol') }
                                                        value={invoice.constant_symbol}
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            constant_symbol: value
                                                        })}
                                                    />
                                                    <SuperField as="input"
                                                        size="small"
                                                        label={ t('specific_symbol') }
                                                        value={invoice.specific_symbol}
                                                        onChange={(e, { value }) => setInvoice({
                                                            ...invoice,
                                                            specific_symbol: value
                                                        })}
                                                    />
                                                </Grid.Column>
                                            </>
                                        }
                                    </Grid.Row>
                                </Grid>
                            </Segment>

                            { withItems && 
                                <Segment loading={loadingItems} style={{ minHeight: "10rem" }}>
                                    { !loadingItems && 
                                        <>
                                            <FlexRow background="transparent" padding="0.5rem">
                                                <FlexItem>
                                                    <Header as="h3" content={ t('invoice_items') }/>
                                                </FlexItem>
                                                <FlexItem textAlign="right">
                                                    <strong>{ t('mode') }</strong>: 
                                                    <Button.Group size="small" basic style={{ borderRadius: 0, marginLeft: "1rem" }}>
                                                        <Button 
                                                            type="button" 
                                                            active={ invoice.tax_mode === 1 } 
                                                            style={{ fontWeight: invoice.tax_mode === 1 ? "bold" : "normal" }} 
                                                            onClick={() => setInvoice({...invoice, tax_mode: 1})}
                                                            >
                                                            <Icon style={{ color: "var(--success)" }} name="check"/> { t('vat_payer') }
                                                        </Button>
                                                        <Button 
                                                            type="button"
                                                            active={ invoice.tax_mode === 2 }
                                                            style={{ fontWeight: invoice.tax_mode === 2 ? "bold" : "normal" }} 
                                                            onClick={() => setInvoice({...invoice, tax_mode: 2})}
                                                        >
                                                            <Icon style={{ color: "var(--danger)" }} name="close"/> { t('not_vat_payer') }
                                                        </Button>
                                                    </Button.Group>
                                                </FlexItem>
                                            </FlexRow>
                                            <FlexRow fontSize="0.9" background="transparent" padding="1rem 0" borders>
                                                <FlexItem>
                                                    <Form.Group style={{ marginBottom: 0 }}>
                                                        <Form.Field style={{ paddingLeft: "1rem", fontWeight: "bold", width: "70px" }} content={t('code')}/>
                                                        <Form.Field width={5} style={{ marginLeft: "1rem", fontWeight: "bold" }} content={<> { t('name') } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/>
                                                        <Form.Field width={2} style={{ fontWeight: "bold" }} content={<> { t('amount') } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/>
                                                        <Form.Field width={1} style={{ fontWeight: "bold"}} content={t('price_unit')}/>
                                                        <Form.Field width={3} style={{ fontWeight: "bold" }} content={<> { t('unit_price') } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/>
                                                        { invoice.tax_mode === 1 && <Form.Field width={2} style={{ fontWeight: "bold" }} content={<> { t('tax_rate') + " (%)" } <sup style={{ color: "var(--danger)" }}>*</sup> </>}/> }
                                                        <Form.Field width={3} style={{ fontWeight: "bold" }} content={t('final_price')}/>
                                                    </Form.Group>
                                                </FlexItem>
                                                <FlexItem basis="10%"/>
                                            </FlexRow>

                                            { items.map((item, key) => (
                                                <FlexRow key={key} fontSize="0.9" background="transparent" padding="1rem 0" borders>
                                                    <FlexItem>
                                                        <Form.Group style={{ marginBottom: "0.5rem" }}>
                                                            <SuperField size="small" as="input" style={{ width: "70px" }} value={item.code} onChange={(e, { value }) => onRecordChange(key, "code", value)}/>
                                                            <SuperField size="small" as="input" error={item.error?.name ? true : false} width={5} value={item.name} onChange={(e, { value }) => onRecordChange(key, "name", value)}/>
                                                            <SuperField size="small" as="input" error={item.error?.amount ? true : false} width={2} value={item.amount} onChange={(e, { value }) => onRecordChange(key, "amount", setDotSeparator(value))}/>
                                                            <SuperField size="small" as="input" width={1} value={item.unit} onChange={(e, { value }) => onRecordChange(key, "unit", value)}/>
                                                            { invoice.tax_mode === 1 
                                                                ? 
                                                                <>
                                                                    <SuperField 
                                                                        width={3}
                                                                        as="input"
                                                                        size="small"
                                                                        error={item.error?.unit_price ? true : false}
                                                                        value={item.unit_price}
                                                                        icon={<BaseCalculator item={item} index={key}/>}
                                                                        onChange={(e, { value }) => onRecordChange(key, "unit_price", setDotSeparator(value))}
                                                                    />
                                                                    <SuperField 
                                                                        width={2}
                                                                        as="input"
                                                                        size="small"
                                                                        error={item.error?.tax_rate ? true : false}
                                                                        value={item.tax_rate}
                                                                        onChange={(e, { value }) => onRecordChange(key, "tax_rate", setDotSeparator(value))}
                                                                    />
                                                                </>
                                                                :
                                                                <>
                                                                    <SuperField 
                                                                        width={3}
                                                                        as="input"
                                                                        size="small"
                                                                        error={item.error?.unit_price ? true : false}
                                                                        value={item.unit_price}
                                                                        onChange={(e, { value }) => onRecordChange(key, "unit_price", setDotSeparator(value))}
                                                                    />
                                                                </>
                                                            }
                                                            
                                                            <Form.Field style={{ fontWeight: "bold", paddingTop: "0.5rem", fontSize: "1.2rem" }} width={3}>
                                                                { calculateTotalForItem(item.amount, item.unit_price, invoice.tax_mode, item.tax_rate) } { invoice.currency } 
                                                            </Form.Field>
                                                        </Form.Group>
                                                        {/* <Form.Group style={{ marginBottom: "0" }}>
                                                            <SuperField width={10} size="small" label={ t("note") } as="input"/>
                                                            <SuperField width={6} size="small" label={ t("discount") } as="input"/>
                                                        </Form.Group> */}
                                                    </FlexItem>
                                                    <FlexItem basis="10%" textAlign="center">
                                                        { item.processing 
                                                            ? <Loader size="small" className='dark-loader' active inline/> 
                                                            : 
                                                            <>
                                                                { item.error === null && 
                                                                    <Icon 
                                                                        name="close" 
                                                                        loading={item.processing} 
                                                                        style={{ cursor: "pointer", color: "var(--danger)" }} 
                                                                        onClick={() => onRemove(item, key)}
                                                                    />
                                                                }

                                                                { item.error !== null && 
                                                                    <Icon 
                                                                        name="warning" 
                                                                        loading={item.processing} 
                                                                        style={{ cursor: "pointer", color: "var(--danger)" }} 
                                                                    />
                                                                }
                                                            </>
                                                        }
                                                    </FlexItem>
                                                </FlexRow>
                                            )) }

                                            <Divider/>
                                            <Popup 
                                                trigger={
                                                    <Button type="button" style={{ background: "var(--dark)", color: "var(--white)" }} size="small" onClick={ () => onItemFormAdd() }>
                                                        <Icon name="plus"/> { t('add_item') }
                                                    </Button>
                                                }
                                                content={t('hint_item_add')}
                                            />
                                        </>
                                    }
                                </Segment>
                            }

                            { !withItems && 
                                <Segment>
                                    <Header as="h3" content={ t('tax_summary') }/>
                                    <Divider/>
                                    <p style={{ fontWeight: "bold" }}> This feature is not supported in this version of application.</p>
                                </Segment>
                            }
                        </Grid.Column>
                    </Grid.Row>
                    <Divider/>
                    <Grid.Row>
                        <Grid.Column textAlign="right" style={{ fontSize: "1.5rem" }}>
                            { t('total_price') }: <br/><br/>
                            <strong style={{ fontSize: "2rem" }}>
                                { calculateTotalForInvoice(items, invoice.tax_mode) + " " + invoice.currency }
                            </strong>
                        </Grid.Column>
                    </Grid.Row>
                </>
                }
            </Grid>
        </Form>
    );
};

export default InvoiceForm;