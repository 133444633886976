import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
// store 
import { API } from '@store/config';
import { routes } from '@routes';
import { patchRequest, getRequest, deleteRequest } from '@services/ServiceCommon';
// components 
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import EmptyRow from '@components/tables/EmptyRow';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Header, Grid, Divider, List, Button, Segment } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific
import EmploymentFulfillmentForm from './forms/EmploymentFulfillmentForm';

const OrderEmploymentDetail = ({ order, setResult, onClose }) => {
    const { t } = useTranslation()
    const [fulfillments, setFulfillments] = useState([])
    const [loadingFulfillments, setLoadingFulfillments] = useState(true)

    useEffect(() => {
        async function fetchFullfilments(){
            setLoadingFulfillments(true)
            const request = await getRequest(API.ORDERS + "employment_fullfilments/?order=" + order.id)
            
            if( request.status === 200 ){
                setFulfillments(request.response)
            }
            setLoadingFulfillments(false)
        }

        fetchFullfilments()
    }, [order.id])

    const handleOrderStatus = async (id) => {
        const request = await patchRequest(API.ORDERS + id + "/", { 
            is_active: !order.is_active,
            resourcetype: "EmploymentOrder",
        })

        if( request.status === 200 ){
            setResult(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter(item => {
                        if( item.id === id ){
                            item.is_active = request.response.is_active
                        }

                        return item
                    })
                }
            }))
        }
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.ORDERS + "employment_fullfilments/" + id + "/")

        if( request.status === 204 ){
            setFulfillments(prevState => prevState.filter(item => item.id !== id))
        }
    }

    const getTotalFee = (items) => {
        let totalFee = null;

        for (let i = 0; i < items.length; i++) {
            totalFee += parseFloat(items[i].fee_per_hour.sum)
        }

        return totalFee
    }

    return (
        <>
            {/* Header */}
            <Grid>
                <Grid.Row verticalAlign="middle" columns={2} style={{ padding: 0 }}>
                    <Grid.Column>
                        <Header as="h3" style={{ marginBottom: "0.2rem" }}>
                            { order.name } { order?.order_number ? " - " + order.order_number : ""} <br/>
                        </Header>
                        <small> 
                            { t('created_by') } { order.created_by?.name } { t('at') } { order.created_on }
                        </small>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                        <strong style={{ fontSize: "1.5rem", fontWeight: "bold", color: order.is_active ? "var(--info)" : "var(--danger)" }}>
                            { order.is_active ? t('active_order') : t('closed_order') }
                        </strong> 
                        <CanView permissions={['orders.c_manage_order']}>
                            <br/>
                            <ConfirmModal
                                description={ !order.is_active ? t('confirm_order_open') : t('confirm_order_close')}
                                onConfirm={() => handleOrderStatus(order.id)}
                                button={
                                    <small style={{ color: "var(--primary)" }} className="ref-link"> { !order.is_active ? t('open_order') : t('close_order') } </small>
                                }
                            />
                        </CanView>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>

            {/* Stats/info section */}
            <Grid>
                <Grid.Row verticalAlign="middle" columns={2}>
                    <Grid.Column>
                        <div style={{ marginBottom: "1rem" }}>
                            { order.confirmed_on === null 
                                ? 
                                    <>
                                        <span style={{ color: "var(--variant2)" }}> { t('state') } </span> <br/>
                                        <strong style={{ fontSize: "1.2rem" }}>
                                            {t('not_confirmed')}
                                        </strong>
                                    </>
                                : 
                                    <>
                                        <span style={{ color: "var(--variant2)" }}> { t('state') } </span> <br/>
                                        <strong style={{ fontSize: "1.2rem" }}>{ order?.order_status_display }</strong> <br/>
                                        <small> 
                                            { t('confirmed_on') } 
                                            <strong> { moment(order.confirmed_on).format("DD.MM.YYYY") } </strong> 
                                            {t('by')} {" "}
                                            <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order?.confirmed_by?.id}>
                                                <span>{ order.confirmed_by?.name }</span>
                                            </Link>
                                        </small>
                                    </>
                            }
                        </div>
                        
                        <span style={{ color: "var(--variant2)" }}> { t('general_information') } </span> <br/>
                        { order?.items?.length &&
                            <div>
                                <span style={{fontWeight: "bold"}}>  {t('ordered_people')}:</span>
                                <span> {" " + order?.items?.length}</span>
                            </div>
                        }
                        { order?.payment_option_display &&
                            <div>
                                <span style={{fontWeight: "bold"}}>  {t('payment_option')}:</span>
                                <span> {" " + order?.payment_option_display}</span>

                            </div>
                        }

                        { order?.account?.name &&
                            <div>
                                <span style={{fontWeight: "bold"}}>  {t('account')}:</span>
                                <Link target="_blank" to={routes.ACCOUNTS_DETAIL + order.account.id}>
                                    <span>{" " + order?.account?.name }</span>
                                </Link>

                            </div>
                        }
                        
                        { order?.order_manager?.id &&
                            <div style={{ marginBottom: "1rem" }}>
                                <span style={{fontWeight: "bold"}}>  {t('order_manager')}:</span>
                                <Link target="_blank" to={routes.EMPLYOEES_DETAIL + order.order_manager.id}>
                                    <span> {" " + order?.order_manager?.fullname}</span>
                                </Link>

                            </div>
                        }
                        { order?.assigned_to.length > 0 &&
                            <div style={{ marginBottom: "1rem" }}>
                                <List>
                                    <List.Item><span style={{fontWeight: "bold"}}>  {t('assigned_employees')}:</span></List.Item>
                                    {order.assigned_to.map((item, index)  => (
                                        <List.Item target="_blank" key={index} as={Link} to={routes.EMPLYOEES_DETAIL + item.id} style={{ paddingLeft: "1rem" }}>
                                            <List.Content>
                                                <Icon name="person-outline" style={{ marginRight: "0.5rem" }}/>
                                                <span style={{ color: "var(--primary)", position: "relative", top: "-0.2rem" }}>{item.fullname}</span>
                                            </List.Content>
                                        </List.Item>
                                    ))}
                                </List>
                            </div>
                        }
                        { order?.assigned_teams.length > 0 &&
                            <div>
                                <List>
                                    <List.Item><span style={{fontWeight: "bold"}}>  {t('teams')}:</span></List.Item>
                                    {order.assigned_teams.map((item, index)  => (
                                        <List.Item target="_blank" key={index} as={Link} to={routes.MEMBER_DETAIL + item.id} style={{ paddingLeft: "1rem" }}>
                                            <List.Content>
                                                <Icon name="people-outline" style={{ marginRight: "0.5rem" }}/>
                                                <span style={{ color: "var(--primary)", position: "relative", top: "-0.2rem" }}>{item.name}</span>
                                            </List.Content>
                                        </List.Item>
                                    ))}
                                </List>
                            </div>
                        }
                    </Grid.Column>
                    <Grid.Column textAlign="left">
                        <Header as="h3" content={ t('ordered_people')}/>
                        <Divider/>
                        <FlexRow background="transparent">
                            <FlexHeader content={t('employee')}/>
                            <FlexHeader textAlign="right" content={t('fee')}/>
                        </FlexRow>
                        { order.items?.map(item => (
                            <FlexRow key={item.id} background="transparent" borders>
                                <FlexItem>
                                    <Link target="_blank" to={routes.EMPLYOEES_DETAIL + item.profile.id}>
                                        { item.profile.fullname }
                                    </Link>
                                </FlexItem>
                                <FlexItem textAlign="right">
                                    { item.fee_per_hour.sum }
                                </FlexItem>
                            </FlexRow>
                        )) }
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            {/* Fulfillment table */}
            <CanView permissions={['orders.c_view_order_fulfillment']}>
                <Segment loading={loadingFulfillments} style={{ border: 0, minHeight: "10rem" ,boxShadow: 'none', padding: 0, background: "transparent" }}>
                    { !loadingFulfillments && 
                        <>
                            <Grid>
                                <Grid.Row style={{ padding: 0 }} verticalAlign="middle" columns={2}>
                                    <Grid.Column>
                                        <Header as="h3" content={t('fulfillment')}/>
                                    </Grid.Column>
                                    <Grid.Column textAlign="right">
                                        <CanView permissions={['orders.c_manage_order_fulfillment']}>
                                            <SuperDuperModal
                                                centered={false}
                                                header={t('add_fulfillment')}
                                                closeIcon
                                                size="tiny" 
                                                trigger={ 
                                                    <Button primary size="small" content={ t('add_fulfillment') }/>
                                                }
                                                content={
                                                    <EmploymentFulfillmentForm 
                                                        order={order}
                                                        totalFee={getTotalFee(order.items)}
                                                        setFulfillments={setFulfillments}
                                                    />
                                                }
                                            />
                                        </CanView>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Divider/>
                            <FlexTable>
                                <FlexRow>
                                    <FlexHeader content={ t('date_from') + " - " + t('date_to') }/>
                                    <FlexHeader content={ t('hours') }/>
                                    <FlexHeader content={ t('fee') }/>
                                    <FlexHeader content={ t('total') }/>
                                    <FlexHeader basis="20%" content={ t('actions') }/>
                                </FlexRow>
                                <EmptyRow length={fulfillments.length}/>
                                { fulfillments.map(fulfillment => (
                                    <FlexRow fontSize="0.9rem" borders key={fulfillment.id}>
                                        <FlexItem bold>
                                            { moment(fulfillment.date_from).format("DD.MM.YYYY") } - { moment(fulfillment.date_to).format("DD.MM.YYYY") }
                                        </FlexItem>
                                        <FlexItem bold>
                                            { fulfillment.total_hours !== null ? fulfillment.total_hours : 0}
                                        </FlexItem>
                                        <FlexItem bold>
                                            { fulfillment.total_fee !== null ? fulfillment.total_fee : 0 }
                                        </FlexItem>
                                        <FlexItem bold>
                                            { (fulfillment.total_hours !== null && fulfillment.total_fee !== null) 
                                                ? parseFloat(parseFloat(fulfillment.total_hours) * parseFloat(fulfillment.total_fee)).toFixed(2) 
                                                : 0
                                            }
                                        </FlexItem>
                                        <FlexItem basis="20%">
                                            <FlexRow background="transparent" padding="0">
                                                <CanView permissions={['orders.c_manage_order_fulfillment']}>
                                                    <FlexItem>
                                                        <SuperDuperModal
                                                            centered={false}
                                                            header={t('add_fulfillment')}
                                                            closeIcon
                                                            size="tiny" 
                                                            trigger={ 
                                                                <Icon name="pencil-outline" style={{ color: "var(--dark)", cursor: "pointer" }}/>
                                                            }
                                                            content={
                                                                <EmploymentFulfillmentForm 
                                                                    order={order}
                                                                    fulfillment={fulfillment}
                                                                    setFulfillments={setFulfillments}
                                                                />
                                                            }
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                                <CanView permissions={['orders.c_manage_order_fulfillment']}>
                                                    <FlexItem>
                                                        <ConfirmModal
                                                            description={t('delete_message_fulfillment')}
                                                            onConfirm={() => onDelete(fulfillment.id)}
                                                            button={
                                                                <Icon name="close-outline" style={{ color: "var(--danger)", cursor: "pointer" }}/>
                                                            }
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                            </FlexRow>
                                        </FlexItem>
                                    </FlexRow>
                                )) }
                            </FlexTable>
                            
                        </>
                    }
                </Segment>
            </CanView>
        </>
    );
};

export default OrderEmploymentDetail;