import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// store
import { routes } from '@routes';
import { addJournal } from '@store/actions';
// components
import EmptyRow from '@components/tables/EmptyRow';
import { Button, Header, Divider } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';

const SwitchAccountingJournal = ({ connection, user, setRefetch }) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { t } = useTranslation()

    const switchJournal = (journal) => {
        dispatch(addJournal(journal))
        history.push(routes.INVOICES)
        setRefetch(true)
    }

    return (
        <>
            <Header as="h3" content={t("accounting_journals")}/>
            <Divider/>
            <FlexTable>
                <FlexRow background="transparent" borders>
                    <FlexHeader basis="120%" content={t('name')}/>
                    <FlexHeader content={t('id_number')}/>
                    <FlexHeader content={t('actions')}/>
                </FlexRow>
                <EmptyRow length={connection.accounts.length}/>
                    { connection.accounts.map(journal => (
                        <FlexRow background="transparent" borders fontSize="0.9rem">
                            <FlexItem bold basis="120%">{ journal.name }</FlexItem>
                            <FlexItem>{ journal.id_number }</FlexItem>
                            <FlexItem>
                                { journal.id === user.journal.id 
                                    ? <strong> { t('currently_selected') }</strong>
                                    : 
                                    <Button 
                                        size="small" primary
                                        content={ journal.id === user.journal.id ? t('currently_selected') : t('switch_journal_action') }
                                        disabled={ journal.id === user.journal.id || journal.allowed_users.filter(item => item.user.username === user.username).length === 0 || journal.allowed_users.find(item => item.user.username === user.username)?.can_manage !== true}
                                        onClick={ () => switchJournal(journal) }
                                    />
                                }
                            </FlexItem>
                        </FlexRow>
                    )) }
            </FlexTable>
        </>
    );
};

export default SwitchAccountingJournal;