import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-semantic-toasts';
// store 
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useFetchData, useQueryPage, useIsMount } from '@helpers/hooks';
// components 
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
import SuperField from '@components/forms/SuperField';
import { Segment, Sidebar, Ref, Button, Menu, Form } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific components
import UpdateStoredItemForm from './forms/UpdateStoredItemForm';
import ManageStoreItemForm from './forms/ManageStoreItemForm';
import StoredItemFilterForm from './forms/StoredItemFilterForm';
import StoreAdditionalItemToGroup from './forms/StoreAdditionalItemToGroup';
import StoredItemHistory from './StoredItemHistory';

const StoredItems = ({ response, setResponse, issue, issuedItems, setIssuedItems }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const history = useHistory()
    const isMount = useIsMount()
    const segmentRef = useRef()
    let page = useQueryPage()
    page = isMount ? 1 : page

    const [searchType, setSearchType] = useState("code")

    const initFilterValues = {
        item: "",
        warehouse: "",
        category: "",
        is_vehicle: false,
        only_groups: false,
        search: ""
    }

    const [visible, setVisible] = useState(false)
    const [limit, setLimit] = useState(10)
    const [storedItems, setStoredItems] = useFetchData(API.ASSET_STORED_ITEMS + "?paginate=true&ordering=code&page=" + page + "&limit=" + limit)
    const [filter, setFilter] = useState(initFilterValues)
    
    useEffect(() => {
        if( response !== null ){
            if( response.type === "stored_create" ){
                setStoredItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        count: prev.data.count + 1,
                        results: [response.result, ...storedItems.data.results]
                    }
                }))
            }

            if( response.type === "stored_update" ){
                setStoredItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        results: storedItems.data.results.filter(storedItem => {
                            if( storedItem.id === response.result.id ){
                                storedItem.sn = response.result.sn;
                                storedItem.quantity = response.result.quantity;
                                // storedItem.item = response.result.item;
                                // storedItem.warehouse = response.result.warehouse;
                            }
                            return storedItem
                        })
                    }
                }))
            }

            setResponse(null)
        }
        // eslint-disable-next-line
    }, [response])

    function checkIfWasIssued(storedItem){
        return issuedItems.find(item => item.storedItem.id === storedItem.id) !== undefined
    }

    const handleIssueItem = (storedItem) => {
        if( checkIfWasIssued(storedItem) ){ // remove
            setIssuedItems(prev => prev.filter(item => item.storedItem.id !== storedItem.id))
        } else { // issue
            setIssuedItems(prev => [
                {
                    id: 0,
                    quantity: (storedItem.sn !== null || storedItem.all_children?.length > 0) ? 1 : 0,
                    valid_until: "",
                    note: "",
                    isProcessing: false,
                    storedItem: {
                        id: storedItem.id,
                        code: storedItem.code,
                        sn: storedItem.sn,
                        name: storedItem?.item?.title,
                        stock: storedItem.quantity,
                        warehouse: storedItem?.warehouse?.title,
                    }
                },
                ...prev
            ])
        }
    }

    const onFilter = async (params, isFiltered) => {
        
        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        } 

        setStoredItems(prev => {
            return {
                ...prev,
                isLoading: true,
            }
        })

        if( filter.item ) params += "&item=" + filter.item;
        if( filter.warehouse ) params += "&warehouse=" + filter.warehouse
        params += filter.search !== "" && searchType === "search" ? "&search=" + filter.search : ""
        params += filter.search !== "" && searchType === "code" ? "&code=" + filter.search : ""
        params += filter.search !== "" && searchType === "sn" ? "&sn=" + filter.search : ""
        params += filter.is_vehicle !== false ? "&is_vehicle=" + filter.is_vehicle : ""
        params += filter.only_groups !== false ? "&only_groups=" + filter.only_groups : ""
        params += filter.category !== "" ? "&category=" + filter.category : ""

        const request = await requests.get(API.ASSET_STORED_ITEMS + "?paginate=true&ordering=code" + params)

        if (request !== undefined) {
            if (request.status === 200) {
                setStoredItems(prev => ({
                    ...prev,
                    data: request.response
                }))
            }
        }

        setStoredItems(prev => ({
            ...prev,
            isLoading: false
        }))
    }

    const onDelete = async (id, parentItem) => {
        const request = await requests.del(API.ASSET_STORED_ITEMS +id + "/")

        if( request.status === 204){
            if (parentItem?.id === undefined){
                setStoredItems(prev => ({
                    ...prev,
                    data: {
                        ...prev.data,
                        count: prev.data.count - 1,
                        results: prev.data.results.filter(item => item.id !== id)
                    }
                }))
            } else {
                // fetch parent item
                const getStoredItem = await requests.get(API.ASSET_STORED_ITEMS + parentItem.id + "/")
                if (getStoredItem.status === 200){
                    setStoredItems(prev => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            count: prev.data.count - 1,
                            results: prev.data.results.map(item => {
                                if (item.id === parentItem.id){
                                    item = getStoredItem.response
                                }

                                return item
                            })
                        }
                    }))
                }
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('can_not_be_removed'),
                description: t('stored_item_cannot_be_removed'),
                animation: 'bounce',
                time: 5000,
            }); 
        }
    }

    return (
        <Ref innerRef={segmentRef}>
            <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                <Sidebar
                    style={{ width: "40rem" }}
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    icon='labeled'
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    width='wide'
                    target={segmentRef}
                >
                    <StoredItemFilterForm
                        filters={filter}
                        setFilters={setFilter}
                        setVisible={setVisible}
                        onFilter={async () => {
                            history.replace({ pathname: location.pathname, search: `?page=${1}` });
                            await onFilter("", true)
                        }}
                    />
                </Sidebar>
                <Sidebar.Pusher>
                    <FlexRow background="transparent" padding="0">
                        <FlexItem>
                            <Form onSubmit={async (e) => {
                                e.preventDefault()
                                history.replace({ pathname: location.pathname, search: `?page=${1}` });
                                await onFilter("", true)
                            }}>
                                <Form.Group stackable>
                                    <SuperField
                                        autoFocus={true}
                                        width="8"
                                        as='input'
                                        label={t('search')}
                                        placeholder={t('search_stored_item_hint')}
                                        value={filter.search}
                                        onChange={(e, { value }) => setFilter({ ...filter, search: value })}
                                    />
                                    <SuperField
                                        as='choice'
                                        label={t('search_type')}
                                        value={searchType}
                                        customOptions={[
                                            { key: 1, value: "search", text: t('all')},
                                            { key: 2, value: "code", text: t('code')},
                                            { key: 3, value: "sn", text: t('serial_number')},
                                        ]}
                                        onChange={(e, { value }) => setSearchType(value)}
                                    />
                                    <Form.Field style={{ paddingTop: "1.7rem" }}>
                                        <Button 
                                            primary
                                            content={t('confirm')}
                                        />
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </FlexItem>
                        <FlexItem textAlign="right">
                            <Button
                                size="small"
                                style={{ 
                                    background: "transparent", 
                                    color: "var(--dark)", 
                                    paddingLeft: "1rem", 
                                    paddingRight: "1rem" 
                                }}
                                onClick={() => setVisible(!visible)}
                            >
                                <Icon name='funnel-outline'/>
                            </Button>
                        </FlexItem>
                    </FlexRow>
                    <Segment loading={storedItems.isLoading} style={{ 
                        padding: 0, 
                        background: "transparent", 
                        boxShadow: "none", 
                        border: "none",
                        marginBottom: "1rem"
                    }}>
                        <FlexTable stripped={false}>
                            <FlexRow>
                                <FlexHeader basis="10%" content={""}/>
                                <FlexHeader basis="40%" content={t('code')}/>
                                <FlexHeader basis="120%" content={t('item')}/>
                                <FlexHeader content={t('current_stock')}/>
                                <FlexHeader content={t("warehouse")}/>
                                { issue !== true && 
                                    <FlexHeader content={t("note")}/>
                                }
                                <FlexHeader content={t('actions')}/>
                            </FlexRow>
                            {(storedItems?.data?.count === 0 || storedItems === undefined) &&
                                <FlexRow fontSize="0.9rem" borders>
                                    <FlexItem bold textAlign="center" content={t('no_data')} />
                                </FlexRow>
                            }

                            {storedItems?.data?.results?.map(storedItem => (
                                <FlexRow background={storedItem.all_children?.length > 0 ? "var(--light)" : "transparent"} fontSize="0.9rem" borders key={storedItem.id} isToggle={storedItem.all_children?.length > 0} 
                                    content={
                                        <>
                                            { storedItem.all_children?.map(children => (
                                                <FlexRow background="tranparent" fontSize="0.9rem">
                                                    <FlexItem basis="10%"/>
                                                    <FlexItem basis="40%">
                                                        { children?.code }
                                                    </FlexItem>
                                                    <FlexItem basis="120%">
                                                        <strong>{ children?.item?.title }</strong> <br/>
                                                        { children?.sn ? <small>SN: { children?.sn }</small> : "" } 
                                                        { children?.sn && children?.item?.is_vehicle && <> | </> }
                                                        { children?.item?.is_vehicle && 
                                                            <strong>
                                                                <Icon name="car-outline" style={{ marginRight: "0.5rem" }}/>
                                                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                                                    { t('vehicle') }
                                                                </span>
                                                            </strong> }
                                                    </FlexItem>
                                                    <FlexItem content={children.quantity}/>
                                                    <FlexItem> { children?.warehouse?.title || "" } </FlexItem>
                                                    { issue !== true && 
                                                        <FlexItem>
                                                            { children?.note }
                                                        </FlexItem>
                                                    }
                                                    <FlexItem>
                                                        { issue !== true && 
                                                            <FlexRow padding="0" background="transparent">
                                                                <CanView permissions={['assets.c_view_item_history']}>
                                                                    <FlexItem>
                                                                        <Action as="modal" type="icon" modalSize="large" icon="reader-outline" tooltip={t('history')} header={t('history') + " - " + children?.item?.title}
                                                                            modal={<StoredItemHistory storedItem={children}/>}
                                                                        />
                                                                    </FlexItem>
                                                                </CanView>
                                                                <CanView permissions={['assets.c_manage_items']}>
                                                                    <FlexItem>
                                                                        <Action as="modal" type="icon" modalSize="tiny" icon="pencil-outline" tooltip={t('update_item')} header={t('update_item')}
                                                                            modal={
                                                                                <UpdateStoredItemForm parentItem={storedItem} storedItem={children} setStoredItems={setStoredItems} />
                                                                            }
                                                                        />
                                                                    </FlexItem>
                                                                    <FlexItem>
                                                                        <Action as="modal" type="icon" modalSize="tiny" icon="swap-vertical-outline" tooltip={t('make_a_correction')} header={t('make_a_correction')}
                                                                            modal={
                                                                                <ManageStoreItemForm 
                                                                                    action="correction" 
                                                                                    parentItem={storedItem}
                                                                                    storedItem={children} 
                                                                                    setStoredItems={setStoredItems} 
                                                                                />
                                                                            }
                                                                        />
                                                                    </FlexItem>
                                                                    <FlexItem>
                                                                        <Action as="modal" type="icon" modalSize="tiny" icon="archive-outline" tooltip={t('discard_item')} header={t('discard_item')}
                                                                            modal={
                                                                                <ManageStoreItemForm 
                                                                                    action="discard" 
                                                                                    parentItem={storedItem}
                                                                                    storedItem={children} 
                                                                                    setStoredItems={setStoredItems} 
                                                                                />
                                                                            }
                                                                        />
                                                                    </FlexItem>
                                                                </CanView>
                                                                <CanView permissions={['assets.c_delete_items']}>
                                                                    <FlexItem>
                                                                        <Action as='delete' 
                                                                            tooltip={t('remove_item')} 
                                                                            text={t('are_you_sure')} 
                                                                            onClick={() => onDelete(children.id, storedItem)}
                                                                        />
                                                                    </FlexItem>
                                                                </CanView>
                                                            </FlexRow>
                                                        }
                                                    </FlexItem>
                                                </FlexRow>
                                            )) }
                                        </>
                                    }
                                >
                                    { storedItem.all_children?.length === 0 && <FlexItem basis="10%" content={""}/> }
                                    <FlexItem basis="40%">
                                        { storedItem?.code }
                                    </FlexItem>
                                    <FlexItem basis="120%">
                                        <strong>{ storedItem?.item?.title }</strong> <br/>
                                        { storedItem?.sn ? <small>SN: { storedItem?.sn }</small> : "" } 
                                        { storedItem?.sn && storedItem?.item?.is_vehicle && <> | </> }
                                        { storedItem?.item?.is_vehicle && <strong> <Icon name="car"/> { t('vehicle') }</strong> }
                                    </FlexItem>
                                    <FlexItem content={storedItem.quantity}/>
                                    <FlexItem>
                                        { storedItem?.warehouse?.title || "" }
                                    </FlexItem>
                                    { issue !== true && 
                                        <FlexItem>
                                            { storedItem?.note }
                                        </FlexItem>
                                    }
                                    { issue === true && 
                                        <FlexItem>
                                            <Button 
                                                fluid 
                                                disabled={storedItem.quantity === 0} 
                                                onClick={() => handleIssueItem(storedItem)}
                                                style={{
                                                    color: "var(--white)",
                                                    background: checkIfWasIssued(storedItem) ? "var(--dark)" : "var(--primary)"
                                                }}
                                            >
                                                { storedItem.quantity === 0 
                                                    ? t('out_of_stock')
                                                    : checkIfWasIssued(storedItem) 
                                                        ? <><Icon name="checkmark"/> {t('selected')} <br/> <small>{t('click_to_remove')}</small> </>
                                                        : t('select_item')
                                                }
                                            </Button>
                                        </FlexItem>
                                    }
                                    { issue !== true && 
                                        <FlexItem>
                                            <FlexRow padding="0" background="transparent">
                                                <CanView permissions={['assets.c_manage_items']}>
                                                    <FlexItem>
                                                        <Action as="modal" type="icon" modalSize="small" icon="add-outline" tooltip={t('add_item')} header={t('add_item')}
                                                            modal={
                                                                <StoreAdditionalItemToGroup
                                                                    storedGroupedItem={storedItem}
                                                                    setStoredItems={setStoredItems}
                                                                />
                                                            }
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                                <CanView permissions={['assets.c_view_item_history']}>
                                                    <FlexItem>
                                                        <Action as="modal" type="icon" modalSize="large" icon="reader-outline" tooltip={t('history')} header={t('history') + " - " + storedItem?.item?.title}
                                                            modal={<StoredItemHistory storedItem={storedItem}/>}
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                                <CanView permissions={['assets.c_manage_items']}>
                                                    <FlexItem>
                                                        <Action as="modal" type="icon" modalSize="tiny" icon="pencil-outline" tooltip={t('update_item')} header={t('update_item')}
                                                            modal={
                                                                <UpdateStoredItemForm storedItem={storedItem} setStoredItems={setStoredItems} />
                                                            }
                                                        />
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <Action as="modal" type="icon" modalSize="tiny" icon="swap-vertical-outline" tooltip={t('make_a_correction')} header={t('make_a_correction')}
                                                            modal={
                                                                <ManageStoreItemForm 
                                                                    action="correction" 
                                                                    storedItem={storedItem} 
                                                                    setStoredItems={setStoredItems} 
                                                                />
                                                            }
                                                        />
                                                    </FlexItem>
                                                    <FlexItem>
                                                        <Action as="modal" type="icon" modalSize="tiny" icon="archive-outline" tooltip={t('discard_item')} header={t('discard_item')}
                                                            modal={
                                                                <ManageStoreItemForm 
                                                                    action="discard" 
                                                                    storedItem={storedItem} 
                                                                    setStoredItems={setStoredItems} 
                                                                />
                                                            }
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                                <CanView permissions={['assets.c_delete_items']}>
                                                    <FlexItem>
                                                        <Action as='delete' 
                                                            tooltip={t('remove_item')} 
                                                            text={t('are_you_sure')} 
                                                            onClick={() => onDelete(storedItem.id)}
                                                        />
                                                    </FlexItem>
                                                </CanView>
                                            </FlexRow>
                                        </FlexItem>
                                    }
                                </FlexRow>
                            ))}
                        </FlexTable>
                    </Segment>
                    <Paginator
                        forcePage={page || 1}
                        limit={limit}
                        setLimit={setLimit}
                        length={storedItems?.data?.count || 0}
                        onChange={(params) => onFilter(params)}
                    />
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Ref>
    );
};

export default StoredItems;