import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store
import { API } from '@store/config';
import { createRequest, updateRequest } from '@services/ServiceCommon';
import { isEmpty } from "@helpers/validation";
// components
import { Form } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const HROrderFulfillmentForm = ({ order, fulfillment, setFulfillments, onClose }) => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        date: fulfillment !== undefined ? fulfillment.date : moment().format("YYYY-MM-DD"),
        amount: fulfillment !== undefined ? fulfillment.amount : 0,
        order: order.id
    })
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async () => {
        setIsProcessing(true)
        if (fulfillment === undefined) {
            const request = await createRequest(API.ORDER_FULFILLMENTS, form)
            if( request.status === 201 ){
                setFulfillments(prevState => [request.response, ...prevState])
                onClose()
            }
        } else {
            const request = await updateRequest(API.ORDER_FULFILLMENTS + fulfillment.id + "/", form)
            if( request.status === 200 ){
                setFulfillments(prevState => prevState.filter(item => {
                    if (item.id === fulfillment.id) {
                        item.date = request.response.date
                        item.amount = request.response.amount
                    }

                    return item
                }))
                onClose()
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
                <SuperField as="datepicker"
                    required
                    label={t('date')}
                    value={form.date}
                    onChange={(e, { value }) => setForm({ ...form, date: value })}
                />
                <SuperField as="input" type="number" min="0"
                    required
                    label={t('amount')}
                    value={form.amount}
                    onChange={(e, { value }) => setForm({ ...form, amount: value })}
                />
            </Form.Group>

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    disabled={isProcessing || isEmpty(form.date) || isEmpty(form.amount) || isEmpty(form.status) || form.amount < 0}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default HROrderFulfillmentForm;