import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
// store
import { API } from '@store/config';
// import { useQueryPage } from '@helpers/hooks';
import { getRequest } from '@services/ServiceCommon';
import { addInvoicePairingStatus } from '@store/actions/'
// components
import { Divider/*, Button*/ } from 'semantic-ui-react';
import InvoicesCardListing from './InvoicesCardListing';
import InvoicesTableNew from '../components/InvoicesTableNew';

const InvoiceListView = ({ segmentRef, connection, premiumConnection, journal, type }) => {
    // eslint-disable-next-line
    const { t } = useTranslation()
    const pairing_status = useSelector(state => state.pairing_status)
    const dispatch = useDispatch()
    const [limit, setLimit] = useState(10)
    const [isLoading, setIsLoading] = useState(true)
    const [choices, setChoices] = useState(null)
    const [pairInvoices, setPairInvoices] = useState(premiumConnection?.id ? pairing_status || false : false)

    const initialFilters = {
        date_from: "",
        date_to: "",
        date_type: 1,
        invoice_type: "",
        number: "",
        status: "",
        city: "",
        country: "",
        name: "",
        registration_id: "",
        tax_id: "",
        vat_id: "",
        verified: "",
        approval: "",
        split_ratio: "",
        ordering: {
            direction: "desc",
            field: "created_at"
        }
    }

    const [filters, setFilters] = useState(initialFilters)

    useEffect(() => {
        dispatch(addInvoicePairingStatus(pairInvoices))
        // eslint-disable-next-line
    }, [pairInvoices])

    useEffect(() => {
        async function getChoices(){
            setIsLoading(true)
            const request = await getRequest(API.GET_INVOICES + "choices/?connection=" + connection.connection_id)
            
            if( request.status === 200 ){
                setChoices(request.response)
            }
            setIsLoading(false)
        }

        getChoices()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <InvoicesCardListing 
                type={type}
                connection={connection}
                journal={journal} 
                setFilters={setFilters}
                pairInvoices={pairInvoices}
                setPairInvoices={setPairInvoices}
                premiumConnection={premiumConnection}
                initialFilters={initialFilters}
            />
            
            <Divider/>
            <InvoicesTableNew 
                segmentRef={segmentRef} 
                connection={connection}
                pairInvoices={pairInvoices}
                setPairInvoices={setPairInvoices}
                premiumConnection={premiumConnection}
                journal={journal}
                type={type}
                limit={limit}
                filters={filters}
                setFilters={setFilters}
                choices={choices}
                isLoading={isLoading}
                setLimit={setLimit}
            />
        </>
    );
};

export default InvoiceListView;