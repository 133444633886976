import React, { useState }from 'react'
import { useTranslation } from 'react-i18next';
//store
import { isEmpty } from "@helpers/validation"
import { accountsService } from '@services/ServiceAccounts';
//components
import {  Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import ResponsiblePersonField from '@components/forms/common/ResponsoblePersonField';


export const AddResponsiblePersonForm = ({ assigned, data, onClose, onConfirm }) => {
    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [id] = useState(data.id ? data.id : 0); // if 0 then its Create Form, else Update
    const [errorList, setErrorList] = useState([]); // empty array
    const [newAssignee, setNewAssignee] = useState(0)
    const [assignedList] = useState(assigned ? assigned : []); // empty array
    const {t}= useTranslation()

    const handleSubmit = async () => {
        setIsProcessing(true);

        const result = await accountsService.updateAccount(id,{
            assigned_to: {add: [newAssignee]}
        });

            if( result.status === 200 ) {
                onConfirm(result.response);
                onClose();
            } else {
                const error_message = result.response.assigned_to.add;
                setErrorList([...errorList, error_message]);
            }


        setIsProcessing(false);
    }

    return (
        <Form onSubmit={handleSubmit}>
            { errorList.length > 0 &&
                 (<Message negative>
                    { errorList.map( (error, index) =>
                       <p key={index}> {error} </p>
                    )}
                 </Message>)
            }

            <Form.Field >
                <ResponsiblePersonField
                    assignedList={assignedList}
                    required
                    value={newAssignee}
                    setValue={(e, data) => setNewAssignee(data.value)}
                />
            </Form.Field>

            <Form.Field style={{ textAlign: "right"}}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing ||
                        isEmpty(newAssignee) }
                    loading={isProcessing}
                    text={t('create')}
                />
            </Form.Field>
        </Form>
    )
}



