import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import '@store/languages';
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { dates } from '@helpers/dates';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import AvatarIcon from '@components/AvatarIcon';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Grid, Segment, Divider, Header, Button, Popup } from 'semantic-ui-react';
// components specific to module
import ThisWeekGraph from '../components/day-overview/ThisWeekGraph';
import LastWeekGraph from '../components/day-overview/LastWeekGraph';
import MonthViewGraph from '../components/day-overview/MonthViewGraph';
import InterruptionReasonForm from '../components/day-overview/InterruptionReasonForm';

const DayOverview = () => {
    const { t } = useTranslation()
    const language = useSelector(state => state.language)
    const user = useSelector(state => state.user)

    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line
    const [day, setDay] = useState(moment().format("YYYY-MM-DD"))
    const [employee, setEmployee] = useState({
        id: user?.profile?.id || 0,
        fullname: user?.profile?.name || "JH",
        profile_picture: null,
    })
    const [interruptions, setInterruptions] = useState([])
    const [attendance, setAttendance] = useState(null)

    // stats dates:
    const firstDayOfMonth = moment().startOf('month')
    const lastDayOfMonth = moment().endOf('month')
    const firstDayOfCurrentWeek = moment().startOf('week')
    const lastDayOfCurrentWeek = moment().endOf('week')
    const firstDayOfLastWeek = moment().startOf('week').subtract(7,'days')
    const lastDayOfLastWeek = moment().endOf('week').subtract(7,'days')

    useEffect( () => {
        async function fetchDayOverview(){
            setLoading(true)
            const request = await requests.get(API.ATTENDANCE + "?employee=" + employee.id + "&date_from=" + day + "&date_to=" + day)

            if (request.status === 200){
                if (request.response.length > 0){
                    setEmployee(request.response[0].employee)
                    setAttendance(request.response[0])
                    setInterruptions(request.response[0].interruptions)
                }
            }
            setLoading(false)
        }

        fetchDayOverview()
        // eslint-disable-next-line
    }, [])

    const isActiveInterruption = () => {
        return interruptions.filter(interruption => interruption.end === null).length !== 0
    }


    const SelfManageActions = () => {
        const { t } = useTranslation()
        const [processingStartOffAction, setProcessingStartOffAction] = useState(false)
        const [processingBreakAction, setProcessingBreakAction] = useState(false)
        const [interruptionStopAction, setInterruptionStopAction] = useState(false)

        const isBreakDisabled = () => {
            if (attendance === null) return true
            if (processingStartOffAction) return true
            if (processingBreakAction) return true
            if (attendance?.end) return true
            if (attendance?.end_pause !== null) return true

            return false
        }

        const isInterruptionDisabled = () => {
            if (attendance === null) return true
            if (interruptionStopAction) return true
            if (processingStartOffAction) return true
            if (processingBreakAction) return true
            if (attendance?.end && !isActiveInterruption()) return true
            if (attendance?.start_pause !== null && attendance?.end_pause === null) return true

            return false
        }

        const isStartOffDisabled = () => {
            if (processingStartOffAction) return true
            if (processingBreakAction) return true
            if (attendance?.end) return true

            return false
        }

        const handleStartEndAction = async () => {
            setProcessingStartOffAction(true)
            let data = { employee: employee.id }

            if (attendance !== null) {
                data['end'] = moment.utc().format("YYYY-MM-DD HH:mm:ss")

                if (attendance.start_pause !== null && attendance.end_pause === null) {
                    data['end_pause'] = moment.utc().format("YYYY-MM-DD HH:mm:ss")
                }
            }


            const request = await requests.post(API.ATTENDANCE, data)
            if (request.status === 201 || request.status === 200){
                setAttendance(request.response)

                if (attendance !== null) {
                    // close day for this employee
                    await requests.put(API.ATTENDANCE_CLOSE_DAY + "?employee=" + employee.id)
                }

            } else {
                alert("error, see more in console...")
                console.log(request.response)
            }

            setProcessingStartOffAction(false)
        }

        const handleBreakStartStopAction = async () => {
            setProcessingBreakAction(true)
            let data = { employee: employee.id }

            if (attendance.start_pause === null){
                data['start_pause'] = moment.utc().format("YYYY-MM-DD HH:mm:ss")
            } else {
                data['end_pause'] = moment.utc().format("YYYY-MM-DD HH:mm:ss")
            }

            const request = await requests.post(API.ATTENDANCE, data)
            if (request.status === 201 || request.status === 200){
                setAttendance(request.response)
            } else {
                alert("error, see more in console...")
                console.log(request.response)
            }

            setProcessingBreakAction(false)
        }

        const stopInterruption = async () => {
            setInterruptionStopAction(true)
            const interruption = interruptions.find(interruption => interruption.end === null)
            if (interruption) {
                const request = await requests.patch(API.ATTENDANCE_INTERRUPTIONS + interruption.id + "/", {
                    end: moment.utc().format("YYYY-MM-DD HH:mm:ss")
                })

                if (request.status === 200) {
                    setInterruptions(prev => prev.filter(item => {
                        if (item.id === interruption.id) {
                            item.end = request.response.end
                        }

                        return item
                    }))

                    const fetchAttendance = await requests.get(API.ATTENDANCE + attendance.id + "/")
                    if (fetchAttendance.status === 200) {
                        setAttendance(fetchAttendance.response)
                    }
                }

            }

            setInterruptionStopAction(false)
        }

        return (
            <>
                { attendance === null 
                ? 
                    <Button fluid size="huge"
                        type="button"
                        style={{ 
                            fontWeight: "bold",
                            background:  attendance === null ? "var(--success-hover)" : "var(--danger-hover)",
                            color: "var(--light)",
                            borderRadius: "5px",
                            textTransform: "uppercase" 
                        }}
                        disabled={isStartOffDisabled()}
                        loading={processingBreakAction}
                        onClick={ () => handleStartEndAction() }
                    > 
                        { t("start_work") } 
                    </Button>  
                : 
                    <ConfirmModal
                        description={t('are_you_sure_that_you_want_to_end_your_work_day')}
                        onConfirm={() => handleStartEndAction()}
                        button={
                            <Button fluid size="huge"
                                type="button"
                                style={{ 
                                    fontWeight: "bold",
                                    background:  attendance === null ? "var(--success-hover)" : "var(--danger-hover)",
                                    color: "var(--light)",
                                    borderRadius: "5px",
                                    textTransform: "uppercase" 
                                }}
                                disabled={isStartOffDisabled()}
                                loading={processingStartOffAction}
                            > 
                                { t('end_work') } 
                            </Button>   
                        }
                    />
                }
                  
                <Button.Group fluid size="large" style={{ fontWeight: "bold", marginTop: "0.5rem" }}>
                    <Button primary
                        style={{
                            borderRadius: "5px",
                            marginRight: "0.25rem",
                            textTransform: "uppercase" 
                        }} 
                        disabled={isBreakDisabled()}
                        loading={processingStartOffAction}
                        onClick={() => handleBreakStartStopAction()}
                    > 
                        { t('break') } { (attendance?.start_pause !== null && attendance?.end_pause === null) && <><br/> <small>{ t('stop') }</small></>}
                    </Button>    
                    { isActiveInterruption() 
                        ? 
                            <Button
                                style={{
                                    background: "var(--dark)",
                                    color: "var(--light)",
                                    borderRadius: "5px",
                                    marginLeft: "0.25rem",
                                    textTransform: "uppercase"
                                }}
                                disabled={isInterruptionDisabled()}
                                loading={interruptionStopAction}
                                onClick={() => stopInterruption()}
                            > 
                                { t('interruption') } <br/> <small>{ t('stop') }</small>
                            </Button>   
                        : 
                            <SuperDuperModal
                                size="small"
                                trigger={
                                    <Button
                                        style={{
                                            background: "var(--dark)",
                                            color: "var(--light)",
                                            borderRadius: "5px",
                                            marginLeft: "0.25rem",
                                            textTransform: "uppercase"
                                        }}
                                        disabled={isInterruptionDisabled()}
                                    > 
                                        { t('interruption') }
                                    </Button>    
                                }
                                content={
                                    <InterruptionReasonForm
                                        attendance={attendance}
                                        setInterruptions={setInterruptions}
                                        setAttendance={setAttendance}
                                    />}
                            />
                    }
                    
                </Button.Group>
            </>
        )
    }

    const formatTime = (time) => {
        if (time === undefined || time === null) return "--:--"
        return moment(dates.convertUTCDateToLocalDate(time)).format("HH:mm")
    }

    const calcDifference = (start_datetime, end_datetime, type) => {
        let value = 0
        const start = start_datetime ? moment(dates.convertUTCDateToLocalDate(start_datetime)) : null
        const end = end_datetime ? moment(dates.convertUTCDateToLocalDate(end_datetime)) : null
        const current_time = moment.utc().format("YYYY-MM-DD HH:mm:ss")
        const current_time_timezone = moment(dates.convertUTCDateToLocalDate(current_time))

        if ( start !== null && end !== null ) {
            // calculate difference between start-end
            value = end.diff(start, type, true)
        }

        if ( start !== null && end === null ) {
            // calculate difference between start and current time
            value = current_time_timezone.diff(start, type, true)
        }

        return value
    }

    const formatBreak = () => {
        let minutes = 0
        if (attendance === null) return minutes + " min."
        minutes = calcDifference(attendance.start_pause, attendance.end_pause, "minutes")

        return minutes.toFixed(2).replace(".00", "") + " min."
    }

    const formatTotal = () => {
        let hours = 0
        let interruption_time = 0
        if (attendance === null) return hours + " hod."
        hours = calcDifference(attendance.start, attendance.end, "hours")
        let break_hours = calcDifference(attendance.start_pause, attendance.end_pause, "hours")

        for (let i = 0; i < interruptions.length; i++) {
            interruption_time += calcDifference(interruptions[i].start, interruptions[i].end, "hours")
        }

        hours = hours - break_hours - interruption_time

        return hours.toFixed(2).replace(".00", "") + " hod."
    }

    const formatInterruptions = () => {
        let hours = 0
        if (attendance === null) return hours + " hod."

        for (let i = 0; i < interruptions.length; i++) {
            hours += calcDifference(interruptions[i].start, interruptions[i].end, "hours")
        }

        return hours.toFixed(2).replace(".00", "") + " hod."
    }

    return (
        <Segment 
            loading={loading}
            style={{ 
                padding: 0, 
                background: "transparent", 
                boxShadow: "none", 
                border: "none",
                marginBottom: "1rem",
                marginTop: "1rem",
            }}
        >
            { loading && 
                <p style={{ textAlign: "center", color: "var(--dark)", paddingTop: "6rem" }}> 
                { t('loading_day_overview') } 
                </p>
            }
            { !loading && 
            <>
                <Segment>
                    <Grid stackable>
                        <Grid.Row verticalAlign="middle">
                            <Grid.Column only="mobile" mobile={4} textAlign="center">
                                <SelfManageActions/>
                                <Divider/>
                            </Grid.Column>
                            <Grid.Column computer={12}>
                                <Grid>
                                    <Grid.Row verticalAlign="middle">
                                        <Grid.Column width={1} textAlign="center">
                                            <AvatarIcon size="50" name={employee?.fullname} src={employee?.profile_picture || null}/>
                                        </Grid.Column>    
                                        <Grid.Column width={15}>
                                            <Header as="h2" style={{ marginBottom: 0, fontWeight: "bold" }}>
                                                { employee.fullname }     
                                            </Header>
                                            <p> 
                                                { attendance?.employee?.positions?.[0]?.title || "--"}, {" "}
                                                { attendance?.employee?.unit?.name 
                                                    ? 
                                                    <Link to={routes.UNIT_DETAIL + attendance?.employee?.unit?.id}> 
                                                        { attendance?.employee?.unit?.name } 
                                                    </Link>
                                                    : "--" 
                                                }
                                            </p>     
                                        </Grid.Column>    
                                    </Grid.Row>
                                </Grid>
                                <Grid stackable>
                                    <Grid.Row columns={6}>
                                        <Grid.Column>
                                            <strong> { t('date') } </strong>
                                            <div style={{ fontSize: "1.2rem", textTransform: "capitalize" }}> 
                                                { moment(day).locale(language).format('dddd') }, {" "}
                                                { moment(day).locale(language).format('D MMMM YYYY') } 
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <strong> { t('start_time') } </strong>
                                            <div style={{ fontSize: "1.2rem" }}> { formatTime(attendance?.start) } </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <strong> { t('end_time') } </strong>
                                            <div style={{ fontSize: "1.2rem" }}> { formatTime(attendance?.end) } </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <strong> 
                                                { t('break_time') }
                                                { attendance?.start_pause && 
                                                    <Popup
                                                        position="top center"
                                                        trigger={ <Icon style={{ marginLeft: "0.5rem", color: "var(--success)" }} name="ellipse"/> }
                                                        content={
                                                            <div>
                                                                <Header as="h4" content={ 
                                                                    (attendance?.start_pause !== null && attendance?.end_pause === null) 
                                                                        ? t("pause_started") 
                                                                        : t("pause_ended")
                                                                }/>
                                                                { t('start') }:{" "}
                                                                <strong> 
                                                                    { attendance?.start_pause 
                                                                        ? moment(dates.convertUTCDateToLocalDate(attendance?.start_pause)).format("DD.MM.YYYY HH:mm:ss") 
                                                                        : "--:--"
                                                                    } 
                                                                </strong> <br/>
                                                                { t('end') }:{" "}
                                                                <strong> 
                                                                    { attendance?.end_pause !== null 
                                                                        ? moment(dates.convertUTCDateToLocalDate(attendance?.end_pause)).format("DD.MM.YYYY HH:mm:ss") 
                                                                        : "--:--"
                                                                    }
                                                                </strong>
                                                            </div>
                                                        }
                                                    />
                                                }
                                            </strong>
                                            <div style={{ fontSize: "1.2rem" }}> { formatBreak() } </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <strong> 
                                                { t('interruption_time') }
                                                { interruptions.length > 0 && 
                                                    <Popup
                                                        position="top center"
                                                        trigger={ <Icon style={{ marginLeft: "0.5rem" }} name="information-circle"/> }
                                                        content={
                                                            <div>
                                                                { interruptions.map((interruption, index) => (
                                                                    <>
                                                                        <Header as="h4" content={interruption.reason?.title}/>
                                                                        { t('start') }: {" "}
                                                                        <strong> 
                                                                            { interruption?.start 
                                                                                ? moment(dates.convertUTCDateToLocalDate(interruption?.start)).format("DD.MM.YYYY HH:mm:ss") 
                                                                                : "--:--" 
                                                                            }
                                                                        </strong> <br/>
                                                                        { t('end') }:{" "}
                                                                        <strong>
                                                                            { interruption?.end !== null 
                                                                                ? moment(dates.convertUTCDateToLocalDate(interruption?.end)).format("DD.MM.YYYY HH:mm:ss")
                                                                                : "--:--"
                                                                            }
                                                                        </strong>
                                                                        { (index !== (interruptions.length - 1)) && <Divider/> }
                                                                    </>
                                                                )) }
                                                                
                                                            </div>
                                                        }
                                                    />
                                                }
                                            </strong>
                                            <div style={{ fontSize: "1.2rem" }}> { formatInterruptions() } </div>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <strong> { t('total') } </strong>
                                            <div style={{ fontSize: "1.2rem" }}> { formatTotal() } </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>    
                            <Grid.Column only="computer tablet" computer={4} tablet={4} textAlign="center">
                                <SelfManageActions/>
                            </Grid.Column>    
                        </Grid.Row>    
                    </Grid>    
                </Segment>   
                <Divider/>

                <Grid stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Segment>
                                <Grid>
                                    <Grid.Row verticalAlign="middle" columns="2" style={{ padding: 0 }}>
                                        <Grid.Column>
                                            <Header as="h4" content={ t('last_week') }/>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            { firstDayOfLastWeek.format("DD.MM.YYYY") } - { lastDayOfLastWeek.format("DD.MM.YYYY") }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider/>
                                <LastWeekGraph
                                    employee={employee}
                                    dateFrom={firstDayOfLastWeek}
                                    dateTo={lastDayOfLastWeek}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment>
                                <Grid>
                                    <Grid.Row verticalAlign="middle" columns="2" style={{ padding: 0 }}>
                                        <Grid.Column>
                                            <Header as="h4" content={ t('this_week') }/>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            { firstDayOfCurrentWeek.format("DD.MM.YYYY") } - { lastDayOfCurrentWeek.format("DD.MM.YYYY") }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider/>
                                <ThisWeekGraph 
                                    employee={employee}
                                    dateFrom={firstDayOfCurrentWeek}
                                    dateTo={lastDayOfCurrentWeek}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Segment>
                                <Grid>
                                    <Grid.Row verticalAlign="middle" columns="2" style={{ padding: 0 }}>
                                        <Grid.Column>
                                            <Header as="h4" content={ t('month_overview') }/>
                                        </Grid.Column>
                                        <Grid.Column textAlign="right">
                                            { firstDayOfMonth.format("DD.MM.YYYY") } - { lastDayOfMonth.format("DD.MM.YYYY") }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Divider/>
                                <MonthViewGraph
                                    employee={employee}
                                    dateFrom={firstDayOfMonth}
                                    dateTo={lastDayOfMonth}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
            }
        </Segment>
    );
};

export default DayOverview;