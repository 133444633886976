import React from 'react';
import { useTranslation } from "react-i18next";

import { Header, Segment, Grid, Label, Icon } from 'semantic-ui-react'

const CaseDetail = ({ data }) => {
    const { t } = useTranslation();

    const ListLabels = ({ data }) => {
        if (data.length === 0) return <>{t("no_labels")}</>
        return data.map((item, key) => (
            <Label key={key} style={{ backgroundColor: item.color }}>{item.name}</Label>
        ))
    }
    const ListUsers = ({ users, teams }) => {
        return (
            <div>
                {users.map((item, key) => (
                    <Label key={key} style={{ marginBottom: '0.2rem' }}>
                        <Icon name='user' />
                        {item.name}
                    </Label>
                ))}
                {teams.map((item, key) => (
                    <Label key={key} style={{ marginBottom: '0.2rem' }}>
                        <Icon name='users' />
                        {item.name}
                    </Label>
                ))}
            </div>
        )
    }
    function formatDate(input) {
        let dateAll = input.split(' ')
        let date = dateAll[0]
        let time = dateAll[1]
        date = date.split('-')
        return `${date[2]}.${date[1]}.${date[0]} ${time}`
    }

    return (
        <Segment style={{ margin: 0, display: 'flex', width: "100%" }} as={Grid}>
            <Grid.Row style={{ flexDirection: 'column' }}>
                <Header>
                    {data.title} - {data.type.title}
                    <Header.Subheader>{formatDate(data.created_on)}</Header.Subheader>
                </Header>
            </Grid.Row>
            <Grid.Row style={{ flexDirection: 'column' }}>
                <Header>{t("description")}</Header>
                <div>{data.description}</div>
            </Grid.Row>
            <Grid.Row style={{ flexDirection: 'column' }}>
                <Header>{t("labels")}</Header>
                <div style={{ display: 'flex' }}>
                    <ListLabels data={data.label} />
                </div>
            </Grid.Row>
            <Grid.Row style={{ flexDirection: 'column' }}>
                <Header>{t("assignees")}</Header>
                <ListUsers users={data.assigned_users} teams={data.assigned_teams} />
            </Grid.Row>

        </Segment>
    )
}

export default CaseDetail;