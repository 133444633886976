import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from 'react-router-dom';
import moment from "moment";
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { useSelectOptions, useFetchData, useQueryPage } from '@helpers/hooks';
// components
import Icon from "@components/Icon";
import { FlexTable, FlexHeader, FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Container, Button, Divider } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import ConfirmModal from '@components/modals/ConfrimModal';
import BreadcrumbNav from '@components/BreadcrumbNav';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import UploadFile from '@components/UploadFile';
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
// module specific
import PlannedCourseForm from "../components/PlannedCourseForm";
import ManageModule from "./ManageModule";
import PlannedCoursesList from "./PlannedCoursesList";
import RecordForm from '../components/RecordForm';
import CoursesFilters from '../components/CoursesFilters';

const CoursesList = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const location = useLocation()
    let page = useQueryPage()

    const [limit, setLimit] = useState(10)
    const [records, setRecords] = useFetchData(API.COURSES_RECORDS + "?paginate=true&limit=" + limit + "&page=" + page)
    const [plannedCourses, setPlannedCourses] = useFetchData(API.PLANNED_COURSES + "?paginate=true&limit=" + limit + "&page=" + page)
    const [categories, setCategories] = useFetchData(API.COURSES_CATEGORIES)
    const [categoriesForSelection] = useSelectOptions(API.COURSES_CATEGORIES, "title")
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(0)
    const [reFetched, setReFetched] = useState(false)

    const initState = {
        category: "",
        search: "",
        mandatory: false,
        selfImprovemet: false
    }

    const [filters, setFilters] = useState(initState)

    const onFilter = () => {
        let filterBaseParams = ""

        if (filters.search !== "") {
            filterBaseParams += "&search=" + filters.search
        }

        if (filters.category !== "") {
            filterBaseParams += "&category=" + filters.category;
        }

        if (filters.mandatory && !filters.selfImprovemet) {
            filterBaseParams += "&is_mandatory=true"
        } else if (!filters.mandatory && filters.selfImprovemet) {
            filterBaseParams += "&is_mandatory=false"
        }

        return filterBaseParams
    }

    const breadcrumb_items = [
        { name: t("courses"), icon: "", href: "" },
    ];

    const handleSubmit = async () => {
        history.replace({ pathname: location.pathname, search: `?page=${1}` });
        await fetchRecords("", true)
    }

    useEffect(() => {
        handleSubmit()
        // eslint-disable-next-line
    }, [filters])

    const fetchRecords = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if (!records.isLoading) {
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if (!params.includes('page')) {
                params += "&page=" + page
            }

            if (!params.includes('limit')) {
                params += "&limit=" + limit
            }

            setRecords(prevState => ({
                ...prevState,
                isLoading: true
            }))

            const request = await requests.get(API.COURSES_RECORDS + "?paginate=true" + params)

            if (request.status === 200) {
                setRecords(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }

            setReFetched(false)
            setLoading(false)
        }
    };


    const resolveExpiration = (dateFrom, dateTo) => {
        if (dateTo === null || dateTo === undefined || dateTo === "") {
            return t("no_expiration");
        }

        if (moment().isAfter(dateTo)) {
            return (
                <span style={{ color: "var(--danger)" }}>{t("expired")}</span>
            );
        }

        return moment(dateTo).endOf("day").fromNow();
    };

    const onDelete = async (id) => {
        const request = await requests.del(API.COURSES_RECORDS + id + "/")
        if (request.status === 204) {
            setRecords(prev => ({
                ...prev,
                data: {
                    ...prev.data,
                    count: prev.data.count - 1,
                    results: prev.data.results.filter((item) => item.id !== id)
                }
            }))
        }
    };

    const actions = [
        {
            component: (
                <CanView permissions={["courses.c_manage_all_courses"]}>
                    <SuperDuperModal
                        trigger={
                            <Button
                                color="black"
                                icon={<Icon name="settings-outline" style={{ marginRight: "0.5rem" }}/>}
                                content={<span style={{ position: "relative", top: "-0.2rem" }}> { t("manage") } </span>}
                                size="small"
                            />
                        }
                        size="tiny"
                        content={
                            <ManageModule
                                categories={categories.data}
                                setCategories={setCategories}
                                resolveExpiration={resolveExpiration}
                            />
                        }
                    />
                </CanView>
            ),
        }
    ]

    return (
        <CanView permissions={["courses.c_view_all_courses", "courses.c_view_user_courses"]} redirect>
            <BreadcrumbNav items={breadcrumb_items} actions={actions} />
            <Container fluid >
                <Button.Group basic size="small" style={{ borderRadius: "0" }}>
                    <Button active={view === 0} content={t('records')} onClick={() => setView(0)} />
                    <Button active={view === 1} content={t('planned_courses')} onClick={() => setView(1)} />
                </Button.Group>
                <CanView permissions={["courses.c_manage_all_courses"]}>
                    <Button.Group size="small" style={{ borderRadius: "0", float: "right" }}>
                        <SuperDuperModal
                            trigger={
                                <Button
                                    style={{ background: "var(--dark)", color: "var(--light)" }}
                                    onClick={() => setView(0)}
                                    content={t('create_record')}
                                    size="small"
                                />
                            }
                            size='small'
                            content={
                                <RecordForm setRecords={setRecords} />
                            }
                        />
                        <SuperDuperModal
                            trigger={
                                <Button
                                    primary
                                    onClick={() => setView(1)}
                                    content={t("create_planned_course")}
                                    size="small"
                                />
                            }
                            size="small"
                            content={
                                <PlannedCourseForm setPlannedCourses={setPlannedCourses} />
                            }
                        />
                    </Button.Group>
                </CanView>
                {view === 0 ?
                    <>
                        <Divider />
                        <CoursesFilters filter={filters} setFilter={setFilters} categories={categoriesForSelection.options} fetchRecords={fetchRecords} />
                        <Divider />
                        {(loading || reFetched) ? <Loading /> :
                            <>
                                <FlexTable>
                                    <FlexRow>
                                        <FlexHeader basis="130%" content={t('employee')} />
                                        <FlexHeader basis="130%" content={"Course"} />
                                        <FlexHeader content={t('made_on')} />
                                        <FlexHeader content={t('valid_from')} />
                                        <FlexHeader content={t('valid_until')} />
                                        <FlexHeader content={t('status')} />
                                        <FlexHeader content={t('document')} />
                                        <CanView permissions={["courses.c_manage_all_courses"]}>
                                            <FlexHeader content={t('actions')} />
                                        </CanView>
                                    </FlexRow>
                                    <EmptyRow length={records?.data?.results?.length || 0}/>
                                    {records?.data?.results?.map(record => (
                                        <FlexRow borders key={record.id}>
                                            <FlexItem basis="130%" bold content={record.person.fullname} />
                                            <FlexItem basis="130%" bold content={
                                                <>
                                                    {record?.course?.title} <br />
                                                    <small>{resolveExpiration(record.valid_from, record.valid_until)}</small>
                                                </>
                                            } />
                                            <FlexItem content={record.made_on} />
                                            <FlexItem content={record.valid_from} />
                                            <FlexItem content={record.valid_until} />
                                            <FlexItem bold content={!record.failed ? <span style={{ color: "var(--success)" }}>Completed</span> : <span style={{ color: "var(--danger)" }}>Failed</span>} />
                                            <FlexItem>
                                                {record?.attachment
                                                    ?
                                                    // eslint-disable-next-line
                                                    <a href={record?.attachment} target="_blank" rel="noopener noreferrer">
                                                        <Icon style={{ cursor: "pointer", color: "var(--danger)", fontSize: "1.5rem"}} name="document-text-outline" />
                                                    </a>
                                                    :
                                                    <CanView permissions={["courses.c_manage_all_courses"]}>
                                                        <SuperDuperModal
                                                            trigger={<Button size="tiny" basic content={t('add_document')} />}
                                                            content={
                                                                <UploadFile
                                                                    field="attachment"
                                                                    endpoint={API.COURSES_RECORDS + record.id + "/attachment/"}
                                                                    callback={(response) => setRecords(prev => ({
                                                                        ...prev,
                                                                        data: {
                                                                            ...prev.data,
                                                                            results: prev.data.results.filter((item) => {
                                                                                if (item.id === record.id) {
                                                                                    item.attachment = response.attachment
                                                                                }

                                                                                return item
                                                                            })
                                                                        }
                                                                    }))}
                                                                />
                                                            }
                                                        />
                                                    </CanView>
                                                }
                                            </FlexItem>
                                            <FlexItem>
                                                <FlexRow padding="0" background="transparent">
                                                    <CanView permissions={["courses.c_manage_all_courses"]}>
                                                        <FlexItem>
                                                            <SuperDuperModal
                                                                trigger={
                                                                    <Icon name="pencil" style={{ color: "var(--dark)", cursor: "pointer" }} />
                                                                }
                                                                size='small'
                                                                content={
                                                                    <RecordForm record={record} setRecords={setRecords} />
                                                                }
                                                            />
                                                        </FlexItem>
                                                        <FlexItem>
                                                            <ConfirmModal
                                                                description={t('are_you_sure')}
                                                                onConfirm={() => onDelete(record.id)}
                                                                button={
                                                                    <Icon name="close" style={{ color: "var(--danger)", cursor: "pointer" }} />
                                                                }
                                                            />
                                                        </FlexItem>
                                                    </CanView>
                                                </FlexRow>
                                            </FlexItem>
                                        </FlexRow>
                                    ))}
                                </FlexTable>
                                <Paginator
                                    forcePage={page || 1}
                                    limit={limit}
                                    setLimit={setLimit}
                                    length={records?.data?.count || 0}
                                    onChange={(params) => fetchRecords(params)}
                                />
                            </>
                        }
                    </>
                    : <PlannedCoursesList plannedCourses={plannedCourses} setPlannedCourses={setPlannedCourses} categories={categoriesForSelection.options} records={records} />
                }
            </Container>
        </CanView>
    );
};

export default CoursesList;
