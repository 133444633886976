import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { useHasPermissions } from '@helpers/hooks'
import { patchRequest, getRequest } from '@services/ServiceCommon';
// components
import { useTranslation } from 'react-i18next';
import SuperField from '@components/forms/SuperField'; 
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Header, Segment, Button, Icon, Popup, Form, Divider } from 'semantic-ui-react';
import { FlexHeader, FlexItem, FlexRow, FlexTable } from '@components/tables/FlexTable';
// import CanView from '@components/perms/CanView';
import ConfirmModal from '@components/modals/ConfrimModal';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import BudgetDetailView from '../../cost-centers/components/BudgetDetailView';
import InvoicePreviewPDF from '../../../sm/invoices/components/InvoicePreviewPDF';

const ApprovalsList = ({ status, approvals, setApprovals }) => {
    const { t } = useTranslation();
    const user = useSelector(state => state.user)
    const hasPermToManageAll = useHasPermissions(['approvals.c_manage_all_approvals'])

    const onPerformAction = async (status, approval, note) => {
        if( approval.resourcetype === "InvoiceApproval" ){
            let approvalData = {}

            if( user.profile_id === approval.responsible_person?.id ){
                approvalData['approved_by_responsible_person'] = status
                if( note !== undefined ){
                    approvalData['responsible_person_note'] = note
                }
            }
            
            if( user.profile_id === approval.second_responsible_person?.id ){
                approvalData['approved_by_second_responsible_person'] = status
                if( note !== undefined ){
                    approvalData['second_responsible_person_note'] = note
                }
            }

            if( user.profile_id === approval.reviewer?.id ){
                approvalData['approved_by_reviewer'] = status
                if( note !== undefined ){
                    approvalData['reviewer_note'] = note
                }
            }

            if( user.profile_id === approval.second_reviewer?.id ){
                approvalData['approved_by_second_reviewer'] = status
                if( note !== undefined ){
                    approvalData['second_reviewer_note'] = note
                }
            }

            const request = await patchRequest(API.APPROVALS + "invoices/" + approval.id + "/", approvalData)

            if(request.status === 200){
                const status = request.response?.is_approved
                const approval_date = request.response?.approval_datetime ? moment(request.response.approval_datetime).format("YYYY-MM-DD") : null
                if( status === true || status === false ){
                    const connectionRequest = await getRequest(API.INVOICES + "invoice_connections/")
                    if(connectionRequest.status === 200){
                        const invoice_id = approval.invoice_reference.split(';')[1]
                        let connection = connectionRequest?.response?.[0] 
                        await patchRequest(API.INVOICES + invoice_id + "/?connection=" + connection.connection_id, {
                            approval: status === true ? 1 : 3,
                            date_approved: status === true ? approval_date : null 
                        })

                        // if is_approved is set to TRUE, try to change status from Draft to Pending
                        if (status === true) {
                            await patchRequest(API.INVOICES + invoice_id + "/?connection=" + connection.connection_id, {
                                status: 1
                            })
                        }
                    }
                }

                if( request.response?.is_approved === true ){
                    setApprovals(prevState => {
                        return {
                            ...prevState,
                            data: {
                                ...prevState.data,
                                count: prevState.data.count - 1,
                                results: prevState.data.results.filter(item => item.id !== approval.id)
                            }
                        }
                    })
                } else {
                    setApprovals(prevState => {
                        return {
                            ...prevState,
                            data: {
                                ...prevState.data,
                                results: prevState.data.results.filter(item => {
                                    if( item.id === approval.id ){
                                        item.is_approved = request.response.is_approved
                                        item.automatic_approval = request.response.automatic_approval
                                        item.approved_by_responsible_person = request.response.approved_by_responsible_person
                                        item.approved_by_second_responsible_person = request.response.approved_by_second_responsible_person
                                        item.approved_by_reviewer = request.response.approved_by_reviewer
                                        item.approved_by_second_reviewer = request.response.approved_by_second_reviewer
                                        item.responsible_person_note = request.response.responsible_person_note
                                        item.second_responsible_person_note = request.response.second_responsible_person_note
                                        item.reviewer_note = request.response.reviewer_note
                                        item.second_reviewer_note = request.response.second_reviewer_note
                                        item.responsible_person = request.response.responsible_person
                                        item.second_responsible_person = request.response.second_responsible_person
                                        item.reviewer = request.response.reviewer
                                        item.second_reviewer = request.response.second_reviewer
                                    }
        
                                    return item
                                })
                            }
                        }
                    })
                }
            }
        } else {
            const request = await patchRequest(API.APPROVALS + approval.id + "/", {
                is_approved: status
            })
    
            if(request.status === 200){
                setApprovals(prevState => {
                    return {
                        ...prevState,
                        data: {
                            ...prevState.data,
                            count: prevState.data.count - 1,
                            results: prevState.data.results.filter(item => item.id !== approval.id)
                        }
                    }
                })
            }
        }
    }

    const isConfirmed = (approval, status) => {
        if( approval.resourcetype === "InvoiceApproval" ){
            if( (user.profile_id === approval?.responsible_person?.id) && (approval?.approved_by_responsible_person === status) ){
                return true
            }
            
            if( (user.profile_id === approval?.second_responsible_person?.id) && (approval?.approved_by_second_responsible_person === status) ){
                return true
            }
            
            if( (user.profile_id === approval?.reviewer?.id) && (approval?.approved_by_reviewer === status) ){
                return true
            }
            
            if( (user.profile_id === approval?.second_reviewer?.id) && (approval?.approved_by_second_reviewer === status) ){
                return true
            }
        } else {
            return approval.is_approved === status
        }
    }

    const isAssignedUser = (approval) => {
        if( approval.resourcetype === "InvoiceApproval" ){
            if( user.profile_id === approval?.responsible_person?.id ){
                return true
            }
            
            if( user.profile_id === approval?.second_responsible_person?.id ){
                return true
            }
            
            if( user.profile_id === approval?.reviewer?.id ){
                return true
            }
            
            if( user.profile_id === approval?.second_reviewer?.id ){
                return true
            }
        } else {
            if( hasPermToManageAll ){
                return true
            } else {
                if( user.profile_id === approval?.responsible_person?.id ){
                    return true
                }
            }
        }

        return false
    }

    const RecruitmentApproval = ({ approval }) => {
        return (
            <>
                {t('recruitment')}: <Link className="ref-link" to={routes.HIRING_ROOM + approval?.recruitment?.id + "/"} target="_blank">{ approval?.recruitment?.name }</Link>
            </>
        )
    }

    const PlannedCourseApproval = ({ approval }) => {
        return (
            <>
                {t('planned_course')}: <Link className="ref-link" to={routes.COURSES} target="_blank">{ approval?.planned_course?.course?.title }</Link>
            </>
        )
    }

    const ProjectApproval = ({ approval }) => {
        return (
            <>
                {t('project')}: <Link className="ref-link" to={routes.PROJECT_DETAIL + approval.project.id + "/"} target="_blank">{ approval?.project?.name }</Link>
            </>
        )
    }

    const RecruitmentStageApproval = ({ approval }) => {
        return (
            <>
                { t('recruitment') } - {t('move_to_stage_request')}:<br/>
                <small>{t('from')} <u>{ approval.stage_from.name }</u> {t('to')} <u>{ approval.stage_to.name }</u> <br/></small>
                <Link className="ref-link" to={routes.HIRING_ROOM + approval.recruitment.id + routes.CANDIDATES_DETAIL + approval.candidate.id} target="_blank">{t('show_details')}</Link>
            </>
        )
    }

    const BudgetApproval = ({ approval }) => {
        return (
            <>
                {t('budget')}: {" "}
                <SuperDuperModal
                    size="large"
                    trigger={
                        <span style={{ color: "var(--primary)" }} className="ref-link">{ moment(approval?.budget?.date_from).format("DD.MM.YYYY")} - { moment(approval?.budget?.date_to).format("DD.MM.YYYY") }</span>
                    }
                    content={<BudgetDetailView budget={approval.budget}/>}
                />
            </>
        )
    }

    const InvoiceApproval = ({ approval }) => {
        const reference = approval.invoice_reference.split(';')
        return (
            <>
                {t('recieved_invoice')}: {" "}
                <SuperDuperModal
                    size="large"
                    trigger={
                            <span style={{ color: "var(--primary)" }} className="ref-link">
                                { reference?.[2] }
                            </span>
                    }
                    content={
                        <InvoicePreviewPDF
                            id={reference?.[1]}
                            approvalActions={
                                <>
                                    <InvoiceApprovalConfirmation 
                                        approval={approval}
                                        confirmType={"approve"}
                                    />
                                    <InvoiceApprovalConfirmation 
                                        approval={approval}
                                        confirmType={"deny"}
                                    />
                                </>
                            }
                        />
                    }
                />
                { reference.length > 4 
                    && 
                        <>
                            <br/>
                            { reference?.[3] + ", " + reference?.[4] + ", " + reference?.[5] }
                        </>
                }
            </>
        )
    }

    const InvoiceApprover = ({ approver, decision, note, type }) => {
        return (
            <div>
                { approver?.fullname 
                    ? 
                        <>
                            { decision === null && <Icon name="circle outline" style={{ color: "transparent" }}/>}
                            { decision === true && <Icon name="checkmark" style={{ color: "var(--success)" }}/>}
                            { decision === false && <Icon name="close" style={{ color: "var(--danger)" }}/>}
                            <Link className="ref-link" title={t(type)} to={routes.EMPLYOEES_DETAIL + approver?.id} target="_blank">{ approver?.fullname }</Link> {" "}
                            { (note !== "" && note !== null) &&
                                <Popup
                                    basic
                                    trigger={
                                        <Icon name="file alternate outline" style={{ paddingLeft: "0.5rem", color: "var(--dark)" }}/>
                                    }
                                    content={ note }
                                />
                            }
                        </>
                    : ""
                }
            </div>
        )
    }

    const InvoiceApprovalConfirmation = ({ approval, confirmType }) => {

        const ConfirmType = ({ confirmType, approval, ...rest }) => {
            if( confirmType === "approve" ){

                return (
                    <Button { ...rest }
                        disabled={ !isAssignedUser(approval) || isConfirmed(approval, true) }
                        primary
                    >
                        { t(confirmType) }
                    </Button>
                )
            } else {
                return (
                    <Button { ...rest }
                        disabled={ !isAssignedUser(approval) || isConfirmed(approval, false)  }
                        style={{ background: "var(--danger)", color: "var(--white)" }} 
                    >
                        { t('deny') }
                    </Button>
                )
            }
        }

        const ConfirmForm = ({ onPerformAction, onClose, approval, confirmType }) => {
            const [isProcessing, setIsProcessing] = useState(false)
            const [note, setNote] = useState("")

            const handleSubmit = async () => {
                setIsProcessing(true)
                if( confirmType === "approve" ){
                    await onPerformAction(true, approval, note)
                } else {
                    await onPerformAction(false, approval, note)
                }
                
                onClose()
                setIsProcessing(false)
            }

            return (
                <Form onSubmit={handleSubmit}>
                    <Header as="h3" content={t('are_you_sure')}/>
                    <Divider/>
                    <SuperField 
                        fluid 
                        as="textarea"
                        value={note}
                        onChange={(e, { value }) => setNote(value)}
                        label={ t('note') }
                    />
                    <Divider/>
                    <Form.Field style={{ textAlign: "right" }}>
                        <ModalCancel 
                            onClose={onClose}
                            disabled={isProcessing}
                        />

                        <ModalSubmit
                            disabled={isProcessing}
                            loading={isProcessing}
                            text={t('confirm')}
                        />
                    </Form.Field>
                </Form>
            )
        }

        return (
            <SuperDuperModal
                trigger={ 
                    <ConfirmType 
                        confirmType={confirmType}
                        approval={approval}
                    />
                }
                content={ 
                    <ConfirmForm
                        onPerformAction={onPerformAction}
                        approval={approval}
                        confirmType={confirmType}
                    />
                }
            />
        )
    }

    return (
        <Segment loading={approvals.isLoading} style={{ border: 0, boxShadow: 'none', minHeight: "5rem", padding: 0, background: "transparent" }}>
            { !approvals.isLoading && 
                <FlexTable>
                    <FlexRow>
                        <FlexHeader basis="200%" content={t('requested_approval')} />
                        <FlexHeader content={t('requested_when')} />
                        <FlexHeader content={t('approval_status')} />
                        { status === true && <FlexHeader content={t('approved_when')} /> }
                        { status === false && <FlexHeader content={t('denied_when')} /> }
                        <FlexHeader content={t('responsible_person')} />
                        {status === null && <FlexHeader content={t('actions')} />}
                    </FlexRow>
                    {approvals.data.results.length === 0 && (
                        <FlexRow fontSize="1.2rem">
                            <FlexItem textAlign="center">
                                <Header as="h2" content={t('no_record_found')} />{' '}
                            </FlexItem>
                        </FlexRow>
                    )}

                    { approvals.data.results.map(approval => (
                            <FlexRow borders key={approval.id} fontSize="1rem">
                                <FlexItem basis="200%" bold>
                                    { approval.resourcetype === "RecruitmentApproval" && <RecruitmentApproval approval={approval}/> }
                                    { approval.resourcetype === "PlannedCourseApproval" && <PlannedCourseApproval approval={approval}/> }
                                    { approval.resourcetype === "ProjectApproval" && <ProjectApproval approval={approval}/> }
                                    { approval.resourcetype === "BudgetApproval" && <BudgetApproval approval={approval}/> }
                                    { approval.resourcetype === "InvoiceApproval" && <InvoiceApproval approval={approval}/> }
                                    { approval.resourcetype === "RecruitmentStageApproval" && <RecruitmentStageApproval approval={approval}/> }
                                </FlexItem>
                                <FlexItem bold content={moment(approval.created_on).format("DD.MM.YYYY HH:mm")} />
                                <FlexItem
                                    content={
                                        approval.is_approved === null ? t('pending')
                                        : approval.is_approved === false
                                        ? t('denied')
                                        : t('approved')
                                    }
                                />
                                { status !== null && <FlexItem bold content={
                                    <>
                                        { approval?.approval_datetime && 
                                            <>{ moment(approval.approval_datetime).format("DD.MM.YYYY HH:mm")} <br/></>
                                        }
                                        <small>
                                            { approval.approved_by?.fullname 
                                                ? <Link className="ref-link" to={routes.EMPLYOEES_DETAIL + approval?.approved_by?.id} target="_blank">{ approval.approved_by?.fullname }</Link>
                                                : "--"
                                            }
                                        </small>
                                    </>    
                                }/> }
                                <FlexItem>
                                    { approval.resourcetype === "InvoiceApproval" 
                                        ? 
                                        <>
                                            <InvoiceApprover
                                                approver={approval.responsible_person}
                                                decision={approval.approved_by_responsible_person}
                                                note={approval.responsible_person_note}
                                                type={'responsible_person'}
                                            />
                                            <InvoiceApprover
                                                approver={approval.second_responsible_person}
                                                decision={approval.approved_by_second_responsible_person}
                                                note={approval.second_responsible_person_note}
                                                type={'second_responsible_person'}
                                            />
                                            <InvoiceApprover
                                                approver={approval.reviewer}
                                                decision={approval.approved_by_reviewer}
                                                note={approval.reviewer_note}
                                                type={'reviewer_person'}
                                            />
                                            <InvoiceApprover
                                                approver={approval.second_reviewer}
                                                decision={approval.approved_by_second_reviewer}
                                                note={approval.second_reviewer_note}
                                                type={'second_reviewer_person'}
                                            />
                                        </>
                                        : 
                                        <>
                                            { approval.responsible_person?.fullname 
                                                ? 
                                                    <>
                                                        <Icon name="circle outline" style={{ color: "transparent" }}/>
                                                        <Link className="ref-link" to={routes.EMPLYOEES_DETAIL + approval?.responsible_person?.id} target="_blank">{ approval.responsible_person?.fullname }</Link>
                                                    </>
                                                : ""
                                            }
                                        </>
                                    }
                                    
                                </FlexItem>
                                
                                {/* <CanView permissions={["approvals.c_manage_all_approvals", "approvals.c_manage_user_approvals"]}> */}
                                    {status === null && 
                                        <FlexRow padding="0" background="transparent">
                                            <Button.Group size="tiny">
                                                { approval.resourcetype === "InvoiceApproval" 
                                                    ? 
                                                    <>
                                                        <InvoiceApprovalConfirmation 
                                                            approval={approval}
                                                            confirmType={"approve"}
                                                        />
                                                        <InvoiceApprovalConfirmation 
                                                            approval={approval}
                                                            confirmType={"deny"}
                                                        />
                                                    </>
                                                    : 
                                                    <>
                                                        <ConfirmModal
                                                            description={t('are_you_sure')}
                                                            onConfirm={() => onPerformAction(true, approval)}
                                                            button={
                                                                <Button 
                                                                    disabled={ !isAssignedUser(approval) || isConfirmed(approval, true) }
                                                                    primary
                                                                >
                                                                    { t('approve') }
                                                                </Button>
                                                            }
                                                        />
                                                        <ConfirmModal
                                                            description={t('are_you_sure')}
                                                            onConfirm={() => onPerformAction(false, approval)}
                                                            button={
                                                                <Button 
                                                                    disabled={ !isAssignedUser(approval) || isConfirmed(approval, false) }
                                                                    style={{ background: "var(--danger)", color: "var(--white)" }} 
                                                                >
                                                                    { t('deny') }
                                                                </Button>
                                                            }
                                                        />
                                                    </>
                                                }
                                            </Button.Group>
                                        </FlexRow>
                                    }
                                {/* </CanView> */}
                            </FlexRow>
                        )
                    )}
                </FlexTable>
            }
        </Segment>
    );
};

export default ApprovalsList;
