import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
// components
//other
import SuperField from '@components/forms/SuperField';
import { Message, Button, Divider, Form } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const PassportForm = ({ onClose, existingPassport, data, setData, patchEndpoint }) => {
    const { t } = useTranslation();

    const [passport, setPassport] = useState({
        travel_document_type: existingPassport ? existingPassport?.travel_document_type?.toString() : 1,
        travel_document_number: existingPassport ? existingPassport?.travel_document_number : "",
        date_of_issue: existingPassport ? existingPassport?.date_of_issue : null,
        valid_until: existingPassport ? existingPassport?.valid_until : null,
        issued_by: existingPassport ? existingPassport?.issued_by : null,
        issued_country: existingPassport ? existingPassport?.issued_country?.toString() : ""
    });
    const [errors, setErrors] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false)

    // const [image, setImage] = useState("");

    const handleSubmit = async () => {
        setIsProcessing(true)
        let passportData = {
            travel_document_type: passport.travel_document_type,
            travel_document_number: passport.travel_document_number,
            date_of_issue: passport.date_of_issue !== "" ? passport.date_of_issue : null,
            valid_until: passport.valid_until !== "" ? passport.valid_until : null,
            issued_by: passport.issued_by,
        }

        if (passport.issued_country === "" || passport.issued_country === null) {
            delete passport.issued_country
        }

        if (passport.issued_country !== "") {
            passportData['issued_country'] = passport.issued_country
        }

        if (existingPassport && existingPassport.id) {

            let form_data = data.personal_information.passport
            form_data = {
                personal_information: {
                    passports: {
                        update: {[existingPassport.id]: passport}
                    }
                }
            }
            const request = await requests.patch(patchEndpoint + `${data?.id}/`, form_data)

            if (request.status === 200) {
                setData(prev => ({
                    ...prev,
                    personal_information: request.response.personal_information
                }))

                onClose()
            } else if( request.status === 400 ) {
                setErrors(request.response)
            }
        } else {

            let form_data = data.personal_information.passports
            form_data = {
                personal_information: {
                    passports: {
                        create: [passport]
                    }
                }
            }
            const request = await requests.patch(patchEndpoint + `${data?.id}/`, form_data)

            if (request.status === 200) {
                setData(prev => ({
                    ...prev,
                    personal_information: request.response.personal_information
                }))
                onClose()
            } else if( request.status === 400 ) {
                setErrors(request.response)
            }
        }

        setIsProcessing(false)
    }

    const removePassport = async (id) => {
        setIsProcessing(true)
        const request = await requests.del(API.PASSPORTS + id + "/")

        if (request.status === 204) {
            setData(prev => ({
                ...prev,
                personal_information: {
                    ...prev.personal_information,
                    passports: prev.personal_information?.passports?.filter(passport => passport.id !== id)

                }
            }))
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                header={t("error_submission_message")}
                list={errors?.non_field_errors}
            />

            <SuperField as="choice"
                required
                type="travel_document_type_choices"
                label={t('add_passp')}
                error={ errors?.personal_information.birth_info.citizenship?.[0] || false }
                value={passport?.travel_document_type?.toString()}
                onChange={(e, { value }) => setPassport({
                    ...passport,
                    travel_document_type: value
                })}
            />
            <SuperField as="input"
                disabled={passport.travel_document_type === "3" || passport.travel_document_type === "4"}
                label={t('travel_doc_num')}
                placeholder={t('enter_doc')}
                error={ errors?.travel_document_number?.[0] || false }
                value={passport.travel_document_number}
                onChange={e => setPassport({
                    ...passport,
                    travel_document_number: e.target.value
                })}
            />
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    disabled={passport.travel_document_type === "3" || passport.travel_document_type === "4"}
                    label={t('date_issue')}
                    error={ errors?.date_of_issue?.[0] || false }
                    value={passport.date_of_issue}
                    onChange={(e, { value }) => setPassport({
                        ...passport,
                        date_of_issue: value
                    })}
                />
                <SuperField as="datepicker"
                    disabled={passport.travel_document_type === "3" || passport.travel_document_type === "4"}
                    label={t('valid_until')}
                    error={ errors?.valid_until?.[0] || false }
                    value={passport.valid_until}
                    onChange={(e, { value }) => setPassport({
                        ...passport,
                        valid_until: value
                    })}
                />
            </Form.Group>

            <Form.Group widths="equal">
                <SuperField as="input"
                    disabled={passport.travel_document_type === "3" || passport.travel_document_type === "4"}
                    label={t('issued_by')}
                    placeholder={t('enter_issued_by')}
                    error={ errors?.issued_by?.[0] || false }
                    value={passport.issued_by}
                    onChange={e => setPassport({
                        ...passport,
                        issued_by: e.target.value
                    })}
                />
                <SuperField as="choice"
                    search
                    type="countries"
                    label={t('issued_country')}
                    error={ errors?.issued_country?.[0] || false }
                    disabled={passport.travel_document_type === "3" || passport.travel_document_type === "4"}
                    value={passport?.issued_country?.toString()}
                    onChange={(e, { value }) => setPassport({
                        ...passport,
                        issued_country: value
                    })}
                />
            </Form.Group>

            {/* <Form.Field>
                <Form.Input
                    disabled={true}
                    type="file"
                    label={t('warning')}
                />
                <span><strong>{t('passp_image')}</strong>: {t('passp_image_err')}</span>
            </Form.Field> */}

            <Divider />
            <Button.Group floated="right" style={{ marginBottom: "1.5rem" }}>
                {existingPassport && <Button disabled={isProcessing} type="button" color="red" onClick={() => removePassport(existingPassport.id)}> {t('passp_remove')} </Button>}
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit text={t('submit')} loading={isProcessing} disabled={isProcessing} />
            </Button.Group>
        </Form>
    );
};

export default PassportForm;