import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import moment from 'moment';
// store 
import { API } from "@store/config";
import { requests } from '@helpers/requests';
import { dates } from '@store/services/helpers/dates';
// components
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
import SuperField from '@components/forms/SuperField'
import { Segment, Form, Divider } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';

const StoredItemHistory = ({ storedItem }) => {
    const { t } = useTranslation()
    
    const [loading, setLoading] = useState(true)
    const [history, setHistory] = useState([])

    // eslint-disable-next-line
    const [page, setPage] = useState(1)
    // eslint-disable-next-line
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)

    const [filters, setFilters] = useState({
        operation: "",
    })
    
    async function fetchHistory(params, isFiltered){
        setLoading(true)

        if (params !== undefined){
            const queryParams = params.split("&")
            const pageParam = queryParams.find(item => item.includes('page'))
            if (pageParam) {
                const splitArray = pageParam.split("=")
                setPage(splitArray[1])
            }
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        } 

        if( filters.operation ) params += "&operation=" + filters.operation
        const query = "&query={id,operation_display,operation,created_on,created_by,note,quantity,stored_item{id,code,sn,item{id,title},warehouse{id, title}}}"
        const request = await requests.get(API.ASSET_STORED_ITEMS + storedItem.id + `/history/?paginate=true&ordering=-created_on` + params + query)
        if (request.status === 200) {
            setHistory(request.response.results)
            setTotal(request.response.count)
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchHistory("", true)
        // eslint-disable-next-line
    }, [page, filters])

    
    return (
        <Segment loading={loading} style={{ 
            padding: 0, 
            background: "transparent", 
            boxShadow: "none", 
            border: "none" 
        }}>
            <Form onSubmit={(e) => e.preventDefault()}>
                <SuperField as="choice"
                    search
                    width={6}
                    size="small"
                    label={t('operation')}
                    type="issued_item_operation"
                    value={filters.operation}
                    onChange={(e, { value }) => setFilters({...filters, operation: value})}
                />
            </Form>
            <Divider/>
            { !loading && 
                <>

                    <FlexTable responsive={false}>
                        <FlexRow fontSize="0.9rem">
                            <FlexHeader content={t('warehouse')} />
                            <FlexHeader content={t('operation')} />
                            <FlexHeader content={t('quantity')} />
                            <FlexHeader content={t('owner')} />
                            <FlexHeader content={t('note')} />
                            <FlexHeader content={t('made_on')} />
                        </FlexRow>
                        <EmptyRow length={history.length}/>
                        { history.map(item => (
                            <FlexRow borders fontSize="0.9rem" key={item.id}>
                                <FlexItem>{item?.stored_item?.warehouse?.title}</FlexItem>
                                <FlexItem>{item?.issued_item?.status_display ? item?.issued_item?.status_display + " - " : ""} {item.operation_display} </FlexItem>
                                <FlexItem>{item.quantity}</FlexItem>
                                <FlexItem>{item?.issued_item?.employee?.fullname}</FlexItem>
                                <FlexItem>{item.note}</FlexItem>
                                <FlexItem>
                                    {moment(dates.convertUTCDateToLocalDate(item.created_on)).format("DD.MM.YYYY HH:mm")}<br/>
                                    <small>by <strong>{ item.created_by?.name }</strong></small>
                                </FlexItem>
                            </FlexRow>
                        ))}
                    </FlexTable>
                    { total > 0 && 
                        <Paginator
                            forcePage={page || 1}
                            forceReload={false}
                            limit={limit}
                            setLimit={setLimit}
                            length={total || 0}
                            onChange={(params) => fetchHistory(params)}
                        />
                    }
                </>
            }

        </Segment>
    );
};

export default StoredItemHistory;