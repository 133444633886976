import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
// store
import { routes } from '../../store/routes';
// components
import Icon from '@components/Icon';
import Tree from 'react-d3-tree';
import { Button } from 'semantic-ui-react';
import { CreateUnitForm } from '@pages/dashboard/core/company/components/units/CreateUnitForm';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { useTranslation } from 'react-i18next';
import CanView from '@components/perms/CanView';


const AddUnitButton = ({ unit, rerender }) => {
    const company = useSelector(state => state.company)
    const {t} = useTranslation()

    const handleUnit = () => {
        rerender()
    }

    return (
        <CanView permissions={["company.c_manage_units"]}>
            <SuperDuperModal
                trigger={
                    <Button basic fluid style={{ padding: "0.5rem", borderColor: "var(--light-grey)" }}>
                        <Icon name="add-outline" />
                    </Button>
                }
                size="small"
                header={t('create_unit_org')}
                content={
                    <CreateUnitForm
                        unitId={unit}
                        companyId={company.id}
                        onConfirm={(unit) => handleUnit()}
                    />
                }
            />
        </CanView>

    );
}


const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            // const { width, height } = containerElem.getBoundingClientRect();
            const { width } = containerElem.getBoundingClientRect();
            // setTranslate({ x: width / 2, y: height/2 });
            setTranslate({ x: width / 2, y: 40 })
        }
    }, []);
    return [translate, containerRef];
};



const TreeGraph = (props) => {
    const [translate, containerRef] = useCenteredTree();
    const nodeSize = { x: 180, y: 90 };
    const foreignObjectProps = {
        width: nodeSize.x,
        height: nodeSize.y,
        x: -90,
        y: -40
    };

    const rerender = props.rerender

    const leafClass = 'leaf-class';
    const rootClass = 'root-class';

    const NodeLink = (props) => {

        return (
            <Link to={routes.COMPANY + "unit/" + props.data?.id}>
                <div style={props.data.attributes?.is_business ? styles.nodeActive : styles.nodeHead }>
                        {props.data?.name?.slice(0, 21)}
                        {props.data?.name?.length > 23 && <span>...</span>}
                </div>
                {props.data.attributes?.employee_count && (<div>Employees: {props.data.attributes?.employee_count}</div>)}
            </Link>
        )
    }

    const renderForeignObjectNode = ({  nodeDatum, toggleNode, rerender, foreignObjectProps }) => (
        <g>
            <foreignObject {...foreignObjectProps}>
                <div style={styles.node} className={nodeDatum.children ? rootClass : leafClass} >
                    <NodeLink data={nodeDatum}/>
                    {nodeDatum.children && (

                        <div>
                            <AddUnitButton unit={nodeDatum.id} rerender={rerender}/>
                            <Button fluid style={styles.nodeButton} onClick={toggleNode}>
                                {nodeDatum.__rd3t.collapsed ? <Icon name="chevron-down-outline" /> : <Icon name="chevron-up-outline" />}
                            </Button>
                        </div>
                    )}
                </div>
            </foreignObject>
        </g >
    );


    return (
        <div style={styles.treeContainer} ref={containerRef}>
            <Tree
                data={props.data}
                translate={translate}
                nodeSize={nodeSize}
                renderCustomNodeElement={(rd3tProps) => renderForeignObjectNode({ ...rd3tProps, rerender, foreignObjectProps })}
                orientation="vertical"
                pathFunc="step"
                depthFactor={100}
                // zoomable={true}
                enableLegacyTransitions={true}
                // initialDepth={2}
                // onUpdate={(data) => dynamicLayout(data)}
            />
        </div>
    )

    //==================================================================
        //--------------USELESS CODE----------------------------------------
        // const HEIGHT_WARIABLE = 100
        // const [treeContainer, setTreeContainer] = useState({
        //     width: "100%",
        //     height: HEIGHT_WARIABLE, //RESIZABLE CONTAINER HEIGHT
        // })

        // let treeHeight = 0

        // function getTreeHeight(data) {//SHOULD GET HEIGHT OF ACTUAL TREE
        //     for (let i = 0; i < data.hasOwnProperty.length; i++) {
        //         if (data.children && data.__rd3t.collapsed === false) {
        //             data.children.map((item) => (getTreeHeight(item)))
        //         }
        //     }
        //     if (data.__rd3t.depth > treeHeight) {
        //         treeHeight = data.__rd3t.depth
        //     }
        //     console.log(data)
        //     return
        // }

        // function dynamicLayout(data) {//SETTING CONTAINER SIZE
        //     if (data.node === null) { return }
        //     getTreeHeight(data.node)
        //     console.log("Tree Depth: " + treeHeight)
        //     setTreeContainer((prevState) => ({ ...prevState, height: ((treeHeight + 1) * HEIGHT_WARIABLE) }))
    // }


}
const styles = {
    treeContainer: {
        width: "100%",
        height: 720,
    },
    node: {
        backgroundColor: "var(--variant5)",
        textAlign: "center",
        fontSize: 13,
        height: "70px",
        marginLeft: "1rem",
        marginRight: "1rem",
        color: "var(--dark)"
    },
    nodeHead: {
        backgroundColor: "var(--success)",
        fontWeight: "bold",
        color: "white",
    },
    nodeActive: {
        backgroundColor: "var(--primary)",
        fontWeight: "bold",
        color: "white",
    },
    nodeButton: {
        width: "100%",
        height: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "var(--info)",
        color: "var(--default)",
        position: "relative",
        bottom: 0
    },
}

export default TreeGraph