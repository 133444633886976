import { APIRequest, URLManager } from './helpers/requests';
import { API } from "../config";


export const teamsService = {
    getTeams,
    deleteTeam,
    createTeam,
    updateTeam,
    getTeamById,
    getTeamMembers,
    getTeamMember,
    createTeamMember,
    updateTeamMember,
    deleteTeamMember,
    getTeamStats
};



async function getTeamStats(team_id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TEAMS, team_id) + "stats/",
        method: 'GET',
        data: {},
        private: true
    });
}

async function getTeams(params) {
    params = params || ""
    return await APIRequest({
        url: API.TEAMS + params,
        method: 'GET',
        data: {},
        private: true
    });
}

// get only one member based on ID
async function getTeamMembers(team_id) {
    return await APIRequest({
        url: API.TEAMMEMBERS +"?team="+team_id,
        method: 'GET',
        data: {},
        private: true
    });
}

async function updateTeamMember(member_id, data) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TEAMMEMBERS, member_id),
        method: 'PUT',
        data: data,
        private: true
    });
}

async function getTeamMember(member_id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TEAMMEMBERS, member_id),
        method: 'GET',
        private: true
    });
}

async function createTeamMember(data) {
    return await APIRequest({
        url: API.TEAMMEMBERS,
        method: 'POST',
        data: data,
        private: true
    });
}

// CRUD for Teams:
async function createTeam(data) {
    return await APIRequest({
        url: API.TEAMS,
        method: 'POST',
        data: data,
        private: true
    });
}

async function updateTeam(data, id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TEAMS, id),
        method: 'PUT',
        data: data,
        private: true
    });
}

async function deleteTeam(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TEAMS, id),
        method: 'DELETE',
        // data: data,
        private: true
    });
}
async function getTeamById(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TEAMS, id),
        method: 'GET',
        data: {},
        private: true
    });
}

async function deleteTeamMember(id) {
    return await APIRequest({
        url: URLManager.url_with_id(API.TEAMMEMBERS, id),
        method: 'DELETE',
        // data: data,
        private: true
    });
}









