import React from 'react';
import { useTranslation } from "react-i18next"
import { useHistory, Link } from 'react-router-dom';

//store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
import { routes } from '@routes';
import { useHasPermissions } from '@helpers/hooks';
//components
import Icon from '@components/Icon';
import AvatarIcon from '@components/AvatarIcon';
import { Grid, Label, Header } from 'semantic-ui-react';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import LabelsForm from '@components/forms/LabelsForm';
import CanView from '@components/perms/CanView';
import Action from '@components/general/Action';
// import ProfileHistory from './ProfileHistory';
import ProfileCardHeaderForm from './forms/ProfileCardHeaderForm';
import StartOnOff from '../../../pages/dashboard/hr/onboarding/components/forms/StartOnOff';

const ProfileHeader = ({data, setData, patchEndpoint}) => {
    const { t } = useTranslation()
    const history = useHistory();
    const canViewUnit = useHasPermissions(["company.c_view_units"]);
    const canManage = useHasPermissions(['common.c_manage_all_employees', 'common.c_manage_user_employees']);

    const uploadPicture = async (image) => {
        const formData = new FormData();
        formData.append("profile_picture", image);

        const result = await requests.post(patchEndpoint + data.id + "/picture/", formData);

        return result;
    }

    const deletePicture = async () => {
        const result = await requests.del(patchEndpoint + data.id + "/picture/")

        return result;
    }
    
    const onLabelsAdd = (id, labels) => {
        setData(prev=> ({
            ...prev,
            contact_labels: labels

        }))
}
    const onLabelRemove = async (id, labelID) => {

        const request = await requests.patch(patchEndpoint + id + "/?query={id, contact_labels}", {
            contact_labels: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setData(prevState => ({
                ...prevState,
                contact_labels: request.response.contact_labels
            }))
        }

    }

    const onDelete = async (id) => {
        const request = await requests.del(API.EMPLOYEES + id + "/");

        if (request.status === 204) {
            history.push(routes.EMPLYOEES_LIST);
        }
    }

    const ShowLabels = ({ profile }) => {
        return (
            <>
                {profile?.contact_labels?.map(label => (
                    <Label style={{ marginTop: '3px', color: "white", backgroundColor: label.color === "" ? "" : label.color}} key={label.id}>
                        <span style={{ position: "relative", top: "-0.2rem" }}>{label.name}</span>
                        <CanView permissions={['common.c_manage_all_employees', 'common.c_manage_all_candidates']}>
                            <Icon name="close-outline" style={{ marginLeft: "0.5rem", color: "var(--danger)", cursor: "pointer" }} onClick={() => onLabelRemove(profile.id, label.id)} />
                        </CanView>
                    </Label>
                ))}
                <CanView permissions={['common.c_manage_all_employees', 'common.c_manage_all_candidates']}>
                    <SuperDuperModal
                        trigger={
                            <Label basic size="tiny" style={{ marginTop: '4px', cursor: "pointer" }}>
                                <Icon name="add-outline"/>
                            </Label>
                        }
                        content={
                            <LabelsForm
                                id={profile?.id}
                                data={profile?.contact_labels}
                                onConfirm={onLabelsAdd}
                                patchEndpoint={patchEndpoint}
                                labelEndpoint={API.CONTACT_LABELS}
                                fieldName={"contact_labels"}
                            />
                        }
                    />
                </CanView>
            </>
        );
    }

    return (
        <>
            <Grid.Row columns={2}>
                <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Header as="h3">
                        <div>
                            { data.is_active ?
                                <> <Icon style={{marginRight: '0.8rem', color: "var(--success)" }} name="ellipse" /> <span style={{ position: "relative", top: "-0.2rem" }}>{t('active')}</span></> :
                                <> <Icon style={{marginRight: '0.8rem', color: "var(--danger)"}} name="ellipse"/> <span style={{ position: "relative", top: "-0.2rem" }}>{t('inactive')}</span></>
                            }
                            <span style={{ paddingLeft: "1rem" }}>
                            { data?.gdpr_valid_until ?
                                <> <Icon style={{marginRight: '0.8rem', color: "var(--success)" }} name="ellipse" /><span style={{ position: "relative", top: "-0.2rem" }}>{t('gdpr_valid_until')}: <strong>{data?.gdpr_valid_until}</strong></span></> :
                                <> <Icon style={{marginRight: '0.8rem', color: "var(--danger)"}} name="ellipse" /><span style={{ position: "relative", top: "-0.2rem" }}>{t('gdpr_required')}</span></>
                            }
                            </span>
                        </div>
                    </Header>
                </Grid.Column>
                <Grid.Column textAlign='right' mobile={16} tablet={8} computer={4}>
                      {/* <Action
                            as="modal"
                            type="icon"
                            iconColor="black"
                            icon="history"
                            tooltip={t('history')}
                            modal={
                                <ProfileHistory
                                    data={data}
                                />
                            }
                        /> */}
                        <Action
                            as="modal"
                            type="icon"
                            iconColor="black"
                            icon="pencil-outline"
                            tooltip={t('edit_header')}
                            modal={
                            <ProfileCardHeaderForm
                                data={data}
                                onConfirm={setData}
                                patchEndpoint={patchEndpoint}
                            />
                            }
                        />
                        <Action
                            as="modal"
                            type="icon"
                            iconColor="black"
                            icon="barcode-outline"
                            tooltip={'Start On/Off-Boarding'}
                            modal={
                                <StartOnOff id={data.id}
                                    btnName='Start'
                                />
                            }
                        />
                        <CanView permissions={["common.c_delete_all_employees"]}>
                            <Action as='delete' tooltip={t('delete')} text={t('delete_employee_message')} onClick={() => onDelete(data.id)} />
                        </CanView>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
                <Grid.Column mobile={16} tablet={8} computer={3}>
                    <AvatarIcon
                        size={200}
                        name={data.fullname ? data.fullname : "undefined"}
                        src={data?.profile_picture}
                        handleUpload={uploadPicture}
                        handleDelete={deletePicture}
                        uploadable={canManage}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={10}>
                    <Header as="h1" style={{ marginBottom: '3px' }}> {data?.personal_information?.person?.titles_before} {data.fullname} {data?.personal_information?.person?.titles_after} </Header>
                    <strong>{data?.positions?.[0]?.title}</strong>
                    <br />
                    <Grid.Row style={{ paddingTop: "5px" }}>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <Icon style={{ marginRight: "0.5rem" }} name="mail-outline" />
                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                <span style={{ paddingRight: "5px" }}>
                                    {data?.personal_information?.contact_info?.emails?.[0]?.email ?
                                        <>
                                        <a href={"mailto:" + data?.personal_information?.contact_info?.emails?.[0]?.email}>
                                            {data?.personal_information?.contact_info?.emails?.[0]?.email }
                                        </a>
                                        {data?.personal_information?.contact_info?.emails?.[1]?.email  && ","}
                                        </>
                                        : "~"
                                    }
                                </span>
                                <span style={{ paddingRight: "5px" }}>
                                    {data?.personal_information?.contact_info?.emails?.[1]?.email &&
                                        <>
                                        <a href={"mailto:" + data?.personal_information?.contact_info?.emails?.[1]?.email}>
                                            {data?.personal_information?.contact_info?.emails?.[1]?.email }
                                        </a>
                                        {data?.personal_information?.contact_info?.emails?.[2]?.email  && ","}
                                        </>
                                    }
                                </span>
                                <span style={{ paddingRight: "5px" }}>
                                    {data?.personal_information?.contact_info?.emails?.[2]?.email &&
                                        <a href={"mailto:" + data?.personal_information?.contact_info?.emails?.[2]?.email}>
                                            {data?.personal_information?.contact_info?.emails?.[2]?.email }
                                        </a>
                                    }
                                </span>
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: "5px" }}>
                            <Icon style={{ marginRight: "0.5rem" }} name="call-outline" />
                            <span style={{ position: "relative", top: "-0.2rem" }}>
                                <span style={{ paddingRight: "5px" }}>
                                    {data?.personal_information?.contact_info?.phones?.[0]?.phone ?
                                        <>
                                        <a href={"tex:" + data?.personal_information?.contact_info?.phones?.[0]?.phone}>
                                            {data?.personal_information?.contact_info?.phones?.[0]?.phone }
                                        </a>
                                        {data?.personal_information?.contact_info?.phones?.[1]?.phone  && ","}
                                        </>
                                        : "~"
                                    }
                                </span>
                                <span style={{ paddingRight: "5px" }}>
                                    {data?.personal_information?.contact_info?.phones?.[1]?.phone &&
                                        <>
                                        <a href={"tex:" + data?.personal_information?.contact_info?.phones?.[1]?.phone}>
                                            {data?.personal_information?.contact_info?.phones?.[1]?.phone }
                                        </a>
                                        {data?.personal_information?.contact_info?.phones?.[2]?.phone  && ","}
                                        </>
                                    }
                                </span>
                                <span style={{ paddingRight: "5px" }}>
                                    {data?.personal_information?.contact_info?.phones?.[2]?.phone &&
                                        <a href={"tex:" + data?.personal_information?.contact_info?.phones?.[2]?.phone}>
                                            {data?.personal_information?.contact_info?.phones?.[2]?.phone }
                                        </a>
                                    }
                                </span>
                            </span>
                    </Grid.Row>
                    <Grid.Row  style={{ paddingTop: "5px" }}>
                            <Icon style={{ marginRight: "0.5rem" }}  name="location-outline" />
                            { data?.personal_information?.permanent_address?.street || data?.personal_information?.permanent_address?.postcode || data?.personal_information?.permanent_address?.city
                             || data?.personal_information?.permanent_address?.country_display ?
                            data?.personal_information?.permanent_address?.street + ", " + data?.personal_information?.permanent_address?.postcode + " " + data?.personal_information?.permanent_address?.city + ", " +
                            data?.personal_information?.permanent_address?.country_display : "" }
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: "1.5rem" }}>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                            <ShowLabels profile={data} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                    {canViewUnit ?
                    <>
                    <Link to={routes.UNIT_DETAIL + data?.unit?.id} target="_blank">
                        <strong style={{ fontSize: "2rem", marginBottom: '3px' }}> {data?.unit?.name} </strong><br />
                    </Link>
                    <Link to={routes.UNIT_DETAIL + data?.unit?.id} target="_blank">
                        <strong>{data?.unit?.name}</strong>   <br />
                    </Link>
                    </> :
                    <>
                        <Header as="h1" style={{ marginBottom: '3px' }}> {data?.unit?.name} </Header>
                        <strong>{data?.unit?.name}</strong>   <br />
                    </>}
                    <strong>{data?.personal_information?.custom_id_prefix}-{data?.personal_information?.custom_id}</strong>
                    <br />
                    { data?.start_date ?
                        <>{t('start_day')}:<br />
                        <strong>{data?.start_date ? data?.start_date : '~'}</strong> </> :
                        <>{t('expected_start_date')}:<br />
                        <strong>{data?.expected_start_date ? data?.expected_start_date : '~'}</strong> </>
                    } <br />

                    {t('end_date')}:<br />
                    <strong>{data?.end_date ? data?.end_date : '~'}</strong>  <br />
                    {t('id_card_number')}:<br />
                    <strong>{data?.personal_information?.id_card_number ? data?.personal_information?.id_card_number : '~'}</strong>
                </Grid.Column>

            </Grid.Row>
        </>
    );
};



export default ProfileHeader;
