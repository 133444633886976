import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// store
import { routes } from "@routes/index";
import { allowedModules as modules } from '@store/permissions/index';
// components
import { Grid, Card, Header, Form, Divider, Container } from 'semantic-ui-react';
import CanView from '@components/perms/CanView';
import SuperField from '@components/forms/SuperField';

const TypesList = () => {
    const { t } = useTranslation();
    const allowedModules = useSelector(state => state.allowed_modules)

    const types = [
        { title: t("hiring_sources"), url: "hiring-sources", category: "hiring", category_name: t('hiring_rooms'), module: modules.HIRING_ROOMS },
        { title: t("cases_types"), url: "cases-types", category: "cases", category_name: t('cases'), module: modules.CASES },
        { title: t("person_types"), url: "person-types", category: "employees", category_name: t('employees'), module: modules.EMPLOYEES },
        { title: t("phone_types"), url: "phone-types", category: "employees", category_name: t('employees'), module: modules.EMPLOYEES },
        { title: t("unit_types"), url: "unit-types", category: "company", category_name: t('company'), module: modules.COMPANY },
        { title: t("isco"), url: "isco", category: "positions", category_name: t('positions'), module: modules.POSITIONS },
        { title: t("contract_types"), url: "contract-types", category: "contract", category_name: t('contract'), module: modules.CONTRACTS },
        { title: t("record_categories"), url: "cost-centers-record-categories", category: "centers", category_name: t('cost_centers'), module: modules.COST_CENTERS },
        { title: t("course_categories"), url: "course-categories", category: "courses", category_name: t('courses'), module: modules.COURSES },
        { title: t("template_categories"), url: "template-categories", category: "boarding", category_name: t('onboarding'), module: modules.ONBOARDING },
        { title: t("timesheets_activities"), url: "timesheets-activities", category: "timesheets", category_name: t('timesheets'), module: modules.TIMESHEETS },
        { title: t("benefits_categories"), url: "benefits-categories", category: "benefits", category_name: t('benefits'), module: modules.BENEFITS },
        { title: t("interruption_reasons"), url: "interruption-reasons", category: "attendance", category_name: t('attendance'), module: modules.ATTENDANCE },
        { title: t("turnuses"), url: "turnuses", category: "attendance", category_name: t('attendance'), module: modules.ATTENDANCE },
        { title: t("driver_licence"), url: "driver-licence", category: "employees", category_name: t('employees'), module: modules.EMPLOYEES },
    ]

    const [options, setOptions] = useState([])
    const [selectedCategory, setSelectedCategory] = useState("all")
    const [data, setData] = useState([])
    const [search, setSearch] = useState("")

    useEffect(() => {
        async function fetchOptions() {
            const listOfOptions = []
            const categs = []
            for (let i = 0; i < types.length; i++) {
                const canRender = types[i].module.every( module => allowedModules.indexOf(module) >= 0)
                if (!categs.includes(types[i].category) && canRender) {
                    listOfOptions.push({ key: i + 1, value: types[i].category, text: types[i].category_name });
                    categs.push(types[i].category)
                }
            }
            setOptions([{key: 0, value: "all", text: t('all')}, ...listOfOptions])
        }

        fetchOptions()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetchData() {
            const newData = []
            for (let i = 0; i < types.length; i++) {
                if (selectedCategory === types[i].category) {
                    //newData.push(types[i]);
                    if (types[i].category_name.toLowerCase().includes(search.toLowerCase()) || types[i].title.toLowerCase().includes(search.toLowerCase())) {
                        newData.push(types[i]);
                    }
                }
                else if (selectedCategory === "all") {
                    if (types[i].category_name.toLowerCase().includes(search.toLowerCase()) || types[i].title.toLowerCase().includes(search.toLowerCase())) {
                        newData.push(types[i]);
                    }
                }
            }

            setData(newData)
        }

        fetchData()
        // eslint-disable-next-line
    }, [selectedCategory, search])

    return (
        <Container fluid style={{ marginTop: "1rem" }}>
            <Form>
                <Form.Group style={{ marginLeft: "0.5rem" }}>
                    <SuperField as="choice"
                        label={t('category')}
                        search
                        width={3}
                        value={selectedCategory}
                        onChange={(e, { value }) => {
                            if (value === "") value = "all"
                            setSelectedCategory(value)
                        }}
                        customOptions={options}
                    />
                    <SuperField as="input"
                        icon="search"
                        width={4}
                        label={t('search')}
                        placeholder={t('search')}
                        value={search}
                        onChange={(e, { value }) => setSearch(value)}
                    />
                </Form.Group>
            </Form>
            <Divider />
            <Grid stretched style={{ marginLeft: "0.2rem" }}>
                {data.map((item, index) =>
                    <CanView permissions={[]} modules={item.module}>
                        <Grid.Column mobile={16} tablet={8} computer={5} key={index}>
                            <Card
                                fluid
                                as={Link}
                                to={routes.SETTINGS_TYPES + '/' + item.url}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 0,
                                    height: "100%",
                                    cursor: "pointer"
                                }}
                            >
                                <Card.Content style={{ textAlign: "center", flexGrow: 0 }}>
                                    <Header as="h3" style={{ marginBottom: "0.2rem" }}>
                                        {item.title}
                                    </Header>
                                    <strong>{item.category_name}</strong><br /><br />
                                    <span style={{ color: "var(--variant2)" }}> {t('select_to_manage')} </span>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </CanView>
                )
                }
            </Grid>
        </Container>
    );
};

export default TypesList;