import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next"
//store
import { icons } from '@icons';
import { routes } from '@routes';
import { API } from '@store/config';
import { useHasPermissions } from '@helpers/hooks';
import { getRequest } from '@services/ServiceCommon';
//components
import BreadcrumbNav from '@components/BreadcrumbNav';
import Loading from '@components/general/Loading';
import { Container, Tab } from 'semantic-ui-react';
import SupplierHeader from '../components/SupplierHeader';
import DetailView from '../components/DetailView';
import DetailViewFreelance from '../components/DetailViewFreelance';


const SupplierDetail = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const canViewHomeTab = useHasPermissions(["accounts.c_view_assigned_accounts", "accounts.c_view_user_accounts", "accounts.c_view_all_accounts"]);

    // eslint-disable-next-line
    const [id] = useState(params.id);
    const [data, setData] = useState([])
    const [profile, setProfile] = useState(undefined)
    const [account, setAccount] = useState(undefined)

    useEffect(() => {
        async function fetchData() {
            const result = await getRequest(API.SUPPLIERS + '?identification_number=' + id)
            if (result.status === 200) {
                setData(result.response[0])
                setAccount(result.response[0].account)
                setProfile(result.response[0].profile)
                setIsLoading(false);
            }
        }
        fetchData()
    }, [id])

    const panes = [
        {
            menuItem: canViewHomeTab && t('home'),
            render: () =>
                <Tab.Pane>
                    { account && <DetailView account={account} setAccount={setAccount} /> }
                    { profile && <DetailViewFreelance profile={profile} setProfile={setProfile} /> }
                </Tab.Pane>
        },
    ];

    const breadcrumb_items = [
        // Put: name, icon and href data inside to create breadcrumb item
        { 'name': t("suppliers"), 'icon': icons.ACCOUNTS, 'href': routes.SUPPLIERS },
        { 'name': data?.profile?.fullname ? data.profile.fullname : data?.account?.name ? data.account.name : '--', 'icon': 'user outline', 'href': '' }
    ]

    return (
        <Fragment>
            <BreadcrumbNav items={breadcrumb_items} />
            {isLoading ? <Loading /> :
                <Container fluid>
                    <SupplierHeader data={data} isProfile={profile?.id}/>
                    <Tab panes={panes} />
                </Container>
            }
        </Fragment>
    );
};


export default SupplierDetail;