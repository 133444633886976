import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
import { createRequest, updateRequest } from '@services/ServiceCommon';
// components 
import { Form, Divider, Header } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const EmploymentFulfillmentForm = ({ order, fulfillment, totalFee, setFulfillments, onClose}) => {
    console.log(totalFee)
    const { t } = useTranslation();
    const [form, setForm] = useState({
        date_from: fulfillment !== undefined ? fulfillment.date_from : moment().startOf('month').format("YYYY-MM-DD"),
        date_to: fulfillment !== undefined ? fulfillment.date_to : moment().endOf('month').format("YYYY-MM-DD"),
        total_hours: fulfillment !== undefined ? fulfillment.total_hours : null,
        total_fee: fulfillment !== undefined ? fulfillment.total_fee : totalFee || null,
        order: order.id
    })
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async () => {
        setIsProcessing(true)
        
        if (fulfillment === undefined) {
            const request = await createRequest(API.ORDERS + "employment_fullfilments/", form)
            if( request.status === 201 ){
                setFulfillments(prevState => [request.response, ...prevState])
                onClose()
            }
        } else {
            const request = await updateRequest(API.ORDERS + "employment_fullfilments/" + fulfillment.id + "/", form)
            if( request.status === 200 ){
                setFulfillments(prevState => prevState.filter(item => {
                    if (item.id === fulfillment.id) {
                        item.date_from = request.response.date_from
                        item.date_to = request.response.date_to
                        item.total_hours = request.response.total_hours
                        item.total_fee = request.response.total_fee
                    }

                    return item
                }))
                onClose()
            }
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group widths='equal'>
                <SuperField as="datepicker" required
                    label={t('date_from')}
                    value={form.date_from}
                    onChange={(e, { value }) => setForm({ ...form, date_from: value })}
                    />
                <SuperField as="datepicker" required
                    label={t('date_from')}
                    value={form.date_to}
                    onChange={(e, { value }) => setForm({ ...form, date_to: value })}
                    />
            </Form.Group>

            <Header as="h3" content={t('additional_information')}/>
            <Divider/>

            <Form.Group widths='equal'>
                <SuperField as="input"
                    label={t('hours')}
                    value={form.total_hours}
                    onChange={(e, { value }) => setForm({ ...form, total_hours: value })}
                    />
                <SuperField as="input"
                    label={t('fee')}
                    value={form.total_fee}
                    onChange={(e, { value }) => setForm({ ...form, total_fee: value })}
                />
            </Form.Group>

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={isProcessing || isEmpty(form.date_from) || isEmpty(form.date_to)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default EmploymentFulfillmentForm;