import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField'
import SuperFieldSet from '@components/forms/SuperFieldSet'
import { Form } from 'semantic-ui-react';

const PersonData = ({ setData }) => {
    const { t } = useTranslation();

    const [person, setPerson] = useState({
        firstname: "",
        lastname: "",
        gender: "",
        middlename: "",
        titles_before: "",
        titles_after: ""
    })

    useEffect( () => {
        setData(prevState => ({
            ...prevState,
            person: person
        }))

    }, [person, setData])

    return (
        <SuperFieldSet legend={ t('personal_information') }>
            <Form.Group widths="equal">
                <SuperField as="input"
                    autoFocus
                    required
                    label={ t('firstname') }
                    placeholder={ t('enter_firstname') }
                    value={ person.firstname }
                    onChange={ (e) => setPerson({
                        ...person,
                        firstname: e.target.value
                    })}
                />
                <SuperField as="input"
                    required
                    label={ t('lastname') }
                    placeholder={ t('enter_lastname') }
                    value={ person.lastname }
                    onChange={ (e) => setPerson({
                        ...person,
                        lastname: e.target.value
                    })}
                />
            </Form.Group>
            <Form.Group>

                <SuperField as="input"
                    label={ t('middlename') }
                    placeholder={ t('enter_middlename') }
                    width="8"
                    value={ person.middlename }
                    onChange={ (e) => setPerson({
                        ...person,
                        middlename: e.target.value
                    })}
                />
                <SuperField as="input"
                    label={ t('title_before') }
                    width="2"
                    value={ person.titles_before }
                    onChange={ (e) => setPerson({
                        ...person,
                        title_before: e.target.value
                    })}
                />
                <SuperField as="input"
                    label={ t('title_after') }
                    width="2"
                    value={ person.titles_after }
                    onChange={ (e) => setPerson({
                        ...person,
                        title_after: e.target.value
                    })}
                />
                <SuperField as="choice"
                    required
                    width="4"
                    type="genders"
                    label={ t('gender') }
                    value={ person.gender.toString() }
                    onChange={ (e, { value }) => setPerson({
                        ...person,
                        gender: parseInt(value)
                    })}
                />
            </Form.Group>
        </SuperFieldSet>
    );
};

export default PersonData;