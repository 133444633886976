import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-semantic-toasts';
// store
import { API } from '@store/config';
import { createRequest } from '@services/ServiceCommon';
import { isEmpty } from '@helpers/validation';
// components
import SuperField from '@components/forms/SuperField';
import { Divider, Header, Form, Message } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const StartOnboarding = ({ id, onClose, setCandidates, acceptAsEmployee, converted }) => {
    const { t } = useTranslation();
    const [template, setTemplate] = useState({})
    const [deadline, setaDeadline] = useState('')
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null)

    const handleSubmit = async () => {
        setIsProcessing(true)
        if (converted === 'no') {
            acceptAsEmployee(id)
        }

        const requestPatch = await createRequest(API.ONBOARDING_CHECKLIST_CONFIG + template + "/instantiate/", { employee: id, deadline: deadline })

        if (requestPatch.status === 201) {
            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('started_onboarding'),
                animation: 'bounce',
                time: 5000,
            });

            setCandidates(prevState => ({
                ...prevState,
                results: prevState.results.filter(candidate => {
                    if (candidate.id === id) {
                        candidate.onboardings = requestPatch.response
                    }
                    return candidate
                })
            })
            )
        }
        else if (requestPatch.status === 400) {
            setErrors(requestPatch.response)
        }

        setIsProcessing(false)
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Message
                error
                visible={errors?.non_field_errors?.length > 0}
                list={errors?.non_field_errors || []}
            />

            <>
                <Header as="h3" content={t('start_onboarding')} />
                <Divider />
                <Form.Group widths={2}>
                    <SuperField as="choice"
                        search
                        required
                        label={t('template')}
                        value={template}
                        endpoint={API.ONBOARDING_CHECKLIST_CONFIG + '?type=1'}
                        onChange={(e, { value }) => setTemplate(value)}
                        text="title"
                    />

                    <SuperField as="datepicker"
                        dateFormat="YYYY-MM-DD"
                        required
                        label={t('deadline')}
                        placeholder={t('deadline')}
                        value={deadline}
                        iconPosition="left"
                        onChange={(e, data) => setaDeadline(data.value)}
                    />
                </Form.Group>

            </>

            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    text={t('confirm')}
                    loading={isProcessing}
                    disabled={isProcessing || isEmpty(template) || isEmpty(deadline)}
                />
            </Form.Field>

        </Form>
    );
};

export default StartOnboarding;