import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//store
import { routes } from "@routes/index";
import { icons } from "@icons";
import { candidateService } from "@services/ServiceCandidate";
import { hiringService } from "@services/ServiceHiring";
//components
import BreadcrumbNav from "@components/BreadcrumbNav";
import Pipeline from "../components/detail/Pipeline";
import Loading from "@components/general/Loading";


const CandidateDetail = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [idRoom] = useState(params.room);
    const [idCandidate] = useState(params.candidate);
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [room, setRoom] = useState({})
    const history = useHistory()

    // candidate state
    const [blacklisted, setBlacklisted] = useState(false)
    const [rejected, setRejected] = useState(false)

    const fetchBlacklisted = async () => {
        const blacklistedResult = await hiringService.getBlacklisted("?profile=" + params.candidate)

        if (blacklistedResult.status === 200) {
            if (blacklistedResult.response.length) {
                setBlacklisted(true)
            }
        }
    }

    const fetchRejected = async () => {
        const rejectedResult = await hiringService.getRejected("?profile=" + params.candidate + "&recruitment=" + params.room)

        if (rejectedResult.status === 200) {
            if (rejectedResult.response.length) {
                setRejected(true)
            }
        }
    }

    const fetchCandidate = async () => {
        const result = await candidateService.getCandidates(params.candidate, "?recruitment=" + params.room);

        if (result.status === 403) {
            history.push(routes.DASHBOARD)
            return
        }

        if (result.status === 200) {
            setData(result.response);
        }
    }

    const fetchHiringRoom = async () => {
        const result = await hiringService.getHiringRoom(params.room);

        if (result.status === 200) {
            setRoom(result.response);
        }
    }

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true)
            await fetchHiringRoom()
            await fetchCandidate()
            await fetchRejected()
            await fetchBlacklisted()
            setIsLoading(false);
        };

        fetchData();

        // eslint-disable-next-line
    }, [params.room, params.candidate]);


    const breadcrumb_items = [
        // Put: name, icon and href data inside to create breadcrumb item
        {
            name: t('hiring_room'),
            icon: icons.HIRING_ROOM,
            href: routes.HIRING,
        },
        { name: room.name, icon: icons.CANDIDATES, href: routes.HIRING_ROOMS + idRoom },
        { name: data.fullname, icon: 'user' },

    ];

    return (
        <Fragment>
            <div style={{ backgroundColor: "white" }}>
                <BreadcrumbNav items={breadcrumb_items} />
                {isLoading ? <Loading /> :
                    <Pipeline
                        rejected={rejected}
                        setRejected={setRejected}
                        blacklisted={blacklisted}
                        setBlacklisted={setBlacklisted}
                        room={room}
                        data={data}
                        idRoom={idRoom}
                        setCandidate={setData}
                        id={idCandidate}
                    />
                }
            </div>
        </Fragment>
    );
};


export default CandidateDetail;
