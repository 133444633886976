import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
// store
import { isEmpty } from "@helpers/validation"
import { accountsService } from '@services/ServiceAccounts';
// components
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, TextArea, Message} from 'semantic-ui-react';
import AccountTypeField from '@components/forms/common/AccountTypeField';
import IndustryField from '@components/forms/common/IndustryField';
import CompanySizeField from '@components/forms/common/CompanySizeField';
import StatusField from '@components/forms/common/StatusField';


export const EditHeaderAccount = ({ data, onClose, onConfirm }) => {
    const [id] = useState(data ? data.id : 0); // if 0 then its Create Form, else Update

    const [header, setHeader] = useState({
        description: (data && data.description && data.description) || '',
        name: (data && data.name && data.name) || '',
        accountType: (data && data.account_type && data.account_type) || '',
        accountTypeDisplay: (data && data.account_type_display && data.account_type_display) || '',
        website: ( data && data.website && data.website) || '',
        companySize: data && data.company_size && data.company_size,
        companySizeDisplay: (data && data.company_size_display && data.company_size_display) || '',
        industry: (data && data.industry && data.industry) || '',
        industryDisplay: (data && data.industry_display && data.industry_display) || '',
        status: (data && data.status && data.status) || 1,
        statusDisplay: (data && data.status_display && data.status_display) || '',
        rank: (data && data.rank && data.rank )|| '',
    });

    const [isProcessing, setIsProcessing] = useState(false); // true, false
    const [errorList, setErrorList] = useState([]); // empty array
    const {t}=useTranslation()

    const handleSubmit = async () => {
        setIsProcessing(true);

        const dataHeader = {
            name: header.name,
            website: header.website,
            description: header.description,
            account_type: header.accountType,
            industry: header.industry,
            company_size: header.companySize,
            status: header.status,
            rank: header.rank!==''? header.rank: null
        }

        if(id!==0){
            const result = await accountsService.updateAccount(id, dataHeader);

            if (result.status === 200) {
                onConfirm(result.response)
                onClose()
            } else if(result.status===400){
                if(result.response.website){
                const error_message = result.response.website;
                setErrorList([...errorList, error_message]);}
                else{ const error_message = result.response.rank;
                    setErrorList([...errorList, error_message]);}
            } else {
                const error_message = result.response.detail
                setErrorList([...errorList, error_message]);
            }
        }else{

            const result = await accountsService.createAccounts(dataHeader);

            if (result.status === 201) {
                onConfirm(result.response)
                onClose()
            } else if(result.status===400){
                if(result.response.website){
                const error_message = result.response.website;
                setErrorList([...errorList, error_message]);}
                else{ const error_message = result.response.rank;
                    setErrorList([...errorList, error_message]);}
            } else {
                const error_message = result.response.detail
                setErrorList([...errorList, error_message]);
            }


        }
        setIsProcessing(false);
    }


    return (
        <Form onSubmit={handleSubmit}>
            { errorList.length > 0 &&
                <Message negative>

                    {errorList.map((error, index) =>
                        <p key={index}> {error} </p>
                    )}
                </Message>
            }

            <Form.Group widths={2}>
                <Form.Field>
                    <Form.Input
                        autoFocus
                        required
                        label={t('name')}
                        placeholder={t('name')}
                        value={header.name}
                        onChange={(e) => setHeader({
                            ...header,
                            name: e.target.value
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        label={t('website')}
                        placeholder={t('website_placeholder')}
                        value={header.website}
                        onChange={(e) => setHeader({
                            ...header,
                            website: e.target.value
                        })}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
                <AccountTypeField
                    required
                    placeholder= {t('account_type')}
                    value={header.accountType}
                    setValue={(e, data) => setHeader({
                        ...header,
                        accountType: data.value

                    })}
                />
                <IndustryField
                    required
                    placeholder={t('industry_type')}
                    value={header.industry}
                    setValue={(e, data) => setHeader({
                        ...header,
                        industry: data.value
                    })}
                />
            </Form.Group>
            <Form.Group widths={ id===0 ? 2 : 3}>
                <CompanySizeField
                    placeholder={t('company_size')}
                    value={header.companySize}
                    setValue={(e, data) => setHeader({
                        ...header,
                        companySize: data.value
                    })}
                />

                { id === 0 ? '' :
                    <StatusField
                        required
                        value={header.status}
                        placeholder={t('status_placeholder')}
                        setValue={(e, data) => setHeader({
                            ...header,
                            status: data.value
                        })}
                    />
                }
                 <Form.Input
                    label={t('rank')}
                    placeholder={t('ranking_placeholder')}
                    value={header.rank}
                    onChange={(e) => setHeader({
                        ...header,
                        rank: e.target.value
                    })}
                />
                </Form.Group>
            <Form.Field>
                <Form.TextArea
                    label={t('description')}
                    placeholder={t('description_placeholder')}
                    control={TextArea}
                    value={header.description}
                    onChange={(e) => setHeader({
                        ...header,
                        description: e.target.value
                    })}
                />
            </Form.Field>

            <Form.Field style={{ textAlign: "right"}}>
                <ModalCancel onClose={onClose} disabled={isProcessing} />
                <ModalSubmit
                    disabled={isProcessing ||
                        isEmpty(header.name) ||
                        isEmpty(header.accountType) ||
                        isEmpty(header.industry)}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    )
}

