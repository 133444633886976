import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
// store
import { routes } from '@routes';
// components
import { Grid } from 'semantic-ui-react';
import ProjectCard from './card-view/ProjectCard';

const ProjectCardView = ({ projects, setProjects }) => {
    const { t } = useTranslation();

    return (
        <Grid stretched style={{ margin: "1rem" }} centered>
            {projects?.data?.results?.length > 0
                ?
                projects?.data?.results?.map((item, index) =>
                    <Grid.Column mobile={16} tablet={8} computer={5} as={Link} to={routes.PROJECT_DETAIL + item.id} key={index}>
                        <ProjectCard
                            id={item.id}
                            name={item.name}
                            description={item.description}
                            startDate={item.from_date}
                            endDate={item.due_date}
                            teams={item.teams}
                            is_active={item.is_active}
                            project_manager={item.project_manager}
                        />
                    </Grid.Column>
                )
                :
                <div>
                    <span>{t("nothing")}</span>
                </div>
            }
        </Grid>
    )
}

export default ProjectCardView