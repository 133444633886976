import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// components
import SuperField from '@components/forms/SuperField'
import SuperFieldSet from '@components/forms/SuperFieldSet'
import { Form } from 'semantic-ui-react';

const BirthInfo = ({ setData, errors }) => {
    const { t } = useTranslation();

    const [birthInfo, setBirthInfo] = useState({
        date_of_birth: "",
        country_of_birth: "",
    })

    useEffect( () => {
        let birth_info = null;
        if( birthInfo.date_of_birth !== null ){
            birth_info = birthInfo
        }

        setData(prevState => ({
            ...prevState,
            birth_info: birth_info
        }))

    }, [birthInfo, setData])

    return (
        <SuperFieldSet legend={t('birth_information')}>
            <Form.Group widths="equal">
                <SuperField as="datepicker"
                    required
                    label={ t('date_of_birth') }
                    startMode="year"
                    value={ birthInfo.date_of_birth }
                    error={ errors?.data?.personal_information?.birth_info?.date_of_birth }
                    onChange={ (e, { value }) => setBirthInfo({
                        ...birthInfo,
                        date_of_birth: value
                    }) }
                />
                <SuperField as="choice"
                    search
                    label={t('country_of_birth')}
                    type="countries"
                    value={ birthInfo.country_of_birth }
                    onChange={ (e, { value }) => setBirthInfo({
                        ...birthInfo,
                        country_of_birth: value
                    }) }
                />
            </Form.Group>
        </SuperFieldSet>
    );
};

export default BirthInfo;