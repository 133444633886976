import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
//store
import { routes } from '@store/routes';
import { benefitsService } from '@store/services/ServiceBenefits';
//components
import { List, Grid, Loader } from 'semantic-ui-react';
import AvatarIcon from '@components/AvatarIcon';


const BenefitAssignee = ({ row }) => {
  const { t } = useTranslation()

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)

      const result = await benefitsService.getBenefits(row.id)
      setData(result.response.employees);

      setIsLoading(false)
    }

    fetchData();

  }, [row.id])

  return (
   <Fragment>
      {isLoading ? <Loader active inline='centered' /> :
        <>
          {data?.length !== 0 ?
            data.map((item, index) =>
              <Grid textAlign='left' columns={2} as={Link} to={routes.EMPLYOEES_DETAIL + item.id} >
                <Grid.Row>
                  <Grid.Column computer={2} tablet={2} mobile={2}>
                    <AvatarIcon size="30" name={item.fullname} src={item.profile_picture} />

                  </Grid.Column>
                  <Grid.Column computer={6} tablet={6} mobile={6}>
                    <List relaxed>
                      <List.Item>
                        <List.Content>
                          <List.Header as='a'>{item.fullname}</List.Header>
                          <List.Description>
                            {item.position?.name}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>

                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )
            :
            <Grid>{t('no_data')}</Grid>
          }</>
        }
  </Fragment>
  )
};

export default BenefitAssignee