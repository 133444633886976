import React from 'react';
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom";
// store
import { API } from '@store/config';
// components
import Icon from '@components/Icon';
import TypesManagement from './TypesManagement';
import { Container } from 'semantic-ui-react';

const TypesDetail = () => {
    const { t } = useTranslation();
    const params = useParams();

    return (
        <Container fluid style={{ marginTop: "1rem"}}>
            {params.slug === "hiring-sources" &&
                <TypesManagement
                    title={t("hiring_sources")}
                    endpoint={API.SOURCES}
                    permissions={[{
                        view: ['common.c_view_hiring_source'],
                        manage: ['common.c_manage_hiring_source'],
                        delete: ['common.c_delete_hiring_source'],
                    }]}
                    definitions={[{
                        name: t("sources"),
                        key: "name",
                        as: "input",
                        isRequired: true
                    }]}
                />
            }
            {params.slug === "cases-types" &&
                <TypesManagement
                    title={t("cases_types")}
                    endpoint={API.CASES_TYPES}
                    permissions={[{
                        view: ['cases.c_view_all_cases', 'cases.c_view_assigned_user_cases'],
                        manage: ['cases.c_manage_all_cases'],
                        delete: ['cases.c_manage_all_cases'],
                    }]}
                    definitions={[{
                        name: t("cases_types"),
                        key: "title",
                        as: "input",
                        isRequired: true
                    }]}
                />
            }
            {params.slug === "person-types" &&
                <TypesManagement
                    title={t("person_types")}
                    endpoint={API.PERSON_TYPE}
                    permissions={[{
                        view: [],
                        manage: [],
                        delete: [],
                    }]}
                    definitions={[{
                        name: t("person_types"),
                        key: "name",
                        as: "input",
                        isRequired: true
                    }]}
                />
            }
            {params.slug === "phone-types" &&
                <TypesManagement
                    title={t("phone_types")}
                    endpoint={API.PHONE_TYPES}
                    permissions={[{
                        view: [],
                        manage: [],
                        delete: [],
                    }]}
                    definitions={[{
                        name: t("phone_types"),
                        key: "name",
                        as: "input",
                        isRequired: true
                    },
                    {
                        name: t("icon"),
                        key: "icon",
                        as: "choice",
                        icon: true,
                        isRequired: true,
                        customOptions: [
                            { key: 'phone', value: 'call-outline', text: <Icon name='call-outline' /> },
                            { key: 'mobile', value: 'mobile-outline', text: <Icon name='mobile-outline' /> },
                        ],
                    }
                    ]}
                />
            }
            {params.slug === "unit-types" &&
                <TypesManagement
                    title={t("phone_types")}
                    endpoint={API.UNIT_TYPES}
                    permissions={[{
                        view: ['company.c_view_active_company', 'company.c_view_inactive_companies', 'company.c_view_units'],
                        manage: ['company.c_add_company', 'company.c_change_active_company', 'company.c_change_inactive_companies', 'company.c_manage_units'],
                        delete: ['company.c_add_company', 'company.c_change_active_company', 'company.c_change_inactive_companies', 'company.c_manage_units'],
                    }]}
                    definitions={[{
                        name: t("unit_types"),
                        key: "name",
                        as: "input",
                        isRequired: true
                    }
                    ]}
                />
            }
            {params.slug === "isco" &&
                <TypesManagement
                    title={t("isco")}
                    endpoint={API.JOBS + "isco/"}
                    permissions={[{
                        view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'],
                        manage: ['contracts.c_manage_all_contracts'],
                        delete: ['contracts.c_manage_all_contracts'],
                    }]}
                    definitions={[
                        {
                            name: t("code"),
                            key: "code",
                            as: "input",
                            isRequired: true
                        },
                        {
                            name: t("name"),
                            key: "fullname",
                            as: "input",
                            isRequired: true
                        },
                        {
                            name: t("country"),
                            key: "country",
                            as: "choice",
                            type: "countries",
                            isRequired: true,
                            search: true
                        },
                        {
                            name: t("shortname"),
                            key: "shortname",
                            as: "input",
                            isRequired: false
                        }
                    ]}
                />
            }
            {params.slug === "contract-types" &&
                <TypesManagement
                    title={t("contract_types")}
                    endpoint={API.CONTRACTS_TYPES}
                    permissions={[{
                        view: ['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts'],
                        manage: ['contracts.c_manage_all_contracts'],
                        delete: ['contracts.c_manage_all_contracts'],
                    }]}
                    definitions={[{
                        name: t("contract_types"),
                        key: "title",
                        as: "input",
                        isRequired: true
                    }
                    ]}
                />
            }
            {params.slug === "cost-centers-record-categories" &&
                <TypesManagement
                    title={t("record_categories")}
                    endpoint={API.COST_CENTERS_RECORD_CATEGORIES}
                    permissions={[{
                        view: ['cost_centers.c_view_cost_centers'],
                        manage: ['cost_centers.c_manage_cost_centers'],
                        delete: ['cost_centers.c_manage_cost_centers'],
                    }]}
                    definitions={[
                        {
                            name: t("title"),
                            key: "title",
                            as: "input",
                            isRequired: true
                        },
                        {
                            name: t("code"),
                            key: "code",
                            as: "input",
                        },
                        {
                            name: t("type"),
                            key: "type",
                            as: "choice",
                            isRequired: true,
                            customOptions: [
                                { key: 1, value: 1, text: t('income') },
                                { key: 2, value: 2, text: t('expense') }
                            ],
                        }
                    ]}
                />
            }
            {params.slug === "course-categories" &&
                <TypesManagement
                    title={t("course_categories")}
                    endpoint={API.COURSES_CATEGORIES}
                    permissions={[{
                        view: ['courses.c_view_all_courses', 'courses.c_view_user_courses'],
                        manage: ['courses.c_manage_all_courses'],
                        delete: ['courses.c_manage_all_courses'],
                    }]}
                    definitions={[{
                        name: t("course_categories"),
                        key: "title",
                        as: "input",
                        isRequired: true
                    }
                    ]}
                />
            }
            {params.slug === "template-categories" &&
                <TypesManagement
                    title={t("template_categories")}
                    endpoint={API.TEMPLATING_CATEGORY}
                    permissions={[{
                        view: ['templating.c_view_documents'],
                        manage: ['templating.c_manage_documents'],
                        delete: ['templating.c_manage_documents'],
                    }]}
                    definitions={[{
                        name: t("template_categories"),
                        key: "title",
                        as: "input",
                        isRequired: true
                    }
                    ]}
                />
            }
            {params.slug === "timesheets-activities" &&
                <TypesManagement
                    title={t("timesheets_activities")}
                    endpoint={API.TIMESHEETS_CATEGORIES}
                    permissions={[{
                        view: ['timesheets.c_view_all_records'],
                        manage: [],
                        delete: [],
                    }]}
                    definitions={[{
                        name: t("title"),
                        key: "title",
                        as: "input",
                        isRequired: true
                    }
                    ]}
                />
            }
            {params.slug === "benefits-categories" &&
                <TypesManagement
                    title={t("benefits_categories")}
                    endpoint={API.BENEFITS_CATEGORIES}
                    permissions={[{
                        view: ['benefits.c_view_all_benefits', 'benefits.c_view_user_benefits'],
                        manage: [],
                        delete: [],
                    }]}
                    definitions={[{
                        name: t("benefits_categories"),
                        key: "name",
                        as: "input",
                        isRequired: true
                    },
                    {
                        name: t("color"),
                        key: "color",
                        as: "colorpicker",
                        isRequired: true
                    }
                    ]}
                />
            }
            {params.slug === "interruption-reasons" &&
                <TypesManagement
                    title={t("interruption_reasons")}
                    endpoint={API.ATTENDANCE_INTERRUPTION_REASONS}
                    permissions={[{
                        view: [],
                        manage: ['attendance.c_manage_interruption_reasons'],
                        delete: ['attendance.c_manage_interruption_reasons'],
                    }]}
                    definitions={[{
                        name: t("title"),
                        key: "title",
                        as: "input",
                        isRequired: true
                    }]}
                />
            }
            {params.slug === "driver-licence" &&
                <TypesManagement
                    title={t("driver_licence")}
                    endpoint={API.DRIVER_LICENCE}
                    permissions={[{
                        view: [],
                        manage: ['common.c_manage_all_employees'],
                        delete: ['common.c_manage_all_candidates '],
                    }]}
                    definitions={[{
                        name: t("driver_group"),
                        key: "driver_group",
                        as: "input",
                        isRequired: true
                    }]}
                />
            }
            {params.slug === "turnuses" &&
                <TypesManagement
                    title={t("turnuses")}
                    endpoint={API.ATTENDANCE_TURNUSES}
                    permissions={[{
                        view: [],
                        manage: ['attendance.c_manage_turnuses'],
                        delete: ['attendance.c_manage_turnuses'],
                    }]}
                    definitions={[{
                        name: t("hours"),
                        key: "hours",
                        as: "input",
                        isRequired: true
                    }]}
                />
            }
        </Container>
    );
};

export default TypesDetail;