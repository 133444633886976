import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store 
import { API } from '@store/config';
// import { createRequest } from '@services/ServiceCommon';
import { useFetchData } from '@helpers/hooks';
// components
// import { PDFViewer } from '@react-pdf/renderer';
// import PDFTemplate from './PDFTemplate';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';


const RenderTemplateHtmlToPDF = ({ objId, documentId, objInstance, onClose }) => {
    const { t } = useTranslation();
    const [htmlResult] = useFetchData(API.TEMPLATING + documentId + "/fill/"+objInstance+"/" + objId)

    useEffect(() => {
        async function handlePDFGeneration(){
            if(htmlResult.isLoading) return;
            var xhr = new XMLHttpRequest();
            xhr.open('POST', API.EXPORTS_HTML_TO_PDF, true);
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');

            xhr.onload = function(e) {
                if (this.status === 200) {
                    var blob = new Blob([this.response], {type: 'application/pdf'}),
                    fileURL = URL.createObjectURL(blob);
                    window.open(fileURL,'_blank');

                    onClose()
                }
                };
                
            xhr.send(JSON.stringify({
                content: htmlResult?.data?.html
            }));
        }

        handlePDFGeneration();
        // eslint-disable-next-line
    }, [htmlResult])

    return (
        <>
        { htmlResult.isLoading 
            ? 
            <Segment style={{ padding: "5rem" }}>
                    <Dimmer active={htmlResult.isLoading } inverted>
                        <Loader size="medium" inverted> { t('generating_pdf') } </Loader>
                    </Dimmer>
                </Segment>  
            :
            <div style={{ height: "1000px" }}>
                {/* <PDFViewer style={{ width: "100%", height: "100%" }}>
                    <PDFTemplate htmlContent={htmlResult?.data?.html}/>
                </PDFViewer> */}
            </div>
        }
        </>
    );
};

export default RenderTemplateHtmlToPDF;