import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { useLocation } from 'react-router-dom';
import { useFetchData } from '@helpers/hooks';
import { patchRequest, deleteRequest, getRequest } from '@services/ServiceCommon';
import { routes } from '@routes';
import { icons } from '@icons';
import { useQueryPage } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import EmptyRow from '@components/tables/EmptyRow';
import Loading from '@components/general/Loading';
import AvatarIcon from '@components/AvatarIcon';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import Action from '@components/general/Action';
import { Button, Icon as SemanticIcon, Label, Divider, Sidebar, Segment, Menu, Grid } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
// module specific components
import AccountsFilterForm from '../components/forms/AccountsFilterForm';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SupplierForm from '../../suppliers/components/forms/SupplierForm';
import ContactCell from '../../suppliers/components/ContactCell';
import LabelsForm from '../../suppliers/components/forms/LabelsForm';

const AccountsList = () => {
    const { t } = useTranslation()
    const [accounts, setAccounts] = useFetchData(API.ACCOUNTS + "?paginate=true&is_supplier=false")
    const [visible, setVisible] = useState(false)

    const [limit, setLimit] = useState(10)
    const [loading, setLoading] = useState(false)
    const [reFetched, setReFetched] = useState(false)
    let page = useQueryPage()

    const initialFilters = {
        search: "",
        identification_number: "",
        dit_number: "",
        vat_number: "",
        is_active: true,
        is_freelancer: "",
        approve_automatically: "",
        unit: "",
        labels: []
    }

    const [filters, setFilters] = useState(initialFilters)
    const location = useLocation()

    const onFilter = () => {
        setVisible(false)
        let filterBaseParams = ""

        if (filters?.identification_number !== "") filterBaseParams += "&identification_number=" + filters?.identification_number
        if (filters?.dit_number !== "") filterBaseParams += "&dit_number=" + filters?.dit_number
        if (filters?.vat_number !== "") filterBaseParams += "&vat_number=" + filters?.vat_number
        if (filters?.unit !== "") filterBaseParams += "&unit=" + filters?.unit
        if (filters?.search !== "") filterBaseParams += "&search=" + filters?.search
        if (filters?.approve_automatically !== "") filterBaseParams += "&approve_automatically=" + filters?.approve_automatically

        if (filters?.labels.length > 0) {
            let mergedLabels = ""
            for (let i = 0; i < filters.labels.length; i++) {
                if (i === 0) {
                    mergedLabels += filters.labels[i]
                } else {
                    mergedLabels += "--" + filters.labels[i]
                }
            }

            filterBaseParams += "&account_and_contact_labels=" + mergedLabels
        }
        
        return filterBaseParams
    }

    const onLabelsAdd = (id, labels) => {
        setAccounts(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                results: accounts.data.results.filter(emp => {
                    if (emp?.id === id) {
                        emp.tags = labels
                    }
                    return emp;
                })
            }
        }))
    }

    const onLabelRemove = async (id, labelID, type) => {

        const request = await patchRequest(API.ACCOUNTS + id + '/', {
            tags: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setAccounts(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: accounts.data.results.filter(emp => {
                        if (emp?.id === id) {
                            emp.tags = request.response.tags
                        }
                        return emp;
                    })
                }
            }))
        }

    }

    const ShowLabels = ({ row }) => {
        return (
            <>
                {row?.tags?.map(label => (
                    <Label basic size="tiny" key={label.id}>
                        <Icon name="pricetag-outline" style={{ marginRight: "0.5rem", color: label.color === "" ? "" : label.color }} /> 
                        <span style={{ position: "relative", top: "-0.2rem" }}>{label.name}</span>
                        <Icon name="close-outline" style={{ marginLeft: "0.5rem" }} onClick={() => onLabelRemove(row.id, label.id)} />
                    </Label>
                ))}
                <SuperDuperModal
                    trigger={
                        <Label basic size="tiny" style={{ cursor: "pointer" }}>
                            <Icon name="add-outline"/>
                        </Label>
                    }
                    content={
                        <LabelsForm
                            id={row.id}
                            contactLabels={row.tags}
                            type={'account'}
                            onConfirm={onLabelsAdd}
                        />
                    }
                />
            </>
        );
    }

    const onDelete = async (id) => {

        const request = await deleteRequest(API.ACCOUNTS + id + '/');
        if (request.status === 204) {
            setAccounts(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: accounts.data.results.filter(item => item.id !== id)
                }
            }))
        }

    }

    const onAdd = (response) => {
        setAccounts(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                results: [response, ...prevState.data.results]
            }
        }))
    }

    const onUpdate = (response) => {
        setAccounts(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                results: prevState.data.results.filter(acc => {
                    if (acc.account !== null) {
                        if (acc.id === response.id) {
                            acc.name = response.name
                            acc.business_detail = response.business_detail
                            acc.contact_info = response.contact_info
                            acc.approve_automatically = response.approve_automatically
                        }
                    }
                    return acc;
                })
            }
        }))
    }

    const fetchAccounts = async (params, isFiltered) => {
        params = params || ""
        params += onFilter()
        if (!loading){
            setReFetched(true)
            setLoading(true)

            if (isFiltered) {
                page = 1
            } else {
                let searchParams = new URLSearchParams(location.search)
                const param = searchParams.get('page')
                page = (param !== null ? param : 1)
            }

            if ( !params.includes('page') ) {
                params += "&page=" + page
            }

            if ( !params.includes('limit')){
                params += "&limit=" + limit
            } 

        setAccounts(prevState => ({
            ...prevState,
            isLoading: true
        }))
        const request = await getRequest(API.ACCOUNTS + "?paginate=true&is_supplier=false" + params)

        if (request.status === 200) {
            setAccounts(prevState => ({
                ...prevState,
                isLoading: false,
                data: request.response
            }))
        }
        setReFetched(false)
            setLoading(false)
        }
    }

    return (
        <>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("accounts"), 'icon': icons.ACCOUNTS, 'href': '' }
                ]}
            />
            <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                <Sidebar
                    style={{ width: "40rem", }}
                    as={Menu}
                    animation='overlay'
                    direction='right'
                    icon='labeled'
                    vertical
                    visible={visible}
                    onHide={() => setVisible(false)}
                    width='wide'
                >
                    <AccountsFilterForm
                        setVisible={setVisible}
                        fetchAccounts={fetchAccounts}
                        onFilter={onFilter} 
                        filters={filters} 
                        setFilters={setFilters}
                    />
                </Sidebar>
                <Sidebar.Pusher>
                    <Segment loading={reFetched || loading} basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }}>
                        <Grid>
                            <Grid.Row style={{ padding: 0 }} verticalAlign="middle">
                                <Grid.Column computer={8} mobile={16} tablet={16} />
                                <Grid.Column computer={8} mobile={16} tablet={16} style={{ textAlign: "right" }}>
                                    <Button
                                        size="small"
                                        style={{
                                            background: "transparent",
                                            color: "var(--black)",
                                            paddingLeft: "1rem",
                                            paddingRight: "1rem"
                                        }}
                                        onClick={() => setVisible(!visible)}
                                    >
                                        <Icon name='funnel-outline'/>
                                    </Button>
                                    <SuperDuperModal
                                        header={t('account_create')}
                                        size="small"
                                        trigger={
                                            <Button size="small" primary>{t('account_create')}</Button>
                                        }
                                        content={
                                            <SupplierForm isSupplier={false} type="account" onConfirm={(response, type) => onAdd(response, type)} />
                                        }
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Divider />

                        {accounts.isLoading ? <Loading /> : 
                         (!reFetched && !loading) &&
                            <FlexTable>
                                <FlexRow>
                                    <FlexHeader basis="50%" content={t('name')} />
                                    <FlexHeader basis="50%" content={t('id_number')} />
                                    <FlexHeader basis="60%" content={t('contact')} />
                                    <FlexHeader basis="150%" content={t('labels')} />
                                    <FlexHeader basis="50%" content={t('invoice_approval')} />
                                    <FlexHeader basis="30%" content={t('actions')} />
                                </FlexRow>
                                <EmptyRow length={accounts.data.results?.length} />
                                {accounts.data?.results?.map((account, index) => (
                                    <FlexRow key={index} fontSize="0.9rem" borders>
                                        <FlexItem basis="50%">
                                            <FlexRow background="transparent" padding="0" fontSize="0.9rem">
                                                <FlexItem basis="10%">
                                                    <SemanticIcon.Group style={{ marginRight: "0.5rem", padding: "0.5rem" }}>
                                                        <AvatarIcon
                                                            size="30"
                                                            name={account?.name ? account.name : 'undefined'}
                                                            src={account?.logo ? account.logo : ''}
                                                        />
                                                    </SemanticIcon.Group>
                                                </FlexItem>
                                                <FlexItem>
                                                    <strong> {account?.name ? account.name : '--'} </strong><br />
                                                </FlexItem>
                                            </FlexRow>
                                        </FlexItem>
                                        <FlexItem basis="50%" content={account?.business_detail?.identification_number} />
                                        <FlexItem basis="60%" content={<ContactCell row={account?.contact_info ? account.contact_info : ''} />} />
                                        <FlexItem basis="150%" content={
                                            <ShowLabels
                                                row={account ? account : ''}
                                                type={'account'}
                                            />
                                        } />
                                        <FlexItem basis="50%" bold>
                                            {account !== null && <> {account.approve_automatically ? t('automatic') : t('manual')} </>}
                                        </FlexItem>
                                        <FlexItem basis="30%">
                                            <FlexRow background="transparent" padding="0">
                                                <FlexItem textAlign="center">
                                                    <Action type='icon' as="link" to={routes.ACCOUNTS_DETAIL + account?.id} tooltip={t('view_detail')} />
                                                </FlexItem>
                                                <FlexItem textAlign="center">
                                                    <Action
                                                        as="modal"
                                                        type="icon"
                                                        icon="pencil-outline"
                                                        size="small"
                                                        popupText={t('edit')}
                                                        modal={
                                                            <SupplierForm
                                                                isSupplier={false}
                                                                data={account}
                                                                type={'account'}
                                                                onConfirm={(response, type) => onUpdate(response, type)}
                                                            />
                                                        }
                                                    />
                                                </FlexItem>
                                                <FlexItem textAlign="center">
                                                    <Action
                                                        as='delete'
                                                        text={t('delete_account')}
                                                        onClick={() => onDelete(account?.id, 'account')}
                                                    />
                                                </FlexItem>
                                            </FlexRow>
                                        </FlexItem>
                                    </FlexRow>
                                ))}
                            </FlexTable>
                        }
                        
                    </Segment>
                    <Paginator
                            forcePage={page || 1}
                            limit={limit}
                            setLimit={setLimit}
                            length={accounts?.data?.count || 0}
                            onChange={(params) => fetchAccounts(params)}
                        />
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </>
    );
};

export default AccountsList;