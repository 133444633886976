import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { toast } from 'react-semantic-toasts';
import moment from 'moment';
// store 
import { API } from '@store/config';
import { getRequest, patchRequest, createRequest } from '@services/ServiceCommon';
import { parseJsonStringToObject } from "@helpers/functions";
// components
import { Button, Segment, Grid, Header, Divider } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable'
import SuperDuperModal from '@components/modals/SuperDuperModal';
import ContactCell from '../../../candidates/components/tables/ContactCell';
import ProfileCard from '@components/general/ProfileCard';
import ConfirmModal from '@components/modals/ConfrimModal';
import CanView from '@components/perms/CanView';

const ReactionsTable = ({ roomID, isRejected, setIsRejected, publishers, setPublishers, setCandidates }) => {
    const { t } = useTranslation();
    const publisherChoices = useSelector(state => state.choices?.publishers)
    const [publisherChoiceList, setPublisherChoiceList] = useState([])
    const [reactionStats, setReactionStats] = useState({})

    const [isFetching, setFetching] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        fetchReactionStats()
        createPublisherList()
        // eslint-disable-next-line
    }, [])

    async function fetchReactions(params){
        params = params || "" 
        setFetching(true)
        const request = await getRequest(API.PUBLICATION_REACTIONS + "?recruitment=" + roomID + params)

        if (request.status === 200) {
            let reactionsTable = [];
            let candidateData;
            for (let i = 0; i < request.response.length; i++) {
                if (typeof request.response[i].data === 'object') {
                    candidateData = request.response[i].data
                }
                else {
                    candidateData = parseJsonStringToObject(request.response[i].data);
                }

                reactionsTable.push({
                    id: request.response[i].id,
                    uuid: request.response[i].uuid,
                    name: candidateData?.personal_information?.person?.firstname + ' ' + candidateData?.personal_information?.person?.lastname,
                    phone: candidateData?.personal_information?.contact_info?.phones?.create[0]?.phone,
                    email: candidateData?.personal_information?.contact_info?.emails?.create[0]?.email,
                    created_on: request.response[i].created_on ? moment(request.response[i].created_on).format('YYYY-MM-DD') : '',
                    publication: request.response[i].publication.publishers_display_list,
                    gdpr: request.response[i].gdpr_agreement?.id > 0 ? true : false,
                    status: request.response[i].is_rejected,
                    profile: request.response[i].profile?.id,
                    candidateData: candidateData
                });
            }
            setData(reactionsTable);
        }

        setFetching(false)
    }

    useEffect(() => {
        let params = '';

        if (isRejected !== "") {
            params += "&is_rejected=" + isRejected
        }
        if (publishers !== "") {
            params += "&publishers=" + publishers
        }

        fetchReactions(params)
        // eslint-disable-next-line
    }, [isRejected, publishers])

    const onReject = async (reactionData) => {
        const request = await patchRequest(API.PUBLICATION_REACTIONS + reactionData.uuid + "/", { is_rejected: true })

        if (request.status === 200) {
            setData(prevState => prevState.filter(reaction => {
                if(reaction.id === request.response.id){
                    reaction.status = true
                }

                return reaction
            }))

            setReactionStats(prevState => ({
                ...prevState,
                results: prevState.results.filter(item => {
                    if( item.id === reactionData.id ){
                        item.is_rejected = true
                    }

                    return item
                })
            }))

            toast({
                type: 'success',
                icon: 'check circle',
                title: t('success'),
                description: t('reject_reaction_message'),
                animation: 'bounce',
                time: 5000,
            }); 
        }
    }

    const onApply = async (reactionData, roomID) => {
        const request = await createRequest(API.PUBLICATION_REACTIONS + reactionData.id + "/convert_to_profile/", {})
        
        if (request.status === 201) {
            const requestAssign = await createRequest(API.CANDIDATES + request.response.id + "/assign_to_recruitment/", { recruitment: roomID })
            if (requestAssign.status === 200) {
                setData(prevState => prevState.filter(reaction => {
                    if(reaction.id === reactionData.id){
                        reaction.profile = request.response.id
                        reaction.status = false
                    }
    
                    return reaction
                }))
                setCandidates(prevState => (
                    {
                        ...prevState,
                        count: prevState.count += 1,
                        results: [...prevState.results, requestAssign.response]
                    }
                ))

                toast({
                    type: 'success',
                    icon: 'check circle',
                    title: t('success'),
                    description: t('convert_to_profile_success_message'),
                    animation: 'bounce',
                    time: 5000,
                }); 
            }
        } else {
            toast({
                type: 'error',
                icon: 'warning',
                title: t('error'),
                description: t('convert_to_profile_error_message'),
                animation: 'bounce',
                time: 5000,
            }); 
        }
    }

    const fetchReactionStats = async () => {
        const request = await getRequest(`${API.JOBS_STATS}publishers/stats/?recruitment=${roomID}`)
        setReactionStats(request.response)
    }

    const createPublisherList = () => {
        let choiceList = []
        Object.entries(publisherChoices).forEach(([key, value], index) => {
            choiceList.push({ value: key, text: value })
        })

        setPublisherChoiceList(choiceList)
    }

    const transformNameToKey = (name) => {
        let key = name;
        key = key.replace(/\./g, "_")
        key = key.toLowerCase()

        return key
    }


    return (
        <>
            <Grid>
                <Grid.Row columns={8} textAlign="center" style={{ fontWeight: "bold", cursor: "pointer" }}>
                    <Grid.Column
                        as={Segment}
                        style={{ background: publishers === "" && isRejected === "" ? "var(--light)" : "none", paddingTop: "1rem", paddingBottom: "1rem" }}
                        onClick={() => {
                            setPublishers("")
                            setIsRejected("")
                        }}
                    >
                        <Header as="h3" content={t('all')} style={{ marginBottom: 0 }} />
                        <Header as="h1" content={reactionStats?.responses_count?.all} style={{ marginTop: "0.5rem", marginBottom: 0 }} />
                    </Grid.Column>

                    {publisherChoiceList.map(publisher => (
                        <Grid.Column
                            key={publisher.value}
                            as={Segment}
                            style={{ background: publishers === publisher.value ? "var(--light)" : "none", paddingTop: "1rem", paddingBottom: "1rem" }}
                            onClick={() => {
                                setPublishers(publisher.value)
                                setIsRejected("")
                            }}
                        >
                            <Header as="h3" content={publisher.text} style={{ marginBottom: 0 }} />
                            <Header as="h1" content={reactionStats?.responses_count?.[transformNameToKey(publisher.text)]} style={{ marginTop: "0.5rem", marginBottom: 0 }} />
                        </Grid.Column>
                    ))}

                    <Grid.Column
                        as={Segment}
                        style={{ background: publishers === "" && isRejected === true ? "var(--light)" : "none", paddingTop: "1rem", paddingBottom: "1rem" }}
                        onClick={() => {
                            setPublishers("")
                            setIsRejected(true)
                        }}
                    >
                        <Header as="h3" content={t('rejected')} style={{ marginBottom: 0, color: "var(--danger)" }} />
                        <Header as="h1" content={reactionStats?.results?.filter(result => result.is_rejected).length} style={{ marginTop: "0.5rem", marginBottom: 0 }} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <Divider />

            <Segment loading={isFetching} style={{ border: 0, boxShadow: 'none', minHeight: "5rem", padding: 0, background: "transparent" }}>
                { !isFetching && 
                    <FlexTable>
                        <FlexRow>
                            <FlexHeader basis="110%" content={t('name')} />
                            <FlexHeader basis="80%" content={t('contact')} />
                            <FlexHeader basis="50%" content={t('created_on')} />
                            <FlexHeader basis="50%" content={t('publication')} />
                            <FlexHeader basis="30%" content={t('gdpr')} />
                            <FlexHeader basis="50%" content={t('is_rejected')} />
                            <FlexHeader basis="30%" content={t('actions')} />
                        </FlexRow>
                        {data.length === 0 &&
                            <FlexRow fontSize="1rem" borders>
                                <FlexItem bold textAlign="center" content={t('no_records')} />
                            </FlexRow>
                        }
                        {data?.map((row, i) => (
                            <FlexRow key={i} fontSize="1rem" borders>
                                <FlexItem basis="110%" content={<b>{row.name}</b>} />
                                <FlexItem basis="80%" content={<ContactCell row={row} />} />
                                <FlexItem basis="50%" content={row.created_on} />
                                <FlexItem basis="50%" content={
                                    row.publication.map((pub, i) => (
                                        <div key={i}>{pub}</div>
                                    ))
                                } />
                                <FlexItem basis="30%" content={row?.gdpr ? t('yes') : t('no')} />
                                <FlexItem basis="50%" content={row.status ? t('yes') : t('no')} />
                                <FlexItem basis="30%">
                                    <FlexRow padding="0" background="transparent">
                                        <FlexItem>
                                            <SuperDuperModal
                                                trigger={
                                                    <Button
                                                        icon="eye"
                                                        style={{ background: "none", color: "var(--primary)", paddingRight: '1rem' }}
                                                    />
                                                }
                                                size={"small"}
                                                content={<ProfileCard candidateData={row?.candidateData} />}
                                            />
                                        </FlexItem>
                                            <CanView permissions={['candidates.c_manage_recruitment', 'common.c_manage_all_candidates']}>
                                                <FlexItem>
                                                    {(!row.profile && !row.status) &&
                                                        <ConfirmModal
                                                            button={
                                                                <Button
                                                                    icon='external square alternate'
                                                                    style={{ background: 'none', color: 'var(--blue)', paddingLeft: 0 }}
                                                                />
                                                            }
                                                            description={t('would_you_like_to_convert_this_reaction_to_candidate')}
                                                            onConfirm={() => onApply(row, roomID)}
                                                        />
                                                    }
                                                </FlexItem>
                                            </CanView>
                                            <CanView permissions={['candidates.c_manage_recruitment']}>
                                                <FlexItem>
                                                    {(!row.status && row.profile === undefined) &&
                                                        <ConfirmModal
                                                            button={<Button icon="delete" style={{ background: "none", color: "var(--danger)", paddingLeft: 0 }} />}
                                                            description={t('reject_react')}
                                                            onConfirm={() => onReject(row)}
                                                        />
                                                    }
                                                </FlexItem>
                                            </CanView>
                                    </FlexRow>
                                </FlexItem>
                            </FlexRow>
                        ))}
                    </FlexTable>
                }
            </Segment>
        </>
    );
};

export default ReactionsTable;