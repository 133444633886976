import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// store 
import { API } from '@store/config';
import { patchRequest } from '@services/ServiceCommon';
// Components
import { Form } from 'semantic-ui-react';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import SuperLabelField from '@components/forms/SuperLabelField';

const CandidateLabelsForm = ({ id, candidateLabels, onClose, onConfirm }) => {
    const { t } = useTranslation();
    const [labels, setLabels] = useState(candidateLabels.map(item => item.id)) 
    const [isProcessing, setIsProcessing] = useState(false)

    const onSubmit = async () => {
        setIsProcessing(true)
        const request = await patchRequest(API.CANDIDATES + id + "/?query={id, contact_labels}", {
            contact_labels: {
                remove: candidateLabels.map(item => item.id),
                add: labels
            }
        })

        if( request.status === 200 ){
            onConfirm(id, request.response.contact_labels)
            onClose()
        }
        setIsProcessing(false)
    }

    return (
        <Form onSubmit={onSubmit}>
            <SuperLabelField
                value={labels}
                onChange={(labels) => setLabels(labels)}
                endpoint={API.CONTACT_LABELS}
            />
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit
                    disabled={isProcessing}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
        </Form>
    );
};

export default CandidateLabelsForm;