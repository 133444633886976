import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Grid, Search } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';

const FilterAction = ({ text, isActive, ...rest }) => {
    return (
        <span className={"filter-action " + (isActive && "active")}  {...rest}>
            {text}
        </span>
    )
}

const CoursesFilters = ({ categories, filter, setFilter }) => {
    const { t } = useTranslation();

    return (
        <Grid verticalAlign="middle">
            <Grid.Row style={{ padding: 0 }}>
                <Grid.Column computer={4} tablet={16} mobile={16}>
                    <FlexRow padding="0.5rem">
                        <FlexItem>
                            <FilterAction isActive={filter.mandatory} onClick={() => setFilter({ ...filter, mandatory: !filter.mandatory })} text={t('mandatory')} />
                            <FilterAction isActive={filter.selfImprovemet} onClick={() => setFilter({ ...filter, selfImprovemet: !filter.selfImprovemet })} text={t('self_improvement')} />
                        </FlexItem>
                    </FlexRow>
                </Grid.Column>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                    <Search
                        fluid
                        open={false}
                        value={filter.search}
                        className="report-search"
                        placeholder={t('enter_term_to_search')}
                        onSearchChange={(e, { value }) => setFilter({ ...filter, search: value })}
                    />
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right">
                    <SuperField fluid as="choice"
                        customOptions={categories}
                        value={filter.category}
                        onChange={(e, { value }) => setFilter({ ...filter, category: value })}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default CoursesFilters;