import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// store
import { routes } from '@routes';
import { API } from '@store/config';
import { setDotSeparator } from '@helpers/functions';
import { useSelectOptions, useFetchData } from '@helpers/hooks';
import { createRequest, getRequest, patchRequest } from '@services/ServiceCommon';
// components
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
import { Form, Divider, Segment, Button, Header, Loader, Dimmer } from 'semantic-ui-react';

const ContractForm = ({ onClose, contract, setContracts }) => {
    const { t } = useTranslation();
    // eslint-disable-next-line
    const [workPositionOptions, setWorkPositionOptions] = useSelectOptions(API.JOB_POSITIONS + "?query={id, title}", "title")
    const [contractTypes, setContractTypes] = useSelectOptions(API.CONTRACTS_TYPES + "?query={id, title}", "title")
    const [employerOptions, setEmployerOptions] = useState({
        isLoading: true,
        options: []
    })
    const [company] = useFetchData(API.COMPANIES + "?query={id, business_detail{id, name}, is_active}&is_active=true")
    const [isProcessing, setIsProcessing] = useState(false)
    const [formData, setFormData] = useState({
        contractType: contract !== undefined ? contract.contract_data.type.id : "",
        employee: contract !== undefined ? contract.employee.id : "",
        employer: contract !== undefined ? contract.contract_data.employer?.id : "",
        workPosition: contract !== undefined ? contract.contract_data.work_position.id : "",
        workLevel: contract !== undefined ? contract.contract_data.work_level?.toString() : "",
        starts: contract !== undefined ? contract.contract_data.valid_from : "",
        ends: contract !== undefined ? contract.contract_data.valid_until : "",
        fond: contract !== undefined ? contract.contract_data.fond : "",
        salary: contract !== undefined ? contract.contract_data.salary.salary : 0,
        currency: contract !== undefined ? contract.contract_data.salary.currency : "EUR",
        perUnit: contract !== undefined ? contract.contract_data.salary.per_unit?.toString() : "",
        trialPeriod: contract !== undefined ? contract.contract_data.trial_period : "",
        isWorkingShifts: contract !== undefined ? contract.contract_data.is_working_shifts : false
    })

    useEffect(() => {
        // fetch units of active company
        async function fetchUnits(){
            if( !company.isLoading ){
                let tmpOptions = []

                if( company.data?.[0].business_detail?.id ){
                    tmpOptions.push({
                        key: company.data?.[0].business_detail?.id,
                        value: company.data?.[0].business_detail?.id,
                        text: company.data?.[0].business_detail?.name
                    })
                }
    
                const request = await getRequest(API.UNITS + "?query={id, name, business_detail}&only_from_active_company=true")
                if( request.status === 200 ){
                    for (let i = 0; i < request.response.length; i++) {
                        if( request.response[i].business_detail?.id ){
                            tmpOptions.push({
                                key: request.response[i].business_detail?.id,
                                value: request.response[i].business_detail?.id,
                                text: request.response[i].business_detail?.name
                            })
                        }
                    }
                }
    
                setEmployerOptions({
                    isLoading: false,
                    options: tmpOptions
                })
                if( contract === undefined || contract?.contract_data?.employer?.id === undefined ){
                    setFormData(prevState => ({...prevState, employer: company.data?.[0].business_detail?.id}))
                }
            }
        }

        fetchUnits()
        // eslint-disable-next-line
    }, [company])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true)

        const data = {
            contract_data: {
                valid_from: formData.starts,
                valid_until: formData.ends === "" ? null : formData.ends,
                fond: formData.fond,
                work_level: formData.workLevel,
                trial_period: formData.trialPeriod === "" ? null : formData.trialPeriod,
                is_working_shifts: formData.isWorkingShifts,
                employer: formData.employer,
                type: formData.contractType,
                salary: formData.salary ? {
                    currency: formData.currency,
                    salary: formData.salary,
                    per_unit: formData.perUnit
                } : null,
                work_position: formData.workPosition
            },
            employee: formData.employee,
        }
        
        if( contract === undefined ){
            const request = await createRequest(API.CONTRACTS_INSTANCES, data)
    
            if( request.status === 201 ){
                setContracts(prevState => {
                    return {
                        ...prevState,
                        data: {
                            ...prevState.data,
                            results: [request.response, ...prevState.data.results]
                        }
                    }
                })
    
                onClose()
            }
        } else {
            const request = await patchRequest(API.CONTRACTS_INSTANCES + contract.id + "/", data)
    
            if( request.status === 200 ){
                setContracts(prevState => {
                    return {
                        ...prevState,
                        data: {
                            ...prevState.data,
                            results: prevState.data.results.filter(item => {
                                if(item.id === contract.id){
                                    item.employee = request.response.employee
                                    item.contract_data = request.response.contract_data
                                }
    
                                return item
                            })
                        }
                    }
                })
    
                onClose()
            }

        }

        setIsProcessing(false)
    }

    const handleAddItem = async (newItem) => {
        setContractTypes({...contractTypes, isLoading: true})
        const request = await createRequest(API.CONTRACTS_TYPES, {
            title: newItem
        })

        if (request.status === 201) {
            setContractTypes(prevState => {
                return {
                    isLoading: false,
                    options: [{ 
                        key: request.response.id, 
                        value: request.response.id, 
                        text: request.response.title 
                    }, ...prevState.options]
                }
            })

            setFormData(prevState => {
                return {
                    ...prevState, 
                    contractType: request.response.id
                }
            })
        }
    }

    return (
        <>
            { company.isLoading
                ? 
                <Segment style={{ padding: "5rem" }}>
                    <Dimmer active={company.isLoading} inverted>
                        <Loader size="medium" inverted>{ t('configuration_check') }</Loader>
                    </Dimmer>
                </Segment> 
                :
                <Form onSubmit={handleSubmit}>
                    { (company?.data?.length > 0 && company?.data?.[0]?.business_detail?.id) ? 
                        <>

                            <SuperField as="choice"
                                search
                                required
                                label={ t('employer') }
                                value={formData.employer}
                                onChange={(e, { value }) => setFormData({...formData, employer: value})}
                                disabled={employerOptions.isLoading}
                                loading={employerOptions.isLoading}
                                customOptions={employerOptions.options}
                            />

                            <Divider/>

                            <Form.Group widths="equal">
                                <SuperField as="choice"
                                    search
                                    required
                                    text="fullname"
                                    label={ t('employee') }
                                    value={formData.employee}
                                    onChange={(e, { value }) => setFormData({...formData, employee: value})}
                                    endpoint={API.EMPLOYEES + "?query={id, fullname}"}
                                />
                                <SuperField as="choice"
                                    allowAdditions
                                    search
                                    required
                                    text="title"
                                    size="small"
                                    label={ t('contract_type') }
                                    value={formData.contractType}
                                    help={ t('add_contract_type_hint') }
                                    disabled={contractTypes.isLoading}
                                    loading={contractTypes.isLoading}
                                    customOptions={contractTypes.options}
                                    onAddItem={(e,{ value }) => handleAddItem(value)}
                                    onChange={(e, { value }) => setFormData({...formData, contractType: value})}

                                />
                            </Form.Group>

                            <Form.Group widths="equal">
                                <SuperField as="choice"
                                    search
                                    required
                                    size="small"
                                    label={ t('work_position') }
                                    value={formData.workPosition}
                                    onChange={(e, { value }) => setFormData({...formData, workPosition: value})}
                                    disabled={ workPositionOptions.isLoading }
                                    loading={ workPositionOptions.isLoading }
                                    customOptions={workPositionOptions.options}
                                />
                                <SuperField as="choice"
                                    search
                                    required
                                    label={ t('work_level') }
                                    type="contracts_work_levels"
                                    value={formData.workLevel}
                                    onChange={(e, { value }) => setFormData({...formData, workLevel: value})}
                                />
                            </Form.Group>



                            <Form.Group widths="equal">
                                <SuperField as="datepicker"
                                    required
                                    closable
                                    label={ t('starts') }
                                    value={formData.starts}
                                    onChange={(e, { value }) => setFormData({...formData, starts: value})}
                                />
                                <SuperField as="datepicker"
                                    closable
                                    label={ t('ends') }
                                    value={formData.ends}
                                    onChange={(e, { value }) => setFormData({...formData, ends: value})}
                                />
                            </Form.Group>

                            <Form.Group widths="equal">
                                <SuperField as="input"
                                    type="number"
                                    required
                                    label={ t('fond') }
                                    value={formData.fond}
                                    onChange={(e, { value }) => setFormData({...formData, fond: value})}
                                />
                                <SuperField as="datepicker"
                                    closable
                                    label={ t('trial_period') }
                                    value={formData.trialPeriod}
                                    onChange={(e, { value }) => setFormData({...formData, trialPeriod: value})}
                                />
                            </Form.Group>

                            <SuperField as="checkbox"
                                label={ t('is_working_shifts')}
                                checked={formData.isWorkingShifts}
                                onChange={() => setFormData({...formData, isWorkingShifts: !formData.isWorkingShifts})}
                            />

                            <Header as="h3">{ t('salary_config') }</Header>
                            <Divider/>
                            <Form.Group widths="equal">
                                <SuperField as="input"
                                    type="number"
                                    required
                                    label={ t('salary') }
                                    value={formData.salary}
                                    onChange={(e, { value }) => setFormData({...formData, salary: setDotSeparator(value)})}
                                />
                                <SuperField as="choice"
                                    search
                                    required
                                    label={ t('currency') }
                                    type="currency_codes"
                                    value={formData.currency}
                                    onChange={(e, { value }) => setFormData({...formData, currency: value})}
                                />
                                <SuperField as="choice"
                                    required
                                    type="job_salary_type"
                                    label={ t('salary_type') }
                                    value={formData.perUnit}
                                    onChange={(e, { value }) => setFormData({...formData, perUnit: value})}
                                />
                            </Form.Group>


                            <Divider/>
                            <Form.Field style={{ textAlign: "right" }}>
                                <ModalCancel onClose={onClose} />
                                <ModalSubmit
                                    loading={isProcessing}
                                    disabled={
                                        isProcessing ||
                                        formData.employee === "" ||
                                        formData.contractType === "" ||
                                        formData.workPosition === "" ||
                                        formData.fond === "" ||
                                        formData.starts === "" ||
                                        formData.workLevel === "" ||
                                        formData.perUnit === "" ||
                                        formData.currency === "" ||
                                        formData.salary === ""
                                    }
                                    text={t('save')} />
                            </Form.Field>
                        </>
                        : 
                            <Segment placeholder>
                                <Header textAlign="center">
                                    { (company?.data?.length > 0 && !company?.data?.[0]?.is_active) && t('inactive_company_msg')}
                                    { (company?.data?.length > 0 && company?.data?.[0]?.business_detail === null) &&  t('not_properly_configured_company_msg')}
                                </Header>
                                <Button as={Link} to={routes.COMPANY} primary>{ t('go_to_company_profile') }</Button>
                            </Segment>
                        }
                </Form>
            }
        </>
    )
};

export default ContractForm;