import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
// components
import { Form, Divider } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField'
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';

const TypeForm = ({ endpoint, definitions, action, onConfirm, onClose, result }) => {
    // eslint-disable-next-line
    const [form, setForm] = useState({})
    const [isProcessing, setIsProcessing] = useState(false)
    const [errors, setErrors] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        let arr = {}
        for (let i = 0; i < definitions.length; i++) {
            arr[definitions[i].key] = result?.[definitions[i].key] ? result[definitions[i].key] : ""
        }

        setForm(arr)
        // eslint-disable-next-line
    }, [])

    const onSubmit = async (e) => {
        e.preventDefault()
        setIsProcessing(true);
        setErrors(null);
        let request = null;

        if (action === 'create') {
            request = await requests.post(endpoint, form)
        } else {
            request = await requests.put(endpoint + result.id + "/", form)
        }
        if (request !== null) {
            if (request.status === 201 || request.status === 200) {
                onConfirm(request.response);
                onClose()
            } else {
                setErrors(request.response)
            }
        }

        setIsProcessing(false);
    }

    return (
        <Form onSubmit={onSubmit}>
            { errors !== null && errors?.non_field_errors }
            {definitions.map((field, index) => (
                <>
                    {field.as === "choice"
                        ? field.customOptions !== undefined
                            ?
                            <SuperField
                                key={index}
                                as={field.as}
                                search={field.search}
                                label={field.name}
                                value={form?.[field.key]}
                                required={field.isRequired}
                                error={ errors === null ? false : errors?.[field.key] }
                                customOptions={field.customOptions}
                                onChange={(e, { value }) => setForm({
                                    ...form,
                                    [field.key]: value
                                })}
                            />
                            :
                            <SuperField
                                key={index}
                                as={field.as}
                                search={field.search}
                                type={field.type}
                                required={field.isRequired}
                                label={field.name}
                                value={form?.[field.key]}
                                error={ errors === null ? false : errors?.[field.key] }
                                onChange={(e, { value }) => setForm({
                                    ...form,
                                    [field.key]: value
                                })}
                            />
                        :
                        <SuperField
                            key={index}
                            as={field.as}
                            search={field.search}
                            required={field.isRequired}
                            label={field.name}
                            value={form?.[field.key]}
                            onChange={(e, { value }) => setForm({
                                ...form,
                                [field.key]: value
                            })}
                            error={ errors === null ? false : errors?.[field.key] }
                            color={form?.[field.key]}
                            setColor={(value) => setForm({
                                ...form,
                                [field.key]: value
                            })}
                        />
                    }
                </>

            ))}

            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel
                    onClose={onClose}
                    disabled={isProcessing}
                />
                <ModalSubmit
                    loading={isProcessing}
                    disabled={isProcessing /*|| isEmpty(title) || isEmpty(content)*/}
                    text={t('save')}
                />
            </Form.Field>
        </Form>
    )
}

export default TypeForm;