import React from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { routes } from '@routes';
import { requests } from '@helpers/requests';
// components
import Icon from '@components/Icon';
import { Label } from 'semantic-ui-react';
import EmptyRow from '@components/tables/EmptyRow';
import { FlexTable, FlexRow, FlexItem, FlexHeader } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import LabelsForm from '@components/forms/LabelsForm';
import Action from '@components/general/Action';
import CanView from '@components/perms/CanView';

import ProjectUpdateForm from './forms/ProjectUpdateForm';


const ProjectTableView = ({ projects, setProjects }) => {
    const { t } = useTranslation()

    const onDelete = async (id) => {
        const request = await requests.del(API.PROJECTS + id + "/")
        if(request.status === 204){
            setProjects(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    count: prevState.data.count - 1,
                    results: projects.data.results.filter(item => item.id !== id)
                }
            }))
        }
    }

    const onLabelsAdd = (id, labels) => {
        setProjects(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                results: projects.data.results.filter(emp => {
                    if (emp?.id === id) {
                        emp.labels = labels
                    }
                    return emp;
                })
            }
        }))
    }

    const onLabelRemove = async (id, labelID) => {

        const request = await requests.patch(API.PROJECTS + id + "/?query={id, labels}", {
            labels: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setProjects(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: projects?.data?.results?.filter(emp => {
                        if (emp?.id === id) {
                            emp.labels = request.response.labels
                        }
                        return emp;
                    })
                }
            }))
        }

    }

    const ShowLabels = ({ row }) => {
        return (
            <>
                {row?.labels?.map(label => (
                    <Label style={{ marginTop: '3px'}} basic size="tiny" key={label.id}>
                        <Icon name="pricetag-outline" style={{ color: label.color === "" ? "" : label.color, marginRight: "0.5rem" }} /> 
                        <span style={{ position: "relative", top: "-0.1rem" }}>
                            {label.name}
                        </span>
                        <CanView permissions={['projects.c_manage_labels']}>
                            <Icon
                                name="close-outline"
                                style={{ marginLeft: "0.5rem", color: "var(--danger)", cursor: "pointer" }}
                                onClick={() => onLabelRemove(row.id, label.id)}
                            />
                        </CanView>
                    </Label>
                ))}
                <CanView permissions={['projects.c_manage_labels']}>
                    <SuperDuperModal
                        trigger={
                            <Label basic size="tiny" style={{ marginTop: '5px',  cursor: "pointer" }}>
                                <Icon name="add-outline" style={{ marginRight: 0 }} />
                            </Label>
                        }
                        content={
                            <LabelsForm
                                id={row.id}
                                data={row.labels}
                                onConfirm={onLabelsAdd}
                                patchEndpoint={API.PROJECTS}
                                labelEndpoint={API.PROJECT_LABELS}
                                fieldName={"labels"}
                            />
                        }
                    />
                </CanView>
            </>
        );
    }

    return (
        <>
            <FlexTable>
                <FlexRow>
                    <FlexHeader basis="50%" content={t('name')} />
                    <FlexHeader basis="130%" content={t('labels')} />
                    <FlexHeader basis="40%" content={t('start_date')} />
                    <FlexHeader basis="40%" content={t('due_date')} />
                    <FlexHeader basis="30%" content={t('actions')} />
                </FlexRow>
                <EmptyRow length={projects?.data?.results?.length} />
                {projects?.data?.results?.map((project, index) => (
                    <FlexRow key={index} fontSize="0.9rem" borders>
                        <FlexItem basis="50%">
                            <strong>{project?.name}</strong>  <br />
                            <small>{project?.identifier}</small>
                        </FlexItem>
                        <FlexItem basis="130%" content={
                            <ShowLabels
                                row={project ? project : ''}
                                type={'projects'}
                            />
                        } />
                        <FlexItem basis="40%" content={project?.from_date} />
                        <FlexItem basis="40%" content={project?.due_date} />
                        <FlexItem basis="30%">
                            <FlexRow background="transparent" padding="0">
                                <FlexItem textAlign="center">
                                    <Action type='icon' as="link" to={routes.PROJECT_DETAIL + project.id} tooltip={t('view_detail')} />
                                </FlexItem>
                                <FlexItem textAlign="center">
                                    <CanView permissions={['projects.c_manage_all_projects', 'projects.c_manage_user_projects']}>
                                        <Action
                                            as="modal"
                                            type="icon"
                                            icon="pencil-outline"
                                            size="small"
                                            popupText={t('edit')}
                                            modal={
                                                <ProjectUpdateForm project={project} projects={projects} listView={true} setProjects={setProjects}/>
                                            }
                                        />
                                    </CanView>
                                </FlexItem>
                                <FlexItem textAlign="center">
                                    <CanView permissions={['projects.c_delete_all_projects']}>
                                        <Action
                                            as='delete'
                                            text={t('delete_project')}
                                            onClick={() => onDelete(project.id)}
                                        />
                                    </CanView>
                                </FlexItem>
                            </FlexRow>
                        </FlexItem>
                    </FlexRow>
                ))}
            </FlexTable>
        </>
    );
};

export default ProjectTableView;