import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
// components
import { Doughnut } from 'react-chartjs-2';
// eslint-disable-next-line
import { Dimmer, Form, Grid, Header, Segment, Icon, Popup } from 'semantic-ui-react';
// eslint-disable-next-line
import FilterOptions from '../graphs/FilterOptions';

const PieGraphInvoice = ({ premiumConnection, bankAccounts, loading }) => {
    const { t } = useTranslation();
    const colors = useSelector(state => state.global_pref.colors)
    const availableColors = [colors.primary, colors.info, colors.success, colors.warning, colors.danger, colors.iceblue, colors.blue, colors.dark]

    // eslint-disable-next-line
    const [values, setValues] = useState('23258.60');
    // eslint-disable-next-line
    const [valuesName, setValuesName] = useState('Paid');

    const content = (
        <Header as='h2' inverted style={{ width: "100%", color: "var(--primary)" }}>
            <Icon name="lock" style={{ fontSize: "5rem", marginRight: 0, marginTop: "4rem" }}/>
        </Header>
    )

    return (
        <Dimmer.Dimmable
            as={Segment}
            loading={loading}
            style={{ background: "transparent", padding: "1rem", minHeight: "20rem", boxShadow: "none", border: 0 }}
            dimmed={premiumConnection?.id === undefined}
            content={content}
        >
            <Grid columns='2'>
                <Grid.Column mobile={16} computer={16} tablet={16} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    {/* <Header as="h3" content={ t('bank_account_balances') } textAlign="center" style={{ marginTop: 0 }}/> */}
                    { bankAccounts.length > 0 
                        ? 
                            <div style={{ width: "100%", height: "auto" }}>
                                <Doughnut 
                                    options={{
                                        legend: {
                                            display: true,
                                            position: "left"
                                        },
                                    }}
                                    cutout="50"
                                    data={{
                                        labels: bankAccounts.map(bank => bank.accountReference.iban + " - "+ bank.bankCode +" (" + (bank?.balances?.[0]?.balanceAmount?.amount || 0) + " " + (bank?.balances?.[0]?.balanceAmount?.currency || 0) + ")"),
                                        datasets: [{
                                            data: bankAccounts.map(bank => bank?.balances?.[0]?.balanceAmount?.amount || 0),
                                            backgroundColor: bankAccounts.map((bank, index) => availableColors[index])
                                        }]
                                    }} 
                                />
                            </div>
                        : 
                            <div style={{ textAlign: "center", fontWeight: "bold" }}> 
                                { premiumConnection?.id !== undefined && t('no_bank_accounts_founded') }
                            </div>
                    }
                </Grid.Column>
                {/* <Grid.Column width='6' style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Form style={{ paddingTop: '3rem' }}>
                        <Form.Field inline>
                            <Grid columns='2'>
                                <Grid.Column width='1' style={{ padding: 0 }}>
                                    <Form.Input
                                        type='Checkbox'
                                        value='23258.60'
                                        checked={values === '23258.60'}
                                        onChange={(e) => {
                                            setValues(e.target.value);
                                            setValuesName('Paid');
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column style={{ padding: 0 }}>
                                    {t('paid')}
                                    <b>23258,60</b>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                        <Form.Field inline>
                            <Grid columns='2'>
                                <Grid.Column width='1' style={{ padding: 0 }}>
                                    <Form.Input
                                        type='Checkbox'
                                        value='22150.00'
                                        checked={values === '22150.00'}
                                        onChange={(e) => {
                                            setValues(e.target.value);
                                            setValuesName('All Invoices');
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column style={{ padding: 0 }}>
                                    {t('all_invoices')}
                                    <b>22150,00</b>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                        <Form.Field inline>
                            <Grid columns='2'>
                                <Grid.Column width='1' style={{ padding: 0 }}>
                                    <Form.Input
                                        type='Checkbox'
                                        value='3158.60'
                                        checked={values === '3158.60'}
                                        onChange={(e) => {
                                            setValues(e.target.value);
                                            setValuesName('Past due');
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column style={{ padding: 0 }}>
                                    {t('past_due')}
                                    <b>3158,60</b>
                                </Grid.Column>
                            </Grid>
                        </Form.Field>
                    </Form>
                    <br />
                    <FilterOptions />
                </Grid.Column> */}
            </Grid>
            
            <Dimmer 
                title={t('locked_feature')}
                active={premiumConnection?.id === undefined}
                content={content}
                style={{
                    background: "rgba(255, 255, 255, 0.85)",
                    color: "var(--dark)",
                    cursor: "not-allowed",
                    textAlign: "center",
                    zIndex: "998"
                }}
             />
        </Dimmer.Dimmable>
    );
};

export default PieGraphInvoice;