export const icons = {
      // HR
      POSITIONS: "book-outline",
      CONTRACTS: "file-tray-full-outline",
      EMPLOYEES: "person-outline",
      BENEFITS: "heart-outline",
      COURSES: "ribbon-outline",
      JOBS: "briefcase-outline",
      CANDIDATES: "id-card-outline",
      HIRING_ROOM: "trophy-outline",
      ONBOARDING: "barcode-outline",
      ATTENDANCE: "list",
      // PROJECTS
      PROJECTS: "folder-outline",
      TEAMS: "people-outline",
      TIMESHEETS: "timer-outline",
      // FINANCE
      SUPPLIERS: "business-outline",
      ACCOUNTS: "business-outline",
      ORDERS: "documents-outline",
      INVOICES: "layers-outline",
      PAYMENTS: "color-filter-outline",
      COST_CENTERS: 'prism-outline',
      CONTACTS: "call-outline",

      // OPERATIONS
      APPROVALS: "checkmark-done-outline",
      CASES: "help-buoy-outline",
      FORECASTS: 'stats-chart-outline',
      REPORTS: "pie-chart-outline",
      ASSETS: "cube-outline",
      COMPANY: "triangle-outline",
      GDPR_AGREEMENTS: 'clipboard-outline',
      SETTINGS: "settings-outline",

      // not sure yet...
      USERS: "users",
      CALENDAR: "calendar alternate",
      DOCUMENTS: "folder",
}