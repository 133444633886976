import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { isEmpty } from '@helpers/validation';
import { getRequest, createRequest, updateRequest} from '@services/ServiceCommon'; 
// components
import Icon from '@components/Icon';
import EmptyRow from '@components/tables/EmptyRow'; 
import SuperField from '@components/forms/SuperField';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
// import ConfirmModal from '@components/modals/ConfrimModal';
import ColorPickerField from "@components/forms/settings/ColorPickerField";
import { Form, Divider, Icon as SemanticIcon, Dropdown, Grid, Header, Popup, Button } from 'semantic-ui-react';

const HRProcessForm = ({ process, setProcesses, onClose }) => {
    const { t } = useTranslation();

    const options = [
        {
            key: 1,
            text: <span><Icon name="phone-portrait-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('mobile') }</span></span>,
            value: 'phone-portrait-outline',
        },
        {
            key: 2,
            text: <span><Icon name="id-card-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('id_card') }</span></span>,
            value: 'id-card-outline',
        },
        {
            key: 3,
            text: <span><Icon name="information-circle-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('information') }</span></span>,
            value: 'information-circle-outline',
        },
        {
            key: 4,
            text: <span><Icon name="briefcase-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('briefcase') }</span></span>,
            value: 'briefcase-outline',
        },
        {
            key: 5,
            text: <span><Icon name="calendar-clear-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('calendar') }</span></span>,
            value: 'calendar-clear-outline',
        },
        {
            key: 6,
            text: <span><Icon name="clipboard-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('clipboard') }</span></span>,
            value: 'clipboard-outline',
        },
        {
            key: 7,
            text: <span><Icon name="copy-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('copy') }</span></span>,
            value: 'copy-outline',
        },
        {
            key: 8,
            text: <span><Icon name="mail-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('mail') }</span></span>,
            value: 'mail-outline',
        },
        {
            key: 9,
            text: <span><Icon name="create-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('sign') }</span></span>,
            value: 'create-outline',
        },
        {
            key: 10,
            text: <span><Icon name="timer-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('timer') }</span></span>,
            value: 'timer-outline',
        },
        {
            key: 11,
            text: <span><Icon name="call-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('call') }</span></span>,
            value: 'call-outline',
        },
        {
            key: 12,
            text: <span><Icon name="person-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('person') }</span></span>,
            value: 'person-outline',
        },
        {
            key: 13,
            text: <span><Icon name="ribbon-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('ribbon') }</span></span>,
            value: 'ribbon-outline',
        },
        {
            key: 14,
            text: <span><Icon name="eye-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('eye') }</span></span>,
            value: 'eye-outline',
        },
        {
            key: 15,
            text: <span><Icon name="thumbs-up-outline" style={{ marginRight: "0.5rem" }}/> <span style={{ position: "relative", top: "-0.2rem" }}>{ t('thumbs_up') }</span></span>,
            value: 'thumbs-up-outline',
        },
    ]

    const [name, setName] = useState(process !== undefined ? process.name : "")  
    const [isProcessing, setIsProcessing] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [existingStages, setExistingStages] = useState([])
    const [stages, setStages] = useState(process !== undefined ? process.stages.map((item, index) => ({
        id: item.id,
        order: index + 1,
        name: item.name,
        icon: item.icon,
        color: item.color,
        is_approval_required: item.is_approval_required,
        resourcetype: "RecruitmentStage",
        error: null,
        processing: false
    })) : [])

    useEffect(() => { // sort array
        setStages(prevState => prevState.sort((a, b) => a.order > b.order ? 1 : -1))
    }, [stages])

    useEffect(() => {
        async function fetchExistingStages(){
            setFetching(true)
            const request = await getRequest(API.PROCESSES + "stages/")
            
            if( request.status === 200 ){
                setExistingStages(request.response)
            }
            setFetching(false)
        }

        fetchExistingStages()
    }, [])

    const movePosition = (oldPos, newPos) => {
        const oldPosition = stages.findIndex(item => item.order === oldPos)
        const newPosition = stages.findIndex(item => item.order === newPos)

        setStages(prevState => prevState.filter((item, index) => {
            if( index === oldPosition ){
                item.order = newPos
                console.log(item)
            }
            
            if( index === newPosition ){
                item.order = oldPos
                console.log(item)
            }
            return item
        }).sort((a, b) => a.order > b.order ? 1 : -1))
    
    }

    const handleSubmit = async () => {
        setIsProcessing(true)
        // handle stages processing
        for (let i = 0; i < stages.length; i++) {
            onItemChange(i, "processing", true)
            let request = undefined
            const dataset = {
                name: stages[i].name,
                icon: stages[i].icon,
                color: stages[i].color,
                is_approval_required: stages[i].is_approval_required,
                is_active: true,
                resourcetype: "RecruitmentStage"
            }

            if( stages[i].id === 0 ){ // create
                request = await createRequest(API.PROCESSES + "stages/", dataset)
            } else {  // update
                request = await updateRequest(API.PROCESSES + "stages/" + stages[i].id + "/", dataset)
            }

            if( request !== undefined ){
                if( request.status === 201 ){
                    onItemChange(i, "id", request.response.id)
                }
                
                if( request.status === 400 ){
                    onItemChange(i, "error", request.response)
                }
            }
            onItemChange(i, "processing", false)
        }
        
        const data = {
            name: name,
            process_type: 1,
            is_active: true
        }

        const stagesObjects = stages.filter(item => item.id > 0)
        const orderedStages = stagesObjects.filter((item, index) => {
            item.order = index + 1
            return item
        })

        let finalRequest = undefined
        if( process === undefined ){ // create
            finalRequest = await createRequest(API.PROCESSES, {
                ...data,
                stages: orderedStages.map(item => item.id)
            })
        } else { // update
            finalRequest = await updateRequest(API.PROCESSES + process.id + "/", {
                ...data,
                stages: orderedStages.map(item => item.id)
            })
        }

        if( finalRequest !== undefined ){
            if( finalRequest.status === 201 ){
                setProcesses(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        count: prevState.data.count + 1,
                        results: [finalRequest.response, ...prevState.data.results]
                    }
                }))
                onClose()
            } else if(finalRequest.status === 200){
                setProcesses(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        count: prevState.data.count + 1,
                        results: prevState.data.results.filter(item => {
                            if( item.id === finalRequest.response.id){
                                item.name = finalRequest.response.name
                                item.stages = finalRequest.response.stages
                            }

                            return item
                        })
                    }
                }))
                onClose()
            }
        }

        setIsProcessing(false)
    }

    const addStage = (stage) => {
        setStages(prevState => [...prevState, {
            id: stage !== undefined ? stage.id : 0,
            order: prevState.length + 1,
            name: stage !== undefined ? stage.name : "",
            icon: stage !== undefined ? stage.icon : "",
            color: stage !== undefined ? stage.color : "",
            is_approval_required: stage !== undefined ? stage.is_approval_required : false,
            resourcetype: "RecruitmentStage",
            error: null,
            processing: false
        }])
    }

    const onItemChange = (index, updatedKey, value) => {
        setStages(prevState => prevState.filter((record, key) => {
            if( index === key ){
                record[updatedKey] = value
            }

            return record
        }))
    }

    const onItemRemove = async (item, index) => {
        const prevStages = stages.filter((item, key) => key !== index)

        setStages(prevStages.filter((item, index) => {
            item.order = index + 1
            return item
        }))
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SuperField as="input" 
                autoFocus
                required
                label={t('name')}
                value={name}
                onChange={ (e, { value }) => setName(value) }
            />
            <Divider/>
            <Grid>
                <Grid.Row style={{ padding: "0" }} columns={2} verticalAlign='middle'>
                    <Grid.Column>
                        <Header as="h3" content={t('stages')}/>
                    </Grid.Column>
                    <Grid.Column style={{ textAlign: "right" }}>
                        <Button.Group size="small">
                            <Dropdown
                                // simple
                                loading={fetching}
                                disabled={fetching}
                                floating
                                button
                                scrolling
                                style={{ background: "var(--dark)", color: "var(--white)", marginRight: "0.5rem" }}
                                text={ t('Select from existing stages') }
                            >
                                <Dropdown.Menu>
                                    { existingStages.map(stage => (
                                        <>
                                            { stages.find(item => item.id === stage.id) === undefined && 
                                                <Dropdown.Item onClick={() => addStage(stage)}>
                                                    <SemanticIcon name={stage.icon} style={{ color: stage.color, marginRight: "1rem" }}/>
                                                        <span style={{ color: "var(--dark)" }}>{ stage.name }</span>
                                                </Dropdown.Item>
                                            }
                                        </>
                                    )) }
                                </Dropdown.Menu>
                            </Dropdown>
                       
                            <Button type="button" primary onClick={() => addStage()}>
                                { t('add_stage') }
                            </Button>
                        </Button.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider/>

            <Form.Group unstackable>
                <Form.Field width={1} style={{ fontWeight: "bold", textAlign: "center" }} content={""} />
                <Form.Field required width={5} style={{ fontWeight: "bold" }} content={t('name')} />
                <Form.Field width={3} style={{ fontWeight: "bold" }} content={t('icon')} />
                <Form.Field width={2} style={{ fontWeight: "bold", paddingLeft: "1rem" }} content={t('color')} />
                <Form.Field width={3} style={{ fontWeight: "bold", textAlign: "center" }}>
                    { t('require_approval')} 
                    <Popup
                        basic
                        trigger={
                            <Icon name="information-circle-outline" style={{ marginLeft: "0.5rem", fontSize: "1.2rem" }}/>
                        }
                        content={t('require_approval_info_tooltip')}
                    />
                </Form.Field>
                <Form.Field width={2} style={{ fontWeight: "bold" }} content={""} />
            </Form.Group>
            <EmptyRow length={stages.length}/>
            { stages.map((stage, index) => (
                <Form.Group verticalAlign="middle">
                    <Form.Field width={1} style={{ textAlign: "center" }}>
                        <Form.Group widths="equal">
                            <Form.Field style={{ marginTop: "0.7rem", padding: 0 }}>
                                { (stages.length > 2 && stage.order === 1) && <Icon style={{ cursor: "pointer", marginRight: 0 }} onClick={ () => movePosition(stage.order, stages.length) } name="chevron-down-circle-outline"/> }
                                { (stages.length > 2 && stage.order === stages.length) && <Icon style={{ cursor: "pointer", marginRight: 0 }} onClick={ () => movePosition(stage.order, 1) } name="chevron-up-circle-outline"/> }
                            </Form.Field>
                            <Form.Field style={{ padding: 0 }}>
                                <div> <Icon disabled={stage.order === 1} style={{ cursor: stage.order === 1 ? "normal" : "pointer" }} onClick={ () => movePosition(stage.order, (stage.order - 1)) } name="chevron-up-outline" title="Move up"/> </div>
                                <div> <Icon disabled={stage.order === stages.length} style={{ cursor: stage.order === stages.length ? "normal" : "pointer" }} name="chevron-down-outline" onClick={ () => movePosition(stage.order, (stage.order + 1)) }  title="Move down"/> </div>
                            </Form.Field>
                        </Form.Group>
                    </Form.Field>
                    <SuperField as="input"
                        width={5}
                        size="small"
                        value={stage.name}
                        onChange={ (e, { value }) => onItemChange(index, "name", value)}
                    />
                    <SuperField as="choice" 
                        width={3}
                        size="small"
                        customOptions={options}
                        value={stage.icon}
                        onChange={ (e, { value }) => onItemChange(index, "icon", value)}
                    />
                    <Form.Field width={2} size="small" style={{ marginLeft: "1rem" }}>
                        <ColorPickerField
                            color={stage.color}
                            setColor={ (value) => onItemChange(index, "color", value)}
                        />
                    </Form.Field>
                    <Form.Field size="small" width={3} style={{ textAlign: "center", marginTop: "1rem" }}>
                        <SuperField as="checkbox"
                            checked={stage.is_approval_required}
                            onChange={ () => onItemChange(index, "is_approval_required", !stage.is_approval_required)}
                        />
                    </Form.Field>
                    <Form.Field width={2} style={{ textAlign: "center", marginTop: "0.8rem" }}>
                        { stage.order === stages.length && 
                            <Popup
                                basic
                                trigger={
                                    <Icon name="information-circle-outline" style={{ marginRight: "0.5rem" }}/>
                                }
                                content={t('hired_stage_tooltip')}
                            />
                        }
                        <Popup
                            basic
                            position="top center"
                            trigger={
                                <Icon name="close-outline" onClick={ () => onItemRemove(stage, index) } style={{ color: "var(--danger)", cursor: "pointer" }}/>
                            }
                            content={t('remove_stage_tooltip')}
                        />
                    </Form.Field>
                </Form.Group>
            )) }
            <div style={{ height: "5rem", opacity: 0 }}></div>
            <Divider/>
            <Form.Field style={{ textAlign: "right" }}>
                <ModalCancel onClose={onClose}/>
                <ModalSubmit 
                    disabled={isProcessing || isEmpty(name) || stages.length === 0}
                    loading={isProcessing}
                    text={t('confirm')}
                />
            </Form.Field>
            
        </Form>
    );
};

export default HRProcessForm;