import React, { useState, useEffect } from 'react';
// import moment from 'moment';
import { useTranslation } from "react-i18next";
// store
import { icons } from '@icons';
import { API } from '@store/config';
import { useFetchData } from '@helpers/hooks';
import { getRequest, deleteRequest, updateRequest } from '@services/ServiceCommon';
import { getParams } from '@helpers/functions';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import BreadcrumbNav from '@components/BreadcrumbNav';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import { Container, Divider, Button, Grid, Segment } from 'semantic-ui-react';
// module specific Components
import HROrdersTable from '../components/tables/HROrdersTable';
import EmploymentOrdersTable from '../components/tables/EmploymentOrdersTable';
import CreateOrderForm from '../components/forms/CreateOrderForm';

const Orders = () => {
    const { t } = useTranslation();
    const [result, setResult] = useFetchData(API.ORDERS + "?paginate=true&resource_type=hrorder")
    const [view, setView] = useState(1)

    const previous = getParams(result?.data?.previous)
    const next = getParams(result?.data?.next)

    const onOrderConfirm = async (orderID) => {
        const request = await updateRequest(API.ORDERS + orderID + "/confirm/");
        if (request.status === 200) {
            setResult(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    results: prevState.data.results.filter((item) => {
                        if (item.id === orderID) {
                            item.confirmed_on = request.response.confirmed_on;
                        }
        
                        return item;
                    })
                }
            }));
        }
    }

    const onDelete = async (id) => {
        const request = await deleteRequest(API.ORDERS + id + "/",);

        if( request.status === 204 ){
            setResult(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    count: prevState.data.count - 1,
                    results: prevState.data.results.filter( order => order.id !== id )
                }
            }))
        }
    }

    const fetchOrders = async (params) => {
        if( !result.isLoading ){
            params = params || ""
            let viewType = ""
    
            setResult(prevState => ({
                ...prevState,
                isLoading: true
            }))
    
            if( view === 1 ) viewType = "hrorder"
            if( view === 2 ) viewType = "employmentorder"
            if( view === 3 ) viewType = "saleorder"
            const request = await getRequest(API.ORDERS + "?paginate=true&resource_type=" + viewType + params)
    
            if( request.status === 200 ){
                setResult(prevState => ({
                    ...prevState,
                    isLoading: false,
                    data: request.response
                }))
            }
        }
    }

    useEffect(() => {
        fetchOrders()
        // eslint-disable-next-line
    }, [view])

    return (
        <CanView permissions={['orders.c_view_order']} redirect>
            <BreadcrumbNav items={[
                { 'name': t('orders'), 'icon': icons.ORDERS, 'href': "" }
            ]}/>
            <Container fluid>
                <Grid>
                    <Grid.Row style={{ padding: 0 }}>
                        <Grid.Column computer={8} tablet={8} mobile={16} style={{ padding: 0 }}>
                            <Button.Group basic size='small' style={{ marginBottom: '0rem', borderRadius: 0 }}>
                                <Button style={{ fontWeight: view === 1 ? "bold" : "normal" }} active={view === 1} onClick={() => setView(1)}>{t('recruitment')}</Button>
                                <Button style={{ fontWeight: view === 2 ? "bold" : "normal" }} active={view === 2} onClick={() => setView(2)}>{t('employment')}</Button>
                                <Button disabled style={{ fontWeight: view === 3 ? "bold" : "normal" }} active={view === 3} onClick={() => setView(3)}>{t('sales')}</Button>
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column computer={8} tablet={8} mobile={16} textAlign="right" style={{ padding: 0 }}>
                            {/* <Button
                                size="small"
                                style={{ 
                                    background: "var(--dark)", 
                                    color: "var(--white)", 
                                    paddingLeft: "1rem", 
                                    paddingRight: "1rem" 
                                }}
                                onClick={() => setVisible(!visible)}
                            >
                                <Icon name='filter' style={{ margin: 0 }} />
                            </Button> */}
                            <CanView permissions={["orders.c_manage_order"]}>
                                <SuperDuperModal 
                                    size="small"
                                    trigger={
                                        <Button primary size="small">{ t('create_order') }</Button>
                                    }
                                    content={
                                        <CreateOrderForm 
                                            view={undefined}
                                            setView={setView}
                                            setResult={setResult}
                                        />
                                    }
                                />
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>

                <Segment loading={result.isLoading} style={{ padding: 0, margin: 0, minHeight: "2rem",backgroundColor: "transparent", border: "none", boxShadow: "none" }}>
                    { (view === 1 && !result.isLoading) && <HROrdersTable result={result} setResult={setResult} onDelete={onDelete} onOrderConfirm={onOrderConfirm}/> }
                    { (view === 2 && !result.isLoading) && <EmploymentOrdersTable result={result} setResult={setResult} onDelete={onDelete} onOrderConfirm={onOrderConfirm}/> }
                    {/* { (view === 3 && !result.isLoading) && <HROrdersTable result={result} setResult={setResult}/> } */}
                </Segment>
                

                {/* Pagination */}
                <FlexRow>
                    <FlexItem basis="50%">
                        <Button.Group>
                            <Button disabled={ previous === null } primary onClick={ previous !== null ? () => fetchOrders(previous) : null }> <Icon name="chevron-back-outline"/> </Button>
                            <Button disabled={ next === null } primary onClick={ next !== null ? () => fetchOrders(next) : null }> <Icon name="chevron-forward-outline"/> </Button>
                        </Button.Group>
                    </FlexItem>
                    <FlexItem basis="50%" textAlign="right">
                        { t('total_records') + ": " }{ result.isLoading ? 0 : result.data.count }
                    </FlexItem>
                </FlexRow>
            </Container>
        </CanView>
    );
};

export default Orders;