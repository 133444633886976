import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next"
// store
import { API } from '@store/config';
import { requests } from '@helpers/requests';
// components
import Loading from '@components/general/Loading';
import { Grid, Form, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import CanView from '@components/perms/CanView';

import MilestoneForm from '../../forms/MilestoneForm';
import ProjectMilestonesCard from './ProjectMilestonesCard';


const ProjectMilestonesTab = ({ project }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [milestones, setMilestones] = useState([])

    const initState = {
        search: "",
        status: "",
        is_active: "",
        from_date: "",
        to_date: "",
    }

    const activeOptions = [
        { key: 1, value: "&is_active=true", text: t('yes') },
        { key: 2, value: "&is_active=false", text: t('no') },
    ]

    const [filters, setFilters] = useState(initState)

    async function fetchData(params){
        params = params || ""
        let queryparams = ''
        if (filters.status !== '') queryparams += "&status=" + filters.status
        if ( filters.is_active !== "") params += filters.is_active
        if (filters.from_date !== '') queryparams += "&from_date=" + filters.from_date
        if (filters.to_date !== '') queryparams += "&due_date=" + filters.to_date
        if (filters.search !== '') queryparams += "&search=" + filters.search

        setIsLoading(true)
        const request = await requests.get(API.PROJECT_MILESTONES + `?project=${project.id}${params}${queryparams}`);

        if (request.status === 200) {
            setMilestones(request.response);
        }
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const onCreate = (response) => {
        setMilestones(prev => ([...prev, response]))
    }

    return (
        <>
        {isLoading ? <Loading /> :
        <>
        <Grid columns={2}>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Form style={{ marginTop: "1rem", paddingBottom: "0.5rem", float: 'right'}}>
                        <Form.Group>
                            <SuperField as="datepicker"
                                label={ t('date_from') }
                                value={filters.from_date}
                                onChange={ (e, { value }) => setFilters({...filters, from_date: value})}
                                />
                            <SuperField as="datepicker"
                                label={ t('date_to') }
                                value={filters.to_date}
                                onChange={ (e, { value }) => setFilters({...filters, to_date: value})}
                            />
                            <SuperField as="choice"
                                label={ t('is_active') }
                                value={filters.is_active}
                                onChange={(e, {value}) => setFilters({...filters, is_active: value})}
                                customOptions={activeOptions}
                            />
                            <SuperField as="choice"
                                type="milestone_statuses"
                                label={t('status')}
                                value={filters.status}
                                onChange={ (e, {value}) => setFilters({ ...filters, status: value }) }
                            />
                            <SuperField as="input"
                                icon="search"
                                iconPosition="left"
                                label={t('search')}
                                placeholder={t('search')}
                                value={filters.search}
                                onChange={(e, { value }) => setFilters({
                                    ...filters,
                                    search: value
                                })}
                            />

                            <Form.Field style={{ marginTop: '1.8rem' }}>
                                <Button loading={isLoading} disabled={isLoading} size="small" content={t('filter')} primary onClick={ () => {fetchData()}} />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
                <Grid.Column width={2}>
                    <CanView permissions={['projects.c_manage_milestones']}>
                        <SuperDuperModal
                            header={t('add_milestone')}
                            trigger={
                                <Button size="small" style={{marginTop: '2.8rem', textAlign: 'right'}} primary>{t('add_milestone')}</Button>
                            }
                            content={
                                <MilestoneForm project={project} onConfirm={(response, type) => onCreate(response, type)} />
                            }
                        />
                    </CanView>
                </Grid.Column>

            </Grid.Row>
        </Grid>
            <Grid stretched style={{ margin: "1rem" }} centered>
                {milestones?.length > 0
                    ?
                    milestones?.map((milestone, index) =>
                        <Grid.Column mobile={16} tablet={8} computer={5} key={index}>
                            <ProjectMilestonesCard
                               milestone={milestone}
                               setMilestones={setMilestones}
                               milestones={milestones}
                               project={project}
                               />
                        </Grid.Column>
                    )
                    :
                    <div>
                        <span>{t("nothing")}</span>
                    </div>
                }
            </Grid>
        </>
        }
        </>
    )
}

export default ProjectMilestonesTab;



