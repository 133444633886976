import React from 'react';

const SaleOrderForm = () => {
    return (
        <div>
            Hello from SaleOrderForm
        </div>
    );
};

export default SaleOrderForm;