import { APIRequest } from './helpers/requests';
import { API } from "../config";

/*
 * Service functions
 */
export const candidateService = {
   getCandidates,
   moveStage
}

async function getCandidates(id, params){
    params= params || ""
    return await APIRequest({
        url:  API.CANDIDATES + id +'/'+ params, 
        method: "GET",
        data: {},
        private: true
    })
}

async function moveStage(id, data){
    
    return await APIRequest({
        url:  API.CANDIDATES + id +'/move_stage/', 
        method: "POST",
        data: data,
        private: true
    })
}