import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
// store 
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
//compponents
import AvatarIcon from '@components/AvatarIcon';
import { Divider, Grid, Header, Icon, Label, Sticky, Segment } from 'semantic-ui-react'


const ProfileCard = ({ candidateData, candidateID }) => {
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect( () => {
        async function fetchData(){
            setIsLoading(true)
            if( candidateID !== undefined ){
                const request = await getRequest(API.CANDIDATES + candidateID + "/")
                if( request.status === 200 ){
                    setData(request.response)
                }
            } else {
                setData(candidateData)
            }
            setIsLoading(false)
        }

        fetchData()
        // eslint-disable-next-line
    }, [])


    const { t } = useTranslation();
    const genders = useSelector(state => state.choices?.genders)
    const educationTypes = useSelector(state => state.choices?.education_type_choices)
    const languages = useSelector(state => state.choices?.language_choices)
    const languageLevels = useSelector(state => state.choices?.language_level_choices)

    const EducationLine = ({ line }) => {
        return (
            <Fragment>
                <Header as='h4' content={line.name} style={{ marginBottom: 0, marginTop: '1rem' }} />
                <Header.Subheader content={educationTypes?.[line.education_type]} />
                <Divider />
            </Fragment>
        );
    }

    const ExperienceLine = ({ line }) => {
        return (
            <Fragment>
                <Header as='h4' content={line?.job_title} style={{ marginBottom: 0, marginTop: '1rem' }} />
                { line?.description !== "" && <Header.Subheader content={line?.description} /> }
                <Header.Subheader content={line?.employer} />
                <Divider />
            </Fragment>
        );
    }

    const InterestsLine = ({ line }) => {
        return (
            <>
                {line?.name + ', '}
            </>
        );
    }

    const SkillsLine = ({ line }) => {
        return (
            <Label content={line?.name} style={{ marginBottom: '0.3rem' }} />
        );
    }

    const LanguagesLine = ({ line }) => {
        return (
            <Label content={languages?.[line?.name]} detail={languageLevels?.[line?.level]} style={{ marginBottom: '0.3rem' }} />
        );
    }

    const IconLine = ({ iconName, line }) => {
        return (
            <Grid style={{ paddingBottom: 0 }}>
                <Grid.Row style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Icon name={iconName} style={{ color: "var(--primary)" }} />
                    <div style={{ paddingLeft: "1rem" }}>
                        {line}
                    </div>
                </Grid.Row>
            </Grid>
        );
    }

    return (
        <Segment loading={isLoading} style={{ 
            padding: 0, 
            background: "transparent", 
            boxShadow: "none", 
            border: "none",
            marginBottom: "1rem"
        }}>
        { !isLoading && 
            <Grid columns={2}>
                <Grid.Column textAlign="center" width="6">
                    <Sticky>
                        <AvatarIcon size={100} name={data.personal_information?.person?.firstname + ' ' + data.personal_information?.person?.lastname} />
                        <Header
                            as="h2"
                            content={
                                data.personal_information?.person?.firstname + ' ' +
                                data.personal_information?.person?.lastname
                            }
                            style={{ marginBottom: 0 }}
                        />
                        <Header.Subheader content={data.personal_information?.personal_number} />
                        <Divider />
                        <IconLine iconName='user' line={genders[data.personal_information?.person.gender]} />
                        <IconLine iconName='birthday' line={data.personal_information?.birth_info?.date_of_birth} />
                        {
                            candidateID !== undefined
                                ? <IconLine iconName='mail' line={data.personal_information?.contact_info?.emails[0]?.email} />
                                : <IconLine iconName='mail' line={data.personal_information?.contact_info?.emails?.create[0]?.email} />
                        }
                        {
                            candidateID !== undefined
                                ? <IconLine iconName='phone' line={data.personal_information?.contact_info?.phones[0]?.phone} />
                                : <IconLine iconName='phone' line={data.personal_information?.contact_info?.phones?.create[0]?.phone} />
                        }
                        <IconLine iconName='home' line={data.personal_information?.permanent_address?.city + ', ' + data.personal_information?.permanent_address?.country} />
                        <IconLine iconName='map marker alternate' line={data.personal_information?.temporary_address?.city + ', ' + data.personal_information?.temporary_address?.country} />
                    </Sticky>
                </Grid.Column>
                <Grid.Column width="10" style={{ paddingLeft: "5rem" }}>
                    <Header as="h3" content={t('education')} style={{ color: "var(--primary)" }} />
                    {
                        candidateID === undefined
                            ?
                            data?.educations?.create?.length > 0 ?
                                data?.educations?.create?.map((line, i) => (
                                    <EducationLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                            :
                            data?.educations?.length > 0 ?
                                data?.educations?.map((line, i) => (
                                    <EducationLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                    }
                    <Header as="h3" content={t('experiences')} style={{ color: "var(--primary)" }} />
                    {
                        candidateID === undefined
                            ?
                            data?.experiences?.create?.length > 0
                                ?
                                data?.experiences?.create?.map((line, i) => (
                                    <ExperienceLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                            :
                            data?.experiences?.length > 0
                                ?
                                data?.experiences?.map((line, i) => (
                                    <ExperienceLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                    }
                    <Header as="h3" content={t('languages')} style={{ color: "var(--primary)" }} />
                    {
                        candidateID === undefined
                            ?
                            data?.languages?.create?.length > 0 ?
                                data?.languages?.create?.map((line, i) => (
                                    <LanguagesLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                            :
                            data?.languages?.length > 0 ?
                                data?.languages?.map((line, i) => (
                                    <LanguagesLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                    }
                    <Header as="h3" content={t('interests')} style={{ color: "var(--primary)" }} />
                    {
                        candidateID === undefined
                            ?
                            data?.interests?.create?.length > 0 ?
                                data?.interests?.create?.map((line, i) => (
                                    <InterestsLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                            :
                            data?.interests?.length > 0 ?
                                data?.interests?.map((line, i) => (
                                    <InterestsLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                    }
                    <Header as="h3" content={t('skills')} style={{ color: "var(--primary)" }} />
                    {
                        candidateID === undefined
                            ?
                            data?.skills?.create?.length > 0 ?
                                data?.skills?.create?.map((line, i) => (
                                    <SkillsLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                            :
                            data?.skills?.length > 0 ?
                                data?.skills?.map((line, i) => (
                                    <SkillsLine key={i} line={line} />
                                ))
                                : t('data_not_provided')
                    }
                </Grid.Column>
            </Grid>
        }
        </Segment>
    );
};

export default ProfileCard;