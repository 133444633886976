import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { useSelectOptions } from '@helpers/hooks';
import { getRequest, createRequest, updateRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import { Button, Divider, Form, Header } from 'semantic-ui-react';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperField from '@components/forms/SuperField';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import ModalCancel from '@components/buttons/ModalCancel';
import ModalSubmit from '@components/buttons/ModalSubmit';
// module specific comps
import FormPreview from './FormPreview';

const FormView = ({ selected, setSelected, applyForm, setApplyForm, result, setResult }) => {
    const { t } = useTranslation();

    const stepBack = () => {
        setApplyForm({})
        setSelected([])
    }

    const handleSave = async (name, is_archived, gdpr, additional_gdprs, applyForm, setIsProcessing, onClose) => {
        setIsProcessing(true)

        let data = {
            name: name,
            fields: null,
            gdpr: gdpr,
            additional_gdprs: {
                add: additional_gdprs
            },
            // attachments: null,
            is_archived: is_archived
        }

        if( applyForm !== undefined && applyForm?.id > 0 ){
            data['additional_gdprs'] = {
                remove: applyForm?.additional_gdprs ? applyForm.additional_gdprs.map(item => item.id) : [],
                add: data.additional_gdprs.add
            }
        }

        let fieldsToRemove = []
        let fieldsToAdd = []
        let fieldsToCreate = []

        if( applyForm?.fields?.length > 0 ){
            const fields = applyForm.fields;
            for (let i = 0; i < fields.length; i++) {
                fieldsToRemove.push(fields[i].id)

                if( selected.includes(fields[i].name) ){
                    fieldsToAdd.push(fields[i].id)
                }
            }
        }

        // Create those that does not exists yet
        const getFieldsRequest = await getRequest(API.APPLICATION + "field_configs/")
        if( getFieldsRequest.status === 200){
            const existingFieldsFromDB = getFieldsRequest.response;
            for (let i = 0; i < selected.length; i++) {
                const fieldFromDB = existingFieldsFromDB.find( field => field.name === selected[i] )
                if( fieldFromDB === undefined ){
                    fieldsToCreate.push({
                        name: selected[i]
                    })
                } else {
                    if (fieldsToAdd.indexOf(fieldFromDB.id) === -1) {
                        fieldsToAdd.push(fieldFromDB.id)
                    }
                }
            }
        }

        data['fields'] = {
            add: fieldsToAdd,
            create: fieldsToCreate
        }

        if( fieldsToRemove.length > 0 ){
            data['fields'] = {
                remove: fieldsToRemove,
                ...data.fields
            }
        }

        let request = null
        if( applyForm?.id === 0 ){ // create
            request = await createRequest(API.APPLICATION + "form_configs/", data)
        } else {
            request = await updateRequest(API.APPLICATION + "form_configs/" + applyForm.id + "/", data)
        }

        if( request.status === 200 || request.status === 201){
            // handle success
            onClose()

            if( applyForm.id === 0 ){
                setResult({
                    ...result,
                    data: [...result.data, request.response]
                })
            } else {
                setResult({
                    ...result,
                    data: result.data.filter( item => {
                        if( item.id === request.response.id ){
                            item.name = request.response.name
                            item.is_archived = request.response.is_archived
                            item.fields = request.response.fields
                            item.attachments = request.response.attachments
                        }

                        return item
                    })
                })
            }

            setSelected([])
            setApplyForm({})
        } else {
            // handle error
        }

        setIsProcessing(false)
    }

    const TemplateForm = ({ onClose, applyForm }) => {
        const [gdprs] = useSelectOptions(API.GDPRS, "title")
        const [formData, setFormData] = useState({
            name: applyForm?.name ? applyForm.name : "",
            gdpr: applyForm?.gdpr ? applyForm.gdpr.id : "",
            additional_gdprs: applyForm?.additional_gdprs ? applyForm.additional_gdprs.map(item => item.id) : [],
            is_archived: applyForm?.is_archived ? applyForm.is_archived : false,
        })

        const [isProcessing, setIsProcessing] = useState(false);

        return (
            <>
            { selected.length > 0
                ?
                    <Form onSubmit={ () => handleSave(formData.name, formData.is_archived, formData.gdpr, formData.additional_gdprs, applyForm, setIsProcessing, onClose) }>
                        <SuperField as="input"
                            autoFocus
                            fluid
                            required
                            label={ t('template_name') }
                            value={formData.name}
                            onChange={(e) => setFormData({
                                ...formData,
                                name: e.target.value
                            })}
                        />

                        <SuperField as="choice"
                            fluid
                            required
                            search
                            customOptions={gdprs.options}
                            loading={gdprs.isLoading}
                            disabled={gdprs.isLoading}
                            label={ t('main_gdpr') }
                            value={formData.gdpr}
                            onChange={(e, { value }) => setFormData({
                                ...formData,
                                gdpr: value
                            })}
                        />

                        <SuperField as="choice"
                            fluid
                            search
                            multiple
                            customOptions={gdprs.options}
                            loading={gdprs.isLoading}
                            disabled={gdprs.isLoading}
                            label={ t('additional_gdprs') }
                            value={formData.additional_gdprs}
                            onChange={(e, { value }) => setFormData({
                                ...formData,
                                additional_gdprs: value
                            })}
                        />


                        {/* <SuperField as="checkbox"
                            label={ t('is_archived') }
                            checked={formData.is_archived}
                            onChange={(e) => setFormData({
                                ...formData,
                                is_archived: !formData.is_archived
                            })}
                        /> */}
                        <Divider/>
                        <Form.Field style={{ textAlign: "right"}}>
                            <ModalCancel onClose={onClose} disabled={isProcessing} />
                            <ModalSubmit
                                disabled={isProcessing || formData.name === "" || formData.gdpr === "" }
                                loading={isProcessing}
                                text={t('save')}
                            />
                        </Form.Field>
                    </Form>
                : <Header as="h3" textAlign="center"> { t('select_data_source') } </Header>
            }
            </>
        )
    }

    return (
        <>
            <FlexRow padding="0">
                <FlexItem>
                    <h3>
                        <Icon
                            name="arrow-back-outline"
                            onClick={ () => stepBack()}
                            style={{ marginRight: "2rem", cursor: "pointer" }}
                        />
                        <span style={{ position: "relative", top: "-0.2rem" }}>
                            { t('form_preview') }
                        </span>
                    </h3>
                </FlexItem>
                <FlexItem>
                    <SuperDuperModal
                        trigger={
                            <Button primary floated="right">
                                { t('save_template') }
                            </Button>
                        }
                        content={
                            <TemplateForm applyForm={applyForm}/>
                        }
                    />
                </FlexItem>
            </FlexRow>
            <Divider/>

            <FormPreview isTemplate selected={selected}/>
        </>
    );
};

export default FormView;