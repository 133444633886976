import React from 'react';
import { useTranslation } from 'react-i18next';
// components
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import Action from '@components/general/Action';

const InfoRow = ({ text, content, isVisible, allowEdit, modal }) => {
    const { t } = useTranslation();
    
    return (
        <>
            { isVisible && 
                <FlexRow background="transparent" padding="0.5rem">
                    <FlexItem basis="60%"> { text } </FlexItem>
                    <FlexItem bold> 
                        { content } 

                        { allowEdit && 
                            <span style={{ marginLeft: "1rem" }}>
                                <Action as="modal" type="icon" modalSize="tiny" icon="pencil" tooltip={t('edit')}
                                    modal={modal}
                                />
                            </span>
                        }
                    </FlexItem>
                </FlexRow>
            }
        </>
    )
}

export default InfoRow;