import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
// store
import { unitService } from '@store/services/ServiceUnit';
// components
import { Grid } from 'semantic-ui-react';
import Loading from '@components/general/Loading';
import TreeGraph from '@components/general/TreeGraph'


const UnitDetailUnits = () => {
    const params = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [unitData, setUnitData] = useState({});

    async function fetchData() { 
        setIsLoading(true);
        const result = await unitService.getUnitInTree(params.unit_id);
        
        if (result.status === 200) {
            setUnitData(result.response);     
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [params.unit_id]);
   
    return (
        <Grid centered divided="vertically" style={styles.container}>
            { isLoading ? <Loading /> :
                <Grid.Row style={styles.tree}>
                    {unitData
                        ? <TreeGraph data={unitData} rerender={fetchData} />
                        : <div style={styles.warning}>Unit has no child unit yet.</div>
                    }
                </Grid.Row>
            }
        </Grid>

    )
}
const styles = {
    container: { padding: "2rem" },
    warning: { fontSize: 20, }
}

export default UnitDetailUnits
