import React from 'react';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
// store 
import { routes } from '@routes';
// components
import SupplierList from './views/SupplierList';
import SupplierDetail from './views/SupplierDetail';

const Suppliers = () => {
    return (
        <Switch>
            <Route exact path={routes.SUPPLIERS} component={SupplierList} />
            <Route exact path={routes.SUPPLIERS + ':id'} component={SupplierDetail} />
        </Switch>
    );
};

export default withRouter(Suppliers);