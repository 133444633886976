import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// store
import { icons } from '@icons';
import { routes } from '@routes';
// components
import Icon from '@components/Icon';
import CanView from '@components/perms/CanView';
import { Button, Container, Divider, Grid } from 'semantic-ui-react';
import BreadcrumbNav from '@components/BreadcrumbNav';
import Action from '@components/general/Action';
// module specific components
import DayOverview from './views/DayOverview';
import ManageEmployee from './views/ManageEmployee';
import ImportAttendance from './components/ImportAttendance';
// import ManageGroups from './views/ManageGroups';

const Attendance = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [view, setView] = useState(() => {
        let current_view = 1
        if (window.location.pathname === routes.ATTENDANCE + "employee") current_view = 2
        if (window.location.pathname === routes.ATTENDANCE + "groups") current_view = 3

        return current_view
    })

    const setViewType = (to, view) => {
        setView(view)
        history.push(to)
    }

    return (
        <CanView permissions={['attendance.c_view_all_records', 'attendance.c_view_user_records']} redirect>
            <BreadcrumbNav
                style={{ marginBottom: 0 }}
                items={[
                    { 'name': t("attendance"), 'icon': icons.ATTENDANCE, 'href': '' }
                ]}
            />
            <Container fluid>
                <Grid>
                    <Grid.Row style={{ padding: 0 }} stackable columns={2}>
                        <Grid.Column style={{ padding: 0 }}>
                            <Button.Group basic size="small" style={{ borderRadius: 0 }}>
                                <Button active={view === 1} style={{ fontWeight: view === 1 ? 'bold' : 'normal' }} content={t('day_overview')} onClick={() => setViewType(routes.ATTENDANCE, 1)}/>
                                <CanView permissions={['attendance.c_manage_all_records']}>
                                    <Button active={view === 2} style={{ fontWeight: view === 2 ? 'bold' : 'normal' }} content={t('manage_employee')} onClick={() => setViewType(routes.ATTENDANCE + "employee", 2)}/>
                                    {/* <Button active={view === 3} style={{ fontWeight: view === 3 ? 'bold' : 'normal' }} content={t('manage_groups')} onClick={() => setViewType(routes.ATTENDANCE + "groups", 3)}/> */}
                                </CanView>
                            </Button.Group>
                        </Grid.Column>
                        <Grid.Column style={{ textAlign: 'right', padding: 0 }}>
                            <CanView permissions={['attendance.c_manage_all_records']}>
                                <Action as='modal' type='button' primary
                                    header={t('import_attendance')}
                                    buttonText={
                                        <>
                                            <Icon name="cloud-upload-outline" style={{ marginRight: "0.5rem" }}/> 
                                            <span style={{ position: "relative", top: "-0.1rem" }}>
                                                {t('import_attendance')}
                                            </span>
                                        </>
                                    }
                                    modal={
                                        <ImportAttendance/>
                                    }
                                />
                            </CanView>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Divider/>
                <Switch>
                    <Route exact path={routes.ATTENDANCE} component={DayOverview}/>
                    <CanView permissions={['attendance.c_manage_all_records']}>
                        <Route exact path={routes.ATTENDANCE + "employee"} component={ManageEmployee}/>
                        {/* <Route exact path={routes.ATTENDANCE + "groups"} component={ManageGroups}/> */}
                    </CanView>
                </Switch>
            </Container>
        </CanView>
    );
};

export default Attendance;