import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { icons } from '@store/icons';
import { routes } from '@routes';
import { useQueryPage } from '@helpers/hooks';
import { getRequest, deleteRequest, patchRequest } from '@services/ServiceCommon';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import Loading from '@components/general/Loading';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Button, Segment, Sidebar, Grid, Divider, Container } from 'semantic-ui-react';
// module components
import BreadcrumbNav from '@components/BreadcrumbNav';
import CandidatesTable from '../components/tables/CandidatesTable';
import CandidateForm from '../components/forms/CandidateForm';
import CandidateFilters from './CandidateFilters';
import CanView from '@components/perms/CanView';

const CandidatesList = () => {
    const { t } = useTranslation();
    const [candidates, setCandidates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [limit, setLimit] = useState(10);
    const [recordCount, setRecordCount] = useState(0);
    const page = useQueryPage()
    const history = useHistory()

    const breadcrumb_items = [
        { 'name': t('candidates'), 'icon': icons.CANDIDATES, 'href': '' }
    ]

    const onDelete = async (id) => {
        const request = await deleteRequest(API.CANDIDATES + id + "/");

        if (request.status === 204) {
            setCandidates(prevState => prevState.filter(item => item.id !== id))
        }
    }

    const fetchCandidates = async (params) => {
        setIsLoading(true);
        params = params || "";

        if (initialLoad) { // to prevent appending &page=X on each pagination call (when changing pages)
            params += "&page=" + page
            setInitialLoad(false)
        }

        if( !params.includes('limit')){
            params += "&limit=" + limit
        }

        const request = await getRequest(API.CANDIDATES + "?query={id,fullname,profile_picture,skills,cv,contact_labels,personal_information{person,contact_info}}&paginate=true" + params)
        if (request.status === 200) {
            setCandidates(request.response.results.map(candidate => {
                return {
                    id: candidate.id,
                    fullname: candidate.fullname,
                    gender: candidate?.personal_information?.person?.gender_display,
                    avatar: candidate?.profile_picture,
                    phone: candidate?.personal_information?.contact_info?.phones[0]?.phone,
                    email: candidate?.personal_information?.contact_info?.emails[0]?.email,
                    cv: candidate?.cv,
                    labels: candidate?.contact_labels,
                    skills: candidate?.skills
                }
            }));
            setRecordCount(request.response.count)
        }

        setIsLoading(false);
    }

    const onLabelsAdd = (id, labels) => {
        setCandidates(prevState => prevState.filter(candidate => {
            if (candidate.id === id) {
                candidate.labels = labels
            }
            return candidate;
        }))
    }

    const onLabelRemove = async (id, labelID) => {
        const request = await patchRequest(API.CANDIDATES + id + "/?query={id, contact_labels}", {
            contact_labels: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setCandidates(prevState => prevState.filter(candidate => {
                if (candidate.id === id) {
                    candidate.labels = request.response.contact_labels
                }
                return candidate;
            }))
        }
    }



    useEffect(() => {
        fetchCandidates()
        // eslint-disable-next-line
    }, [])

    return (
        <CanView permissions={["common.c_view_all_candidates", "common.c_view_user_profile"]} redirect>
            <BreadcrumbNav items={breadcrumb_items} />
            <Container fluid style={{ marginBottom: "5rem" }}>
                <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                    <CandidateFilters
                        visible={visible}
                        setVisible={setVisible}
                        setCandidates={setCandidates}
                        setIsLoading={setIsLoading}
                        fetchCandidates={fetchCandidates}
                    />
                    <Sidebar.Pusher>
                        <Segment basic style={{ padding: 0, margin: 0, background: "transparent", border: "none", boxShadow: "none" }}>
                            <Grid>
                                <Grid.Row columns="2">
                                    <Grid.Column />
                                    <Grid.Column textAlign="right" style={{ paddingLeft: 0 }}>
                                        <Button
                                            icon={<Icon name="funnel-outline"/>}
                                            style={{ 
                                                background: "transparent",
                                                paddingLeft: "1rem",
                                                paddingRight: "1rem"
                                            }}
                                            onClick={() => setVisible(!visible)}
                                        />
                                        <CanView permissions={["common.c_manage_all_candidates"]}>
                                            <SuperDuperModal
                                                size="small"
                                                trigger={
                                                    <Button
                                                        primary
                                                        content={t('add_candidate')}
                                                        size="small"
                                                    />
                                                }
                                                content={
                                                    <CandidateForm onResponse={(response) => history.push(routes.CANDIDATES_CARD + response.id)
                                                    }/>
                                                }
                                            />
                                        </CanView>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Divider />
                            {isLoading ? <Loading /> :
                                <CandidatesTable
                                    candidates={candidates}
                                    setCandidates={setCandidates}
                                    onDelete={onDelete}
                                    onLabelsAdd={onLabelsAdd}
                                    onLabelRemove={onLabelRemove}
                                />
                            }
                            <Paginator
                                forcePage={page}
                                limit={limit}
                                setLimit={setLimit}
                                length={recordCount}
                                onChange={(params) => fetchCandidates(params)}
                            />

                        </Segment>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>
            </Container>
        </CanView>
    );
};

export default CandidatesList;