import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next"
// store
import { candidatesService } from '@services/ServiceCandidates'
// components
import RoomCards from './RoomCards'
import ModalCancel from '@components/buttons/ModalCancel'
import { Button, Divider, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'

const AssignToHiringForm = ({candidate, onClose}) => {
    const { t } = useTranslation();

    const [hiringRoom, setHiringRoom] = useState("")
    const [hiringRoomsIn, setHiringRoomsIn] = useState([])
    const [allHiringRooms, setAllHiringRooms] = useState([])
    const [hiringRoomsAvailable, setHiringRoomsAvailable] = useState([])

    // form states
    const [errors, setErrors] = useState([])
    const [isFinished, setIsFinished] = useState(false)
    // eslint-disable-next-line
    const [isProcessing, setIsProcessing] = useState(false)
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        async function fetchData() {
            const result = await candidatesService.getHiringRooms("?is_active=true&query={id, name}")
            if(result.status === 200) {
                setAllHiringRooms(result.response)
            } 

            const resultFiltered = await candidatesService.getHiringRooms("?is_active=true&query={id, name}&exclude_by_candidate=" + candidate)
            if(resultFiltered.status === 200) {
                setHiringRoomsAvailable(resultFiltered.response)
            }

            setLoading(false)
        }
        fetchData();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let arr = []
        for(let x=0; x < allHiringRooms.length; x++){
            const found = hiringRoomsAvailable.find(item => item.id === allHiringRooms[x].id);
            if(found === undefined){
                arr.push(allHiringRooms[x])
            }
        }
        setHiringRoomsIn(arr)
    }, [hiringRoomsAvailable, allHiringRooms])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setErrors([])
        setIsProcessing(true);

        const result = await candidatesService.assignCandidate(candidate, hiringRoom)

        if(result.status === 200){
            setIsFinished(true)
            setHiringRoom("")
        } else {
            if( result.response.detail ){
                setErrors([...errors, result.response.detail])
            }
        }

        setIsProcessing(false)
    }

    const addCandidate = async (id, data) => {
        setErrors([])
        const result = await candidatesService.addCandidateToHiringRoom(id, data);

        if (result.status === 200) {
                const found = hiringRoomsAvailable.find(item => item.id === data.recruitment);
                setHiringRoomsIn([...hiringRoomsIn, found])
                setHiringRoomsAvailable(prevState => prevState.filter(item => item.id !== data.recruitment))
        } else {
            if( result.response.detail ){
                setErrors([...errors, result.response.detail])
            }
        }
    }

    return (
        <Segment 
            loading={loading} 
            style={{ 
                padding: 0,
                margin: 0,
                background: "transparent",
                border: "none",
                boxShadow: "none"
            }}>
            { !loading && 
                <>
                    { isFinished
                    ?
                        <Grid centered>
                            <Header as="h3" content={t("assign_to_hiring_room_success_message")}/>
                            <Button primary onClick={ () => setIsFinished(false) }>{t("assign_another_hiring_room")}</Button>
                        </Grid>
                    :
                        <Form onSubmit={(e) => handleSubmit(e)}>
                            <Message
                                error
                                visible={errors.length > 0}
                                header={t("error_submission_message")}
                                list={errors}
                            />

                            <h3>{t("assign_to_hiring_room")}</h3>
                            <Divider/>
                            {hiringRoomsAvailable.length > 0 &&
                                hiringRoomsAvailable.map((item, i) =>
                                    <RoomCards
                                        name={item.name}
                                        id={item.id}
                                        candidateId={candidate}
                                        addCandidate={addCandidate}
                                        in={false}
                                        color="black"
                                    />
                                )
                            }
                            <Divider style={{ marginTop: "2rem", marginBottom: "2rem" }} horizontal >{t("allready_assigned")}</Divider>
                            {hiringRoomsIn.length > 0 &&
                                hiringRoomsIn.map((item, i) =>
                                    <RoomCards
                                        name={item.name}
                                        id={item.id}
                                        in={true}
                                        color="grey"
                                    />
                                )
                            }
                            <Divider/>
                            <Form.Field style={{ textAlign: "right"}}>
                                <ModalCancel onClose={onClose} />
                            </Form.Field>
                        </Form>
                    }
                </>
            }
        </Segment>
    );
};

export default AssignToHiringForm;