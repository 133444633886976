import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
// store
import { API } from '@store/config';
import { getRequest } from '@services/ServiceCommon';
import { useHasPermissions, useQueryPage, useIsMount } from '@helpers/hooks';
// components
import Icon from '@components/Icon';
import Paginator from '@components/Paginator';
import CanView from '@components/perms/CanView';
import Loading from '@components/general/Loading';
import BreadcrumbNav from '@components/BreadcrumbNav';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import { Container, Sidebar, Segment, Menu, Button, Input, Ref } from 'semantic-ui-react';
// module specific
import ContractList from './ContractList';
import TerminationList from './TerminationList';
import ContractForm from '../components/ContractForm';
import ContractFilterForm from '../components/ContractFilterForm'
import ContractTerminationFilterForm from '../components/ContractTerminationFilterForm'

const ContractListView = () => {
    const { t } = useTranslation();
    const history = useHistory()
    const isMount = useIsMount();
    const segmentRef = useRef()
    let page = useQueryPage()

    const [limit, setLimit] = useState(10)
    const [isLoading, setIsLoading] = useState(true)
    const location = useLocation()
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState([])
    const [datas, setDatas] = useState([])
    const [view, setView] = useState(0) // 0 as Contracts by default
    const isManageable = useHasPermissions(['contracts.c_manage_all_contracts']);

    const terminationsInitState = {
        employee: "",
        type: "",
        noticePeriodAfter: "",
        noticePeroidBefore: "",
        terminatedBy: ""
    }
    const [terminationsFilters, setTerminationsFilters] = useState(terminationsInitState)

    const contractsInitState = {
        status: "",
        employee: "",
        type: "",
        validUntilAfter: "",
        validUntilBefore: "",
        validFromAfter: "",
        validFromBefore: "",
        trialPeriodAfter: "",
        trialPeriodBefore: "",
        workPosition: "",
        isValidUntilNone: ""
    }
    const [contractsFilters, setContractsFilters] = useState(contractsInitState)

    const setParams = () => {
        let params = "?paginate=true&search=" + (search || "")
        if (view === 1) {
            if( terminationsFilters.type !== "") params += `&type=${terminationsFilters.type}`
            if( terminationsFilters.employee !== "") params += `&employee=${terminationsFilters.employee}`
            if( terminationsFilters.noticePeriodAfter !== "") params += `&notice_period_after=${terminationsFilters.noticePeriodAfter}`
            if( terminationsFilters.noticePeroidBefore !== "") params += `&notice_period_before=${terminationsFilters.noticePeroidBefore}`
            if( terminationsFilters.terminatedBy !== "") params += `&valid_from_before=${terminationsFilters.terminatedBy}`
        }
        else {
            if( contractsFilters.type !== "") params += `&type=${contractsFilters.type}`
            // if( contractsFilters.project !== "") params += `&project=${contractsFilters.project}`
            // if( contractsFilters.team !== "") params += `&team=${contractsFilters.team}`
            if( contractsFilters.employee !== "") params += `&employee=${contractsFilters.employee}`
            if( contractsFilters.workPosition !== "") params += `&work_position=${contractsFilters.workPosition}`
            if( contractsFilters.status !== "") params += contractsFilters.status
            if( contractsFilters.validUntilBefore !== "") params += `&valid_until_before=${contractsFilters.validUntilBefore}`
            if( contractsFilters.validUntilAfter !== "") params += `&valid_until_after=${contractsFilters.validUntilAfter}`
            if( contractsFilters.validFromBefore !== "") params += `&valid_from_before=${contractsFilters.validFromBefore}`
            if( contractsFilters.validFromAfter !== "") params += `&valid_from_after=${contractsFilters.validFromAfter}`
            if( contractsFilters.trialPeriodBefore !== "") params += `&trial_period_before=${contractsFilters.trialPeriodBefore}`
            if( contractsFilters.trialPeriodAfter !== "") params += `&trial_period_after=${contractsFilters.trialPeriodAfter}`
            if( contractsFilters.isValidUntilNone !== "") params += `&is_valid_until_none=${contractsFilters.isValidUntilNone}`

        }
        return params
    }

    const fetchDatas = async (params_arg, isFiltered) => {
        setIsLoading(true)
        let params = setParams()
        params += params_arg || ""
        if (isFiltered) {
            page = 1
        } else {
            let searchParams = new URLSearchParams(location.search)
            const param = searchParams.get('page')
            page = (param !== null ? param : 1)
        }

        if ( !params.includes('page') ) {
            params += "&page=" + page
        }

        if ( !params.includes('limit')){
            params += "&limit=" + limit
        }

        const endpoint = (view === 0) ? API.CONTRACTS_INSTANCES : API.CONTRACTS_TERMINATIONS
        const request = await getRequest(endpoint + params)

        if (request.status === 200) {
            setDatas({
                ...datas,
                data: request.response
            })
        }
        setIsLoading(false)

        }

    useEffect(() => {
        fetchDatas()
        // eslint-disable-next-line
    }, [search])

    useEffect(() => {
        async function fetchOnViewChange(){
            if(!isMount && !isLoading){
                history.replace({ pathname: location.pathname, search: `?page=${1}` });
                await fetchDatas("", true)
            }
        }

        fetchOnViewChange()
        // eslint-disable-next-line
    }, [view])

    return (
        <CanView permissions={['contracts.c_view_all_contracts', 'contracts.c_view_user_contracts']} redirect>
            <BreadcrumbNav items={[{ 'name': t("contracts"), 'icon': '', 'href': '' }]} />
            <Ref innerRef={segmentRef} >
                <Container fluid >
                    <Sidebar.Pushable as={Segment} style={{ border: "none", background: "transparent", boxShadow: "none", padding: 0, margin: 0 }} className="fixed-sidebar">
                    <Sidebar
                        style={{ width: "40rem", }}
                        as={Menu}
                        animation='overlay'
                        direction='right'
                        icon='labeled'
                        vertical
                        visible={visible}
                        onHide={() => setVisible(false)}
                        width='wide'
                        target={segmentRef}
                    >
                        {view === 0 
                        ?   <ContractFilterForm
                                setVisible={setVisible}
                                setContracts={setDatas}
                                fetchDatas={fetchDatas}
                                contractsInitState = {contractsInitState}
                                contractsFilters={contractsFilters}
                                setContractsFilters={setContractsFilters}
                                search={search}
                            />
                        :   <ContractTerminationFilterForm
                                setVisible={setVisible}
                                setTerminations={setDatas}
                                fetchDatas={fetchDatas}
                                terminationsInitState={terminationsInitState}
                                terminationsFilters={terminationsFilters}
                                setTerminationsFilters={setTerminationsFilters}
                                search={search}
                            />
                        }

                    </Sidebar>
                    <Sidebar.Pusher>
                        <FlexRow padding="0rem">
                            <FlexItem basis="150%">
                                <Button.Group basic size="small" style={{ borderRadius: "0" }}>
                                    <Button active={view === 0} content={t('contracts')} onClick={() => setView(0)} />
                                    <Button active={view === 1} content={t('terminations')} onClick={() => setView(1)} />
                                </Button.Group>
                            </FlexItem>
                            <FlexItem>
                                <FlexRow padding="0">
                                    <FlexItem>
                                        <Input size="small" icon='search' value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search')} style={{ width: "100%" }} />
                                    </FlexItem>
                                    <FlexItem basis={isManageable ? "50%" : "100%"} textAlign="right">
                                        <Button
                                            size="small"
                                            style={{
                                            background: "transparent",
                                            color: "var(--black)",
                                            paddingLeft: "1rem",
                                            paddingRight: "1rem"
                                            }}
                                            onClick={() => setVisible(!visible)}
                                        >
                                            <Icon name='funnel-outline' style={{ margin: 0, fontSize: "1.2rem" }} />
                                        </Button>

                                    </FlexItem>
                                    <CanView permissions={['contracts.c_manage_all_contracts']}>
                                        <FlexItem>
                                            <SuperDuperModal
                                                header={t('create_contract')}
                                                size="small"
                                                trigger={
                                                    <Button fluid primary onClick={() => setView(0)}> {t('create_contract')} </Button>
                                                }
                                                content={
                                                    <ContractForm setContracts={setDatas} />
                                                }
                                            />
                                        </FlexItem>
                                    </CanView>
                                </FlexRow>
                            </FlexItem>
                        </FlexRow>
                        <div style={{ marginTop: "0.5rem" }}/>

                        {view === 0 && isLoading ? <Loading /> :
                            <>
                                {view === 0 && <ContractList contracts={datas?.data?.results} setContracts={setDatas} />}
                            </>
                        }
                        {view === 1 && isLoading ? <Loading /> :
                            <>
                                {view === 1 && <TerminationList terminations={datas?.data?.results} setTerminations={setDatas} />}
                            </>
                        }
                        <Paginator
                            forcePage={page || 1}
                            limit={limit}
                            setLimit={setLimit}
                            length={datas?.data?.count || 0}
                            onChange={(params) => fetchDatas(params)}
                        />
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Container>
            </Ref>
        </CanView>
    );
};

export default ContractListView;