import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next"
// routes
import { routes } from '@routes/index';
// components
import Icon from '@components/Icon';
import { Button, Grid, Label } from 'semantic-ui-react';
import { FlexTable, FlexRow, FlexHeader, FlexItem } from '@components/tables/FlexTable';
import EmptyRow from '@components/tables/EmptyRow';
import AvatarIcon from '@components/AvatarIcon';
import CanView from '@components/perms/CanView';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import SkillsTooltip from '@components/general/SkillsToolip';
import Action from '@components/general/Action';
// module specific
import StartOnOff from '../../../onboarding/components/forms/StartOnOff';
import ContactCell from '../../../candidates/components/tables/ContactCell';
import LabelsForm from '../forms/LabelsForm';

const EmployeesTable = ({ data, onDelete, onLabelsAdd, onLabelRemove }) => {
    const { t } = useTranslation();

    const TableAction = ({ row }) => {
        return (
            <FlexRow>
                <CanView permissions={["common.c_view_all_employees", "common.c_view_user_employees"]}>
                    <FlexItem>
                        <Action type='icon' as="link" to={routes.EMPLYOEES_DETAIL + row.id} tooltip={t('view_detail')} paddingLeft="0.5rem" paddingRight="0.5rem" />
                    </FlexItem>
                </CanView>
                <CanView permissions={["onboarding.c_manage_all_checklist_instances"]}>
                    <FlexItem>
                        <Action
                            as="modal"
                            type="icon"
                            header='Start On/Off Boarding'
                            icon="barcode-outline"
                            iconColor="var(--dark)"
                            tooltip={'Start On/Off-Boarding'}
                            modal={
                                <StartOnOff id={row.id} btnName='Start'/>
                            }
                        />
                    </FlexItem>
                </CanView>
                <CanView permissions={["common.c_delete_all_employees"]}>
                    <FlexItem>
                        <Action as='delete' tooltip={t('delete')} text={t('delete_employee_message')} onClick={() => onDelete(row.id)} />
                    </FlexItem>
                </CanView>
            </FlexRow>
        );
    }

    const PutAvatar = ({ row }) => {
        return (
            <Grid style={{ padding: 0 }} >
                <Grid.Row style={{ padding: 0 }} verticalAlign='middle'>
                    <Grid.Column width={2}>
                        <AvatarIcon
                            size={35}
                            name={row.fullname}
                            src={row.profile_picture}
                        />
                    </Grid.Column>
                    <Grid.Column width={14}>
                        <strong>{row.fullname}</strong> <br/>
                        <span>{row.positions[0]?.title}</span>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    const PutUnit = ({ row }) => {
        return (
            <>
                {
                    row.unit !== null ?
                        <span style={{ cursor: "pointer", color: "var(--primary)" }}>
                            <CanView permissions={["company.c_view_units"]}>
                                <Button as={Link} to={routes.COMPANY + 'unit/' + row.unit.id} style={{ backgroundColor: 'transparent', padding: 0 }}>
                                    <strong>{row.unit.name}</strong>
                                </Button>
                            </CanView>
                        </span>
                        :
                        <span>
                            --
                        </span>
                }

            </>
        );
    }

    const PutDot = ({ row }) => {
        return (
            <Grid columns='2' style={{ padding: 0 }}>
                <Grid.Row style={{ padding: 0 }}>
                    <Grid.Column width='4' style={{ paddingLeft: 0, paddingRight: 0 }}>
                        {row.is_active ?
                            <Icon
                                name='ellipse'
                                style={{ color: "var(--success)" }}
                            />
                            :
                            <Icon
                                name='ellipse'
                                style={{ color: "var(--danger)" }}
                            />
                        }
                    </Grid.Column>
                    <Grid.Column style={{ paddingLeft: 0 }}>
                        {row.is_active ?
                            t('active_him')
                            :
                            t('inactive_him')
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }

    const ShowLabels = ({ row }) => {

        return (
            <>
                {row?.contact_labels?.map(label => (
                    <Label basic size="tiny" key={label.id}>
                        <Icon name="bookmark-outline" style={{ color: label.color === "" ? "" : label.color }} /> {label.name}
                        <Icon name="close-outline" style={{ marginLeft: "0.5rem", cursor: "pointer" }} onClick={() => onLabelRemove(row.id, label.id)} />
                    </Label>
                ))}
                <SuperDuperModal
                    trigger={
                        <Label basic size="tiny" style={{ cursor: "pointer" }}>
                            <Icon name="add-outline" />
                        </Label>
                    }
                    content={
                        <LabelsForm
                            id={row.id}
                            contactLabels={row.contact_labels}
                            onConfirm={onLabelsAdd}
                        />
                    }
                />
            </>
        );
    }

    return (
        <FlexTable>
            <FlexRow>
                <FlexHeader basis="100%" content={t('name')} />
                <FlexHeader basis="100%" content={t('contact')} />
                <FlexHeader basis="80%" content={t('unit')} />
                <FlexHeader basis="50%" content={t('skills')} />
                <FlexHeader basis="100%" content={t('labels')} />
                <FlexHeader basis="50%" content={t('status')} />
                <FlexHeader basis="50%" content={t('actions')} />
            </FlexRow>
            <EmptyRow length={data.length} />
            {data?.map((employee) => (
                <FlexRow key={employee.id} fontSize="0.9rem" borders>
                    <FlexItem basis="100%" content={<PutAvatar row={employee} />} />
                    <FlexItem basic="100%" content={<ContactCell row={{ phone: employee.personal_information?.contact_info?.phones[0]?.phone, email: employee.personal_information?.contact_info?.emails[0]?.email }} />} />
                    <FlexItem basis="80%" content={<PutUnit row={employee} />} />
                    <FlexItem basis="50%" content={<SkillsTooltip skills={employee.skills} />} />
                    <FlexItem basis="100%" content={<ShowLabels row={employee} />} />
                    <FlexItem basis="50%" content={<PutDot row={employee} />} />
                    <FlexItem basis="50%" content={<TableAction row={employee} />} />
                </FlexRow>
            ))}
        </FlexTable>
    );
};

export default EmployeesTable;