import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
// store
import { requests } from '@helpers/requests';
import { API } from '@store/config';
import { toast } from 'react-semantic-toasts';
// components
import Icon from '@components/Icon';
import { Grid, Form, Message, Button } from 'semantic-ui-react';
import SuperField from '@components/forms/SuperField';
import EmptyRow from '@components/tables/EmptyRow';
import CanView from '@components/perms/CanView';
import SuperDuperModal from '@components/modals/SuperDuperModal';
import LabelsForm from '@components/forms/LabelsForm';
import { FlexRow, FlexItem } from '@components/tables/FlexTable';
import CustomFormDivider from '@components/general/CustomFormDivider';


const ProjectUpdateForm = ({ project, setProject, listView, projects, setProjects }) => {
    const { t } = useTranslation();

    const [isProcessing, setIsProcessing] = useState(false);
    const [errors, setErrors] = useState(null);
    const [form, setForm] = useState({
        name: project.name ? project.name : null,
        identifier: project.identifier ? project.identifier : null,
        description: project.description ? project.description : null,
        budget_amount: project.budget_amount ? project.budget_amount : null,
        project_manager: project.project_manager ? project.project_manager.id : null,
        from_date: project.from_date ? project.from_date : null,
        due_date: project.due_date ? project.due_date : null,
        end_date: project.end_date ? project.end_date : null,
        is_active: project.is_active ? project.is_active : null
    })
    // const [search, setSearch] = useState("")

    const handleSubmit = async () => {
        setErrors(null)
        setIsProcessing(true);

        const result = await requests.patch(API.PROJECTS + project.id + "/", form)

        if ( listView === true ){

            if( result.status === 200 ){
                setProjects(prevState => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        results: projects?.data?.results?.map(item => {
                            if (item?.id === project.id) {
                                item = result?.response
                            }
                            return item;
                        })
                    }
                }))
                toast({
                    type: 'success',
                    title: t('updated'),
                    description: t('project_was_sucessfully_updated'),
                    animation: 'bounce',
                    time: 5000,
                 })

            } else if( result.status === 400 ) {
                setErrors(result.response)
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('something_went_wrong'),
                    description: t('something_went_wrong'),
                    animation: 'bounce',
                    time: 5000,
                 })
            }

        } else {
            if( result.status === 200 ){
                setProject(result.response)
                toast({
                    type: 'success',
                    title: t('updated'),
                    description: t('project_was_sucessfully_updated'),
                    animation: 'bounce',
                    time: 5000,
                 })
            } else if( result.status === 400 ) {
                setErrors(result.response)
                toast({
                    type: 'error',
                    icon: 'warning',
                    title: t('something_went_wrong'),
                    description: t('something_went_wrong'),
                    animation: 'bounce',
                    time: 5000,
                 })
            }
            setIsProcessing(false)

        }

    }

    const onLabelsAdd = (id, labels) => {
        setProject(prev=> ({
            ...prev,
            labels: labels

        }))
}

    const onLabelRemove = async (id, labelID) => {

        const request = await requests.patch(API.PROJECTS + id + "/?query={id, labels}", {
            labels: {
                remove: [labelID]
            }
        });

        if (request.status === 200) {
            setProject(prevState => ({
                ...prevState,
                labels: request.response.labels
            }))
        }

    }

    const ShowLabels = ({ project }) => {
        return (
            <>
            <Grid>

            <Grid.Row columns={2}>
                {/* <Grid.Column textAlign='right' mobile={16} tablet={16} computer={8}>
                    <Input size="small" icon='search' value={search} onChange={(e) => setSearch(e.target.value)} placeholder={t('search')} style={{ margin: "5px", width: "100%" }} />
                </Grid.Column> */}
                <Grid.Column textAlign='right' mobile={16} tablet={16} computer={16}>
                    <CanView permissions={['projects.c_manage_labels']}>
                        <SuperDuperModal
                            trigger={
                                <span style={{ textAlign: "right", margin: "1rem" }} >
                                    <Button size="small" primary> {t('add_label')} </Button>
                                </span>

                            }
                            content={
                                <LabelsForm
                                    id={project.id}
                                    data={project.labels}
                                    onConfirm={onLabelsAdd}
                                    patchEndpoint={API.PROJECTS}
                                    labelEndpoint={API.PROJECT_LABELS}
                                    fieldName={"labels"}
                                />
                            }
                        />
                    </CanView>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column textAlign='right' mobile={16} tablet={16} computer={16}>
                    <EmptyRow length={project?.labels?.length}/>
                    {project?.labels?.map(label => (
                    <>
                        <FlexRow key={label.id}>
                            <FlexItem basis='150%'>
                                <Icon name="pricetag-outline" style={{ marginRight: "0.5rem", color: label.color === "" ? "" : label.color }} /> 
                                <span style={{ position: "relative", top: "-0.2rem" }}>
                                    {label.name}
                                </span>
                            </FlexItem>
                            <FlexItem basis='20%'>
                                <CanView permissions={['projects.c_manage_labels']}>
                                    <Icon style={{cursor: "pointer", color: "var(--danger)"}} name="close-outline" onClick={() => onLabelRemove(project.id, label.id)} />
                                </CanView>
                            </FlexItem>
                        </FlexRow>
                    </>
                    ))}
                </Grid.Column>
            </Grid.Row>
            </Grid>
            </>
        );
    }

    return (
        <>
        <Grid columns={3}>
            <Grid.Row>
                <Grid.Column width={listView ? 16 : 10} >
                    <Form onSubmit={handleSubmit}>
                        <Message
                            error
                            visible={errors?.non_field_errors?.length > 0}
                            header={t("error_submission_message")}
                            list={errors?.non_field_errors}
                        />

                            <Grid.Row>
                                <Grid.Column>
                                    <CustomFormDivider
                                        header = {
                                            <>
                                                { t('project_update') }
                                            </>
                                        }
                                        headerSize = "h4"
                                        content = {
                                            <>
                                                <Form.Group widths="equal">
                                                    <SuperField as="input"
                                                        required
                                                        autoFocus
                                                        label={t('name')}
                                                        placeholder={t('name')}
                                                        value={form.name}
                                                        error={ errors?.name || false }
                                                        onChange={(e, { value }) =>
                                                            setForm({ ...form, name: value})
                                                        }
                                                    />
                                                    <CanView permissions={['projects.c_assign_project_manager']}>
                                                        <SuperField as="choice"
                                                            search
                                                            style={{marginTop: '4px'}}
                                                            text="fullname"
                                                            label={t('project_manager')}
                                                            value={form?.project_manager}
                                                            error={ errors?.project_manager || false }
                                                            onChange={(e, { value }) =>
                                                                setForm({ ...form, project_manager: value})
                                                            }
                                                            endpoint={API.EMPLOYEES + "?only_basic_info=true"}
                                                        />
                                                    </CanView>
                                                </Form.Group>

                                                <SuperField as="textarea"
                                                    label={t('description')}
                                                    placeholder={t('description')}
                                                    value={form?.description}
                                                    error={ errors?.description || false }
                                                    onChange={(e, { value }) =>
                                                        setForm({ ...form, description: value})
                                                    }
                                                />

                                                <Form.Group widths={2}>
                                                    <SuperField as="datepicker"
                                                        label={ t('start_date') }
                                                        value={form?.from_date}
                                                        error={ errors?.from_date || false }
                                                        onChange={(e, { value }) =>
                                                            { value === "" || value === null ?
                                                            setForm({ ...form, from_date: null}) :
                                                            setForm({ ...form, from_date: value})
                                                        }}
                                                    />
                                                    <SuperField as="datepicker"
                                                        label={ t('due_date') }
                                                        value={form?.due_date}
                                                        error={ errors?.due_date || false }
                                                        onChange={(e, { value }) =>
                                                            { value === "" || value === null ?
                                                            setForm({ ...form, due_date: null}) :
                                                            setForm({ ...form, due_date: value})
                                                        }}
                                                    />
                                                </Form.Group>

                                                <Form.Group widths={2}>
                                                    <SuperField as="datepicker"
                                                        label={ t('end_date') }
                                                        value={form?.end_date}
                                                        error={ errors?.end_date || false }
                                                        onChange={(e, { value }) =>
                                                            { value === "" || value === null ?
                                                            setForm({ ...form, end_date: null}) :
                                                            setForm({ ...form, end_date: value})
                                                        }}
                                                    />
                                                    <SuperField as="input"
                                                        label={t('budget')}
                                                        placeholder={t('budget')}
                                                        value={form?.budget_amount}
                                                        error={ errors?.budget_amount || false }
                                                        onChange={(e, { value }) =>
                                                            { value === "" || value === null ?
                                                            setForm({ ...form, budget_amount: null}) :
                                                            setForm({ ...form, budget_amount: value})
                                                        }}
                                                    />
                                                </Form.Group>
                                                { project?.is_active ?
                                                    <SuperField as="checkbox"
                                                        label={ t('close_project') }
                                                        error={ errors?.is_active || false }
                                                        onChange={(e, { value }) => setForm({ ...form, is_active: false})}

                                                    /> :
                                                    <SuperField as="checkbox"
                                                        label={ t('open_project') }
                                                        error={ errors?.is_active || false }
                                                        onChange={(e, { value }) => setForm({ ...form, is_active: true})}
                                                    />
                                                }
                                            </>
                                        }
                                    />
                                </Grid.Column>

                            </Grid.Row>
                            <CanView permissions={['projects.c_manage_all_projects', 'projects.c_manage_user_projects']}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Field style={{ textAlign: "right", margin: "1rem" }}>
                                            <Button disabled={isProcessing} loading={isProcessing} size="large" primary> {t('save')} </Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </CanView>
                    </Form>
                </Grid.Column>
                { listView !==true &&
                <Grid.Column width={6}>
                    <CustomFormDivider
                        header = {
                            <>
                                { t('label_management') }
                            </>
                        }
                        headerSize = "h4"
                        content = {
                            <>
                                <ShowLabels
                                    project={project}
                                    type={'projects'}
                                />
                            </>
                        }
                    />
                </Grid.Column>
                }

            </Grid.Row>
        </Grid>



        </>
    )
}

export default ProjectUpdateForm;



